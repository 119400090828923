import PropTypes from "prop-types"
import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Typography } from "@mui/material";
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom';

function AdvertismentSlide({ ad }) {
    const theme = useTheme();
    const below700 = useMediaQuery(theme.breakpoints.down('700'));
    const history = useHistory();

    const routesToSubscriptionDetails = (ad) =>{
        let params = `${ad?.subscriptionDetail?.id}/${ad?.subscriptionDetail?.versionId}`
        history.push({
            pathname:`/purchase/details/${params}`
        });
    }
    
    return (
        <Box 
            sx={{ 
                mr:1,cursor:'pointer'
            }} 
            onClick={()=>routesToSubscriptionDetails(ad)}
        >
            <Paper
                elevation={0}
                sx={{
                    p: 0.5,
                    height: 175,
                    width: 337,
                    // height: 180,
                    // width: "100%",
                    borderRadius: 1.25,
                    border: 1,
                    borderColor: "grey.200",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 8,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${ad.bg_image})`,
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 8,
                        // background: "linear-gradient(to right, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0))",
                        padding: 16
                    }}>
                        {/* <Box sx={{ px: 1.5, mb: 1.5, borderRadius: 0.5, background: "#FDEB00", width: "fit-content" }}>
                            <Typography fontWeight="bold">
                                {ad.promotion_type}
                            </Typography>
                        </Box>
                        <Typography variant="h6" sx={{ color: "common.white", fontWeight: "bold" }}>
                            {ad.title}
                        </Typography> */}
                    </div>
                </div>
            </Paper>
        </Box>
    );
}

AdvertismentSlide.propTypes = {
  ad: PropTypes.shape({
    bg_image: PropTypes.string,
    // bg_image: PropTypes.shape({
    //   default: PropTypes.string
    // }),
    promotion_type: PropTypes.string,
    title: PropTypes.string
  })
}

function Carousel({ advertisementData = [] }) {
    const theme = useTheme()
    // const belowSm = useMediaQuery(theme.breakpoints.down('sm'))
    const above700 = useMediaQuery(theme.breakpoints.up('700'));

    const [showArrow,setShowArrow] = React.useState(0);

    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        arrows: false,
        infinite:advertisementData?.length > 3, // 3 is slidesToshow number
        speed: 200,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    infinite: advertisementData?.length > 1, // 1 is slidesToshow number
                },
            },
        ],
        variableWidth: true,
        
    };

    const previous = () => {
        sliderRef.current.slickPrev()
    }

    const next = () => {
        sliderRef.current.slickNext()
    }

    React.useEffect(()=>{
        if(sliderRef?.current){
            setShowArrow(document?.getElementsByClassName('slick-active')?.length);
        }
    },[])

    return (
        <Box>
            {(above700 && advertisementData?.length > showArrow) && <Stack justifyContent="flex-end" direction="row" spacing={1}>
                <IconButton onClick={previous} aria-label="previous" size="small" id="previousButtonCarousel">
                    <ChevronLeftIcon sx={{ color: "text.primary" }} />
                </IconButton>
                <IconButton onClick={next} aria-label="next" size="small" id="nextButtonCarousel">
                    <ChevronRightIcon sx={{ color: "text.primary" }} />
                </IconButton>
            </Stack>}

            {/* Slider */}
            {<Box sx={[
                {maxWidth: "100vw", paddingTop: above700 ? 0 : 1},
                {'& .slick-track':{marginLeft:0,display:'flex'}}
            ]}>
                <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
                    {advertisementData?.length && advertisementData.map((ad, index) => (
                        <AdvertismentSlide key={ad?.id ?? index} ad={ad} />
                    ))}
                </Slider>
            </Box>}
        </Box>
    );
}

Carousel.propTypes = {
  advertisementData: PropTypes.array.isRequired
}

export default Carousel;
