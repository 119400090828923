import PropTypes from "prop-types";
import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomButton } from "..";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RightArrowStripesIcon } from "../../assets";

function DrawerSkeleton(props) {
  const [loading, setLoading] = useState(false);
  const {
    handleClose,
    handleConfirm,
    handleClear,
    renderBackIcon,
    renderTitle,
    showButton = true,
    parentID = ""
  } = props;
  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          pt: 2,
          pb: 1.5,
          px: 1,
          alignItems: "center",
          border: 1,
          borderColor: "grey.border",
          position: "absolute",
          right: 0,
          left: 0,
          top: 0,
        }}
      >
        {renderBackIcon ? (
          renderBackIcon()
        ) : (
          <IconButton
            onClick={handleClose}
            aria-label="close-drawer"
            id={`filterCloseBtnIcon${parentID}`}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        )}
        {renderTitle ? (
          renderTitle()
        ) : (
          <Typography
            sx={{
              flex: 1,
              textAlign: "center",
              ml: props.secondaryButtonText ? "0px" : "-44px",
            }}
          >
            {props.title}
          </Typography>
        )}

        {props.secondaryButtonText && (
          <CustomButton
            variant="text"
            color={
              props.secondaryButtonColor ? props?.secondaryButtonColor : "error"
            }
            onClick={handleClear}
            aria-label="reset"
            id={`${props?.secondaryButtonText?.replaceAll(
              " ",
              "-"
            )}-btn-icon-${parentID}`}
          >
            {props.secondaryButtonText}
          </CustomButton>
        )}
      </Stack>
      <Box
        sx={{
          mb: showButton ? 6.5 : 0,
          mt: 8.25,
          maxHeight: `calc(100vh - ${showButton ? 52 : 0}px + 66px)`,
          overflow: "scroll",
          flex: 1,
          ...(props.gutters && { px: { xs: 2.5, md: 2.5 } }),
        }}
      >
        {props.children}
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        {showButton ? (
          <CustomButton
            fullWidth
            sx={{ borderRadius: 0, py: 2 }}
            endIcon={<RightArrowStripesIcon />}
            loading={loading}
            onClick={() => {
              setLoading(true);
              handleConfirm();
            }}
            id={`${props?.buttonText?.replaceAll(" ", "-")}-btn-${parentID}`}
          >
            {props.buttonText}
          </CustomButton>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

DrawerSkeleton.propTypes = {
  buttonText: PropTypes.string,
  gutters: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
};

DrawerSkeleton.defaultProps = {
  gutters: true,
  buttonText: "Save",
  handleClose: () => null,
  handleConfirm: () => null,
  secondaryButtonText: "",
  handleClear: () => null,
};

export default DrawerSkeleton;
