import { CreateIcon, DeleteIcon } from "../../../assets";
import Box from "@mui/material/Box";
import { CustomButton } from "../../../components";
import { CustomCard } from "../../../components/composedComponents/subscriptions/customCard";
import { IconButton } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { isObjectEmpty, snackProps } from "../../../utils";
import { withDialog, withDrawer, withSnackBar } from "../../../HOC's";
import AddContactDrawer from "../../../components/cards/addContactCard";
import { Prompt } from ".";
import ViewAllContact from "./viewAllContact";
import { StyledChip } from "./styledChip";

const dynamicPx = { xs: 1.5, md: 1.5 };

function MultipleContact(props) {
  const {
    contacts = [],
    options = [],
    isPrimaryLogicNeeded,
    primaryContactId,
    handleSave,
    handleDelete,
    helperText,
    parentID=""
  } = props;

  // Handlers
  const handleCloseDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: <></>,
    });
  };

  const primaryChangePrompt = (closeAndSave) => {
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <Prompt
          title={"Change Primary Contact"}
          body={
            <>
              Are you sure, do you want to update <br /> this contact as
              primary?
            </>
          }
          handleClose={handleClose}
          handleAccept={handleAccept}
        />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: () => closeAndSave(),
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handleSaveContact = (data) => {
    const closeAndSave = () => {
      handleCloseDrawer();
      handleSave(data);
    };

    // Will execute this function where primary logic is not needed
    if (!isPrimaryLogicNeeded) {
      return closeAndSave();
    }

    if (
      (contacts?.length === 0 && !data?.isPrimary) ||
      (primaryContactId === data?.id && !data?.isPrimary)
    ) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Atleast one contact should be primary.",
      });
    } else if (
      contacts?.length > 0 &&
      primaryContactId &&
      primaryContactId !== data?.id &&
      data?.isPrimary
    ) {
      primaryChangePrompt(closeAndSave);
    } else {
      closeAndSave();
    }
  };

  const handleAddEditClick = (editData = null) => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <AddContactDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={options}
          onSave={handleSaveContact}
          handleClose={handleClose}
          isPrimaryLogicNeeded={isPrimaryLogicNeeded}
          parentID={`FromAddContact${parentID}`}
        />
      ),
    });
  };

  const handldeDeleteClick = (deletedContact) => {
    if (primaryContactId === deletedContact?.id) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message:
          "Please set any other contact as primary to delete this contact.",
      });
    }
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <Prompt
          title={"Delete Contact"}
          body={
            <>
              Are you sure, do you want to delete <br /> this contact?
            </>
          }
          handleClose={handleClose}
          handleAccept={handleAccept}
        />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: () => handleDelete(deletedContact),
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handleViewAllClick = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllContact
          value={contacts}
          handleClose={handleClose}
          handleEditClick={handleAddEditClick}
          handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  return (
    <>
      <CustomCard
        {...props}
        required={props.required}
        titleSx={{ color: "text.primary", fontWeight: 500 }}
        stackSx={{ px: dynamicPx }}
        title={"Contact Details"}
        helperText={helperText}
        parentID={`FromMultipleContact${parentID}`}
      >
        <Box
          sx={{
            pb: 1,
            bgcolor: (theme) => alpha(theme.palette.secondary.light, 0.1),
          }}
        >
          {!isObjectEmpty(contacts) && !props.isEdit && (
            <Box>
              {Array.isArray(contacts) &&
                contacts?.length > 0 &&
                contacts?.slice(0, 1)?.map((contact, index) => (
                  <Stack
                    gap={0}
                    sx={{
                      borderBottom: 1,
                      borderColor: "grey.border",
                      px: dynamicPx,
                      pt: 1,
                      bgcolor: "common.white",
                    }}
                  >
                    <>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                      >
                        <Stack>
                          <Stack
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                            sx={{}}
                          >
                            <Typography color="textSecondary">
                              {contact?.mobile_type?.label}
                            </Typography>
                            {contact?.isPrimary && (
                              <StyledChip label="Primary" color="error" />
                            )}
                          </Stack>
                          <Typography
                            sx={{
                              color: "text.primary",
                            }}
                          >
                            {`${contact?.mobilenumberISDcode} ${contact?.mobilenumber}`}
                          </Typography>
                        </Stack>
                        <Stack
                          flexDirection="row"
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          // sx={{ mb: 2 }}
                        >
                          <IconButton
                            onClick={() => handleAddEditClick(contact)}
                            id={`editContact${contact?.id}`}
                          >
                            <CreateIcon fontSize="small" sx={{fontSize: "16px"}} />
                          </IconButton>
                          <IconButton
                            onClick={() => handldeDeleteClick(contact)}
                            id={`deleteContact${contact?.id}`}
                          >
                            <DeleteIcon fontSize="small" sx={{fontSize: "16px"}} />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </>
                  </Stack>
                ))}
            </Box>
          )}

          <Box sx={{ px: dynamicPx, pt: 1 }}>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <CustomButton
                variant="text"
                color="secondary"
                size="small"
                onClick={() => handleAddEditClick()}
                id={`addContactBtn${parentID}`}
              >
                + Add Contact
              </CustomButton>
              {contacts?.length > 1 ? (
                <CustomButton
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleViewAllClick}
                  id={`viewSavedContactButton${parentID}`}
                >
                  View Saved Contact
                </CustomButton>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Box>
      </CustomCard>
    </>
  );
}

MultipleContact.defaultProps = {
  isPrimaryLogicNeeded: true,
};

export default withDrawer(withDialog(withSnackBar(MultipleContact)));
