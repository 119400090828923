import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Self hosted font
// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";

// import * as serviceWorker from "./serviceWorkerRegistration";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51JmVnxJ0AUazv46CVeGlj1C7Uz9pctAc3F43wfCGLKjIbuUpZ89B8IoHUQUk5gv9hTaiYr3VLThuFjJ7zhwBrZsY00zTyYzcg2"
);

document.addEventListener("DOMContentLoaded", async () => {
  const API = `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_API_SERVER_HOST}/api/v1`;
  let fetchKey;
  let actualStripePromise;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  };
  try {
    fetchKey = await fetch(`${API}/stripe-config`, options);
    const publishableKey = await fetchKey.json();
    // console.log(publishableKey?.data?.publishKey)
    actualStripePromise = publishableKey?.data?.publishKey
      ? await loadStripe(publishableKey?.data?.publishKey)
      : null;
  } catch (error) {
    console.log(error);
    console.error("STRIPE FETCH KEY ERROR");
  }

  ReactDOM.render(
    <React.StrictMode>
      <Elements stripe={actualStripePromise}>
        <App />
      </Elements>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorker.register();
reportWebVitals();
