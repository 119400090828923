import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const useTopNavHeightHooks = () =>{
const theme = useTheme();
const xsOnly = useMediaQuery("(min-width:600px)");
const isMinWidthZeroAndLandscapeOrientation = useMediaQuery("(min-width:0px) and (orientation: landscape)");

    let topnavHeight;
    if(xsOnly){
        topnavHeight = '64px'
    }
    else if(isMinWidthZeroAndLandscapeOrientation){
        topnavHeight = '48px'
    }
    else{
        topnavHeight = '56px'
    }
    return topnavHeight
};

export default useTopNavHeightHooks




