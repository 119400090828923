
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Boolean(re.test(email?.trim()));
};

export let maxMobileNumberLimit = 14;

export const isNumberKey = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= maxMobileNumberLimit) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault();
    }
  }
};

export const isNumberDecimalAndNoE = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (e.which === 69 || e.which === 101 || e.which === 45)
        e.preventDefault();
    }
  }
};

export const isNumberKeyDown = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= maxMobileNumberLimit) {
      console.log(e)
      if (e.which === 38 || e.which === 40 || e.type === "wheel") e.preventDefault();
    }
  }
};

export const checkScroll = (e) => {
  console.log(e.nativeEvent)
  //
  if(e.type === "wheel")
  {
    // console.log(e.target)
    // this.blur()
    e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }
}

export const containsPeriod = (value) => {
  const regex = /^[^.].*[^.]$/;
  return regex.test(value);
};

export const isUserIdLimitError = (userId) => {
    return {
      error: userId?.toString()?.trim()?.length < 6 || userId?.toString()?.trim()?.length > 30 ? true : false,
      errorMessage: "Should contain atleast 6 characters and maximum 30 characters"
    }
}

export const checkOneCharOneDigitOptionalSymbol = (value) => {
  const exp = /(?=.*\W{0,})(?=.*\d{1,})(?=.*[^\W\d_])/
  return exp.test(value)
}

// Password CustomValidations
export const minCheck = (passwordValue) => {
  const exp = /^(?=.*[A-Za-z\d\W])(?!.*\s).{8,}$/;
  return exp.test(passwordValue);
};
export const minNumberAndSymbolCheck = (passwordValue) => {
  const exp = /(?=.*\W)(?=.*\d)/;
  return exp.test(passwordValue);
};
export const charCaseCheck = (passwordValue) => {
  const exp = /(?=.*[a-z])(?=.*[A-Z])/;
  return exp.test(passwordValue);
};
