import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorPrimary,
    stroke: (props) => props.htmlColorPrimary,
  },
  cls2: {
    fill: (props) => props.htmlColorSecondary,
    stroke: (props) => props.htmlColorSecondary,
  },
}));

function LocationDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="location icon" strokeWidth={0.6}>
        <path
          data-name="icons8-location (2)"
          d="M12.055.502a8.134 8.134 0 0 1 8.125 8.125c0 2.03-1.307 4.062-2.446 5.605-.661.9-1.408 1.807-2.133 2.689a8.326 8.326 0 0 1 2.54 1.067 2.8 2.8 0 0 1 1.466 2.261c0 1.077-.838 2.055-2.361 2.756a14.042 14.042 0 0 1-10.386 0c-1.522-.7-2.361-1.679-2.361-2.756a2.808 2.808 0 0 1 1.479-2.27 8.377 8.377 0 0 1 2.559-1.066l-.028-.034c-.368-.442-.749-.9-1.113-1.35-2.082-2.571-3.469-4.728-3.469-6.9A8.136 8.136 0 0 1 12.055.502Zm0 1.024a7.111 7.111 0 0 0-7.1 7.1c0 1.873 1.3 3.856 3.241 6.258.36.445.739.9 1.1 1.339l.568.684.321.389.308.378.295.368c.193.243.377.48.553.713q.132.175.259.347t.248.343q.121.171.237.34.128-.188.261-.375t.27-.373q.136-.186.276-.369.139-.184.28-.365.14-.181.281-.359l.28-.352c.186-.232.37-.458.549-.677l.51-.622c.722-.88 1.469-1.789 2.12-2.672 1.553-2.106 2.246-3.647 2.246-5a7.109 7.109 0 0 0-7.103-7.095ZM9.268 17.795c-2.22.461-3.744 1.45-3.744 2.453 0 .644.643 1.31 1.765 1.825a13.04 13.04 0 0 0 9.531 0c1.121-.516 1.764-1.181 1.764-1.825 0-.995-1.508-1.981-3.7-2.445l-.058.071-.145.18-.182.226-.136.17-.191.241-.14.179-.182.235-.139.183-.187.251-.147.2-.045.062q-.13.179-.256.359l-.071.1-.065.094-.089.132-.054.08-.082.125-.061.094q-.066.1-.129.2a.513.513 0 0 1-.083.1.521.521 0 0 1-.049.041.513.513 0 0 1-.173.082.5.5 0 0 1-.064.012.507.507 0 0 1-.066 0 .512.512 0 0 1-.4-.188.5.5 0 0 1-.038-.053l-.12-.189-.093-.143-.034-.052-.08-.119-.054-.081-.074-.109-.054-.079q-.12-.173-.247-.348l-.032-.045c-.051-.071-.105-.142-.158-.214l-.154-.206-.157-.206-.164-.213-.163-.208-.171-.216-.18-.224-.167-.206-.21-.257Z"
          className={classes.cls1}
        />
        <path
          data-name="icons8-location (2)"
          d="M12.049 4.22a4.425 4.425 0 1 1-4.314 4.422 4.375 4.375 0 0 1 4.314-4.422Zm0 1.024a3.4 3.4 0 1 0 3.292 3.4 3.349 3.349 0 0 0-3.292-3.4Z"
          className={classes.cls2}
        />
      </g>
    </SvgIcon>
  );
}

LocationDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default LocationDuoToneIcon;
