import * as React from "react";
import { SvgIcon } from "@mui/material";

const WishlistIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g data-name="wishlist icon">
      <g data-name="Group 95331" fill="#9297a5">
        <path d="M3.592 5v9h-1.6v2.25a2.334 2.334 0 0 0 2.4 2.25h6.412V17H4.392a.778.778 0 0 1-.8-.75v-.75h8.82l-1.6-1.5H5.198V6.5h10.42v3h1.6V5Zm3.209 3v1.5h1.6V8Zm3.206 0v1.5h4.008V8Zm-3.206 3v1.5h1.6V11Zm9.217 3.439Z" />
        <path
          data-name="icons8-heart (1)"
          d="M19.042 10.669a3.275 3.275 0 0 0-2.2.906 3.275 3.275 0 0 0-2.198-.906 3.082 3.082 0 0 0-2.394 1c-2 2.218 1.783 4.814 2.671 5.51.532.417 1.188.912 1.58 1.205a.585.585 0 0 0 .684 0 95.605 95.605 0 0 0 1.58-1.205c.889-.7 4.667-3.292 2.671-5.51a3.08 3.08 0 0 0-2.394-1Z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default WishlistIcon;
