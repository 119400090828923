import PropTypes from "prop-types";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { CustomTextfield } from "..";

// import PropTypes from "prop-types";

function CustomSelect(props) {
  return (
    <Autocomplete
      disablePortal
      id={`combo-box-${props?.id}`}
      options={props?.options}
      value={props.value}
      onChange={props.onChange}
      multiple={props.multiple}
      // sx={{ width: 300 }}
      renderInput={(params) => (
        <CustomTextfield
          {...params}
          {...props.InputProps}
          label={props.label}
          size={props.size}
        />
      )}
      {...props}
    />
  );
}

CustomSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
  multiple: PropTypes.bool,
};

CustomSelect.defaultProps = {
  options: [],
  onChange: () => {},
};

export default CustomSelect;

{
  /* <FormControl>
      <Select
        value={props.selectValue}
        onChange={props.onSelectChange}
        input={<OutlinedInput id="" size="small" />}
      >
        {props.selectOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText id="component-error-text"></FormHelperText>
    </FormControl> */
}
