import React from "react";
import { CustomSnackBar } from "./components";
import CustomSOS from "./components/sos";
import { SOSContext } from "./contexts";
import { snackProps } from "./utils";

const defaultState = {
  open: true,
};

class AppSOS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  // Callback for both Snackbar component and context close
  // reason param will be empty when not called from snackbar
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      ...defaultState,
    });
  };

  handleCreate = (contextSnackProps) => {
    this.setState({ ...contextSnackProps });
  };

  render() {
    return (
      <>
        <CustomSOS
        // open={this.state.open}
        // message={this.state.message}
        // duration={this.state.duration}
        // severity={this.state.severity}
        // vertical={this.state.vertical}
        // horizontal={this.state.horizontal}
        // handleClose={this.handleClose}
        />
        {this.props.children}
      </>
    );
  }
}

export default AppSOS;
