import React from "react";
import forgot_password_1 from "../../assets/icons/forgot_password_1.svg";
import forgot_password_2 from "../../assets/icons/forgot_password_2.png";
import forgot_password_3 from "../../assets/icons/forgot_password_3.svg";
import forgot_password_4 from "../../assets/icons/forgot_password_4.svg";
import forgot_password_5 from "../../assets/icons/forgot_password_5.png";
// import forgot_mail from "../../assets/icons/forgot_mail.svg";
// import forgot_mobile from "../../assets/icons/forgot_mobile.svg";
import orange_on from "../../assets/icons/orange_on.svg";
import orange_off from "../../assets/icons/orange_off.png";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ChangePassword from "./changePassword";
import Grid from "@mui/material/Grid";
import OTPVerification from "./otpVerification";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";
import { dontShowInMenuRoutes } from "../../router/routes";
import Screen from "./screen";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { withSnackBar } from "../../HOC's";
import { maskEmail, maskString, snackProps } from "../../utils";
import { Box, Hidden } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { OnboardFormCard } from "./components";
import TickIcon from '../../assets/icons/tick.svg';

const styles = (theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "70px",
  },
  image: {
    height: "20px",
    width: "20px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  grid2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f0f2f6",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      background: "#ffffff",
    },
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      mobileNumber: "1234567890",
      otp: "",
      password: "",
      confirmPassword: "",
      userID: true,
      sendOTP_to: false,
      verifyOTP: false,
      chnagePassword: false,
      confirmation: false,
      changePassword: false,
      send_OTP_to_mobile: false,
      loading: false,
    };
  }

  componentDidMount = async () => {};
  changeState = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };
  sendOTP_ = () => {
    if (this.state?.userId) {
      const state = this.state;
      state.userID = false;
      state.sendOTP_to = true;
      this.setState({ ...this.state, ...state });
    } else {
      // alert("please enter user ID");
      this.props.snack.setSnack({
        open: true,
        message: "Please enter User ID",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };

  changeSentOTPTo = () => {
    let state = this.state;
    state.verifyOTP = false;
    state.sendOTP_to = true;
    this.setState({ ...this.state, ...state });
  };
  //SEND OTP
  sendOTP = async () => {
    if (this.state?.userId) {
      this.setState({ loading: true });
      // let res = await this.props?.GET_OTP_BY_USERID({
      //   userID: this.state.userId,
      // });
      Promise.resolve(
        await this.props?.GET_OTP_BY_USERID({
          userID: this.state.userId,
        })
      )
        .then((res) => {
          if (
            res?.payload?.data?.message !== "USER NOT EXISTS IN OUR DATABASE"
          ) {
            const state = this.state;
            state.mobileNumber = res?.payload?.data?.mobileNo;
            state.phoneNumber = res?.payload?.data?.phoneNumber;
            state.phoneNumberISD = res?.payload?.data?.phoneNumberISD;
            state.emailId = res?.payload?.data?.emailid;
            state.userID = false;
            state.sendOTP_to = true;
            state.otp_to = res?.payload?.data?.emailid;
            state.verifyOTP = false;
            state.loading = false;

            this.setState({ ...this.state, ...state });
            this.props.snack.setSnack({
              open: true,
              message: "User ID Verified Successfully",
              duration: 6000,
              severity: snackProps.severity.success,
              setSnack: () => null,
            });
          } else {
            this.setState({ loading: false });
            this.props.snack.setSnack({
              open: true,
              message: "User ID Not Registered",
              duration: 6000,
              severity: snackProps.severity.error,
              setSnack: () => null,
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ loading: false });
      this.props.snack.setSnack({
        open: true,
        message: "Please enter User ID",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };

  sendOPT_to_specfic = async () => {
    this.setState({ loading: true });
    let payload = {
      userid: this.state.userId,
    };
    if (this.state.send_OTP_to_mobile) {
      payload.phoneNumberISD = this.state.phoneNumberISD;
      payload.phoneNumber = this.state.phoneNumber;
    } else {
      payload.emailid = this.state.otp_to;
    }

    Promise.resolve(await this.props.GENERATE_FORGET_PASSWORD_OTP(payload))
      .then((res) => {
        console.clear();
        console.log(res);
        if (res?.payload?.data?.status === "Success") {
          const state = this.state;
          state.verifyOTP = true;
          state.sendOTP_to = false;
          state.loading = false;
          this.setState({ ...this.state, ...state });
          this.props.snack.setSnack({
            open: true,
            message: "OTP sent Successfully",
            duration: 6000,
            severity: snackProps.severity.success,
            setSnack: () => null,
          });
        } else {
          this.setState({ loading: false });
          this.props.snack.setSnack({
            open: true,
            message: "Unable to sent OTP. Please try again Later",
            duration: 6000,
            severity: snackProps.severity.error,
            setSnack: () => null,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  //VERIFY OTP
  otpverify = async () => {
    if (this.state?.otp && this.state?.otp?.length === 4) {
      this.setState({ loading: true });
      let payload = {
        userid: this.state.userId,
        otp: this.state.otp,
      };
      if (this.state.send_OTP_to_mobile) {
        payload.phoneNumberISD = this.state.phoneNumberISD;
        payload.phoneNumber = this.state.phoneNumber;
      } else {
        payload.emailid = this.state.otp_to;
      }

      console.log(payload);
      let res = await this.props?.VERIFY_FORGET_PASSWORD_OTP(payload);
      if (res?.payload?.message === "Success") {
        const state = this.state;
        state.verifyOTP = false;
        state.changePassword = true;
        state.loading = false;
        state.res_user_id = res?.payload?.data?.userId;
        this.setState({ ...this.state, ...state });
        this.props.snack.setSnack({
          open: true,
          message: "OTP Verified Successfully",
          duration: 6000,
          severity: snackProps.severity.success,
          setSnack: () => null,
        });
      } else {
        this.setState({ loading: false });
        this.props.snack.setSnack({
          open: true,
          message: "OTP is not valid",
          duration: 6000,
          severity: snackProps.severity.error,
          setSnack: () => null,
        });
      }
    } else {
      this.setState({ loading: false });
      // alert("please enter otp (4) digit");
      this.props.snack.setSnack({
        open: true,
        message: "Please enter OTP (4) digit",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };

  validatePassword(password) {
    const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/; // 8 digit
    const re2 = /(?=.*\W)(?=.*\d)/; //Min number & synbol check
    const re3 = /(?=.*[a-z])(?=.*[A-Z])/; //char case check
    let res = re.test(password) && re2.test(password) && re3.test(password);
    return res;
  }
  //CHANGE PASSWORD
  changePassword = async () => {
    if (this.state?.password && this.state?.confirmPassword) {
      if (
        this.validatePassword(this.state?.password) &&
        this.validatePassword(this.state?.confirmPassword)
      ) {
        if (this.state?.password === this.state?.confirmPassword) {
          this.setState({ loading: true });
          let res = await this.props?.FORGOT_CHANGE_PASSWORD({
            user_id: this.state?.res_user_id,
            // mobileNumber: this.state?.mobileNumber,
            email: this.state?.emailId,
            password: this.state?.password,
            // oldpassword: this.state?.password,
          });
          if (res?.payload?.message === "Success") {
            const state = this.state;
            state.changePassword = false;
            state.confirmation = true;
            state.loading = false;
            this.setState({ ...this.state, ...state });
            this.props.snack.setSnack({
              open: true,
              message: "Password Changed Successfully",
              duration: 6000,
              severity: snackProps.severity.success,
              setSnack: () => null,
            });
          } else {
            this.setState({ loading: false });
            this.props.snack.setSnack({
              open: true,
              message: "Unable to update password",
              duration: 6000,
              severity: snackProps.severity.error,
              setSnack: () => null,
            });
          }
        } else {
          this.setState({ loading: false });
          this.props.snack.setSnack({
            open: true,
            message: "Password and Confirm Password should be same",
            duration: 6000,
            severity: snackProps.severity.error,
            setSnack: () => null,
          });
        }
      } else {
        this.setState({ loading: false });
        this.props.snack.setSnack({
          open: true,
          message:
            "Password must contain an Uppercase a lowercase a number a symbol and minimum of 8 character",
          duration: 6000,
          severity: snackProps.severity.error,
          setSnack: () => null,
        });
      }
    } else {
      this.setState({ loading: false });
      // alert("please enter password & confirm password...");
      this.props.snack.setSnack({
        open: true,
        message: "Please enter password and confirm password",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };

  //HANDLE ROUTE
  handleRoute = () => {
    alert("Back to sign in");
  };
  // hanldleOTPFunction = (value) => {
  // };

  handleOtpChange = (newOtp) => {
    this.setState({ ...this.state, otp: newOtp });
  };

  backtoLogin = () => {
    this.props.history.push({
      pathname: dontShowInMenuRoutes.login.pathName,
    });
  };
  sendOTPTo = (value) => {
    this.setState({
      ...this.state,
      send_OTP_to_mobile: !this.state?.send_OTP_to_mobile,
      otp_to:
        value === "email" ? this.state?.emailId : this.state?.mobileNumber,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{ overflowX: "hidden" }}>
        <>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={0}
              sm={0}
              md={6}
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                background: "white",
                minHeight: "100vh",
              }}
            >
              {this.state?.userID === true && (
                <Screen imageURL={forgot_password_1} />
              )}
              {this.state?.sendOTP_to === true && (
                <Screen imageURL={forgot_password_2} />
              )}
              {this.state?.verifyOTP === true && (
                <Screen imageURL={forgot_password_3} />
              )}
              {this.state?.changePassword === true && (
                <Screen imageURL={forgot_password_4} />
              )}
              {this.state?.confirmation === true && (
                <Screen imageURL={forgot_password_5} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              alignItems="center"
              justifyContent="center"
              className={classes.grid2}
            >
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.state?.userID === true && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{
                      background: "white",
                      padding: "30px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <OnboardFormCard> */}
                    <Hidden mdUp>
                      <Box sx={{ margin: "auto", width: "50%" }}>
                        <img
                          src={forgot_password_1}
                          style={{ width: "150px" }}
                        />
                      </Box>
                    </Hidden>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "20px",
                          color: "black",
                          fontSize: "22px",
                          fontWeight: 600,
                        }}
                      >
                        Forgot Password?
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "16px",
                          color: "#bcbfc7",
                          fontSize: "15px",
                        }}
                      >
                        Enter your user ID to reset your password
                      </p>
                    </div>
                    <div>
                      <div style={{ margin: "50px 20px" }}>
                        <TextField
                          id="userIdTextfield"
                          fullWidth={true}
                          label="User ID"
                          variant="outlined"
                          value={this.state.userId}
                          onChange={(e) =>
                            this.changeState("userId", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className={classes.buttonStyle}>
                      <Button
                        id="sendOtpBtn"
                        style={{
                          width: "100%",
                          margin: "20px",
                          textTransform: "none",
                        }}
                        disabled={this.state.loading}
                        variant="contained"
                        onClick={() => this.sendOTP()}
                      >
                        {this.state.loading ? `Sending ` : ` Send OTP`}
                      </Button>
                    </div>
                    {/* </OnboardFormCard> */}
                  </Grid>
                )}
                {this.state?.sendOTP_to === true && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{
                      background: "white",
                      padding: "30px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <OnboardFormCard> */}
                    <Hidden mdUp>
                      <Box sx={{ margin: "auto", width: "50%" }}>
                        <img
                          src={forgot_password_2}
                          style={{ width: "150px" }}
                        />
                      </Box>
                    </Hidden>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "20px",
                          color: "black",
                          fontSize: "22px",
                          fontWeight: 600,
                        }}
                      >
                        Forgot Password?
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "16px",
                          color: "#bcbfc7",
                          fontSize: "15px",
                        }}
                      >
                        To get OTP please select one of the option to change
                        password.
                      </p>
                    </div>
                    <div>
                      <div
                        style={{
                          margin: "50px 0px",
                          // border: "2px dotted #e3e3e3",
                          borderRadius: "5px",
                        }}
                      >
                        {this.state?.mobileNumber ? (
                          <>
                            <Card
                              onClick={() => this.sendOTPTo("phone")}
                              id="verificationCodePhone"
                            >
                              <CardHeader
                                avatar={
                                  // <Avatar
                                  //   fontSize="small"
                                  //   sx={{ bgcolor: "#d0e2fb" }}
                                  //   className={classes.image}
                                  //   src={forgot_mobile}
                                  // />
                                  <IconButton sx={{ bgcolor: "#d0e2fb" }}>
                                    <PhoneAndroidIcon
                                      sx={{ color: "primary.main" }}
                                    />
                                  </IconButton>
                                }
                                action={
                                  <IconButton aria-label="settings">
                                    <img
                                      src={
                                        this.state?.send_OTP_to_mobile
                                          ? orange_on
                                          : orange_off
                                      }
                                      alt="orange_on"
                                    />
                                  </IconButton>
                                }
                                title="Get verification code at"
                                subheader={`${
                                  this.state?.phoneNumberISD
                                }  ${maskString(this.state?.phoneNumber)}`}
                              />
                            </Card>
                            {/* <hr style={{ border: "1px solid #e3e3e3" }} /> */}
                          </>
                        ) : (
                          <></>
                        )}
                        <br />

                        {this.state?.emailId ? (
                          <Card
                            onClick={() => this.sendOTPTo("email")}
                            id="verificationCodeEmail"
                          >
                            <CardHeader
                              avatar={
                                // <Avatar
                                //   sx={{ bgcolor: "#d0e2fb" }}
                                //   className={classes.image}
                                //   src={forgot_mail}
                                //   fontSize={"small "}
                                // />
                                <IconButton sx={{ bgcolor: "#d0e2fb" }}>
                                  <MailOutlineIcon
                                    sx={{ color: "primary.main" }}
                                  />
                                </IconButton>
                              }
                              action={
                                <IconButton aria-label="settings">
                                  <img
                                    src={
                                      this.state?.send_OTP_to_mobile
                                        ? orange_off
                                        : orange_on
                                    }
                                    alt="orange_off"
                                  />
                                </IconButton>
                              }
                              title="Get verification code at"
                              subheader={maskEmail(this.state?.emailId)}
                            />
                          </Card>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className={classes.buttonStyle}>
                      <Button
                        id="sendOtpBtnFromForgotPassword"
                        style={{
                          width: "100%",
                          margin: "20px",
                          textTransform: "none",
                        }}
                        variant="contained"
                        disabled={this.state.loading}
                        onClick={() => this.sendOPT_to_specfic()}
                      >
                        {this.state.loading ? `Sending ` : ` Send OTP`}
                      </Button>
                    </div>
                    {/* </OnboardFormCard> */}
                  </Grid>
                )}
                {this.state?.verifyOTP === true && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{
                      background: "white",
                      padding: "30px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <OnboardFormCard> */}
                    <Hidden mdUp>
                      <Box sx={{ margin: "auto", width: "50%" }}>
                        <img
                          src={forgot_password_3}
                          style={{ width: "150px" }}
                        />
                      </Box>
                    </Hidden>
                    <OTPVerification
                      mobileNumber={this.state?.otp_to}
                      route={"/signin"}
                      otpverify={() => this.otpverify()}
                      changeOtpTo={() => this.changeSentOTPTo()}
                      resendOtp={() => this.sendOPT_to_specfic()}
                      buttonName={"Back to Sign In"}
                      handleOtpChange={this.handleOtpChange}
                      {...this.state}
                    />
                    {/* </OnboardFormCard> */}
                  </Grid>
                )}
                {this.state?.changePassword === true && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{
                      background: "white",
                      padding: "30px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <OnboardFormCard> */}
                    <Hidden mdUp>
                      <Box sx={{ margin: "auto", width: "50%" }}>
                        <img
                          src={forgot_password_4}
                          style={{ width: "150px" }}
                        />
                      </Box>
                    </Hidden>
                    <ChangePassword
                      header={"Change Password"}
                      buttonName={"Continue"}
                      confirmChangePaasword={this.changePassword}
                      handleInputChange={this.changeState}
                      {...this.state}
                    />
                    {/* </OnboardFormCard> */}
                  </Grid>
                )}
                {this.state?.confirmation === true && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{
                      background: "white",
                      padding: "30px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <OnboardFormCard> */}
                    <div style={{ textAlign: "center" }}>
                      <Hidden mdUp>
                        <Box sx={{ margin: "auto", width: "50%" }}>
                          <img
                            src={forgot_password_5}
                            style={{ width: "150px" }}
                          />
                        </Box>
                      </Hidden>
                      <div
                        style={{
                          marginTop: 48,
                          padding: 20,
                        }}
                      >
                        <img
                          src={TickIcon}
                          alt="tick"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                      <p
                        style={{
                          // margin: "80px 30px",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        Your password has been changed successfully
                      </p>
                    </div>
                    <div className={classes.buttonStyle}>
                      <Button
                        id="backToSignInBtn"
                        style={{
                          width: "100%",
                          textTransform: "none",
                          margin: "40px 20px",
                        }}
                        variant="contained"
                        onClick={() => this.backtoLogin()}
                        sx={{
                          marginTop: { xs: "20px", sm: "20px", md: "14px" },
                        }}
                      >
                        Back to Sign In
                      </Button>
                    </div>
                    {/* </OnboardFormCard> */}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>

        {/* OTP VERIFICATION */}
        {/* {this.state.verifyOTP && (
          <OTPVerification
            mobileNumber={"+9791630322"}
            route={"/signin"}
            otpverify={() => this.otpverify()}
            buttonName={"Back to Sign In"}
            handleOtpChange={this.handleOtpChange}
            {...this.state}
          />
        )} */}
        {/* CHANGE PASSWORD */}
        {/* {this.state.changePassword && (
          <ChangePassword
            header={"Change Password"}
            buttonName={"Continue"}
            confirmChangePaasword={this.changePassword}
            handleInputChange={this.changeState}
            {...this.state}
          />
        )} */}
        {/* CONFIRMATION PAGE */}
        {/* {this.state.confirmation && (
          <SuccessComponent
            message={"Your password has been changed successfully"}
            route={"/signin"}
            handleRoute={() => this.handleRoute()}
            buttonName={"Back to Sign In"}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  get_otp_by_user_id: state?.loginSlice?.getOTPByUserId,
  // verifyOtpRegisteredNumber: state?.loginSlice?.verifyOtpRegisteredNumber,
  otp_verify: state?.loginSlice?.OTPVerify,
  change_password: state?.loginSlice?.changePassword,
});

// export default withStyles(styles)(
//   connect(mapStateToProps, actions)(withRouter(ForgotPassword))
// );

export default connect(
  mapStateToProps,
  actions
)(withSnackBar(withRouter(withStyles(styles)(ForgotPassword))));
