import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: 100,

    // "& .MuiAvatar-root": {
    //   height: 96,
    //   width: 96,
    //   background: theme.palette.common.white,
    //   [theme.breakpoints.down("sm")]: {
    //     height: 96,
    //     width: 96,
    //     margin: "auto",
    //   },
    // },
  },
  errorRoot: {
    display: "flex",
    "& .MuiAvatar-root": {
      width: 160,
      background: theme.palette.secondary.light,
      [theme.breakpoints.down("sm")]: {
        width: 120,
        margin: "auto",
      },
    },
  },
  cameraContainer: {
    textAlign: "center",
    cursor: "pointer",
    "& svg": {
      fill: theme.palette.secondary.main,
    },
  },
  textContainer: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: 5,
    "& p": {
      color: theme.palette.text.label,
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiTypography-body1": {
        fontSize: "0.6rem",
      },
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
  },
}));
