import * as React from "react";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    stroke: (props) => props.htmlColorSecondary,
  },
  cls2: {
    stroke: (props) => props.htmlColorPrimary,
  },
}));

function EmailDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        data-name="email icon"
      >
        <path
          d="m20.749 6.482-8.875 6.459-8.875-6.459"
          className={classes.cls1}
        />
        <path
          d="M4.774 4.25h14.2a1.833 1.833 0 0 1 1.775 1.875v11.25a1.833 1.833 0 0 1-1.775 1.875h-14.2a1.833 1.833 0 0 1-1.775-1.875V6.125A1.833 1.833 0 0 1 4.774 4.25Z"
          className={classes.cls2}
        />
      </g>
    </SvgIcon>
  );
}

EmailDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default EmailDuoToneIcon;
