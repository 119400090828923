import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useOtpInputStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    gap: 10,
  },
  input: {
    width: "2rem !important",
    height: "2.5rem",
    borderRadius: 4,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    // borderBottom: `1.6px solid #D3DADD`,
    borderBottom: `1.6px solid #0063E7`,
    // border: `1.6px solid ${theme.palette.ternary.main}`,
    fontSize: "20px",
  },
  input_: {
    width: "3rem !important",
    height: "2.5rem",
    borderRadius: 4,
    //borderTop: 0,
    //borderRight: 0,
    //borderLeft: 0,
    border: `1.6px solid #f8b053`,
    fontSize: "20px",
    marginTop:"14px",
    background:"#f8f9fb"
  },
  focus: {
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderBottom: `1.6px solid #0063E7`,
    outline: 0,
    borderRadius: 4
  },
  focus_: {
    //borderTop: 0,
    //borderRight: 0,
    //borderLeft: 0,
    border: "1.6px solid #f8b053",
    marginTop: "14px",
    borderRadius: 4
  },
  error: {
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderBottom: `1.6px solid ${theme.palette.error.main}`,
  },
}));

function CustomOtpInput(props) {
  const classes = useOtpInputStyles();
  return (
    <>
      <OtpInput
        value={props.otp}
        onChange={props.handleOtpChange}
        numInputs={props.otpSize}
        //   separator={""}
        containerStyle={classes.container}
        inputStyle={props.customInput ? classes.input_ : classes.input}
        focusStyle={props.customInput ? classes.focus_ : classes.focus}
        errorStyle={classes.error}
        hasErrored={props.hasOtpErrored}
        shouldAutoFocus
      />
      {props.hasOtpErrored && (
        <FormHelperText error sx={{ mt: 1.5, textAlign: "center" }}>
          Please enter a valid OTP
        </FormHelperText>
      )}
    </>
  );
}

CustomOtpInput.propTypes = {
  handleOtpChange: PropTypes.func,
  hasOtpErrored: PropTypes.bool,
  otp: PropTypes.string,
  otpSize: PropTypes.number,
};

export default CustomOtpInput
