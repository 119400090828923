import PropTypes from "prop-types";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { DrawerSkeleton } from "../../../components";
import { CreateIcon, DeleteIcon } from "../../../assets";
import { getFormattedAddress } from "../../../utils";

function ViewAllAddress(props) {
  const {
    value,
    handleClose,
    handleEditClick,
    handldeDeleteClick,
    takeAddressDataFromRootObject,
    hideActionButtons,
    drawerSkeletonProps
  } = props;

  return (
    <DrawerSkeleton
      // title={"Address Details"}
      showButton={false}
      handleClose={handleClose}
      gutters={false}
      parentID="FromViewAllAddress"
      renderTitle={() => (
        <Typography
            sx={{
              flex: 1,
              textAlign: "center",
              ml: drawerSkeletonProps?.secondaryButtonText ? "0px" : "-44px",
              fontSize: "20px",
              fontWeight: 500
            }}
          >
            Address Details
          </Typography>
      )}
      {...drawerSkeletonProps}
    >
      {value?.map((val) => (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ py: 1, px: 4 }}
          >
            <Stack>
              <Typography sx={{fontSize: "14px", mb: 1}} color="textSecondary">{val?.address_type?.label}</Typography>
              <Typography sx={{fontSize: "16px"}}>
                {takeAddressDataFromRootObject
                  ? getFormattedAddress(val)
                  : getFormattedAddress(val?.fieldState) ?? "-"}
              </Typography>
            </Stack>
            {!hideActionButtons && <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              // sx={{ mb: 2 }}
            >
              <IconButton onClick={() => handleEditClick(val)}>
                <CreateIcon fontSize="small" sx={{fontSize: "16px"}} id={`edit${val?.id}`} />
              </IconButton>
              <IconButton onClick={() => handldeDeleteClick(val)}>
                <DeleteIcon fontSize="small" sx={{fontSize: "16px"}} id={`delete${val?.id}`} />
              </IconButton>
            </Stack>}
          </Stack>
          <Divider />
        </>
      ))}
    </DrawerSkeleton>
  );
}

ViewAllAddress.propTypes = {
  value: PropTypes.array,
};

export default ViewAllAddress;
