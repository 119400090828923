function stringToColor(string) {
  if (string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
}

export function stringAvatar(name) {
  return {
    // sx: {
    //   bgcolor: (theme) => stringToColor(name) ?? theme.palette.grey.border,
    // },
    // Takes first letter of firstname & lastname and capitalize it. ("John Doe" -> "JD")
    children: `${name.toString().trim().split(" ")[0][0]?.toUpperCase()}${name.toString().trim().split(" ").length > 1 ? name.toString().trim().split(" ")[1][0]?.toUpperCase() : ""}`,
  };
}
