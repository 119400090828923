import * as React from "react";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import {
  Stack,
  Box,
  Grid,
  Paper,
  styled,
  Typography,
  Divider,
  Tooltip,
  Skeleton,
} from "@mui/material";
import {
  currencyPriceFormatter,
  textClampStyleGenerator,
} from "../../../utils";
import CustomChip from "../../chip";
import CustomAvatar from "../../avatar";
import { alpha, IconButton } from "@mui/material";
import CustomButton from "../../button";
import CancelIcon from "@mui/icons-material/Cancel";
import { grey, pink, red } from "@mui/material/colors";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ServiceIcon from "../../../assets/icons/serviceIcon";
import PackageIcon from "../../../assets/icons/packageIcon";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import CustomAvatarWithText from "../../avatar/CustomAvatarWithText";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "10px 16px 0px 16px",
  position: "relative",
  boxShadow: "0 15px 14px 0 rgba(17, 18, 69, 0.03);",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.grey.darkBorder}`,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 1rem 5px 1rem",
    // width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    // background: "white",
    margin: theme.spacing(0, 1, 0, 1),
  },
  cartAdded: {
    backgroundColor: `${alpha(theme.palette.success.main, 0.25)} !important`,
    borderColor: `${alpha(theme.palette.success.main, 0.45)} !important`,
    color: `${theme.palette.success.main} !important`,
  },
  cartButton: {
    // color: `${theme.palette.enquiry.viewQuotes} !important`,
    borderColor: `${theme.palette.enquiry.viewQuotes} !important`,
  },
}));

function WishlistCard({ data, ...props }) {
  const classes = useStyles();
  const location = useLocation();
  return (
    <StyledPaper>
      {/* <Tooltip title={"Remove from Wishlist"} arrow>
        <IconButton
          sx={{
            fontSize: "15px",
            position: "absolute",
            top: "-2%",
            right: "0%",
          }}
          onClick={() => props.handleAddRemoveWishList(data?.id)}
        >
          <CancelIcon sx={{ fontSize: "18px", color: red[400] }} />
        </IconButton>
      </Tooltip> */}
      <Grid container columnSpacing={2}>
        <Grid item container>
          <Grid
            item
            container
            id={`${props?.from}Card${props?.id}`}
            sx={{ py: 1.5, flexWrap: "nowrap" }}
            onClick={(e) => props?.handleCardClick(data?.id, data?.versionId)}
          >
            <Grid item>
              {/* <Stack> */}
              {/* <CustomAvatar
                  loading={props?.loading}
                  src={data?.image}
                  variant={"rounded"}
                  width={53}
                  height={53}
                  // sx={{borderRadius: "6px 6px 0px 0px"}}
                />
                {!props?.loading && (
                  <CustomChip
                    label={data?.type}
                    type={data?.type}
                    sx={{ mt: 0, fontStyle: "normal" }}
                    //,border:0, borderRadius: "0px 0px 4px 4px"
                  />
                )} */}
              <CustomAvatarWithText
                loading={props?.loading}
                src={data?.image}
                variant={"rounded"}
                width={"90px"}
                height={"100px"}
                chip={{
                  label: data?.type,
                  type: data?.type,
                }}
              />
              {/* </Stack> */}
            </Grid>
            <Grid item xs sx={{ position: "relative", pl: 1 }}>
              {props?.loading && <Skeleton width="60%" />}
              {!props?.loading && (
                <>
                  <Stack flexDirection="column" gap={1.5}>
                    <Tooltip title={data?.name} arrow>
                      <Typography
                        sx={{
                          ...textClampStyleGenerator(1),
                          pl: 1,
                          fontWeight: "bold",
                        }}
                      >
                        {data?.name}
                      </Typography>
                    </Tooltip>
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="body2"
                        color="secondary"
                        sx={{ pl: 1, fontWeight: "bold" }}
                      >
                        {currencyPriceFormatter(data?.currency, data?.discount)}
                      </Typography>
                      {data?.price && data?.price !== data?.discount && (
                        <Typography
                          variant="body2"
                          style={{ paddingLeft: 10, paddingTop: 2 }}
                          color="textSecondary"
                          sx={{
                            textDecoration: "line-through",
                            fontSize: "12px",
                            alignItems: "flex-end",
                          }}
                        >
                          {currencyPriceFormatter(data?.currency, data?.price)}
                        </Typography>
                      )}
                    </div>
                    <>
                      {data?.service_count > 0 || data?.package_count > 0 ? (
                        <Stack
                          flexDirection="row"
                          divider={
                            <Divider
                              orientation="vertical"
                              sx={{ color: "black" }}
                            />
                          }
                          gap={1}
                          sx={{}}
                        >
                          {data?.package_count > 0 && (
                            <>
                              {/* <CustomChip
                            label={`${
                              data?.package_count <= 5
                                ? data?.package_count
                                : data?.package_count + "+"
                            } ${
                              data?.package_count > 1 ? "Packages" : "Package"
                            }`}
                            icon={<PackageIcon fontSize={"large"} />}
                            sx={{
                              fontStyle: "normal",
                              color: "text.primary",
                              border: 0,
                            }}
                          /> */}
                              <Stack flexDirection="row">
                                <PackageIcon
                                  sx={{ fontSize: "1.8rem", mt: "3px" }}
                                />
                                <Typography
                                  sx={{
                                    marginTop: "0.4rem",
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                    pl: 1,
                                  }}
                                >
                                  <b>{`${
                                    data?.package_count <= 5
                                      ? data?.package_count
                                      : data?.package_count + "+"
                                  }`}</b>
                                  {` ${
                                    data?.package_count > 1
                                      ? "Packages"
                                      : "Package"
                                  }`}
                                </Typography>
                              </Stack>
                            </>
                          )}
                          {data?.service_count > 0 && (
                            <>
                              {/* <CustomChip
                            label={`${
                              data?.service_count <= 5
                                ? data?.service_count
                                : data?.service_count + "+"
                            } ${
                              data?.service_count > 1 ? "Services" : "Service"
                            }`}
                            icon={<ServiceIcon fontSize={"large"} />}
                            sx={{
                              fontStyle: "normal",
                              color: "text.primary",
                              border: 0,
                            }}
                          /> */}
                              <Stack flexDirection="row">
                                <ServiceIcon sx={{ fontSize: "2rem" }} />
                                <Typography
                                  sx={{
                                    marginTop: "0.4rem",
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                    pl: 1,
                                  }}
                                >
                                  <b>{`${
                                    data?.service_count <= 5
                                      ? data?.service_count
                                      : data?.service_count + "+"
                                  }`}</b>
                                  {` ${
                                    data?.service_count > 1
                                      ? "Services"
                                      : "Service"
                                  }`}
                                </Typography>
                              </Stack>
                            </>
                          )}
                        </Stack>
                      ) : (
                        // <div style={{ height: "32px", }}></div>
                        <Stack flexDirection="row">
                          <ServiceIcon sx={{ fontSize: "2rem" }} />
                          <Typography
                            sx={{
                              marginTop: "0.4rem",
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              pl: 1,
                            }}
                          >
                            <b>{`1`}</b>
                            {`Service`}
                          </Typography>
                        </Stack>
                      )}
                    </>
                  </Stack>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider light={true} />
            <Stack
              flexDirection="row"
              justifyContent="center"
              gap={3}
              sx={{ py: 2 }}
            >
              <CustomButton
                onClick={() =>
                  props?.handleAddRemoveWishList(data?.id, data?.isWishlist)
                }
                id={`removeButton${props?.id}`}
                loading={props?.loading}
                fullWidth={data?.isExpired ? false : true}
                size={"small"}
                variant="outlined"
                startIcon={
                  !data?.isWishlist ? (
                    <FavoriteBorderOutlinedIcon sx={{ color: "#FF4256" }} />
                  ) : location.pathname === "/wishlist" ? (
                    <></>
                  ) : (
                    <FavoriteIcon sx={{ color: "#FF4256" }} />
                  )
                }
                sx={{
                  color: "#FF4256",
                  width: data?.isExpired ? "30%" : "100%",
                  border: 0,
                  // borderColor: pink[500],
                  backgroundColor: "rgb(255,66,86, 0.1)",
                  // padding:"0px !important",
                  textTransform: "capitalize",
                  // padding: "10px 0px",
                  "&:hover": {
                    border: 0,
                    backgroundColor: "rgb(255,66,86, 0.1)",
                    // content:"Remove",
                  },
                }}
              >
                {data?.isWishlist
                  ? location.pathname === "/wishlist"
                    ? "Remove"
                    : "Added"
                  : "Wishlist"}
              </CustomButton>
              {data?.isExpired ? (
                <Typography
                  variant={"body2"}
                  textAlign={"center"}
                  sx={{ color: "red", width: "70%", padding: "3px 0px" }}
                >{`This ${data?.type?.toLowerCase()} is unavailable`}</Typography>
              ) : (
                <>
                  <CustomButton
                    onClick={(e) => props?.handleEnquiry(data)}
                    loading={props?.loading}
                    fullWidth
                    id={`enquireButton${props?.id}`}
                    size={"small"}
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    sx={{
                      color: "#0063E7",
                      border: 0,
                      fontWeight: "normal",
                      // padding:"0px !important",
                      // borderColor: pink[500],
                      backgroundColor: "rgba(97,161,245,0.16)",
                      textTransform: "capitalize",
                      "&:hover": {
                        border: 0,
                        backgroundColor: "rgba(97,161,245,0.16)",
                      },
                    }}
                  >
                    Enquire Now
                  </CustomButton>
                  <CustomButton
                    onClick={(e) => props?.handleAddToCart(data?.id)}
                    id={`AddtoCartButton${props?.id}`}
                    disabled={data?.isAddedToCart}
                    fullWidth
                    className={
                      data?.isAddedToCart
                        ? classes.cartAdded
                        : classes.cartButton
                    }
                    loading={props?.loading}
                    size={"small"}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {data?.isAddedToCart ? `Added` : `Add to Cart`}
                  </CustomButton>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default WishlistCard;
