import * as React from "react";
import { SvgIcon, useTheme } from "@mui/material";

function InfoIcon(props) {
  const theme = useTheme();
  const getStrokeColor = (color, htmlColor) => {
    if (htmlColor) {
      return htmlColor;
    } else {
      switch (color) {
        case "primary":
          return theme.palette.primary.main;
        case "secondary":
          return theme.palette.secondary.main;
        case "success":
          return theme.palette.success.main;
        case "error":
          return theme.palette.error.main;
        default:
          return theme.palette.primary.main;
      }
    }
  };

  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13 17h-2v-6h2v6zm0-8h-2V7h2v2z" />
      <path
        fill="none"
        stroke={getStrokeColor(props.color, props.htmlColor)}
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M12 3a9 9 0 100 18 9 9 0 100-18z"
      />
    </SvgIcon>
  );
}

export default InfoIcon;
