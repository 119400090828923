import { SvgIcon } from "@mui/material";
import * as React from "react";

function TrackerIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      htmlColor="transparent"
      stroke={props.color ?? "rgba(0, 0, 0, 0.54)"}
      {...props}
    >
      <path
        d="M4 12.336h3.665L10.25 5l4.666 14.79 2.383-7.454H20.8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default TrackerIcon;
