import PropTypes from "prop-types";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { DrawerSkeleton } from "../../../components";
import { CreateIcon, DeleteIcon } from "../../../assets";

function ViewAllEmailType(props) {
  const {
    value,
    handleClose,
    handleEditClick,
    handldeDeleteClick,
    hideActionButtons,
    drawerSkeletonProps
  } = props;

  return (
    <DrawerSkeleton
      // title={"Email Details"}
      showButton={false}
      handleClose={handleClose}
      gutters={false}
      renderTitle={() => (
        <Typography
          sx={{
            flex: 1,
            textAlign: "center",
            ml: drawerSkeletonProps?.secondaryButtonText ? "0px" : "-44px",
            fontSize: "20px",
            fontWeight: 500
          }}
        >
          Email Details
        </Typography>
      )}
      {...drawerSkeletonProps}
    >
      {value?.map((val) => (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ py: 1, px: 4 }}
          >
            <Stack>
              <Typography sx={{ fontSize: "14px", mb: 1 }} color="textSecondary">
                {val?.email_type?.label ? val?.email_type?.label : "-"}
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>{val?.email ? val?.email : "-"}</Typography>
            </Stack>
            {!hideActionButtons && (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              // sx={{ mb: 2 }}
              >
                <IconButton onClick={() => handleEditClick(val)}>
                  <CreateIcon fontSize="small" sx={{fontSize: "16px"}} />
                </IconButton>
                <IconButton onClick={() => handldeDeleteClick(val)}>
                  <DeleteIcon fontSize="small" sx={{fontSize: "16px"}} />
                </IconButton>
              </Stack>
            )}
          </Stack>

          <Divider />
        </>
      ))}
    </DrawerSkeleton>
  );
}

ViewAllEmailType.propTypes = {
  value: PropTypes.array,
};

export default ViewAllEmailType;
