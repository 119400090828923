import PropTypes from "prop-types";
import React from "react";
import { styled } from "@mui/material";
import Chip from "@mui/material/Chip";
import { SubscriptionType } from "../../utils";

const getTypeColor = (type) => {
  if (type) {
    const selected = SubscriptionType.filter(
      (subType) => subType.label === type
    );
    return {
      backgroundColor: selected?.length > 0 ? selected?.[0]?.color : "",
      color: selected?.length > 0 ? "white" : "",
    };
  }
  return {};
};

const CustomizedChip = styled(Chip, {
  shouldForwardProp: (prop) => !["type", "style"].includes(prop),
})(({ theme, type, style={} }) => ({
  height: "22px",
  borderRadius: "4px",
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.grey[300]}`,
  fontStyle: "italic",
  "& .MuiChip-label": {
    paddingLeft: 6,
    paddingRight: 6,
  },
  ...getTypeColor(type),
  ...style
}));

function CustomChip(props) {
  return (
    <CustomizedChip label={props.label} variant={props.variant} color={props.color} {...props} />
    // <CustomizedChip
    //   sx={{
    //     height: "22px",
    //     borderRadius: "4px",
    //     color: (theme) => theme.palette.text.secondary,
    //     border: (theme) =>  `1px solid ${theme.palette.grey[300]}`,
    //     fontStyle: "italic",
    //     "& .MuiChip-label": {
    //       paddingLeft: 0.75,
    //       paddingRight: 0.75,
    //     },
    //     ...getTypeColor(props.type),
    //   }}
    //   label={props.label}
    // />
  );
}

CustomChip.propTypes = {
  /**
   * The Label of the chip.
   */
  label: PropTypes.string.isRequired,

  /**
   * Variant of the chip.
   */
  variant: PropTypes.string,

  /**
   * Color of the chip.
   */
  color: PropTypes.string,
};

CustomChip.defaultProps = {
  variant: "outlined",
};

export default CustomChip;
