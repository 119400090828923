import PropTypes from "prop-types";
import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const MyToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: 16,
  flexWrap: "wrap",
  "& .MuiToggleButton-root": {
    minWidth: 80,
    textTransform: "unset",
    "&.Mui-selected": {
      background: theme.palette.secondary.main,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
      "&:hover": {
        background: theme.palette.secondary.dark,
      },
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    background: "#f5f5f5",
    "&:not(:last-of-type), &:not(:first-of-type)": {
      border: 0,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
}));

const RadioStyleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: 16,
  flexWrap: "wrap",
  "& .MuiToggleButton-root": {
    minWidth: 60,
    padding: 18,
    textTransform: "unset",
    background: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.grey[200],
    },
    "&.Mui-selected": {
      background: theme.palette.secondary.main,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
      "&:hover": {
        background: theme.palette.secondary.dark,
      },
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary,
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    "&:not(:last-of-type), &:not(:first-of-type)": {
      border: 0,
      border: `1px solid ${theme.palette.grey.darkBorder}`,
      borderRadius: "50%",
    },
  },
}));

const ToggleGroupStyleSelector = ({
  variant = "default",
  children,
  ...props
}) => {
  if (variant === "default") {
    return <MyToggleGroup {...props}>{children}</MyToggleGroup>;
  } else if (variant === "radio") {
    return <RadioStyleButtonGroup {...props}>{children}</RadioStyleButtonGroup>;
  }
};

function StyledToggleButtonGroup(props) {
  return (
    <Box {...props}>
      {props?.options && props?.options?.length > 0 ? (
        <Box>
          {props.label && (
            <InputLabel sx={{ mb: 1, ...props.labelSx }}>
              {props.label}
            </InputLabel>
          )}
          <ToggleGroupStyleSelector
            value={props.data?.value}
            exclusive
            onChange={props.onChange}
            variant={props.variant}
            size="small"
            // aria-label="">
          >
            {props.options.map((option, index) => {
              // console.log(JSON.stringify(option?.value))
              return (
                <ToggleButton
                  value={option.value}
                  key={index}
                  disabled={option.disabled}
                  aria-label={option.label}
                  // id={option?.value?.replace(/[,\s]/g,'-')}
                  id={`toggleBtn${option?.value}`}
                >
                  <Typography
                    sx={{ color: option.disabled && "action.disabled" }}
                    // color="disabled"
                  >
                    {option.label}
                  </Typography>
                </ToggleButton>
              );
            })}
          </ToggleGroupStyleSelector>
        </Box>
      ) : (
        <Typography>{props?.emptyText}</Typography>
      )}
    </Box>
  );
}

StyledToggleButtonGroup.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  data: PropTypes.object,
};

StyledToggleButtonGroup.defaultProps = {
  options: [],
};

export default StyledToggleButtonGroup;
