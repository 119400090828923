export const isObjectEmpty = (data) => {
  return data && Object.keys(data).length === 0 && data.constructor === Object;
};

export const isObject = (data) => {
  if (data === null) {
    return false;
  }
  return typeof data === "function" || typeof data === "object";
};

export const isDataEmpty = (data) => {
  if (Array.isArray(data)) {
    return data?.length <= 0;
  } else if (isObject(data)) {
    return isObjectEmpty(data);
  }
  return true;
};

// Function that takes an argument, Checks if the argument is an array or not.
// If the argument is an array, check if the length is greater than 0 and return that array.
// Else return an empty array.
export const isArrayEmpty = (data) => {
  return Array.isArray(data) && data.length > 0 ? data : [];
}
