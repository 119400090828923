import { SvgIcon } from "@mui/material"
import * as React from "react"

function HomeIcon(props) {
    return (
        <SvgIcon width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M12 4l-9 8.1h2.455v7.364h5.727v-4.91h1.636v4.909h5.727V12.1H21L12 4zm0 2.2l4.909 4.42v7.207h-2.454v-4.909h-4.91v4.909H7.091V10.62L12 6.2z"
            />
        </SvgIcon>
    )
}

export default HomeIcon
