import React from "react";
import { connect } from 'react-redux'
import {
  Divider,
  Grid,
  Typography,
  IconButton
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { actions } from "tanyacare-middleware"; 
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { ViewFormCustom } from "form-configurator"

// const { GET_ACTIVITY_LOGS } = actions;

const styles = (theme) => ({
  menulist: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: '#0000000a'
    }
  }
});

class ServiceNote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         data: [],
         form_data: {}
        };
      }
   async componentDidMount(){
        let data = await this.props?.GET_UI_JSON({appointment_id: this.props.id, isReport: true});
        
        this.setState({
            data: data?.payload?.data
        })
    }

    selectSection = (data) => {
      this.setState({...this.state, form_data: data})
    }
  render() {
    const { classes } = this.props
    return (
      <div>
        {/* {!this.state?.data && <NotFoundSection message="No data Found" />} */}

        {this.state?.data && (
          <>
            <Grid style={{ padding: "8px 6px ", display: "flex", alignItems: "center" }}>
              <Grid item>
                    <IconButton
                      onClick={this.props.handleClose}
                      id={`serviceNote${this?.props?.id}`}
                    >
                      <ChevronLeft />
                    </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h6"}>Service Note</Typography>
              </Grid>
            </Grid>

            <Divider light />
            {this.state?.form_data?.forms && 
                <ViewFormCustom xl={12} md={12} sm={12} xs={12} formDetails={this.state?.form_data??{}} />
            }
            {!this.state?.form_data?.forms && this.state?.data?.[0]?.formData?.map(val => {
              return <>
            <Grid className={classes.menulist} style={{ padding: "8px 20px", display: "flex", alignItems: "center",  }} id={`menu-list-${this?.props?.id}`} onClick={()=>this.selectSection(val)}>
              <Grid item xs={12}>
                <Typography variant={"body1"}>{val?.form_details?.templateName}</Typography>
              </Grid>
              <Grid item>
                  <ChevronRight />
              </Grid>
            </Grid>
            <Divider light /></>
            })}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    logs: state?.activity_app?.logs
  });

export default connect(
    mapStateToProps,
    actions
  )(withStyles(styles)(ServiceNote));
