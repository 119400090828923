import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { SectionHeader } from ".";
import {
  WeightIcon,
  HeightIcon,
  //   BmiIcon,
  BmiBodyIcon,
  BloodgroupIcon,
} from "../../../../assets";
import moment from "moment";
import { isArrayEmpty } from "../../../../utils";
import Button from "@mui/material/IconButton";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { withDialog } from "../../../../HOC's";
import purify from "dompurify";
import { NoDataHandler } from "../../../../components";
import { styled } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

const LabelTypography = styled(Typography)({
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  fontSize: "14px",
});

function IconAvatar({ icon, avatarBg }) {
  if (icon)
    return (
      <Avatar
        variant="rounded"
        sx={{ width: 30, height: 30, bgcolor: avatarBg }}
      >
        {icon}
      </Avatar>
    );

  return "";
}

function HealthInformationRow({
  icon,
  avatarBg,
  label,
  value,
  lastUpdated,
  loading,
}) {
  return (
    <Stack flexDirection="row" gap={2}>
      <IconAvatar icon={icon} avatarBg={avatarBg} />

      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flex: 1,
        }}
      >
        <Stack>
          <Typography fontWeight="400">{label ? label : "User Id"}</Typography>
          {lastUpdated && (
            <Typography
              variant="caption"
              color="textSecondary"
              fontWeight="400"
            >
              {moment(lastUpdated).isValid()
                ? `Last Updated on ${lastUpdated}`
                : lastUpdated}
            </Typography>
          )}
        </Stack>
        {loading ? (
          <Skeleton variant="text" width={"50%"} />
        ) : (
          <Typography fontWeight="500">{value ? value : "-"}</Typography>
        )}
      </Stack>
    </Stack>
  );
}

function MedicalHistory({ icon, avatarBg, label, value, onClick, loading }) {
  const valueWithoutHtmlTags = value?.trim()?.replace(/<[^>]*>/g, "");
  const limit = 70;
  return (
    <Stack flexDirection="row" gap={2} sx={{ cursor: "pointer" }}>
      <IconAvatar icon={icon} avatarBg={avatarBg} />

      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          flex: 1,
        }}
      >
        <Typography fontWeight="400">{label ? label : "Untitled"}</Typography>

        {/* <IconButton size="small" disableRipple>
          <ChevronRightIcon sx={{ color: "text.secondary" }} />
        </IconButton> */}
        {loading ? (
          <Skeleton variant="text" width={"50%"} />
        ) : (
          <>
            {valueWithoutHtmlTags ? (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: purify.sanitize(
                      `<div style="font-size: 14px;">${
                        valueWithoutHtmlTags
                          .slice(0, limit)
                          .concat(
                            valueWithoutHtmlTags?.length > limit ? "..." : ""
                          ) ?? "You don't have any medical history yet"
                      }</div>`
                    ),
                  }}
                ></div>
                {valueWithoutHtmlTags?.length > limit ? (
                  <Box
                    sx={{ ml: "auto", color: "#F3AB40", cursor: "pointer" }}
                    onClick={() => onClick(value)}
                  >
                    view All
                  </Box>
                ) : (
                  ``
                )}
              </>
            ) : (
              <Typography fontWeight="500">Not updated</Typography>
            )}
          </>
        )}

        {/* {!loading && <Button
          color="secondary"
          size="small"
          sx={{ textTransform: "unset", color: "#F3AB40", fontSize: "14px", fontWeight: 400 }}
          disableRipple
        >
          View
        </Button>} */}
      </Stack>
    </Stack>
  );
}

function SpecialPrecaution({
  icon,
  avatarBg,
  label,
  value = [],
  loading,
  onClick = () => {},
}) {
  return (
    <Stack flexDirection="row" gap={2}>
      <IconAvatar icon={icon} avatarBg={avatarBg} />

      <Stack sx={{width: "100%"}}>
        <Typography fontWeight="400">{label ? label : "Untitled"}</Typography>
        {loading ? (
          <Skeleton variant="text" width={"50%"} />
        ) : value?.length > 0 ? (
          <Stack gap={0.5}>
            {value?.slice(0, 3)?.map((item) => (
              <Stack
                flexDirection="row"
                alignItems="center"
                gap={1.5}
                sx={{ ml: 0.25 }}
              >
                <Stack flexDirection="row" gap={0.5}>
                  <div>
                    <div>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: "0.65rem" }}
                      />
                    </div>
                  </div>
                  <LabelTypography>{item}</LabelTypography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography fontWeight="500">{"Not updated"}</Typography>
        )}
        {!loading && value?.length > 3 && (
          <Box
            sx={{ ml: "auto", color: "#F3AB40", cursor: "pointer" }}
            onClick={() => onClick(value)}
          >
            view All
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

function HealthInformation({ data, onEdit, loading, ...props }) {
  const handleViewMedicalHistory = (value) => {
    props.dialog.setDialog({
      open: true,
      title: "Medical History",
      renderContent: (
        <>
          {value ? (
            <div
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(
                  `<div  style="width: 100%; overflow-wrap: break-word;">${value}</div>`
                ),
              }}
            ></div>
          ) : (
            <Box>
              <NoDataHandler
                message={"You don't have any medical history"}
                fullWidth={"250px"}
                // fullheight={"300px"}
              />
            </Box>
          )}
        </>
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            minWidth: (theme) => ({ xs: "90%", sm: "80%", md: "50%" }),
          },
        },
      },
    });
  };

  const handleViewSpecialPrecaution = (value = []) => {
    props.dialog.setDialog({
      open: true,
      title: "Special Precautions",
      renderContent: (
        <List dense={false}>
          {value?.map((_, index) => (
            <ListItem sx={{pl: 1, pr: 0.5}}>
              <ListItemIcon sx={{ minWidth: "24px", alignSelf: "flex-start", mt: "4px" }}>
                <FiberManualRecordIcon
                  fontSize="small"
                  htmlColor="#605F5F"
                  sx={{ fontSize: "0.9rem" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={_}
                sx={{
                  my: 0,
                  "& .MuiListItemText-primary": { fontWeight: "400" },
                }}
              />
            </ListItem>
          ))}
        </List>
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            minWidth: (theme) => ({ xs: "90%", sm: "80%", md: "50%" }),
          },
        },
      },
    });
  };

  return (
    <Stack>
      <SectionHeader
        id={`editHealthInfoButtonMobile`}
        title="Health Information"
        buttonLabel={"Edit"}
        buttonHandler={() => onEdit()}
      />
      <Stack
        sx={{ bgcolor: "common.white", p: 2.5 }}
        gap={1.5}
        style={{
          boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
        }}
        divider={<Divider sx={{ borderColor: "#EAEAEA" }} />}
      >
        <HealthInformationRow
          icon={<WeightIcon fill="white" />}
          avatarBg={"#4A4AE2"}
          label="Weight"
          value={data?.weight ? `${data?.weight} kg` : ""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />
        <HealthInformationRow
          icon={<HeightIcon fill="white" stroke="white" />}
          avatarBg={"#31B198"}
          label="Height"
          value={data?.height ? `${data?.height} cm` : ""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />
        <HealthInformationRow
          icon={<BmiBodyIcon fill="white" />}
          avatarBg={"#F27D35"}
          label="BMI"
          value={data?.bmi ? data?.bmi : ""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />
        <HealthInformationRow
          icon={<BloodgroupIcon fill="white" stroke="white" />}
          avatarBg={"#E24A4A"}
          label="Blood Group"
          value={data?.bloodGroup ? data?.bloodGroup : ""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />
        <MedicalHistory
          icon={<BloodgroupIcon fill="white" stroke="white" />}
          avatarBg={"#4A8AE2"}
          label="Medical History"
          value={data?.medicalHistory}
          onClick={handleViewMedicalHistory}
          loading={loading}
        />
        <SpecialPrecaution
          icon={<BloodgroupIcon fill="white" stroke="white" />}
          avatarBg={"#F67280"}
          label="Special Precautions"
          value={data?.specialPrecautions ?? []}
          loading={loading}
          onClick={handleViewSpecialPrecaution}
          // value={Array(4).fill(
          //   "Lorem Ipsum is a dummy text in industry dummy text in industry"
          // )}
        />
      </Stack>
    </Stack>
  );
}

export default withDialog(HealthInformation);
