import * as React from "react"

export function DirectionsIcon(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <path
        fill="none"
        stroke="#9297a5"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M10 1a9 9 0 109 9 9 9 0 00-9-9z"
        data-name="Path 660"
      />
      <path
        fill="#9297a5"
        d="M10.676 9.324l-.724 2.009-.218-.367-.26-.439-.439-.26-.367-.218 2.008-.725M14 6L4 9.6l4.014 2.382L10.4 16 14 6z"
        data-name="Path 661"
      />
    </svg>
  )
}
