import { SvgIcon } from "@mui/material"
import * as React from "react"

function SettingsIcon(props) {
    return (
        <SvgIcon width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            htmlColor="transparent"
            stroke={props.color ?? "rgba(0, 0, 0, 0.54)"}
            {...props}>
            <path
                d="M11.551 14.599a2.65 2.65 0 110-5.3 2.65 2.65 0 010 5.3v0z"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
            <path
                d="M17.643 10.949a5.647 5.647 0 010 2l-.079.485 1.535 1.335-1.334 2.309-1.916-.661-.382.313a6.13 6.13 0 01-1.735 1l-.46.174-.388 2h-2.666l-.389-2-.46-.174a6.13 6.13 0 01-1.735-1l-.385-.312-1.918.661L4 14.77l1.535-1.335-.079-.485a6.308 6.308 0 01-.088-1c.003-.335.032-.67.089-1l.079-.485L4 9.129 5.334 6.82l1.915.661.382-.313a6.13 6.13 0 011.735-1L9.829 6l.389-2h2.665l.389 2 .46.174c.63.236 1.215.574 1.735 1l.382.313 1.918-.661 1.334 2.309-1.535 1.328.077.486z"
                strokeWidth={1.5}
                strokeMiterlimit={10}
            />
        </SvgIcon>
    )
}

export default SettingsIcon
