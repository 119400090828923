import PropTypes from "prop-types";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { DrawerSkeleton } from "../../../components";
import { CreateIcon, DeleteIcon } from "../../../assets";
import { StyledChip } from "./styledChip";

function ViewAllContact(props) {
  const {
    value,
    handleClose,
    handleEditClick,
    handldeDeleteClick,
    hideActionButtons,
    hidePrimary,
    drawerSkeletonProps,
  } = props;

  return (
    <DrawerSkeleton
      // title={"Contact Details"}
      showButton={false}
      handleClose={handleClose}
      gutters={false}
      parentID="FromViewAllContact"
      renderTitle={() => (
        <Typography
          sx={{
            flex: 1,
            textAlign: "center",
            ml: drawerSkeletonProps?.secondaryButtonText ? "0px" : "-44px",
            fontSize: "20px",
            fontWeight: 500
          }}
        >
          Contact Details
        </Typography>
      )}
      {...drawerSkeletonProps}
    >
      {value?.map((val) => (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ py: 1, px: 4 }}
          >
            <Stack>
              <Stack flexDirection="row" gap={1} alignItems="center" sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: "14px" }} color="textSecondary">
                  {val?.mobile_type?.label}
                </Typography>
                {!hidePrimary && val?.isPrimary && (
                  <StyledChip label="Primary" color="error" />
                )}
              </Stack>
              <Typography sx={{ fontSize: "16px" }}>
                {!val?.mobilenumberISDcode && !val?.mobilenumber
                  ? "-"
                  : `${val?.mobilenumberISDcode} ${val?.mobilenumber}`}
              </Typography>
            </Stack>
            {!hideActionButtons && (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              // sx={{ mb: 2 }}
              >
                <IconButton onClick={() => handleEditClick(val)}>
                  <CreateIcon fontSize="small" sx={{fontSize: "16px"}} id={`edit${val?.id}`} />
                </IconButton>
                <IconButton onClick={() => handldeDeleteClick(val)}>
                  <DeleteIcon fontSize="small" sx={{fontSize: "16px"}} id={`delete${val?.id}`} />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <Divider />
        </>
      ))}
    </DrawerSkeleton>
  );
}

ViewAllContact.propTypes = {
  value: PropTypes.array,
};

export default ViewAllContact;
