import PropTypes from "prop-types";
import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { MobileDatePicker } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";

const DateSlotToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: 16,
  flexWrap: "wrap",
  "& .MuiToggleButton-root": {
    minWidth: 80,
    textTransform: "unset",
    "&.Mui-selected": {
      background: theme.palette.secondary.main,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
      "&:hover": {
        background: theme.palette.secondary.dark,
      },
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    background: "#f5f5f5",
    "&:not(:last-of-type), &:not(:first-of-type)": {
      border: 0,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
}));

function DateSlotField(props) {
  // DatePicker States
  const [open, setOpen] = useState(false);
  const [customDate, setCustomDate] = useState(new Date());

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };
  // console.log(moment(customDate).toDate());
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        {props.label && <InputLabel sx={{ mb: 1 }}>{props?.label}</InputLabel>}
        <Typography color="primary">{props?.duration}</Typography>
      </Stack>
      <DateSlotToggleGroup
        value={props.data?.value}
        exclusive
        onChange={props.onChange}
        size="small"
        // aria-label=""
      >
        {Array.isArray(props.options) &&
          props?.options?.map((option) => (
            <ToggleButton value={option?.value} aria-label={option?.label} id={option?.value?.replace(/[,\s]/g,'-')}>
              <Typography>{option?.label}</Typography>
            </ToggleButton>
          ))}
        <Stack
          onClick={toggleOpen}
          justifyContent="center"
          sx={{
            p: 0.9,
            cursor: "pointer",
            background: "white !important",
            border: "1px solid #e5e5e5 !important",
            userSelect: "none",
            borderRadius: 0.75,
          }}
        >
          Select Date...
        </Stack>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            open={open}
            value={customDate}
            onChange={(newValue) => {
              setCustomDate(newValue);
            }}
            id="select-date-field"
            onClose={toggleOpen}
            onAccept={() => props.customDateHandler(customDate)}
            disablePast
            renderInput={(params) => <></>}
          />
        </LocalizationProvider>
      </DateSlotToggleGroup>
    </Box>
  );
}

DateSlotField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  data: PropTypes.object,
};

DateSlotField.defaultProps = {
  options: [],
};

export default DateSlotField;
