import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  PersonalDetails,
  FamilyMemberDetails,
  HealthInformation,
} from "./components";
import EditProfile from "../editProfile";
import EditFamilyMembers from "../editFamilyMembers";
import EditHealthInfo from "../editHealthInfo";
import { dontShowInMenuRoutes } from "../../../router/routes";

const initialBreadCrumbs = [
  {
    label: "Go Back to My Profile",
    link: dontShowInMenuRoutes.profile.pathName,
  },
];
function WebProfile({
  data,
  state: parentState,
  loading,
  dependencyOptions,
  handleUpdate = () => {},
  onCancel = () => {},
  changeEditScreenId = () => {},
  ...props
}) {
  const [familyData, setFamilyData] = useState();

  const handleEdit = (id) => {
    changeEditScreenId(id);
  };

  const handleFamilyEdit = (data) => {
    setFamilyData(data);
    changeEditScreenId(2);
  };

  const handleDelete = (id) => {
    let req_data = [...data?.familyMembers];
    let pop_data = req_data.filter((x) => x.id === id)?.[0];
    const index = req_data.indexOf(pop_data);
    if (index > -1) {
      req_data.splice(index, 1);
    }
    // req_data?.push(state);
    console.log(req_data);
    let res = {
      familyMembers: req_data,
    };
    handleUpdate(res);
  };

  switch (props.screenId) {
    case 0:
      return (
        <Grid container rowSpacing={1} columnSpacing={1} sx={{ mb: 2 }}>
          <Grid item sm={12}>
            <PersonalDetails
              data={data}
              loading={loading}
              handleEdit={handleEdit}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FamilyMemberDetails
              data={data?.familyMembers}
              loading={loading}
              handleAdd={handleEdit}
              handleEdit={handleFamilyEdit}
              handleDelete={handleDelete}
            />
          </Grid>
          <Grid item sm={12} md={6} alignSelf="stretch">
            <HealthInformation
              data={data}
              loading={loading}
              handleEdit={handleEdit}
            />
          </Grid>
        </Grid>
      );

    case 1:
      return (
        <EditProfile
          breadcrumbs={initialBreadCrumbs}
          resetScreen={onCancel}
          editData={data}
          handleUpdate={handleUpdate}
          dependencyOptions={dependencyOptions}
          loading={false}
        />
      );
    case 2:
      return (
        <EditFamilyMembers
          breadcrumbs={initialBreadCrumbs}
          resetScreen={onCancel}
          editData={familyData}
          data={data}
          dependencyOptions={dependencyOptions}
          handleUpdate={handleUpdate}
          loading={false}
        />
      );
    case 3:
      return (
        <EditHealthInfo
          breadcrumbs={initialBreadCrumbs}
          resetScreen={onCancel}
          dependencyOptions={dependencyOptions}
          data={parentState}
          handleUpdate={handleUpdate}
          loading={false}
        />
      );
    default:
      return "";
  }
}

export default WebProfile;
