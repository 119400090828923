import * as React from "react";

//package
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";
import { alpha } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import PersonIcon from "@mui/icons-material/Person";

import { CustomAvatar } from "../..";
import { AuthContext } from "../../../contexts";
import { useHistory, useLocation } from "react-router-dom";
import {
  CartIcon,
  LogoutIcon,
  NameIcon,
  NotificationIcon,
} from "../../../assets";
import { withDialog, withDrawer, withSnackBar } from "../../../HOC's";
import { drawerProps } from "../../../utils";
import Notification from "./notification";
import { dontShowInMenuRoutes } from "../../../router/routes";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomizedProfileButton = styled(Button)(({ theme }) => ({
  height: "2.25rem !important",
  alignItems: "center !important",
  color: theme.palette.common.black,
  borderRadius: 8,
  borderColor: "transparent",
  marginLeft: "0.50rem",
  padding: "0rem 0.50rem",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    // borderRadius: "0px !important",
    borderRadius: 8,
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: "0rem 0.25rem",
}));

const UserMenuContainer = styled(Paper)(({ theme }) => ({
  "&.MuiPaper-root": {
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.08)",
    padding: "6px 14px 6px 16px",
    borderRadius: 10,
  },
}));

const UserMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
    border: "1px solid rgba(224, 224, 224, 1)",
    marginTop: theme.spacing(1),
    minWidth: 180,
    // color:
    //   theme.palette.mode === "light"
    //     ? "rgb(55, 65, 81)"
    //     : theme.palette.grey[300],
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
    "& .MuiMenu-list": {
      paddingTop: "12px",
      paddingBottom: "12px",
      // paddingLeft: "6px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    "& .MuiMenuItem-root": {
      paddingLeft: "20px",
      // "& .MuiSvgIcon-root": {
      //   fontSize: 18,
      //   color: theme.palette.text.secondary,
      //   marginRight: theme.spacing(1.5),
      // },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const style = makeStyles((theme) => ({
  appBar: {
    // backgroundColor: `${theme.palette.primary.main} !important`,
    "&.MuiAppBar-root": {
      backgroundColor: theme.palette.background.appbar,
    },
    color: `${theme.palette.common.white} !important`,
    boxShadow: "none !important",
  },
  customIcon: {
    color: `${theme.palette.common.white} !important`,
  },
  topnavMenu: {
    alignItems: "center !important",
  },
  mobileIconStyle: {
    "&.MuiSvgIcon-root": {
      color: theme.palette.text.primary,
    },
  },
}));
const { LOGOUT_AUTH, CART_COUNT, GET_NOTIFICATION_COUNT } = actions;

function TopNav(props) {
  const classes = style();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  console.log(location);
  console.log(history);

  //context
  const authContext = React.useContext(AuthContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  //useSelector
  const profileData = useSelector((state) => state?.clientProfile?.getProfile);
  const cartCount = useSelector((state) => state?.clientCart?.cartCount);
  const notificationCount = useSelector((state) => state?.notification?.count);
  // alert(notificationCount?.data?.count)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileNavigation = () => {
    history.push(dontShowInMenuRoutes.profile.pathName);
  };

  const handleLogout = () => {
    authContext.setAuth({ ...authContext.auth, refresh: false });
    dispatch(LOGOUT_AUTH({}));
    localStorage.removeItem("_mGdRe");
  };

  const notificationsHandler = () => {
    handleMobileMenuClose();
    props.drawer.setDrawer({
      open: true,
      anchor: drawerProps.direction?.right,
      component: (handleClose) => <Notification history={history} handleClose={handleClose} />,
    });
  };

  const cartCounthandler = React.useCallback(() => {
    Promise.resolve(dispatch(CART_COUNT({}))).catch((err) => {
      console.log(err);
    });
    Promise.resolve(dispatch(GET_NOTIFICATION_COUNT({}))).catch((err) => {
      console.log(err);
    });
  }, [dispatch]);

  React.useEffect(() => {
    cartCounthandler();
  }, [cartCounthandler]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <UserMenu
      anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}
      id={menuId}
      keepMounted
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* LogoutIcon */}
      <MenuItem
        onClick={() => {
          handleProfileNavigation();
          handleMenuClose();
        }}
        id="menuProfileBtn"
      >
        <ListItemIcon>
          <NameIcon fill="#9297A5" sx={{ fontSize: "1.2rem" }} />
        </ListItemIcon>
        <ListItemText sx={{ fontWeight: "400" }} >My Profile</ListItemText>
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem
        onClick={() => {
          handleLogout();
          handleMenuClose();
        }}
        id="menuLogoutBtn"
      >
        <ListItemIcon>
          <LogoutIcon fontSize="small" sx={{ fontSize: "1.2rem" }} />
        </ListItemIcon>
        <ListItemText sx={{ fontWeight: "400" }}>Log out</ListItemText>
      </MenuItem>
    </UserMenu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMobileMenuClose();
          history.push("/cart");
        }}
      >
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={cartCount?.data?.count} color="success">
            {/* <ShoppingCartOutlinedIcon /> */}
            <CartIcon color="#252B43" />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>
      <MenuItem onClick={() => notificationsHandler()}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={notificationCount?.data?.count} color="success">
            {/* <NotificationsNoneOutlinedIcon /> */}
            <NotificationIcon color="#252B43" />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar sx={{ borderBottom: 1, borderColor: "grey.300" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
            className={classes.menuIconClass}
            id="mobileHamburgerIcon"
          >
            <MenuIcon className={classes.mobileIconStyle} />
          </IconButton>
          {/* <Hidden mdDown>
            {location.pathname === "/purchase" ? (
              <Box sx={{ flexGrow: 1 }}>
                <InputBase fullWidth placeholder={"Search"} />
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1 }} />
            )}
          </Hidden>
          <Hidden mdUp> */}
          <Box sx={{ flexGrow: 1 }} />
          {/* </Hidden> */}

          {/* Screens above sm will render this Box */}
          <Stack
            sx={{ display: { xs: "none", md: "flex" }, gap: 4 }}
            flexDirection="row"
            alignItems="center"
          >
            <Stack
              sx={{ gap: 1 }}
              flexDirection="row"
              alignItems="center"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: "26px",
                    margin: "auto 0px",
                    borderStyle: "dashed",
                  }}
                />
              }
            >
              <IconButton
                // size="small"
                id={`cartTopNav`}
                aria-label="go to cart"
                color="inherit"
                onClick={() => {
                  history.push("/cart");
                }}
              >
                <Badge
                  badgeContent={cartCount?.data?.count}
                  color="success"
                  overlap={"circular"}
                  sx={{
                    "& .MuiBadge-badge": { top: 1, right: 2, color: "white" },
                  }}
                >
                  <CartIcon htmlColor="#252B43" />
                </Badge>
              </IconButton>

              <IconButton
                aria-label="open notifications drawer"
                color="inherit"
                id={`notificationTopNav`}
                onClick={() => notificationsHandler()}
              >
                <Badge
                  color="success"
                  badgeContent={notificationCount?.data?.count}
                  overlap={"circular"}
                  sx={{
                    "& .MuiBadge-badge": { top: 1, right: 2, color: "white" },
                  }}
                >
                  <NotificationIcon color="#252B43" />
                </Badge>
              </IconButton>
            </Stack>

            <UserMenuContainer onClick={handleProfileMenuOpen}>
              <Stack
                flexDirection="row"
                gap={2}
                alignItems="center"
                sx={{ cursor: "pointer" }}
                // onClick={() =>
                //   history.push(dontShowInMenuRoutes.profile.pathName)
                // }
              >
                <Stack flexDirection="row" gap={1.5} alignItems="center">
                  <CustomAvatar
                    src={`${profileData?.data?.profilePic}`}
                    name={profileData?.data?.clientName}
                    loading={profileData?.loading}
                    // variant=""
                    // variant={
                    //   profileData?.data?.profilePic ? "circular" : "rounded"
                    // }
                    width={"1.75rem"}
                    height={"1.75rem"}
                  />
                  {profileData?.loading ? (
                    <Skeleton variant="text" width="60px" />
                  ) : (
                    <Typography color="textPrimary" fontWeight="400">
                      {profileData?.data?.clientName}
                    </Typography>
                  )}
                </Stack>
                <IconButton size="small">
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    htmlColor="#363A57"
                    sx={{ fontSize: "1.15rem" }}
                  />
                </IconButton>
              </Stack>
            </UserMenuContainer>
          </Stack>

          {/* Screens below md will render this Box */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              aria-label="go to cart"
              color="inherit"
              onClick={() => {
                history.push("/cart");
              }}
              id="topnavCartIcon"
            >
              <Badge
                badgeContent={cartCount?.data?.count}
                color="success"
                overlap={"circular"}
                sx={{
                  "& .MuiBadge-badge": { top: 1, right: 2, color: "white" },
                }}
              >
                <CartIcon htmlColor="#252B43" />
              </Badge>
            </IconButton>

            <IconButton
              aria-label="open notification drawer"
              color="inherit"
              onClick={() => notificationsHandler()}
              id="topnavNotificationIcon"
            >
              <Badge
                color="success"
                badgeContent={notificationCount?.data?.count}
                overlap={"circular"}
                sx={{
                  "& .MuiBadge-badge": { top: 1, right: 2, color: "white" },
                }}
              >
                <NotificationIcon color="#252B43" />
              </Badge>
            </IconButton>

            <IconButton
              // size="small"
              aria-label="go to profile"
              color="inherit"
              onClick={handleProfileNavigation}
              id="topnavProfileIcon"
            >
              <PersonIcon htmlColor="#252B43" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}

export default withSnackBar(withDrawer(withDialog(TopNav)));
