import React from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import MoreVert from "@mui/icons-material/MoreVert";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  activityStatus,
  createTestProps,
  loadScript,
  snackProps,
  ACTIVITY_MODE_TYPE,
  drawerProps,
} from "../../../utils";
import ActivityAndMembersCard from "./ActivityAndMembersCard";
import SummaryTextCard from "./summaryTextCard";
import ProfileCardActivitySummary from "./profileCardActivitySummary";
import CheckInCheckOutCard from "./checkInCheckOutCard";
import { actions } from "tanyacare-middleware";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoadingSection } from "../../../components/integerateHelper/loading";
import { TextField } from "../../../components/inputs";
import { withDialog, withSnackBar, withDrawer } from "../../../HOC's";
import CustomDateTimePicker from "../../dateTimePicker";
import ChooseSlot from "../../../screens/subscriptions/chooseSlot";
import { routes } from "../../../router/routes";
// import { withRouter } from "react-router";
// import { RightArrowStripesIcon } from "../../../assets";

export const colorCode = {
  PENDING: "#bc2d4f",
  COMPLETED: "#31b198",
};

const styles = (theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
  cancelButton: {
    boxShadow: "unset !important",
  },
  service_note_css: {
    display: "flex",
    padding: 16,
  },
  service_note_text: {},
  video_cal_btn: {
    position: "absolute !important",
    bottom: 0,
    width: "100% !important",
    borderRadius: "0px !important",
    textTransform: "capitalize !important",
    fontSize: "16px !important",
    [theme.breakpoints.down("sm")]: {
      position: "fixed !important",
    },
  },
  drawerMain: {
    height: "100%",
    display: "contents !important",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "68px",
      display: "flex !important",
    },
  },
  btnArrow: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    paddingLeft: "6px",
    paddingBottom: "4px",
  },
});

class ActivitySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientLocation: null,
      addressLoading: false,
      anchorEl: false,
      setDelete: false,
      setReschedule: false,
      fromDate: null,
      toDate: null,
    };
  }

  async componentDidMount() {
    if (this?.props?.data) {
      let data_get = await this.props.GET_ACTIVITY_SUMMARY({
        activityID: this?.props?.data?.id ?? null,
      });
      const { data } = data_get?.payload;
      if (data?.latitude && data?.longitude) {
        this.getLocationFromLatlong();
      }
      if (data?.activity_status === "Closed") {
        this.props.CHECK_REPORT_ASSESSMENT &&
          this.props.CHECK_REPORT_ASSESSMENT({
            appointmentId: data?.activity_id,
          });
      }
      await this.props.GET_TRACKING_TIME_SETUP({});
    }
  }

  getLocationFromLatlong = () => {
    this.loadGoogleApi();
  };

  loadGoogleApi = () => {
    const { data } = this.props?.activityDetails;
    const latitude = parseFloat(data?.latitude);
    const longitude = parseFloat(data?.longitude);
    if (
      latitude &&
      longitude &&
      latitude > -90 &&
      latitude < 90 &&
      longitude > -180 &&
      longitude < 180
    ) {
      this.setState(
        {
          addressLoading: true,
        },
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU&callback=initMap&libraries=&v=weekly`,
          () => this.handleScriptLoad(latitude, longitude)
        )
      );
    }
  };

  handleScriptLoad = (latitude, longitude) => {
    // Google Location - Geocoder API
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          //   results[0].formatted_address;
          this.setState({
            addressLoading: false,
            clientLocation: results[0].formatted_address,
          });
        } else {
          this.setState({
            clientLocation: null,
          });
        }
      } else {
        this.setState({
          clientLocation: null,
        });
      }
    });
  };

  closeDrawer = () => {
    this.props.drawer.setDrawer({
      ...this.props.drawer,
      open: false,
      component: <></>,
    });
  };

  handleActivityDelete = async () => {
    // console.clear();
    // console.log(this.state?.cancelation_reason);
    this.closeDrawer();
    // this.props.dialog.setDialog({ open: false });

    this.props.dialog.setDialog({
      open: true,
      title: "",
      content: (
        <>
          <p>
            Are you sure, you want to cancel this Appointment?
            <br />
            {this.state?.cancelation_reason
              ? `Reason: ${this.state?.cancelation_reason}`
              : ""}
          </p>
        </>
      ),
      renderContent: null,
      positiveButtonProps: {
        text: "Confirm",
      },
      negativeButtonProps: {
        text: "Cancel",
      },
      onAccept: async () => {
        this.props.dialog.setDialog({ open: false });
        let res_data = await this.props.DELETE_ACTIVITY({
          activityId: this.props?.activityDetails?.data?.activity_id,
          remarks: this.state?.cancelation_reason,
        });
        console.log(res_data);
        if (res_data?.payload) {
          this.props.snack.setSnack({
            open: true,
            message: "Deleting Appointment...",
            duration: 6000,
            severity: snackProps.severity.success,
            setSnack: () => null,
          });
        }
        this.props.reload();
      },
    });
  };

  handleTimeChange = async (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        const payload = {
          partnerId: this.props?.activityDetails?.data?.partnerId,
          resourceTypeCategoryId:
            this.props?.activityDetails?.resourceTypeCategoryId,
          fromTime: this.state.fromTime,
          toTime: moment(this.state.fromTime).add(
            this.props?.activityDetails?.data?.duration,
            "minutes"
          ),
        };
        console.log(payload);
      }
    );
    // this.props.SERVICE_PROFESSIONAL_BY_RESOURCE_TYPE_CATEGORY(payload)
  };

  handleActivityReschedule = async () => {
    if (this.state.fromDate < this.state.toDate) {
      this.closeDrawer();
      await this.props.EDIT_ACTIVITY({
        activityId: this.props?.activityDetails?.data?.activity_id,
        from_timestamp: this.state.fromDate,
        toDate: this.state.toDate,
      });
    } else {
      this.props.snack.setSnack({
        open: true,
        message: "To date should not be greater than from date",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };

  openVideoCall = (url) => {
    this.closeDrawer();
    window.open(url, "_blank");
  };

  navigateToTracking = () => {
    // Navigate to tracker
    if (this.props?.history) this.props?.history.push(routes.tracker.pathName);
  };

  reloadData = () => {
    this.closeDrawer();
    this.props.snack.setSnack({
      open: true,
      message: "Appointment Rescheduled Successfully",
      severity: snackProps.severity.success,
    });
    this.props.reload();
  };

  onReschedule = () => {
    console.clear();
    console.log(this.props.activityDetails?.data);
    let duration = this.props.activityDetails?.data?.service?.duration
      ? this.props.activityDetails?.data?.service?.duration
      : moment(this.props.activityDetails?.data?.to_time_stamp).diff(
          moment(this.props.activityDetails?.data?.from_time_stamp),
          "minutes"
        );
    const selectedData = {
      activityId: this?.props?.data?.id,
      activity_type_id: this.props.activityDetails?.data?.service?.id,
      title: `Appointment for ${
        this.props.activityDetails?.data?.service?.name ?? "Service"
      }`,
      activityTypeName: this.props.activityDetails?.data?.service?.name,
      serviceId: this.props.activityDetails?.data?.service?.id,
      subscriptionId: this.props.activityDetails?.data?.subscription?.value,
      subscriptionType: this.props.activityDetails?.data?.subscription?.type,
      duration: duration,
      providerId: this.props.activityDetails?.data?.service?.partnerId,
      resourceTypeCategoryId:
        this.props.activityDetails?.data?.service?.resourceTypeCategoryId,
      // // ...params?.data,
      address:
        this.props.activityDetails?.data?.mode === ACTIVITY_MODE_TYPE.VIDEO
          ? null
          : this.props.activityDetails?.data?.address?.add_location?.address,
      selectedVenue: this.props.activityDetails?.data?.selectedVenue,
      description: this.props.activityDetails?.data?.activity_description,
      latitude:
        this.props.activityDetails?.data?.mode === ACTIVITY_MODE_TYPE.VIDEO
          ? null
          : `${this.props.activityDetails?.data?.address?.add_location?.latitude}` ??
            null,
      longitude:
        this.props.activityDetails?.data?.mode === ACTIVITY_MODE_TYPE.VIDEO
          ? null
          : `${this.props.activityDetails?.data?.address?.add_location?.longitude}` ??
            null,
      mode: this.props.activityDetails?.data?.mode,
    };
    // console.clear();
    // console.log(selectedData);
    // if(isReschedule)
    // {
      // alert(JSON.stringify(this.props.activityDetails?.data))
      selectedData.professional = this.props.activityDetails?.data?.service_professional_info?.value
    // }
    debugger;
    this.props.drawer.setDrawer({
      open: true,
      anchor: drawerProps.direction.right,
      component: (handleClose) => (
        <ChooseSlot
          data={selectedData}
          navigateToTracking={() => this.navigateToTracking()}
          handleClose={handleClose}
          isEdit={true}
          isReschedule={true}
          reload={() => this.reloadData()}
          from_time={this.props.activityDetails?.data?.from_time_stamp}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
            maxWidth: "600px",
          },
        },
      },
    });
  };

  handleRedirect = () => {
    this.props.handleClose();
    if (this.props.notificationClose) {
      this.props.notificationClose();
    }
    // console.clear()
    // console.log(this.props.activityDetails)
    this.props.history?.push(
      `${routes.subscriptions.subRoutes.subscriptionServiceBooking.absolutePath}/${this.props.activityDetails?.data?.subscription?.value}`,
      {
        isEdit: true,
        edit_data: this.props.activityDetails?.data,
        serviceId: this.props.activityDetails?.data?.service?.id,
        description: this.props.activityDetails?.data?.activity_description,
      }
    );
  };

  trackServiceProfessional = async () => {
    let { data } = this.props?.getTrackingTimeList;
    if (this.props?.trackServiceProfessional) {
      this.props.trackServiceProfessional(
        data?.[0]?.duration,
        this.props?.activityDetails?.data?.from_time_stamp
        )
    }
  }

  render() {
    const { classes } = this.props;
    const { selectedType } = this.props;
    const {
      data,
      loading = false,
      // error = false,
    } = this.props?.activityDetails;
    const isLessThan24 = moment(data?.from_time_stamp).diff(moment(), "h") < 24;
    const isDateFuture = moment(data?.from_time_stamp).diff(moment(), "minutes") > 0;
    return (
      <>
        {loading && (
          <LoadingSection
            bottom={"30vh"}
            message="Loading appointment summary..."
          />
        )}
        {!loading && data && Object.keys(data)?.length > 0 && (
          <>
            <Grid container className={classes.drawerMain}>
              <Grid
                item
                container
                style={{
                  padding: "12px 12px 12px 6px",
                  background: "white",
                  alignItems: "center",
                  borderBottom: "0.5px solid rgb(244, 244, 245)",
                  height: 64,
                }}
              >
                <Grid item>
                  <IconButton
                    // onClick={this.props.onBackBtnClicked}
                    onClick={this.props.handleClose}
                    id={`activitySummaryHandleclose${data?.activity_id}`}
                  >
                    <ChevronLeft />
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">Appointment Summary</Typography>
                </Grid>
                {selectedType === "upcoming" || isDateFuture ? (
                  <>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.setState({ anchorEl: event.currentTarget });
                      }}
                      id={`activitySummaryMoreVert${data?.activity_id}`}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={(e) => {
                        e.stopPropagation();
                        this.setState({ anchorEl: null });
                      }}
                      id={`menu-${data?.activity_id}`}
                    >
                      {data?.activity_status !== activityStatus.completed &&
                        data?.activity_status !== activityStatus.inProgress &&
                        data?.activity_status !== activityStatus.cancelled &&
                        data?.activity_status !== activityStatus.closed && (
                          <>
                            {!isLessThan24 && (
                              <>
                                <MenuItem
                                  id="edit_menu_activity"
                                  onClick={
                                    //   (e) => {
                                    //   this.props.onEditBtnClicked(
                                    //     1,
                                    //     this.props.data
                                    //   );
                                    // }
                                   () => this.handleRedirect()
                                  }
                                >
                                  Edit Appointment
                                </MenuItem>
                                <MenuItem
                                  id={`cancel-menu-activity-${data?.activity_status}-${data?.activity_id}`}
                                  onClick={(e) => {
                                    this.setState({ setDelete: true });
                                    this.setState({ anchorEl: false });
                                    // this.handleActivityDelete(data?.activity_id)
                                  }}
                                >
                                  Cancel Appointment
                                </MenuItem>
                                <MenuItem
                                  id="reschedule_menu_activity"
                                  onClick={
                                    // (e) => {
                                    // this.setState({ setReschedule: true });
                                    // this.setState({ anchorEl: false });
                                    // }
                                    () => this.onReschedule()
                                  }
                                >
                                  Reschedule Appointment
                                </MenuItem>
                              </>
                            )}
                            <MenuItem
                              id={`menuActivityLog${data?.activity_status}${data?.activity_id}`}
                              onClick={(e) => {
                                // this.setState({anchorEl: false}, () => {this.props.onRescheduleBtnClicked(this.props, data)})
                                this.setState({ anchorEl: false }, () => {
                                  this.props.activityLogBtnClicked(
                                    1,
                                    this.props.data
                                  );
                                });
                              }}
                            >
                              Appointment Log
                            </MenuItem>
                          </>
                        )}

                      {data?.activity_status === activityStatus.completed ||
                      data?.activity_status === activityStatus.cancelled ||
                      data?.activity_status === activityStatus.closed ? (
                        <>
                          <MenuItem
                            id={`menuActivityLog${data?.activity_status}${data?.activity_id}`}
                            onClick={(e) => {
                              // this.setState({anchorEl: false}, () => {this.props.onRescheduleBtnClicked(this.props, data)})
                              this.setState({ anchorEl: false }, () => {
                                this.props.activityLogBtnClicked(
                                  1,
                                  this.props.data
                                );
                              });
                            }}
                          >
                            Appointment History
                          </MenuItem>
                        </>
                      ) : (
                        <></>
                      )}
                    </Menu>
                  </>
                ) : (
                  <>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.setState({ anchorEl: event.currentTarget });
                      }}
                      id={`activitySummaryMoreVert-${data?.activity_id}`}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={(e) => {
                        e.stopPropagation();
                        this.setState({ anchorEl: null });
                      }}
                      id={`menu-${data?.activity_id}`}
                    >
                      <>
                        <MenuItem
                          id={`menuActivityLog${data?.activity_status}${data?.activity_id}`}
                          onClick={(e) => {
                            // this.setState({anchorEl: false}, () => {this.props.onRescheduleBtnClicked(this.props, data)})
                            this.setState({ anchorEl: false }, () => {
                              this.props.activityLogBtnClicked(
                                1,
                                this.props.data
                              );
                            });
                          }}
                        >
                          Appointment History
                        </MenuItem>
                      </>
                    </Menu>
                  </>
                )}
              </Grid>

              <ActivityAndMembersCard
                hideProfilePicture
                title={data?.activity_name}
                activityID={data?.activity_id}
                timing={
                  data?.from_time_stamp && data?.to_time_stamp
                    ? `${moment(data?.from_time_stamp).format(
                        "Do MMM hh:mm A"
                      )} - ${moment(data?.to_time_stamp).format(
                        "Do MMM hh:mm A"
                      )}`
                    : undefined
                }
                hideIsActive
                activityStatus={data?.activity_type?.label} //activity_type will be a object with {value, label}
                activityStatusColor="#28a9c1"
                currentStatus={data?.activity_status}
                currentStatusColor={colorCode[data?.activity_status]}
                // useActivityAsSummaryCard
                hideEndIcon
                padding={2}
                mode={data?.mode}
              />
              {data?.activity_description && (
                <SummaryTextCard
                  description={data?.activity_description}
                  padding={2}
                />
              )}

              {/* {!hideClient && ( */}
              <ProfileCardActivitySummary
                id={data?.client_info?.id}
                profilePicture={data?.service_professional_info?.image}
                name={data?.service_professional_info?.name}
                gender={data?.service_professional_info?.role}
                // age={data?.client_info?.age}
                address={this.state.clientLocation}
                //videoCallUrl
                // meetingUrl={
                //   data?.mode === "VIDEO_CALL" ? data?.videoCallUrl : false
                // }
                addressLoading={this.state.addressLoading}
                padding={2}
                hideQuickIcons
                hideAddressSection={false}
                // modeType={data?.mode}
              />
              {/* )} */}

              {/* {!hideServiceProfessional && ( */}
              <div style={{ display: "block", padding: 16 }}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  {data?.activity_type?.label ?? ""}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {"Subscription :"}
                  {data?.subscription?.label}
                  {"("}
                  {data?.subscription?.type}
                  {")"}
                </Typography>
              </div>
              {/* )} */}

              {/* Service note */}
              {data?.activity_status === "Closed" &&
                this.props.serviceNote?.data?.length > 0 &&
                this.props.serviceNote?.data?.[0]?.report && (
                  <Grid xs={12}>
                    <div
                      style={{
                        display: "flex",
                        padding: 16,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{ marginRight: "auto" }}
                        variant="body1"
                        color="textPrimary"
                        gutterBottom
                      >
                        {"Service Note"}
                      </Typography>
                      <Typography style={{ color: "#FFA811" }}>
                        {"View"}
                        <IconButton
                          style={{ marginLeft: "11px" }}
                          onClick={(e) => {
                            this.setState({ anchorEl: false }, () => {
                              this.props.activityLogBtnClicked(
                                2,
                                this.props.data
                              );
                            });
                          }}
                          id={`view-${data?.activity_id}`}
                        >
                          <ChevronRight />
                        </IconButton>
                      </Typography>
                    </div>
                  </Grid>
                )}

              {
                selectedType === "upcoming" &&
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      textTransform: 'capitalize',
                      padding: '7.76px'
                    }}
                    onClick={this.trackServiceProfessional}
                    {...createTestProps("track service professional location button")}
                    >
                    Track Service Professional
                  </Button>
                </Grid>
              }

              <Grid item xs={12}>
                <CheckInCheckOutCard
                  leftText="Check - In"
                  leftSubText={
                    data?.check_in?.updatedAt
                      ? moment(data?.check_in?.updatedAt).format("hh:mm A")
                      : " - "
                  }
                  rightText="Check - Out"
                  rightSubText={
                    data?.check_out?.updatedAt
                      ? moment(data?.check_out?.updatedAt).format("hh:mm A")
                      : " - "
                  }
                  padding={1.5}
                />
              </Grid>
              {isLessThan24 && selectedType === "upcoming" && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", padding: "12px" }}
                  >
                    <Typography
                      style={{ marginRight: "auto" }}
                      variant="body1"
                      color="textPrimary"
                      gutterBottom
                    >
                      {"Do you want to cancel or reschedule this Appointment?"}
                    </Typography>
                    <Typography
                      style={{ marginRight: "auto" }}
                      variant="body1"
                      color="textPrimary"
                      gutterBottom
                    >
                      <span
                        style={{ color: "#03dac5", cursor: "pointer" }}
                        onClick={() => {
                          window.open("tel:+1 300-132-511");
                        }}
                        id={`callHelpdesk${data?.activity_id}`}
                      >
                        {"Call Helpdesk"}
                      </span>
                      {" or Coordinator "}
                      {data?.serviceCoordinatorInfo?.contact?.country_code &&
                        data?.serviceCoordinatorInfo?.contact?.mobile_no && (
                          <span
                            style={{ color: "#03dac5", cursor: "pointer" }}
                            id={`${data?.serviceCoordinatorInfo?.contact?.mobile_no}-${data?.activity_id}`}
                            onClick={() => {
                              window.open(
                                `tel:${
                                  data?.serviceCoordinatorInfo?.contact
                                    ?.country_code
                                }${"-"}${
                                  data?.serviceCoordinatorInfo?.contact
                                    ?.mobile_no
                                }`
                              );
                            }}
                          >
                            {
                              data?.serviceCoordinatorInfo?.contact
                                ?.country_code
                            }{" "}
                            {data?.serviceCoordinatorInfo?.contact?.mobile_no}
                          </span>
                        )}
                    </Typography>
                  </Grid>
                </>
              )}

              {/* Reason For Cancelation */}
              {this.state?.setDelete && (
                <>
                  <Grid container>
                    <Grid
                      item
                      container
                      style={{
                        padding: "20px",
                        background: "white",
                      }}
                    >
                      <TextField
                        id={`reasonForCancellation-${data?.activity_id}`}
                        required={true}
                        type={"text"}
                        onlyText={false}
                        rowsMax={5}
                        multiline={true}
                        title={"Cancelation Reason"}
                        placeholder={"Reason For Cancelation"}
                        defaultValue=""
                        value={this.state.cancelation_reason}
                        handleOnChange={(e) => {
                          console.log(e.target?.value);
                          this.setState({
                            cancelation_reason: e.target?.value,
                          });
                        }}
                        error={false}
                        errorText={"Reason Needed"}
                        disabled={false}
                      />
                      <div className={classes.saveBtnContainer}>
                        <Button
                          variant={"outlined"}
                          color={"primary"}
                          onClick={() => this.setState({ setDelete: false })}
                          style={
                            this.props.cancelButtonStyles
                              ? {
                                  marginRight: 12,
                                  ...this.props.cancelButtonStyles,
                                }
                              : { marginRight: 12 }
                          }
                          id={this.props?.cancelButtonId}
                        >
                          {"Cancel"}
                        </Button>
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          className={classes.saveBtn}
                          style={
                            this.props.saveButtonStyles
                              ? {
                                  margin: 16,
                                  padding: "5px 40px",
                                  ...this.props.saveButtonStyles,
                                }
                              : { margin: 16, padding: "5px 40px" }
                          }
                          disabled={false}
                          id={this.props?.saveButtonId}
                          onClick={this.handleActivityDelete}
                        >
                          {"Done"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* Reschedule  */}
              {this.state?.setReschedule && (
                <>
                  <Grid
                    container
                    style={{
                      padding: "20px",
                      background: "white",
                    }}
                  >
                    {/* <Grid
                      item
                      container
                      style={{
                        padding: "20px",
                        background: "white",
                      }}
                    > */}
                    <Typography>Reschedule Date and Time</Typography>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <CustomDateTimePicker
                        id={createTestProps(
                          "my order from date and time filter"
                        )}
                        label="From Date & Time"
                        value={this.state.fromDate}
                        // onChange={(data) => this.setState({ fromDate: data })}
                        onChange={(fromTime) =>
                          this.handleTimeChange("fromTime", fromTime)
                        }
                        minDate={moment(new Date())}
                        InputProps={{ size: "small", fullWidth: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomDateTimePicker
                        id={createTestProps(
                          "my order to date and time filter"
                        )}
                        label="To Date & Time"
                        value={this.state.toDate}
                        onChange={(toTime) => this.setState({ toDate: toTime })}
                        minDate={
                          this.state.fromDate
                            ? moment(this.state.fromDate)
                            : null
                        }
                        disabled={!this.state.fromDate}
                        InputProps={{ size: "small", fullWidth: true }}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <div className={classes.saveBtnContainer}>
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        onClick={() => this.setState({ setReschedule: false })}
                        style={
                          this.props.cancelButtonStyles
                            ? {
                                marginRight: 12,
                                ...this.props.cancelButtonStyles,
                              }
                            : { marginRight: 12 }
                        }
                        id={this.props?.cancelButtonId}
                      >
                        {"Cancel"}
                      </Button>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        className={classes.saveBtn}
                        style={
                          this.props.saveButtonStyles
                            ? {
                                margin: 16,
                                padding: "5px 40px",
                                ...this.props.saveButtonStyles,
                              }
                            : { margin: 16, padding: "5px 40px" }
                        }
                        disabled={false}
                        id={this.props?.saveButtonId}
                        onClick={this.handleActivityReschedule}
                      >
                        {"Done"}
                      </Button>
                    </div>
                    {/* </Grid> */}
                  </Grid>
                </>
              )}

              {/* VIDEO_CALL */}
              {data?.mode === "VIDEO_CALL" &&
                selectedType === "upcoming" &&
                !this.state?.setDelete &&
                !this.state?.setReschedule && (
                  <Button
                    onClick={() => this.openVideoCall(data?.videoCallUrl)}
                    size={"large"}
                    className={classes.video_cal_btn}
                    variant="contained"
                    id={`joinVideoCall${data?.activity_id}`}
                  >
                    {"Join Video Call "}
                    <div className={classes.btnArrow}>
                      <ChevronRight />
                    </div>
                  </Button>
                )}
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activityDetails: state?.clientActivity?.activitySummary,
  serviceNote: state?.dependency_api?.checkReportAssessment,
  getTrackingTimeList: state?.configuration?.getTrackingTimeList,
});

export default connect(
  mapStateToProps,
  actions
)(withSnackBar(withDrawer(withDialog(withStyles(styles)(ActivitySummary)))));
