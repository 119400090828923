import { SvgIcon } from "@mui/material";
import * as React from "react";

function EmptyIcon(props) {
  return (
    <SvgIcon
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.479 16.337l-6.073 3.335-6.072-3.335V9.668l6.072-3.334 6.073 3.334v6.67z"
        fill="#FFB954"
      />
      <path
        d="M6.334 9.668l6.072 3.335 6.073-3.335-6.073-3.334-6.072 3.334z"
        fill="#F8FAFB"
      />
      <path
        d="M6.334 9.67v6.667l6.072 3.335.004-.002v-6.667L6.337 9.668l-.003.002z"
        fill="#FFC66C"
      />
      <path
        d="M18.479 9.668l2.005 2.006-6.072 3.334-2.006-2.005 6.073-3.335zM6.334 9.668l-2.006 2.006 6.073 3.334 2.005-2.005-6.072-3.335zM18.479 9.668l2.005-2.005-6.072-3.335-2.006 2.006 6.073 3.334zM6.334 9.668L4.328 7.663l6.073-3.335 2.005 2.006-6.072 3.334z"
        fill="#FEDA9B"
      />
    </SvgIcon>
  );
}

export default EmptyIcon;
