import PropTypes from "prop-types";
import React from "react";
import { Stack, Typography, Divider } from "@mui/material";
import { CustomButton } from "../../../components";

const Prompt = ({
  title,
  body,
  positiveButtonText,
  negativeButtonText,
  handleClose,
  handleAccept,
}) => {
  return (
    <Stack spacing={2} justifyContent="center" sx={{ pt: "20px" }}>
      <Typography align="center" variant="h6" sx={{ px: "20px" }}>
        {title}
      </Typography>
      <Typography
        align="center"
        variant="body1"
        color="textSecondary"
        sx={{ px: "20px" }}
      >
        {body}
      </Typography>
      <Stack
        flexDirection="row"
        sx={{ borderTop: 1, borderColor: "grey.border" }}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: "grey.border" }}
          />
        }
      >
        <CustomButton
          onClick={handleClose}
          variant="text"
          sx={{ flex: 1, py: 1.25, borderRadius: 0, color: "text.secondary" }}
        >
          {negativeButtonText}
        </CustomButton>
        <CustomButton
          onClick={handleAccept}
          variant="text"
          sx={{ flex: 1, py: 1.25, borderRadius: 0 }}
          color="primary"
        >
          {positiveButtonText}
        </CustomButton>
      </Stack>
    </Stack>
  );
};

Prompt.propTypes = {
  body: PropTypes.string,
  handleAccept: PropTypes.func,
  handleClose: PropTypes.func,
  negativeButtonText: PropTypes.string,
  positiveButtonText: PropTypes.string,
  title: PropTypes.string,
};

Prompt.defaultProps = {
  body: "",
  handleAccept: () => {},
  handleClose: () => {},
  negativeButtonText: "No",
  positiveButtonText: "Yes",
  title: "",
};

export default Prompt;
