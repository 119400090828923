import Paper from "@mui/material/Paper"
import { styled } from '@mui/system'
import React from 'react'
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { CustomButton } from '../..'

const CustomPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    borderRadius: 10,
    border: "1px solid",
    borderColor: "#d8dce5",
    // borderColor: theme.palette.grey.border,
    // paddingBottom: theme.spacing(1),
    // padding: 30,
    // boxShadow: "0 16px 16px 0 rgba(54, 58, 87, 0.06)",
    // backdropFilter: "blur(30px)",

    [theme.breakpoints.down('sm')]: {
        // padding: theme.spacing(3)
    }
}))

export const CustomCard = (props) => {
    return (
        <CustomPaper elevation={0} sx={{ ...props.sx, overflow: "hidden" }}>
            <Stack sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", py: 1, px: { xs: 2.5, md: 3.5 }, ...props.stackSx }}>
                <Typography color="textSecondary" sx={{...props.titleSx}}>{props.title} {props.required && <Box component="span" sx={{color: "error.main"}}>*</Box>}</Typography>
                {props.button && <CustomButton variant="text" size="small" color="secondary" id={`${props?.button}-${props?.parentID}`} onClick={props.onClick}>{props.button}</CustomButton>}
                {props.helperText && <Typography color="error">{props.helperText}</Typography>}
            </Stack>
            <Divider />
            {props.children}
        </CustomPaper>
    )
}