import React, {useEffect} from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CustomTextfield } from "..";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

export default function AddressAutoComplete(props) {
  const [address, setAddress] = React.useState("");
//   const [coordinates, setCoordinates] = React.useState({
//     lat: null,
//     lng: null,
//   });

  useEffect(() => {
    setAddress(props?.value?.address ? props?.value?.address : "");
  }, [props?.value])

  const handleSelect = async (value) => {

    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    // setCoordinates(latLng);

    let addressContainer = {};
    addressContainer["address"] = results[0]?.formatted_address;
    addressContainer["latitude"] = latLng?.lat;
    addressContainer["longitude"] = latLng?.lng;
    addressContainer["address_components"] = results[0]?.address_components;

    props.handleOnPlaceSelect(addressContainer);
  };

  const searchOptions = {
    types: ["address"],
  };

  return (
    <Box>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Box>
            {/* <p>Latitude: {coordinates.lat}</p>
            <p>Longitude: {coordinates.lng}</p> */}

            <CustomTextfield
              {...getInputProps({
                label: "Select Location",
                name: "select_location",
                size: "small",
                sx: { width: "100%" },
                ...props.textFieldProps
              })}
            />

            <Box sx={{ position: "relative" }}>
              {loading ? <Box>...loading</Box> : null}

              <Paper sx={{ position: "absolute", width: "100%", zIndex: 10 }}>
                {suggestions.map((suggestion) => {
                  return (
                    <MenuList {...getSuggestionItemProps(suggestion)}>
                      <MenuItem>
                        <Typography variant="inherit" noWrap>
                          {suggestion.description}
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  );
                })}
              </Paper>
            </Box>
          </Box>
        )}
      </PlacesAutocomplete>
    </Box>
  );
}
