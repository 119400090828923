import React from "react";
import ReactDOM from "react-dom";
import CustomDialog from '../dialog';

const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen
) => {

  const container = document.createElement("div");

  container.setAttribute("custom-confirm-view", "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false);
  };

  document.body.appendChild(container);
  const { title, content, positiveButtonProps, negativeButtonProps } = JSON.parse(message);
  ReactDOM.render(
       <CustomDialog
            handleClose={handleCancel}
            handleAccept={(e) => {
                handleConfirm(e);
            }}
            open={confirmOpen}
            title={title}
            content={content}
            positiveButtonProps={positiveButtonProps}
            negativeButtonProps={negativeButtonProps}
        />,
    container
  );
};

export default UserLeaveConfirmation;