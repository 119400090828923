import { SvgIcon } from "@mui/material";
import * as React from "react";

function NotificationIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      htmlColor="transparent"
      stroke={props.color ?? "rgba(0, 0, 0, 0.54)"}
      {...props}
    >
      <path
        d="M12.18 3.007A6.232 6.232 0 006.358 9.29v3.453L5.086 15.3a.906.906 0 00.82 1.292h13.14a.907.907 0 00.82-1.292l-1.268-2.557V9.118a6.116 6.116 0 00-6.418-6.11v0zM14.515 19.275a2.04 2.04 0 11-4.079 0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default NotificationIcon;
