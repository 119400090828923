import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CustomAvatar } from "../";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { currencyPriceFormatter } from "../../utils";

const CustomPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: 10,
  border: "1px solid",
  borderColor: "#d8dce5",
  // borderColor: theme.palette.grey.border,
  padding: 20,
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
    borderRadius: 0,
    border: "0px solid",
    borderColor: "#d8dce5",
    borderBottom: "1px solid #d8dce5!important",
  },
}));

const EnquiryNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  // fontWeight: "bold",
  //   fontSize: "1.20rem",
  //   [theme.breakpoints.down("md")]: {
  //     fontSize: "0.75rem",
  //   },
}));

const CustomPrice = styled(Typography)(({ theme }) => ({
  // width: "fit-content",
  // margin: "auto",
  fontWeight: "bold",
  color: theme.palette.enquiry.viewQuotes,
  // justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "fit-content",
    margin: "auto",
    // marginTop:"12%"
  },
}));

function CartProducts(props) {
  const { data, handleRedirect } = props;
  return (
    <CustomPaper
      elevation={0}
      onClick={() => handleRedirect(data?.id, data?.versionId)}
      id={`cartList${data?.id}`}
    >
      <Grid container wrap="wrap">
        <Grid item xs>
          <Stack flexDirection="row" gap={1}>
            <CustomAvatar
              src={data?.image}
              //   sx={{
              //     width: { xs: 30, sm: 40, md: 50 },
              //     height: { xs: 30, sm: 40, md: 50 },
              //   }}
            />
            <Stack>
              <Typography color="textSecondary" variant="body2">
                {data?.type}
              </Typography>
              <Typography>{data?.name}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item>
          <Stack flexDirection="row" gap={2} alignItems="center">
            {data?.isExpired ? (
              <Typography
                variant={"body2"}
                textAlign={"center"}
                sx={{ color: "red" }}
              >{`This ${data?.type?.toLowerCase()} is unavailable`}</Typography>
            ) : (
              <Stack
                flexDirection="row"
                alignItems="flex-end"
                gap={1}
                sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
              >
                <Typography fontWeight={"500"} color="primary">
                  {currencyPriceFormatter(data?.currency, data?.discount)}
                </Typography>
                {data?.price && data?.discountPercentage !== 0 && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {currencyPriceFormatter(data?.currency, data?.price)}
                  </Typography>
                )}
              </Stack>
            )}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                props.handleDeleteItem(data?.id);
              }}
              id={`cartListDelete${data?.id}`}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

export default CartProducts;

{
  /* <Grid
          container
          spacing={1}
          item
          xs={9}
          md={9}
          sx={{
            flexDirection: { xs: "row", md: "row" },
            alignItems: { xs: "flex-start", md: "flex-start" },
          }}
        >
          <Grid item>
            <CustomAvatar
              src={data?.image}
              sx={{
                width: { xs: 30, sm: 40, md: 50 },
                height: { xs: 30, sm: 40, md: 50 },
              }}
            />
          </Grid>
          <Grid item xs>
            <Stack>
              <Typography
                color="textSecondary"
                sx={{
                  textAlign: { xs: "start", md: "start" },
                }}
                variant="body2"
              >
                {data?.type}
              </Typography>
              <EnquiryNameTypography
                sx={{ textAlign: { xs: "start", md: "start" } }}
                variant="body1"
              >
                {data?.name}
              </EnquiryNameTypography>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={3} md={3} alignItems="center">
          <Hidden mdUp>
            <Stack
              sx={{
                flexDirection: { xs: "column", md: "column" },
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <IconButton
                sx={{ padding: "0 0" }}
                onClick={() => props.handleDeleteItem(data?.id)}
              >
                <DeleteOutlineIcon
                  sx={{ fontSize: { xs: "12px", md: "24px" } }}
                />
              </IconButton>
              <CustomPrice>
                {currencyPriceFormatter(data?.currency, data?.price)}
              </CustomPrice>
            </Stack>
          </Hidden>
          <Hidden mdDown>
            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
                height: "100%",
                alignItems: "center",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              <CustomPrice>
                {currencyPriceFormatter(data?.currency, data?.price)}
              </CustomPrice>
              <IconButton
                sx={{ padding: "0 0" }}
                onClick={() => props.handleDeleteItem(data?.id)}
              >
                <DeleteOutlineIcon
                  sx={{ fontSize: { xs: "12px", md: "24px" } }}
                />{" "}
              </IconButton>
            </Stack>
          </Hidden>
        </Grid> */
}
