import * as React from "react"

export function VideoCallIcon(props) {
  return (
    <svg width={22} height={14} viewBox="0 0 22 14" {...props}>
      <path
        fill="none"
        stroke="#9297a5"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M15 13H2a1 1 0 01-1-1V2a1 1 0 011-1h13a1 1 0 011 1v10a1 1 0 01-1 1zm1-6l5-4v8l-5-4"
        data-name="Video Call"
      />
    </svg>
  )
}
