import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material";
import { StyledPaper } from "./styledPaper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  WeightDuoToneIcon,
  HeightDuoToneIcon,
  BloodgroupDuoToneIcon,
  NotepadDuoToneIcon,
  // NotepadPlusDuoToneIcon
} from "../../../../assets/icons/clientIcons";
// import { ReactComponent as BloodgroupDuoToneIcon } from "../../../../assets/icons/clientIcons/duotone/bloodgroupDuoTone.svg";
// import { ReactComponent as NotepadDuoToneIcon } from "../../../../assets/icons/clientIcons/duotone/notepadDuoTone.svg";
import moment from "moment";
import { getFormattedBloodGroupLabel } from "../../../../utils";
import { withDialog } from "../../../../HOC's";
import purify from "dompurify";
import { NoDataHandler } from "../../../../components";

const StyledDivider = styled(Divider)(() => ({
  borderColor: "#EAEAEA",
}));

const LabelTypography = styled(Typography)({
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  fontSize: "14px",
});

// function HealthAttribute({ label, value }) {
//   return (
//     <Stack sx={{ minWidth: "calc(25% - 3px)" }}>
//       <Typography
//         variant="body2"
//         color="textSecondary"
//         fontWeight="400"
//         sx={{ fontStyle: "italic", color: "rgba(146, 151, 165, 1)" }}
//         gutterBottom
//       >
//         {label ? label : "Untitled"}
//       </Typography>
//       <Typography fontWeight="400">{value ? value : "-"}</Typography>
//     </Stack>
//   );
// }

function IconAvatar({ icon }) {
  if (icon)
    return (
      <Avatar
        // variant="rounded"
        sx={{
          bgcolor: "white",
          border: "1px solid #E7E9EF",
          height: 48,
          width: 48,
        }}
      >
        {icon}
      </Avatar>
    );

  return "";
}

function HealthInformationRow({
  icon,
  label,
  value1,
  value2,
  lastUpdated,
  loading,
}) {
  return (
    <Stack flexDirection="row" gap={2}>
      <IconAvatar icon={icon} />
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flex: 1,
        }}
      >
        <Stack>
          <Typography fontWeight="500">{label ? label : "Untitled"}</Typography>
          {lastUpdated && (
            <Typography
              variant="caption"
              color="textSecondary"
              fontWeight="400"
            >
              {moment(lastUpdated).isValid()
                ? `Last Updated on ${lastUpdated}`
                : lastUpdated}
            </Typography>
          )}
        </Stack>
        {loading ? (
          <Skeleton variant="text" width={"30%"} />
        ) : (
          <Typography fontWeight="500" sx={{ fontSize: "18px" }}>
            {value1 ? value1 : "Not updated"}
            <Box component="span" sx={{ fontSize: "16px" }}>
              {value2 ? ` ${value2}` : ""}
            </Box>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

// function MedicalHistory({ icon, label, value, onClick }) {
//   return (
//     <Stack flexDirection="row" gap={2} sx={{ cursor: "pointer" }} onClick={() => onClick(value)}>
//       <IconAvatar icon={icon} />

//       <Stack flexDirection="row"
//         justifyContent="space-between"
//         alignItems="center"
//         sx={{
//           flex: 1,
//         }}>
//         <Typography fontWeight="500">
//           {label ? label : "Untitled"}
//         </Typography>

//         {/* <IconButton size="small" disableRipple>
//           <ChevronRightIcon sx={{ color: "text.secondary" }} />
//         </IconButton> */}

//         <Button
//           color="secondary"
//           size="small"
//           sx={{ textTransform: "unset", color: "#F3AB40", fontSize: "14px", fontWeight: 400 }}
//           disableRipple
//         >
//           View
//         </Button>
//       </Stack>
//     </Stack>
//   );
// }

function MedicalHistory({ icon, avatarBg, label, value, onClick, loading }) {
  const valueWithoutHtmlTags = value?.trim()?.replace(/<[^>]*>/g, "");
  const limit = 140;
  return (
    <Stack flexDirection="row" gap={2}>
      <IconAvatar icon={icon} avatarBg={avatarBg} />

      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          flex: 1,
        }}
      >
        <Typography fontWeight="500" sx={{mb: 1.5}}>{label ? label : "Untitled"}</Typography>

        {/* <IconButton size="small" disableRipple>
          <ChevronRightIcon sx={{ color: "text.secondary" }} />
        </IconButton> */}
        {loading ? (
          <Skeleton variant="text" width={"50%"} />
        ) : (
          <>
            {valueWithoutHtmlTags ? (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: purify.sanitize(
                      `<div style="font-size: 14px;">${
                        valueWithoutHtmlTags
                          .slice(0, limit)
                          .concat(
                            valueWithoutHtmlTags?.length > limit ? "..." : ""
                          ) ?? "You don't have any medical history yet"
                      }</div>`
                    ),
                  }}
                ></div>
                {valueWithoutHtmlTags?.length > limit ? (
                  <Box
                    sx={{ ml: "auto", color: "#F3AB40", cursor: "pointer" }}
                    onClick={() => onClick(value)}
                  >
                    view All
                  </Box>
                ) : (
                  ``
                )}
              </>
            ) : (
              <Typography fontWeight="500">Not updated</Typography>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}


function SpecialPrecaution({
  icon,
  avatarBg,
  label,
  value = [],
  loading,
  onClick = () => {},
}) {
  return (
    <Stack flexDirection="row" gap={2}>
      <IconAvatar icon={icon} />

      <Stack width={"100%"}>
        <Typography fontWeight="500" sx={{mb: 1.5}}>{label ? label : "Untitled"}</Typography>
        {loading ? (
          <Skeleton variant="text" width={"50%"} />
        ) : value?.length > 0 ? (
          <Stack gap={0.5}>
            {value?.slice(0, 3)?.map((item) => (
              <Stack
                flexDirection="row"
                alignItems="center"
                gap={1.5}
                sx={{ ml: 0.25 }}
              >
                <Stack flexDirection="row" gap={0.5}>
                  <div>
                    <div>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: "0.65rem" }}
                      />
                    </div>
                  </div>
                  <LabelTypography>{item}</LabelTypography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography fontWeight="500">{"Not updated"}</Typography>
        )}
        {!loading && value?.length > 3 && (
          <Box
            sx={{ ml: "auto", color: "#F3AB40", cursor: "pointer" }}
            onClick={() => onClick(value)}
          >
            view All
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

// function SpecialPrecaution({ icon, avatarBg, label, value = [] }) {
//   return (
//     <Stack flexDirection="row" gap={2}>
//       <IconAvatar icon={icon} />
//       <Stack>
//         <Typography fontWeight="400">{label ? label : "Untitled"}</Typography>
//         {Array.isArray(value) &&
//           value?.map((item) => (
//             <Stack
//               flexDirection="row"
//               alignItems="center"
//               gap={1.5}
//               sx={{ ml: 1.25 }}
//             >
//               <Box
//                 sx={{
//                   height: "4px",
//                   width: "4px",
//                   background: "#605F5F",
//                   borderRadius: "50%",
//                 }}
//               ></Box>
//               <Typography>{item}</Typography>
//             </Stack>
//           ))}
//       </Stack>
//     </Stack>
//   );
// }

function HealthInformation({ data, loading, handleEdit, ...props }) {
  const blood = getFormattedBloodGroupLabel(data?.bloodGroup, true);

  const handleViewMedicalHistory = (value) => {
    props.dialog.setDialog({
      open: true,
      title: "Medical History",
      renderContent: (
        <>
          {value ? (
            <div
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(
                  `<div  style="width: 100%; overflow-wrap: break-word;">${value}</div>`
                ),
              }}
            ></div>
          ) : (
            <Box>
              <NoDataHandler
                message={"You don't have any medical history"}
                fullWidth={"250px"}
                // fullheight={"300px"}
              />
            </Box>
          )}
        </>
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            minWidth: (theme) => ({ xs: "90%", sm: "80%", md: "50%" }),
          },
        },
      },
    });
  };

  const handleViewSpecialPrecaution = (value = []) => {
    props.dialog.setDialog({
      open: true,
      title: "Special Precautions",
      renderContent: (
        <List dense={false}>
          {value?.map((_, index) => (
            <ListItem>
              <ListItemIcon sx={{ minWidth: "24px" }}>
                <FiberManualRecordIcon
                  fontSize="small"
                  htmlColor="#605F5F"
                  sx={{ fontSize: "0.9rem" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={_}
                sx={{
                  my: 0,
                  "& .MuiListItemText-primary": { fontWeight: "400" },
                }}
              />
            </ListItem>
          ))}
        </List>
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            minWidth: (theme) => ({ xs: "90%", sm: "80%", md: "50%" }),
          },
        },
      },
    });
  };

  return (
    <StyledPaper>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 3, py: 1.5 }}
      >
        <Typography variant="h6" fontWeight="500" style={{ fontSize: "18px" }}>
          Health Info
        </Typography>
        <Button
          id={`editHealthInfo`}
          size="small"
          sx={{ textTransform: "unset", fontWeight: "500", fontSize: "18px" }}
          onClick={() => {
            handleEdit(3);
          }}
        >
          Edit
        </Button>
      </Stack>
      <StyledDivider />
      {/* <Stack rowGap={4} sx={{ px: 3, py: 2.5 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          rowGap={3}
          columnGap={0.5}
        >
          <HealthAttribute label="Height" value="150cm" />
          <HealthAttribute label="Weight" value="50 kg" />
          <HealthAttribute label="BMI" value="20 Normal" />
          <HealthAttribute label="Blood Group" value="B +ve" />
        </Stack>

        <MedicalHistory label="Medical History" value="Not updated" />

        <SpecialPrecaution label="Special Precaution" value="Not updated" />
      </Stack> */}

      <Stack
        sx={{ bgcolor: "common.white", p: 2.5 }}
        gap={1.5}
        style={{
          boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
        }}
        divider={<Divider sx={{ borderColor: "#EAEAEA" }} />}
      >
        <HealthInformationRow
          icon={<WeightDuoToneIcon fill="white" />}
          label="Weight"
          value1={data?.weight ? data?.weight : ""}
          value2={data?.weight ? `kg` : ""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />
        <HealthInformationRow
          icon={<HeightDuoToneIcon fill="white" stroke="white" />}
          label="Height"
          value1={data?.height ? data?.height : ""}
          value2={data?.height ? `cm` : ""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />
        <HealthInformationRow
          icon={<WeightDuoToneIcon fill="white" />}
          label="BMI"
          value1={data?.bmi}
          value2={""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />
        <HealthInformationRow
          icon={<BloodgroupDuoToneIcon fill="white" stroke="white" />}
          label="Blood Group"
          value1={data?.bloodGroup ? blood?.group : ""}
          value2={data?.bloodGroup ? blood?.type : ""}
          loading={loading}
          // lastUpdated={moment().format("Do MMM YYYY")}
        />

        <MedicalHistory
          icon={<NotepadDuoToneIcon fill="white" stroke="white" />}
          label="Medical History"
          value={data?.medicalHistory}
          onClick={handleViewMedicalHistory}
          loading={loading}
        />

        <SpecialPrecaution
          icon={<NotepadDuoToneIcon fill="white" stroke="white" />}
          label="Special Precautions"
          // value={[1,2]}
          value={data?.specialPrecautions ?? []}
          onClick={handleViewSpecialPrecaution}
          loading={loading}
          // value={Array(4).fill(
          //   "Lorem Ipsum is a dummy text in industry dummy text in industry"
          // )}
        />
      </Stack>
    </StyledPaper>
  );
}

export default withDialog(HealthInformation);
