import PropTypes from "prop-types";
import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { CustomAvatar, CustomButton, CustomChip } from "../..";
import { styled } from "@mui/system";
import { dateTimeFormatterV2 } from "../../../utils";
import moment from "moment";
import { Hidden, useTheme } from "@mui/material";

const CustomPaper = styled(Paper)(({ theme }) => ({
  position: "relative",
  width: "100%",
  borderRadius: 10,
  border: "1px solid",
  borderColor: "#d8dce5",
  // borderColor: theme.palette.grey.border,
  // padding: 30,
  // paddingRight: 50,

  // Ribbon
  overflow: "hidden",

  [theme.breakpoints.down("sm")]: {
    // padding: theme.spacing(3),
    // paddingRight: theme.spacing(3),
  },
}));

const Ribbon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "inActive",
})(({ theme, inActive }) => ({
  margin: 0,
  // padding: "12px 0px",
  background: inActive ? theme.palette.error.main : theme.palette.success.main,
  color: theme.palette.common.white,
  fontSize: "0.8rem",
  position: "absolute",
  top: 0,
  right: 0,
  transform: "translateX(10%) translateY(30%) rotate(45deg)",
  transformOrigin: "top left",

  "&:before": {
    content: `""`,
    position: "absolute",
    top: 0,
    // margin: "0 -1px",
    width: "100%",
    height: "100%",
    background: inActive
      ? theme.palette.error.main
      : theme.palette.success.main,
    right: "100%",
  },
  "&:after": {
    content: `""`,
    position: "absolute",
    top: 0,
    // margin: "0 -1px",
    width: "100%",
    height: "100%",
    background: inActive
      ? theme.palette.error.main
      : theme.palette.success.main,
    left: "100%",
  },
}));

function SubscriptionOverviewCard(props) {
  const { loading, data } = props;
  const theme = useTheme();

  // Custom Logics
  // const isBookable = data?.isBookable;
  const endDate = moment(data?.expired_on);
  const currentDay = moment();
  let isExpired = moment().isAfter(endDate);
  let showExpiry = Math.round(endDate.diff(currentDay, "minutes") / 1440) < 10;
  const isLessThan14Days = data?.createdAt
    ? moment().diff(data?.createdAt, "days") + 1 <= 14
    : false;

  const expiredText = () => {
    if (isExpired && showExpiry) return `Expired on: ${endDate.fromNow()}`;
    else if (showExpiry)
      return `Expires on: ${Math.round(
        endDate.diff(currentDay, "minutes") / 1440
      )} days`;
    else return `Expires on: ${dateTimeFormatterV2(endDate)}`;
  };

  return (
    <CustomPaper elevation={0}>
      {/* <Ribbon inActive={false}>Active</Ribbon> */}
      <Grid
        container
        spacing={2}
        flexWrap="wrap"
        alignItems="center"
        sx={{
          padding: 3,
          paddingRight: 5,
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(1),
            paddingRight: theme.spacing(3),
          },
        }}
      >
        <Grid
          container
          spacing={2}
          item
          xs
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "center" },
          }}
        >
          <Grid item>
            <CustomAvatar
              src={data?.image}
              loading={loading}
              sx={{
                width: { xs: 60, sm: 60, md: 70 },
                height: { xs: 60, sm: 60, md: 70 },
              }}
            />
          </Grid>
          <Grid item xs>
            <Stack>
              <Stack
                sx={{
                  flexDirection: { xs: "row", md: "row" },
                  flexWrap: { xs: "wrap-reverse", md: "nowrap" },
                  gap: 1,
                  justifyContent: { xs: "center", md: "flex-start" },
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Skeleton width={"30vw"} />
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: { xs: "body1.fontSize", md: "h6.fontSize" },
                        fontWeight: { xs: "500" },
                        textAlign: { xs: "center", md: "start" },
                      }}
                    >
                      {data?.name}
                    </Typography>
                    {data?.type && (
                      <CustomChip label={data?.type} type={data?.type} />
                    )}
                  </>
                )}
              </Stack>
              {/* <Typography variant="body2" color="textSecondary" sx={{ textAlign: { xs: "center", md: "start" }, mt: { xs: 1, md: 0.25 } }}>By Managedcare</Typography> */}
              <Stack
                direction="column"
                gap={1}
                // divider={<Divider orientation="vertical" flexItem />}
                sx={{
                  justifyContent: { xs: "center", md: "flex-start" },
                  textAlign: { xs: "center", md: "left" },
                  mt: 1,
                }}
              >
                {/* <Typography variant="body2" color="textSecondary">Monthly</Typography> */}
                {loading ? (
                  <Skeleton width={"24vw"} />
                ) : (
                  <Typography variant="body2" fontWeight="400">
                    {expiredText()}
                  </Typography>
                )}
                {data?.isCancelled && (
                  <Typography color="error">
                    Cancelled on{" "}
                    {moment(data?.cancelledOn).format("Do MMM YYYY")}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12} md={"auto"}>
          <Stack
            sx={{
              flexDirection: { xs: "row", md: "column" },
              gap: 1.5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {data?.isCancelled ? (
              <Hidden mdDown>
                <Stack sx={{ textAlign: { xs: "center", md: "left" } }}>
                  <Typography color="secondary">
                    {data?.status?.label}
                  </Typography>

                  <Stack direction="column" gap={0}>
                    <Typography>ID - {data?.refundId}</Typography>
                    <Typography
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        props.cancelRequestLogs({
                          id: data?.refundId,
                          transactionId: data?.transactionId,
                        })
                      }
                    >
                      View More
                    </Typography>
                  </Stack>
                </Stack>
              </Hidden>
            ) : (
              <>
                {props.showCallToActions &&
                  !loading &&
                  isLessThan14Days &&
                  +data?.pending_services_count > 0 && (
                    <CustomButton
                      variant="outlined"
                      id={`subscriptionDetailsCancelSubscriptionButton`}
                      color="error"
                      onClick={props.handleCancel}
                      loading={props.cancelLoading}
                      loadingIndicator={"Cancelling"}
                    >
                      Cancel Subscription
                    </CustomButton>
                  )}
              </>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Hidden mdUp>
        {data?.isCancelled ? (
          <Box>
            <Divider />
            <Stack
              sx={{
                padding: "2px",
                paddingRight: 5,
                [theme.breakpoints.down("sm")]: {
                  paddingX: theme.spacing(2),
                  paddingRight: theme.spacing(1),
                },
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" gap={0}>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "13px", fontWeight: 600, mt: 1 }}
                  >
                    {data?.status?.label}
                  </Typography>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "13px", fontWeight: 600 }}
                  >
                    {data?.status?.label === "Refund Requested" &&
                      "Waiting for Approval"}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={0} sx={{ marginY: "5px" }}>
                  <Typography sx={{ fontSize: "13px" }}>
                    ID - {data?.refundId}
                  </Typography>
                  <Typography
                    color="primary"
                    sx={{ cursor: "pointer", fontSize: "13px" }}
                    onClick={() =>
                      props.cancelRequestLogs({
                        id: data?.refundId,
                        transactionId: data?.transactionId,
                      })
                    }
                  >
                    View More
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        ) : (
          <></>
        )}
      </Hidden>
    </CustomPaper>
  );
}

SubscriptionOverviewCard.propTypes = {
  showCallToActions: PropTypes.bool,
};

SubscriptionOverviewCard.defaultProps = {
  showCallToActions: true,
};

export default SubscriptionOverviewCard;
