import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import {
  ButtonWithInputField,
  CustomTextfield,
  DrawerSkeleton,
  RichTextEditor,
  StyledToggleButtonGroup,
} from "../../../../../components";
import { bmiCalculation } from "../../../../../utils";

const richTextComponentProps = {
  multiline: true,
  rowsMax: 4,
  type: "textArea",
  isLimitHelperTextNeeded: true,
};

const initialHealthInfoState = {
  height: "",
  weight: "",
  bmi: 0,
  bloodGroup: "",
  medical_history: "",
  precaution: [],
};

function HealthInfoForm({
  editData,
  handleClose,
  dependencyOptions,
  ...props
}) {
  const [state, setState] = useState({ ...initialHealthInfoState });

  useEffect(() => {
    setState(editData);
  }, [editData]);

  const updateFieldState = (value, key) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const saveOnUpdate = () => {
    // Update on Parent
    props.onUpdate(state);
  };

  const { height, weight } = state;

  useEffect(() => {
    if (height && weight) {
      updateFieldState(bmiCalculation(height, weight), "bmi");
    }
  }, [height, weight]);

  const removeItemFromAnArrayCommonFunction = (
    arrOfValues,
    key,
    componentState,
    bool
  ) => {
    const newArr = [...arrOfValues];
    newArr?.splice(key, 1);
    updateFieldState(newArr, componentState);
  };

  return (
    <DrawerSkeleton
      renderTitle={() => (
        <Typography
          sx={{ flex: 1, textAlign: "center", fontWeight: 500, ml: "-44px" }}
        >
          Edit Health Info
        </Typography>
      )}
      handleClose={handleClose}
      handleConfirm={saveOnUpdate}
      buttonText={"Update"}
    >
      <Grid container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={2}>
        <Grid item xs={12} md>
          {/* Field 1 */}
          <CustomTextfield
            // required
            fullWidth
            // size="small"
            type="number"
            sx={{ marginY: 1 }}
            label="Height"
            value={state["height"]}
            onChange={(e) => updateFieldState(e?.target?.value, "height")}
            InputProps={{
              endAdornment: <InputAdornment position="end">CM</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md>
          {/* Field 2 */}
          <CustomTextfield
            // required
            fullWidth
            // size="small"
            type="number"
            sx={{ marginY: 1 }}
            label="Weight"
            value={state["weight"]}
            onChange={(e) => updateFieldState(e?.target?.value, "weight")}
            InputProps={{
              endAdornment: <InputAdornment position="end">KG</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md>
          {/* Field 3 */}
          <CustomTextfield
            // required
            fullWidth
            // size="small"
            sx={{ marginY: 1 }}
            label="BMI"
            disabled={true}
            value={bmiCalculation(state["height"], state["weight"])}
            // onChange={(e) => updateFieldState(e?.target?.value, 'height')}
          />
        </Grid>

        <Grid item xs={12}>
          {/* Field 4 */}
          <StyledToggleButtonGroup
            id={"Submitted by"}
            label={"Blood Group"}
            // size="small"
            sx={{ marginY: 1 }}
            variant="radio"
            options={
              Array.isArray(dependencyOptions?.blood_group) &&
              dependencyOptions?.blood_group?.length > 0
                ? dependencyOptions?.blood_group?.map((blood) => {
                    let lbl = blood?.label;
                    let shortlbl = blood?.short_label;
                    return {
                      ...blood,
                      label: shortlbl,
                      short_label: lbl,
                    };
                  })
                : []
            }
            data={{ value: state["bloodGroup"] }}
            onChange={(e, value) => {
              //   console.log(value)
              if (value !== null) {
                updateFieldState(value, "bloodGroup");
              }
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <RichTextEditor
            id={"addMedicalHistoryFromStepThree"}
            value={state["medical_history"]}
            label={"Medical History"}
            keys={"medical_history"}
            saveOnBlur={(value) => {
              updateFieldState(value, "medical_history");
            }}
            {...richTextComponentProps}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <ButtonWithInputField
            label={"Special Precautions"}
            titleInLabel
            arrOfValues={state["precaution"]}
            buttonValue={"+ ADD"}
            componentState={"precaution"}
            removeListFunc={removeItemFromAnArrayCommonFunction}
            // error={this.state.error[component.key] ? true : false}
            // errorMessage={errorMessage.requiredMessage}

            id={{
              text_id: "addSpecialPrecautionsFromAddEditProfile",
              button_id: "addSpecialPrecautionsButtonFromAddEditProfile",
            }}
            handleButtonClick={(value) => {
              if (value) {
                let data = state["precaution"] ?? [];
                data = [...data, value];
                updateFieldState(data, "precaution");
              }
            }}
          />
        </Grid>
      </Grid>
    </DrawerSkeleton>
  );
}

export default HealthInfoForm;
