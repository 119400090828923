import { SvgIcon } from "@mui/material";
import * as React from "react";

function FilterIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // htmlColor="transparent"
      // stroke={props.color ?? "rgba(0, 0, 0, 0.54)"}
      {...props}
    >
      <path
        d="M20.667 17.333h-8.334 8.334zm-13.334 0H4h3.333zM20.667 5.667h-5.834 5.834zM9 5.667H4h5zM20.667 11.5H19h1.667zm-7.5 0H4h9.167zm-2.5-7.5a1.667 1.667 0 100 3.334 1.667 1.667 0 000-3.334v0zm4.167 5.833a1.667 1.667 0 100 3.334 1.667 1.667 0 00-.001-3.334h.001zm-6.667 5.834a1.668 1.668 0 100 3.335 1.668 1.668 0 000-3.335z"
        strokeWidth={2}
        strokeMiterlimit={10}
        stroke={props?.stroke ?? "rgba(0, 0, 0, 0.54)"}
      />
    </SvgIcon>
  );
}

export default FilterIcon;
