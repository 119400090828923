import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RightArrowStripesIcon } from "../../assets";
import { errorMessages, isObjectEmpty, snackProps } from "../../utils";
import {
  AddressAutoComplete,
  CustomButton,
  CustomTextfield,
  CustomSelect,
} from "..";
import { withSnackBar } from "../../HOC's";
// import { v4 as uuidv4 } from 'uuid';

function AddAddressDrawer(props) {
  const {
    isEdit,
    editData,
    options = [],
    onSave = () => {},
    handleClose = () => {},
    parentID=""
  } = props;

  // Component States
  const [location, setLocation] = useState("");
  const [address_type, setAddressType] = useState(null);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errorText, setErrorText] = useState({});

  const styleProp = {
    sx: { width: "100%" },
  };

  useEffect(() => {
    console.clear();
    console.log("==================================");
    console.log(editData);
    debugger;
    if (!isObjectEmpty(editData)) {
      console.log("INSIDE");
      //   const { add_location = {} } = editData;
      // setLocation(editData?.address_components)
      setLocation(editData?.location);
      setAddressType(editData?.address_type);
      setAddressLine1(editData?.fieldState?.addressLine1);
      setAddressLine2(editData?.fieldState?.addressLine2);
      setCity(editData?.fieldState?.city);
      setState(editData?.fieldState?.state);
      setCountry(editData?.fieldState?.country);
      setPostalCode(editData?.fieldState?.postalCode);
    }
  }, [editData]);

  const validate = () => {
    let localError = false;
    let localErrorText = {};

    // If the input fields are not empty, then return error false
    if (
      !!location &&
      !!address_type &&
      !!addressLine1 &&
      !!city &&
      !!state &&
      !!country &&
      !!postalCode
    )
      return false;

    if (!location) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        location: errorMessages.required,
      };
    }
    if (!address_type) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        address_type: errorMessages.required,
      };
    }
    if (!addressLine1) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        addressLine1: errorMessages.required,
      };
    }
    if (!city) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        city: errorMessages.required,
      };
    }
    if (!state) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        state: errorMessages.required,
      };
    }
    if (!country) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        country: errorMessages.required,
      };
    }
    if (!postalCode) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        postalCode: errorMessages.required,
      };
    }

    setErrorText(localErrorText);

    return localError;
  };

  const handleSave = () => {
    const validation = validate();

    // Validate fields
    if (validation) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: errorMessages.fieldsRequired,
      });
    } else {
      let clientAddress = isEdit ? { ...editData } : {};
      clientAddress = {
        // ...location,
        ...clientAddress,
        location: location,
        add_location: location,
        address_type,
        fieldState: {
          addressLine1,
          addressLine2,
          city,
          state,
          country,
          postalCode,
        },
        addressLine1,
        addressLine2,
        city,
        area: city,
        state,
        country,
        postalCode,
        // id: uuidv4(),
      };

      // alert(JSON.stringify(location))
      onSave(clientAddress);
    }
  };

  //   Maps autocomplete Callback
  const handleOnPlaceSelect = (addressContainer) => {
    debugger;
    // If none of these property exists in the value object, then return;
    if (
      !(
        !!addressContainer?.address &&
        !!addressContainer?.address_components &&
        !!addressContainer?.latitude &&
        !!addressContainer?.longitude
      )
    ) {
      return;
    } else {
      let addrLine1 = `${
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("street_number")
        )?.[0]?.long_name ?? ""
      } ${
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("route")
        )?.[0]?.long_name ?? ""
      }`;
      let addrLine2 = `${
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("sublocality")
        )?.[0]?.long_name ?? ""
      }`;
      // let street_number = value?.address_components?.filter(_ => _?.types?.includes("street_number"))?.[0]?.long_name ?? ""
      // let route = value?.address_components?.filter(_ => _?.types?.includes("route"))?.[0]?.long_name ?? ""
      let locality =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("locality")
        )?.[0]?.long_name ?? "";

      let administrative_area_level_2 =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("administrative_area_level_2")
        )?.[0]?.long_name ?? "";

      let administrative_area_level_1 =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("administrative_area_level_1")
        )?.[0]?.long_name ?? "";

      let countryName =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("country")
        )?.[0]?.long_name ?? "";

      let postal_code =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("postal_code")
        )?.[0]?.long_name ?? "";

      // Set All the states
      setLocation(addressContainer);
      setAddressLine1(addrLine1);
      setAddressLine2(addrLine2);

      if (locality === null) {
        setCity(administrative_area_level_2);
      } else if (administrative_area_level_2 === null) {
        setCity(locality);
      } else {
        setCity(administrative_area_level_2);
      }

      setState(administrative_area_level_1);
      setCountry(countryName);
      setPostalCode(postal_code);
    }
  };

  // Disable Country & Postal code if filled
  const checkCountryPostalCodeDisability = () => {
    if (typeof location === typeof "") {
      return false;
    } else {
      if (typeof location === "object" && location !== null) {
        if (typeof location?.address === typeof "") {
          return true;
        }
      }
    }
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          pt: 2,
          pb: 1.5,
          px: 1,
          alignItems: "center",
          border: 1,
          borderColor: "grey.border",
          position: "absolute",
          right: 0,
          left: 0,
          top: 0,
        }}
      >
        <IconButton onClick={handleClose} aria-label="close-drawer" id={`closeBtn${parentID}`}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ flex: 1, textAlign: "center", ml: "-44px" }}>
          {isEdit ? `Edit Address` : `Add Address`}
        </Typography>
      </Stack>
      <Box
        sx={{
          mb: 6.5,
          mt: 8.25,
          py: 2.5,
          px: 2.5,
          maxHeight: "calc(100vh - 52px + 66px)",
          overflow: "scroll",
        }}
      >
        {/* ADDRESS FIELDS */}

        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <CustomSelect
              label={"Address Type"}
              value={address_type}
              onChange={(event, newValue) => {
                setAddressType(newValue);
              }}
              options={options}
              size="small"
              InputProps={{
                required: true,
                error: !!errorText["address_type"],
                helperText: errorText["address_type"],
              }}
              id={`addressType${parentID}`}
            />
          </Grid>

          <Grid item xs={12}>
            <AddressAutoComplete
              value={location}
              handleOnPlaceSelect={handleOnPlaceSelect}
              textFieldProps={{
                required: true,
                error: !!errorText["location"],
                helperText: errorText["location"],
                id:`selectLocation${parentID}`
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Address Line 1"}
              value={addressLine1}
              onChange={(event) => setAddressLine1(event.target.value)}
              size="small"
              required
              error={!!errorText["addressLine1"]}
              helperText={errorText["addressLine1"]}
              id={`addressLine1${parentID}`}
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Address Line 2"}
              value={addressLine2}
              onChange={(event) => setAddressLine2(event.target.value)}
              size="small"
              id={`addressLine2${parentID}`}
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"City"}
              value={city}
              onChange={(event) => setCity(event.target.value)}
              size="small"
              required
              error={!!errorText["city"]}
              helperText={errorText["city"]}
              id={`city${parentID}`}
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"State"}
              value={state}
              onChange={(event) => setState(event.target.value)}
              size="small"
              required
              error={!!errorText["state"]}
              helperText={errorText["state"]}
              id={`state${parentID}`}
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Country"}
              value={country}
              onChange={(event) => setCountry(event.target.value)}
              size="small"
              required
              error={!!errorText["country"]}
              helperText={errorText["country"]}
              disabled={checkCountryPostalCodeDisability() && country}
              id={`country${parentID}`}
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Postal Code"}
              id={`postalCode${parentID}`}
              value={postalCode}
              onChange={(event) => setPostalCode(event.target.value)}
              size="small"
              required
              error={!!errorText["postalCode"]}
              helperText={errorText["postalCode"]}
              disabled={
                checkCountryPostalCodeDisability() &&
                props?.data?.add_location?.fieldState?.postalCode &&
                postalCode
              }
              {...styleProp}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        <CustomButton
          fullWidth
          sx={{ borderRadius: 0, py: 2 }}
          endIcon={<RightArrowStripesIcon />}
          onClick={handleSave}
          id={`${isEdit ? "updateAddress" : "saveAddress"}${parentID}`}
        >
          {isEdit ? "Update Address" : "Save Address"}
        </CustomButton>
      </Box>
    </>
  );
}

export default withSnackBar(AddAddressDrawer);
