import React, { useState, useEffect } from "react";
import {
  CustomDatePicker,
  CustomSelect,
  CustomTextfield,
  CustomTextfieldWithSelect,
  DrawerSkeleton,
  StyledToggleButtonGroup,
} from "../../../../../components";
import { UploadProfileImage } from "../../../../../components/profileImageUpload";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { genderOptions, snackProps } from "../../../../../utils";
import { withSnackBar } from "../../../../../HOC's";

const initialPersonalDetailsState = {
  profileImage: "",
  salutation: "",
  name: "",
  primaryEmail: "",
  dob: null,
  gender: "",
  occupation: "",
  languages: [],
};

function PersonalDetailForm({
  editData,
  handleClose,
  dependencyOptions,
  ...props
}) {
  const [state, setState] = useState({ ...initialPersonalDetailsState });
  const [errorText, setErrorText] = useState({});
  const [fieldsToValidate] = useState([
    "name",
    "salutation",
    "primaryEmail",
    "dob",
    "gender",
  ]);

  useEffect(() => {
    setState(editData);
  }, [editData]);

  const updateFieldState = (value, key) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const validate = () => {
    let error = {};
    let errorText = {};
    fieldsToValidate.map((field) => {
      switch (field) {
        case "name": {
          if (state["name"] === "") {
            error[field] = true;
            errorText[field] = "Name should not be empty";
          }
          break;
        }
        case "salutation": {
          if (state["salutation"] === "") {
            error[field] = true;
            errorText[field] = "Salutation should not be empty";
          }
          break;
        }
        case "primaryEmail": {
          if (state["primaryEmail"] === "") {
            error[field] = true;
            errorText[field] = "Primary Email should not be empty";
          }
          break;
        }
        case "dob": {
          if (state["dob"] === null) {
            error[field] = true;
            errorText[field] = "DOB should not be empty";
          }
          break;
        }
        case "gender": {
          if (state["gender"] === "") {
            error[field] = true;
            errorText[field] = "Gender should not be empty";
          }
          break;
        }
        default:
          return "";
      }
      return field;
    });

    setErrorText(errorText);
    return { error, errorText };
  };

  const saveOnUpdate = () => {
    const { error } = validate();

    // Error Message - Snackbar
    if (Object.keys(error)?.length > 0) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Please fill required fields",
      });
    }

    // Update on Parent
    props.onUpdate(state);
  };

  return (
    <DrawerSkeleton
      renderTitle={() => (
        <Typography
          sx={{ flex: 1, textAlign: "center", fontWeight: 500, ml: "-44px" }}
        >
          Edit Personal Details
        </Typography>
      )}
      handleClose={handleClose}
      handleConfirm={saveOnUpdate}
      buttonText={"Update"}
    >
      <Stack>
        {/* Profile Picture */}
        <Stack alignItems={"center"} sx={{ my: 2.5 }}>
          <UploadProfileImage
            value={state["profileImage"]}
            label={"profile_img"}
            profile_pic={state["profileImage"]}
            handleFileChange={(e) => {
              updateFieldState(e?.target?.files?.[0], "profileImage");
            }}
            error={false}
            errorText={""}
            id={"profile_img"}
          />
        </Stack>

        {/* Name */}
        <CustomTextfieldWithSelect
          sx={{ mt: 1, mb: 2, gap: 1 }}
          label={"Name"}
          selectLabel={"Salutation"}
          textFieldProps={{
            id: "",
            name: "Name",
            required: true,
          }}
          selectProps={{
            id: "",
            name: "Salutation",
            required: true,
          }}
          selectOptions={dependencyOptions?.salutation}
          fullWidth
          selectValue={state["salutation"]}
          value={state["name"]}
          // onSelectChange={(e, value) => console.log(value?)}
          onSelectChange={(e, value) => {
            let data = dependencyOptions?.salutation?.filter(
              (x) => x?.value === value?.value
            )?.[0];
            updateFieldState(data, "salutation");
          }}
          onChange={(e) => updateFieldState(e.target.value, "name")}
          error={errorText?.name ? true : false}
          helperText={errorText?.name}
          selectError={errorText?.salutation ? true : false}
          selectHelperText={errorText?.salutation}

          // isSalutationError={errorText?.salutation ? true : false}
          // inputError={errorText?.name ? true : false}
          // helperInputText={errorText?.name}
        />

        {/* Email */}
        <CustomTextfield
          fullWidth
          // size="small"
          type={"email"}
          sx={{ mt: 1, mb: 2 }}
          onChange={(e) => updateFieldState(e.target.value, "primaryEmail")}
          value={state["primaryEmail"]}
          label="Primary Email Address"
          error={errorText?.primaryEmail ? true : false}
          helperText={errorText?.primaryEmail}
          required
        />

        {/* DOB */}
        <CustomDatePicker
          fullWidth
          label={"DOB"}
          value={state["dob"]}
          disableFuture
          // size="small"
          // sx={{ marginY: 1, color: "primary.main" }}
          InputProps={{
            marginY: 2,
            fullWidth: true,
            required: true,
            helperText: errorText?.dob,
            error: errorText?.dob ? true : false,
          }}
          onChange={(newDate) =>
            updateFieldState(moment(newDate).toISOString(), "dob")
          }
          helperText={errorText?.dob}
          error={errorText?.dob ? true : false}
        />

        {/* Gender */}
        <StyledToggleButtonGroup
          id={"gender"}
          label={"Gender"}
          // size="small"
          data={{ value: state["gender"] }}
          sx={{ marginY: 2 }}
          options={genderOptions}
          onChange={(e, value) => {
            //   console.log(value)
            updateFieldState(value, "gender");
          }}
          error={errorText?.gender ? true : false}
          helperText={errorText?.gender}
        />

        {/* Occupation */}
        <CustomTextfield
          fullWidth
          value={state["occupation"]}
          onChange={(e) => updateFieldState(e.target.value, "occupation")}
          sx={{ marginY: 2 }}
          label="Occupation"
          error={errorText?.occupation ? true : false}
          helperText={errorText?.occupation}
          // required
        />

        {/* Preferred Language */}
        <CustomSelect
          // id={"my order status filter"}
          sx={{ marginY: 2 }}
          options={dependencyOptions?.language}
          filterSelectedOptions
          loading={false}
          value={state["languages"]}
          label={"Preferred Languages"}
          onChange={(e, value) => updateFieldState(value, "languages")}
          // onChange={(e, value) => console.log(value)}
          multiple={true}
          // size="small"
          error={errorText?.languages ? true : false}
          helperText={errorText?.languages}
        />
      </Stack>
    </DrawerSkeleton>
  );
}

export default withSnackBar(PersonalDetailForm);
