import * as React from "react";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorSecondary,
  },
  cls2: {
    fill: "none",
    stroke: (props) => props.htmlColorPrimary,
  },
}));

function PhoneDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Group 95306">
        <path
          d="M23.717 5.414 21.6 3.234a.73.73 0 0 0-1.235.38v1.39h-5.737v-1.39a.721.721 0 0 0-1.228-.38l-2.12 2.18a.726.726 0 0 0-.28.58.741.741 0 0 0 .282.61l2.12 2.18a.722.722 0 0 0 .8.16.73.73 0 0 0 .428-.54v-1.4h5.734v1.4a.73.73 0 0 0 1.235.38l2.12-2.18a.774.774 0 0 0 .281-.61.758.758 0 0 0-.283-.58Z"
          className={classes.cls1}
        />
        <path
          d="M19.9 17.178v2.851a1.9 1.9 0 0 1-2.072 1.9 18.806 18.806 0 0 1-8.2-2.917 18.531 18.531 0 0 1-5.7-5.7 18.806 18.806 0 0 1-2.919-8.24A1.9 1.9 0 0 1 2.9 3h2.851a1.9 1.9 0 0 1 1.9 1.634A12.2 12.2 0 0 0 8.316 7.3a1.9 1.9 0 0 1-.427 2.01l-1.207 1.207a15.2 15.2 0 0 0 5.7 5.7l1.207-1.2a1.9 1.9 0 0 1 2.005-.428 12.2 12.2 0 0 0 2.67.665 1.9 1.9 0 0 1 1.634 1.929Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
          className={classes.cls2}
        />
      </g>
    </SvgIcon>
  );
}

PhoneDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default PhoneDuoToneIcon;
