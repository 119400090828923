import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { styled } from "@mui/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const RemoveTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.ternary.main,
  transform: "translate(0, 1.5px) scale(0.85)",
  transformOrigin: "top left",
  cursor: "pointer",
  color: "#FF4D4A"
}));

const LabelTypography = styled(Typography)({
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-line-clamp": 3,
  "-webkit-box-orient": "vertical",
});

export const SimpleList = (props) => {
  return (
    <Grid
      container
      item
      xs="12"
      sx={{
        margin: "8px 0px",
      }}
    >
      <Grid item xs={10}>
        <Stack flexDirection="row" gap={1.5}>
          <div>
            <div>
              <FiberManualRecordIcon
                fontSize="small"
                htmlColor="#3082F0"
                sx={{ fontSize: "0.85rem" }}
              />
            </div>
          </div>
          <LabelTypography variant="body1" component="div">
            {/* <span style={{ paddingRight: "4px" }}>&#8226;</span> */}
            {props.text}
          </LabelTypography>
        </Stack>
      </Grid>
      <Grid container item xs={2} justify="flex-end">
        <RemoveTypography
          variant="body1"
          component="div"
          shrink
          onClick={props.handleRemove}
        >
          {"Remove".toUpperCase()}
        </RemoveTypography>
      </Grid>
    </Grid>
  );
};
