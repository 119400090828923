import moment from "moment";
import { isObject } from "./sanityChecks";

export const themes = {
  default: "default",
  dark: "dark",
};

export const tanyacareContactInfo = {
  mail: "reach.us@tanya.care",
  phone: "1300-13-2511",
};

export const mobileAppStoreLinks = {
  playStore: "https://play.google.com/store/apps/details?id=com.my_tanya_care",
  huaweiStore: "https://appgallery.huawei.com/app/C104504033",
};

export const SubscriptionType = [
  {
    label: "Service",
    value: "b6aea2a2-f752-4e82-9db4-bc6514f9f2ea",
    color: "#7871B0",
  },
  {
    label: "Product",
    value: "8641fabf-feb6-4b14-b060-5ffe75388d1c",
    color: "#31B198",
  },
  {
    label: "Package",
    value: "3c968f29-e785-4910-b62a-b7a8a8960c23",
    color: "#F67280",
  },
];

export const SortBy = {
  full: [
    {
      label: "Most Recent",
      value: "most_recent",
    },
    {
      label: "Price - Low to High",
      value: "low_high",
    },
    {
      label: "Price - High to Low",
      value: "high_low",
    },
    {
      label: "A - Z",
      value: "ASC",
    },
    {
      label: "Z - A",
      value: "DESC",
    },
  ],
  onlyAlphapets: [
    {
      label: "Most Recent",
      value: "most_recent",
    },
    {
      label: "A - Z",
      value: "ASC",
    },
    {
      label: "Z - A",
      value: "DESC",
    },
  ],
};

export const snackProps = {
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
};

export const drawerProps = {
  drawerWidth: 320,
  closeIconHiden: false,
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

export const currencyPriceFormatter = (currency, price) => {
  return `${currency ? currency + " " : ""}${
    isNaN(parseFloat(price)) ? "0.00" : parseFloat(price).toFixed(2).toString()
  }`;
};

export const dateTimeFormatter = (dateTime, withOutTime) => {
  const formatString = withOutTime ? "MMM D, ddd" : "MMM D, ddd, hh:mmA";
  return moment(dateTime).isValid()
    ? moment(dateTime).format(formatString)
    : dateTime;
};

export const dateTimeFormatterV2 = (dateTime, withOutTime) => {
  const formatString = "Do MMM, yyyy";
  return moment(dateTime).isValid()
    ? moment(dateTime).format(formatString)
    : dateTime;
};

export const loadingArray = (size = 1) => {
  return Array.from(new Array(size));
};

export const getValues = (data) => {
  return data?.length > 0 ? data?.map((item) => `${item?.value}`) : [];
};

/** -----------------------------------MY ORDERS----------------------------------- */
export const ORDER_STATUS = {
  success: "62f1cd34-53e2-4a0a-abb0-a24387016987",
  failed: "ee8164a9-4552-4ffb-896e-1b9d4abad900",
  initiated: "f8eb18e9-2b6d-4aab-9cac-903630aca871",
};

export const EnquiryStatus = {
  enquiry_created: (theme) => theme.palette.secondary.main,
  quote_submitted: (theme) => theme.palette.secondary.main,
  quote_finalized: (theme) => theme.palette.success.main,
  payment_completed: (theme) => theme.palette.success.main,
  enquiry_closed: (theme) => theme.palette.error.main,
  enquiry_cancelled: (theme) => theme.palette.error.main,
};

export const ENQUIRY_STATUS = {
  cancelled: "enquiry_cancelled",
  closed: "enquiry_closed",
  finalized: "quote_finalized",
  purchased: "payment_completed",
};

export const HELP_DESK_CONTACT_NUMBER = "1300132511";

/**-------------------------------------Subscription Status-------------------------- */
export const SUBSCRIPTION_STATUS = {
  cancelled: "subscription_cancelled",
  requested: "refund_requested",
  approved: "refund_approved",
  rejected: "refund_not_approved",
  completed: "refund_completed",
};

/** ------------------------------------Activity Mode -------------------------------- */
export const ACTIVITY_MODE_TYPE = {
  VIDEO: "VIDEO_CALL",
  IN_PERSON: "IN_PERSON",
};

export const durationText = (duration) => {
  const durationInt = parseInt(duration ? duration : 0);
  const hours = parseInt(durationInt / 60);
  const minutes = parseInt(durationInt % 60);
  return `${hours > 0 ? hours : ""}${
    hours > 0 ? (hours <= 1 ? " hour" : " hours") : ""
  }${minutes > 0 ? (hours > 0 ? " " : "") + minutes : ""}${
    minutes > 0 ? (minutes <= 1 ? " minute" : " minutes") : ""
  }`;
};

export const getFormattedName = (salutation, name) => {
  if (!name) return "";
  return salutation ? `${salutation}. ${name}` : name;
};

export const getFormattedAddress = (address = {}) => {
  const { addressLine1, addressLine2, area, state, country, postalCode } =
    address;

  let formattedAddress = "";

  if (addressLine1) {
    formattedAddress += `${addressLine1},`;
  }
  if (addressLine2) {
    formattedAddress += ` ${addressLine2},`;
  }
  if (area) {
    formattedAddress += ` ${area},`;
  }
  if (state) {
    formattedAddress += ` ${state},`;
  }
  if (country) {
    formattedAddress += ` ${country},`;
  }
  if (postalCode) {
    formattedAddress += ` ${postalCode}`;
  }

  return formattedAddress;
};

export const addressComponentTypes = {
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  area: "area", //city
  state: "state",
  country: "country",
  postalCode: "postalCode",
};
export const textClampStyleGenerator = (lines = 1) => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
};

export const initialFilterState = {
  type: [],
  frequency: [],
  modeOfActivity: [],
  serviceProfessionals: [],
  activityStatus: [],
  orderStatus: [],
  enquiryStatus: [],
  submittedBy: {},
  fromDate: null,
  toDate: null,
  sortBy: {
    label: "Most Recent",
    value: "most_recent",
  },
};

// export const generateTestProps = (label = "Test Id") => {
//   const id = label?.split(" ").join("_");
//   return id;
// };

export const fileLessThanTenMb = (fileSize) => {
  return fileSize <= 10485760;
};

export const videoFormats = [
  "m1v",
  "mpeg",
  "mov",
  "qt",
  "mpa",
  "mpg",
  "mpe",
  "avi",
  "movie",
  "mp4",
];

export const imageFormats = ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd"];

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const bmiCalculation = (height = 1, weight = 1) => {
  weight = parseFloat(weight);
  height = parseFloat(height);
  let BMI = Math.round((weight / Math.pow(height, 2)) * 10000);
  return isNaN(BMI) ? "" : BMI;
};

export const defaultArray = (data) => {
  return Array.isArray(data) && data?.length > 0 ? data : [];
};

export const maskString = (str = "-".repeat(10), maskCount = 2) => {
  str = str + "";
  const size = str?.length;
  if (size > maskCount * 2) {
    return (
      str.slice(0, maskCount) +
      "*".repeat(size - maskCount * 2) +
      str.slice(size - maskCount, size)
    );
  } else {
    return str;
  }
};

export const maskEmail = (item) => {
  var parts = item.split("@");
  // len = parts[0].length;

  return item.replace(parts[0].slice(3), "*".repeat(6));
};

export const activityStatus = [
  {
    label: "Scheduled",
    value: "Scheduled",
  },
  {
    label: "In progress",
    value: "In progress",
  },
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "Closed",
    value: "Closed",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
];

export const activityMode = [
  {
    label: "Video Call",
    value: "VIDEO_CALL",
  },
  {
    label: "In Person",
    value: "IN_PERSON",
  },
];

export const getYear = (date) => {
  if (moment(date).isValid) {
    return moment().diff(date, "years");
  }
  return 0;
};

export const getMonth = (date) => {
  if (moment(date).isValid) {
    return moment().diff(date, "months");
  }
  return 0;
};

export const getDay = (date) => {
  if (moment(date).isValid) {
    return moment().diff(date, "days");
  }
  return 0;
};

export const getDaysMonthsYears = (date) => {
  if (moment(date).isValid) {
    return {
      years: getYear(date),
      months: getMonth(date),
      days: getDay(date),
    };
  }
  return {
    years: 0,
    months: 0,
    days: 0,
  };
};

export const getAgeString = (date) => {
  if (moment(date).isValid) {
    const { years, months, days } = getDaysMonthsYears(date);
    if (years > 0) {
      return `${years} ${years > 1 ? "years" : "year"}`;
    } else if (months > 0) {
      return `${months} ${months > 1 ? "months" : "month"}`;
    } else if (days > 0) {
      return `${days} ${days > 1 ? "days" : "day"}`;
    }
  }
  return "";
};

export const getGenderString = (gender) => {
  if (isObject(gender)) return gender?.label;
  switch (gender?.toLowerCase()) {
    case "male":
      return "Male";
    case "female":
      return "Female";
    case "other":
      return "Other";
    default:
      return "";
  }
};

export const sortByEmergency = (data) => {
  const primaryData = data?.filter((item) => item?.isEmergencyContact === true);
  const otherData = data?.filter((item) => item?.isEmergencyContact !== true);
  return [...primaryData, ...otherData];
};

export const sortByPrimary = (data) => {
  const primaryData = data?.filter((item) => item?.isPrimary === true);
  const otherData = data?.filter((item) => item?.isPrimary !== true);
  return [...primaryData, ...otherData];
};

export const getSearchParamsString = (search, field) => {
  let queryValue = new URLSearchParams(search);
  if (queryValue.has(field)) {
    return queryValue.get(field);
  } else {
    return "";
  }
};

export const isTrialAccountType = () => {
  return process.env.REACT_APP_IS_TRIAL === "true";
};

export const trialPopupStorageIdentifier = "showTrialPopup";

export const storeTrialPopupState = (state) => {
  window.localStorage.setItem(
    trialPopupStorageIdentifier,
    state ? "true" : "false"
  );
};
export const avatarColors = {
  0: "#2eb5f0",
  1: "#F67280",
  2: "#f27d35",
  3: "#353596",
  4: "#e74c3c",
  5: "#1abc9c",
  6: "#f1c40f",
  7: "#e67e22",
  8: "#34495e",
  9: "#8e44ad",
};

// Convert an id which is a string containing numbers and alphabets to a numeric digit from 0 to 9 and return the digit as a string
export const idToDigitNumeric = (id = "0") => {
  return id
    .toString()
    .replace(/[^0-9]/g, "")
    .split("")
    .pop();
};

export const getFormattedBloodGroupLabel = (bloodGroup, returnAsObject) => {
  if (bloodGroup) {
    switch (bloodGroup) {
      case "A Positive (A+)": {
        return returnAsObject ? { group: "A", type: "+ve" } : "A Positive (A+)";
      }
      case "A Negative (A-)": {
        return returnAsObject ? { group: "A", type: "-ve" } : "A Negative (A-)";
      }
      case "B Positive (B+)": {
        return returnAsObject ? { group: "B", type: "+ve" } : "B Positive (B+)";
      }
      case "B Negative (B-)": {
        return returnAsObject ? { group: "B", type: "-ve" } : "B Negative (B-)";
      }
      case "AB Positive (AB+)": {
        return returnAsObject
          ? { group: "AB", type: "+ve" }
          : "AB Positive (AB+)";
      }
      case "AB Negative (AB-)": {
        return returnAsObject
          ? { group: "AB", type: "-ve" }
          : "AB Negative (AB-)";
      }
      case "O Positive (O+)": {
        return returnAsObject ? { group: "O", type: "+ve" } : "O Positive (O+)";
      }
      case "O Negative (O-)": {
        return returnAsObject ? { group: "O", type: "-ve" } : "O Negative (O-)";
      }
      default: {
        return "";
      }
    }
  }
  return "";
};

export const SOURCE_TYPE = {
  ELP: "Website",
  CLIENT_APP: "Client App",
};

// Function that takes a space seperated string and returns camelcase string with whitespace removed and append a ID at the end
export const createTestProps = (str = "default test id", id) => {
  let testId = str
    ?.toLowerCase()
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "");

  if (!!id) {
    testId = testId.concat(`${id}`);
  }

  // console.log(`%c ${testId}`, "background: #4B3F72; color: #ffffff");

  return { id: testId, "aria-labelledby": testId };
};
