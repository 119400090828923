import React from "react";
import logo from "../../assets/icons/logo.png";
import mail from "../../assets/icons/mail.png";
import phone from "../../assets/icons/phone.svg";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { tanyacareContactInfo } from "../../utils";

const styles = (theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "70px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  grid2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f0f2f6",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      background: "#ffffff",
    },
  },
});

class Screen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <div style={{ padding: "40px" }}>
        <div>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item md={7}>
              <img alt="logo" src={logo} />
            </Grid>
            <Grid item md={3}>
              <div style={{ display: "flex", justifyContent:"flex-end"  }}>
                <img alt="logo" src={mail} />
                <div
                  style={{
                    color: "#0063e7",
                    fontSize: "12px",
                    paddingLeft: "5px",
                  }}
                >
                  {tanyacareContactInfo.mail}
                </div>{" "}
              </div>
            </Grid>
            <Grid item md={2}>
              <div style={{ display: "flex", justifyContent:"flex-end"  }}>
                <img alt="logo" src={phone} />
                <span
                  style={{
                    color: "#0063e7",
                    fontSize: "12px",
                    paddingLeft: "5px",
                  }}
                >
                  {tanyacareContactInfo.phone}
                </span>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.flexCenter}>
          <img
            alt="Remy Sharp"
            style={{ marginTop: "50px" }}
            src={this.props.imageURL}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Screen);
