import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function MobileFilledIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.104 15.106a13.344 13.344 0 0 1-1.707-.124 12.987 12.987 0 0 1-1.467-.378.901.901 0 0 0-.905.225l-1.967 1.959a13.646 13.646 0 0 1-3.37-2.482A13.668 13.668 0 0 1 7.21 10.93l1.957-1.977a.904.904 0 0 0 .224-.906c-.154-.482-.28-.972-.376-1.469a13.418 13.418 0 0 1-.125-1.71A.89.89 0 0 0 8 4H4.887A.878.878 0 0 0 4 4.889a15.286 15.286 0 0 0 4.406 10.697A15.228 15.228 0 0 0 19.083 20a.88.88 0 0 0 .887-.889v-3.119a.89.89 0 0 0-.866-.886Z"
        fill="#0063E7"
      />
    </SvgIcon>
  );
}

export default MobileFilledIcon;
