import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Stack, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useWindowDimensions from "./useWindowDimensions";
import { usePosition } from "./usePosition";
import useSize from "@react-hook/size";

/**
 * Problems faced while maintaining the bottom stepper/progress at center of the text that we provided.
 * 1. Cannot have a responsive design when using transform and calc utils
 *
 * To solve this,
 *
 * Need 3 things -
 *  1. Window width
 *  2. The step text width
 *  3. The step text position
 *
 *  Using these 3 we can think of a solution as follows,
 *
 * Consider the whole screen as 100%,
 * First, Calculate the left side percentage of the text element center of window width (left + (width/2), windowWidth)
 * Second, Find percentage of left pixel of window width
 * Enjoy the magic!
 *
 * @returns object
 */
export default function SignupStepper(props) {
  // Component States
  const [activeStep, setActiveStep] = React.useState(1);

  useEffect(() => {
    setActiveStep(props.activeStep);
  }, [props.activeStep]);

  // Hooks
  const { width: windowWidth } = useWindowDimensions();

  // Step 1 element needs
  const step1Ref = useRef(null);
  const { left: step1Left } = usePosition(step1Ref);
  const [step1Width] = useSize(step1Ref);

  // Step 2 element needs
  const step2Ref = useRef(null);
  const { left: step2Left } = usePosition(step2Ref);
  const [step2Width] = useSize(step2Ref);

  // Step 3 element needs
  const step3Ref = useRef(null);
  const { left: step3Left } = usePosition(step3Ref);
  const [step3Width] = useSize(step3Ref);

  /**
   *
   * @param {number} left Left position from window of the element.
   * @param {number} elementWidth Width of the element.
   * @param {number} currentWindowWidth Width of the window.
   * @returns {number} Center Position of the text from left (in px)
   */
  const getFinalEffort = (left, elementWidth, currentWindowWidth) => {
    function valueOfPercentage(num, per) {
      return (num / 100) * per;
    }

    function whatPercentOf(dash1, dash2) {
      return (dash1 / dash2) * 100;
    }

    const position1Percentage = whatPercentOf(
      left + elementWidth / 2,
      currentWindowWidth
    );

    const finalEffort = valueOfPercentage(currentWindowWidth, position1Percentage);

    return `translateX(calc(-100vw - -${finalEffort}px)) !important`;
  };

  // console.log("==================STEP-1======================");
  // console.log(getFinalEffort(step1Left, step1Width, windowWidth));
  // console.log("==================STEP-2======================");
  // console.log(getFinalEffort(step2Left, step2Width, windowWidth));
  // console.log("==================STEP-3======================");
  // console.log(getFinalEffort(step3Left, step3Width, windowWidth));

  // console.log("==========Window h, w ==============")
  // console.log(windowHeight, windowWidth);

  // console.log("==========Element h, w ==============")
  // console.log(height, width );

  // console.log("==========POSITION==============")
  // console.log(step1Left, step1Top);
  // console.log(step1Ref)

  // console.log("==========step1Left + (width/2)==============")
  // console.log(step1Left + (width/2))

  // console.log("============finalEffort=====================")
  // console.log(finalEffort)

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        flexDirection="row"
        gap={2}
        divider={<ChevronRightIcon fontSize="small" />}
        justifyContent="center"
        sx={{ width: "100%", py: 2.5 }}
      >
        <Typography ref={step1Ref} color={activeStep === 1 ? "textPrimary" : "textSecondary"} fontWeight={"500"} >Create Your Profile</Typography>
        <Typography ref={step2Ref} color={activeStep === 2 ? "textPrimary" : "textSecondary"} fontWeight={"500"} >Add Your Family Members Details</Typography>
        <Typography ref={step3Ref} color={activeStep === 3 ? "textPrimary" : "textSecondary"} fontWeight={"500"} >Add Your Health Info</Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={24}
        color="success"
        sx={{
          top: "4px",
          "&.MuiLinearProgress-root": {
            bgcolor: "unset",
            overflow: "unset",
          },
          "& .MuiLinearProgress-bar": {
            transform:
              activeStep === 1
                ? getFinalEffort(step1Left, step1Width, windowWidth)
                : activeStep === 2
                ? getFinalEffort(step2Left, step2Width, windowWidth)
                : activeStep === 3
                ? getFinalEffort(step3Left, step3Width, windowWidth)
                : "",

            "&:after": {
              content: `"${activeStep}"`,
              position: "absolute",
              right: 0,
              // transform: "translate(50%, -50%)",
              transform: "translate(100%, -40%)",
              // left: "50%",
              // top: "-50%",
              width: 18,
              height: 18,
              bgcolor: "success.main",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%"
            }
          },
        }}
      />


    </Box>
  );
}
