import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

const CustomizedTextfield = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "helperTextNoWrap",
})(({ theme, helperTextNoWrap }) => ({
  ...(helperTextNoWrap && {
    "& .MuiFormHelperText-root": {
      whiteSpace: "nowrap",
    },
  }),

  // Add if there is any custom styles
  // "& .MuiOutlinedInput-root": {
  //   "& > fieldset": {
  //     borderColor: "rgba(53, 53, 150, 0.2)",
  //     "&$focused $notchedOutline": {
  //       borderColor: "rgba(53, 53, 150, 0.2)",
  //     },
  //   },
  // },
  // "&$focused $notchedOutline": {
  //   borderColor: "rgba(53, 53, 150, 0.2)",
  // },
  // '&:hover $notchedOutline': {
  //   borderColor: 'orange'
  // },
  // focused: {},
  // notchedOutline: {},

  // New
  // '& label': {
  //   color: '#9297a5',
  // },
  // '& label.Mui-focused': {
  //   color: '#9297a5',
  // },
  // '& .MuiInput-underline:after': {
  //   borderBottomColor: 'rgba(53, 53, 150, 0.2)',
  // },
  // '& .MuiOutlinedInput-root': {
  //   '& fieldset': {
  //     borderColor: 'rgba(53, 53, 150, 0.2)',
  //   },
  //   '&:hover fieldset': {
  //     borderColor: 'rgba(53, 53, 150, 0.2)',
  //   },
  //   '&.Mui-focused fieldset': {
  //     borderColor: 'rgba(53, 53, 150, 0.2)',
  //   },
  //   "&.Mui-error": {
  //     '&:hover fieldset': {
  //       borderColor: theme.palette.error.main,
  //     },
  //   }
  // },
}));

function CustomTextfield(props) {
  return (
    <CustomizedTextfield
      {...props}
      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      inputProps={props.inputProps}
    />
  );
}

CustomTextfield.propTypes = {
  /**
   * The id of the input element. Use this prop to make label and helperText accessible for screen readers.
   */
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextNoWrap: PropTypes.bool,
};

CustomTextfield.defaultProps = {
  id: "textfield",
  name: "",
  placeholder: "",
  label: "",
  value: "",
  error: false,
  helperText: "",
  helperTextNoWrap: false,
};

export default CustomTextfield;
