import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { CustomAvatar, CustomChip, CustomButton } from "../../..";
import { styled } from "@mui/system";
import {
  currencyPriceFormatter,
  dateTimeFormatterV2,
  ORDER_STATUS,
} from "../../../../utils";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));

const pxDynamic = { xs: 2, md: 3 };

function ItemOverview({ loading, handleRedirect = () => null, ...props }) {
  const data = props?.data;
  return (
    <Stack
      sx={{
        pt: 3,
        px: pxDynamic,
        pb: 3,
        flexDirection: { md: "row" },
        gap: { xs: 2, md: 3 },
      }}
    >
      <Stack
        sx={{
          justifyContent: { xs: "center", md: "flex-start" },
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <CustomAvatar
          loading={loading}
          src={data?.services?.[0]?.image}
          width={60}
          height={60}
        />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={0.5}
          sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
        >
          {loading ? (
            <Skeleton width="75%" sx={{ mb: { xs: 0.75, md: 0.25 } }} />
          ) : (
            <CustomTypography
              fontWeight={"500"}
              sx={{ mb: { xs: 0.75, md: 0.25 } }}
            >
              {!!data?.services?.[0]?.name ? data?.services?.[0]?.name : ""}
            </CustomTypography>
          )}
          {/* <CustomTypography color="textSecondary" variant="body2">(Monthly)</CustomTypography> */}
        </Stack>
        {/* <CustomTypography color="textSecondary" variant="body2" style={{ lineHeight: 1 }}>Pratco Healthcare</CustomTypography> */}
        {loading ? (
          <Skeleton width="50%" sx={{ mx: { xs: "auto", md: "unset" } }} />
        ) : (
          <Stack
            flexDirection="row"
            alignItems="flex-end"
            gap={1}
            sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
          >
            {data?.services?.[0]?.price &&
            data?.services?.[0]?.discountPercentage ? (
              <>
                <CustomTypography fontWeight={"500"} variant="body2">
                  {currencyPriceFormatter(
                    data?.services?.[0]?.currency,
                    data?.services?.[0]?.discount
                  )}
                </CustomTypography>
                <CustomTypography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textDecoration: "line-through" }}
                >
                  {currencyPriceFormatter(
                    data?.services?.[0]?.currency,
                    data?.services?.[0]?.price
                  )}
                </CustomTypography>
              </>
            ) : (
              <CustomTypography fontWeight={"500"}>
                {currencyPriceFormatter(
                  data?.services?.[0]?.currency,
                  data?.services?.[0]?.discount
                )}
              </CustomTypography>
            )}
          </Stack>
        )}
        {loading ? (
          <Skeleton width="30%" sx={{ mx: { xs: "auto", md: "unset" } }} />
        ) : (
          !!props?.data?.orderedOn && (
            <CustomTypography
              color="textSecondary"
              variant="body2"
              sx={{ mt: 0.5 }}
            >
              {`Quantity:`}{" "}
              {data?.services?.[0]?.quantity?.toString()?.padStart(2, "0")}
            </CustomTypography>
          )
        )}
        {loading ? (
          <Skeleton width="30%" sx={{ mx: { xs: "auto", md: "unset" } }} />
        ) : (
          !!props?.data?.orderedOn && (
            <CustomTypography
              color="textSecondary"
              variant="body2"
              sx={{ my: 0.5 }}
            >
              {`${
                ORDER_STATUS.failed === props?.data?.orderStatus?.value
                  ? "Payment Failed on:"
                  : ORDER_STATUS.initiated === props?.data?.orderStatus?.value
                  ? "Payment initiated on:"
                  : "Date of Purchase:"
              }`}{" "}
              {moment(data?.orderedOn).isValid()
                ? moment(data?.orderedOn).format("Do MMM, yyyy, hh:mmA")
                : data?.orderedOn}
            </CustomTypography>
          )
        )}
        {loading ? (
          <Skeleton width="50%" sx={{ mx: { xs: "auto", md: "unset" } }} />
        ) : (
          <Stack
            flexDirection="row"
            alignItems="flex-end"
            gap={1}
            sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
          >
            <CustomTypography fontWeight={"500"}>
              Amount:{" "}
              {currencyPriceFormatter(data?.currency, data?.sellingPrice)}
            </CustomTypography>
            {/* {data?.discountPrice && (
              <CustomTypography
                variant="body2"
                color="textSecondary"
                sx={{ textDecoration: "line-through" }}
              >
                {currencyPriceFormatter(data?.currency, data?.discountPrice)}
              </CustomTypography>
            )} */}
          </Stack>
        )}
        {loading ? (
          <Skeleton
            width="25%"
            sx={{ mt: 1, mx: { xs: "auto", md: "unset" } }}
          />
        ) : (
          ORDER_STATUS.success === props?.data?.orderStatus?.value &&
          !!props?.data?.orderedOn && (
            <Stack
              sx={{
                mt: 1,
                flexDirection: "row",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Box sx={{ maxWidth: 220 }}>
                <CustomChip
                  label={`Service started on : ${dateTimeFormatterV2(
                    data?.orderedOn
                  )}`}
                  variant="outlined"
                  style={{
                    fontStyle: "unset",
                    backgroundColor: "rgba(230, 194, 43, 0.08)",
                    color: "#93823A",
                    border: 0,
                    height: "26px",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                />
              </Box>
            </Stack>
          )
        )}
      </Stack>
      <Stack
        sx={{
          justifyContent: { xs: "center", md: "flex-start" },
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <CustomButton
          onClick={() =>
            handleRedirect(
              data?.services?.[0]?.id,
              data?.services?.[0]?.versionId
            )
          }
          sx={{ whiteSpace: "nowrap" }}
          id={`viewDetails${data?.services?.[0]?.id}`}
        >
          View Details
        </CustomButton>
      </Stack>
    </Stack>
  );
}

export default ItemOverview;
