import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomButton, CustomTextfield, CustomSelect } from "../../components";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RightArrowStripesIcon } from "../../assets";
import {
  errorMessages,
  isObjectEmpty,
  snackProps,
  validateEmail,
} from "../../utils";
import { withSnackBar } from "../../HOC's";

function AddEmailDrawer(props) {
  const {
    isEdit,
    editData,
    options = [],
    onSave = () => {},
    handleClose = () => {},
  } = props;

  // Component States
  const [email_type, setEmailType] = useState("");
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState({});

  const styleProp = {
    sx: { width: "100%" },
  };

  useEffect(() => {
    console.log("-------------------------------------");
    console.log(editData);
    debugger;
    if (!isObjectEmpty(editData)) {
      setEmailType(editData?.email_type);
      setEmail(editData?.email);
    }
  }, [editData]);

  const validate = () => {
    let localError = false;
    let localErrorText = {};

    // If email type is empty
    if (!email_type) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        email_type: errorMessages.required,
      };
    }

    // If Email is empty or not valid
    if (!email) {
      localError = true;
      localErrorText = { ...localErrorText, email: errorMessages.required };
    } else if(!validateEmail(email)) {
      localError = true;
      localErrorText = { ...localErrorText, email: errorMessages.validEmail };
    }
    // If the input fields are not empty, then return error false
    else if (!!email_type && !!email) return false;

    setErrorText(localErrorText);

    return localError;
  };

  const handleSave = () => {
    const validation = validate();

    // Validate fields
    if (validation) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: errorMessages.fieldsRequired,
      });
    } else {
      let clientEmail = isEdit ? { ...editData } : {};
      clientEmail = {
        ...clientEmail,
        email_type: email_type,
        email: email,
        label: email_type?.label,
        value: email_type?.value,
        constIdentifier: email_type?.constIdentifier,
        // id: uuidv4(),
      };
      onSave(clientEmail);
    }
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          pt: 2,
          pb: 1.5,
          px: 1,
          alignItems: "center",
          border: 1,
          borderColor: "grey.border",
          position: "absolute",
          right: 0,
          left: 0,
          top: 0,
        }}
      >
        <IconButton onClick={handleClose} aria-label="close-drawer">
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ flex: 1, textAlign: "center", ml: "-44px" }}>
          {isEdit ? `Edit Email` : `Add Email`}
        </Typography>
      </Stack>
      <Box
        sx={{
          mb: 6.5,
          mt: 8.25,
          py: 2.5,
          px: 2.5,
          maxHeight: "calc(100vh - 52px + 66px)",
          overflow: "scroll",
        }}
      >
        {/* Email FIELDS */}
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <CustomSelect
              label={`Email Type`}
              value={email_type}
              onChange={(event, newValue) => {
                setEmailType(newValue);
              }}
              options={options}
              size="small"
              InputProps={{
                required: true,
                error: !!errorText["email_type"],
                helperText: errorText["email_type"],
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Email"}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              size="small"
              required
              error={!!errorText["email"]}
              helperText={errorText["email"]}
              {...styleProp}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        <CustomButton
          fullWidth
          sx={{ borderRadius: 0, py: 2 }}
          endIcon={<RightArrowStripesIcon />}
          onClick={handleSave}
        >
          {isEdit ? "Update Email" : "Save Email"}
        </CustomButton>
      </Box>
    </>
  );
}

AddEmailDrawer.propTypes = {
  editData: PropTypes.any,
  handleClose: PropTypes.func,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
  options: PropTypes.array,
};

export default withSnackBar(AddEmailDrawer);
