import PropTypes from "prop-types";
import React from "react";
import Grid from "@mui/material/Grid";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { currencyPriceFormatter } from "../../utils";
import { Divider, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

function ParticularRow({
  loading,
  particularName,
  particularAmount,
  makeNameBold,
  isPaid,
  isOffer,
}) {
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ py: 0.5 }}
    >
      <Typography {...(makeNameBold && { fontWeight: "bold" })}>
        {particularName}
      </Typography>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {loading ? (
          <Skeleton width="60px" />
        ) : (
          <Typography
            sx={{ ...(isOffer && { color: "success.main" }) }}
            fontWeight="bold"
          >
            {particularAmount}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

ParticularRow.propTypes = {
  isOffer: PropTypes.bool,
  isPaid: PropTypes.bool,
  makeNameBold: PropTypes.bool,
  particularAmount: PropTypes.string,
  particularName: PropTypes.string,
};

const pxDynamic = { xs: 1.5, md: 2.5 };
function OrderSummary(props) {
  const {
    loading = false,
    data,
    amount,
    addEnquiryHandler,
    showButtons = true,
    isEnquiry,
  } = props;

  const history = useHistory();

  //   const handleAddEnquirys = (id) => {
  //     console.clear();
  //     console.log(id);
  //     props.drawer.setDrawer({
  //       open: true,
  //       anchor: drawerProps.direction.right,
  //       component: (
  //         <AddEnquiry
  //           showDetails={false}
  //           ids={id}
  //           handleAddEnquiry={(data) => addEnquiryHandler(data)}
  //         />
  //       ),
  //     });
  //   };

  const getActualPrice = (orderData) => {
    const serviceValue = orderData?.services.map((it) => Number(it?.amount) * (it?.quantity ? it?.quantity : 1));
    const subTotal = serviceValue?.reduce((a, b) => a + b, 0);
    return subTotal ? subTotal : 0;
  };

  return (
    <Box
      sx={{
        padding: "0.25rem",
        margin: { xs: "0rem", md: "1rem 1rem 1rem 1rem" },
        backgroundColor: "white",
        borderWidth: "1px !important",
        borderColor: "rgba(219, 222, 229, 0.49) !important",
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "1rem",
              margin: "1rem",
              textAlign: "center",
              borderRadius: 1,
              backgroundColor: "rgba(219, 222, 229, 0.49) !important",
            }}
          >
            ORDER SUMMARY
          </Box>
          {/* <ItemParticulars data={props.selectedItem} loading={props.loading} /> */}
          <Box>
            <Stack sx={{ px: pxDynamic, py: 0.5 }}>
              {props?.isEnquiry ? (
                <>
                  <ParticularRow
                    loading={loading}
                    particularName={"Price"}
                    particularAmount={currencyPriceFormatter(
                      data?.metaData?.services?.[0]?.currency,
                      getActualPrice(data?.metaData) ?? 0
                    )}
                  />
                  <ParticularRow
                    loading={loading}
                    particularName={"Discount %"}
                    particularAmount={data?.discount ?? 0}
                  />
                  <ParticularRow
                    loading={loading}
                    particularName={"Tax Amount"}
                    particularAmount={currencyPriceFormatter(
                      data?.metaData?.services?.[0]?.currency,
                      data?.taxAmount
                    )}
                  />
                  <Divider />
                  <ParticularRow
                    loading={loading}
                    particularName={"Total Amount"}
                    particularAmount={currencyPriceFormatter(
                      data?.metaData?.services?.[0]?.currency,
                      data?.finalAmount
                    )}
                    makeNameBold
                  />
                </>
              ) : (
                <>
                  <ParticularRow
                    loading={loading}
                    particularName={
                      isEnquiry
                        ? "Price"
                        : `Price(${
                            data?.items?.length > 0 ? data?.items?.length : `0`
                          } Item)`
                    }
                    particularAmount={currencyPriceFormatter(
                      data?.currency,
                      data?.amount || amount
                    )}
                  />
                  <ParticularRow
                    loading={loading}
                    particularName={"Discount %"}
                    particularAmount={data?.discount ?? 0}
                  />
                  <ParticularRow
                    loading={loading}
                    particularName={"Tax Amount"}
                    particularAmount={currencyPriceFormatter(
                      data?.currency,
                      data?.tax_amount
                    )}
                  />
                  {/* <ParticularRow loading={loading} particularName={`Offer Applied ${data?.data?.discountPercentage ? props?.data?.discountPercentage + "% OFF" : ""}`} particularAmount={`-${currencyPriceFormatter(data?.currency, data?.discountPrice)}`} isOffer /> */}
                  <Divider />
                  <ParticularRow
                    loading={loading}
                    particularName={"Total Amount"}
                    particularAmount={currencyPriceFormatter(
                      data?.currency,
                      data?.total_amount || amount
                    )}
                    makeNameBold
                  />
                </>
              )}
              {showButtons && (
                <Stack
                  sx={{
                    flexDirection: { xs: "row", md: "column" },
                    justifyContent: "stretch",
                  }}
                >
                  <Button
                    fullWidth
                    sx={{ m: "0.25rem 0.1rem" }}
                    variant="outlined"
                    onClick={() => addEnquiryHandler(data?.itemIds)}
                    disabled={data?.items?.length > 0 ? false : true}
                    id={`enquiryBtn${data?.itemIds?.[0]}`}
                  >
                    Enquire
                  </Button>
                  <Button
                    fullWidth
                    sx={{ m: "0.25rem 0.1rem" }}
                    variant="contained"
                    disabled={data?.items?.length > 0 ? false : true}
                    id={`payBtn${data?.itemIds?.[0]}`}
                    onClick={() => {
                      history.push("/payment");
                    }}
                  >
                    {`Pay ${currencyPriceFormatter(
                      data?.currency,
                      data?.total_amount
                    )}`}{" "}
                  </Button>
                </Stack>
              )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

// OrderSummary.propTypes = {
//     isBackNeeded: PropTypes.bool,
//     onBackClick: function (props, propName, componentName) {
//         if (props['isBackNeeded'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function')) {
//             return new Error(
//                 'Failed prop type: Invalid prop `' + propName + '` of type `undefined` supplied to `' + componentName + '`, expected `function`.'
//             );
//         }
//     },
//     selectedItem: PropTypes.object
// }

// export default
export default withSnackBar(withDrawer(withDialog(OrderSummary)));
