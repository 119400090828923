import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { SectionHeader } from ".";
import {
  NameIcon,
  EmailIcon,
  OccupationIcon,
  MobileIcon,
  LanguageIcon,
} from "../../../../assets";
import { withDrawer } from "../../../../HOC's";
import { PersonalDetailForm } from "./drawers";
import { isArrayEmpty } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";

const { GET_SALUTATION, GET_LANGUAGE } = actions;

function PersonDetailRow({ icon, avatarBg, label, value, loading }) {
  return (
    <Stack flexDirection="row" gap={2}>
      <Avatar
        variant="rounded"
        sx={{ width: 30, height: 30, bgcolor: avatarBg }}
      >
        {icon}
      </Avatar>
      <Stack>
        <Typography variant="body2" color="textSecondary" fontWeight="400">
          {label ? label : "User Id"}
        </Typography>
        {loading ? (
          <Skeleton variant="text" width={"60px"} />
        ) : (
          <Typography fontWeight="400">
            {value ? value : "Not updated"}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

function PersonalDetails({
  state,
  data,
  onEdit = () => {},
  loading = false,
  ...props
}) {
  const dispatch = useDispatch();

  const handleUpdate = () => {};

  // const onEdit = (editData = null) => {
  //   props.drawer.setDrawer({
  //     open: true,
  //     component: (handleClose) => (
  //       <PersonalDetailForm
  //         isEdit={!!state}
  //         editData={state ?? {}}
  //         onUpdate={handleUpdate}
  //         handleClose={handleClose}
  //         dependencyOptions={dependencyOptions}
  //       />
  //     ),
  //   });
  // };

  return (
    <Stack>
      <SectionHeader
        id={`editPersonalDetailsMobile`}
        title="Personal Details"
        buttonLabel="Edit Personal Details"
        buttonHandler={onEdit}
      />
      <Stack
        sx={{ bgcolor: "common.white", p: 2.5 }}
        gap={2.5}
        style={{
          boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
        }}
      >
        <PersonDetailRow
          icon={<NameIcon fill="white" />}
          avatarBg={"#31B198"}
          label="User Id"
          value={data?.userId}
          loading={loading}
        />
        <PersonDetailRow
          icon={<EmailIcon fill="none" stroke="white" />}
          avatarBg={"#4A4AE2"}
          label="Primary Email Address"
          value={data?.emailid}
          loading={loading}
        />
        <PersonDetailRow
          icon={<MobileIcon fill="none" stroke="white" />}
          avatarBg={"#F27D35"}
          label="Primary Mobile Number"
          value={`${data?.mobilenumberISDcode} ${data?.mobile}`}
          loading={loading}
        />
        <PersonDetailRow
          icon={<OccupationIcon fill="white" />}
          avatarBg={"#4A8AE2"}
          label="Occupation"
          value={data?.occupation}
          loading={loading}
        />
        <PersonDetailRow
          icon={<LanguageIcon fill="white" stroke="white" />}
          avatarBg={"#E24A4A"}
          label="Preferred Languages"
          value={
            data?.languages && Array.isArray(JSON.parse(data?.languages))
              ? JSON.parse(data?.languages)
                  ?.map((language) => language?.label)
                  .join(", ")
              : ""
          }
          loading={loading}
        />
      </Stack>
    </Stack>
  );
}

export default withDrawer(PersonalDetails);
