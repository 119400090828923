import React, { useContext } from 'react'
import { DrawerContext } from '../contexts'

const withDrawer = (WrappedComponent) => (props) => {
    const drawer = useContext(DrawerContext)
    return (
        <WrappedComponent {...props} drawer={drawer}>{props.children}</WrappedComponent>
    )
}

export default withDrawer
