import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  ProfileSummary,
  PersonalDetails,
  IdDetails,
  ContactDetails,
  AddressDetails,
  HealthInformation,
  FamilyMemberDetails,
  EmailDetails,
} from "./components";
import { withDialog, withDrawer } from "../../../HOC's";
import { PersonalDetailForm, HealthInfoForm } from "./components/drawers";
import AddEmailDrawer from "../../../components/cards/addEmailAddressCard";
import ViewAllEmailType from "../../registration/components/viewAllEmailType";
import AddIdDrawer from "../../../components/cards/addIdentificationCard";
import ViewAllIdType from "../../registration/components/viewAllIdType";
import AddContactDrawer from "../../../components/cards/addContactCard";
import ViewAllContact from "../../registration/components/viewAllContact";
import AddAddressDrawer from "../../../components/cards/addAddressCard";
import ViewAllAddress from "../../registration/components/viewAllAddress";
import { isArrayEmpty } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";
import FamilyMemberList from "./components/drawers/familyMemberList";
import { v4 as uuidV4 } from "uuid";
import EditFamilyMembers from "../editFamilyMembers";
import { dontShowInMenuRoutes } from "../../../router/routes";
import EditFamilyMembersMobile from "./components/drawers/editFamilyMembersMobile";
import { Prompt } from "../../registration/components";

// const initialPersonalDetailsState = {
//   profileImage: "",
//   pic: "",
//   salutation: "",
//   name: "",
//   primaryEmail: "",
//   dob: null,
//   gender: "",
//   occupation: "",
//   languages: [],
// };

// const initialIdDetailsState = {
//   idTypes: [],
// };
// const initialContactDetailsState = {
//   otherPhoneNumbers: [],
// };
// const initialAddressDetailsState = {
//   otherAddress: [],
// };

// const initialHealthInformationState = {
//   height: "",
//   weight: "",
//   bmi: 0,
//   bloodGroup: "",
// };

// const initialFamilyMembersState = {
//   relations: [],
// };

const initialState = {
  profileImage: "",
  pic: "",
  salutation: "",
  name: "",
  primaryEmail: "",
  dob: null,
  gender: "",
  occupation: "",
  languages: [],
  otherEmailIds: [],
  idTypes: [],
  otherPhoneNumbers: [],
  otherAddress: [],
  height: "",
  weight: "",
  bmi: 0,
  bloodGroup: "",
  relations: [],
};

function MobileProfile({
  data,
  state,
  handleUpdate = () => {},
  dependencyOptions,
  ...props
}) {
  const dispatch = useDispatch();
  // const [state, setState] = useState({ ...initialState });

  const [screenId, setScreenId] = useState(0);
  const [familyData, setFamilyData] = useState({});

  const initialBreadCrumbs = [
    {
      label: "Go Back to My Profile",
      link: dontShowInMenuRoutes.profile.pathName,
    },
  ];

  const updateFieldState = (value, key) => {
    handleUpdate({
      ...state,
      [key]: value,
    });
  };

  useEffect(() => {
    //   Set State from the data
  }, []);

  const closeDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: "",
    });
  };

  // -----------------Personal Detail
  const handlePersonalDetailEdit = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (handleClose) => (
        <PersonalDetailForm
          isEdit={!!state}
          editData={state ?? {}}
          onUpdate={(updatedData) => {
            closeDrawer();
            handleUpdate(updatedData);
          }}
          handleClose={handleClose}
          dependencyOptions={dependencyOptions}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  // ---------------------------------------------Email Type Handlers --------------------------
  const handleSaveEmailType = (emailData) => {
    closeDrawer();
    debugger;
    const emailTypes = [...state["otherEmailIds"]];
    if (!!emailData?.id) {
      // Edit
      let updatedData = emailTypes.map((emailItem) => {
        if (emailItem?.id === emailData?.id) {
          return {
            ...emailItem,
            ...emailData,
          };
        }
        return emailItem;
      });

      updateFieldState(updatedData, "otherEmailIds");
    } else {
      emailTypes.push({ ...emailData, id: uuidV4() });
      updateFieldState(emailTypes, "otherEmailIds");
    }
  };

  const getEmailOptions = () => {
    const allOptions = [...dependencyOptions?.email_type];
    const usedOptions = [...state?.otherEmailIds];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.email_type?.value === originalOption?.value
        )
    );

    return remainingOptions;
  };

  const handleEmailEdit = (editData) => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (handleClose) => (
        <AddEmailDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={getEmailOptions()}
          onSave={handleSaveEmailType}
          handleClose={handleClose}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  const handleEmailDelete = (editData) => {
    closeDrawer();
    debugger;
    let emailTypes = [...state["otherEmailIds"]];
    emailTypes = emailTypes.filter(
      (emailType) => emailType?.id !== editData?.id
    );
    updateFieldState(emailTypes, "otherEmailIds");
  };

  const handleViewAllEmail = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllEmailType
          value={data?.otherEmailIds}
          handleClose={handleClose}
          handleEditClick={handleEmailEdit}
          handldeDeleteClick={handleEmailDelete}
          drawerSkeletonProps={{
            secondaryButtonText: "+ Add",
            secondaryButtonColor: "primary",
            handleClear: () => handleEmailEdit(),
          }}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  // ---------------------------------------------ID Type Handlers --------------------------
  const handleSaveIdType = (idData) => {
    closeDrawer();
    debugger;
    const idTypes = [...state["idTypes"]];
    if (!!idData?.id) {
      // Edit
      let updatedData = idTypes.map((idItem) => {
        if (idItem?.id === idData?.id) {
          return {
            ...idItem,
            ...idData,
          };
        }
        return idItem;
      });

      updateFieldState(updatedData, "idTypes");
    } else {
      idTypes.push({ ...idData, id: uuidV4() });
      updateFieldState(idTypes, "idTypes");
    }
  };

  const getIDOptions = () => {
    const allOptions = [...dependencyOptions?.client_id_type];
    const usedOptions = [...state?.idTypes];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.id_type_name?.value === originalOption?.value
        )
    );

    return remainingOptions;
  };

  const handleIdEdit = (editData) => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (handleClose) => (
        <AddIdDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={getIDOptions()}
          onSave={handleSaveIdType}
          handleClose={handleClose}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  const handleIdDelete = (editData) => {
    closeDrawer();
    debugger;
    let idTypes = [...state["idTypes"]];
    idTypes = idTypes.filter((idType) => idType?.id !== editData?.id);
    updateFieldState(idTypes, "idTypes");
  };

  const handleViewAllId = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllIdType
          value={data?.idTypes}
          handleClose={handleClose}
          handleEditClick={handleIdEdit}
          handldeDeleteClick={handleIdDelete}
          drawerSkeletonProps={{
            secondaryButtonText: "+ Add",
            secondaryButtonColor: "primary",
            handleClear: () => handleIdEdit(),
          }}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  // ---------------------------------------------Contact Type Handlers --------------------------
  const handleSaveContactType = (contact) => {
    closeDrawer();
    debugger;
    const otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    if (!!contact?.id) {
      // Edit
      let updatedData = otherPhoneNumbers.map((contactData) => {
        if (contactData?.id === contact?.id) {
          return {
            ...contactData,
            ...contact,
          };
        }
        return contactData;
      });

      updateFieldState(updatedData, "otherPhoneNumbers");
    } else {
      otherPhoneNumbers.push({ ...contact, id: uuidV4() });
      updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
    }
  };

  const getContactOptions = () => {
    const allOptions = [...dependencyOptions?.phone_type];
    const usedOptions = [...state?.otherPhoneNumbers];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.mobile_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  const handleContactEdit = (editData) => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (handleClose) => (
        <AddContactDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={getContactOptions()}
          onSave={handleSaveContactType}
          handleClose={handleClose}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  const handleContactDelete = (contact) => {
    closeDrawer();
    debugger;
    let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    otherPhoneNumbers = otherPhoneNumbers.filter(
      (phoneNumber) => phoneNumber?.id !== contact?.id
    );
    updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
  };

  const handleViewAllContact = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllContact
          value={data?.otherPhoneNumbers}
          handleClose={handleClose}
          handleEditClick={handleContactEdit}
          handldeDeleteClick={handleContactDelete}
          hidePrimary
          drawerSkeletonProps={{
            secondaryButtonText: "+ Add",
            secondaryButtonColor: "primary",
            handleClear: () => handleContactEdit(),
          }}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  // ---------------------------------------------Address Type Handlers --------------------------
  const handleSaveAddressType = (address) => {
    closeDrawer();
    debugger;
    const otherAddress = [...state["otherAddress"]];
    if (!!address?.id) {
      // Edit
      let updatedData = otherAddress.map((addressData) => {
        if (addressData?.id === address?.id) {
          return {
            ...addressData,
            ...address,
          };
        }
        return addressData;
      });

      updateFieldState(updatedData, "otherAddress");
    } else {
      otherAddress.push({ ...address, id: uuidV4() });
      updateFieldState(otherAddress, "otherAddress");
    }
  };

  const getAddressOptions = () => {
    const allOptions = [...dependencyOptions?.address_type];
    const usedOptions = [...state?.otherAddress];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.address_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  const handleAddressEdit = (editData) => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (handleClose) => (
        <AddAddressDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={getAddressOptions()}
          onSave={handleSaveAddressType}
          handleClose={handleClose}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  const handleAddressDelete = (address) => {
    closeDrawer();
    debugger;
    let otherAddress = [...state["otherAddress"]];
    otherAddress = otherAddress.filter(
      (addressData) => addressData?.id !== address?.id
    );
    updateFieldState(otherAddress, "otherAddress");
  };

  const handleViewAllAddress = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllAddress
          value={data?.otherAddress}
          takeAddressDataFromRootObject
          handleClose={handleClose}
          handleEditClick={handleAddressEdit}
          handldeDeleteClick={handleAddressDelete}
          drawerSkeletonProps={{
            secondaryButtonText: "+ Add",
            secondaryButtonColor: "primary",
            handleClear: () => handleAddressEdit(),
          }}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  // -----------------HealthInfo
  const handleHealthInfoEdit = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (handleClose) => (
        <HealthInfoForm
          isEdit={!!state}
          editData={state ?? {}}
          onUpdate={(updatedData) => {
            closeDrawer();
            handleUpdate(updatedData);
          }}
          handleClose={handleClose}
          dependencyOptions={dependencyOptions}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
      // component: (handleClose) => (
      //   <PersonalDetailForm handleClose={handleClose} />
      // ),
    });
  };

  // -----------------Family Members

  const deleteFamilyMember = (id) => {
    let req_data = [...data?.familyMembers];
    let pop_data = req_data.filter((x) => x.id === id)?.[0];
    const index = req_data.indexOf(pop_data);
    if (index > -1) {
      req_data.splice(index, 1);
    }
    // req_data?.push(state);
    console.log(req_data);
    let res = {
      familyMembers: req_data,
    };
    console.clear();
    console.log(res);
    handleUpdate(res);
    props.drawer.setDrawer({
      open: false,
    });
    setScreenId(0);
  };

  const handleDelete = (id, isEmergencyContact) => {
    debugger;
    // Ask confirmation on deleting the emergency contact member
    if (isEmergencyContact) {
      props.dialog.setDialog({
        open: true,
        renderContent: (handleClose, handleAccept) => (
          <Prompt
            title={"Delete Family Member"}
            body={
              <>
                Deleting this family member will disable <br /> Emergency
                contact option in Call Assist
              </>
            }
            handleClose={handleClose}
            handleAccept={handleAccept}
          />
        ),
        positiveButtonProps: {
          hide: true,
        },
        negativeButtonProps: {
          hide: true,
        },
        onAccept: () => deleteFamilyMember(id),
        sx: {
          Dialog: {
            "&.MuiDialog-paper": {
              borderRadius: "12px",
            },
          },
          DialogContent: {
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          },
        },
      });
    } else {
      deleteFamilyMember(id);
    }
  };

  const handleFamilyDetailDrawer = (familyMemberId, isAdd) => {
    const prefillData = isAdd
      ? {}
      : data?.familyMembers?.filter((x) => x.id === familyMemberId)?.[0] ?? {};
    setFamilyData(prefillData);
    props.drawer.setDrawer({
      open: false,
    });
    setScreenId(1);
  };

  const resetScreen = () => {
    setScreenId(0);
  };
  const handleFamilyMembersDetail = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (handleClose) => (
        <FamilyMemberList
          isEdit={!!state}
          editData={state ?? {}}
          onUpdate={handleUpdate}
          handleDelete={handleDelete}
          handleAdd={() => handleFamilyDetailDrawer(null, true)}
          onEditClick={handleFamilyDetailDrawer}
          handleClose={handleClose}
          dependencyOptions={dependencyOptions}
          data={data?.familyMembers}
        />
      ),
      sx: {
        Drawer: {
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
          },
        },
      },
    });
  };

  switch (screenId) {
    case 0:
      return (
        <Stack>
          <ProfileSummary data={data} loading={props?.loading} />
          <PersonalDetails
            state={state}
            data={data}
            onEdit={handlePersonalDetailEdit}
            loading={props?.loading}
          />
          <EmailDetails
            state={state}
            data={data?.otherEmailIds}
            isView={data?.otherEmailIds?.length > 0}
            onEdit={handleEmailEdit}
            onDelete={handleEmailDelete}
            onViewAll={handleViewAllEmail}
          />
          <IdDetails
            state={state}
            data={data?.idTypes}
            isView={data?.idTypes?.length > 0}
            onEdit={handleIdEdit}
            onDelete={handleIdDelete}
            onViewAll={handleViewAllId}
          />
          <ContactDetails
            state={state}
            data={data?.otherPhoneNumbers}
            isView={data?.otherPhoneNumbers?.length > 0}
            onEdit={handleContactEdit}
            onDelete={handleContactDelete}
            onViewAll={handleViewAllContact}
          />
          <AddressDetails
            state={state}
            data={data?.otherAddress}
            isView={data?.otherAddress?.length > 0}
            onEdit={handleAddressEdit}
            onDelete={handleAddressDelete}
            onViewAll={handleViewAllAddress}
          />
          <HealthInformation
            state={state}
            data={data}
            onEdit={handleHealthInfoEdit}
            loading={props?.loading}
          />
          <FamilyMemberDetails
            state={state}
            data={data?.familyMembers}
            onClick={props?.loading ? () => {} : handleFamilyMembersDetail}
            handleUpdate={handleUpdate}
          />
        </Stack>
      );
    case 1:
      return (
        // <Stack>
        //   <FamilyMemberList
        //     isEdit={!!state}
        //     editData={state ?? {}}
        //     onUpdate={handleUpdate}
        //     handleDelete={handleDelete}
        //     handleAdd={handleFamilyDetailDrawer}
        //     onEditClick={handleFamilyDetailDrawer}
        //     handleClose={() => {}}
        //     dependencyOptions={dependencyOptions}
        //     data={data?.familyMembers}
        //   />
        // </Stack>
        <EditFamilyMembersMobile
          breadcrumbs={initialBreadCrumbs}
          resetScreen={resetScreen}
          handleClose={() => {}}
          editData={familyData}
          data={data}
          dependencyOptions={dependencyOptions}
          handleUpdate={handleUpdate}
        />
      );
    default:
      return "";
  }

  // return (
  //   <Stack>
  //     <ProfileSummary data={data} />
  //     <PersonalDetails
  //       state={state}
  //       data={data}
  //       onEdit={handlePersonalDetailEdit}
  //     />
  //     <IdDetails
  //       state={state}
  //       data={data?.idTypes}
  //       isView={data?.idTypes?.length > 0}
  //       onEdit={handleIdEdit}
  //       onDelete={handleIdDelete}
  //       onViewAll={handleViewAllId}
  //     />
  //     <ContactDetails
  //       state={state}
  //       data={data?.otherPhoneNumbers}
  //       isView={data?.otherPhoneNumbers?.length > 0}
  //       onEdit={handleContactEdit}
  //       onDelete={handleContactDelete}
  //       onViewAll={handleViewAllContact}
  //     />
  //     <AddressDetails
  //       state={state}
  //       data={data?.otherAddress}
  //       isView={data?.otherAddress?.length > 0}
  //       onEdit={handleAddressEdit}
  //       onDelete={handleAddressDelete}
  //       onViewAll={handleViewAllAddress}
  //     />
  //     <HealthInformation
  //       state={state}
  //       data={data}
  //       onEdit={handleHealthInfoEdit}
  //     />
  //     <FamilyMemberDetails
  //       state={state}
  //       data={data?.familyMembers}
  //       onClick={handleFamilyMembersDetail}
  //       handleUpdate={handleUpdate}
  //     />
  //   </Stack>
  // );
}

export default withDrawer(withDialog(MobileProfile));
