import { styled } from "@mui/styles";

// const SliderWrapper = styled("div")(() => ({
//     ".ft-slick__dots--custom": {
//       "height": "8px",
//       "width": "14px",
//       "backgroundColor": "#e5e7e9",
//       "borderRadius": "4px",
//       "position": "relative"
//     },
//     ".slick-dots .slick-active .ft-slick__dots--custom": {
//       "width": "56px",
//       "top": "0px",
//       "overflow": "hidden"
//     },
//     ".slick-dots .slick-active .ft-slick__dots--custom .loading": {
//       "height": "8px",
//       "animation": "loading 5s ease-in",
//       "backgroundImage": "linear-gradient(270deg, #4fd15a, #03ac0e)",
//       "display": "inline-block",
//       "position": "absolute",
//       "top": "0",
//       "left": "0",
//       "borderRadius": "4px"
//     }
//   }));

const SliderWrapper = styled("div")`
  .ft-slick__dots--custom {
    height: 8px;
    width: 14px;
    background-color: #e5e7e9;
    border-radius: 4px;
    position: relative;
  }
  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 56px;
    top: 0px;
    overflow: hidden;

    .loading {
      height: 8px;
      animation: loading 5s ease-in;
      background-image: linear-gradient(270deg, #4fd15a, #03ac0e);
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
    }
  }
`;

export default SliderWrapper;
