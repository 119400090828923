import React, { useState, useEffect } from "react";
import signup from "../../assets/icons/signup.svg";
import verify_otp_confirm from "../../assets/icons/verify_otp_confirm.svg";
import verify_confirm_icon from "../../assets/icons/verify_confirm_icon.svg";
import Button from "@mui/material/Button";
// import { MobileNumberWithCode } from "qdm-component-library";
import Grid from "@mui/material/Grid";
import { __countries } from "../../components/counties";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { isValidPhoneNumber } from "libphonenumber-js";
import { withStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";
import Screen from "./screen";
import playstore from "../../assets/icons/playstore.png";
import huaweiStore from "../../assets/icons/playstore_1.svg";
import c_ from "../../assets/icons/c.svg";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import CustomOtpInput from "../../components/otpInput";
import EditIcon from "@mui/icons-material/Edit";
import CustomTextfieldWithSelect from "../../components/textfieldWithSelect";
import {
  countries,
  getSearchParamsString,
  tanyacareContactInfo,
  maskString,
  mobileAppStoreLinks,
  snackProps,
} from "../../utils";
import { countryFilterOption } from "../../utils";
import { withSnackBar } from "../../HOC's";
import { dontShowInMenuRoutes } from "../../router/routes";
import { Box, Hidden, IconButton, Stack, Typography } from "@mui/material";
import packJson from "../../../package.json";
import Registration from "../registration";
import { OnboardFormCard } from "./components";
import { EditOutlined } from "@mui/icons-material";

function OtpTimer(props) {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      // props.changeResendState(true);
    }
    let myInterval = setInterval(() => {
      // Block Resend State
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <>
      {minutes === 0 && seconds === 0 ? null : (
        <Typography sx={{ textAlign: "center", color: "success.main" }}>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
      )}
    </>
  );
}

const styles = (theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "70px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    // marginTop:"20px"
  },
  grid2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f0f2f6",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      background: "#ffffff",
    },
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class SingUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowRegistration: false,
      mobileNo: {},
      otpData: {
        phoneNumber: "",
        phoneNumberISD: "",
        otp: "",
      },
      OTP_: "",
      signUpPage: true,
      otpPage: false,
      confirmationPage: false,
      dropdownData: __countries,
      checked: false,
      code: { code: "MY", label: "Malaysia", phone: "60" },
      number: "",
      loading: false,
      searchParams: "",
    };
  }
  componentDidMount() {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      this.setState({
        searchParams: urlParams,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };
  goBack = () => {
    console.log(this.state);
    let code = this.state.code;
    let obj = {
      code: code,
      number: this.state.number,
      mobileNo: {},
      otpData: {
        phoneNumber: "",
        phoneNumberISD: "",
        otp: "",
      },
      OTP_: "",
      signUpPage: true,
      otpPage: false,
      confirmationPage: false,
      dropdownData: __countries,
      checked: false,
      resendState: false,
    };
    this.setState(obj);
  };
  sendOtp = async () => {
    // alert(JSON.stringify(this.state.number));
    // ;
    this.setState({ loading: true });
    if (
      //  true
      this.state?.number &&
      this.state.code
    ) {
      if (
        //  true
        isValidPhoneNumber(this.state?.number, this.state.code?.code)
      ) {
        let res = await this.props?.SEND_OTP_REGISTRATION_NUMBER({
          // phoneNumber: 9585217654,
          phoneNumber: this.state.number,
          // phoneNumberISD: 91,
          phoneNumberISD: this.state.code?.phone,
        });
        if (res?.payload?.message === "Success") {
          const state = { ...this.state };
          state.loading = false;
          state.signUpPage = false;
          state.otpPage = true;
          state.resendState = false;
          state.otpData = {
            otp: res?.payload?.data?.otp,
            phoneNumber: res?.payload?.data?.phoneNumber,
            phoneNumberISD: res?.payload?.data?.phoneNumberISD,
          };
          state.OTP_ = "";
          this.setState({ ...this.state, ...state });
          // alert("OTP send to mobile number!");
          this.props.snack.setSnack({
            open: true,
            message: "OTP Sent to mobile Successfully",
            duration: 6000,
            severity: snackProps.severity.success,
            setSnack: () => null,
          });
        } else {
          this.setState({ loading: false });
          this.props.snack.setSnack({
            open: true,
            message: "Unable to send OTP. Please try again Later",
            duration: 6000,
            severity: snackProps.severity.error,
            setSnack: () => null,
          });
        }
      } else {
        this.setState({ loading: false });
        this.props.snack.setSnack({
          open: true,
          message: "Please enter a valid Mobile Number",
          duration: 6000,
          severity: snackProps.severity.error,
          setSnack: () => null,
        });
      }
    } else {
      this.setState({ loading: false });
      // alert("Please enter mobile code & mobile number");
      this.props.snack.setSnack({
        open: true,
        message: "Please enter mobile code & mobile number",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };
  //VERIFY OTP
  otpverify = async () => {
    if (this.state?.OTP_ && this.state?.OTP_?.length === 4) {
      // if (this.state?.OTP_ === this.state.otpData?.otp) {
      let res = await this.props?.GET_OTP_VERIFY_REGISTRATION_NUMBER({
        phoneNumber: this.state?.otpData?.phoneNumber,
        phoneNumberISD: this.state?.otpData?.phoneNumberISD,
        otp: this.state?.OTP_,
      });
      if (String(res?.payload?.data?.message).toLowerCase() === "success") {
        const state = this.state;
        state.otpPage = false;
        state.confirmationPage = true;
        this.setState({ ...this.state, ...state });
        this.props.snack.setSnack({
          open: true,
          message: "OTP Verified Successfully",
          duration: 6000,
          severity: snackProps.severity.success,
          setSnack: () => null,
        });
      } else {
        this.props.snack.setSnack({
          open: true,
          message:
            res?.payload?.message ||
            res?.payload?.data?.message ||
            "Something went wrong!",
          duration: 6000,
          severity: snackProps.severity.error,
          setSnack: () => null,
        });
      }
      // } else {
      //   alert("Wrong otp");
      // }
    } else {
      // alert("please enter otp (4) digit");
      this.props.snack.setSnack({
        open: true,
        message: "Please enter OTP (4) Digit",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
  handleOtpChange = (newOtp) => {
    this.setState({ ...this.state, OTP_: newOtp });
  };

  handleRoute = () => {
    alert("Back to sign in");
  };
  continue = () => {
    let pathObj = { pathName: dontShowInMenuRoutes.selfRegistration.pathName };
    let products = getSearchParamsString(
      this.state.searchParams,
      "enqProducts"
    );
    let pathUrl = dontShowInMenuRoutes.selfRegistration.pathName;
    if (products) {
      pathUrl = `${dontShowInMenuRoutes.selfRegistration.pathName}?enqProducts=${products}`;
    }
    this.props.history.push(pathUrl, {
      phoneNumber: this.state?.otpData?.phoneNumber,
      phoneNumberISD: this.state?.otpData?.phoneNumberISD,
    });
    // this.setState({ isShowRegistration: true });
    // this.props.history.push(dontShowInMenuRoutes.selfRegistration.pathName);
    // alert("continue...");
  };

  render() {
    const { classes } = this.props;
    return this.state.isShowRegistration ? (
      <Registration />
    ) : (
      <div style={{ overflowX: "hidden" }}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              background: "white",
              minHeight: "100vh",
            }}
          >
            {this.state.signUpPage && <Screen imageURL={signup} />}
            {this.state.otpPage && <Screen imageURL={verify_confirm_icon} />}
            {this.state.confirmationPage && (
              <Screen imageURL={verify_confirm_icon} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            alignItems="center"
            justifyContent="center"
            className={classes.grid2}
          >
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  bgcolor: { xs: "common.white", md: "#f0f2f6" },
                  minHeight: "100vh",
                  pt: { md: 2.5 },
                }}
              > */}
              {this.state?.signUpPage && (
                <>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    style={{
                      background: "white",
                      padding: "30px 20px 40px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <OnboardFormCard> */}
                      <Hidden mdUp>
                        <Box sx={{ margin: "auto", width: "50%" }}>
                          <img src={signup} style={{ width: "150px" }} />
                        </Box>
                      </Hidden>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          style={{
                            margin: "10px 20px 0 20px",
                            color: "black",
                            fontSize: "22px",
                            fontWeight: 600,
                          }}
                        >
                          Get Started
                        </Typography>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          style={{
                            margin: "5px 5px 10px 5px",
                            color: "#bcbfc7",
                            fontSize: "14px",
                          }}
                        >
                          Registration with your mobile number to get OTP
                        </Typography>
                      </div>
                      <Box sx={{ margin: { xs: "0px", md: "20px 20px" } }}>
                        {/* <CustomTextfieldWithSelect
                        error={false}
                        size="small"
                        helperText=""
                        textFieldProps={{
                          id: "",
                          name: "Contact Number",
                          placeholder: "Contact Number",
                        }}
                        selectProps={{}}
                        sx={{ gap: 1 }}
                        selectOptions={countries}
                        fullWidth
                        selectValue={this.state.code?.phone}
                        value={this.state.number}
                        onSelectChange={(e, value) =>
                          this.changeState("code", value)
                        }
                        onChange={(e) =>
                          this.changeState("number", e.target.value)
                        }
                      /> */}

                        <CustomTextfieldWithSelect
                          fullWidth={true}
                          selectValue={this.state.code ? this.state.code : null}
                          selectOptions={countries}
                          onSelectChange={(e, value) => {
                            this.changeState("code", value);
                          }}
                          sx={{ gap: 1 }}
                          size="small"
                          filterOptions={countryFilterOption}
                          selectProps={{
                            id: "countrySelectSingup",
                            name: "country",
                          }}
                          textFieldProps={{
                            id: "registerContactSignup",
                            name: "contactNumber",
                            helperTextNoWrap: true,
                          }}
                          placeholder={{
                            select: "Code",
                            text: "Mobile Number",
                          }}
                          useCustomLabel
                          value={this.state.number}
                          onChange={(e) =>
                            this.changeState("number", e.target.value)
                          }
                          error={false}
                          helperText={""}
                        />
                      </Box>

                      <Stack
                        sx={{
                          fontSize: "12px !important",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          color: "#bcbfc7",
                          padding: { xs: "0px", md: "0px 20px 20px 20px" },
                        }}
                      >
                        <Checkbox
                          size="small"
                          sx={{ padding: "0px 9px" }}
                          checked={this.state.checked}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          id="agreeCheckbox"
                        />{" "}
                        <Typography
                          sx={{ fontSize: { xs: "11px", md: "13px" } }}
                        >
                          I agree to tanya care{" "}
                          <Link
                            href="https://www.tanya.care/privacy-policy/"
                            id="privacyPolicySignup"
                          >
                            Privacy Policy
                          </Link>{" "}
                          &{" "}
                          <Link
                            href="https://www.tanya.care/terms-of-use/"
                            id="termsOfUseSignup"
                          >
                            Terms of Use
                          </Link>
                        </Typography>
                      </Stack>
                      <div className={classes.buttonStyle}>
                        <Button
                          disabled={!this.state.checked || this.state.loading}
                          style={{ width: "100%", textTransform: "none" }}
                          variant="contained"
                          onClick={() => this.sendOtp()}
                          id="getOptSignup"
                          sx={{
                            marginTop: { xs: "20px", sm: "20px", md: "14px" },
                            position: { xs: "absolute", md: "inherit" },
                            right: 0,
                            left: 0,
                            bottom: 0,
                            borderRadius: { xs: 0, md: "6px" },
                          }}
                        >
                          {this.state.loading ? "Sending" : "Get OTP"}
                        </Button>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          sx={{
                            margin: { xs: "50px 20px", md: "40px" },
                            color: "black",
                            fontSize: "15px",
                            position: { xs: "absolute", md: "inherit" },
                            right: 0,
                            left: 0,
                            bottom: 0,
                            // fontWeight: 600,
                          }}
                        >
                          Do you have any queries? Call{" "}
                          <a
                            href={`tel:${tanyacareContactInfo.phone}`}
                            style={{
                              color: "#4f94ee",
                              textDecoration: "unset",
                              cursor: "pointer"
                            }}
                          >
                            Helpdesk
                          </a>
                        </Typography>
                      </div>
                    {/* </OnboardFormCard> */}
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    {/* <Grid
                      container
                      sx={{ paddingTop: "15px", textAlign: "center", display:{xs: "none", md:"block"} }}
                    >
                      <Grid
                        item
                        xs={4}
                        style={{
                          textAlign: "center",
                          justifyContent: "space-between",
                          display: "flex",
                          gap:3,
                        }}
                      > */}
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      sx={{
                        my: 1,
                        gap: 1,
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "black",
                          marginRight: 2,
                        }}
                      >
                        Get the app!
                      </Typography>
                      <a
                        href={mobileAppStoreLinks.playStore}
                        target="_blank"
                        className={classes.link}
                      >
                        <img alt="playstore icon" src={playstore} />
                      </a>
                      <a
                        href={mobileAppStoreLinks.huaweiStore}
                        target="_blank"
                        className={classes.link}
                      >
                        <img alt="huawei store icon" src={huaweiStore} />
                      </a>
                      {/* </Grid>

                    </Grid> */}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                  <Grid item xs={12} sm={8} md={6}>
                    <Grid
                      container
                      style={{ paddingTop: "15px", textAlign: "center" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          textAlign: "center",
                          justifyContent: "center",
                          display: { xs: "none", md: "flex" },
                          // display: "flex",
                        }}
                      >
                        <img alt="playstore icon" src={c_} />
                        <span
                          style={{
                            fontSize: "15px",
                            color: "#737373",
                            padding: "0px 10px",
                          }}
                        >
                          tanya.care
                        </span>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "#737373",
                            padding: "0px 10px",
                            borderRight: "2px solid rgb(217 214 214)",
                          }}
                        >
                          All Rights Reserved
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#737373",
                            padding: "0px 10px",
                          }}
                        >
                          {`v${packJson.version}`}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                </>
              )}
              {this.state?.otpPage && (
                <>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    style={{
                      background: "white",
                      padding: "30px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <OnboardFormCard> */}
                      <Hidden mdUp>
                        <Box sx={{ margin: "auto", width: "50%" }}>
                          <img src={signup} style={{ width: "150px" }} />
                        </Box>
                      </Hidden>
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            margin: "20px",
                            color: "black",
                            fontSize: "22px",
                            fontWeight: 600,
                          }}
                        >
                          OTP Verification
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Stack
                          sx={{ margin: "20px" }}
                          flexDirection={"row"}
                          gap={1}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Typography color="textSecondary">
                            Code sent to +{this.state?.otpData?.phoneNumberISD}
                            {"-"}
                            {maskString(this.state?.otpData?.phoneNumber)}
                            {/* {`Code sent to +91-`+ maskString("98765432109") } */}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={this.goBack}
                            id="codeSentEditSignup"
                          >
                            <EditOutlined
                              fontSize="small"
                              style={{ color: "#0063E7" }}
                            />
                          </IconButton>
                        </Stack>
                      </div>
                      <CustomOtpInput
                        handleOtpChange={this.handleOtpChange}
                        hasOtpErrored={false}
                        otp={this.state.OTP_}
                        otpSize={4}
                        customInput
                      />
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            color: "#6F6F6F",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          Didn’t get the code?{" "}
                          <Button
                            variant="text"
                            style={{
                              color: "#0063E7",
                              fontSize: "14px",
                            }}
                            // disabled={!this.state.resendState}
                            onClick={() => this.sendOtp()}
                            id="resendCodeSignup"
                          >
                            Resend Code
                          </Button>
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            margin: "20px",
                            color: "#28A9C1",
                            fontSize: "16px",
                          }}
                        >
                          <OtpTimer
                            initialMinute={4}
                            initialSeconds={59}
                            changeResendState={(status) => {
                              this.setState({ resendState: status });
                            }}
                          />
                        </p>
                      </div>
                      <div className={classes.buttonStyle}>
                        <Button
                          disabled={!this.state.checked}
                          style={{ width: "100%", textTransform: "none" }}
                          variant="contained"
                          onClick={() => this.otpverify()}
                          sx={{
                            marginTop: { xs: "20px", sm: "20px", md: "14px" },
                          }}
                          id="verifyOtpSignup"
                        >
                          Verify
                        </Button>
                      </div>
                    {/* </OnboardFormCard> */}
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={2}
                    md={3}
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  ></Grid>
                </>
              )}
              {this.state?.confirmationPage && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  style={{
                    background: "white",
                    padding: "50px",
                    borderRadius: "10px",
                  }}
                >
                  {/* <OnboardFormCard> */}
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img alt="logo" src={verify_otp_confirm} />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "20px",
                          color: "#363A57",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        Your mobile number verified successfully
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "15px",
                          color: "#6F6F6F",
                          fontSize: "16px",
                        }}
                      >
                        Create your profile to proceed further
                      </p>
                    </div>
                    <div className={classes.buttonStyle}>
                      <Button
                        style={{ width: "100%", textTransform: "none" }}
                        variant="contained"
                        onClick={() => this.continue()}
                        sx={{
                          marginTop: { xs: "20px", sm: "20px", md: "14px" },
                        }}
                        id="continueToProfile"
                      >
                        Continue
                      </Button>
                    </div>
                  {/* </OnboardFormCard> */}
                </Grid>
              )}
              {/* </Stack> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

//export default ForgotUserId;
const mapStateToProps = (state) => ({
  send_Otp_Registered_Number: state?.loginSlice?.sendOtpRegisteredNumber,
  verify_Otp_Registered_Number: state?.loginSlice?.verifyOtpRegisteredNumber,
});

export default connect(
  mapStateToProps,
  actions
)(withSnackBar(withRouter(withStyles(styles)(SingUp))));
// export default withStyles(styles)(
//   connect(mapStateToProps, actions)(withRouter(SingUp))
// );
