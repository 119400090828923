import PropTypes from "prop-types";
import React from "react";
import Paper from "@mui/material/Paper";

function OnboardFormCard({ children, maxWidth }) {
  return (
    <Paper
      sx={{
        borderRadius: 2,
        py: 3,
        pb: { xs: 1, md: 3 },
        px: { xs: 2, sm: 4 },
        maxWidth: maxWidth,
      }}
      elevation={0}
    >
      {children}
    </Paper>
  );
}

OnboardFormCard.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node,
};

OnboardFormCard.defaultProps = {
  maxWidth: "400px",
};

export default OnboardFormCard;
