import * as React from 'react';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { styled } from "@mui/material"


const ALERT_TYPES = {
    error:  'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
}

const StyledAlert = styled(Alert)(({ theme, severity }) => ({
    "& .MuiAlert-icon, .MuiAlert-message, .MuiAlert-action": {
        color: theme.palette.common.white,
    },
    ...(severity === ALERT_TYPES.error && {
        backgroundColor: theme.palette.error.main,
    }),
    ...(severity === ALERT_TYPES.info && {
        backgroundColor: theme.palette.info.main,
    }),
    ...(severity === ALERT_TYPES.success && {
        backgroundColor: theme.palette.success.main,
    }),
    ...(severity === ALERT_TYPES.warning && {
        backgroundColor: theme.palette.warning.main,
    }),
}))

function CustomSnackBar({ open, message, duration, severity, vertical, horizontal, handleClose }) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{
                vertical: vertical,
                horizontal: horizontal,
            }}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            }
        >
            <StyledAlert severity={severity} onClose={handleClose} sx={{ width: '100%' }}>
                {message}
            </StyledAlert>
        </Snackbar>
    );
}

export default CustomSnackBar
