import { Grid, Box, Divider, ClickAwayListener } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import OrderSummary from "../../components/cards/OrderSummary";
import { Stack, Typography, Button, IconButton } from "@mui/material";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import {
  currencyPriceFormatter,
  drawerProps,
  isTrialAccountType,
  snackProps,
} from "../../utils";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CustomTextfield from "../../components/textfield";
import { CustomizedRadioButton } from "../../components/cutomRadioButton";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  CardElement,
  useStripe,
  useElements,
  FpxBankElement,
} from "@stripe/react-stripe-js";
// import { snackProps } from "../../utils"
import "./style.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PurchaseSuccess from "../../components/cards/purchaseSuccess";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const {
  GET_CART_TOTAL,
  PAYMENT_INITIATE,
  CART_COUNT,
  PAYMENT_STATUS,
  GET_CLIENT_PROFILE,
  GET_QUOTES_INFO,
} = actions;

const Payment = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const isEnquiry = history?.location?.state?.isEnquiry;

  const { enquiry_id, quote_id, amount } = useParams();

  const [enquiredFor, setEnquiredFor] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [cardHolder, setCardHolder] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [isFPXCompleted, setIsFPXCompleted] = useState(false);
  const [openedAccordian, setOpenedAccordian] = useState(1);
  // const [isCompleted, setIsCompleted] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [trialCardDetailsExpand, setTrialCardDetailsExpand] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Store States
  const paymentInitiate = useSelector(
    (state) => state?.clientPayment?.initiate?.data
  );
  const quoteData = useSelector(
    (state) => state?.enquiry_management?.getQuoteInfo
  );

  const cartData = useSelector((state) => state?.clientCart?.cartTotal);

  const getCartDetails = useCallback(() => {
    Promise.resolve(dispatch(GET_CART_TOTAL({}))).catch((err) => {
      console.log(err);
    });
  }, [dispatch]);

  const query = new URLSearchParams(useLocation().search);
  const clientSecretElement = query.get("payment_intent_client_secret");
  const orderIdElement = query.get("orderId");

  useEffect(() => {
    if (stripe && clientSecretElement) {
      stripe
        .retrievePaymentIntent(clientSecretElement)
        .then(({ paymentIntent }) => {
          Promise.resolve(dispatch(GET_CLIENT_PROFILE({})))
            .then((res_data) => {
              if (paymentIntent?.status === "succeeded") {
                paymentStatusHandler(orderIdElement, true);
              } else {
                paymentStatusHandler(orderIdElement, false);
              }
            })
            .catch((err) => console.log(err));
        });
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [clientSecretElement, stripe]);

  const profileData = useSelector((state) => state?.clientProfile?.getProfile);

  useEffect(() => {
    // If isEnquiry is false or undefined hit cart data API
    if (!history?.location?.state?.isEnquiry) {
      getCartDetails();
    }
    // eslint-disable-next-line
  }, [getCartDetails]);

  const getQuoteInfo = () => {
    Promise.resolve(
      dispatch(
        GET_QUOTES_INFO({ quotesId: history?.location?.state?.quotesId ?? "" })
      )
    ).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (history?.location?.state?.isEnquiry) {
      getQuoteInfo();
    }
    // eslint-disable-next-line
  }, [history?.location?.state?.quotesId]);

  // useEffect(() => {
  //   console.log(
  //     "============================cartData============================"
  //   );
  //   console.log(cartData);
  //   console.log(
  //     "============================quoteData============================"
  //   );
  //   console.log(quoteData)

  // });

  let cart_data = cartData?.data;

  // const query = new URLSearchParams(useLocation().search);
  // const clientSecret_key = query.get("payment_intent_client_secret");

  // useEffect(() => {
  //   stripe.retrievePaymentIntent(clientSecret_key).then(({ paymentIntent }) => {
  //     alert(paymentIntent?.status)
  //   });
  // }, [clientSecret_key]);

  // const FPXPymentReturn = () => {
  //   const [message, setMessage] = useState("");
  //   const query = new URLSearchParams(useLocation().search);
  //   const clientSecret = query.get("payment_intent_client_secret");

  //   useEffect(() => {
  //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //       // alert(paymentIntent?.status);
  //       dispatch(GET_CLIENT_PROFILE({}));
  //     });
  //   }, [clientSecret]);
  // };

  const fetchPaymentSheetParams = async () => {
    let payload = {};

    // alert(enquiry_id, quote_id)
    if (enquiry_id && quote_id) {
      payload = {
        enquiryId: enquiry_id,
        quoteId: quote_id,
        // amount: amount,
        // currency: "MYR",
        amount: quoteData?.data?.finalAmount,
        currency: quoteData?.data?.metaData?.services?.[0]?.currency,
        isiOS: false,
      };
      // alert(JSON.stringify(payload))
    } else {
      // alert("in cart mode")
      payload = {
        itemIds: cart_data?.itemIds,
        currency: cart_data?.currency?.toString(),
        amount: cart_data?.total_amount?.toString(),
        // amount:100,
        isiOS: false,
      };
    }

    // payload = {
    //   enquiryId:"5102935c-4948-4b99-bbd6-907d24e2257d",
    //   quoteId:"61ebac7c-7233-4202-8a5c-6c3058bf2f77",
    //   amount: 2576.17,
    //   currency: "MYR",
    //   isiOS: false,
    // }

    // alert(JSON.parse(payload))

    const response = await Promise.resolve(dispatch(PAYMENT_INITIATE(payload)));
    return { data: response?.payload?.data, error: response?.payload?.error };
  };

  const CloseDialog = () => {
    props.dialog.setDialog({
      open: false,
    });
    // Clear Cart count on Close Dialog
    Promise.resolve(dispatch(CART_COUNT({}))).catch((err) => {
      console.log(err);
    });
    history.push("/purchase");
  };

  const paymentStatusHandler = (orderId, status) => {
    // alert(orderId)

    Promise.resolve(dispatch(PAYMENT_STATUS({ orderId })))
      .then((res) => {
        if (res?.payload?.error) {
          // alert("Error");
        }

        dispatch(CART_COUNT({}));
        const data = res?.payload?.data ? res?.payload?.data?.[0] : null;
        // alert("Success API Response")
        // alert(JSON.stringify(data))

        Promise.resolve(dispatch(GET_CLIENT_PROFILE({})))
          .then((clientProfileResponse) => {
            let name = `${
              clientProfileResponse?.payload?.data?.salutation?.label
                ? `${clientProfileResponse?.payload?.data?.salutation?.label}.`
                : ``
            } ${clientProfileResponse?.payload?.data?.clientName}`;

            if (status) {
              // Success Dialog
              setPaymentProcessing(false);
              dispatch(CART_COUNT({}));
              props.dialog.setDialog({
                open: true,
                title: "",
                content: (
                    <PurchaseSuccess
                      variant={true}
                      name={name}
                      order_data={data}
                      profile_data={profileData}
                      onRedirect={() => CloseDialog()}
                    />
                ),
                onCloseCallback: CloseDialog,
                renderContent: null,
                positiveButtonProps: {
                  hide: true,
                },
                negativeButtonProps: {
                  hide: true,
                },
                onAccept: () => {
                  history.push("/purchase");
                },
                
              });
            } else {
              // payment failure dialog
              dispatch(CART_COUNT({}));
              setPaymentProcessing(false);
              props.dialog.setDialog({
                open: true,
                title: "",
                content: (
                    <PurchaseSuccess
                      variant={false}
                      name={name}
                      order_data={data}
                      profile_data={profileData}
                      orderId={orderId}
                      paymentInitiate={paymentInitiate}
                      onRedirect={() => CloseDialog()}
                    />
                ),
                onCloseCallback: CloseDialog,
                renderContent: null,
                positiveButtonProps: {
                  hide: true,
                },
                negativeButtonProps: {
                  hide: true,
                },
                onAccept: () => {
                  history.push("/purchase");
                },
              });
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  // const paymentModeValidator = () => {
  //   if (paymentMethod === 1) {
  //     // if (cardHolder === "" && !isCompleted) {
  //     if (isCompleted === false) {
  //       props.snack.setSnack({
  //         open: true,
  //         message: "Card Number Invalid",
  //         duration: 6000,
  //         severity: snackProps.severity.error,
  //         setSnack: () => null,
  //       });
  //       // alert("Card Number Invalid")
  //     } else if (cardHolder === "") {
  //       props.snack.setSnack({
  //         open: true,
  //         message: "Card Holder Name is mandatory",
  //         duration: 6000,
  //         severity: snackProps.severity.error,
  //         setSnack: () => null,
  //       });
  //     } else {
  //       return;
  //     }
  //   } else if (paymentMethod === 2) {
  //   } else if (paymentMethod === 3) {
  //   }
  //   // || paymentMethod !== 2 || paymentMethod !== 3
  // };

  const getpaymentMethod = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return alert(
        "Something went wrong, While trying to initialize the payment"
      );
    }
    // Fetching the client secret
    // setCustomLoading(true);
    setPaymentProcessing(true);
    const { data, error: paymentSheetError } = await fetchPaymentSheetParams();
    if (!paymentSheetError) {
      const clientSecret = data?.paymentIntent;
      if (paymentMethod === 1) {
        if (cardHolder !== "" && isCompleted) {
          // alert("Inside Confirm Pament")
          const { error: stripeError } = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: cardHolder,
                },
              },
            }
          );

          if (stripeError) {
            paymentStatusHandler(data?.orderId, false);
            // alert(stripeError)
            setPaymentProcessing(false);

            return;
          } else {
            //  Success Condition
            paymentStatusHandler(data?.orderId, true);
            // console.log(paymentIntent)
          }
        } else {
          if (isCompleted === false) {
            setPaymentProcessing(false);
            props.snack.setSnack({
              open: true,
              message: "Card Number Invalid",
              duration: 6000,
              severity: snackProps.severity.error,
              setSnack: () => null,
            });
            // alert("Card Number Invalid")
          } else {
            setPaymentProcessing(false);
            props.snack.setSnack({
              open: true,
              message: "Card Holder Name is mandatory",
              duration: 6000,
              severity: snackProps.severity.error,
              setSnack: () => null,
            });
          }
          // alert("Name Must")
        }
      } else if (paymentMethod === 2) {
        // alert("Net Backing")
        if (isFPXCompleted) {
          let { error: stripeError } = await stripe.confirmFpxPayment(
            clientSecret,
            {
              payment_method: {
                fpx: elements.getElement(FpxBankElement),
              },
              return_url: `${window.location.origin}/payment?return=true&orderId=${data?.orderId}`,
            }
          );
          if (stripeError) {
            setPaymentProcessing(false);
            // alert("Fail")
            paymentStatusHandler(data?.orderId, false);
            console.log(stripeError);
            return;
          } else {
            //  Success Condition
            setPaymentProcessing(false);
            // alert("True ----")
            paymentStatusHandler(data?.orderId, true);
            // console.log(paymentIntent)
          }
        } else {
          setPaymentProcessing(false);
          props.snack.setSnack({
            open: true,
            message: "Please select a Bank",
            duration: 6000,
            severity: snackProps.severity.error,
            setSnack: () => null,
          });
        }
      } else {
        if (enquiredFor === "Ali Pay") {
          // alert("Ali Pay")
          const { error: stripeError } = await stripe.confirmAlipayPayment(
            clientSecret,
            {
              payment_method: {
                // billing_details: {
                //   name,
                // },
              },
              return_url: `${window.location.origin}/payment?return=true&orderId=${data?.orderId}`,
            }
          );
          if (stripeError) {
            setPaymentProcessing(false);
            paymentStatusHandler(data?.orderId, false);
            console.log(stripeError);
            return;
          } else {
            //  Success Condition
            paymentStatusHandler(data?.orderId, true);
            // console.log(paymentIntent)
          }
        } else if (enquiredFor === "Grab Pay") {
          // alert("Grab Pay")
          const { error: stripeError } = await stripe.confirmGrabPayPayment(
            clientSecret,
            {
              payment_method: {
                // billing_details: {
                //   name,
                // },
              },
              return_url: `${window.location.origin}/payment?return=true&orderId=${data?.orderId}`,
            }
          );

          if (stripeError) {
            setPaymentProcessing(false);
            paymentStatusHandler(data?.orderId, false);
            console.log(stripeError);
            return;
          } else {
            //  Success Condition
            paymentStatusHandler(data?.orderId, true);
            // console.log(paymentIntent)
          }
        } else {
          setPaymentProcessing(false);
          props.snack.setSnack({
            open: true,
            message: "Please select a wallet",
            duration: 6000,
            severity: snackProps.severity.error,
            setSnack: () => null,
          });
        }
      }
    } else {
      setPaymentProcessing(false);
      props.snack.setSnack({
        open: true,
        message: "There was an error, while processing your payment",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };

  const setAccordianValue = (value) => {
    setPaymentMethod(value);
    setOpenedAccordian(value);
    return;
  };
  return (
    <>
      {cartData?.loading && <div>Loading...</div>}
      {!cartData?.loading && cartData?.error && <div>Error...</div>}
      {!cartData?.loading && Number(cartData?.data?.total_amount) <= 0 && (
        <div> Cart total is zero </div>
      )}
      {!cartData?.loading &&
        !cartData?.error &&
        (Number(cartData?.data?.total_amount) > 0 || amount) && (
          <form id="payment-form" onSubmit={(e) => getpaymentMethod(e)}>
            {/* <Button onClick={() => setSuccess()}>Click</Button> */}
            <Grid container>
              <Grid item xs={12} md={8} sx={{ order: { xs: 2, md: 1 } }}>
                <Box sx={{ padding: { xs: 0, md: "0.5rem 0.5rem" } }}>
                  <Box
                    sx={{
                      //   overflow: "auto",
                      //   position: "relative",
                      margin: { sx: 0, md: "0.25rem 1rem" },
                      //   maxHeight: { md: "calc(100vh - 64px)" },
                    }}
                  >
                    <div>
                      <Box sx={{ margin: { xs: 0, md: 1 } }}>
                        <div>
                          <Accordion expanded={openedAccordian === 1}>
                            <AccordionSummary
                              onClick={() => setAccordianValue(1)}
                              expandIcon={
                                openedAccordian === 1 ? (
                                  <RadioButtonCheckedIcon
                                    sx={{ color: "secondary.main" }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedIcon />
                                )
                              }
                              aria-controls="panel2a-content"
                              id="panel1aHeader"
                            >
                              <CreditCardIcon
                                fontSize={"small"}
                                sx={{ marginRight: "1rem" }}
                              />
                              <Typography> Credit/Debit Card</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                              {/* <PaymentGateway /> */}
                              {/* <Stack sx={{}}> */}
                              <Grid container>
                                <Grid item xs={12} md={6}>
                                  <CustomTextfield
                                    id="card-holder-name"
                                    name="Card Holder "
                                    label="Card Holder "
                                    value={cardHolder}
                                    onChange={(e) =>
                                      setCardHolder(e.target.value)
                                    }
                                    placeholder={"Card Holder "}
                                    multiline={false}
                                    rows={1}
                                    fullWidth
                                    size="small"
                                    sx={{ m: "16px -5px" }}
                                    // sx={{ width : 500, borderColor:"rgba(219, 222, 229, 0.49) !important" }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CardElement
                                    id="card"
                                    onChange={(e) => {
                                      setIsCompleted(e?.complete);
                                    }}
                                    options={{
                                      hidePostalCode: true,
                                      style: {
                                        base: {
                                          color: "#424770",
                                          letterSpacing: "0.025em",
                                          height: "480px",
                                          // padding: "8.5px 14px",
                                          fontFamily:
                                            "Source Code Pro, monospace",
                                          "::placeholder": {
                                            color: "#aab7c4",
                                          },
                                        },
                                        invalid: {
                                          color: "#9e2146",
                                        },
                                      },
                                    }}
                                    // onChange={(e) => {
                                    //   console.log(e);
                                    // }}
                                  />
                                </Grid>
                                {isTrialAccountType() && (
                                  <Grid item xs={12}>
                                    <Stack
                                      flexDirection="row"
                                      gap={1}
                                      alignItems={"center"}
                                      onClick={() => {
                                        setTrialCardDetailsExpand(
                                          (prev) => !prev
                                        );
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ ml: 1, fontWeight: 400 }}
                                      >
                                        Click to view test card details
                                      </Typography>
                                      <IconButton size="small">
                                        {trialCardDetailsExpand ? (
                                          <KeyboardArrowUpIcon fontSize="small" />
                                        ) : (
                                          <KeyboardArrowDownIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </Stack>
                                    {trialCardDetailsExpand && (
                                      <>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          sx={{ ml: 2, fontWeight: 400 }}
                                        >
                                          • {`Card Holder - Test User`}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          sx={{ ml: 2, fontWeight: 400 }}
                                        >
                                          • {`Card no - 4242 4242 4242 4242`}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          sx={{ ml: 2, fontWeight: 400 }}
                                        >
                                          • {`Expiry date - 12/35`}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          sx={{ ml: 2, fontWeight: 400 }}
                                        >
                                          • {`CVC no - 123`}
                                        </Typography>
                                      </>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                              {/* </Stack> */}
                            </AccordionDetails>
                          </Accordion>

                          {!isTrialAccountType() && (
                            <>
                              <Accordion expanded={openedAccordian === 2}>
                                <AccordionSummary
                                  onClick={() => setAccordianValue(2)}
                                  expandIcon={
                                    openedAccordian === 2 ? (
                                      <RadioButtonCheckedIcon
                                        sx={{ color: "secondary.main" }}
                                      />
                                    ) : (
                                      <RadioButtonUncheckedIcon />
                                    )
                                  }
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <AccountBalanceIcon
                                    fontSize={"small"}
                                    sx={{ marginRight: "1rem" }}
                                  />
                                  <Typography>Online Banking</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                  {/* <NetBankingGateway /> */}
                                  <FpxBankElement
                                    onChange={(e) =>
                                      setIsFPXCompleted(e?.complete)
                                    }
                                    options={{
                                      accountHolderType: "individual",
                                      style: {
                                        base: {
                                          color: "#424770",
                                          letterSpacing: "0.025em",
                                          fontFamily:
                                            "Source Code Pro, monospace",
                                          "::placeholder": {
                                            color: "#aab7c4",
                                          },
                                          padding: "10px 14px",
                                        },
                                        invalid: {
                                          color: "#9e2146",
                                        },
                                      },
                                    }}
                                  />
                                </AccordionDetails>
                              </Accordion>

                              <Accordion expanded={openedAccordian === 3}>
                                <AccordionSummary
                                  onClick={() => setAccordianValue(3)}
                                  expandIcon={
                                    openedAccordian === 3 ? (
                                      <RadioButtonCheckedIcon
                                        sx={{ color: "secondary.main" }}
                                      />
                                    ) : (
                                      <RadioButtonUncheckedIcon />
                                    )
                                  }
                                  aria-controls="panel2a-content"
                                  id="panel3a-header"
                                >
                                  <AccountBalanceWalletOutlinedIcon
                                    fontSize={"small"}
                                    sx={{ marginRight: "1rem" }}
                                  />
                                  <Typography>Wallets </Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                  {/* <DigitalWalletGateway /> */}
                                  <CustomizedRadioButton
                                    data={{
                                      key: "1",
                                      label: "",
                                      options: [
                                        {
                                          // key: "General Enquiry",
                                          label: "Ali Pay",
                                          value: "Ali Pay",
                                        },
                                        {
                                          // key: "Pricing Related",
                                          label: "Grab Pay",
                                          value: "Grab Pay",
                                        },
                                      ],
                                      id: "",
                                    }} //needs label, options, handleOnChange
                                    value={enquiredFor}
                                    id={""}
                                    handleOnChange={(e) => setEnquiredFor(e)}
                                    // handleOnChange={(e, newValue) => alert(e)}
                                    error={false}
                                    errorText={"NoError"}
                                    disabled={false}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </>
                          )}
                        </div>
                        <Box sx={{ display: { xs: "block", md: "none" } }}>
                          <Button
                            id="paymentBtn"
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={
                              paymentProcessing === true
                                ? true
                                : enquiredFor === "Ali Pay" ||
                                  enquiredFor === "Grab Pay"
                                ? false
                                : isCompleted === true
                                ? false
                                : isFPXCompleted === true
                                ? false
                                : true
                            }
                            sx={{ marginTop: "1rem" }}
                            // onClick={(e) => {
                            //   getpaymentMethod(e);
                            // }}
                          >
                            {paymentProcessing
                              ? `Paying ....`
                              : `Pay ${
                                  !amount
                                    ? `${currencyPriceFormatter(
                                        cart_data?.currency,
                                        cart_data?.total_amount
                                      )}`
                                    : `${amount}`
                                }`}
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                order={1}
                sx={{ order: { xs: 1, md: 2 } }}
              >
                <div>
                  <OrderSummary
                    isEnquiry={isEnquiry}
                    data={isEnquiry ? quoteData?.data : cart_data}
                    loading={isEnquiry ? quoteData?.loading : cartData?.loading}
                    amount={amount}
                    showButtons={false}
                  />
                  <Box
                    sx={{
                      width: "90%",
                      margin: "auto",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    <Button
                      id="paymentBtn"
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={
                        paymentProcessing === true
                          ? true
                          : enquiredFor === "Ali Pay" ||
                            enquiredFor === "Grab Pay"
                          ? false
                          : isCompleted === true
                          ? false
                          : isFPXCompleted === true
                          ? false
                          : true
                      }
                    >
                      {paymentProcessing
                        ? `Paying ....`
                        : `Pay ${
                            !amount
                              ? `${currencyPriceFormatter(
                                  cart_data?.currency,
                                  cart_data?.total_amount
                                )}`
                              : `${amount}`
                          }`}
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
    </>
  );
};

// export default Cart;
export default withSnackBar(withDrawer(withDialog(Payment)));
