import PropTypes from "prop-types"
import React from 'react'
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 400

function DrawerSelector({ open, anchor, variant, handleOpen, handleClose, sx={}, ...props }) {
    const theme = useTheme()
    const belowSm = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <>
            {belowSm ? (
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    sx={{
                        ...sx?.Drawer,
                    }}
                >
                    {props.children}
                </SwipeableDrawer>
            ) : (
                <Drawer
                    anchor={anchor}
                    open={open}
                    variant={variant}
                    onClose={() => handleClose()}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        // display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        ...sx?.Drawer,
                    }}
                >
                    {props.children}
                </Drawer>
            )}
        </>
    )
}

function CustomDrawer(props) {
    const { anchor, component, handleClose } = props
    return (
        <DrawerSelector {...props}>
            {typeof component === 'function' ? component(handleClose) : component}
            {typeof component !== 'function' && <Box style={{
                position: "fixed",
                top: 10,
                ...(anchor === "left" && {
                    left: drawerWidth + 4
                }),
                ...(anchor === "right" && {
                    right: drawerWidth + 4
                }),
            }}>
                <IconButton onClick={handleClose} aria-label="close-drawer" sx={{
                    bgcolor: "common.white", "&:hover": {
                        bgcolor: "#EBEBEB"
                    }
                }}>
                    <CloseOutlinedIcon fontSize="small" />
                </IconButton>
            </Box>}
        </DrawerSelector>
    )
}

CustomDrawer.propTypes = {
    anchor: PropTypes.string,
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    variant: PropTypes.string
}


export default CustomDrawer
