import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";
import { withStyles, makeStyles } from "@mui/styles";
import Tooltip from '@mui/material/Tooltip';
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  oppositeContent: {
    flex: "0 !important",
    padding: "6px !important"
  },
  connectorStyle: {
    backgroundColor: theme.palette.primary.main,
  },
  ellipsisLine: {
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    display: "-webkit-box !important",
    WebkitLineClamp: "1 !important",
    WebkitBoxOrient: "vertical !important",
    lineHeight: 1.8,
    paddingTop: "0px !important",
    paddingRight: "0px !important",
  },
  ellipsisLine_createdAt: {
    color: "gray !important" 
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    cursor: "pointer"
  },
}))(Tooltip);

export default function ActivityTimeline({ sequenceData, lineHidden }) {
  const classes = useStyles();
  console.log(sequenceData)
  return (
    <React.Fragment>
      <Timeline style={{paddingRight: 0}}>
        {sequenceData?.length > 0 && sequenceData?.map((x, index) => (
            <TimelineItem style={{minHeight: lineHidden ? "30px" : "70px"}}>
              <TimelineOppositeContent
                className={classes.oppositeContent}
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {lineHidden ? '' : 
                  index+1 === sequenceData.length ? '' : <TimelineConnector classes={{ root: classes.connectorStyle }} />
                }
              </TimelineSeparator>
              <TimelineContent style={{padding: "0px 16px 6px 16px"}}>
                <HtmlTooltip disableHoverListener={x.message?.length > 50 ? false : true} title={x.message} placement="top-start" >
                     <>
                        <Typography className={classes.ellipsisLine} >{x.message}</Typography>
                        <Typography className={classes.ellipsisLine_createdAt} >{x.createdAt?moment(x.createdAt).format("MMM Do YY - h:mm a"):""}</Typography>
                     </>
                </HtmlTooltip>
                {x?.dependentService?.length > 0 && 
                    x?.dependentService?.map((val, i) => {
                      return (<>
                       {i === 0 && 
                        <TimelineItem style={{minHeight: "5px"}}>
                          <TimelineOppositeContent className={classes.oppositeContent} style={{paddingRight: "8px"}}></TimelineOppositeContent>
                          <TimelineSeparator>
                            {/* <TimelineConnector style={{width: "1.5px", backgroundColor: "#FFBE9F"}} />  */}
                          </TimelineSeparator>
                          <TimelineContent>{""}</TimelineContent>
                        </TimelineItem>
                        }
                        <TimelineItem style={{minHeight: "30px"}}>
                          <TimelineOppositeContent className={classes.oppositeContent}></TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot style={{ padding: "2px", backgroundColor: "#FFBE9F"}} />
                            {/* {i+1 === x?.dependentService?.length ? '' : <TimelineConnector style={{width: "1.5px", backgroundColor: "#FFBE9F"}} />}  */}
                          </TimelineSeparator>
                          <HtmlTooltip disableHoverListener={val.message?.length > 50 ? false : true} title={val.message} placement="top-start" >
                             <TimelineContent className={classes.ellipsisLine} >{val.message}</TimelineContent>
                          </HtmlTooltip>
                        </TimelineItem>
                        </>)
                    })
                }
                
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </React.Fragment>
    // <></>
  );
}
