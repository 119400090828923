import React from "react";
// import signup from "../../assets/icons/signup.svg";
// import verify_otp_confirm from "../../assets/icons/verify_otp_confirm.svg";
// import verify_confirm_icon from "../../assets/icons/verify_confirm_icon.svg";
import celebration from "../../assets/icons/celebration.svg";
import Button from "@mui/material/Button";
// import { MobileNumberWithCode } from "qdm-component-library";
import Grid from "@mui/material/Grid";
import { __countries } from "../../components/counties";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";
import Screen from "../signin/screen";

import { checkUrlFromELPRedirection, snackProps } from "../../utils";

import { withSnackBar } from "../../HOC's";
import { dontShowInMenuRoutes, routes } from "../../router/routes";
import { Box, Typography } from "@mui/material";
import { GreenCheckIcon } from "../../assets";

const styles = (theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "70px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  grid2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f0f2f6",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      background: "#ffffff",
    },
  },
});

class SuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNo: {},
      otpData: {
        phoneNumber: "",
        phoneNumberISD: "",
        otp: "",
      },
      OTP_: "",
      signUpPage: true,
      otpPage: false,
      confirmationPage: false,
      dropdownData: __countries,
      checked: false,
      code: { code: "MY", label: "Malaysia", phone: "60" },
      number: "",
      loading: false,
      searchParams: "",
    };
  }

  // shouldComponentUpdate(nextProps) {
  //   // Login AUTH
  //   const { loginState } = nextProps;
  //   const oldLoginState = this.props.loginState;

  //   if (oldLoginState?.loading !== loginState?.loading) {
  //     ;
  //     if (!loginState?.loading && !loginState?.error) {
  //       ;
  //       // Redirect to home
  //       this.props.history.push(routes.home.pathName);
  //     } else if (loginState?.error) {
  //       this.props.snack.setSnack({
  //         open: true,
  //         severity: snackProps.severity.error,
  //         message: "Failed to login. Redirecting to Login page.",
  //       });
  //       setTimeout(() => {
  //         this.props.history.push(dontShowInMenuRoutes.login.pathName);
  //       }, 1000);
  //     }
  //   }

  //   return true;
  // }
  componentDidMount() {
    // Save Search Params
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      this.setState({
        searchParams: urlParams,
      });
    }
  }

  loginUser = () => {
    // this.props.history.push(dontShowInMenuRoutes.selfRegistration.pathName);
    const { userId = "", password = "" } = this.props?.history?.location?.state;
    const _piload = {
      email: userId,
      password: password,
      device: "client_app_web_roles",
    };
    // const _piload = {
    //   email: "jash2",
    //   password: "jash@123",
    //   device: "client_app_web_roles",
    // };
    Promise.resolve(this.props.LOGIN_AUTH(_piload))
      .then(async (res) => {
        if (res?.payload?.error) {
          this.props.snack.setSnack({
            open: true,
            severity: snackProps.severity.error,
            message: "Failed to login. Redirecting to Login page.",
          });
          setTimeout(() => {
            this.props.history.push(dontShowInMenuRoutes.login.pathName);
          }, 1000);
        }

        let elpProducts = checkUrlFromELPRedirection(this.state.searchParams);

        if (Array.isArray(elpProducts) && elpProducts?.length > 0) {
          this.props.history.replace(routes.enquiries.pathName);
          this.props.history.go(0);
        } else {
          // Redirect to home
          this.props.history.replace(routes.home.pathName);
          this.props.history.go(0);
        }
      })
      .catch((err) => {
        this.props.snack.setSnack({
          open: true,
          severity: snackProps.severity.error,
          message: "Failed to login. Redirecting to Login page.",
        });
        setTimeout(() => {
          this.props.history.push(dontShowInMenuRoutes.login.pathName);
        }, 1000);
      });
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.props?.loginState;
    const { loading: enquiryAddLoading } = this.props?.enquiryAdd;

    return (
      <div style={{ overflowX: "hidden" }}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Illustration */}
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              background: "white",
              minHeight: "100vh",
            }}
          >
            <Screen imageURL={celebration} />
          </Grid>

          {/* Information Card */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            alignItems="center"
            justifyContent="center"
            className={classes.grid2}
          >
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                style={{
                  background: "white",
                  padding: "8px 40px 40px",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <CheckCircleIcon color="success" sx={{ fontSize: "8rem" }} /> */}
                  <GreenCheckIcon fill="#353596" sx={{ fontSize: "8rem" }} />
                </div>
                <div style={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    sx={{ m: 2.5 }}
                    // style={{
                    //   margin: "20px",
                    //   color: "#363A57",
                    //   fontSize: "20px",
                    //   fontWeight: 500,
                    // }}
                  >
                    Yaay! <br /> Registered Successfully
                  </Typography>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      marginLeft: "15px",
                      color: "#6F6F6F",
                      fontSize: "16px",
                    }}
                  >
                    Congratulations{" "}
                    <Box
                      component="span"
                      sx={{ color: "text.primary" }}
                    >
                      {this.props?.history?.location?.state?.name ??
                        this.props?.history?.location?.state?.userId}
                    </Box>
                   !
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p
                    style={{
                      margin: "15px",
                      color: "#6F6F6F",
                      fontSize: 15,
                    }}
                  >
                    {/* Congratulations{" "}
                    <Box
                      component="span"
                      sx={{ fontWeight: 600, color: "text.primary" }}
                    >
                      {this.props?.history?.location?.state?.name ??
                        this.props?.history?.location?.state?.userId}
                    </Box>
                    <br/> */}
                   Your tanya.care account has been successfully created. Click on EXPLORE to find out the available services and promotions on tanya.care.
                  </p>
                </div>
                <div className={classes.buttonStyle}>
                  <Button
                    style={{ width: "100%", textTransform: "none" }}
                    variant="contained"
                    disabled={loading || enquiryAddLoading}
                    onClick={() => this.loginUser()}
                    sx={{ marginTop: { xs: "20px", sm: "20px", md: "14px" } }}
                    id={`${
                      enquiryAddLoading
                        ? "enquiry-adding-btn"
                        : !enquiryAddLoading && !loading
                        ? "explore-btn"
                        : "signin-btn"
                    }`}
                  >
                    {loading && "Signing In..."}
                    {enquiryAddLoading && "Enquiry Adding..."}
                    {!enquiryAddLoading && !loading && "Explore"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

//export default ForgotUserId;
const mapStateToProps = (state) => ({
  loginState: state?.authState?.loginAuth,
  enquiryAdd: state?.enquiry_management?.enqpageadd,
});

export default connect(
  mapStateToProps,
  actions
)(withSnackBar(withRouter(withStyles(styles)(SuccessPage))));
