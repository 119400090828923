const TanyaCareTheme = {
  typography: {
    // "fontFamily": [
    //     'Gill_Sans',
    //     '-apple-system',
    //     'BlinkMacSystemFont',
    //     '"Segoe UI"',
    //     'Roboto',
    //     '"Helvetica Neue"',
    //     'Arial',
    //     'sans-serif',
    //     '"Apple Color Emoji"',
    //     '"Segoe UI Emoji"',
    //     '"Segoe UI Symbol"',
    //   ].join(','),
    fontFamily: ["Roboto"].join(","),
    // "fontWeightLight": 300,
    // "fontWeightRegular": 300,
    // "fontWeightMedium": 400,
    // "fontWeightBold": 500,
    color: "#363a57",
    letterSpacing: "0.00938em",
    subtitle1: {
      fontSize: "1.25rem", //20px
    },
    subtitle2: {
      fontSize: "1.125rem", //18px
    },
    body1: {
      fontSize: "1rem", //16px
    },
    body2: {
      fontSize: "0.875rem", //14px
    },
  },
  shape: {
    borderRadius: 6,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          scrollBehavior: "smooth",
        },
        body: {
          backgroundColor: "rgba(244, 244, 245, 1)",
        },
        ".MuiButton-root": {
          borderRadius: "8px",
        },
        ".MuiButton-containedPrimary": {
          "background-color": "#0063E7",
          "&:hover": {
            "background-color": "#0059DD",
          },
        },
        ".MuiButton-outlinedPrimary": {
          color: "#0063E7",
          border: "1px solid rgba(0, 99, 231, 0.5)",
          "&:hover": {
            border: "1px solid #0063E7",
            "background-color": "rgba(0, 99, 231, 0.04)",
          },
        },
        ".MuiInputLabel-asterisk": {
          color: "#bc2d4f",
        },
      },
    },
  },
  palette: {
    ternary: {
      light: "#FFB21B",
      main: "#FFA811",
      dark: "#F59E07",
      contract: "#fff",
    },
    type: "light",
    primary: {
      main: "#0063e7",
      light: "#0a6df1",
      dark: "#0059dd",
    },
    secondary: {
      light: "#FFB21B",
      main: "#FFA811",
      dark: "#F59E07",
      contract: "#fff",
    },
    text: {
      primary: "#363a57",
      secondary: "#9297a5",
    },
    success: {
      main: "#31b198",
      custom: "#3fb2c7",
    },
    error: {
      main: "#bc2d4f",
      custom: "#f15464",
    },
    grey: {
      border: "rgba(219, 222, 229, 0.49)",
      darkBorder: "#d8dce5",
    },
    background: {
      grey: "rgba(53, 53, 150, 0.05)",
      appbar: "#f2f4f8",
    },
    enquiry: {
      status: {
        open: "#f27d35",
        closed: "#363a57",
      },
      viewQuotes: "#0063e7",
      pricingRelated: "#28a9c1",
    },
  },
  mixins: {
    customTop: {
      top: "56px !important",
      "@media (min-width:0px) and (orientation: landscape)": {
        top: "48px !important",
      },
      "@media (min-width:600px)": {
        top: "64px !important",
      },
    },
  },
};
export default TanyaCareTheme;
