import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
// import { CameraSvg } from "../../assets";
import { Typography, Badge } from "@mui/material";
import { useStyles } from "./style";
// import PropTypes from "prop-types";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import { useTheme } from "@emotion/react";
// import { useThemeProps } from "@mui/material";
// import { ImageEditor } from "../imageEditor";
// import { withAlertAndDialog } from "../../HOCs";
// import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";

function Component(props) {
  const classes = useStyles();
  const [object, setObject] = useState(null);
  //   const theme = useTheme()
  // const handleEdit = () =>{
  //   props.dialog.setDialog({
  //     ...props.dialog,
  //     open: true,
  //     title: "Edit Image",
  //     body:
  //       <ImageEditor image = {props.value} callBackFunction={props.handleFileChange}/>,
  //     positiveBtn: "Ok",
  //     negativeBtn: "CANCEL",
  //     dontShowButtons:true,
  //     onOk: ()=>{},
  //     onCancel: ()=>{
  //       props.dialog.setDialog({

  //         ...props.dialog,
  //         open: false,
  //       })
  //     },
  //   });
  // }

  useEffect(() => {
    if (props?.value instanceof File) {
      setObject(URL.createObjectURL(props?.value));
    }
    return () => {
      URL.revokeObjectURL(object);
    };
  }, [props?.value]);

  return (
    <>
      <div>
        {props?.value ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Avatar
                onClick={() => {
                  if (document.getElementById(props?.id)) {
                    document.getElementById(props?.id).click();
                  }
                }}
                sx={{ width: 30, height: 30, bgcolor: "common.white" }}
              >
                <CameraAltIcon
                  sx={{ color: "text.primary", fontSize: "1rem" }}
                />
              </Avatar>
            }
            sx={{
              "& .MuiBadge-badge": {
                transform: "scale(1) translate(-15%, 100%)",
              },
            }}
          >
            <Avatar
              alt="preview image"
              id="image"
              src={object || props?.value}
              sx={{
                width: 80,
                height: 80,
                bgcolor: "grey.200",
                boxShadow:
                  "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
              }}
            />
          </Badge>
        ) : (
          <Avatar
            onClick={() => {
              if (document.getElementById(props?.id)) {
                document.getElementById(props?.id).click();
              }
            }}
            sx={{ width: 80, height: 80, bgcolor: "grey.200" }}
          >
            <CameraAltIcon sx={{ color: "text.secondary" }} />
          </Avatar>
        )}

        <input
          type="file"
          hidden
          accept="image/x-png,image/gif,image/jpeg"
          // id="upload"
          id={props?.id}
          onChange={props.handleFileChange}
        />
      </div>
      <div className={classes.errorRoot}>
        {props.error && (
          <Typography
            variant="body1"
            component="div"
            className={classes.errorText}
          >
            {props.errorText}
          </Typography>
        )}
      </div>
    </>
  );
}

export const UploadProfileImage = (props) => {
  return <Component {...props} />;
};
