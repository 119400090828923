import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FamilyMembersIcon } from "../../../../assets";

function FamilyMemberDetails({ data, onClick = () => { }, ...props }) {
  return (
    <Stack
      id={`viewFamilyMembersMobile`}
      sx={{ bgcolor: "common.white", px: 2.5, py: 1.5, mt: 2.5, mb: 10 }}
      style={{
        boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    >
      <Stack flexDirection="row" gap={2}>
        <Avatar
          variant="rounded"
          sx={{ width: 30, height: 30, bgcolor: "#4A8AE2" }}
        >
          <FamilyMembersIcon stroke={"white"} />
        </Avatar>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            flex: 1,
          }}
        >
          <Typography fontWeight="500">Family Member Details{`${data?.length > 0 ? ` (${data?.length})` : ``}`}</Typography>

          <IconButton size="small">
            <ChevronRightIcon sx={{ color: "text.secondary" }} />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default FamilyMemberDetails;
