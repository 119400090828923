import React, { useState } from "react";
import {
  Box,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { FamilyMemberList, FamilyMemberListCarousel } from ".";
import Family from "../../../assets/family.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function EmptyFamilyMembers() {
  return (
    <Box>
      <Stack alignItems="center">
        <img src={Family} alt="no family members" />
      </Stack>
      <Typography sx={{ mt: 1.5 }}>No Family Members Added</Typography>
    </Box>
  );
}

function FamilyMembersDetailSection(props) {
  const theme = useTheme();
  const belowMd = useMediaQuery(theme.breakpoints.down("md"));

  const { members, onEditClick, onDeleteClick } = props;

  // Component States
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevClick = () => {
    if (currentSlide !== 0) {
      setCurrentSlide((prevSelectedItem) => prevSelectedItem - 1);
    }
  };

  const nextClick = () => {
    if (currentSlide !== members?.length - 1) {
      setCurrentSlide((prevSelectedItem) => prevSelectedItem + 1);
    }
  };

  return (
    <Stack sx={{ height: "100%" }}>
      <Stack sx={{ mb: 3 }} flexDirection="row" gap={0.5} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontWeight="bold">
          {belowMd
            ? `Added Family Members${
                members?.length > 0 ? " (" + members?.length + ")" : ""
              }`
            : "Family Members Details"}
        </Typography>

        {(members?.length > 1 && belowMd) && <Stack flexDirection="row" gap={0.5}>
          <Box>
            <IconButton size={"small"} disabled={currentSlide === 0} onClick={prevClick} id={`prev-btn-${props?.parentID}`}>
              <ChevronLeftIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box>
            <IconButton size={"small"} disabled={currentSlide === members?.length - 1} onClick={nextClick} id={`next-btn-${props?.parentID}`}>
              <ChevronRightIcon fontSize="small" />
            </IconButton>
          </Box>
        </Stack>}
      </Stack>

      {Array.isArray(members) && members?.length > 0 ? (
        <>
          {belowMd ? (
            <FamilyMemberListCarousel
              currentSlide={currentSlide}
              members={members}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          ) : (
            <FamilyMemberList
              members={members}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          )}
        </>
      ) : (
        <Box sx={{ flex: 1, mt: () => belowMd ? 0 : 10, alignSelf: "center" }}>
          <EmptyFamilyMembers />
        </Box>
      )}
    </Stack>
  );
}

export default FamilyMembersDetailSection;
