import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, checked }) => ({
    marginLeft: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      flex: 1,
      color: theme.palette.text.secondary,
      ...(checked && {
        color: theme.palette.text.primary,
      }),
    },
  })
);

function CustomRadioFormControlLabel(props) {
  return (
    <StyledFormControlLabel
      disabled={props.disabled}
      checked={props.checked}
      value={props.value}
      
      control={
        <Radio
          sx={{
            p: "6px",
            color: (theme) => theme.palette.grey.border,
            "&.Mui-checked": {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
          checkedIcon={<CheckCircleIcon color="success" />}
        />
      }
      label={props.label}
      labelPlacement="start"
    />
  );
}

export default CustomRadioFormControlLabel;
