import React, { useState } from "react";
import { Stack, IconButton, Typography, Box } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { CustomButton, SwitchStyleToggleButton } from "../../components";
import { RightArrowStripesIcon } from "../../assets";

function SkipDialog(props) {
  const { question, handleContinue, handleClose } = props;

  //   Component States
  const [continueStep, setContinue] = useState(true);

  // const handleChange = (event) => {
  //   setContinue(event.target.checked);
  // };

  const handleToggle = () => {
    setContinue((prevState) => !prevState);
  };

  return (
    <Stack alignItems='center' sx={{ position: "relative", pb: 6.5, px: 1, pt: 1 }}>
      <Stack alignItems="flex-end" sx={{ width: "100%" }}>
        <IconButton onClick={handleClose} aria-label="close-drawer">
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Typography color="textSecondary" align="center">{question}</Typography>
      {/* <Switch
        checked={continueStep}
        onChange={handleChange}
        inputProps={{ "aria-label": "skip-step" }}
        sx={{mt: 4,mb: 6}}
      /> */}
      <Box sx={{mt: 4,mb: 6}}>
      <SwitchStyleToggleButton value={continueStep} onClick={handleToggle} />
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        <CustomButton
          fullWidth
          sx={{ borderRadius: 0, py: 2 }}
          endIcon={<RightArrowStripesIcon />}
          onClick={() => handleContinue(!continueStep)}
        >
          Continue
        </CustomButton>
      </Box>
    </Stack>
  );
}

export default SkipDialog;
