import { SvgIcon } from "@mui/material"
import * as React from "react"

function PurchaseBagIcon(props) {
    return (
        <SvgIcon width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M9.873 4a1.68 1.68 0 00-1.678 1.678v1.678H4v10.906a1.68 1.68 0 001.678 1.678h11.745a1.68 1.68 0 001.677-1.678V7.356h-4.194V5.678A1.68 1.68 0 0013.228 4H9.873zm0 1.678h3.356v1.678H9.873V5.678zM5.678 9.034h11.745v9.228H5.678V9.034zm3.356 1.678a.839.839 0 100 1.678.839.839 0 000-1.678zm5.034 0a.839.839 0 10.32.064.84.84 0 00-.321-.064h.001z"
            />
        </SvgIcon>
    )
}

export default PurchaseBagIcon
