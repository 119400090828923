import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorPrimary,
  },
  cls2: {
    fill: (props) => props.htmlColorSecondary,
    // stroke: (props) => props.htmlColorSecondary,
  },
}));

function NotepadDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <path
        d="M10.751 9.76v3.04h-3.04v2.027h3.04v3.04h2.027v-3.04h3.04V12.8h-3.04V9.76H10.75Z"
        className={classes.cls1}
      />
      <path
        d="M11.889 0a2.68 2.68 0 0 0-2.667 2.667H5.667A2.68 2.68 0 0 0 3 5.333v16A2.68 2.68 0 0 0 5.667 24H18.11a2.68 2.68 0 0 0 2.667-2.667v-16a2.68 2.68 0 0 0-2.667-2.666h-3.555A2.68 2.68 0 0 0 11.889 0Zm0 1.778a.889.889 0 1 1-.889.889.876.876 0 0 1 .889-.89ZM5.667 4.444h3.555v.89a.889.889 0 0 0 .89.888h3.555a.889.889 0 0 0 .889-.889v-.889h3.555a.874.874 0 0 1 .889.89v16a.877.877 0 0 1-.889.888H5.667a.875.875 0 0 1-.89-.889v-16a.875.875 0 0 1 .89-.889Z"
        className={classes.cls2}
      /> */}

      {/* <g data-name="Group 95325">
        <g data-name="Group 95326">
          <path
            d="M8.72 10.98v3.42H5.3v2.28h3.42v3.42H11v-3.42h3.42V14.4H11v-3.42Z"
            className={classes.cls1}
          />
          <path
            d="M10 0a3.015 3.015 0 0 0-3 3H3a3.015 3.015 0 0 0-3 3v18a3.015 3.015 0 0 0 3 3h14a3.015 3.015 0 0 0 3-3V6a3.015 3.015 0 0 0-3-3h-4a3.015 3.015 0 0 0-3-3Zm0 2a1 1 0 1 1-1 1 .985.985 0 0 1 1-1ZM3 5h4v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V5h4a.984.984 0 0 1 1 1v18a.984.984 0 0 1-1 1H3a.984.984 0 0 1-1-1V6a.984.984 0 0 1 1-1Z"
            className={classes.cls2}
          />
        </g>
      </g> */}

      <path
        d="M10.751 9.76v3.04h-3.04v2.027h3.04v3.04h2.027v-3.04h3.04V12.8h-3.04V9.76H10.75Z"
        className={classes.cls1}
      />
      <path
        d="M11.889 0a2.68 2.68 0 0 0-2.667 2.667H5.667A2.68 2.68 0 0 0 3 5.333v16A2.68 2.68 0 0 0 5.667 24H18.11a2.68 2.68 0 0 0 2.667-2.667v-16a2.68 2.68 0 0 0-2.667-2.666h-3.555A2.68 2.68 0 0 0 11.889 0Zm0 1.778a.889.889 0 1 1-.889.889.876.876 0 0 1 .889-.89ZM5.667 4.444h3.555v.89a.889.889 0 0 0 .89.888h3.555a.889.889 0 0 0 .889-.889v-.889h3.555a.874.874 0 0 1 .889.89v16a.877.877 0 0 1-.889.888H5.667a.875.875 0 0 1-.89-.889v-16a.875.875 0 0 1 .89-.889Z"
        className={classes.cls2}
        strokeWidth={1}
      />
    </SvgIcon>
  );
}

NotepadDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#F27D35",
  htmlColorSecondary: "#000",
};

export default NotepadDuoToneIcon;
