import React from "react";
import { CustomDrawer } from "./components";
import { DrawerContext } from "./contexts";
import { drawerProps } from "./utils";

const defaultState = {
    open: false,
    anchor: drawerProps.direction.right,
    variant: drawerProps.variant.temporary,
    component: "",
    drawerWidth: drawerProps.drawerWidth,
    closeIconHiden: drawerProps.closeIconHiden,
    sx: {
        Drawer: {}
    },
};

class AppDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...defaultState,
        };
    }

    // Open the drawer - used for swipeable drawer - onOpen is required for swipeable drawer
    // Used this to suppress error
    handleOpen = () => {
        return null
    }

    // Close the drawer
    handleClose = () => {
        this.setState({
            ...defaultState,
        });
    };

    handleCreate = (contextDrawerProps) => {
        this.setState({ ...contextDrawerProps });
    };

    render() {
        return (
            <DrawerContext.Provider
                value={{
                    ...this.state,
                    onClose: this.handleClose,
                    setDrawer: this.handleCreate,
                }}
            >
                {this.state.open && (
                    <CustomDrawer
                        closeIconHiden={this.state.closeIconHiden}
                        drawerWidth={this.state.drawerWidth}
                        open={this.state.open}
                        anchor={this.state.anchor}
                        variant={this.state.variant}
                        component={this.state.component}
                        handleClose={this.handleClose}
                        handleOpen={this.handleOpen}
                        sx={this.state.sx}
                    />
                )}
                {this.props.children}
            </DrawerContext.Provider>
        );
    }
}

export default AppDrawer;
