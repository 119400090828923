import React from "react";
import { Redirect, Route } from "react-router-dom";
import { dontShowInMenuRoutes } from "./routes";
import Loading from "../components/loaders/loading";

import { useSelector } from "react-redux";
// import { access } frsom "./access";
// import { AuthContext } from "../contexts";
// import SideNavBar from "../components/navBars/sideNavBar";
import { SideNavBar } from "../components";
import AppSOS from "../App.sos";

const PrivateRoute = ({ children, ...rest }) => {
  const { data, error, loading } = useSelector(
    (state) => state?.authState?.loginAuth
  );

  React.useEffect(()=>{
    return true
  },[loading, error, data])

  //   const authContext = React.useContext(AuthContext);
  // const isAuthenticated = (router) => {
  //   const user_role = loginState?.data?.user_role; //user_role is a string

  //   if (user_role) {
  //     return true
  //   };
  // }

  return (
    <>
      {loading && <Loading />}
      {(error || (!error && !loading && !data?.user_role)) && (
        <Route
          {...rest}
          render={(_) => (
            <Redirect
              to={{
                pathname: dontShowInMenuRoutes.login.pathName,
                state: { from: _?.location },
              }}
            />
          )}
        />
      )}
      {!loading && !error && data?.user_role && (
        <Route
          {...rest}
          render={(_) =>
            data?.user_role ? (
              <SideNavBar>{children}</SideNavBar>
            ) : (
              <Redirect
                to={{
                  pathname: dontShowInMenuRoutes.login.pathName,
                  state: { from: _?.location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

export default PrivateRoute;
