import { SvgIcon } from "@mui/material";
import * as React from "react";

function GridIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      htmlColor="transparent"
      stroke={props.color ?? "rgba(0, 0, 0, 0.54)"}
      {...props}
    >
      <path
        d="M5.17 5h4.348a.169.169 0 01.175.17v4.348a.17.17 0 01-.175.175H5.169A.169.169 0 015 9.518V5.169A.17.17 0 015.17 5v0z"
        strokeWidth={1.5}
      />
      <path
        d="M14.482 5h4.349a.169.169 0 01.169.17v4.348a.17.17 0 01-.17.169h-4.355a.169.169 0 01-.162-.169V5.169A.169.169 0 0114.482 5v0z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.17 14.313h4.348a.17.17 0 01.169.169v4.349a.169.169 0 01-.169.169H5.169A.169.169 0 015 18.83v-4.355a.169.169 0 01.17-.162v0zM14.482 14.313h4.349a.169.169 0 01.169.169v4.349a.169.169 0 01-.17.169h-4.355a.169.169 0 01-.162-.17v-4.355a.169.169 0 01.169-.162v0z"
        strokeWidth={1.5}
      />
    </SvgIcon>
  );
}

export default GridIcon;
