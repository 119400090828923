import React from "react"
import { Stack, Typography, Divider } from "@mui/material"
import { CustomButton } from "../../../components"

const DeletePrompt = ({ handleClose, handleAccept }) => {
    return (
      <Stack spacing={2} justifyContent="center" sx={{ pt: "20px" }}>
        <Typography align="center" variant="h6" sx={{ px: "20px" }}>
          Delete Family Member
        </Typography>
        <Typography
          align="center"
          variant="body1"
          color="textSecondary"
          sx={{ px: "20px" }}
        >
          Are you sure, do you want to delete <br /> family member?
        </Typography>
        <Stack
          flexDirection="row"
          sx={{ borderTop: 1, borderColor: "grey.border" }}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ bgcolor: "grey.border" }}
            />
          }
        >
          <CustomButton
            onClick={handleClose}
            variant="text"
            sx={{ flex: 1, py: 1.25, borderRadius: 0, color: "text.secondary" }}
          >
            No
          </CustomButton>
          <CustomButton
            onClick={handleAccept}
            variant="text"
            sx={{ flex: 1, py: 1.25, borderRadius: 0 }}
            color="primary"
          >
            Yes
          </CustomButton>
        </Stack>
      </Stack>
    );
  };

export default DeletePrompt