import { styled, Chip, alpha } from "@mui/material";

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  height: 26,
  borderRadius: 4,
  ...(color === "success" && {
    color: theme.palette.success.custom,
    backgroundColor: alpha(theme.palette.success.custom, 0.08),
  }),
  ...(color === "error" && {
    color: theme.palette.error.custom,
    backgroundColor: alpha(theme.palette.error.custom, 0.08),
  }),
  //   border: "1px solid #E0E0E0",
  //   borderRadius: 8,
  //   padding: 12,
}));
