import React from "react";

export const LeftArrow = (props) => {
    const width = props?.width ? props?.width : 24.4;
    const height = props?.height ? props?.height : 16.9;
    const color = props?.color ? props?.color : '#3b3f5b';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24.4 16.9"
            {...props}
        >
              <path id="icons8-left_1_" data-name="icons8-left (1)" d="M16.186,12.968a1.4,1.4,0,0,0-.966.439l-6.94,7.028-.966.988.966.988,6.94,7.028a1.413,1.413,0,0,0,2.021-1.977l-4.59-4.634H30.286a1.406,1.406,0,1,0,0-2.811H12.65l4.59-4.634a1.406,1.406,0,0,0-1.054-2.416Z" transform="translate(-7.313 -12.966)" 
              fill={color} />
        </svg>
    );
};