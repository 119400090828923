import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { snackbarClasses, useMediaQuery } from "@mui/material";
import MobileProfile from "./mobile";
import WebProfile from "./web";
import EditProfile from "./editProfile";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import { isArrayEmpty, isJson, removeUsedOptions, snackProps } from "../../utils";
import { SnackContext } from "../../contexts";

const { GET_CLIENT_PROFILE, UPSERT_CLIENT } = actions;

// const initialPersonalDetailsState = {
//   profileImage: "",
//   pic: "",
//   salutation: "",
//   name: "",
//   primaryEmail: "",
//   dob: null,
//   gender: "",
//   occupation: "",
//   preferred_language: [],
// };

// const initialIdDetailsState = {
//   idTypes: [],
// };
// const initialContactDetailsState = {
//   otherPhoneNumbers: [],
// };
// const initialAddressDetailsState = {
//   otherAddress: [],
// };

// const initialHealthInformationState = {
//   height: "",
//   weight: "",
//   bmi: 0,
//   bloodGroup: "",
// };

// const initialFamilyMembersState = {
//   relations: [],
// };

const initialScreenId = 0;

const initialState = {
  profileImage: "",
  pic: "",
  salutation: "",
  name: "",
  primaryEmail: "",
  dob: null,
  gender: "",
  occupation: "",
  languages: [],
  otherEmailIds: [],
  idTypes: [],
  otherPhoneNumbers: [],
  otherAddress: [],
  height: "",
  weight: "",
  bmi: 0,
  bloodGroup: "",
  relations: [],
};

const {
  GET_SALUTATION,
  GET_LANGUAGE,
  GET_ADDRESS_TYPES,
  GET_EMAIL_TYPES,
  GET_PHONE_TYPES,
  GET_CLIENT_ID_TYPES,
  GET_BLOOD_GROUP,
  GET_RELATIONSHIP,
  FILE_UPLOAD,
} = actions;

function ProfileDetail() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const snack = React.useContext(SnackContext);

  // Store Actions
  useEffect(() => {
    dispatch(GET_SALUTATION({}));
    dispatch(GET_LANGUAGE({}));
    dispatch(GET_ADDRESS_TYPES({}));
    dispatch(GET_EMAIL_TYPES({}));
    dispatch(GET_PHONE_TYPES({}));
    dispatch(GET_CLIENT_ID_TYPES({}));
    dispatch(GET_BLOOD_GROUP({}));
    dispatch(GET_RELATIONSHIP({}));
  }, []);

  // Store States
  const profileData = useSelector((state) => state?.clientProfile?.getProfile);
  const salutation = useSelector(
    (state) => state?.clientMaster?.clientSalutation
  );
  const language = useSelector((state) => state?.clientMaster?.clientLanguage);
  const address_type = useSelector(
    (state) => state?.clientMaster?.clientAddressTypes
  );
  const email_type = useSelector(
    (state) => state?.clientMaster?.clientEmailTypes
  );
  const phone_type = useSelector(
    (state) => state?.clientMaster?.clientPhoneTypes
  );
  const client_id_type = useSelector(
    (state) => state?.clientMaster?.clientIdTypes
  );
  const blood_group = useSelector(
    (state) => state?.clientMaster?.clientBloodGroup
  );
  const relationship = useSelector(
    (state) => state?.clientMaster?.clientRelationship
  );

  // All dependency Options
  const dependencyOptions = {
    salutation: isArrayEmpty(salutation?.data),
    language: isArrayEmpty(language?.data),
    email_type: isArrayEmpty(email_type?.data),
    client_id_type: isArrayEmpty(client_id_type?.data),
    phone_type: isArrayEmpty(phone_type?.data),
    address_type: isArrayEmpty(address_type?.data),
    blood_group: isArrayEmpty(blood_group?.data),
    relationship: isArrayEmpty(relationship?.data),
  };

  // Component State

  const [state, setState] = useState({});
  const [flag, setFlag] = useState(false);
  const [screenId, setScreenId] = useState(initialScreenId);

  useEffect(() => {
    dispatch(GET_CLIENT_PROFILE({}));
  }, [dispatch, flag]);

  useEffect(() => {
    const data = profileData?.data;
    setState({
      ...initialState,
      profileImage: data?.profilePic,
      salutation: data?.salutation,
      name: data?.clientName,
      primaryEmail: data?.emailid,
      dob: data?.dob,
      gender: data?.gender,
      occupation: data?.occupation,
      languages: data?.languages ? JSON.parse(data?.languages) : [],
      otherEmailIds: data?.otherEmailIds,
      idTypes: data?.idTypes,
      otherPhoneNumbers: data?.otherPhoneNumbers,
      otherAddress: data?.otherAddress,
      height: data?.height,
      weight: data?.weight,
      bmi: data?.bmi,
      bloodGroup: data?.bloodGroup,
      relations: data?.familyMembers,
      medical_history: data?.medicalHistory ?? "",
      precaution: data?.specialPrecautions ?? [],
    });
  }, [profileData]);

  // return <EditProfile />;

  const onAPIHitting = (finalData) => {
    setState(finalData);
    debugger;
    // Action Call
    Promise.resolve(dispatch(UPSERT_CLIENT(finalData)))
      .then((res) => {
        if (res?.error) {
          return false;
        }
        snack.setSnack({
          open: true,
          message: "Profile Updated Successfully",
          duration: 6000,
          severity: snackProps.severity.success,
          setSnack: () => null,
        });
        setFlag((prev) => !prev);
        resetScreen();
        console.log(res);
      })
      .catch((err) => {
        debugger;
        snack.setSnack({
          open: true,
          message: "Something went wrong.Please try again later.",
          duration: 6000,
          severity: snackProps.severity.error,
          setSnack: () => null,
        });
      });
  };

  const handleUpdate = async (updatedData) => {
    debugger;
    let finalData = {
      // Data from the CLIENT_PROFILE ACTION
      ...profileData?.data,

      // INITIAL STATE
      ...state,

      // UPDATED DATA
      ...updatedData,
    };

    finalData = {
      ...finalData,
      salutation: finalData?.salutation?.value,
      bloodGroup: {
        value: finalData?.bloodGroup,
        label: finalData?.bloodGroup,
      },
      languages: isJson(finalData?.languages)
        ? finalData?.languages
        : JSON.stringify(finalData?.languages),
    };

    console.clear();
    // console.log(finalData);

    let idTypePromises = [],
      familyMemberPromises = [],
      profilePicPromises = [];

    let idTypeFetch;

    // if (finalData?.idTypes) {
    const idData = [...finalData?.idTypes];
    const familyData = [...finalData?.familyMembers];

    // let promises = await new Promise(async (resolve, reject) => {
    //   idTypeFetch = finalData?.idTypes?.map(async (x, index) => {
    //     await dispatch(
    //       FILE_UPLOAD({
    //         file: x?.id_photo,
    //         fileName: x.id_photo?.name?.trim(),
    //       })
    //     );
    //   });
    //   await Promise.all([idTypeFetch])
    //     .then((data) => {
    //       console.clear()
    //       console.log(data)
    //       resolve(data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       reject(false);
    //     });
    // });

    await Promise.all([
      ...finalData?.idTypes?.map(async (x, index) => {
        await dispatch(
          FILE_UPLOAD({
            file: x?.id_photo,
            fileName: x.id_photo?.name?.trim(),
          })
        )
          .then((val) => {
            let _response = val;
            if (_response?.payload?.data?.fileid) {
              idData[index].id_photo = _response?.payload?.data?.fileid;
            }
            finalData = {
              ...finalData,
              idTypes: idData,
            };
          })
          .catch((err) => {
            console.log(err);
          });
      }),
      ...finalData?.familyMembers?.map((z, i) => {
        z?.idTypes?.map(async (x, index) => {
          await dispatch(
            FILE_UPLOAD({
              file: x?.id_photo,
              fileName: x.id_photo?.name?.trim(),
            })
          )
            .then((val) => {
              let _val = val;
              // alert("Hi")
              if (_val?.payload?.data?.fileid) {
                familyData[i].idTypes[index].id_photo =
                  _val?.payload?.data?.fileid;
              }
              finalData = {
                ...finalData,
                familyMembers: familyData,
              };
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }),
      await dispatch(
        FILE_UPLOAD({
          file: finalData?.profileImage,
          fileName: finalData?.profileImage?.name?.trim(),
        })
      )
        .then((val) => {
          let _val = val;
          if (_val?.payload?.data?.fileid) {
            finalData.profileImage = _val?.payload?.data?.fileid;
            finalData.profilePic = _val?.payload?.data?.fileid;
            finalData.uploadPhoto = _val?.payload?.data?.fileid;
          }
          console.log(finalData);
          setState(finalData);
        })
        .catch((err) => {}),
    ])
      .then(() => {
        console.clear();
        console.log(finalData);
        onAPIHitting(finalData);
      })
      .catch((err) => {
        snack.setSnack({
          open: true,
          message: "Something went wrong. Please try again later",
          duration: 6000,
          severity: snackProps.severity.error,
          setSnack: () => null,
        });
      });
  };

  const resetScreen = () => {
    debugger;
    setScreenId(initialScreenId);
  };

  const changeEditScreenId = (id) => {
    setScreenId(id);
  };

  if (belowSm) {
    return (
      <MobileProfile
        data={profileData?.data}
        loading={profileData?.loading}
        state={state}
        handleUpdate={handleUpdate}
        dependencyOptions={dependencyOptions}
      />
    );
  } else {
    return (
      <WebProfile
        data={profileData?.data}
        loading={profileData?.loading}
        state={state}
        handleUpdate={handleUpdate}
        dependencyOptions={dependencyOptions}
        screenId={screenId}
        onCancel={resetScreen}
        changeEditScreenId={changeEditScreenId}
      />
    );
  }
}

export default ProfileDetail;
