import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomButton } from "../..";
import { IconButton } from "@mui/material";
import { getFormattedAddress } from "../../../utils";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import CustomRadioFormControlLabel from "./customRadioFormControlLabel";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RightArrowStripesIcon } from "../../../assets";

function Location(props) {
  const { item } = props;
  const radioGroup = useRadioGroup();
  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }
  return (
    <Box onClick={() => props.onClick(props.value)}>
      <Stack
        gap={0}
        sx={{
          borderBottom: 1,
          borderColor: "grey.border",
          px: { xs: 2.5, md: 3.5 },
          pt: 2,
          cursor: "pointer",
        }}
      >
        <Typography color="textSecondary" sx={{ mb: 0.5 }}>
          {item?.addressType?.label}
        </Typography>
        <CustomRadioFormControlLabel
          value={props.value}
          label={props.label}
          checked={checked}
        />
        <Typography
          sx={{
            mb: 2,
            mr: 2.25,
            color: "text.secondary",
            ...(checked && {
              color: "text.primary",
            }),
          }}
        >
          {getFormattedAddress(item)}
        </Typography>
      </Stack>
    </Box>
  );
}

function SavedAddressDrawer(props) {
  const [value, setValue] = React.useState("");

  useEffect(() => {
    setValue(props.value?.value);
  }, [props.value]);

  const handleChange = (fieldValue) => {
    setValue(fieldValue);
  };
  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          pt: 2,
          pb: 1.5,
          px: 1,
          alignItems: "center",
          border: 1,
          borderColor: "grey.border",
          position: "absolute",
          right: 0,
          left: 0,
          top: 0,
        }}
      >
        <IconButton onClick={props.handleClose} aria-label="close-drawer">
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ flex: 1, textAlign: "center", ml: "-44px" }}>
          Select Address
        </Typography>
      </Stack>
      <Box
        sx={{
          mb: 6.5,
          mt: 8.25,
          maxHeight: "calc(100vh - 52px + 66px)",
          overflow: "scroll",
        }}
      >
        {/* Choose Address */}
        <RadioGroup
          aria-label="gender"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={(evt) => handleChange(evt.target.value)}
        >
          {props.data?.map((option, idx) => (
            <Location
              onClick={handleChange}
              item={option ?? {}}
              value={option?.value}
              label={option?.label}
            />
          ))}
        </RadioGroup>
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        <CustomButton
          fullWidth
          sx={{ borderRadius: 0, py: 2 }}
          endIcon={<RightArrowStripesIcon />}
          onClick={() => props.onSave(value)}
        >
          Save
        </CustomButton>
      </Box>
    </>
  );
}

export default SavedAddressDrawer;
