import {
  DateSlotField,
  StyledToggleButtonGroup,
  ChooseServiceProfessional,
  BookingSuccessModal,
  DrawerSkeleton,
} from "../../components";
import React, { useState, useEffect, useCallback } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { actions } from "tanyacare-middleware";
import { useSelector, useDispatch } from "react-redux";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import {
  durationText,
  getFormattedName,
  loadingArray,
  snackProps,
} from "../../utils";

const {
  CREATE_ACTIVITY,
  EDIT_ACTIVITY,
  SERVICE_PROFESSIONAL_BY_RESOURCE_TYPE_CATEGORY,
  GET_CLIENT_PROFILE,
  GET_OPERATIONAL_HOURS_BASED_ON_TIME,
} = actions;

const dateData = [
  {
    label: "Today",
    value: moment().format("MMM D, YYYY"),
  },
  {
    label: "Tomorrow",
    value: moment().add(1, "days").format("MMM D, YYYY"),
  },
];

function ChooseSlot(props) {
  const dispatch = useDispatch();
  const { data, snack, isEdit = false, isReschedule = false, reload } = props;

  // Store States
  const serviceProfessionals = useSelector(
    (state) => state?.dependency_api?.serviceProfessionalByResourceTypeCategory
  );
  const profileData = useSelector((state) => state?.clientProfile?.getProfile);
  const loginData = useSelector((state) => state?.authState?.loginAuth?.data);
  // const initailDate = {
  //     label: moment(params?.data?.from_timestamp).format('MMM D,YYYY'),
  //     value: moment(params?.data?.from_timestamp),
  // };

  // Component States
  // const [dateData, setDateData] = useState([])
  const [spLoading, setSpLoading] = useState(false);
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dateData[0]);
  const [selectedTime, setSelectedTime] = useState({});
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [slotLoader, setSlotLoader] = useState(true);
  const [customDate, setCustomDate] = useState([]);
  const [onBooking, setOnBooking] = useState(false);
  const [flag, setFlag] = useState(true);
  const [zflag, setZflag] = useState(false);

  // const activityDuration = false
  //     ? moment(params?.data?.to_timestamp).diff(
  //         moment(params?.data?.from_timestamp),
  //         'm',
  //     )
  //     : +data?.duration > 0
  //     ? data?.duration
  //     : 60;
  const activityDuration = +data?.duration > 0 ? data?.duration : 60;

  useEffect(() => {
    dispatch(GET_CLIENT_PROFILE({}));
    (!isEdit || !isReschedule) &&
      getOperationalHours(data?.providerId, selectedDate?.value);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (isReschedule) {
      handleCustomDateChange(moment(props?.from_time));
    }
  }, [isReschedule]);

  useEffect(() => {
    if (flag) {
      if (isReschedule && timeSlotsData.length > 0) {
        setFlag(false);
        // console.clear();
        // console.log(moment(props?.from_time).format("h:mm A"));
        handleTimeSlotChange("", moment(props?.from_time));
        getServiceProfessionals({
          label: moment(props?.from_time).format("h:mm A"),
          value: moment(props?.from_time),
        });

        handleServiceProfessionalChange(data?.professional);
      }
    }
    // else{
    //   if(zflag)
    //   {
    //     setSelectedTime({
    //       label: moment(props?.from_time).format("h:mm A"),
    //       value: moment(props?.from_time),
    //     });
    //   }

    // }
  }, [timeSlotsData]);

  const getOperationalHours = (id, date) => {
    if (!id || !date) {
      return;
    }
    setTimeSlotsData([]);
    const payload = {
      providerId: id,
      day: moment(date).format("dddd"),
      date: moment(date).startOf("days"),
    };
    setSlotLoader(true);
    Promise.resolve(dispatch(GET_OPERATIONAL_HOURS_BASED_ON_TIME(payload)))
      .then((res) => {
        setSlotLoader(false);
        if (res?.payload?.error) {
          return snack.setSnack({
            open: true,
            message: res?.payload?.message
              ? res?.payload?.message
              : "There was an error. Please try again",
            severity: snackProps.severity.error,
          });
        }
        let timeSlots = [];
        if (res?.payload?.[0] && res?.payload?.[0]?.length > 0) {
          res.payload[0].forEach((opHoursData) => {
            const fromTime = opHoursData?.fromTime;
            const toTime = opHoursData?.toTime;
            if (
              moment(fromTime)
                .add(activityDuration, "minutes")
                .seconds(0)
                .milliseconds(0) > moment(toTime).seconds(0).milliseconds(0)
            ) {
              return;
            }
            const operationalTimeSlots = getOperationalTimeSlots(
              fromTime,
              toTime,
              date
            );
            timeSlots = [...timeSlots, ...operationalTimeSlots];
          });
        }

        setTimeSlotsData(timeSlots);
        // if (isReschedule) {
        //   callback();
        // }
      })
      .catch((err) => {
        setSlotLoader(false);
        return snack.setSnack({
          open: true,
          message: "Something went wrong, while getting timeslots",
          severity: snackProps.severity.error,
        });
      });
  };

  const getOperationalTimeSlots = (fromTime, toTime, date) => {
    if (fromTime && toTime) {
      const openTime = {
        hour: moment(fromTime).format("H"),
        minute: moment(fromTime).format("m"),
      };
      const closeTime = {
        hour: moment(toTime).format("H"),
        minute: moment(toTime).format("m"),
      };
      const startDate = moment(date).set({
        hour: openTime.hour,
        minute: openTime.minute,
      });
      const endDate = moment(date).set({
        hour: closeTime.hour,
        minute: closeTime.minute,
      });
      return generateTimeSlots(startDate, endDate, activityDuration);
    } else {
      return [];
    }
  };

  // Generating time slots
  const generateTimeSlots = (start, end, duration = 30) => {
    const timeSlots = [];
    while (start < end) {
      timeSlots.push({
        label: start.format("h:mm A"),
        value: moment(start),
      });
      start.add(duration, "minutes");
    }
    if (isReschedule) {
      let tempArr = timeSlots.filter(
        (x) => x.label === moment(props?.from_time).format("h:mm A")
      );
      if (tempArr?.length < 1) {
        timeSlots.unshift({
          label: moment(props?.from_time).format("h:mm A"),
          value: moment(props?.from_time),
        });
      }
    }
    return timeSlots;
  };

  const handleDateChange = (event, newDate) => {
    if (newDate !== null) {
      const options = [...dateData, ...customDate];
      const selected = options.filter(
        (option) => option.value === newDate
      )?.[0];
      setSelectedDate(selected);
      setSelectedTime({});
      getOperationalHours(data?.providerId, newDate);
    }
  };

  const handleCustomDateChange = (newDate) => {
    let isToday = moment();
    let isTomorrow = moment().add(1, "days");

    const customDateObj = {
      label: moment(newDate).format("MMM D, YYYY"),
      value: moment(newDate).format("MMM D, YYYY"),
    };

    if (
      !newDate?.isSame(isToday, "day") &&
      !newDate?.isSame(isTomorrow, "day")
    ) {
      setCustomDate([customDateObj]);
    }
    setSelectedDate(customDateObj);
    setSelectedTime({});
    getOperationalHours(data?.providerId, newDate);
  };

  const handleTimeSlotChange = (event, newTime) => {
    if (newTime !== null) {
      if (timeSlotsData.length > 0) {
        const selectedTimeSlot = timeSlotsData?.filter((slot) =>
          newTime?.isSame(slot?.value)
        )?.[0];
        // if (!selectedTimeSlot) {
        //   // setTimeSlotsData([
        //   //   {
        //   //     label: moment(props?.from_time).format("h:mm A"),
        //   //     value: moment(props?.from_time),
        //   //   },
        //   //   ...timeSlotsData,
        //   // ]);
        //   setSelectedTime({
        //     label: moment(props?.from_time).format("h:mm A"),
        //     value: moment(props?.from_time),
        //   });
        //   setSelectedProfessional(null);
        //   getServiceProfessionals({
        //     label: moment(props?.from_time).format("h:mm A"),
        //     value: moment(props?.from_time),
        //   });
        // }
        // // alert(JSON.stringify(selectedTimeSlot));
        // else {
        setSelectedTime(selectedTimeSlot);
        setSelectedProfessional(null);
        getServiceProfessionals(selectedTimeSlot);
        // }
      }
    }
    if (isReschedule) {
      handleServiceProfessionalChange(data?.professional);
    }
  };

  // Get the available professionals
  const getServiceProfessionals = (selectedTimeSlot) => {
    setSpLoading(true);
    const payload = {
      partnerId: data?.providerId,
      resourceTypeCategoryId: data?.resourceTypeCategoryId,
      fromTime: selectedTimeSlot?.value,
      toTime: moment(selectedTimeSlot?.value).add(activityDuration, "minutes"),
    };
    Promise.resolve(
      dispatch(SERVICE_PROFESSIONAL_BY_RESOURCE_TYPE_CATEGORY(payload))
    )
      .then((res) => {
        setSpLoading(false);
        if (res?.payload?.error) {
          return snack.setSnack({
            open: true,
            message: "Something went wrong, while getting professionals",
            severity: snackProps.severity.error,
          });
        }
        // if (params?.isEdit) {
        //   scrollToSelectedSP(selectedProfessional);
        // }
      })
      .catch((err) => {
        setSpLoading(false);
        return snack.setSnack({
          open: true,
          message: "Something went wrong, while getting professionals",
          severity: snackProps.severity.error,
        });
      });
  };

  const handleServiceProfessionalChange = (value) => {
    setSelectedProfessional(value);
  };

  // Confirm modal handler
  const onConfirmHandler = () => {
    if (!selectedTime?.value) {
      return snack.setSnack({
        open: true,
        message: `Please select the time slot to confirm booking`,
        // message: `Please select the time slot to confirm ${
        //   params?.isEdit
        //     ? params?.isReschedule
        //       ? 'reschedule'
        //       : 'changes'
        //     : 'booking'
        // }`,
        severity: snackProps.severity.error,
      });
    }
    if (!selectedProfessional) {
      return snack.setSnack({
        open: true,
        message: `Please select the service professional to confirm booking`,
        // message: `Please select the time slot to confirm ${
        //   params?.isEdit
        //     ? params?.isReschedule
        //       ? 'reschedule'
        //       : 'changes'
        //     : 'booking'
        // }`,
        severity: snackProps.severity.error,
      });
    }

    props.dialog.setDialog({
      open: true,
      title: props.isReschedule ? "Confirm Rescheduling?" : "Confirm Booking?",
      content: props.isReschedule
        ? "Are you sure you want to reschedule your appointment?"
        : "Are you sure you want to Confirm the booking?",
      positiveButtonProps: {
        text: "Confirm",
      },
      onAccept: () => confirmBooking(),
    });
  };

  // On Confirm booking
  const confirmBooking = () => {
    setOnBooking(true);
    console.clear();
    console.log(data);
    const _piload = {
      occurence: "NO_REPEAT",
      occurence_type: "NO_REPEAT",
      repeat_on: [],
      cliendId: loginData?.user_id,
      ...data,
      serviceprofessionalId: selectedProfessional,
      from_timestamp: selectedTime?.value?.seconds(0).milliseconds(0),
      to_timestamp: moment(selectedTime?.value)
        .add(activityDuration, "minutes")
        .seconds(0)
        .milliseconds(0),
      // subscriptionType:data?.subscriptionType?.label,
      // address : data.address ? data?.address : "",
      // description: data.description ? data?.description : "",
      // latitude: data.latitude ? data?.latitude : "undefined",
      // longitude: data.longitude ? data?.longitude : "undefined",
      // Change this payload for rescheduling
      isReschedule: isReschedule ? true : false,
      // isReschedule: false,
    };
    Promise.resolve(
      dispatch(
        isEdit ? EDIT_ACTIVITY(_piload) : CREATE_ACTIVITY(_piload)
        // CREATE_ACTIVITY(_piload)
      )
    )
      .then((res) => {
        setOnBooking(false);
        if (res?.payload?.error) {
          return snack.setSnack({
            open: true,
            message: res?.payload?.message
              ? res?.payload?.message
              : "There was an error. Please try again",
            severity: snackProps.severity.error,
          });
        }

        // 1.First redirect, then close drawer,
        // else component will be removed from DOM.
        props.navigateToTracking();

        // const data = res?.payload?.data;
        props.drawer.setDrawer({
          open: false,
        });

        props.isReschedule || props.isEdit
          ? reload()
          : bookingSuccessModal(res?.payload?.data);

        (props.isReschedule || props.isEdit) ?? reload();
      })
      .catch((err) => {
        setOnBooking(false);
        return snack.setSnack({
          open: true,
          message: "Something went wrong, Please try again later",
          severity: snackProps.severity.error,
        });
      });
  };

  const bookingSuccessModal = (bookingData) => {
    const successData = {
      status: true,
      name: `${getFormattedName(
        profileData?.data?.salutation?.label,
        profileData?.data?.clientName
      )}`,
      text: "Thank you for Booking",
      // text: params?.isEdit
      //   ? params?.isReschedule
      //     ? "Rescheduled"
      //     : "Your appointment changes is updated successfully"
      //   : "Thank you for Booking",
      subText: `Your Service Is scheduled on ${"\n"} ${moment(
        bookingData?.scheduled_at
      ).format("Do MMM, YYYY. hh:mm A")}`,
      // params?.isEdit
      //   ? params?.isReschedule
      //     ? `Your Service Is rescheduled at ${"\n"} ${moment(
      //         data?.scheduled_at
      //       ).format("Do MMM, YYYY. hh:mm A")}`
      //     : ""
      //   : `Your Service Is scheduled on ${"\n"} ${moment(
      //       data?.scheduled_at
      //     ).format("Do MMM, YYYY. hh:mm A")}`,
      type: "booking",
      data: [{ ...bookingData?.items }],
      bookingData: `${moment(bookingData?.created_at).format("Do MMM, YYYY")}`,
      serviceName: bookingData?.serviceName,
      description:
        "We hope you enjoy the subscription experience. Thank you for supporting tanya.care, Your contribution is big deal for Us.",
      closeToNavigation: "Tracker",
    };

    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose) => (
        <BookingSuccessModal data={successData} handleClose={handleClose} />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
    });
  };

  return (
    <>
      <DrawerSkeleton
        buttonText={
          onBooking
            ? props.isEdit && !props.isReschedule
              ? "Updating ..."
              : props.isReschedule
              ? "Rescheduling ..."
              : "Confirming ...."
            : props.isEdit && !props.isReschedule
            ? "Update Appointment"
            : props.isReschedule
            ? "Reschedule Appointment"
            : "Confirm Booking"
        }
        handleClose={props.handleClose}
        handleConfirm={onConfirmHandler}
        title={"CHOOSE SLOT"}
        parentID="FromChooseSlot"
      >
        {/* Select Date */}
        <Box sx={{ px: 2, pt: 2, pb: 1.5 }}>
          <DateSlotField
            label="Select Date"
            duration={`Duration: ${durationText(activityDuration)}`}
            options={[...dateData, ...customDate]}
            data={selectedDate}
            onChange={handleDateChange}
            customDateHandler={handleCustomDateChange}
          />
        </Box>

        {/* Select Time */}
        {slotLoader ? (
          <Box sx={{ px: 2, pt: 2, pb: 1.5 }}>
            <Typography>Select Time</Typography>
            <Stack flexDirection="row" columnGap={2} rowGap={1} flexWrap="wrap">
              {loadingArray(6).map((item) => (
                <Skeleton width="25%" height="38px" />
              ))}
            </Stack>
          </Box>
        ) : !!selectedDate?.value && timeSlotsData?.length > 0 ? (
          <Box sx={{ px: 2, pt: 2, pb: 1.5 }}>
            <StyledToggleButtonGroup
              label="Select Time"
              options={timeSlotsData?.map((option) => {
                const isToday = moment().isSame(option?.value, "day");
                const isExpired = isToday
                  ? moment() > moment(option?.value)
                    ? true
                    : false
                  : false;
                return { ...option, disabled: isExpired };
              })}
              data={selectedTime}
              onChange={handleTimeSlotChange}
            />
          </Box>
        ) : (
          <Typography
            vairant="body2"
            color="textSecondary"
            style={{ padding: 16 }}
          >
            Look's like service provider not available at this date.
          </Typography>
        )}
        {!!selectedTime.value && !slotLoader && (
          <ChooseServiceProfessional
            name="service-professional"
            value={selectedProfessional}
            onChange={handleServiceProfessionalChange}
            options={serviceProfessionals?.data ?? []}
            loading={spLoading}
            isReschedule={isReschedule}
          />
        )}
      </DrawerSkeleton>
    </>
  );
}

export default withSnackBar(withDialog(withDrawer(ChooseSlot)));
