import React from "react";
import { CustomDialog } from "./components";
import { DialogContext } from "./contexts";
const defaultState = {
    open: false,
    title: "",
    content: "",
    renderContent: null,
    positiveButtonProps: {},
    negativeButtonProps: {},
    onAccept: () => {},
    onCloseCallback: () => {},
    sx: {
        Dialog: {},
        DialogContent: {}
    },
};
class AppDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...defaultState,
        };
    }
    // Callback for close
    handleClose = () => {
        this.setState({
            ...defaultState,
        });
        this.state.onCloseCallback && this.state.onCloseCallback()
    };
    handleCreate = (dialogProps) => {
        
        this.setState({  ...defaultState,...dialogProps });
    };
    render() {
        return (
            <DialogContext.Provider
                value={{
                    ...this.state,
                    onClose: this.handleClose,
                    setDialog: this.handleCreate,
                }}
            >
                {this.state.open && (
                    <CustomDialog
                        handleClose={this.handleClose}
                        handleAccept={() => {
                            // Clear Context
                            this.setState({
                                ...defaultState,
                            });
                            // Confirm Handler
                            this.state.onAccept()
                        }}
                        open={this.state.open}
                        title={this.state.title}
                        content={this.state.content}
                        renderContent={this.state.renderContent}
                        positiveButtonProps={this.state.positiveButtonProps}
                        negativeButtonProps={this.state.negativeButtonProps}
                        sx={this.state.sx}
                    />
                )}
                {this.props.children}
            </DialogContext.Provider>
        );
    }
}
export default AppDialog;