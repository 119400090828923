import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

function SmartPhoneIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 39"
        {...props}
      >
        <g fill="#252b43">
          <path d="M24.207 35.066V3.801c0-2.098-1.715-3.797-3.828-3.801H3.829C1.714.004.003 1.703 0 3.8v31.266c.004 2.098 1.715 3.801 3.828 3.801h16.594a3.818 3.818 0 002.726-1.144 3.638 3.638 0 001.059-2.657zm-2.887.891a1.23 1.23 0 01-.898.375H3.828a1.275 1.275 0 01-1.277-1.266V3.801c.004-.7.574-1.266 1.277-1.266h16.55c.704 0 1.278.567 1.278 1.266v31.3c.012.317-.113.63-.34.856zm0 0" />
          <path d="M1.277 4.941h21.696v2.536H1.277zm0 0M1.277 29.406h21.696v2.535H1.277zm0 0M13.188 32.828h-2.126a1.27 1.27 0 00-1.277 1.266c0 .699.57 1.27 1.277 1.27h2.126c.707 0 1.277-.571 1.277-1.27 0-.7-.57-1.266-1.277-1.266zm0 0" />
        </g>
      </svg>
    </SvgIcon>
  );
}

SmartPhoneIcon.propTypes = {
  viewBox: PropTypes.string,
};

SmartPhoneIcon.defaultProps = {
  viewBox: "0 0 24 16",
};

export default SmartPhoneIcon;
