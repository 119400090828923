import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { EmptyOutlinedIcon } from "../../assets";
import { Button } from "@mui/material";

function NoDataHandler(props) {
    const { message, fullheight, fullWidth, empty, emptyIcon, showButton=false } = props;

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Stack justifyContent="center" alignItems="center" style={{ minHeight: fullheight, minWidth: fullWidth }}>
                {empty && (emptyIcon ? React.cloneElement(emptyIcon,
                    { sx: { width: props.fontSize, height: props.fontSize } }, null) : <EmptyOutlinedIcon sx={{ width: props.fontSize, height: props.fontSize }} />)}
                <Typography variant="body2" align="center" color="textSecondary" sx={{ fontStyle: "italic" }} >
                    {message}
                </Typography>
                { showButton && <Button variant="contained" onClick={() => props?.redirect()}>{props?.buttonLabel}</Button>}
            </Stack>
        </Box>
    );
}

NoDataHandler.propTypes = {
    message: PropTypes.string,
    fullheight: PropTypes.string,
    fontSize: "5rem"
};

NoDataHandler.defaultProps = {
    message: "No data found",
    // fullheight: "calc(100vh - 64px)",
    fullheight: "calc(70vh)",
    fullWidth: "unset",
    fontSize: "5rem",
};

export default NoDataHandler;
