import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomButton, CustomSelect } from "../../components";
import { IconButton } from "@mui/material";
import {
  countries,
  countryFilterOption,
  errorMessages,
  isObjectEmpty,
  snackProps,
} from "../../utils";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RightArrowStripesIcon } from "../../assets";
import CustomTextfieldWithSelect from "../textfieldWithSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { withSnackBar } from "../../HOC's";
import { isValidPhoneNumber } from "libphonenumber-js";

function AddContactDrawer(props) {
  const {
    isEdit,
    editData,
    options = [],
    onSave = () => {},
    handleClose = () => {},
    isPrimaryLogicNeeded,
    parentID=""
  } = props;

  // Component States
  const [mobile_type, setMobileType] = useState("");
  const [mobilenumberISDcode, setIsdCode] = useState({
    code: "MY",
    label: "Malaysia",
    phone: "60",
  });
  const [mobilenumber, setMobileNumber] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [errorText, setErrorText] = useState({});

  const filterCountryObject = (isdCode) => {
    return countries.filter((country) => country.phone === isdCode)?.[0] ?? {};
  };

  useEffect(() => {
    if (!isObjectEmpty(editData)) {
      setMobileType(editData?.mobile_type);
      setIsdCode(
        filterCountryObject(
          editData?.mobilenumberISDcode?.toString()?.replace("+", "")
        )
      );
      setMobileNumber(editData?.mobilenumber);

      if (isPrimaryLogicNeeded) {
        setIsPrimary(editData?.isPrimary);
      }
    }
  }, [editData]);

  const validate = () => {
    let localError = false;
    let localErrorText = {};

    const isPhoneNumberValid = isValidPhoneNumber(
      mobilenumber,
      mobilenumberISDcode?.code
    );

    // If the input fields are not empty, then return error false
    if (
      !!mobile_type &&
      !!mobilenumberISDcode &&
      !!mobilenumber &&
      isPhoneNumberValid
    )
      return false;

    if (!mobile_type) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        mobile_type: errorMessages.required,
      };
    }

    if (!mobilenumber) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        mobilenumber: errorMessages.required,
      };
    } else if (!isPhoneNumberValid) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        mobilenumber: errorMessages.validMobileNumber,
      };
    }

    setErrorText(localErrorText);

    return localError;
  };

  const handleSave = () => {
    const validation = validate();

    // Validate fields
    if (validation) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: errorMessages.fieldsRequired,
      });
    } else {
      let clientContact = isEdit ? { ...editData } : {};

      let primaryData = {};

      if (isPrimaryLogicNeeded) {
        primaryData = {
          isPrimary: isPrimary,
        };
      }

      clientContact = {
        ...clientContact,
        mobileNo:mobilenumber,
        mobileNoIsdCode:`+${mobilenumberISDcode?.phone}`,
        label: mobile_type?.label,
        value: mobile_type?.value,
        mobile_type: mobile_type,
        mobilenumberISDcode: `+${mobilenumberISDcode?.phone}`,
        mobilenumber: mobilenumber,
        ...primaryData,
      };
      onSave(clientContact);
    }
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          pt: 2,
          pb: 1.5,
          px: 1,
          alignItems: "center",
          border: 1,
          borderColor: "grey.border",
          position: "absolute",
          right: 0,
          left: 0,
          top: 0,
        }}
      >
        <IconButton onClick={handleClose} aria-label="close-drawer" id={`closeBtn${parentID}`}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ flex: 1, textAlign: "center", ml: "-44px" }}>
          {isEdit ? `Edit Contact` : `Add Contact`}
        </Typography>
      </Stack>
      <Box
        sx={{
          mb: 6.5,
          mt: 8.25,
          py: 2.5,
          px: 2.5,
          maxHeight: "calc(100vh - 52px + 66px)",
          overflow: "scroll",
        }}
      >
        {/* Contact Fields */}
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <CustomSelect
              label={`Contact Type`}
              value={mobile_type}
              onChange={(event, newValue) => {
                setMobileType(newValue);
              }}
              options={options}
              size="small"
              InputProps={{
                required: true,
                error: !!errorText["mobile_type"],
                helperText: errorText["mobile_type"],
              }}
              id={`contactType${parentID}`}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfieldWithSelect
              fullWidth={true}
              selectValue={mobilenumberISDcode ? mobilenumberISDcode : null}
              selectOptions={countries}
              onSelectChange={(e, value) => {
                setIsdCode(value);
              }}
              size="small"
              sx={{ gap: 1 }}
              placeholder={{
                select: "ISD Code",
                text: "Mobile Number",
              }}
              filterOptions={countryFilterOption}
              selectProps={{
                id: `countrySelect${parentID}`,
                name: "country",
              }}
              textFieldProps={{
                id: `registerContact${parentID}`,
                name: "contactNumber",
                required: true,
                helperTextNoWrap: true,
              }}
              useCustomLabel
              value={mobilenumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              error={!!errorText["mobilenumber"]}
              helperText={errorText["mobilenumber"]}
            />
          </Grid>

          {isPrimaryLogicNeeded && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    checked={isPrimary}
                    onChange={(e) => setIsPrimary(e.target.checked)}
                    id={`checkbox-${parentID}`}
                  />
                }
                label="Set as primary contact"
                sx={{ mb: 2, color: "text.secondary" }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        <CustomButton
          fullWidth
          sx={{ borderRadius: 0, py: 2 }}
          endIcon={<RightArrowStripesIcon />}
          onClick={handleSave}
          id={`${isEdit ? "updateContact" : "saveContact"}${parentID}`}
        >
          {isEdit ? "Update Contact" : "Save Contact"}
        </CustomButton>
      </Box>
    </>
  );
}

AddContactDrawer.propTypes = {
  editData: PropTypes.any,
  handleClose: PropTypes.func,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
  options: PropTypes.array,
};

export default withSnackBar(AddContactDrawer);
