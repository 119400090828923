import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { StyledPaper } from "./styledPaper";
import {
  avatarColors,
  getAgeString,
  getFlexItemWidth,
  getFormattedAddress,
  getGenderString,
  idToDigitNumeric,
} from "../../../../utils";
import NameIcon from "../../../../assets/icons/clientIcons/name";
import EmailIcon from "../../../../assets/icons/clientIcons/email";
import MobileIcon from "../../../../assets/icons/clientIcons/mobile";
import { styled } from "@mui/material";
import OccupationIcon from "../../../../assets/icons/clientIcons/occupation";
import {
  OccupationDuoToneIcon,
  LocationDuoToneIcon,
  IdcardDuoToneIcon,
  LanguageDuoToneIcon,
  EmailDuoToneIcon,
  PhoneDuoToneIcon,
} from "../../../../assets/icons/clientIcons";
import { CustomAvatar } from "../../../../components";
import Skeleton from "@mui/material/Skeleton";
import { withDrawer } from "../../../../HOC's";
import ViewAllEmailType from "../../../registration/components/viewAllEmailType";
import ViewAllIdType from "../../../registration/components/viewAllIdType";
import ViewAllContact from "../../../registration/components/viewAllContact";
import ViewAllAddress from "../../../registration/components/viewAllAddress";
import { StyledChip } from "../../../registration/components/styledChip";

function PersonalDetailAttribute({ label, value, icon, loading }) {
  return (
    <Stack
      sx={{
        width: getFlexItemWidth(3, 16),
        // width: "calc(1/5*100% - (1 - 1/5)*16px)",
        // minWidth: "fit-content",
        maxWidth: getFlexItemWidth(3, 16),
        // maxWidth: "calc((1/5*100% - (1 - 1/5)*16px) * 2)",
      }}
      flexDirection="row"
      gap={2}
    >
      <Avatar
        sx={{
          bgcolor: "white",
          border: "1px solid #E7E9EF",
          height: 48,
          width: 48,
        }}
      >
        {icon ? icon : <OccupationDuoToneIcon />}
      </Avatar>
      <Stack flex={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          fontWeight="400"
          sx={{ color: "rgba(146, 151, 165, 1)", mb: 1 }}
          // gutterBottom
        >
          {label ? label : "Untitled"}
        </Typography>
        {loading ? (
          <Skeleton variant="text" width={"75%"} />
        ) : (
          <Typography
            fontWeight="500"
            fontSize="1rem"
            sx={{ overflowWrap: "anywhere" }}
          >
            {value ? value : "Not updated"}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

function DocumentAttribute({
  label,
  type,
  value,
  data = [],
  viewAllCallback = () => {},
  icon,
  loading,
}) {
  return (
    <Stack
      sx={{
        width: getFlexItemWidth(3, 16),
        // minWidth: "fit-content",
        maxWidth: getFlexItemWidth(3, 16),

        // flexBasis: "calc(100% / 3)",

        // width: "calc(1/5*100% - (1 - 1/5)*16px)",
        // maxWidth: "calc((1/5*100% - (1 - 1/5)*16px) * 2)",
      }}
      flexDirection="row"
      gap={2}
    >
      <Avatar
        sx={{
          bgcolor: "white",
          border: "1px solid #E7E9EF",
          height: 48,
          width: 48,
        }}
      >
        {icon ? icon : <LocationDuoToneIcon />}
      </Avatar>

      <Stack flex={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          fontWeight="400"
          sx={{ color: "rgba(146, 151, 165, 1)", mb: 1 }}
          // gutterBottom
        >
          {label ? label : "Untitled"}
        </Typography>

        {loading ? (
          <Skeleton variant="text" width={"75%"} />
        ) : (
          <>
            <Typography
              fontWeight="500"
              fontSize={"1.05rem"}
              sx={{ overflowWrap: "anywhere" }}
            >
              {type ? type : "Not updated"}
            </Typography>
            <Stack flexDirection="row" gap={1.5} alignItems="center">
              {value && (
                <Typography
                  fontWeight="400"
                  sx={{
                    color: "rgba(146, 151, 165, 1)",
                    overflowWrap: "anywhere",
                    fontSize: "14px !important",
                  }}
                >
                  {value}
                </Typography>
              )}
              {data?.length > 1 && (
                <Stack flexDirection={"row"} gap={1} alignItems="center">
                  <CountBox count={data?.length - 1} />
                  <Button
                    color="secondary"
                    size="small"
                    sx={{
                      textTransform: "unset",
                      color: "#F3AB40",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                    onClick={viewAllCallback}
                  >
                    View All
                  </Button>
                </Stack>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}

function CountBox({ count = 1 }) {
  return (
    <Box
      sx={{
        // bgcolor: "#FE9169",
        // bgcolor: "secondary.main",
        bgcolor: "#F3AB40",
        color: "white",
        // p: "5px 8px",
        height: 32,
        width: 32,
        fontSize: "14px",
        lineHeight: "32px",
        borderRadius: "50%",
        textAlign: "center",
      }}
    >
      <span>+{count}</span>
    </Box>
  );
}

// function PersonalDetails() {
//   return (
//     <StyledPaper sx={{ p: 2.5 }}>
//       <Stack gap={4}>
//         {/* Summary */}
//         <Stack flexDirection="row" alignItems="center" gap={2}>
//           <Avatar
//             src="/assets/temp/personAvatar.png"
//             sx={{ height: "80px", width: "80px" }}
//           />
//           <Stack
//             flexDirection="row"
//             flex={1}
//             justifyContent="space-between"
//             alignItems="center"
//           >
//             <Stack>
//               <Typography fontWeight="500" fontSize={"1rem"} gutterBottom>
//                 Mr.Jonsnow
//               </Typography>
//               <Typography variant="body2" fontWeight="400" gutterBottom>
//                 Male, 43 years old
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="textSecondary"
//                 sx={{ fontStyle: "italic", color: "rgba(146, 151, 165, 1)" }}
//                 fontWeight="400"
//                 gutterBottom
//               >
//                 Client ID :{" "}
//                 <Box component="span" sx={{ color: "secondary.main" }}>
//                   CLID-15112021-0643
//                 </Box>
//               </Typography>
//             </Stack>
//             <Button
//               color="primary"
//               variant="contained"
//               sx={{ textTransform: "unset", py: 1.25, px: 3.5 }}
//               disableElevation
//             >
//               Edit Profile
//             </Button>
//           </Stack>
//         </Stack>

//         {/* Details */}
//         <Stack gap={2}>
//           <Typography fontWeight="500" sx={{ fontSize: "1.2rem" }}>
//             Personal Details
//           </Typography>
//           <Stack
//             flexDirection="row"
//             flexWrap={"wrap"}
//             rowGap={3}
//             // columnGap={0.5}
//             columnGap={2}
//           >
//             <PersonalDetailAttribute
//               label="User ID"
//               value="Novafelsinki.tanya.care.in"
//             />
//             <PersonalDetailAttribute
//               label="Primary Email Address"
//               value="Jeevaguru@gmail.com"
//             />
//             <PersonalDetailAttribute
//               label="Primary Mobile Number"
//               value="8907654321"
//             />
//             <PersonalDetailAttribute label="DOB" value="05/11/1978" />
//             <PersonalDetailAttribute
//               label="Occupation"
//               value="Visual Designer"
//             />
//             <PersonalDetailAttribute
//               label="Preferred Languages"
//               value="Tamil, English"
//             />
//             <DocumentAttribute
//               label="ID Details"
//               type="Pan Number"
//               value="WER567890TYH78"
//             />
//             <DocumentAttribute
//               label="Contact Details"
//               type="Home"
//               value="7790865432"
//             />
//             <DocumentAttribute
//               label="Address Details"
//               type="Home"
//               value="No 8D, Jay Apartments, Kuala Lumpur, Malaysia, 645889"
//             />
//           </Stack>
//         </Stack>
//       </Stack>
//     </StyledPaper>
//   );
// }

const StyledDivider = styled(Divider)(() => ({
  borderColor: "#EAEAEA",
}));

const GridBox = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns:
    "minmax(auto, 24px) minmax(auto, 0.75fr) minmax(auto, 1fr)",
  columnGap: 20,
}));

function ProfileSummaryAttribute({ label, value, icon, loading }) {
  // <Stack flexDirection="row" alignItems="center" gap={4}>
  //     <Stack flexDirection="row" alignItems="center" flexWrap="nowrap" gap={2}>
  //       {icon ? icon : <NameIcon />}
  //       <Typography
  //         fontWeight={"400"}
  //         color="textSecondary"
  //         sx={{ color: "#9297A5" }}
  //       >
  //         {label ? label : "Untitled"}
  //       </Typography>
  //     </Stack>
  //     <Typography fontWeight={"400"}>{value ? value : "-"}</Typography>
  //   </Stack>
  return (
    <GridBox>
      <Box>{icon ? icon : <NameIcon />}</Box>
      <Typography
        fontWeight={"400"}
        color="textSecondary"
        sx={{ color: "#9297A5", fontSize: "14px !important" }}
        whiteSpace={"nowrap"}
      >
        {label ? label : "Untitled"}
      </Typography>
      {loading ? (
        <Skeleton variant="text" width={"75%"} />
      ) : (
        <Typography fontWeight={"500"}>
          {value ? value : "Not updated"}
        </Typography>
      )}
    </GridBox>
  );
}

function PersonalDetails({ data, loading, handleEdit, ...props }) {
  // const handleIdTypeViewAll = () => {
  //   props.drawer.setDrawer({
  //     open: true,
  //     component: <>Hi</>,
  //   });
  // };

  const handleEmailTypeViewAll = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllEmailType
          value={data?.otherEmailIds ?? []}
          handleClose={handleClose}
          hideActionButtons
          // handleEditClick={handleAddEditClick}
          // handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  const handleIdTypeViewAll = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllIdType
          value={data?.idTypes ?? []}
          handleClose={handleClose}
          hideActionButtons
          // handleEditClick={handleAddEditClick}
          // handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  const handleContactViewAll = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllContact
          value={data?.otherPhoneNumbers ?? []}
          handleClose={handleClose}
          hidePrimary
          hideActionButtons
          // handleEditClick={handleAddEditClick}
          // handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  const handleAddressViewAll = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllAddress
          value={data?.otherAddress ?? []}
          handleClose={handleClose}
          hideActionButtons
          // handleEditClick={handleAddEditClick}
          // handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  return (
    <StyledPaper>
      {/* Header */}
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 3, py: 1.5 }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="500"
          // style={{ fontSize: "1.1rem" }}
        >
          My Profile
        </Typography>
        <Button
          size="small"
          id={`editProfileButton`}
          sx={{ textTransform: "unset", fontWeight: "500", fontSize: "18px" }}
          onClick={() => handleEdit(1)}
        >
          Edit
        </Button>
      </Stack>
      <StyledDivider />

      {/* Profile Summary Content - left */}
      <Stack
        flexDirection="row"
        flexWrap="wrap"
        divider={<StyledDivider orientation="vertical" flexItem />}
      >
        <Stack flexDirection="row" flex={1} sx={{ p: 2.5 }} gap={2}>
          <CustomAvatar
            // src="/assets/temp/personAvatar.png"
            src={data?.profilePic}
            name={data?.clientName}
            loading={loading}
            height={"80px"}
            width={"80px"}
            id={data?.id}
            // bgcolor={avatarColors[idToDigitNumeric(data?.id)]}
            // sx={{ height: "80px", width: "80px" }}
          />

          {/* Name, age, gender, clientId */}
          {loading ? (
            <Stack sx={{ flex: 1 }}>
              <Skeleton variant="text" width={"30%"} />
              <Skeleton variant="text" width={"35%"} />
              <Skeleton variant="text" width={"45%"} />
            </Stack>
          ) : (
            <Stack>
              <Typography fontWeight="500" fontSize={"18px"} gutterBottom>
                {`${
                  data?.salutation?.label ? `${data?.salutation?.label}. ` : ""
                }${data?.clientName}`}
              </Typography>
              <Typography
                variant="body2"
                fontWeight="400"
                color="textSecondary"
                sx={{ color: "rgba(146, 151, 165, 1)", mb: 1 }}
                gutterBottom
              >
                {`${getGenderString(data?.gender)}, ${getAgeString(
                  data?.dob
                )} old`}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontStyle: "italic", color: "rgba(146, 151, 165, 1)" }}
                fontWeight="400"
                gutterBottom
              >
                Client ID :{" "}
                <Box
                  component="span"
                  sx={{
                    fontStyle: "normal",
                    color: "text.primary",
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                  }}
                >
                  {data?.clientId}
                </Box>
              </Typography>
            </Stack>
          )}
        </Stack>

        {/* Profile Summary Content - right */}
        <Stack flex={1} rowGap={1.5} columnGap={1} sx={{ p: 2.5, pl: 3.5 }}>
          <ProfileSummaryAttribute
            icon={<NameIcon fill="#9297A5" fontSize="small" />}
            label={"User ID"}
            value={data?.userId}
            loading={loading}
          />
          <ProfileSummaryAttribute
            icon={
              <EmailIcon fill="#ffffff" stroke="#9297A5" fontSize="small" />
            }
            label={"Primary Email Address"}
            value={data?.emailid}
            loading={loading}
          />
          <ProfileSummaryAttribute
            icon={
              <MobileIcon fill="#ffffff" stroke="#9297A5" fontSize="small" />
            }
            label={"Primary Mobile Number"}
            value={`${data?.mobilenumberISDcode} ${data?.mobile}`}
            loading={loading}
          />
        </Stack>
      </Stack>
      <StyledDivider />

      {/* Details */}
      <Stack gap={2} sx={{ p: 2.5 }}>
        <Typography fontWeight="500" sx={{ fontSize: "1.2rem" }}>
          Personal Details
        </Typography>
        <Stack
          flexDirection="row"
          flexWrap={"wrap"}
          rowGap={3}
          // columnGap={0.5}
          columnGap={2}
        >
          <PersonalDetailAttribute
            label="Occupation"
            value={data?.occupation}
            icon={<OccupationDuoToneIcon />}
            loading={loading}
          />
          <PersonalDetailAttribute
            label="Preferred Languages"
            value={
              data?.languages && Array.isArray(JSON.parse(data?.languages))
                ? JSON.parse(data?.languages)
                    ?.map((language) => language?.label)
                    .join(", ")
                : ""
            }
            icon={<LanguageDuoToneIcon />}
            loading={loading}
          />

          {/* If there is Idtypes */}
          {Array.isArray(data?.idTypes) && data?.idTypes?.length > 0 ? (
            <DocumentAttribute
              label="ID Details"
              type={data?.idTypes?.[0]?.label}
              value={data?.idTypes?.[0]?.id_type}
              data={data?.idTypes}
              viewAllCallback={handleIdTypeViewAll}
              icon={<IdcardDuoToneIcon />}
              loading={loading}
            />
          ) : (
            <DocumentAttribute
              label="ID Details"
              icon={<IdcardDuoToneIcon />}
              loading={loading}
            />
          )}

          {/* If there is emailTypes */}
          {Array.isArray(data?.otherEmailIds) &&
          data?.otherEmailIds?.length > 0 ? (
            <DocumentAttribute
              label="Email Details"
              type={data?.otherEmailIds?.[0]?.label}
              value={data?.otherEmailIds?.[0]?.email}
              data={data?.otherEmailIds}
              viewAllCallback={handleEmailTypeViewAll}
              icon={<EmailDuoToneIcon />}
              loading={loading}
            />
          ) : (
            <DocumentAttribute
              label="Email Details"
              icon={<EmailDuoToneIcon />}
              loading={loading}
            />
          )}

          {/* Contact */}
          {Array.isArray(data?.otherPhoneNumbers) &&
          data?.otherPhoneNumbers?.length > 0 ? (
            <DocumentAttribute
              label="Contact Details"
              type={data?.otherPhoneNumbers?.[0]?.label}
              value={
                data?.otherPhoneNumbers?.[0]?.mobilenumberISDcode &&
                data?.otherPhoneNumbers?.[0]?.mobilenumber
                  ? `${data?.otherPhoneNumbers?.[0]?.mobilenumberISDcode} ${data?.otherPhoneNumbers?.[0]?.mobilenumber}`
                  : `-`
              }
              data={data?.otherPhoneNumbers}
              viewAllCallback={handleContactViewAll}
              icon={<PhoneDuoToneIcon />}
              loading={loading}
            />
          ) : (
            <DocumentAttribute
              label="Contact Details"
              icon={<PhoneDuoToneIcon />}
              loading={loading}
            />
          )}

          {/* Address */}
          {Array.isArray(data?.otherAddress) &&
          data?.otherAddress?.length > 0 ? (
            <DocumentAttribute
              label="Address Details"
              type={data?.otherAddress?.[0]?.address_type?.label}
              value={getFormattedAddress(data?.otherAddress?.[0])}
              data={data?.otherAddress}
              viewAllCallback={handleAddressViewAll}
              icon={<LocationDuoToneIcon />}
              loading={loading}
            />
          ) : (
            <DocumentAttribute
              label="Address Details"
              icon={<LocationDuoToneIcon />}
              loading={loading}
            />
          )}

          {/* <DocumentAttribute label="" type="" value="" icon={<></>} /> */}
        </Stack>
      </Stack>
    </StyledPaper>
  );
}

export default withDrawer(PersonalDetails);
