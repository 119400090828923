import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DrawerSkeleton, NoDataHandler } from "../../../../../components";
// import { MemberCard } from "../../../web/components/familyMemberDetails";
// import { FamilyMemberCard } from "..";
import { FamilyMemberCard } from "../../../../registration/components";
import { EmptyIcon } from "../../../../../assets";

function FamilyMemberList({
  data = [],
  handleClose,
  onEditClick,
  handleDelete,
  handleAdd,
  onUpdate = () => { },
  ...props
}) {
  return (
    <DrawerSkeleton
      renderTitle={() => (
        <Typography
          sx={{ flex: 1, textAlign: "center", fontWeight: 500 }}
        >
          Family Member Details
        </Typography>
      )}
      handleClose={handleClose}
      secondaryButtonText={"+ Add"}
      secondaryButtonColor="primary"
      handleClear={handleAdd}
      //   handleConfirm={saveOnUpdate}
      //   buttonText={"Update"}
      showButton={false}
      gutters={false}
    >
      <Stack rowGap={0.5} sx={{ my: 1 }}>
        {data?.length > 0 ? data?.map((member) => (
          <FamilyMemberCard
            member={member}
            isEmergencyContact={member?.isEmergencyContact}
            onEditClick={onEditClick}
            onDeleteClick={handleDelete}
            hideViewMore
            sx={{
              border: "1px solid #F0F2F5",
              boxShadow: "0px 7px 7px 0px rgba(233, 235, 239, 1)",
              borderRadius: 0,
            }}
          />
        )) : (
          <NoDataHandler
            message={"No family members added yet"}
            empty
            emptyIcon={<EmptyIcon />}
            fontSize={"4.5rem"}
            // fullheight={"100px"}
          />
        )}
      </Stack>
    </DrawerSkeleton>
  );
}

export default FamilyMemberList;
