export * from "./commons";
export * from "./countryCode";
export * from "./errorMessages";
export * from "./validations";
export * from "./placeholderTexts";
export * from "./snackMessages";
export * from "./sanityChecks";

export const activityStatus = {
  completed: "Completed",
  inProgress: "In Progress",
  closed: "Closed",
  cancelled: "Cancelled",
  rescheduled: "Rescheduled",
};

// convertHexToRGBA
export const convertHexToRGBA = (hex, opacity) => {
  if (hex) {
    const tempHex = hex.replace("#", "");
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

// avatarColorBgById
const colorJson = {
  0: "#F3A683",
  1: "#778BEB",
  2: "#E77F67",
  3: "#786FA6",
  4: "#F8A5C2",
  5: "#EA8685",
  7: "#D1CCC0",
  8: "#F7D794",
  9: "#06BEE1",
};
export const avatarColorBgById = (id) => {
  let idLast = id?.toString()?.split("")?.pop();
  return colorJson[idLast] ?? "#778BEB";
};

export const getIdForAvatarColor = (id) => {
  return (
    id?.[id?.toString()?.search(/\d/)] *
    id?.toString()?.split("")?.reverse()?.[
      id?.toString().split("").reverse().join("")?.search(/\d/)
    ]
  );
};

export const getCryptoRandom = () => {
  const crypto = window.crypto || window.msCrypto;
  var array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0] // Compliant for security-sensitive use cases
};

export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};
export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => resolve(null);
  });

export async function createFile(url, type, name) {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: type,
  };
  let file = new File([data], `${name}.${type.split("/")[1]}`, metadata);
  let Url = ToBase64(file);
  return Url;
}

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const checkUrlFromELPRedirection = (searchParams) => {
  var jwt = require("jsonwebtoken");

  let products = [];
  if (searchParams) {
    let param = new URLSearchParams(searchParams);
    const enqProducts = param.get("enqProducts")?.trim();
    // invalid token
    let obj = jwt.decode(enqProducts);
    products = obj?.data?.length > 0 ? obj?.data : [];

    // let decodeUri  = decodeURIComponent(enqProducts)
    // var bytes  = CryptoJS.AES.decrypt(decodeUri, process.env.REACT_APP_ELP_SECRETE_KEY);
    // products = bytes.toString(CryptoJS.enc.Utf8)
    // products = isJson(products) ? JSON.parse(products) : null;
  }
  // console.clear()
  // console.log(products,"enqProducts");
  return products;
};

// Function to search for params in url for given key
export const getUrlParam = (url, key) => {
  var searchParams = new URLSearchParams(url);
  return searchParams.get(key);
};

export const genderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

// Function to return a css calc function for flexItem width taking the number of items per row and columngap as input
export const getFlexItemWidth = (itemsPerRow, columnGap) => {
  return `calc(1/${itemsPerRow}*100% - (1 - 1/${itemsPerRow})*${columnGap}px)`;
};

// Function to return if the given data is object or not
export const isObject = (data) => {
  return data && typeof data === "object" && data.constructor === Object;
};


export const removeUsedOptions = (allOptions = [], data = [], property) => {
  if (Array.isArray(allOptions) && Array.isArray(data)) {
    let selectedProperty = property
      ? data.map((item) => item[property])
      : data.map((item) => item);
    return allOptions.filter(function (allOptionObject) {
      return !selectedProperty.find(function (selectedOptionObject) {
        return allOptionObject.value === selectedOptionObject.value;
      });
    });
  }
  return [];
};
