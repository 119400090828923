import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomTextfield } from "../..";

const CustomPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: 10,
  border: "1px solid",
  borderColor: "#d8dce5",
}));

function DescriptionCard(props) {
  const { value, onChange, maxCount,parentID="" } = props;

  return (
    <CustomPaper elevation={0} sx={{ ...props.sx }}>
      <Box sx={{ py: 2, px: { xs: 2.5, md: 3.5 } }}>
        <Typography sx={{ pb: 1.5 }}>Description / Note</Typography>
        <CustomTextfield
          id={`bookingDescription${parentID}`}
          name="Booking Description"
          value={value}
          onChange={onChange}
          placeholder={"Enter Description here…"}
          multiline
          rows={4}
          sx={{ width: "100%" }}
          helperText={`${value?.length}/${maxCount}`}
        />
      </Box>
    </CustomPaper>
  );
}

DescriptionCard.propTypes = {
  maxCount: PropTypes.number,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  value: PropTypes.string,
};

DescriptionCard.defaultProps = {
  maxCount: 200,
  onChange: () => {},
  value: "",
};

export default DescriptionCard;
