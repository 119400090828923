import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomButton from "../button"

function ErrorHandler(props) {
    const { message, onRetry, retryBtnName, fullheight } = props;

    return (
        <Box sx={{width: "100%", height: "100%"}}>
            <Stack justifyContent="center" alignItems="center" style={{ minHeight: fullheight }}>
                <Typography variant="h6" align="center">
                    {message}
                </Typography>
                <CustomButton
                    sx={{mt: 2, maxWidth: 40}}
                    variant={"outlined"}
                    onClick={onRetry}
                    size="small"
                >
                    {retryBtnName}
                </CustomButton>
            </Stack>
        </Box>
    );
}

ErrorHandler.propTypes = {
    message: PropTypes.string,
    onRetry: PropTypes.func.isRequired,
    retryBtnName: PropTypes.string,
    fullheight: PropTypes.string,
};

ErrorHandler.defaultProps = {
    message: "Something went wrong!",
    retryBtnName: "Retry",
    // fullheight: "calc(100vh - 64px)",
    fullheight: "calc(70vh)",
};

export default ErrorHandler;
