import React, { useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { makeStyles } from "@mui/styles";
import {
  fileLessThanTenMb,
  imageFormats,
  videoFormats,
  formatBytes,
} from "../../utils";
// import { UploadToCloudIcon, PreviewPngIcon } from '../../assets'
// import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    // border: `1.5px dashed ${theme.palette.primary.main}`,
    borderRadius: 8,
    // padding: 12,
    // backgroundColor: "rgba(255,190,159, 0.04)", // peach shade
    // backgroundColor: "rgba(0, 148, 144, 0.04)", // Turqousie shade
    // marginTop: 8,
  },
  // uploadButtonFlex: {
  //   color: theme.palette.primary.main,
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   [theme.breakpoints.down("xs")]: {
  //     justifyContent: "flex-start",
  //   },
  // },
  closeBtn: {
    padding: "0 !important",
    "&.MuiSvgIcon-root": {
      fontSize: "1rem !important",
    },
  },

  // New Styles
  uploadRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `1.5px dashed ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: 12,
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listItemIconRoot: {
    // minWidth: 46
  },
  listItemTextRoot: {
    marginLeft: 6,
  },
  imageStyle: {
    [theme.breakpoints.down("md")]: {
      width: 320,
      height: 250,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

const UploaderWithPreview = ({
  idData: { file, fileUrl, id_photo, id_photo_filetype, id_photo_size },
  ...props
}) => {
  // const [filename, setFilename] = React.useState("");
  const [uploadError, setUploadError] = React.useState(false);
  // const [image, setImage] = React.useState(null);
  const [previewImage, setPreviewImage] = React.useState(null);
  const [uploadPreview, setUploadPreview] = React.useState(null);
  const [imageSrcError, setImageSrcError] = React.useState(false);
  const [fileState, setFileState] = React.useState(null);
  const theme = useTheme();
  const classes = useStyles(props);

  useEffect(() => {
    if (file && file.constructor !== Object) {
      setFileState(file);
      try {
        let tempObjURL = URL.createObjectURL(file);
        setPreviewImage(tempObjURL);
      } catch (error) {
        console.log(error);
      }
    } else if (fileUrl) {
      setPreviewImage(fileUrl);
    }
  }, [file, fileUrl]);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    //   If the user press cancel while browsing for a file
    if (event.target.files[0]) {
      const fileUploaded = event.target.files[0];
      if (fileLessThanTenMb(fileUploaded.size)) {
        // setFilename(fileUploaded.name);
        setUploadError(false);
        setFileState(fileUploaded);
        try {
          let tempObjURL = URL.createObjectURL(fileUploaded);
        //   setPreviewImage(tempObjURL);
        setUploadPreview(tempObjURL)
        } catch (error) {
          console.log(error);
        }
        props.handleFile(fileUploaded);
      } else {
        setUploadError(true);
      }
    }
  };

  const handleRemove = () => {
    // setFilename("");
    hiddenFileInput.current.value = "";
    // setImage(null)
    setFileState(null);
    setPreviewImage(null);
    props.handleRemove();
  };

  const handleImagePreviewDialog = (image, item) => {
    // Display the content in a model
    props.dialog.setDialog({
      ...props.dialog,
      open: true,
      title: item?.label,
      body: (
        <div style={{ paddingTop: 16 }}>
          <img
            src={image}
            className={classes.imageStyle}
            alt={`Failed to show document.`}
            onLoad={() => {
              if (item?.file) {
                URL.revokeObjectURL(image);
              }
            }}
          />
        </div>
      ),
      positiveBtn: "Ok",
      negativeBtn: "CANCEL",
      dontShowButtons: true,
      onOk: () => {},
      onCancel: () => {
        props.dialog.setDialog({
          ...props.dialog,
          open: false,
          title: ``,
          body: "",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          dontShowButtons: false,
        });
      },
    });
  };

  // let filename = props?.value?.constructor === File ? props?.value?.name : file?.constructor === File && image ? file?.name : props?.value
  //
  const getFormattedFormats = (formatArray = []) => {
    return formatArray
      .map((format, index) => {
        return `.${format}`;
      })
      .join();
  };

  let acceptedFormats = `${getFormattedFormats(
    videoFormats
  )},${getFormattedFormats(imageFormats)}`;

  return (
    <>
      <Grid container className={classes.container}>
        <input
          type="file"
          // accept="video/*,image/*"
          accept={
            props?.customExtensions ? props?.customExtensions : acceptedFormats
          }
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          id={`upload-input-${props?.parentID}`}
        />
        {!previewImage && (
          <Grid item xs={12} container justify={"center"}>
            <div className={classes.uploadRoot}>
              {/* <UploadToCloudIcon /> */}
              <Typography
                color={"textSecondary"}
                style={{ padding: "6px 0px" }}
              >
                Upload Your File
              </Typography>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleClick}
                disabled={previewImage ? true : false}
                size={"small"}
                className={classes.uploadButton}
                id={`${previewImage ? 'readyToUploadBtn' : 'browseFilesBtn'}${props?.parentID}`}
              >
                {previewImage ? "Ready to upload" : "Browse Files"}
              </Button>
            </div>
          </Grid>
        )}
        {/* <Grid container justify="center" item xs={12}>
                    <Typography
                        align="center"
                        variant="caption"
                    // style={{ color: theme.palette.primary.main }}
                    >
                        (Max upload 1 file, size is 10MB)
                    </Typography>
                </Grid> */}
        {uploadError && (
          <Grid container justify="center" item xs={12}>
            <Typography style={{ color: theme.palette.ternary.main }}>
              File exceeds limit
            </Typography>
          </Grid>
        )}

        {(fileState || (fileUrl && id_photo_size && id_photo_filetype)) &&
          uploadPreview && (
            <>
              {/* <img src={previewImage} /> */}
              {/* <Avatar src={previewImage} /> */}
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  {/* <Typography color={"textSecondary"} variant={"body2"} >
                                    UPLOADED FILE
                                </Typography> */}
                  <InputLabel shrink>UPLOADED FILE</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <List dense={true}>
                    <ListItem divider>
                      <ListItemIcon
                        style={{
                          justifyContent: imageSrcError
                            ? "center"
                            : "flex-start",
                        }}
                        classes={{ root: classes.listItemIconRoot }}
                      >
                        {/* <PreviewPngIcon /> */}
                        {/* {imageSrcError && <PreviewPngIcon />} */}
                        {uploadPreview && !imageSrcError && (
                          <img
                            src={uploadPreview}
                            alt={"Failed to show preview"}
                            // onLoad={() => {
                            //     if (file) {
                            //         URL.revokeObjectURL(previewImage)
                            //     }
                            // }}
                            onError={() => {
                              setImageSrcError(true);
                            }}
                            style={{
                              // // margin: "auto",
                              // // maxWidth: "100%",
                              // height: "42px",
                              // borderRadius: 4,
                              // visibility: Boolean(id_photo) ? "unset" : "hidden",
                              height: "34px",
                              width: "34px",
                              borderRadius: 4,
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleImagePreviewDialog(uploadPreview)
                            }
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          fileState
                            ? fileState?.name
                            : `${id_photo}${
                                id_photo_filetype ? "." + id_photo_filetype : ""
                              }`
                        }
                        secondary={
                          fileState
                            ? fileState?.size
                              ? `${formatBytes(fileState?.size)}`
                              : null
                            : id_photo_size
                            ? `${formatBytes(id_photo_size)}`
                            : null
                        }
                        classes={{ root: classes.listItemTextRoot }}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={handleRemove}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
          )}

        {(!uploadPreview && previewImage) && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                {/* <Typography color={"textSecondary"} variant={"body2"} >
                                    UPLOADED FILE
                                </Typography> */}
                <Stack flexDirection="row" justifyContent="space-between">
                  <InputLabel shrink>UPLOADED FILE</InputLabel>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleRemove}
                  >
                    <DeleteOutlinedIcon fontSize="small"/>
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {/* <Stack flexDirection="row" justifyContent="space-between"> */}
                  <img
                    src={previewImage}
                    width={354}
                    alt={"Unable to preview. Something went wrong"}
                  />
                  {/* <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={handleRemove}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Stack> */}
              </Grid>
            </Grid>
            {/* <InputLabel shrink>UPLOADED FILE</InputLabel> */}
          </>
        )}
      </Grid>
    </>
  );
};
export default UploaderWithPreview;
