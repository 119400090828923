import * as React from "react"

export function ChatIconIssueDetail(props) {
  return (
    <svg width={20} height={19} viewBox="0 0 20 19" {...props}>
      <defs>
        <style>{".prefix__cls-1{fill:#9297a5}"}</style>
      </defs>
      <g id="prefix__Chat" transform="translate(-2 -3)">
        <path
          id="prefix__Path_656"
          d="M14 3H4a2.006 2.006 0 00-2 2v12l3-3h9a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2zm0 9H4V5h10z"
          className="prefix__cls-1"
          data-name="Path 656"
        />
        <path
          id="prefix__Path_657"
          d="M20 8h-5v2h5v7H10v-4H8v4a2.006 2.006 0 002 2h9l3 3V10a2.006 2.006 0 00-2-2z"
          className="prefix__cls-1"
          data-name="Path 657"
        />
      </g>
    </svg>
  )
}
