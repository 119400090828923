import * as React from "react";

import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import {
  Box,
  Button,
  Divider,
  RadioGroup,
  styled,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { actions } from "tanyacare-middleware";
import { RadioSelectCard } from "../composedComponents/subscriptions/chooseServiceProfessional";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const { GET_CLIENT_PROFILE } = actions;

const FloatingBtn = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  right: 20,
  bottom: 85,
  background: "#ea6f7f",
  color: "white",
  padding: 4,
  zIndex: "1000",
  display: "none",
  [theme.breakpoints.down("md")]: {
    // padding: "0.75rem",
    padding: "0.95rem 0.65rem",
    margin: "0.5rem",
    "&:hover": {
      background: "#ea6f7f",
      color: "white",
    },
  },
  [theme.breakpoints.down("sm")]: {
    display: "inline-flex",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
}));

function CustomSOS() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [emergencyContact, setEmergencyContact] = React.useState([]);
  const [mobileValue, setMobileValue] = React.useState("");
  React.useEffect(() => {
    dispatch(GET_CLIENT_PROFILE({}));
  }, [open]);

  const clientDetails = useSelector(
    (state) => state?.clientProfile?.getProfile
  );

  //   console.clear()
  console.log(clientDetails?.data?.familyMembers);
  console.log("_______________");
  React.useEffect(() => {
    if (clientDetails?.data?.familyMembers) {
      let filterData = clientDetails?.data?.familyMembers?.filter(
        (x) => x.isEmergencyContact === true
      );
      if (filterData.length > 0) {
        let member = {
          // value: filterData?.otherPhoneNumbers?.filter(x => x.isPrimary === true)?.[0]?.mobileNo,
          value: `${
            filterData?.[0]?.otherPhoneNumbers?.filter(
              (x) => x.isPrimary === true
            )?.[0]?.mobileNoIsdCode
          }${filterData?.[0]?.otherPhoneNumbers?.[0]?.mobileNo}`,
          category: "Emergency Contact",
          name: filterData?.[0]?.name,
        };
        console.log(clientDetails);
        console.log("=================================");

        let hepdesk_mem = {
          value: "1300132511",
          category: "Help Desk Contact",
          name: "Help Desk",
        };
        let ambulance_mem = {
          value: "999",
          category: "Ambulance Contact",
          name: "Ambulance",
        };
        let contact = [];
        // if (true) {
        //   // contact = [...member, ...add_mem];
        //   console.clear()
        //   console.log(clientDetails?.data)
        //   contact = [...member]
        // }

        if (clientDetails?.data?.hasEmergencyRequest) {
          
          contact.push(member);
        }
        if (clientDetails?.data?.hasHelpdeskRequest) {
          contact.push(hepdesk_mem);
        }
        if (clientDetails?.data?.hasAmbulanceRequest) {
          contact.push(ambulance_mem);
        }
        setEmergencyContact(contact);
      }
      else{
        let hepdesk_mem = {
          value: "1300132511",
          category: "Help Desk Contact",
          name: "Help Desk",
        };
        let ambulance_mem = {
          value: "999",
          category: "Ambulance Contact",
          name: "Ambulance",
        };
        let contact = [];
        if (clientDetails?.data?.hasHelpdeskRequest) {
          contact.push(hepdesk_mem);
        }
        if (clientDetails?.data?.hasAmbulanceRequest) {
          contact.push(ambulance_mem);
        }
        setEmergencyContact(contact);
      }
    }
  }, [clientDetails]);
  return (
    <>
    { open ? 
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
            Call Assist
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <RadioGroup
            // aria-label="gender"
            name={"Hai"}
            value={selectedValue}
            // onChange={(value) => {setSelectedValue(value?.target?.value)}}
          >
            <>
              {emergencyContact?.length > 0 &&
                emergencyContact?.map((x, index) => {
                  return (
                    <RadioSelectCard
                      onClick={(e) => {
                        setSelectedValue(e);
                        setMobileValue(x.value);
                        // document.location.href = `tel:${x.value}`
                      }}
                      id={`emergencyContact${index+1}`}
                      value={x.value}
                      label={x.category}
                      dontShowPic={false}
                      category={`${x.name} - ${x.value}`}
                      //   profile_pic={""}
                    />
                  );
                })}
              {emergencyContact?.length <= 0 && (
                <Typography
                  sx={{ color: "grey", width: "20%%", margin: "auto" }}
                >
                  No Emergency Contact Saved
                </Typography>
              )}
            </>
          </RadioGroup>

          {emergencyContact?.length > 0 && (
            <Button
              fullWidth
              id={`callEmergencyContactButton`}
              variant="contained"
              disabled={mobileValue !== "" ? false : true}
              onClick={() => {
                document.location.href = `tel:${mobileValue}`;
              }}
            >{`Call ${mobileValue}`}</Button>
          )}
        </Box>
      </SwipeableDrawer>
      : <></> }
      <FloatingBtn
        aria-label="fingerprint"
        id={`callAssistButton`}
        // color={"primary"}
        onClick={
          (e) => {
            setOpen(true);
          }
          // promotionalContentView(e, purchase_data?.promotionalContent)
          // alert("hi")
        }
      >
        {/* <PhoneInTalkIcon fontSize={"small"} /> */}
        <Typography
          variant="caption"
          sx={{ lineHeight: "1", fontWeight: 600, fontSize: "10px" }}
        >
          Call <br /> Assist
        </Typography>
      </FloatingBtn>
    </>
  );
}

export default CustomSOS;
