import { Box, Stack, useTheme, useMediaQuery, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import {
  CustomButton,
  CustomTextfield,
  StyledToggleButtonGroup,
  ButtonWithInputField,
  RichTextEditor
} from "../../components";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { bmiCalculation } from "../../utils";
import { RightArrowStripesIcon } from "../../assets";

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    margin: "0.5rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "1rem 2rem",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "1rem 3rem",
  },
}));

const initialState = {
  height: "",
  weight: "",
  bmi: 0,
  bloodGroup: "",
  medicalHistory:"",
  specialPrecaution:""
};

const StepThree = (props) => {
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { dependencyData } = props;

  const [state, setState] = useState(initialState);

  const updateFieldState = (fieldValue, key) => {
    setState({
      ...state,
      [key]: fieldValue,
    });
  };

  const { height, weight } = state;

  useEffect(() => {
    if (height && weight) {
      updateFieldState(bmiCalculation(height, weight), "bmi");
    }
  }, [height, weight]);

  useEffect(() => {
    console.log(props.data);
    setState({
      ...initialState,
      ...props.data,
    });
  }, [props.data]);

  const handleSubmit = () => {
    // const {error, errorText} = validate();
    // if(Object.keys(error)?.length > 0)
    // setErrorText(errorText);
    props.handleNextStep(state, false);
    // props.handleNextStep(state, false, true)
  };

  const richTextComponentProps =  {
    multiline: true,
    rowsMax: 4,
    type: "textArea",
    isLimitHelperTextNeeded: true,
  };

  const removeItemFromAnArrayCommonFunction = (arrOfValues, key, componentState, bool) =>{
    arrOfValues?.splice(key,1);
    updateFieldState(arrOfValues,componentState)
  }

  return (
    <Box sx={{ bgcolor: "white" }}>
      <StyledBox>
        <Grid container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={2}>
          <Grid item xs={12} md>
            {/* Field 1 */}
            <CustomTextfield
              // required
              fullWidth
              // size="small"
              type="number"
              sx={{ marginY: 1 }}
              label="Height"
              value={state["height"]}
              onChange={(e) => updateFieldState(e?.target?.value, "height")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">CM</InputAdornment>
                ),
              }}
              id="heightTextfieldStepthree"
            />
          </Grid>

          <Grid item xs={12} md>
            {/* Field 2 */}
            <CustomTextfield
              // required
              fullWidth
              // size="small"
              type="number"
              sx={{ marginY: 1 }}
              label="Weight"
              value={state["weight"]}
              onChange={(e) => updateFieldState(e?.target?.value, "weight")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">KG</InputAdornment>
                ),
              }}
              id="weightTextfieldStepthree"
            />
          </Grid>

          <Grid item xs={12} md>
            {/* Field 3 */}
            <CustomTextfield
              // required
              fullWidth
              // size="small"
              sx={{ marginY: 1 }}
              label="BMI"
              disabled={true}
              value={bmiCalculation(state["height"], state["weight"])}
              id="bmiCalculationTextfieldStepthree"
              // onChange={(e) => updateFieldState(e?.target?.value, 'height')}
            />
          </Grid>

          <Grid item xs={12}>
            {/* Field 4 */}
            <StyledToggleButtonGroup
              id={"Submitted by"}
              label={"Blood Group"}
              // size="small"
              sx={{ marginY: 1 }}
              variant="radio"
              options={
                Array.isArray(dependencyData?.blood_group) &&
                dependencyData?.blood_group?.length > 0
                  ? dependencyData?.blood_group?.map((blood) => {
                      let lbl = blood?.label;
                      let shortlbl = blood?.short_label;
                      return {
                        ...blood,
                        label: shortlbl,
                        short_label: lbl,
                      };
                    })
                  : []
              }
              data={{ value: state["bloodGroup"] }}
              onChange={(e, bloodGroup) => {
                //   console.log(value)
                if (bloodGroup !== null) {
                  updateFieldState(bloodGroup, "bloodGroup");
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RichTextEditor
              id={'addMedicalHistoryFromStepThree'}
              value={state["medicalHistory"]}
              label={'Medical History'}
              keys={'medicalHistory'}
              saveOnBlur={(value) => {
                updateFieldState(value, 'medicalHistory')
              }}
              {...richTextComponentProps}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ButtonWithInputField
              label={'Special Precaution'}
              titleInLabel
              arrOfValues={state["specialPrecaution"]}
              buttonValue={'+ ADD'}
              componentState={'specialPrecaution'}
              removeListFunc={removeItemFromAnArrayCommonFunction}

              // error={this.state.error[component.key] ? true : false}
              // errorMessage={errorMessage.requiredMessage}

              id={{
                text_id:"addTermsConditionsFromAddEditClient",
                button_id:"addTermsConditionsButtonFromAddEditClient",
              }}

              handleButtonClick={(value) => {
                if (value) {
                  let data = state["specialPrecaution"] ?? [];
                  data=[...data,value]
                  updateFieldState(data, "specialPrecaution")
                }
              }}
            />
          </Grid>
        </Grid>
      </StyledBox>

      {belowSm ? (
        <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
          <CustomButton
            fullWidth
            sx={{ borderRadius: 0, py: 2 }}
            endIcon={<RightArrowStripesIcon />}
            loading={props.continueLoading}
            onClick={handleSubmit}
            id="proceed-btn-stepthree"
          >
            Proceed
          </CustomButton>
        </Box>
      ) : (
        <Stack
          sx={{
            px: { md: "2rem", lg: "3rem" },
            py: { sm: "1rem" },
            flexDirection: { sm: "row" },
            alignItems: { sm: "flex-end" },
            justifyContent: { sm: "flex-end" },
            gap: 2,
          }}
        >
          {/* <Box sx={{ minWidth: "140px" }}>
            <CustomButton
              sx={{ borderRadius: 2, py: 1.5 }}
              variant="text"
              fullWidth
              onClick={() => {
                props.handlePreviousStep(state);
              }}
            >
              Go Back
            </CustomButton>
          </Box> */}
          <Box sx={{ minWidth: "140px" }}>
            <CustomButton
              sx={{
                borderRadius: 2,
                py: 1.5,
                borderColor: "grey.300",
                textTransform: "capitalize",
              }}
              variant="outlined"
              color="secondary"
              fullWidth
              loading={props.continueLoading}
              onClick={() => props.handleUpdateLater(initialState)}
              id="updateLaterBtnStepthree"
            >
              Update Later
            </CustomButton>
          </Box>
          <Box sx={{ minWidth: "140px" }}>
            <CustomButton
              sx={{ borderRadius: 2, py: 1.5 }}
              variant="contained"
              fullWidth
              loading={props.continueLoading}
              onClick={handleSubmit}
              id="confirmBtnStepthree"
            >
              Confirm
            </CustomButton>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default withDialog(withDrawer(withSnackBar(StepThree)));
