import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { SectionHeader } from ".";
import { CreateIcon, DeleteIcon, EmptyIcon } from "../../../../assets";
import { NoDataHandler } from "../../../../components";

function IdDetails({ data, isView = false, onEdit, onDelete, onViewAll, ...props }) {
  return (
    <Stack>
      <SectionHeader
        id={isView ? `viewAllIDDetailsButtonsMobile` : `addIdDetailsButtonsMobile`}
        title="ID Details"
        buttonLabel={isView ? "View All" : "+Add"}
        buttonHandler={() => (isView ? onViewAll() : onEdit(data?.[0]))}
      />
      {data?.length > 0 ? (
        <Stack
          sx={{ bgcolor: "common.white", p: 2.5 }}
          gap={2.5}
          style={{
            boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
          }}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Stack>
            <Typography variant="body2" color="textSecondary" fontWeight="400">
              {data?.[0]?.label}
            </Typography>
            <Typography fontWeight="400">{data?.[0]?.id_type}</Typography>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <IconButton
              size="small"
              onClick={() => {
                onEdit(data?.[0]);
              }}
            >
              <CreateIcon sx={{fontSize: "16px"}} />
            </IconButton>
            <IconButton size="small" onClick={() => {onDelete(data?.[0])}}>
              <DeleteIcon sx={{fontSize: "16px"}} />
            </IconButton>
          </Stack>
        </Stack>
      ) : (
        <Box sx={{pb: 1, bgcolor: "common.white", boxShadow: "rgb(233 235 239) 0px 7px 7px 0px"}}>
          <NoDataHandler
          message={"You don't have any ID details"}
          empty
          emptyIcon={<EmptyIcon />}
          fontSize={"4.5rem"}
          fullheight={"100px"}
        />
        </Box>
      )}
    </Stack>
  );
}

export default IdDetails;
