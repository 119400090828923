import { SvgIcon } from "@mui/material";
import * as React from "react";

function SuccessIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 19a7 7 0 100-14 7 7 0 000 14z" fill="#31B198" />
      <path
        d="M9.961 12.27l1.418 1.418 3.002-3.003"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default SuccessIcon;
