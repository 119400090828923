import PropTypes from "prop-types";
import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography } from "@mui/material";
import useTheme from "@mui/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import SliderWrapper from "./carouselStyle";
// import { makeStyles } from "@mui/styles";

//styles
import "./slick.css";
import styles from "./slick.module.css";


function AdvertismentSlide({ ad }) {
  const theme = useTheme();
  const below700 = useMediaQuery(theme.breakpoints.down("700"));
  return (
    <Box sx={{ m: { xs: 0, sm: below700 ? 0 : 1 } }}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12}>
              <img
                style={{
                  // display: "flex",
                  // textAlign: "center",
                  // justifyContent: "center",
                  // height: "100%",
                  // width: "100%",
                  margin: "auto",
                }}
                alt="logo"
                src={ad.bg_image.default}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: { xs: "14px", sm: "16px", md: "18px" },
                margin: "10px 32px 0px 32px",
              }}
            >
              {ad.title}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#7e7e7e",
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                marginTop: "10px",
                margin: "5px 32px 32px 32px",
              }}
            >
              {ad.sub_title}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

AdvertismentSlide.propTypes = {
  ad: PropTypes.shape({
    bg_image: PropTypes.shape({
      default: PropTypes.string,
    }),
    promotion_type: PropTypes.string,
    title: PropTypes.string,
  }),
};

const useStyles = makeStyles((theme) => ({
  dots: {
    "& li.slick-active button::before": {
      color: "tomato !important",
    },
    "& li": {
      "& button::before": {
        fontSize: theme.typography.pxToRem(14),
        color: "red",
        opacity: 0.5,
      },
    },
  },
}));

function Carousel({ carouselData = [], onClose, close }) {
  const theme = useTheme();
  const classes = useStyles();
  // const belowSm = useMediaQuery(theme.breakpoints.down('sm'))
  const above700 = useMediaQuery(theme.breakpoints.up("700"));

  console.log(styles)

  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    // dotClass: `slick-dots ${classes.dots}`,
    dotClass: "button__bar",
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // responsive: [
    //   {
    //     breakpoint: 900,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 700,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };

  const settingsWithModules = {
    ...settings,
    dotsClass: "button__bar"
  };

  return (
    <Box>
      {close && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon onClick={onClose} />
        </div>
      )}
      {/* Slider */}
      {
        <div style={{ maxWidth: "100vw", paddingTop: above700 ? 0 : 8 }}>
          {/* <SliderWrapper> */}
          <Slider ref={(slider) => (sliderRef.current = slider)} {...settingsWithModules}>
            {carouselData.map((ad, index) => (
              <AdvertismentSlide key={ad?.id ?? index} ad={ad} />
            ))}
          </Slider>
          {/* </SliderWrapper> */}
        </div>
      }
    </Box>
  );
}

Carousel.propTypes = {
  carouselData: PropTypes.array.isRequired,
};

export default Carousel;
