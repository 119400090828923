import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorPrimary,
  },
  cls2: {
    fill: (props) => props.htmlColorSecondary,
  },
}));

function WeightDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.344 2.049h-4.06A5.508 5.508 0 0 0 9.626.539a5.508 5.508 0 0 0-1.91 1.51h-4.06A3.658 3.658 0 0 0 0 5.709V24h24V5.709a3.666 3.666 0 0 0-3.656-3.66ZM12 1.408a4.111 4.111 0 0 1 4.103 3.89h-3.4V2.932h-1.407v2.367H7.897A4.12 4.12 0 0 1 12 1.408Zm10.593 21.184h-9.89v-4.531h-1.407v4.531h-9.89V5.709a2.255 2.255 0 0 1 2.25-2.252h3.23a5.495 5.495 0 0 0-.403 2.063v1.186h11.03V5.52c0-.707-.136-1.408-.4-2.063h3.231a2.25 2.25 0 0 1 2.25 2.252v16.883Z"
        className={classes.cls1}
      />
      <path
        d="M7.072 8.43a3.245 3.245 0 0 0-3.244 3.247v7.162a2.403 2.403 0 0 0 2.119 2.383 2.398 2.398 0 0 0 2.614-1.824l1.665-6.969a3.25 3.25 0 0 0-1.74-3.676 3.24 3.24 0 0 0-1.414-.325v.002Zm1.786 3.674-1.665 6.969a.994.994 0 0 1-1.959-.231v-7.166a1.84 1.84 0 0 1 2.052-1.824 1.835 1.835 0 0 1 1.571 2.252h.001ZM16.928 8.43a3.24 3.24 0 0 0-3.168 2.549c-.105.479-.1.976.013 1.453l1.665 6.968a2.394 2.394 0 0 0 2.333 1.844 2.401 2.401 0 0 0 2.4-2.402v-7.166a3.252 3.252 0 0 0-3.243-3.246Zm1.836 10.412a.995.995 0 0 1-1.561.815.994.994 0 0 1-.398-.584l-1.665-6.97a1.84 1.84 0 0 1 1.572-2.251 1.835 1.835 0 0 1 2.052 1.824v7.166Z"
        className={classes.cls2}
      />
    </SvgIcon>
  );
}

WeightDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default WeightDuoToneIcon;
