import { SvgIcon } from "@mui/material";
import * as React from "react";

function EmptyOutlinedIcon(props) {
  return (
    <SvgIcon
      // width={24}
      // height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.479 16.337l-6.073 3.335-6.072-3.335V9.668l6.072-3.334 6.073 3.334v6.67z"
        fill="#FFB954"
      />
      <path
        d="M6.334 9.668l6.072 3.335 6.073-3.335-6.073-3.334-6.072 3.334z"
        fill="#F8FAFB"
      />
      <path
        d="M6.334 9.67v6.667l6.072 3.335.004-.002v-6.667L6.337 9.668l-.003.002z"
        fill="#FFC66C"
      />
      <path
        d="M18.479 9.668l2.005 2.006-6.072 3.334-2.006-2.005 6.073-3.335zM6.334 9.668l-2.006 2.006 6.073 3.334 2.005-2.005-6.072-3.335zM18.479 9.668l2.005-2.005-6.072-3.335-2.006 2.006 6.073 3.334zM6.334 9.668L4.328 7.663l6.073-3.335 2.005 2.006-6.072 3.334z"
        fill="#FEDA9B"
      />
      <path
        d="M18.944 9.668l1.773-1.773a.328.328 0 00-.075-.52l-2.131-1.17a.328.328 0 00-.316.575l1.748.96-1.522 1.522-5.473-3.006 1.522-1.521 1.538.845a.328.328 0 10.316-.576L14.57 4.04a.328.328 0 00-.39.055L12.406 5.87l-1.773-1.773a.328.328 0 00-.39-.055L4.17 7.375a.328.328 0 00-.074.52L5.87 9.668l-1.773 1.773a.328.328 0 00.074.52l1.835 1.008v3.368c0 .12.066.23.17.288l6.073 3.334a.328.328 0 00.316 0l6.073-3.334a.328.328 0 00.17-.288V12.97l1.835-1.008a.328.328 0 00.075-.52l-1.773-1.773zm-6.538 2.96l-5.39-2.96 5.39-2.96 5.39 2.96-5.39 2.96zm-2.063-7.893l1.522 1.521-5.473 3.006L4.87 7.74l5.473-3.005zm-3.951 5.34l5.473 3.005-1.522 1.522-5.473-3.006 1.522-1.521zm11.759 6.068l-5.416 2.974V15.76a.328.328 0 10-.657 0v3.357l-5.416-2.974V13.33l3.581 1.966a.328.328 0 00.39-.056l1.773-1.773 1.774 1.773a.328.328 0 00.39.056l3.58-1.966v2.813zm-3.681-1.541l-1.522-1.522 5.473-3.005 1.522 1.521-5.473 3.006z"
        fill="#000"
      />
      <path
        d="M17.293 6.239a.331.331 0 00.328-.329.33.33 0 00-.328-.328.33.33 0 00-.329.328.329.329 0 00.329.329zM12.406 14.341a.33.33 0 00-.328.329c0 .086.035.17.096.232a.33.33 0 00.465 0 .331.331 0 000-.465.33.33 0 00-.233-.096z"
        fill="#000"
      />
    </SvgIcon>
  );
}

export default EmptyOutlinedIcon;
