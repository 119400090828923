import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Typography, Grid, IconButton } from "@mui/material";
import moment from "moment";
import { DrawerContext } from "../../../contexts";
import ActivitySummary from "../tracker/activitySummary";
import useMediaQuery from "@mui/material/useMediaQuery";
import { drawerProps, textClampStyleGenerator } from "../../../utils";
import ActivityLog from "../tracker/activityLog";
import ServiceNote from "../tracker/serviceNote";
import { useDispatch } from "react-redux";
import { actions } from "tanyacare-middleware";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Notification from "../../navBars/topNavBar/notification";

const { UNREAD_IN_APP_NOTIFICATIONS, DELETE_IN_APP_NOTIFICATIONS } = actions;

const style = makeStyles((theme) => ({
  cardDetails: {
    [theme.breakpoints.up("md")]: {
      flexGrow: "1 !important",
      maxWidth: "100% !important",
      flexBasis: "0 !important",
      width: "100% !important",
      display: "flex !important",
      flexWrap: "wrap !important",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
    },
  },
  avatar: (props) => ({
    height: "30px !important",
    width: "30px !important",
    // padding: "16px !important",
    backgroundColor: `${
      props?.status ? "rgba(49, 177, 152, 0.08)" : "#F4F4F4"
    } !important`,
    color: `${
      props?.status ? "rgba(49, 177, 152, 0.08)" : "#F4F4F4"
    } !important`,
    // backgroundColor: "rgba(49, 177, 152, 0.08)",
  }),
  textArea: {
    marginLeft: "15px !important",
    marginTop: "2px !important",
  },
  text1: {
    // display: 'flex !important',
    // fontSize: "16 !important",
    // fontWeight: "600 !important", //
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: "1.85 !important",
    letterSpacing: "normal !important",
    textAlign: "left !important",
    color: "#252b43 !important",
  },
  text: {
    // display: 'flex !important',
    color: "#9297a5 !important",
  },

  activecircle: {
    marginLeft: 10,
    marginTop: 6,
    width: 9,
    height: 9,
    background: theme.palette.success.main,
    borderRadius: "50%",
  },

  active_name: {
    display: "inline !important",
    paddingLeft: "6px !important",
    fontSize: "14px !important",
    color: theme.palette.success.main,
  },
  righttext: {
    color: theme.palette.text.secondary,
    marginRight: "20px !important",
  },
  bottomcards: {
    backgroundColor: "#ffffff !important",
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.04) !important`,
    padding: "5px !important",
    borderRadius: "8px !important",
    marginBottom: theme.spacing(1),
  },
  //   viewButton: {
  //     [theme.breakpoints.down("sm")]: {
  //       display: "none !important",
  //     },
  //   },

  dataType: {
    margin: "auto !important",
    [theme.breakpoints.down("sm")]: {
      margin: "12px 12px 12px auto !important",
    },
  },
}));

const fabId = {
  ADD_ACTIVITY: 1,
  ADD_AVAILABILITY: 2,
};

export const NotificationCard = (props) => {
  const classes = style();
  const drawer = React.useContext(DrawerContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isBetween0And600 = useMediaQuery(theme.breakpoints.down("sm"));
  // const isBetween0And600 = false;
  const onBackBtnClicked = () => {
    drawer.setDrawer({
      ...drawer,
      open: false,
    });
  };

  const onEditBtnClicked = (selectedFabId, data) => {
    console.log(data);
    if (selectedFabId === fabId.ADD_ACTIVITY) {
      drawer.setDrawer({
        ...drawer,
        open: true,
        // component: <AddEditActivity reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
      });
    } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
      drawer.setDrawer({
        ...drawer,
        open: true,
        // component: <AddEditAvailability reload={props.getClientActivities} data={data} isEdit={true} id={data?.availability_id} />
      });
    }
  };

  // const onRescheduleBtnClicked = (props, data ) => {
  const onRescheduleBtnClicked = (selectedFabId, data) => {
    console.log(data);
    if (selectedFabId === fabId.ADD_ACTIVITY) {
      drawer.setDrawer({
        ...drawer,
        open: true,
        //component: <AddEditActivity isReschedule={true} reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
        // component: <AddEditRescheduleActivity isReschedule={true} reload={props.getClientActivities} data={data} isEdit={true} id={data?.activity_id} />
      });
    } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
      drawer.setDrawer({
        ...drawer,
        open: true,
        // component: <AddEditAvailability isReschedule={true} reload={props.getClientActivities} data={data} isEdit={true} id={data?.availability_id} />
      });
    }
  };

  const activityLogBtnClicked = (selectedFabId, data) => {
    const id= data?.activity_id ?? data?.id;
    if (selectedFabId === fabId.ADD_ACTIVITY) {
      drawer.setDrawer({
        ...drawer,
        open: true,
        drawerWidth: isBetween0And600 ? "100%" : 380,
        component: (handleClose) => {
          return (
            <ActivityLog
              handleClose={onViewSummaryClicked}
              onBackBtnClicked={onBackBtnClicked}
              id={id}
              {...props}
            />
          );
        },
      });
    } else if (selectedFabId === fabId.ADD_AVAILABILITY) {
      drawer.setDrawer({
        ...drawer,
        open: true,
        drawerWidth: isBetween0And600 ? "100%" : 380,
        component: (handleClose) => {
          return (
            <ServiceNote
              handleClose={onViewSummaryClicked}
              onBackBtnClicked={onBackBtnClicked}
              id={id}
              {...props}
            />
          );
        },
      });
    }
  };

  const deleteNotification = (id) => {
    dispatch(DELETE_IN_APP_NOTIFICATIONS({ id })).then((err) =>
      console.log(err)
    );
    props.callBack();
  };

  const handleOpen = () => {
    drawer.setDrawer({
      open: true,
      anchor: drawerProps.direction.right,
      component: (handleClose) => <Notification handleClose={handleClose} />,
    });
  };

  const onViewSummaryClicked = () => {
    // alert("Hi")
    dispatch(
      UNREAD_IN_APP_NOTIFICATIONS({ id: props?.data?.id, is_read: true })
    ).then((err) => console.log(err));
    drawer.setDrawer({
      ...drawer,
      drawerWidth: isBetween0And600 ? "100%" : 380,
      open: true,
      closeIconHiden: true,
      component: (handleClose) => {
        return (
          <ActivitySummary
            handleClose={handleOpen}
            onBackBtnClicked={handleOpen}
            onEditBtnClicked={onEditBtnClicked}
            onRescheduleBtnClicked={onRescheduleBtnClicked}
            activityLogBtnClicked={activityLogBtnClicked}
            // reload={props.getClientActivities}
            history={props.history}
            notificationClose={props.notificationClose}
            drawer={drawer}
            showServiceProfessional={true}
            hideClient={true}
            data={{ id: props?.data?.InfoId }}
            selectedType={props.selected}
            reload={props.reload}
          />
        );
      },
    });
  };

  // let onclick = {}
  // if(isBetween0And600){
  let onclick = { onClick: onViewSummaryClicked };
  // }

  // const getStatusColor = (type) => {
  //   switch (type) {
  //     case "Completed":
  //     case "Closed":
  //       return "#03dac5";
  //     case "Cancelled":
  //       return "red";
  //     default:
  //       return "#363a57";
  //   }
  // };
  return (
    // <div className={classes.bottomcards} onClick={() => props.onClickHandler(props.data)} >
    <div className={classes.bottomcards}>
      <Grid container>
        <Grid item container className={classes.cardDetails}>
          <Grid item xs={1}>
            {/* <Avatar className={classes.avatar}> */}
            <AccessTimeIcon
              fontSize={"small"}
              sx={{
                height: "30px !important",
                width: "30px !important",
                borderRadius: "50%",
                // fontWeight: !props.data?.is_read ? "600 !important" : "normal",
                backgroundColor: `${
                  !props.data?.is_read ? "rgba(49, 177, 152, 0.08)" : "#F4F4F4"
                } !important`,
                color: `${
                  !props.data?.is_read ? "#2a2b43" : "#c4c4c4"
                } !important`,
              }}
              // chosenColor={`${!props.data?.is_read ? "#31b198" : "#F4F4F4"}`}
              // chosenFill={`${!props.data?.is_read ? "#31b198" : "#9297A5"}`}
            />
            {/* </Avatar> */}
          </Grid>
          <Grid item xs={9} className={classes.textArea}>
            <div {...onclick} id={`notificationList${props?.data?.InfoId}`}>
              <div>
                <Typography
                  variant="body1"
                  className={classes.text1}
                  sx={{
                    ...textClampStyleGenerator(1),
                    fontWeight: !props.data?.is_read
                      ? "600 !important"
                      : "normal",
                  }}
                >
                  {props.data?.title}
                </Typography>
              </div>
              <div className={classes.text}>
                {/* <Typography variant="body1" className={classes.text}>{props.data?.serviceName}</Typography> */}
                <Typography variant="body2" className={classes.text}>
                  {props.data.serviceProfessional} &nbsp; - &nbsp;{" "}
                  {moment(props.data.created_at).fromNow()}
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={1}
            className={classes.viewButton}
            style={{ margin: "auto" }}
          >
            <IconButton
              onClick={() => {
                deleteNotification(props.data?.id);
              }}
              id={`notificationListDelete${props?.data?.InfoId}`}
            >
              <DeleteOutlineIcon color={"error.main"} fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        {/* <Grid item className={classes.viewButton} style={{ margin: "auto" }}>
          <IconButton>
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </Grid> */}
      </Grid>
    </div>
  );
};
