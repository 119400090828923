import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { CustomTextfield } from "..";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import PropTypes from "prop-types";
import React from "react";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { styled } from "@mui/styles";

const CustomizedPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})(({ fullWidth }) => ({
  // Add if there is any custom styles
  display: "grid",
  gridTemplateColumns: "33% 1fr",
  // columnGap: 12,
  background: "none",
  ...(fullWidth && {
    width: "100%",
  }),
}));

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 250 }} placement="bottom-start" />;
};

function CustomTextfieldWithSelect(props) {
  const { useCustomLabel } = props;
  const filterOptions = createFilterOptions({
    ignoreCase: true,
    stringify: (option) =>
      props.filterOptions ? props.filterOptions(option) : option.label,
  });
  return (
    <CustomizedPaper
      elevation={0}
      sx={{ marginY: 2, ...props.sx }}
      fullWidth={props.fullWidth}
    >
      {/* Autocomplete component */}
      <Autocomplete
        disablePortal
        disableClearable
        id={props.selectProps.id}
        options={props.selectOptions}
        value={props.selectValue}
        onChange={props.onSelectChange}
        // getOptionLabel={(option) => `+${option.phone}`}
        {...(useCustomLabel
          ? { getOptionLabel: (option) => `+${option.phone}` }
          : { getOptionLabel: (option) => (option ? option?.label : "") })}
        {...(useCustomLabel && { filterOptions: filterOptions })}
        PopperComponent={CustomPopper}
        renderInput={(params) => (
          <CustomTextfield
            {...params}
            autoComplete={"off"}
            placeholder={props?.placeholder?.select ?? ""}
            label={props.selectLabel}
            id={props.selectProps.id}
            inputProps={{ ...params?.inputProps, autoComplete: "no" }}
            name={props.selectProps.name}
            size={props.size}
            error={props.selectError}
            helperText={props.selectHelperText}
            {...props.selectProps}
          />
        )}
        renderOption={(componentProps, option) => (
          <>
            {useCustomLabel ? (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...componentProps}
                key={option.code}
              >
                <img
                  alt=""
                  loading="lazy"
                  src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                  width="20"
                />
                {option?.label} ({option?.code}) {option?.phone}
              </Box>
            ) : (
              <Box component="li" {...componentProps} key={option.value}>
                {option?.label}
              </Box>
            )}
          </>
        )}
      />

      {/* Textfield Component */}
      <CustomTextfield
        {...props.textFieldProps}
        error={props.error}
        helperText={props.helperText}
        label={props.label}
        onChange={props.onChange}
        size={props.size}
        value={props.value}
        placeholder={props?.placeholder?.text ?? ""}
      />
    </CustomizedPaper>
  );
}

CustomTextfieldWithSelect.propTypes = {
  /**
   * Selected value from the options provided.
   */
  selectValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,

  /**
   * Array of options.
   */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,

  /**
   * Callback fired when the option is selected.
   */
  onSelectChange: PropTypes.func.isRequired,

  /**
   * Return a string to filter.
   */
  filterOptions: PropTypes.func,

  /**
   * Props applied to the Autocomplete component.
   */
  selectProps: PropTypes.object,

  /**
   * The label content.
   */
  label: PropTypes.string,

  /**
   * The value of the input element, required for a controlled component.
   */
  value: PropTypes.string,

  /**
   * Callback fired when the value is changed.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * If true, the label is displayed in an error state.
   */
  error: PropTypes.bool,

  /**
   * The helper text content.
   */
  helperText: PropTypes.string,

  /**
   * Props applied to the CustomTextfield element.
   */
  textFieldProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }),

  /**
   * If true, the component takes the fullwidth of the container.
   */
  fullWidth: PropTypes.bool,
};

CustomTextfieldWithSelect.defaultProps = {
  error: false,
  helperText: "",
  textFieldProps: {},
  selectProps: {},
  selectOptions: [],
  fullWidth: false,
};

export default CustomTextfieldWithSelect;
