import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { CustomRadioGroup } from "../..";
import { CustomCard } from "./customCard";
import { loadingArray } from "../../../utils"

function ChooseServiceCard(props) {
    const { loading } = props;

    return (
        <CustomCard title={"Choose Service"} sx={{ height: "100%" }}>
            <Box sx={{ py: 2, px: { xs: 2.5, md: 3.5 } }}>
                {loading ? (
                    loadingArray(4).map(loader => (
                        <Skeleton minWidth={"15vw"} />
                    ))
                ) : (
                    <CustomRadioGroup
                        name={"choose-service"}
                        ariaLabel={"service"}
                        value={props.value}
                        handleChange={props.onChange}
                        options={props.options}
                        loading={loading}
                        disabled={props.isEdit}
                        parentID="FromChooseServiceCard"
                    />
                )}
            </Box>
        </CustomCard>
    );
}

export default ChooseServiceCard;
