import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Hidden from "@mui/material/Hidden";
import React from "react";
import { CustomAvatar, CustomButton, CustomChip } from "../..";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Skeleton from "@mui/material/Skeleton";
import { currencyPriceFormatter } from "../../../utils/commons";
import { IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { grey, pink } from "@mui/material/colors";
import { useLocation } from "react-router-dom";

function Recommendation(props) {
  const { item, hideChips, isLoading, isLast, size, handleDetailRedirect } =
    props;
  const location = useLocation();
  return (
    <Box
      sx={{
        p: 2,
        cursor: "pointer",
        ...((!isLast || size < 4) && {
          borderBottom: 1,
          borderColor: "grey.border",
        }),
      }}
      key={item.id}
      id={`recommendationCardList${item?.id}`}
      //   onClick={handleDetailRedirect}
    >
      <Grid container>
        <Grid item container xs={12} md={12}>
          <Grid item onClick={handleDetailRedirect}>
            <CustomAvatar loading={isLoading} />
          </Grid>
          <Grid item xs>
            {isLoading ? (
              <Box sx={{ pl: 1 }}>
                <Skeleton />
                {/* <Skeleton width="75%" /> */}
                <Skeleton width="25%" />
              </Box>
            ) : (
              <>
                <Grid container xs={12}>
                  <Grid item xs={12} sx={{ ml: 1 }}>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      sx={{ placeSelf: { xs: "center", md: "inherit" } }}
                      onClick={handleDetailRedirect}
                    >
                      <Typography sx={{ width: "100%" }}>
                        {item?.name}
                      </Typography>
                      <Hidden mdUp>
                        {/* <Grid item xs={1}> */}
                        <IconButton
                          onClick={() =>
                            props?.handleAddRemoveWishList(
                              item?.id,
                              item?.isWishlist
                            )
                          }
                          id={`recommendationWishListIconButton${props?.id}`}
                          sx={{
                            background: "rgb(255,66,86, 0.1)",
                            border: 0,
                            // border: "1px solid rgb(255,66,86, 0.1) !important",
                            padding: "6px",
                            alignItems: "start",
                            height: "fit-content",
                            "&:hover": {
                              border: 0,
                              backgroundColor: "rgb(255,66,86, 0.1)",
                            },
                          }}
                        >
                          {item?.isWishlist ? (
                            <FavoriteIcon
                              sx={{
                                fontSize: { xs: "16px", md: "normal" },
                                color: pink[500],
                              }}
                            />
                          ) : (
                            <FavoriteBorderOutlinedIcon
                              sx={{
                                color: pink[500],
                                fontSize: { xs: "16px", md: "normal" },
                              }}
                            />
                          )}
                        </IconButton>
                        {/* </Grid> */}
                      </Hidden>
                    </Stack>
                    {/* <Stack direction={"row"} justifyContent="space-between"> */}
                    <Grid container>
                      <Grid item xs={4} >
                          <div onClick={handleDetailRedirect}>
                        {hideChips
                          ? ""
                          : item?.type && (
                              <CustomChip
                                label={item?.type}
                                type={item?.type}
                              />
                            )}
                            </div>

                        {/* <br /> */}
                        <Hidden mdDown>
                          <CustomButton
                            id={`recommendationWishListButton${props?.id}`}
                            size="small"
                            variant="outlined"
                            startIcon={
                              !item?.isWishlist ? (
                                <FavoriteBorderOutlinedIcon
                                  sx={{ color: "#FF4256", fontSize: "10px" }}
                                />
                              ) : location.pathname === "/wishlist" ? (
                                <></>
                              ) : (
                                <FavoriteIcon
                                  sx={{ color: "#FF4256", fontSize: "10px" }}
                                />
                              )
                            }
                            sx={{
                              color: "#FF4256",
                              border: 0,
                              //   width: "15%",
                              mt: 1,
                              // borderColor: pink[500],
                              backgroundColor: "rgb(255,66,86, 0.1)",
                              // padding:"0px !important",
                              textTransform: "capitalize",
                              padding: "1px 8px",
                              "&:hover": {
                                border: 0,
                                backgroundColor: "rgb(255,66,86, 0.1)",
                              },
                            }}
                            onClick={(event) =>
                              props?.handleAddRemoveWishList(
                                item?.id,
                                item?.isWishlist
                              )
                            }
                          >
                            {item?.isWishlist ? "Added" : "Wishlist"}
                          </CustomButton>
                        </Hidden>
                        <Hidden smUp>
                          {!!item?.price &&
                          !!item?.discount &&
                          !!item?.discountPercentage ? (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography variant="body2">
                                {currencyPriceFormatter(
                                  item?.currency,
                                  item?.discount
                                )}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ textDecoration: "line-through" }}
                              >
                                {currencyPriceFormatter(
                                  item?.currency,
                                  item?.price
                                )}
                              </Typography>
                            </Stack>
                          ) : item?.price ? (
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                              {currencyPriceFormatter(
                                item?.currency,
                                item?.price
                              )}
                            </Typography>
                          ) : item?.discount ? (
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                              {currencyPriceFormatter(
                                item?.currency,
                                item?.discount
                              )}
                            </Typography>
                          ) : null}
                        </Hidden>
                      </Grid>
                      <Grid item xs={8}>
                        <Stack
                          height={"100%"}
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="flex-end"
                          sx={{
                            placeSelf: { xs: "center", md: "inherit" },
                            mt: { xs: 1, md: 0 },
                          }}
                          onClick={handleDetailRedirect}
                        >
                          <Hidden smDown>
                            {!!item?.price &&
                            !!item?.discount &&
                            !!item?.discountPercentage ? (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Typography variant="body2">
                                {currencyPriceFormatter(
                                  item?.currency,
                                  item?.discount
                                )}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ textDecoration: "line-through" }}
                              >
                                {currencyPriceFormatter(
                                  item?.currency,
                                  item?.price
                                )}
                              </Typography>
                              </Stack>
                            ) : item?.price ? (
                              <Typography variant="body2">
                                {currencyPriceFormatter(
                                  item?.currency,
                                  item?.price
                                )}
                              </Typography>
                            ) : item?.discount ? (
                              <Typography variant="body2">
                                {currencyPriceFormatter(
                                  item?.currency,
                                  item?.discount
                                )}
                              </Typography>
                            ) : null}
                          </Hidden>

                          <CustomButton
                            id={`recommendationEnquireButton${props?.id}`}
                            size="small"
                            variant="outlined"
                            onClick={(event) =>
                              props.handleEnquiry(event, item)
                            }
                          >
                            Enquire
                          </CustomButton>
                          <CustomButton
                            id={`recommendationBuyNowButton${props?.id}`}
                            endIcon={<ChevronRight />}
                            size="small"
                            onClick={(event) =>
                              props.handleBuyNow(event, item?.id)
                            }
                          >
                            Buy Now
                          </CustomButton>
                        </Stack>
                      </Grid>
                    </Grid>
                    {/* </Stack> */}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={6} onClick={handleDetailRedirect}>
          {isLoading ? (
            ""
          ) : (
            <Stack
              height={"100%"}
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Hidden smDown>
                {!!item?.price &&
                !!item?.discount &&
                !!item?.discountPercentage ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2">
                      {currencyPriceFormatter(item?.currency, item?.price)}
                    </Typography>
                  </Stack>
                ) : item?.price ? (
                  <Typography variant="body2">
                    {currencyPriceFormatter(item?.currency, item?.price)}
                  </Typography>
                ) : item?.discount ? (
                  <Typography variant="body2">
                    {currencyPriceFormatter(item?.currency, item?.discount)}
                  </Typography>
                ) : null}
              </Hidden>

              <CustomButton
                size="small"
                variant="outlined"
                onClick={(event) => props.handleEnquiry(event, item)}
              >
                Enquire
              </CustomButton>
              <CustomButton
                endIcon={<ChevronRight />}
                size="small"
                onClick={(event) => props.handleBuyNow(event, item?.id)}
              >
                Buy Now
              </CustomButton>
            </Stack>
          )}
        </Grid> */}
      </Grid>
    </Box>
  );
}

Recommendation.propTypes = {
  handleBuyNow: PropTypes.func,
  handleEnquiry: PropTypes.func,
  hideChips: PropTypes.bool,
  isLast: PropTypes.bool,
  isLoading: PropTypes.bool,
  item: PropTypes.object,
  size: PropTypes.number,
};

Recommendation.defaultProps = {
  handleBuyNow: () => null,
  handleEnquiry: () => null,
};

function RecommendationsCard(props) {
  const { isLoading, data = [], handleDetailRedirect } = props;
  return (
    <Paper
      sx={{
        boxShadow: "0 15px 14px 0 rgba(17, 18, 69, 0.03);",
        border: 1,
        borderColor: "grey.border",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ py: 1.5, px: 2, borderBottom: 1, borderColor: "grey.border" }}>
        <Typography fontWeight="bold">Recommendations</Typography>
        <Typography variant="body2" color="textSecondary">
          Explore products, packages & More…
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {isLoading ? (
          [0, 1, 2, 3].map((item, index) => (
            <Recommendation
              key={index}
              item={item}
              isLoading={isLoading}
              isLast={index === 3}
              size={4}
            />
          ))
        ) : data?.length > 0 ? (
          data?.map((item, index) => (
            <Recommendation
              key={item?.id}
              id={item?.id}
              item={item}
              isLast={data?.length - 1 === index}
              size={data?.length}
              handleEnquiry={props.handleEnquiry}
              handleBuyNow={props.handleBuyNow}
              handleAddRemoveWishList={props.handleAddRemoveWishList}
              handleDetailRedirect={() =>
                handleDetailRedirect(item?.id, item?.versionId)
              }
            />
            // alert(item?.id)
          ))
        ) : (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <Typography
              color="textSecondary"
              variant="body2"
              align="center"
              sx={{ fontStyle: "italic", my: 2 }}
            >
              No Recommendations to show
            </Typography>
          </Stack>
        )}
      </Box>
    </Paper>
  );
}

RecommendationsCard.propTypes = {
  data: PropTypes.array,
  handleBuyNow: PropTypes.func,
  handleEnquiry: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default RecommendationsCard;
