export const stepperData = [
    {
        label:"Create Your Profile",
    },
    {
        label:"Add Your Family Members Details",
    },
    {
        label: "Add Your Health Info",
    }
]