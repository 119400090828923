import { SvgIcon } from "@mui/material"
import * as React from "react"

function MyOrderIcon(props) {
    return (
        <SvgIcon width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M6.667 6v8H5.333v2c0 1.103.898 2 2 2h5.334v-1.333H7.333A.668.668 0 016.667 16v-.667H14L12.667 14H8V7.333h8.667V10H18V6H6.667zm2.666 2.667V10h1.334V8.667H9.333zm2.667 0V10h3.333V8.667H12zm-2.667 2.666v1.334h1.334v-1.334H9.333zm4.667 0v1.334h.667v1.276l1.39 1.39-1.39 1.391V18H14v1.333h6V18h-.667v-1.276l-1.39-1.39 1.39-1.391v-1.276H20v-1.334h-6zm2 1.334h2v.724l-1 1-1-1v-.724zm1 3.609l1 1V18h-2v-.724l1-1z"
            />
        </SvgIcon>
    )
}

export default MyOrderIcon
