import * as React from 'react';

function CalendarIcon(props) {
    return (
        <svg
            width={21.572}
            height={21.572}
            viewBox="0 0 21.572 21.572"
            {...props}>
            <path id="path_795" fill="#353596" d="M17.132 144.179H0V128h21.572v11.739zm0 0" transform="translate(0 -122.607)" />
            <path id="path_796" fill="rgba(37,43,67,0.1)" d="M0 128h21.572v2.7H0zm0 0" transform="translate(0 -122.607)" />
            <path id="path_797" d="M65.348 2.7H64V.674a.674.674 0 0 1 1.348 0zm0 0" fill="#bfc9d1" transform="translate(-61.303)" />
            <path id="path_798" d="M417.348 2.7H416V.674a.674.674 0 0 1 1.348 0zm0 0" fill="#bfc9d1" transform="translate(-398.473)" />
            <path id="path_799" fill="rgba(255,255,255,0.1)" d="M406.625 406.625v4.44l4.44-4.44zm0 0" transform="translate(-389.493 -389.493)" />
            <path id="path_800" fill="#31b198" d="M144.057 242.483l-3.315-2.652a.674.674 0 0 1 .843-1.053l2.373 1.9 5.164-5.164a.674.674 0 1 1 .953.953zm0 0" transform="translate(-134.569 -225.401)" />
            <path id="path_801" fill="#f27d35" d="M21.572 52.719H0v-3.371A1.348 1.348 0 0 1 1.348 48h18.876a1.348 1.348 0 0 1 1.348 1.348zm0 0" transform="translate(0 -45.978)" />
        </svg>
    );
}

export default CalendarIcon