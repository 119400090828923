import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { CustomButton, NoDataHandler } from "../..";
import { loadingArray, placeholderTexts } from "../../../utils";
import { CustomCard } from "./customCard";
import moment from "moment";
import { styled } from "@mui/material";

const checkIsExpired = (expiryDate) => {
  if (!expiryDate) {
    return false;
  }
  const endDate = moment(expiryDate);
  const isExpired = moment().isAfter(endDate);
  return isExpired;
};

const CountBox = styled(Box)({
  fontWeight: 500,
  marginLeft: 6,
});

const ServiceRow = (props) => {
  const {
    loading,
    data,
    handleBookAService,
    pendingCount,
    isExpired,
    handleRedirect = () => null,
  } = props;

  const border = props.divider
    ? { border: 1, borderColor: "background.grey" }
    : {};

    const getServiceCount = (value) => {
      if (value === null || value <= 0) return 0;
      return value < 10 ? (value == 0 ? value : `0${value}`) : value;
    }
  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        flexWrap: { xs: "wrap" },
        gap: 1,
        py: 1.5,
        px: { xs: 2.5, md: 3.5 },
        ...border,
      }}
      onClick={() => handleRedirect(data?.id, data?.versionId)}
      id={`servicesList${data?.id}`}
    >
      <Stack sx={{ flex: 1 }}>
        {loading ? (
          <Skeleton width="30%" />
        ) : (
          <Typography fontWeight="500">
            {data?.name ? data?.name : placeholderTexts.serviceName}
          </Typography>
        )}
        <Stack
          direction="row"
          gap={1.5}
          sx={{ flex: 1 }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          {loading ? (
            <>
              <Skeleton width="20%" />
              <Skeleton width="20%" />
              <Skeleton width="20%" />
            </>
          ) : (
            <>
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Completed:
                </Typography>
                <CountBox component="span">
                  {getServiceCount(data?.completed_services_count)}
                </CountBox>
              </Stack>
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Booked:
                </Typography>
                <CountBox component="span">
                  {getServiceCount(data?.booked_services_count)}
                </CountBox>
              </Stack>
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Remaining:
                </Typography>
                <CountBox component="span">
                  {getServiceCount(data?.pending_services_count)}
                </CountBox>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>

      <Stack
        justifyContent="flex-end"
        sx={{
          marginLeft: "auto",
          alignItems: { xs: "flex-end", md: "flex-end" },
        }}
      >
        {!loading && pendingCount > 0 && !isExpired ? (
          data?.isBookable ? (
            <CustomButton
              variant="text"
              size="small"
              sx={{ whiteSpace: "nowrap" }}
              onClick={(event) => handleBookAService(event, data?.id)}
              id={`bookService${data?.id}`}
            >
              + Book a Service
            </CustomButton>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Non-bookable
            </Typography>
          )
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
};

function SubscriptionServicesCard(props) {
  const {
    loading,
    data = {},
    handleBookAService,
    handleServiceDetailRedirect,
  } = props;
  return (
    <CustomCard sx={{ pb: 1 }} title="Services">
      {loading ? (
        loadingArray(4).map((item, index) => (
          <ServiceRow loading={loading} divider={index !== 3} />
        ))
      ) : data?.services?.length > 0 ? (
        // Actual Data
        data?.services?.map((service, index) => (
          <ServiceRow
            data={service}
            divider={index + 1 === data?.services?.length}
            handleBookAService={handleBookAService}
            pendingCount={data?.pending_services_count}
            isExpired={checkIsExpired(data?.expired_on) || data?.isCancelled}
            handleRedirect={handleServiceDetailRedirect}
          />
        ))
      ) : (
        // No services found
        <NoDataHandler message={"No services found"} />
      )}
    </CustomCard>
  );
}

export default SubscriptionServicesCard;
