import React, { useContext, useRef } from 'react'
import { SOSContext } from '../contexts'

const withSOS = (WrappedComponent) => (props) => {
    // const sos = useContext(SOSContext)
    return (
        <WrappedComponent/>
    )
}

export default withSOS