import { SvgIcon } from "@mui/material"
import * as React from "react"

function SubscriptionIcon(props) {
    return (
        <SvgIcon width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M5.6 6c-.424 0-.83.161-1.13.447A1.49 1.49 0 004 7.524v9.143c0 .404.17.791.47 1.077.3.285.706.446 1.13.447h12.8c.424-.001.83-.162 1.13-.447.3-.286.47-.673.47-1.077V7.524c0-.404-.17-.792-.47-1.077A1.643 1.643 0 0018.4 6H5.6zm0 1.524h12.8v9.143H5.6V7.524zm4.364 1.142l-1.01 2.235-2.554.235 1.904 1.593-.538 2.414 2.199-1.295 2.256 1.295-.655-2.415 1.96-1.589-2.552-.238-1.01-2.235zM13.6 13.62v1.524h1.6v-1.524h-1.6zm2.4 0v1.524h1.6v-1.524H16z"
            />
        </SvgIcon>
    )
}

export default SubscriptionIcon
