import PropTypes from "prop-types";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { CustomButton } from "../..";
import { withDialog } from "../../../HOC's";
import { CustomCard } from "./customCard";

const TermsConditionDisplay = (props) => {
  const { data } = props;

  const handleViewMore = () => {
    props.dialog.setDialog({
      open: true,
      title: "Terms and Conditions",
      renderContent: (
        <ul>
          {data?.map((x) => {
            return <li>{x}</li>;
          })}
        </ul>
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
    });
  };

  return (
    <CustomCard title={"Terms and Conditions"} sx={{ height: "100%" }}>
      {/* <ul> */}
        <Typography sx={{px:1.5,my:2}}>{data?.[0]}</Typography>
      {/* </ul> */}
      {data?.length > props.maxCount && (
        <Stack flexDirection="row" justifyContent="flex-end" sx={{pr: 2, pb: 1}}>
          <Box>
            <CustomButton
              variant="text"
              color="secondary"
              onClick={handleViewMore}
              size="small"
              sx={{ textTransform: "unset" }}
              id="viewMoreTermsConditions"
            >
              View More
            </CustomButton>
          </Box>
        </Stack>
      )}
    </CustomCard>
  );
};

TermsConditionDisplay.propTypes = {
  data: PropTypes.array,
  maxCount: PropTypes.number,
};

TermsConditionDisplay.defaultProps = {
  data: [],
  maxCount: 1,
};

export default withDialog(TermsConditionDisplay);
