import PropTypes from "prop-types";
import { CreateIcon, DeleteIcon } from "../../../assets";

import Box from "@mui/material/Box";
import { CustomButton } from "../../../components";
import { CustomCard } from "../../../components/composedComponents/subscriptions/customCard";
import { IconButton } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { getFormattedAddress, isObjectEmpty } from "../../../utils";
import { withDialog, withDrawer } from "../../../HOC's";
import { Prompt } from ".";
import ViewAllAddress from "./viewAllAddress";
import AddAddressDrawer from "../../../components/cards/addAddressCard";

const dynamicPx = { xs: 1.5, md: 1.5 };

function MultipleAddress(props) {
  const {
    data: addressData = [],
    options = [],
    handleSave,
    handleDelete,
    helperText,
    parentID=""
  } = props;

  // Handlers
  const handleCloseDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: <></>,
    });
  };

  const handleSaveAddress = (data) => {
    handleCloseDrawer();
    handleSave(data);
  };

  const handleAddEditClick = (editData = null) => {
    debugger;
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <AddAddressDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={options}
          onSave={handleSaveAddress}
          handleClose={handleClose}
          parentID={`FromAddAddress${parentID}`}
        />
      ),
    });
  };

  const handldeDeleteClick = (deletedAddress) => {
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <Prompt
          title={"Delete Address"}
          body={
            <>
              Are you sure, do you want to delete <br /> this address?
            </>
          }
          handleClose={handleClose}
          handleAccept={handleAccept}
        />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: () => handleDelete(deletedAddress),
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handleViewAllClick = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllAddress
          value={addressData}
          handleClose={handleClose}
          handleEditClick={handleAddEditClick}
          handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  return (
    <>
      <CustomCard
        {...props}
        required={props.required}
        titleSx={{ color: "text.primary", fontWeight: 500 }}
        stackSx={{ px: dynamicPx }}
        title={"Address Details"}
        helperText={helperText}
      >
        <Box
          sx={{
            pb: 1,
            bgcolor: (theme) => alpha(theme.palette.secondary.light, 0.1),
          }}
        >
          {!isObjectEmpty(addressData) && !props.isEdit && (
            <Box>
              {Array.isArray(addressData) &&
                addressData?.length > 0 &&
                addressData?.slice(0, 1)?.map((address, index) => (
                  <Stack
                    gap={0}
                    sx={{
                      borderBottom: 1,
                      borderColor: "grey.border",
                      px: dynamicPx,
                      pt: 1,
                      bgcolor: "common.white",
                    }}
                  >
                    <>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                      >
                        <Stack>
                          <Typography color="textSecondary">
                            {address?.address_type?.label}
                          </Typography>
                          <Typography
                            sx={{
                              color: "text.primary",
                            }}
                          >
                            {getFormattedAddress(address?.fieldState)}
                          </Typography>
                        </Stack>
                        <Stack
                          flexDirection="row"
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          // sx={{ mb: 2 }}
                        >
                          <IconButton
                            onClick={() => handleAddEditClick(address)}
                            id={`editAddress${address?.id}`}
                          >
                            <CreateIcon fontSize="small" sx={{fontSize: "16px"}} />
                          </IconButton>
                          <IconButton
                            onClick={() => handldeDeleteClick(address)}
                            id={`deleteAddress${address?.id}`}
                          >
                            <DeleteIcon fontSize="small" sx={{fontSize: "16px"}} />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </>
                  </Stack>
                ))}
            </Box>
          )}

          <Box sx={{ px: dynamicPx, pt: 1 }}>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <CustomButton
                variant="text"
                color="secondary"
                size="small"
                onClick={() => handleAddEditClick()}
                id={`addAddressBtn${parentID}`}
              >
                + Add Address
              </CustomButton>
              {addressData?.length > 1 ? (
                <CustomButton
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleViewAllClick}
                  id={`viewSavedAddressButton${parentID}`}
                >
                  View Saved Address
                </CustomButton>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Box>
      </CustomCard>
    </>
  );
}

MultipleAddress.propTypes = {
  addressArray: PropTypes.array,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func,
  helperText: PropTypes.string,
  isEdit: PropTypes.bool,
  options: PropTypes.array,
  required: PropTypes.bool,
};

export default withDrawer(withDialog(MultipleAddress));
