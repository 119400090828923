import React from "react";
import { TextField } from "../inputs";
import { Button, Divider, styled } from "@mui/material";
import PropTypes from "prop-types";
import { SimpleList } from "../simpleList";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import InputBase from "@mui/material/InputBase";
import { CustomButton } from "..";

const useStyles = makeStyles((theme) => ({
  dividerWidth: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  listingOfferings: {
    marginTop: 20,
  },
  addButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const CustomInput = styled(InputBase)(() => ({
  marginLeft: 1,
  flex: 1,
  ".MuiInputBase-input": {
    "&:focus": {
      border: 0,
    },
  },
}));

export const ButtonWithInputField = (props) => {
  const {
    arrOfValues,
    error,
    errorMessage,
    buttonValue,
    handleButtonClick,
    style,
    title,
    removeListFunc,
    componentState,
    id,
    withBoldLabel,
    withoutUppercase,
    label,
  } = props;
  const [state, setState] = React.useState("");

  //   ADDING DIVIDER
  const addDividerAtTheEnd = (offerList, key, classes) => {
    return offerList.length - 1 === key ? (
      ""
    ) : (
      <Divider className={classes.dividerBackground} />
    );
  };

  //   onEnter

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (document.getElementById(id?.button_id)) {
        document.getElementById(id?.button_id).click();
      }
    }
  };
  //   STYLES

  const classes = useStyles(props);
  return (
    <>
      {label && (
        <InputLabel sx={{ mb: 1, color: "text.primary", fontWeight: "500" }}>
          {label}
        </InputLabel>
      )}
      {/* <TextField
        type={"text"}
        rowsMax={1}
        multiline={false}
        withBoldLabel={withBoldLabel}
        withoutUppercase={withoutUppercase}
        title={title}
        id={id?.text_id}
        placeholder=""
        value={state}
        onKeyUp={handleKeyPress}
        handleOnChange={(e) => {
          setState(e.target.value);
        }}
        error={error}
        errorText={errorMessage}
        style={style}
        {...props}
      /> */}

      <Stack
        flexDirection="row"
        gap={2}
        sx={{
          border: "1px solid #C7C7C7",
          borderRadius: "10px",
          px: 2,
          py: 1,
          mb: 2,
        }}
      >
        <CustomInput
          // sx={{
          //   ml: 1,
          //   flex: 1,
          //   "&$focus": {
          //     border: 0,
          //   },
          // }}
          id={id?.text_id}
          value={state}
          onKeyUp={handleKeyPress}
          onChange={(e) => {
            setState(e.target.value);
          }}
          inputProps={{ "aria-label": "special precautions" }}
        />
        <CustomButton
          variant="contained"
          color="secondary"
          // id={title}
          id={id?.button_id}
          style={{ margin: "10px 0" }}
          className={classes.addButton}
          disableElevation
          onClick={() => {
            handleButtonClick(state);
            setState("");
          }}
          sx={{ color: "common.white", borderRadius: "8px" }}
        >
          {buttonValue}
        </CustomButton>
      </Stack>

      {(arrOfValues?.length > 0 ? arrOfValues : [])?.map((item, key) => {
        return (
          <div>
            <div key={key}>
              <SimpleList
                text={item}
                key={key}
                handleRemove={() => {
                  // removeListFunc(arrOfValues, item, componentState);
                  removeListFunc(arrOfValues, key, componentState, true);
                }}
              />
            </div>
            {addDividerAtTheEnd(arrOfValues, key, classes)}
          </div>
        );
      })}
    </>
  );
};

// Specifies the required props for the component:
ButtonWithInputField.propTypes = {
  value: PropTypes.string.isRequired,
  buttonValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  style: PropTypes.object,
};
