import React from "react";
import { DrawerSkeleton } from "../../../components";
import { Stack, Typography, Divider, IconButton } from "@mui/material";
import { withDrawer } from "../../../HOC's";
import { ViewMemberDetailsDrawer } from ".";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function ViewMore(props) {
  const { parentProps, title, value, component } = props;

  const handleGoBack = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => <ViewMemberDetailsDrawer {...parentProps} />,
    });
  };

  const renderBackIcon = () => {
    return (
      <IconButton onClick={handleGoBack} aria-label="go back">
        <ChevronLeftIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <DrawerSkeleton
      title={title}
      showButton={false}
      // handleClose={handleClose}
      renderBackIcon={renderBackIcon}
      gutters={false}
    >
      {value?.map((val) => (
        <>
          <Stack sx={{ py: 1, px: 4 }}>
            <Typography>{props?.[component]?.primary(val)}</Typography>
            <Typography>{props?.[component]?.secondary(val)}</Typography>
          </Stack>
          <Divider />
        </>
      ))}
    </DrawerSkeleton>
  );
}

export default withDrawer(ViewMore);
