import React from "react";

export const LockIcon = (props) => {
    const width = props?.width ? props?.width : 24;
    const height = props?.height ? props?.height : 24;
    const color = props?.color ? props?.color : '#9297a5';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            {...props}
        >
            <g id="Group_95110" data-name="Group 95110" transform="translate(-60 -1401)">
                <g id="language_icon" data-name="language icon" transform="translate(0 1)">
                    <g id="Rectangle_47881" data-name="Rectangle 47881" transform="translate(60 1400)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                        <rect width="24" height="24" stroke="none" />
                        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                    </g>
                </g>
                <path id="icons8-key" d="M15.362,5a5.4,5.4,0,0,0-5.388,5.388,5.144,5.144,0,0,0,.146,1.047L5.182,16.373a.622.622,0,0,0-.182.44v3.316a.622.622,0,0,0,.622.622H8.937a.622.622,0,0,0,.622-.622V19.092H11.01a.622.622,0,0,0,.622-.622V17.02h1.451A.622.622,0,0,0,13.7,16.4v-.914a5.317,5.317,0,0,0,1.658.292A5.388,5.388,0,0,0,15.362,5Zm0,1.243a4.145,4.145,0,1,1-1.982,7.786.622.622,0,0,0-.92.546v1.2H11.01a.622.622,0,0,0-.622.622v1.451H8.937a.622.622,0,0,0-.622.622v1.036H6.243V17.07L11.212,12.1a.622.622,0,0,0,.159-.606,4.132,4.132,0,0,1,3.99-5.251Zm.829,2.072a1.243,1.243,0,1,0,1.243,1.243A1.243,1.243,0,0,0,16.191,8.316Z" transform="translate(59 1399)" 
                    fill={color}
                />
            </g>
        </svg>
    );
};
