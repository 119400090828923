import {
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import moment from "moment";
import {
  CustomButton,
  CustomSelect,
  CustomTextfield,
  CustomTextfieldWithSelect,
  StyledToggleButtonGroup,
} from "../../components";
import CustomDatePicker from "../../components/datePicker";
import { Grid } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import { UploadProfileImage } from "../../components/profileImageUpload";
import { RightArrowStripesIcon } from "../../assets";
import SkipDialog from "./skipDialog";
import { styled } from "@mui/system";
import {
  checkOneCharOneDigitOptionalSymbol,
  containsPeriod,
  isUserIdLimitError,
  snackProps,
} from "../../utils";
// import customDatePicker from "../../components/input/datePicker";
import {
  MultipleIdentification,
  MultipleContact,
  MultipleAddress,
} from "./components";
import { v4 as uuidV4 } from "uuid";

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    margin: "0.5rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "1rem 2rem",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "1rem 3rem",
  },
}));

const genderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

const initialState = {
  userId: "",
  password: "",
  confirmPassword: "",
  profileImage: "",
  pic: "",
  salutation: "",
  name: "",
  primaryEmail: "",
  dob: null,
  gender: "",
  occupation: "",
  preferred_language: [],
  // address: [],
  otherAddress: [],
  idTypes: [],
  otherPhoneNumbers: [],
};

const StepOne = (props) => {
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { dependencyData } = props;

  const [state, setState] = useState(initialState);
  const [errorText, setErrorText] = useState();
  const [fieldsToValidate] = useState([
    "userId",
    "password",
    "confirmPassword",
    "name",
    "salutation",
    "primaryEmail",
    "dob",
    "gender",
    // "occupation",
    "idTypes",
    // "address",
    "otherAddress",
  ]);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  useEffect(() => {
    console.log(props.data);
    setState({
      ...initialState,
      ...props.data,
    });
  }, [props.data]);

  const updateFieldState = (fieldValue, key) => {
    console.log(fieldValue);
    // if (key === "profileImage") {
    //   const reader = new FileReader();
    //   reader.addEventListener(
    //     "load",
    //     async () => {
    //       value = await reader.result;
    //       setState({
    //         ...state,
    //         pic: value,
    //       });
    //     },
    //     false
    //   );

    //   if (value?.target?.files?.[0]) {
    //     reader.readAsDataURL(value?.target?.files?.[0]);
    //   }
    // }
    //  else {
    setState({
      ...state,
      [key]: fieldValue,
    });
    // }
  };

  const validate = () => {
    let error = {};
    let validationErrorText = {};
    fieldsToValidate.map((field) => {
      switch (field) {
        case "userId": {
          if (state["userId"] === "") {
            error[field] = true;
            validationErrorText[field] = "User Id should not be empty";
          }
          if (!checkOneCharOneDigitOptionalSymbol(state["userId"])) {
            error[field] = true;
            validationErrorText[field] =
              "Should contain atleast one character, letter and zero or more symbols.";
          }
          if (!containsPeriod(state["userId"])) {
            error[field] = true;
            validationErrorText[field] =
              "Should not start or end with a dot(.)";
          }
          const { error: limitError, errorMessage: limitMessage } =
            isUserIdLimitError(state["userId"]);
          if (limitError) {
            error[field] = true;
            validationErrorText[field] = limitMessage;
          }
          break;
        }
        case "password": {
          if (state["password"].length < 8) {
            error[field] = true;
            validationErrorText[field] =
              "Password should be more than 8 character";
          }
          break;
        }
        case "confirmPassword": {
          if (state["confirmPassword"] === "") {
            error[field] = true;
            validationErrorText[field] = "Confirm Password should not be empty";
          }
          if (state["password"] !== state["confirmPassword"]) {
            error[field] = true;
            validationErrorText[field] =
              "Password and Confirm Password should be same";
          }
          break;
        }
        case "name": {
          if (state["name"] === "") {
            error[field] = true;
            validationErrorText[field] = "Name should not be empty";
          }
          break;
        }
        case "salutation": {
          if (state["salutation"] === "") {
            error[field] = true;
            validationErrorText[field] = "Salutation should not be empty";
          }
          break;
        }
        case "primaryEmail": {
          if (state["primaryEmail"] === "") {
            error[field] = true;
            validationErrorText[field] = "Primary Email should not be empty";
          }
          break;
        }
        case "dob": {
          if (state["dob"] === null) {
            error[field] = true;
            validationErrorText[field] = "DOB should not be empty";
          }
          break;
        }
        case "gender": {
          if (state["gender"] === "") {
            error[field] = true;
            validationErrorText[field] = "Gender should not be empty";
          }
          break;
        }
        case "occupation": {
          if (state["occupation"] === "") {
            error[field] = true;
            validationErrorText[field] = "Occupation should not be empty";
          }
          break;
        }
        case "idTypes": {
          if (state["idTypes"].length <= 0) {
            error[field] = true;
            validationErrorText[field] = "Atleast one is mandatory";
          }
          break;
        }
        case "otherAddress": {
          if (state["otherAddress"].length <= 0) {
            error[field] = true;
            validationErrorText[field] = "Atleast one is mandatory";
          }
          break;
        }
        default:
          return "";
      }
      return field;
    });
    return { error, errorText: validationErrorText };
  };

  // const isScreenThreeNeeded = () => {
  //   // Close Old drawer
  //   props.drawer.setDrawer({
  //     open: false,
  //   });

  //   // Open new drawer
  //   props.drawer.setDrawer({
  //     open: true,
  //     component: (handleClose) => (
  //       <SkipDialog
  //         question={"Do you want to add your health info?"}
  //         handleContinue={(value) => {
  //           props.handleSkipDialogContinue(value, 3);

  //           // props.handleNextStep(state, Object.keys(error)?.length > 0 ? true : false, false, true)
  //           props.handleNextStep(state, false, false, true);

  //           handleClose();
  //         }}
  //         handleClose={handleClose}
  //       />
  //     ),
  //   });
  // };

  const handleSubmit = () => {
    const { error, errorText: validationErrorText } = validate();

    setErrorText(validationErrorText);

    // Error Message - Snackbar
    if (Object.keys(error)?.length > 0) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Please fill required fields",
      });
    }

    if (belowSm) {
      // NOTE: If there is not error, then proceed with skipping the stepTwo
      if (Object.keys(error)?.length === 0) {
        props.drawer.setDrawer({
          open: true,
          component: (handleClose) => (
            <SkipDialog
              question={"Do you want to add family member details?"}
              handleContinue={(shouldSkip) => {
                props.handleSkipDialogContinue(shouldSkip, 2);

                // Todo: Add Skip functionality
                props.handleNextStep(
                  state,
                  Object.keys(error)?.length > 0 ? true : false
                );
                // props.handleNextStep(state, false, false, true);

                handleClose();
              }}
              handleClose={handleClose}
            />
          ),
        });
      }
      // NOTE: Temporary
      // props.handleNextStep(state, false, false, true);
    } else {
      props.handleNextStep(
        state,
        Object.keys(error)?.length > 0 ? true : false
      );
      // props.handleNextStep(state, false, false, true);
    }
  };

  // New ID, Contact & Address Card

  const closeDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: "",
    });
  };

  // Identification Details
  const handleIdentificationSave = (idData) => {
    debugger;
    const idTypes = [...state["idTypes"]];
    if (!!idData?.id) {
      // Edit
      let updatedData = idTypes.map((idItem) => {
        if (idItem?.id === idData?.id) {
          return {
            ...idItem,
            ...idData,
          };
        }
        return idItem;
      });

      updateFieldState(updatedData, "idTypes");
    } else {
      idTypes.push({ ...idData, id: uuidV4() });
      updateFieldState(idTypes, "idTypes");
    }
  };

  const handleIdentificationDelete = (idData) => {
    closeDrawer();
    debugger;
    let idTypes = [...state["idTypes"]];
    idTypes = idTypes.filter((phoneNumber) => phoneNumber?.id !== idData?.id);
    updateFieldState(idTypes, "idTypes");
  };

  const getIDOptions = () => {
    const allOptions = [...dependencyData?.client_id_type];
    const usedOptions = [...state?.idTypes];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.id_type_name?.value === originalOption?.value
        )
    );

    return remainingOptions;
  };

  // Contact Details
  const handleContactSave = (contact) => {
    debugger;
    const otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    if (!!contact?.id) {
      // Edit
      let updatedData = otherPhoneNumbers.map((contactData) => {
        if (contactData?.id === contact?.id) {
          return {
            ...contactData,
            ...contact,
          };
        }
        return contactData;
      });

      updateFieldState(updatedData, "otherPhoneNumbers");
    } else {
      otherPhoneNumbers.push({ ...contact, id: uuidV4() });
      updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
    }
  };

  const handleContactDelete = (contact) => {
    closeDrawer();
    debugger;
    let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    otherPhoneNumbers = otherPhoneNumbers.filter(
      (phoneNumber) => phoneNumber?.id !== contact?.id
    );
    updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
  };

  const getContactOptions = () => {
    const allOptions = [...dependencyData?.phone_type];
    const usedOptions = [...state?.otherPhoneNumbers];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.mobile_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  // Address Details
  const handleAddressSave = (address) => {
    debugger;
    const otherAddress = [...state["otherAddress"]];
    if (!!address?.id) {
      // Edit
      let updatedData = otherAddress.map((addressData) => {
        if (addressData?.id === address?.id) {
          return {
            ...addressData,
            ...address,
          };
        }
        return addressData;
      });

      updateFieldState(updatedData, "otherAddress");
    } else {
      otherAddress.push({ ...address, id: uuidV4() });
      updateFieldState(otherAddress, "otherAddress");
    }
  };

  const handleAddressDelete = (address) => {
    closeDrawer();
    debugger;
    let otherAddress = [...state["otherAddress"]];
    otherAddress = otherAddress.filter(
      (addressData) => addressData?.id !== address?.id
    );
    updateFieldState(otherAddress, "otherAddress");
  };

  const getAddressOptions = () => {
    const allOptions = [...dependencyData?.address_type];
    const usedOptions = [...state?.otherAddress];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.address_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  return (
    <Box sx={{ bgcolor: "white" }}>
      <Grid container>
        <Grid item xs={12} md>
          <StyledBox>
            <Typography sx={{ mb: 1, fontWeight: 600 }}>
              Setup Your User Id & Password
            </Typography>
            <CustomTextfield
              sx={{ marginY: 1 }}
              value={state["userId"]}
              onChange={(e) => updateFieldState(e?.target?.value, "userId")}
              fullWidth
              label="User ID"
              error={errorText?.userId ? true : false}
              helperText={errorText?.userId}
              required
              id="useridField1"
              inputProps={{
                autoComplete: "new-password",
              }}
              InputProps={{
                autoComplete: "off",
              }}
              autoComplete={"off"}
            />
            <CustomTextfield
              sx={{ marginY: 1 }}
              // size="small"
              type={passwordVisibility ? "text" : "password"}
              fullWidth
              id="passwordField21"
              inputProps={{
                autoComplete: "new-password",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setPasswordVisibility((visibility) => !visibility);
                      }}
                      onMouseDown={() => {}}
                      edge="end"
                      id="showHidePasswordRegistration"
                    >
                      {passwordVisibility ? (
                        <VisibilityOff sx={{ color: "grey.400" }} />
                      ) : (
                        <Visibility sx={{ color: "grey.400" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              onChange={(e) => updateFieldState(e?.target?.value, "password")}
              value={state["password"]}
              label="Password"
              error={errorText?.password ? true : false}
              helperText={errorText?.password}
              required
            />
            <CustomTextfield
              fullWidth
              sx={{ marginY: 1 }}
              // // size="small"
              label="Confirm Password"
              type={passwordVisibility ? "text" : "password"}
              value={state["confirmPassword"]}
              onChange={(e) =>
                updateFieldState(e?.target?.value, "confirmPassword")
              }
              id="confirmPasswordField21"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setPasswordVisibility((visibility) => !visibility);
                      }}
                      onMouseDown={() => {}}
                      edge="end"
                      id="showHideConfirmPasswordRegistration"
                    >
                      {passwordVisibility ? (
                        <VisibilityOff sx={{ color: "grey.400" }} />
                      ) : (
                        <Visibility sx={{ color: "grey.400" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errorText?.confirmPassword ? true : false}
              helperText={errorText?.confirmPassword}
              required
            />
          </StyledBox>
        </Grid>
        <Divider orientation={"vertical"} flexItem />

        <Grid item xs={12} md>
          <StyledBox>
            <Typography sx={{ mt: { xs: 1, md: 0 }, mb: 1, fontWeight: 600 }}>
              Personal Details
            </Typography>
            <Box sx={{ mb: 2.5 }}>
              <UploadProfileImage
                value={state["profileImage"]}
                // value={state["pic"]}
                label={"profile_img"}
                profile_pic={state["profileImage"]}
                // profile_pic={state["pic"]}
                handleFileChange={(e) => {
                  // console.log(e?.target?.files?.[0])
                  updateFieldState(e?.target?.files?.[0], "profileImage");
                }}
                error={false}
                errorText={""}
                id={"profileImg"}
              />
            </Box>
            <CustomTextfieldWithSelect
              // size="small"
              sx={{ mt: 1, mb: 2, gap: 1 }}
              label={"Name"}
              selectLabel={"Salutation"}
              textFieldProps={{
                id: "nameTextfieldRegistraion",
                name: "Name",
                required: true,
              }}
              selectProps={{
                id: "salutationSelectRegistration",
                name: "Salutation",
                required: true,
                // placeholder: "Mr.",
              }}
              selectOptions={dependencyData?.salutation}
              fullWidth
              selectValue={state["salutation"]}
              value={state["name"]}
              // onSelectChange={(e, value) => console.log(value?)}
              onSelectChange={(e, salutation) => {
                let data = dependencyData?.salutation?.filter(
                  (x) => x?.value === salutation?.value
                )?.[0];
                updateFieldState(data, "salutation");
              }}
              onChange={(e) => updateFieldState(e.target.value, "name")}
              error={errorText?.name ? true : false}
              helperText={errorText?.name}
              selectError={errorText?.salutation ? true : false}
              selectHelperText={errorText?.salutation}

              // isSalutationError={errorText?.salutation ? true : false}
              // inputError={errorText?.name ? true : false}
              // helperInputText={errorText?.name}
            />
            <CustomTextfield
              fullWidth
              // size="small"
              type={"email"}
              sx={{ mt: 1, mb: 2 }}
              onChange={(e) => updateFieldState(e.target.value, "primaryEmail")}
              value={state["primaryEmail"]}
              label="Primary Email Address"
              error={errorText?.primaryEmail ? true : false}
              helperText={errorText?.primaryEmail}
              required
              id="primaryEmailAddress"
            />
            <CustomDatePicker
              fullWidth
              label={"DOB"}
              value={state["dob"]}
              disableFuture
              // size="small"
              // sx={{ marginY: 1, color: "primary.main" }}
              InputProps={{
                marginY: 2,
                fullWidth: true,
                required: true,
                helperText: errorText?.dob,
                error: errorText?.dob ? true : false,
                id:"dateOfBirthRegistration"
              }}
              onChange={(newDate) =>
                updateFieldState(moment(newDate).toISOString(), "dob")
              }
              helperText={errorText?.dob}
              error={errorText?.dob ? true : false}
              
            />

            <StyledToggleButtonGroup
              id={"Gender"}
              label={"Gender"}
              // size="small"
              data={{ value: state["gender"] }}
              sx={{ marginY: 2 }}
              options={genderOptions}
              onChange={(e, gender) => {
                //   console.log(value)
                updateFieldState(gender, "gender");
              }}
              error={errorText?.gender ? true : false}
              helperText={errorText?.gender}
            />
            <CustomTextfield
              fullWidth
              value={state["occupation"]}
              onChange={(e) => updateFieldState(e.target.value, "occupation")}
              sx={{ marginY: 2 }}
              label="Occupation"
              error={errorText?.occupation ? true : false}
              helperText={errorText?.occupation}
              id="occupationRegistration"
              // required
            />
          </StyledBox>
        </Grid>
        <Divider orientation={"vertical"} flexItem />
        <Grid item xs={12} md>
          <StyledBox>
            <Typography sx={{ mt: { xs: 1, md: 0 }, mb: 1, fontWeight: 600 }}>
              Additional Details
            </Typography>
            <CustomSelect
              // id={"my order status filter"}
              sx={{ marginY: 2 }}
              options={dependencyData?.language}
              filterSelectedOptions
              loading={false}
              value={state["preferred_language"]}
              label={"Preferred Languages"}
              onChange={(e, language) =>
                updateFieldState(language, "preferred_language")
              }
              // onChange={(e, value) => console.log(value)}
              multiple={true}
              // size="small"
              error={errorText?.preferred_language ? true : false}
              helperText={errorText?.preferred_language}
              id="preferredLanguageRegistration"
            />

            {/* <ChooseIdentificationCard
              data={state["idTypes"]}
              title={"ID Details"}
              label={"Id"}
              sx={{ marginY: 2 }}
              isEdit={false}
              onSavedAddressButtonClick={onSavedIdButtonClick}
              removeData={removeData}
              onAddEditAddressButtonClick={onAddEditIdButtonClick}
              helperText={errorText?.idTypes}
              required
            />

            <ChooseContactCard
              data={state["otherPhoneNumbers"]}
              title={"Contact Details"}
              label={"Contact"}
              sx={{ marginY: 2 }}
              isEdit={false}
              removeData={removeContactData}
              onSavedAddressButtonClick={onSavedContactButtonClick}
              onAddEditAddressButtonClick={onAddEditContactButtonClick}
            />
            <ChooseAddressCard
              data={state["otherAddress"]}
              title={"Address Details"}
              label={"Address"}
              sx={{ marginY: 2 }}
              isEdit={false}
              removeData={removeAddressData}
              onSavedAddressButtonClick={onSavedAddressButtonClick}
              onAddEditAddressButtonClick={onAddEditAddressButtonClick}
              helperText={errorText?.otherAddress}
              required
            /> */}

            <MultipleIdentification
              data={state?.idTypes}
              options={getIDOptions()}
              required={true}
              handleSave={handleIdentificationSave}
              handleDelete={handleIdentificationDelete}
              helperText={errorText?.idTypes}
              sx={{ mb: 2 }}
              parentID="FromStep1"
            />

            <MultipleContact
              contacts={state?.otherPhoneNumbers}
              options={getContactOptions()}
              isPrimaryLogicNeeded={false}
              // primaryContactId={primaryContactId}
              required={false}
              handleSave={handleContactSave}
              handleDelete={handleContactDelete}
              helperText={errorText?.otherPhoneNumbers}
              sx={{ mb: 2 }}
              parentID="FromStep1"
            />

            <MultipleAddress
              data={state?.otherAddress}
              options={getAddressOptions()}
              required={true}
              handleSave={handleAddressSave}
              handleDelete={handleAddressDelete}
              helperText={errorText?.otherAddress}
              sx={{ mb: 2 }}
              parentID="FromStep1"
            />
          </StyledBox>
        </Grid>
      </Grid>

      {belowSm ? (
        <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
          <CustomButton
            fullWidth
            sx={{ borderRadius: 0, py: 2 }}
            endIcon={<RightArrowStripesIcon />}
            loading={props.continueLoading}
            onClick={handleSubmit}
            id="proceedButtonStepone"
          >
            Proceed
          </CustomButton>
        </Box>
      ) : (
        <Stack
          sx={{
            px: { md: "2rem", lg: "3rem" },
            py: { sm: "1rem" },
            flexDirection: { sm: "row" },
            alignItems: { sm: "flex-end" },
            justifyContent: { sm: "flex-end" },
            gap: 2,
          }}
        >
          <Box sx={{ minWidth: "140px" }}>
            <CustomButton
              sx={{
                borderRadius: 2,
                py: 1.5,
                borderColor: "grey.300",
                textTransform: "capitalize",
              }}
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => props.handleGoBackDialog()}
              id="gobackButtonStepone"
            >
              Go Back
            </CustomButton>
          </Box>

          {/* NOTE Use for DEVELOPMENT PURPOSE only */}
          {/* <Box sx={{ minWidth: "140px" }}>
            <CustomButton
              sx={{
                borderRadius: 2,
                py: 1.5,
                borderColor: "grey.300",
                textTransform: "capitalize",
              }}
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => props.handleUpdateLater(initialState)}
            >
              Update Later
            </CustomButton>
          </Box> */}
          <Box sx={{ minWidth: "140px" }}>
            <CustomButton
              sx={{ borderRadius: 2, py: 1.5 }}
              variant="contained"
              fullWidth
              loading={props.continueLoading}
              onClick={handleSubmit}
              id="confirmButtonStepone"
            >
              Confirm
            </CustomButton>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default withDialog(withDrawer(withSnackBar(StepOne)));
