import { SvgIcon } from "@mui/material"
import * as React from "react"

function CallIcon(props) {
    return (
        <SvgIcon width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            htmlColor="transparent"
            stroke={props.color ?? "rgba(0, 0, 0, 0.54)"}
            {...props}>
            <path
                d="M19 15.252v2.263a1.51 1.51 0 01-1.645 1.509 14.925 14.925 0 01-6.508-2.315 14.707 14.707 0 01-4.525-4.526 14.93 14.93 0 01-2.316-6.538A1.509 1.509 0 015.507 4H7.77a1.508 1.508 0 011.509 1.298c.094.724.27 1.435.524 2.119a1.509 1.509 0 01-.34 1.594l-.953.956a12.067 12.067 0 004.525 4.525l.958-.958a1.508 1.508 0 011.594-.34 9.681 9.681 0 002.119.528A1.508 1.508 0 0119 15.252z"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    )
}

export default CallIcon
