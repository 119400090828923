import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import CartProducts from "../../components/cards/cartProductscard";
import OrderSummary from "../../components/cards/OrderSummary";
import { Stack, Typography } from "@mui/material";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import { drawerProps, snackProps } from "../../utils";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import AddEnquiry from "../enquiries/addEnquiry";
import { routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
// import { snackProps } from "../../utils"

const { GET_CART_TOTAL, DELETE_CART, ENQUIRY_PAGE_ADD, CART_COUNT } = actions;

const EnquiryTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: "0.75rem 1rem",
  fontWeight: "bold",
  fontSize: "0.80rem",
  backgroundColor: theme.palette.common.white,
}));

const Cart = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [flag, setFlag] = useState(true);

  const cartData = useSelector((state) => state?.clientCart?.cartTotal);

  const getCartDetails = useCallback(() => {
    Promise.resolve(dispatch(GET_CART_TOTAL({})))
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  const deleteCartItem = (id) => {
    // alert(id)
    Promise.resolve(dispatch(DELETE_CART({ id })))
      .then((res_data) => {
        // console.log(res_data?.payload?.message)
        if (res_data?.payload?.message === "200 Successful") {
          props.snack.setSnack({
            open: true,
            message: "Item removed from Cart Successfully!",
            duration: 6000,
            severity: snackProps.severity.success,
            setSnack: () => null,
          });
          getCartDetails();
          Promise.resolve(dispatch(CART_COUNT({}))).catch((err) => {
            console.log(err);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addEnquiry = (id) => {
    props.drawer.setDrawer({
      open: true,
      anchor: drawerProps.direction.right,
      component: (handleClose) => (
        <AddEnquiry
          showDetails={false}
          isCart={true}
          handleClose={handleClose}
          parentID="FromCart"
          ids={id}
          handleAddEnquiry={(data) => enquiryHandler(data)}
        />
      ),
    });
  };

  const enquiryHandler = (data) => {
    Promise.resolve(dispatch(ENQUIRY_PAGE_ADD({ ...data })))
      .then((res_data) => {
        console.log(res_data);
        if (!res_data?.payload?.error) {
          props.drawer.setDrawer({
            open: false,
          });

          props.snack.setSnack({
            open: true,
            message: "Enquiry Added Successfully!",
            duration: 6000,
            severity: snackProps.severity.success,
            setSnack: () => null,
          });
          dispatch(CART_COUNT({}));
          history.push(routes.enquiries.pathName);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCartDetails();
  }, [getCartDetails]);

  useEffect(() => {
    let arr = cartData?.data?.items?.filter((x) => x.isExpired === true);
        console.clear()
        console.log(arr)
        if (arr?.length > 0) {
          setFlag(false);
        }
        else{
          setFlag(true)
        }
  }, [cartData])

  let cart_data = cartData?.data;
  console.log(cart_data);
  const fullHeight = "calc(100vh - 64px)";

  // ------------------------------------Cart Service Detail Redirect
  const handleServiceDetailRedirect = (id, versionId) => {
    history.push(
      `${routes.purchase.subRoutes.purchaseDetails.absolutePath}/${id}/${versionId}`
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={cart_data?.items?.length > 0 ? 8 : 12}>
        <Box sx={{ padding: { xs: 0, md: "0.5rem 0.5rem" } }}>
          <Box
            sx={{
              overflow: "auto",
              position: "relative",
              margin: { sx: 0, md: "0.25rem 1rem" },
              maxHeight: { md: "calc(100vh - 64px)" },
            }}
          >
            <div>
              {cart_data?.items?.length > 0 ? (
                cart_data?.items?.map((x) => (
                  <Box sx={{ margin: { xs: 0, md: 1 } }}>
                    <CartProducts
                      data={x}
                      handleDeleteItem={(id) => deleteCartItem(id)}
                      handleRedirect={handleServiceDetailRedirect}
                    />
                  </Box>
                ))
              ) : (
                <>
                  <Stack
                    style={{
                      minHeight: fullHeight,
                      maxHeight: fullHeight,
                      margin: "auto",
                    }}
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      style={{ fontStyle: "italic" }}
                      color="textSecondary"
                    >
                      Cart is Empty
                    </Typography>
                  </Stack>
                </>
              )}
            </div>
          </Box>
        </Box>
      </Grid>
      {cart_data?.items?.length > 0 && (
        <Grid item xs={12} md={4}>
          <div>
            <OrderSummary
              data={cart_data}
              showButtons={flag}
              addEnquiryHandler={(data) => addEnquiry(data)}
            />
            {
              !flag ? <Typography textAlign={"center"} color={"secondary.main"}>Please remove the unavailable service(s) to continue</Typography> : <></>
            }
          </div>
        </Grid>
      )}
    </Grid>
  );
};

// export default Cart;
export default withSnackBar(withDrawer(withDialog(Cart)));
