import * as React from "react";
import { SvgIcon } from "@mui/material";

function CreateIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <path
        d="M16.424 5a2.57 2.57 0 00-1.824.752l-8.157 8.157a1.29 1.29 0 00-.328.559l-1.094 3.828a.553.553 0 00.683.683l3.83-1.089c.21-.06.402-.173.557-.328L18.248 9.4A2.576 2.576 0 0016.424 5zm0 1.1a1.468 1.468 0 011.042.435 1.465 1.465 0 010 2.084l-.715.715-2.084-2.086.715-.715a1.469 1.469 0 011.042-.434V6.1zm-2.538 1.93l2.085 2.085-6.662 6.66a.187.187 0 01-.08.047l-2.872.821.821-2.873a.18.18 0 01.047-.079l6.661-6.661z"
        fill="#0063E7"
      /> */}
      <path
      d="M19.577 0a4.404 4.404 0 0 0-3.126 1.29L2.473 15.272c-.266.266-.46.596-.562.958L.036 22.793a.948.948 0 0 0 1.17 1.171l6.564-1.867c.36-.103.689-.297.954-.562L22.703 7.543A4.416 4.416 0 0 0 19.577 0Zm0 1.886a2.515 2.515 0 0 1 1.786.745 2.512 2.512 0 0 1 0 3.573L20.137 7.43l-3.57-3.576 1.224-1.226a2.518 2.518 0 0 1 1.786-.744v.002Zm-4.35 3.308 3.574 3.575L7.384 20.186a.322.322 0 0 1-.137.08l-4.922 1.408 1.407-4.925a.308.308 0 0 1 .081-.136L15.228 5.194Z"
      fill="#0063E7"
    />
    </SvgIcon>
  );
}

export default CreateIcon;
