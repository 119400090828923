import React, { useState } from "react"
import Box from "@mui/material/Box";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CustomButton, CustomTextfield } from "../..";
import { RightArrowStripesIcon } from "../../../assets";

function CancelReason(props) {

    // Component States
    const [reason, setReason] = useState('')
    const [error, setError] = useState(false)


    // Handlers / Callbacks
    const handleChange = (event) => {
        setReason(event.target.value)
    }

    const handleSubmit = () => {
        if (reason === "") {
            setError(true)
        } else {
            setError(false)
            props.saveReasonHandleClose(reason)
        }
    }

    return (
        <>
            <Box sx={{ mb: 9 }}>
                <Stack
                    sx={{
                        flexDirection: "row",
                        pt: 2,
                        pb: 1.5,
                        px: 1,
                        alignItems: "center",
                        border: 1,
                        borderColor: "grey.border",
                    }}
                >
                    <IconButton onClick={props.handleClose} aria-label="close-drawer">
                        <CloseOutlinedIcon fontSize="small" />
                    </IconButton>
                    <Typography sx={{ flex: 1, textAlign: "center", ml: "-44px" }}>
                        Reason for Cancellation
                    </Typography>
                </Stack>

                <Box sx={{ py: 2, px: { xs: 2.5, md: 3.5 } }}>
                    {/* <Typography sx={{ pb: 1.5 }}>Reason for Cancellation</Typography> */}
                    <CustomTextfield
                        id="subscription-cancel-reason"
                        name="Cancellation Reason"
                        value={reason}
                        onChange={handleChange}
                        placeholder={"Type Here..."}
                        multiline
                        rows={4}
                        sx={{ width: "100%" }}
                        error={error}
                        helperText={error && "This field is required"}
                    />
                </Box>

            </Box>
            <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
                <CustomButton
                    onClick={handleSubmit}
                    fullWidth
                    sx={{ borderRadius: 0, py: 2 }}
                    endIcon={<RightArrowStripesIcon />}
                >
                    Submit
                </CustomButton>
            </Box>
        </>
    );
}

export default CancelReason
