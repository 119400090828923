import * as React from "react";
import { useCallback, useEffect } from "react";
import { makeStyles, styled } from "@mui/styles";
import {
  Stack,
  Box,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  Hidden,
} from "@mui/material";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ProductListViewCard from "../../components/cards/productListViewCard";
import WishlistCard from "../../components/composedComponents/wishlist";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import {
  drawerProps,
  loadingArray,
  snackMessage,
  snackProps,
} from "../../utils";
import AddEnquiry from "../enquiries/addEnquiry";
import {
  CustomButton,
  ErrorHandler,
  NoDataHandler,
  SearchAndFilter,
} from "../../components";
const {
  GET_ALL_WISHLIST,
  ADD_TO_WISHLIST,
  DELETE_WISHLIST,
  ADD_TO_CART,
  CART_COUNT,
  ENQUIRY_PAGE_ADD,
} = actions;

const fullHeight = "calc(100vh - 64px)";

const StyledAppBar = styled(AppBar)(({ theme }) => {
  return {
    backgroundColor: `${theme.palette.common.white} !important`,
    borderBottom: 1,
    borderColor: theme.palette.grey[300],
    zIndex: 2,
    ...theme.mixins.customTop,
  };
});

function Wishlist(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const fullHeight = "calc(100vh - 64px)";

  const [pageNo, setPageNumber] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);
  const pageSize = 9;
  const observer = React.useRef();

  React.useEffect(() => {
    let payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    dispatch(GET_ALL_WISHLIST(payload));
  }, []);

  const getData = (pageNo = 0) => {
    let payload = {
      pageNo: pageNo * pageSize,
      pageSize: pageSize,
    };
    console.clear();
    console.log(payload);
    Promise.resolve(dispatch(GET_ALL_WISHLIST(payload)))
      .then((res) => {
        if (res?.payload?.data?.length > 0) {
          // alert("Inside")
          setHasMore(res?.payload?.data?.length < res?.payload?.totalCount);
        } else {
          // alert("Outside")
          setHasMore(false);
        }
        // setHasMore(res?.payload?.data?.length < res?.payload?.totalCount);
        // console.log(res?.payload?.data?.length < res?.payload?.totalCount)
        // debugger
        // setHasMore(res?.payload?.data?.length < res?.payload?.totalCount);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData(pageNo);
  }, [pageNo]);

  const retry = () => {
    dispatch(GET_ALL_WISHLIST({}));
  };

  const wishListData = useSelector((state) => state?.purchase?.getAllWishlist);

  const { data, loading, error } = wishListData;
  console.log(wishListData);

  const lastListItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const removeFromWishList = (id) => {
    Promise.resolve(dispatch(DELETE_WISHLIST({ id })))
      .then((res) => {
        if (res?.payload?.data) {
          props.snack.setSnack({
            open: true,
            message: "Removed from wishlist.",
            severity: snackProps.severity.error,
          });
          setPageNumber(0);
          getData(0);
          // dispatch(GET_ALL_WISHLIST({}));
        }
      })
      .catch((err) => {});
  };

  const handleRedirect = (id, versionId) => {
    history.push(`/purchase/details/${id}/${versionId}`);
  };

  const handleAddCart = (id) => {
    // alert(id)
    Promise.resolve(dispatch(ADD_TO_CART({ id: id })))
      .then((res) => {
        if (res?.payload?.data) {
          props.snack.setSnack({
            open: true,
            message: "Moved to Cart.",
            severity: snackProps.severity.success,
          });
          // dispatch(DELETE_WISHLIST({ id: id }));
          setPageNumber(0);
          dispatch(CART_COUNT({}));
          // let payload = {
          //   pageNo: pageNo,
          //   pageSize: pageSize,
          // };
          // dispatch(GET_ALL_WISHLIST());

          getData(0);
        }
      })
      .catch((err) => {});
  };

  const handleAddEnquiry = (data) => {
    Promise.resolve(dispatch(ENQUIRY_PAGE_ADD({ ...data })))
      .then((res_data) => {
        console.clear();
        console.log(res_data);
        props.snack.setSnack({
          open: true,
          message: "Enquiry Added Successfully",
          severity: snackProps.severity.success,
        });
        props.drawer.setDrawer({
          open: false,
        });
      })
      .catch((err) => {
        props.snack.setSnack({
          open: true,
          message: "Something went wrong. Please try again later",
          severity: snackProps.severity.error,
        });
        props.drawer.setDrawer({
          open: false,
        });
      });
  };

  const handleEnquire = (data) => {
    props.drawer.setDrawer({
      open: true,
      anchor: drawerProps.direction.right,
      component: (handleClose) => (
        <AddEnquiry
          data={data}
          isCart={false}
          handleClose={handleClose}
          handleAddEnquiry={(enquiryData) => handleAddEnquiry(enquiryData)}
        />
      ),
    });
  };

  const handleEmptyRedirect = () => {
    history.push("/purchase");
  };

  return (
    <>
      {/* <SearchAndFilter
          title={`My Wishlist ${
            data?.length ? "(" + data?.length + ")" : ""
          }`}
          hasFilter={false}
          onSearchChange={() => {}}
          onFilterClick={() => {}}
        /> */}
      <StyledAppBar position="sticky" elevation={0}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color="textPrimary"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          >
            {`My Wishlist ${
              wishListData?.totalCount
                ? "(" + wishListData?.totalCount + ")"
                : ""
            }`}
          </Typography>
        </Toolbar>
      </StyledAppBar>
      <div
        style={
          {
            // overflow: "auto",
            // position: "relative",
            // maxHeight: "calc(100vh - 64px)",
          }
        }
      >
        <Grid container>
          {!loading && error && <ErrorHandler onRetry={() => retry()} />}
          {!loading && !error && data?.length === 0 && (
            <>
              <NoDataHandler
                message={"No items added in your wishlist"}
                empty
                showButton={true}
                buttonLabel={"Explore"}
                redirect={() => handleEmptyRedirect()}
              />
            </>
          )}
          {loading &&
            loadingArray(3).map((x, index) => {
              <Grid item xs={12} md={4} sx={{ p: "5px" }}>
                <WishlistCard loading={true} />
              </Grid>;
            })}
          {wishListData?.data?.length > 0 &&
            wishListData?.data.map((x, index) => {
              return (
                <Grid
                  item
                  ref={
                    wishListData?.data?.length === index + 1
                      ? lastListItemRef
                      : null
                  }
                  xs={12}
                  md={4}
                  sx={{ p: { xs: 1, md: "4px" } }}
                >
                  <Hidden mdDown>
                    <WishlistCard
                      data={x}
                      loading={wishListData?.loading}
                      redirectUrl={"/purchase/details/"}
                      handleCardClick={(id, versionId) =>
                        handleRedirect(id, versionId)
                      }
                      id={x.id}
                      from={"wishList"}
                      handleAddRemoveWishList={(id) => removeFromWishList(id)}
                      handleEnquiry={(data) => handleEnquire(data)}
                      handleAddToCart={(id) => handleAddCart(id)}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <ProductListViewCard
                      data={x}
                      loading={wishListData?.loading}
                      redirectUrl={"/purchase/details/"}
                      handleAddRemoveWishList={(id) => removeFromWishList(id)}
                      handleEnquiry={(data) => handleEnquire(data)}
                      handleAddToCart={(id) => handleAddCart(id)}
                    />
                  </Hidden>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </>
  );
}

export default withSnackBar(withDrawer(withDialog(Wishlist)));
