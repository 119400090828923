import * as React from "react";
import { SvgIcon } from "@material-ui/core";

function EmailFilledIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.75 4A1.75 1.75 0 0 0 2 5.75l10 6.254 9.999-6.25A1.75 1.75 0 0 0 20.25 4H3.75ZM2 7.752V18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7.752l-10 6.249L2 7.752Z"
        fill="#0063E7"
      />
    </SvgIcon>
  );
}

export default EmailFilledIcon;
