import React from "react";

export const TrackerIcon = (props) => {
    const width = props?.width ? props?.width : 119.118
    const height = props?.height ? props?.height : 16.795;
    const color = props?.color ? props?.color : '#9297a5';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.118 16.795"
      {...props}
    >
        <path fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" d="M5891.81 757.689h3.734l2.635-7.339 4.756 14.795 2.425-7.456h3.568" transform="translate(-5890.81 -749.351)"/>
    </svg>
  );
};

