import React from 'react';
import { Stack,Typography,Divider,styled,Skeleton } from '@mui/material';
import { CustomAvatar } from '../';
import { avatarColors } from '../../utils/commons';
import DoneIcon from '@mui/icons-material/Done';

export const CustomSelectListing = (props) =>{

    const IconBox = styled('div', {
        shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'borderColor' })
        (({ backgroundColor,borderColor }) => ({
            width:24,
            height:24,
            borderRadius:'50%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            border:'2px solid',
            borderColor:borderColor,
            backgroundColor:backgroundColor
      }));

    const {
        avatar="",
        label="",
        subLabel="",
        desc="",
        selected=false,
        divider=false,
        dividerPadding=false,
        disabled=false,
        handleChange=null,
        loading=false,
        index=Math.floor(Math.random() * 9)
    } = props; 

    return(
        <>
        {
            loading ? (
            <Stack
                direction={'row'}
                alignItems={'flex-start'}
                sx={{
                    cursor:!disabled ? 'pointer' : 'auto',
                    p:2
                }}
                onClick={!disabled && handleChange}
            >
            <Stack
                direction={'row'}
                alignItems={'flex-start'}
                sx={{flex:1}}
            >
                <Skeleton variant="circular" width={40} height={40} />
                <Stack sx={{flex:1,pl:2}}>
                    <Skeleton variant="text" width={'50%'} />
                    <Skeleton variant="text" width={'50%'} />
                    <Skeleton variant="text" width={'50%'} />
                </Stack>
            </Stack>
            <Skeleton variant="circular" width={24} height={24} />
        </Stack>
            ) : (
                <>
                <div 
                    style={{
                        cursor:!disabled ? 'pointer' : 'auto',
                        padding:'20px'
                    }}
                    onClick={!disabled && handleChange}        
                >
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                    >
                        {avatar && 
                            <div style={{marginRight:16}}>
                                <CustomAvatar src={avatar} name={avatar} width={48} height={48} sx={{background:avatarColors[index]}} />
                            </div>
                        }
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            sx={{flex:1}}
                        >
                            <Stack sx={{flex:1}}>
                                {label &&
                                    <Typography 
                                        variant="body1"
                                        sx={{
                                            mb:1/2,
                                            color:'#363A57',
                                        }}
                                        fontWeight={400}
                                    >
                                        {label}
                                    </Typography>
                                }
                                {subLabel &&
                                <Typography 
                                variant="body2"
                                    sx={{
                                        mb:1/2,
                                        color:'#9297A5',
                                    }}
                                    fontWeight={400}
                                >
                                    {subLabel}
                                </Typography>
                                }
                            </Stack>
                            <IconBox 
                                backgroundColor={selected ? '#11BB95' : 'transparent'}
                                borderColor={selected ? '#11BB95' : '#C7C7C7'}
                            >
                                {selected && <DoneIcon fontSize='10' sx={{color:"#fff"}} />}
                            </IconBox>
                        </Stack>    
                </Stack>
                {desc &&
                    <Stack 
                        direction={'row'}
                    >
                        <div style={{width:48, marginRight:16,}}/>
                        <Typography
                            variant="body2"
                            sx={{
                                color:'#9297A5',
                            }}
                            fontStyle={'italic'}
                            fontWeight={400}
                        >
                            {desc}
                        </Typography>
                    </Stack>
                }
                </div>
                {divider && 
                    <Divider
                        light
                        sx={{
                            mx:dividerPadding && '20px',
                            borderColor:'#F1F2F5'
                        }}
                    />
                }
                </>
            )
        }
        </>
    )
}