import PropTypes from "prop-types"
import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CalendarIcon } from "../../../assets";
import { styled } from "@mui/system";

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
    background: "linear-gradient(120deg, rgba(255,103,103,1) 0%, rgba(255,168,17,1) 100%)",
    borderRadius: 8,
    "& .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontWeight: "bold",
        letterSpacing: 0.5,
    },
    "& .MuiListItemText-secondary": {
        color: theme.palette.grey[200],
    },
}))

function TrackEnquiryCard(props) {
    const fromID = props?.parentID ?? '';
    return (
        <CustomListItemButton onClick={props.onClick} id={`trackEnquiryCard${fromID}`}>
            <ListItemIcon>
                <Avatar sx={{ bgcolor: "background.paper" }}>
                    <CalendarIcon />
                </Avatar>
            </ListItemIcon>
            <ListItemText
                primary="Track your Enquiry status"
                secondary="Click here to know more details"
            />
            <IconButton onClick={props.onClick} id={`trackEnquiryCardArrow${fromID}`}>
                <ChevronRightIcon htmlColor="white" />
            </IconButton>
        </CustomListItemButton>
    );
}

TrackEnquiryCard.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default TrackEnquiryCard;
