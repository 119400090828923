import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material";
// import { alpha } from '@mui/material/styles';

const SwitchStyleToggle = styled(ToggleButtonGroup)(({ theme }) => ({
  padding: 6,
  gap: 2,
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 40,
  cursor: "pointer",
  "& .MuiToggleButton-root": {
    lineHeight: 1,
    background: "unset",
    "&:hover": {
      background: "unset",
    },
    "&.Mui-selected": {
      background: theme.palette.success.main,
      color: theme.palette.common.white,
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
      },
      "&:hover": {
        background: theme.palette.success.light,
      },
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    "&:not(:last-of-type), &:not(:first-of-type)": {
      border: 0,
      padding: "8px 14px",
      borderRadius: 20,
    },
    "&:first-of-type": {
      transition: "0.3s ease background-color",
      "&.Mui-selected": {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        "& .MuiTypography-root": {
          color: theme.palette.common.white,
        },
        "&:hover": {
          background: theme.palette.error.light,
        },
      },
    },
    "&:last-of-type": {
      transition: "0.3s ease background-color",
    },
  },
}));

function SwitchStyleToggleButton(props) {
  return (
    <SwitchStyleToggle
      value={props.value}
      exclusive
      onClick={props.onClick}
      aria-label="toggle state"
    >
      <ToggleButton value={false} aria-label="off">
        No
      </ToggleButton>
      <ToggleButton value={true} aria-label="on">
        Yes
      </ToggleButton>
    </SwitchStyleToggle>
  );
}

export default SwitchStyleToggleButton;
