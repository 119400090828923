import React from "react";
import img from "../../assets/icons/think.svg";
import signup from "../../assets/icons/signup.svg";
import hand_mob from "../../assets/icons/hand_mob.png";
import Button from "@mui/material/Button";
// import { MobileNumberWithCode } from "qdm-component-library";
import Grid from "@mui/material/Grid";
import { __countries } from "../../components/counties";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { isValidPhoneNumber } from "libphonenumber-js";
import { withStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";
import { dontShowInMenuRoutes } from "../../router/routes";
import Screen from "./screen";
import { CustomTextfieldWithSelect } from "../../components";
import { countries, countryFilterOption, snackProps } from "../../utils";
import { withSnackBar } from "../../HOC's";
import { Box, Hidden, Typography } from "@mui/material";
import { OnboardFormCard } from "./components";

const styles = (theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "70px",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  grid2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f0f2f6",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      background: "#ffffff",
    },
  },
});

class ForgotUserId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNo: {},
      mobileNumber: "",
      confirmation: false,
      dropdownData: __countries,
      code: "",
      number: "",
      loading: false,
    };
  }

  componentDidMount = async () => {};
  getUserId = async () => {
    if (
      // this.state?.mobileNo?.mobileNo &&
      // this.state?.mobileNo?.countryData?.code
      this.state?.number &&
      this.state.code
    ) {
      if (
        // isPossiblePhoneNumber(
        //   this.state?.mobileNo?.mobileNo,
        //   this.state?.mobileNo?.countryData?.code
        // ) === true &&
        // isValidPhoneNumber(
        //   this.state?.mobileNo?.mobileNo,
        //   this.state?.mobileNo?.countryData?.code
        // ) === true
        isValidPhoneNumber(this.state?.number, this.state.code?.code)
      ) {
        this.setState({ loading: true });
        let res = await this.props?.FORGET_USERID({
          // phoneNumber: this.state?.mobileNo?.mobileNo,
          phoneNumber: this.state.number,
          // phoneNumberISD: this.state?.mobileNo?.countryData?.value,
          phoneNumberISD: `+${this.state.code?.phone}`,
        });
        if (res?.payload?.data?.error) {
          // alert("Phone Number Not Registered");
          this.setState({ loading: false });
          this.props.snack.setSnack({
            open: true,
            message: "Phone Number Not Registered",
            duration: 6000,
            severity: snackProps.severity.error,
            setSnack: () => null,
          });
        } else {
          if (res?.payload?.message === "Success") {
            const state = this.state;
            state.confirmation = true;
            state.loading = false;
            this.setState({ ...this.state, ...state });
            this.props.snack.setSnack({
              open: true,
              message: "User ID is sent to the registered Mobile Number",
              duration: 6000,
              severity: snackProps.severity.success,
              setSnack: () => null,
            });
          } else {
            this.setState({ loading: false });
            this.props.snack.setSnack({
              open: true,
              message: "Something Went Wrong.Please try again Later.",
              duration: 6000,
              severity: snackProps.severity.error,
              setSnack: () => null,
            });
          }
        }
      } else {
        this.setState({ loading: false });
        this.props.snack.setSnack({
          open: true,
          message: "Please Enter a valid Phone Number",
          duration: 6000,
          severity: snackProps.severity.error,
          setSnack: () => null,
        });
      }
    } else {
      this.setState({ loading: false });
      this.props.snack.setSnack({
        open: true,
        message: "Please enter Mobile Number and Mobile Code",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    }
  };
  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleRoute = () => {
    alert("Back to sign in");
  };
  backtoLogin = () => {
    this.props.history.push({
      pathname: dontShowInMenuRoutes.login.pathName,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ overflowX: "hidden" }}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              background: "white",
              minHeight: "100vh",
            }}
          >
            <Screen imageURL={img} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            alignItems="center"
            justifyContent="center"
            className={classes.grid2}
          >
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!this.state?.confirmation && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  style={{
                    background: "white",
                    padding: "30px 20px",
                    borderRadius: "10px",
                  }}
                >
                  {/* <OnboardFormCard> */}
                    <Hidden mdUp>
                      <Box sx={{ margin: "auto", width: "50%" }}>
                        <img src={signup} style={{ width: "150px" }} />
                      </Box>
                    </Hidden>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        style={{
                          margin: "20px",
                          color: "black",
                          fontSize: "22px",
                          fontWeight: 600,
                        }}
                      >
                        Forgot User ID?
                      </Typography>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        style={{
                          margin: "20px",
                          color: "#bcbfc7",
                          fontSize: "15px",
                        }}
                      >
                        Enter your mobile number to get your user ID via
                        message.
                      </Typography>
                    </div>
                    <div style={{ margin: "30px" }}>
                      {/* <CustomTextfieldWithSelect
                      error={false}
                      size="small"
                      helperText=""
                      textFieldProps={{
                        id: "",
                        name: "Contact Number",
                        placeholder: "Contact Number",
                      }}
                      selectProps={{}}
                      sx={{ gap: 1 }}
                      selectOptions={countries}
                      fullWidth
                      selectValue={this.state.code?.phone}
                      value={this.state.number}
                      onSelectChange={(e, value) =>
                        this.changeState("code", value)
                      }
                      onChange={(e) =>
                        this.changeState("number", e.target.value)
                      }
                    /> */}
                      <CustomTextfieldWithSelect
                        fullWidth={true}
                        selectValue={this.state.code ? this.state.code : null}
                        selectOptions={countries}
                        onSelectChange={(e, value) => {
                          this.changeState("code", value);
                        }}
                        sx={{ gap: 1 }}
                        size="small"
                        filterOptions={countryFilterOption}
                        selectProps={{
                          id: "countrySelectUserid",
                          name: "country",
                        }}
                        textFieldProps={{
                          id: "registerContactUserid",
                          name: "contactNumber",
                          helperTextNoWrap: true,
                        }}
                        placeholder={{
                          select: "Code",
                          text: "Mobile Number",
                        }}
                        useCustomLabel
                        value={this.state.number}
                        onChange={(e) =>
                          this.changeState("number", e.target.value)
                        }
                        error={false}
                        helperText={""}
                      />
                    </div>
                    <div className={classes.buttonStyle}>
                      <Button
                        id="sendUserIdBtn"
                        style={{ width: "100%", textTransform: "none" }}
                        variant="contained"
                        onClick={() => this.getUserId()}
                        disabled={this.state.loading}
                        sx={{
                          marginTop: { xs: "20px", sm: "20px", md: "14px" },
                        }}
                      >
                        {this.state.loading ? `Sending ...` : `Send User ID`}
                      </Button>
                    </div>
                  {/* </OnboardFormCard> */}
                </Grid>
              )}
              {this.state?.confirmation && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  style={{
                    background: "white",
                    padding: "30px 20px",
                    borderRadius: "10px",
                  }}
                >
                  <OnboardFormCard>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "20px",
                          color: "black",
                          fontSize: "22px",
                          fontWeight: 600,
                        }}
                      >
                        Forgot User ID?
                      </p>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img alt="logo" src={hand_mob} />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "20px",
                          color: "#bcbfc7",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        Your User Id has been send to your registered mobile
                        number
                      </p>
                    </div>
                    <div className={classes.buttonStyle}>
                      <Button
                        id="backToSignIn"
                        style={{ width: "100%", textTransform: "none" }}
                        variant="contained"
                        onClick={() => this.backtoLogin()}
                        sx={{
                          marginTop: { xs: "20px", sm: "20px", md: "14px" },
                        }}
                      >
                        Back to Sign In
                      </Button>
                    </div>
                  </OnboardFormCard>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

//export default ForgotUserId;
const mapStateToProps = (state) => ({
  send_user_id: state?.loginSlice?.sendUserID,
});

// export default withStyles(styles)(
//   connect(mapStateToProps, actions)(withRouter(ForgotUserId))
// );
export default connect(
  mapStateToProps,
  actions
)(withSnackBar(withRouter(withStyles(styles)(ForgotUserId))));
