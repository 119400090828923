import {
  Box,
  Button,
  Divider,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withDialog, withDrawer, withSnackBar } from "../../../../../HOC's";
import {
  CustomButton,
  CustomSelect,
  CustomTextfield,
  CustomTextfieldWithSelect,
  StyledToggleButtonGroup,
} from "../../../../../components";
import CustomDatePicker from "../../../../../components/datePicker";
import { Grid } from "@mui/material";
import { alpha } from "@mui/material";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Checkbox from "@mui/material/Checkbox";
import { RightArrowStripesIcon } from "../../../../../assets";
import { styled } from "@mui/system";
// import SkipDialog from "./skipDialog";
import {
  snackProps,
  sortByEmergency,
  sortByPrimary,
} from "../../../../../utils";
import { v4 as uuidV4 } from "uuid";
import isEqual from "react-fast-compare";
import {
  ClearPrompt,
  EditPrefillPrompt,
  DeletePrompt,
  FamilyMembersDetailSection,
  ChangeEmergencyContactPrompt,
  MultipleIdentification,
  MultipleContact,
  MultipleAddress,
} from "../../../../registration/components";
import { DrawerSkeleton } from "../../../../../components";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  MultipleAddressMobile,
  MultipleContactMobile,
  MultipleIdentificationMobile,
} from "../customCards";

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    margin: "1rem 1.5rem 0rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "1rem 2rem",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "1rem 3rem",
  },
}));

const genderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

const BloodGroupData = [
  {
    label: "O+ve",
    value: "O+ve",
  },
  {
    label: "A+ve",
    value: "A+ve",
  },
  {
    label: "B+ve",
    value: "B+ve",
  },
  {
    label: "A-ve",
    value: "A-ve",
  },
];

const initialState = {
  relationship: "",
  id: "",
  salutation: "",
  name: "",
  email: "",
  dob: null,
  gender: "",
  // occupation: "",
  languages: [],
  otherAddress: [],
  idTypes: [],
  otherPhoneNumbers: [],
  isEmergencyContact: false,
};

const EditFamilyMembersMobile = (props) => {
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { dependencyOptions } = props;

  const [state, setState] = useState(initialState);

  const [primaryContactId, setPrimaryContactId] = useState("");

  const [errorText, setErrorText] = useState();

  const [familyMembers, setFamilyMembers] = useState([]);

  const [fieldsToValidate] = useState([
    "relationship",
    "salutation",
    "name",
    "otherPhoneNumbers",
  ]);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [checkData, setCheckData] = useState();

  const { editData, handleClose } = props;
  // alert(JSON.stringify(editData))

  const isAdd = () => {
    return isEqual(editData, {});
  };

  useEffect(() => {
    const crumbs = [...props.breadcrumbs];
    crumbs.push({
      label: isAdd()
        ? "Add New Family Members Details"
        : "Update Family Members Details",
    });
    setBreadcrumbs(crumbs);
  }, [props.breadcrumbs]);

  useEffect(() => {
    console.log(props.data);
    // setState({
    //   ...initialState,
    //   ...props.data?.relations?.[0],
    // });
    if (Array.isArray(props.data?.familyMembers)) {
      setFamilyMembers([...props.data?.familyMembers]);
    }

    // Set Primary contact ID
    const otherPhoneNumbers = editData?.otherPhoneNumbers;
    if (otherPhoneNumbers && Array.isArray(otherPhoneNumbers)) {
      const filteredArray = otherPhoneNumbers.filter(
        (phone) => phone?.isPrimary
      );
      if (filteredArray.length > 0) {
        setPrimaryContactId(filteredArray?.[0]?.id);
      }
    }
  }, [props.data]);

  useEffect(() => {
    // setState(editData);
    if (!isAdd()) {
      let temp_state = {};
      // let gender = editData?.gender
      temp_state["relationship"] = editData?.relationship;
      temp_state["name"] = editData?.name;
      temp_state["id"] = editData?.id;
      temp_state["isEmergencyContact"] = editData?.isEmergencyContact;
      // temp_state["profileImage"] =  editData?.profilePic;
      temp_state["salutation"] = editData?.salutation;
      temp_state["email"] = editData?.email;
      temp_state["dob"] = editData?.dob;
      temp_state["gender"] = editData?.gender?.toLowerCase();
      // (typeof (gender) === "string")
      //   ? gender?.toLowerCase()
      //   : gender?.value?.toLowerCase();
      // temp_state["occupation"] =  editData?.occupation;
      // temp_state["preferred_language"] =  editData?.languages ? JSON.parse(editData?.languages) : [];
      temp_state["otherAddress"] = editData?.otherAddress ?? [];
      temp_state["idTypes"] = editData?.idTypes ?? [];
      temp_state["otherPhoneNumbers"] = editData?.otherPhoneNumbers ?? [];
      console.clear();
      console.log(typeof gender === "string");
      setCheckData(temp_state);
      setState(temp_state);
    }
  }, [editData]);

  const updateFieldState = (value, key) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const closeDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: "",
    });
  };

  // Identification Details
  const handleIdentificationSave = (idData) => {
    debugger;
    const idTypes = [...state["idTypes"]];
    if (!!idData?.id) {
      // Edit
      let updatedData = idTypes.map((idItem) => {
        if (idItem?.id === idData?.id) {
          return {
            ...idItem,
            ...idData,
          };
        }
        return idItem;
      });

      updateFieldState(updatedData, "idTypes");
    } else {
      idTypes.push({ ...idData, id: uuidV4() });
      updateFieldState(idTypes, "idTypes");
    }
  };

  const handleIdentificationDelete = (idData) => {
    closeDrawer();
    debugger;
    let idTypes = [...state["idTypes"]];
    idTypes = idTypes.filter((phoneNumber) => phoneNumber?.id !== idData?.id);
    updateFieldState(idTypes, "idTypes");
  };

  const getIDOptions = () => {
    const allOptions = [...dependencyOptions?.client_id_type];
    const usedOptions = [...state?.idTypes];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.id_type_name?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  // Contact Details
  // const handleContactSave = (contact) => {
  //   debugger;
  //   const otherPhoneNumbers = [...state["otherPhoneNumbers"]];
  //   if (!!contact?.id) {
  //     // Edit
  //     let updatedData = otherPhoneNumbers.map((contactData) => {
  //       if (contactData?.id === contact?.id) {
  //         return {
  //           ...contactData,
  //           ...contact,
  //         };
  //       }
  //       return contactData;
  //     });

  //     updateFieldState(updatedData, "otherPhoneNumbers");
  //   } else {
  //     otherPhoneNumbers.push({ ...contact, id: uuidV4() });
  //     updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
  //   }
  // };

  const handleContactDelete = (contact) => {
    closeDrawer();
    debugger;
    let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    otherPhoneNumbers = otherPhoneNumbers.filter(
      (phoneNumber) => phoneNumber?.id !== contact?.id
    );
    updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
  };

  const getContactOptions = () => {
    const allOptions = [...dependencyOptions?.phone_type];
    const usedOptions = [...state?.otherPhoneNumbers];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.mobile_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  const isSortingNeeded = (data) => {
    if (data?.length === 1) return false;
    if (data?.[0]?.isPrimary) return false;
    return true;
  };

  const handleContactSave = (contact) => {
    if (primaryContactId || state["otherPhoneNumbers"]?.length === 0) {
      if (!!contact?.id) {
        let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
        if (primaryContactId === contact?.id) {
          let updatedData = otherPhoneNumbers.map((contactData) => {
            if (contactData?.id === contact?.id) {
              return {
                ...contactData,
                ...contact,
              };
            }
            return contactData;
          });

          // Sort by primary
          updatedData = isSortingNeeded(updatedData)
            ? sortByPrimary(updatedData)
            : updatedData;
          updateFieldState(updatedData, "otherPhoneNumbers");
        } else {
          if (contact?.isPrimary) {
            otherPhoneNumbers = otherPhoneNumbers.map((phone) => ({
              ...phone,
              isPrimary: false,
            }));
          }

          let updatedData = otherPhoneNumbers.map((contactData) => {
            if (contactData?.id === contact?.id) {
              return {
                ...contactData,
                ...contact,
              };
            }
            return contactData;
          });

          // Sort by primary
          updatedData = isSortingNeeded(updatedData)
            ? sortByPrimary(updatedData)
            : updatedData;
          updateFieldState(updatedData, "otherPhoneNumbers");

          // Set PrimaryContact Id
          if (contact?.isPrimary) {
            setPrimaryContactId(contact?.id);
          }
        }
      } else {
        let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
        if (contact?.isPrimary) {
          otherPhoneNumbers = otherPhoneNumbers.map((phone) => ({
            ...phone,
            isPrimary: false,
          }));
        }
        const newContact = {
          ...contact,
          id: uuidV4(),
        };
        otherPhoneNumbers.push(newContact);

        // Sort by primary
        otherPhoneNumbers = isSortingNeeded(otherPhoneNumbers)
          ? sortByPrimary(otherPhoneNumbers)
          : otherPhoneNumbers;
        updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");

        // Set PrimaryContact Id
        if (newContact?.isPrimary) {
          setPrimaryContactId(newContact?.id);
        }
      }
    }
  };

  // Address Details
  const handleAddressSave = (address) => {
    debugger;
    const otherAddress = [...state["otherAddress"]];
    if (!!address?.id) {
      // Edit
      let updatedData = otherAddress.map((addressData) => {
        if (addressData?.id === address?.id) {
          return {
            ...addressData,
            ...address,
          };
        }
        return addressData;
      });

      updateFieldState(updatedData, "otherAddress");
    } else {
      otherAddress.push({ ...address, id: uuidV4() });
      updateFieldState(otherAddress, "otherAddress");
    }
  };

  const handleAddressDelete = (address) => {
    closeDrawer();
    debugger;
    let otherAddress = [...state["otherAddress"]];
    otherAddress = otherAddress.filter(
      (addressData) => addressData?.id !== address?.id
    );
    updateFieldState(otherAddress, "otherAddress");
  };

  const getAddressOptions = () => {
    const allOptions = [...dependencyOptions?.address_type];
    const usedOptions = [...state?.otherAddress];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.address_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  // Validation Function
  const validate = () => {
    let error = {};
    let errorText = {};
    fieldsToValidate.map((field) => {
      switch (field) {
        case "relationship": {
          if (state["relationship"] === "") {
            error[field] = true;
            errorText[field] = "Relationship Type should not be empty";
          }
          break;
        }
        case "salutation": {
          if (state["salutation"] === "") {
            error[field] = true;
            errorText[field] = "Salutation should not be empty";
          }
          break;
        }
        case "name": {
          if (state["name"] === "") {
            error[field] = true;
            errorText[field] = "Name should not be empty";
          }
          break;
        }
        case "otherPhoneNumbers": {
          if (state["isEmergencyContact"]) {
            if (state["otherPhoneNumbers"].length < 1) {
              error[field] = true;
              errorText[field] = "Atleast one is mandatory";
            }
          }
          break;
        }
        default:
          return "";
      }
      return field;
    });
    return { error: Object.keys(error)?.length > 0, errorText };
  };

  const handleSubmit = () => {
    const { error, errorText } = validate();

    setErrorText(errorText);

    if (error) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Please fill required fields",
      });
    } else {
      // Edit Data
      if (!isAdd()) {
        // alert("Handle Edit")
        let req_data = [...props?.data?.familyMembers];
        let res_data = [];
        if (state["isEmergencyContact"]) {
          if (
            req_data.filter((x) => x.isEmergencyContact === true).length > 0
          ) {
            req_data.map((x) =>
              res_data.push({ ...x, isEmergencyContact: false })
            );
            req_data = [...res_data];

            if (editData?.isEmergencyContact) {
              console.clear();
              let pop_data = req_data.filter((x) => x.id === editData.id)[0];
              console.log(pop_data);
              const index = req_data.indexOf(pop_data);
              console.log(index);
              if (index > -1) {
                req_data.splice(index, 1);
              } else {
                req_data.pop();
              }
              console.log(req_data);
              req_data?.push(state);
              console.log(req_data);

              let res = {
                familyMembers: req_data,
              };

              props?.handleUpdate(res);
              props?.resetScreen();
            } else {
              props.dialog.setDialog({
                open: true,
                title: "Confirmation",
                content:
                  "Are you sure want to make this as a Emergency Contact ?",
                positiveButtonProps: {
                  hide: false,
                },
                negativeButtonProps: {
                  hide: false,
                },
                onAccept: () => {
                  console.clear();
                  let pop_data = req_data.filter(
                    (x) => x.id === editData.id
                  )[0];
                  console.log(pop_data);
                  const index = req_data.indexOf(pop_data);
                  console.log(index);
                  if (index > -1) {
                    req_data.splice(index, 1);
                  } else {
                    req_data.pop();
                  }
                  console.log(req_data);
                  req_data?.push(state);
                  console.log(req_data);

                  let res = {
                    familyMembers: req_data,
                  };

                  props?.handleUpdate(res);
                  props?.resetScreen();
                },
              });
            }
          } else {
            console.clear();
            let pop_data = req_data.filter((x) => x.id === editData.id)[0];
            console.log(pop_data);
            const index = req_data.indexOf(pop_data);
            console.log(index);
            if (index > -1) {
              req_data.splice(index, 1);
            } else {
              req_data.pop();
            }
            console.log(req_data);
            req_data?.push(state);
            console.log(req_data);

            let res = {
              familyMembers: req_data,
            };

            props?.handleUpdate(res);
            props?.resetScreen();
          }
        } else {
          console.clear();
          let pop_data = req_data.filter((x) => x.id === editData)[0];
          console.log(pop_data);
          const index = req_data.indexOf(pop_data);
          console.log(index);
          if (index > -1) {
            req_data.splice(index, 1);
          } else {
            req_data.pop();
          }
          console.log(req_data);
          req_data?.push(state);
          console.log(req_data);

          let res = {
            familyMembers: req_data,
          };

          props?.handleUpdate(res);
          props?.resetScreen();
        }
      } else {
        // Add New Relation
        // alert("Handle Add");
        let req_data = [...props?.data?.familyMembers];
        let res_data = [];
        if (state["isEmergencyContact"]) {
          if (
            req_data.filter((x) => x.isEmergencyContact === true).length > 0
          ) {
            req_data.map((x) =>
              res_data.push({ ...x, isEmergencyContact: false })
            );
            req_data = [...res_data];
            props.dialog.setDialog({
              open: true,
              title: "Confirmation",
              content: "Are you sure want to make this as a primary contact ?",
              positiveButtonProps: {
                hide: false,
              },
              negativeButtonProps: {
                hide: false,
              },
              onAccept: () => {
                req_data?.push({ ...state, id: uuidV4() });
                let res = {
                  familyMembers: req_data,
                };
                props?.handleUpdate(res);
                props?.resetScreen();
              },
            });
          } else {
            req_data?.push({ ...state, id: uuidV4() });
            let res = {
              familyMembers: req_data,
            };
            props?.handleUpdate(res);
            props?.resetScreen();
          }
        } else {
          req_data?.push({ ...state, id: uuidV4() });
          let res = {
            familyMembers: req_data,
          };
          props?.handleUpdate(res);
          props?.resetScreen();
        }
      }
    }
  };

  const handleCancel = () => {
    // alert(isEqual(state, checkData))
    if (isEqual(state, checkData)) {
      props.resetScreen();
    } else {
      props.dialog.setDialog({
        open: true,
        title: "Confirmation",
        content: "Are you sure, do you want to discard the changes made ?",
        positiveButtonProps: {
          hide: false,
        },
        negativeButtonProps: {
          hide: false,
        },
        onAccept: () => props.resetScreen(),
      });
    }
  };

  // Family Members Handler
  const saveFamilyMembers = (deAllocatedMembers = []) => {
    const members =
      deAllocatedMembers?.length > 0 ? deAllocatedMembers : familyMembers;
    // If State object has id, then the mode is edit else add
    if (state?.id) {
      const otherMembers = members.filter((member) => member?.id !== state?.id);
      setFamilyMembers(sortByEmergency([...otherMembers, { ...state }]));
    } else {
      console.log("===================================================");
      console.log(members);
      debugger;
      // setFamilyMembers((prevFamilyMembers) => [
      //   ...prevFamilyMembers,
      //   { ...state, id: uuidV4() },
      // ]);

      let res = sortByEmergency([...members, { ...state, id: uuidV4() }]);
      setFamilyMembers(res);

      props?.handleUpdate({ familyMembers: res });
      props?.resetScreen();
    }
    setState({ ...initialState });
  };

  const emergencyContactArray = () => {
    return (
      familyMembers.filter(
        (member) =>
          member?.isEmergencyContact === true && state?.id !== member?.id
      )?.length > 0
    );
  };

  const checkEmergencyContactConflict = (isEdit) => {
    console.log("state?.isEmergencyContact === true");
    // Check only if the isEmergencyContact State is true
    if (state?.isEmergencyContact === true) {
      console.log("familyMembers?.length > 0");
      // Check if there is any family member
      if (familyMembers?.length > 0) {
        console.log("emergencyContactArray()");
        // Check if any other family member has emergency contact Checked
        // If any other member is emergency contact
        if (emergencyContactArray()) {
          console.log("props.dialog.setDialog");

          const deAllocateEmergencyAndUpdateEmergency = () => {
            debugger;
            const deAllocated = familyMembers.map((member) => ({
              ...member,
              isEmergencyContact:
                state?.id === member?.id ? state?.isEmergencyContact : false,
            }));
            // setFamilyMembers(deAllocated);

            saveFamilyMembers(deAllocated);
          };

          // Ask Prompt
          props.dialog.setDialog({
            open: true,
            renderContent: (handleClose, handleAccept) => (
              <ChangeEmergencyContactPrompt
                handleClose={handleClose}
                handleAccept={handleAccept}
              />
            ),
            positiveButtonProps: {
              hide: true,
            },
            negativeButtonProps: {
              hide: true,
            },
            onAccept: () => deAllocateEmergencyAndUpdateEmergency(),
            sx: {
              Dialog: {
                "&.MuiDialog-paper": {
                  borderRadius: "12px",
                },
              },
              DialogContent: {
                "&.MuiDialogContent-root": {
                  padding: 0,
                },
              },
            },
          });
          return false;
        } else {
          // If there is no emergency contact, then allow user to save family member without prompt
          return true;
        }
      } else {
        return true;
      }
    }

    // return true otherwise
    return true;

    // // Check if the value is true
    // if (value === true) {
    //   // Check if there is any family member
    //   if (familyMembers?.length > 0) {
    //     // Check if any other family member has emergency contact Checked
    //     const emergencyContactArray = familyMembers.filter(
    //       (member) => member?.isEmergencyContact === true
    //     );

    //     // If any other member is emergency contact
    //     if (emergencyContactArray?.length > 0) {
    //       const deAllocateEmergencyAndUpdateEmergency = () => {
    //         updateFieldState(value, key);
    //       };

    //       // Ask Prompt
    //       props.dialog.setDialog({
    //         open: true,
    //         renderContent: (handleClose, handleAccept) => (
    //           <ChangeEmergencyContactPrompt
    //             handleClose={handleClose}
    //             handleAccept={handleAccept}
    //           />
    //         ),
    //         positiveButtonProps: {
    //           hide: true,
    //         },
    //         negativeButtonProps: {
    //           hide: true,
    //         },
    //         onAccept: deAllocateEmergencyAndUpdateEmergency,
    //         sx: {
    //           Dialog: {
    //             "&.MuiDialog-paper": {
    //               borderRadius: "12px",
    //             },
    //           },
    //           DialogContent: {
    //             "&.MuiDialogContent-root": {
    //               padding: 0,
    //             },
    //           },
    //         },
    //       });
    //     } else {
    //       // If there is no emergency contact, then allow user to set emergency contact without prompt
    //       updateFieldState(value, key);
    //     }
    //   } else {
    //     // FamilyMembers array is empty, so no issues in updating it.
    //     updateFieldState(value, key);
    //   }
    // } else {
    //   // Value is false, so no issues in updating it.
    //   updateFieldState(value, key);
    // }
  };

  const saveMemberClick = () => {
    const { error, errorText } = validate();

    setErrorText(errorText);

    if (error) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Please fill required fields",
      });
    } else {
      if (checkEmergencyContactConflict(!!state?.id)) {
        saveFamilyMembers();
      }
    }
  };

  const onEditClick = (memberId) => {
    const prefillEditData = () => {
      const editMember = familyMembers.filter(
        (member) => member?.id === memberId
      )?.[0];
      setState({ ...editMember });
    };

    if (isEqual(state, initialState)) {
      prefillEditData();
    } else {
      props.dialog.setDialog({
        open: true,
        renderContent: (handleClose, handleAccept) => (
          <EditPrefillPrompt
            handleClose={handleClose}
            handleAccept={handleAccept}
          />
        ),
        positiveButtonProps: {
          hide: true,
        },
        negativeButtonProps: {
          hide: true,
        },
        onAccept: prefillEditData,
        sx: {
          Dialog: {
            "&.MuiDialog-paper": {
              borderRadius: "12px",
            },
          },
          DialogContent: {
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          },
        },
      });
    }
  };

  const onDeleteMember = (memberId) => {
    const deleteMember = () => {
      setFamilyMembers((prevFamilyMembers) => {
        return prevFamilyMembers.filter((member) => member?.id !== memberId);
      });
    };

    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <DeletePrompt handleClose={handleClose} handleAccept={handleAccept} />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: deleteMember,
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const onClearClick = () => {
    const clearState = () => {
      return setState({ ...initialState });
    };

    if (isEqual(state, initialState)) {
      return;
    } else {
      props.dialog.setDialog({
        open: true,
        renderContent: (handleClose, handleAccept) => (
          <ClearPrompt handleClose={handleClose} handleAccept={handleAccept} />
        ),
        positiveButtonProps: {
          hide: true,
        },
        negativeButtonProps: {
          hide: true,
        },
        onAccept: clearState,
        sx: {
          Dialog: {
            "&.MuiDialog-paper": {
              borderRadius: "12px",
            },
          },
          DialogContent: {
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          },
        },
      });
    }
  };

  return (
    // <DrawerSkeleton
    //   renderTitle={() => (
    //     <Typography
    //       sx={{ flex: 1, textAlign: "center", fontWeight: 500, ml: "-44px" }}
    //     >
    //       Edit Family Members Details
    //     </Typography>
    //   )}
    //   handleClose={handleClose}
    //   handleConfirm={handleSubmit}
    //   buttonText={"Update"}
    // >
    <Box>
      {/* <Stack sx={{ px: 4, py: 2, bgcolor: "common.white" }}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            {breadcrumbs.map((breadcrumb, index) => {
              if (breadcrumbs.length - 1 === index) {
                return (
                  <Typography color="text.primary">{breadcrumb.label}</Typography>
                );
              }
              return (
                <Link
                  underline="none"
                  color="inherit"
                  onClick={() => {
                    props.resetScreen();
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {breadcrumb.label}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Stack> */}

      <Grid
        container
        flexWrap="wrap-reverse"
        sx={{ border: "1px solid #ECECEC", mb: 8 }}
      >
        <Grid container xs={12} md={12} style={{ backgroundColor: "white" }}>
          <IconButton>
            <KeyboardArrowLeftIcon onClick={() => handleCancel()} />
          </IconButton>
          <Typography
            sx={{ m: "auto", p: 2, textAlign: "center", fontWeight: 500 }}
          >
            {isAdd()
              ? "Add Family Member Details"
              : "Edit Family Member Details"}
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ boxShadow: "rgb(233 235 239) 0px 7px 7px 0px" }}
            >
              <StyledBox>
                <CustomSelect
                  // id={"my order status filter"}
                  sx={{ mt: 1, mb: 2 }}
                  // size="small"
                  options={dependencyOptions?.relationship}
                  loading={false}
                  value={state["relationship"]}
                  label={"Relationship Type"}
                  onChange={(e, value) => {
                    updateFieldState(value, "relationship");
                  }}
                  multiple={false}
                  InputProps={{
                    error: errorText?.relationship ? true : false,
                    helperText: errorText?.relationship,
                  }}
                />

                <CustomTextfieldWithSelect
                  error={false}
                  helperText=""
                  sx={{ mt: 1, mb: 2, gap: 1 }}
                  // size="small"
                  label={"Relation Name"}
                  textFieldProps={{
                    id: "",
                    name: "name",
                    // placeholder: "Name",
                    required: true,
                  }}
                  selectProps={{
                    id: "",
                    name: "Name",
                  }}
                  selectOptions={dependencyOptions?.salutation}
                  fullWidth
                  selectValue={state["salutation"]}
                  value={state["name"]}
                  onSelectChange={(e, value) => {
                    let data = dependencyOptions?.salutation?.filter(
                      (x) => x?.value === value?.value
                    )?.[0];
                    updateFieldState(data, "salutation");
                  }}
                  onChange={(e) => updateFieldState(e.target.value, "name")}
                  error={
                    errorText?.salutation || errorText?.name ? true : false
                  }
                  helperText={errorText?.salutation || errorText?.name}
                />

                <CustomTextfield
                  fullWidth
                  // size="small"
                  sx={{ mb: 2 }}
                  label="Primary Email Address"
                  value={state["email"]}
                  onChange={(e) => updateFieldState(e.target.value, "email")}
                />
                <CustomDatePicker
                  fullWidth
                  label={"DOB"}
                  value={state["dob"]}
                  disableFuture
                  // size="small"
                  // sx={{ marginY: 1, color: "primary.main" }}
                  InputProps={{ sx: { mb: 1 }, fullWidth: true }}
                  onChange={(newDate) =>
                    updateFieldState(moment(newDate).toISOString(), "dob")
                  }
                />

                <StyledToggleButtonGroup
                  id={"Gender"}
                  label={"Gender"}
                  // size="small"
                  sx={{ marginY: 1 }}
                  options={genderOptions}
                  data={{ value: state["gender"] }}
                  onChange={(e, value) => {
                    //   console.log(value)
                    updateFieldState(value, "gender");
                  }}
                />
              </StyledBox>
            </Grid>
            <Divider orientation={"vertical"} flexItem />
            <Grid container item xs={12} md={5} sx={{ bgcolor: "#F2F4F8" }}>
              <Stack sx={{ width: "100%" }}>
                <MultipleIdentificationMobile
                  data={state?.idTypes}
                  options={getIDOptions()}
                  required={false}
                  handleSave={handleIdentificationSave}
                  handleDelete={handleIdentificationDelete}
                  helperText={errorText?.idTypes}
                  sx={{ mb: 2 }}
                />
              </Stack>

              <Stack sx={{ width: "100%" }}>
                <MultipleContactMobile
                  contacts={state?.otherPhoneNumbers}
                  options={getContactOptions()}
                  primaryContactId={primaryContactId}
                  required={!!state["isEmergencyContact"]}
                  parentState={state}
                  handleSave={handleContactSave}
                  handleDelete={handleContactDelete}
                  helperText={errorText?.otherPhoneNumbers}
                  sx={{ mb: 2 }}
                />
              </Stack>

              <Stack sx={{ width: "100%" }}>
                <MultipleAddressMobile
                  data={state?.otherAddress}
                  options={getAddressOptions()}
                  required={false}
                  handleSave={handleAddressSave}
                  handleDelete={handleAddressDelete}
                  helperText={errorText?.otherAddress}
                  sx={{ mb: 2 }}
                />
              </Stack>

              <StyledBox sx={{ width: "100%" }}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="success"
                        checked={state["isEmergencyContact"]}
                        onChange={(e) =>
                          updateFieldState(
                            e.target.checked,
                            "isEmergencyContact"
                          )
                        }
                      />
                    }
                    label="Same for emergency contact details"
                    sx={{ mb: 2, color: "text.secondary" }}
                  />
                </Box>

                {/* FORM CTA */}
                {/* <Stack
                    flexDirection="row"
                    gap={1}
                    sx={{ pb: { xs: 1, md: 0 } }}
                  >
                    <Box sx={{ minWidth: "100px" }}>
                      <Button
                        sx={{
                          borderRadius: 1.5,
                          lineHeight: "normal",
                          textTransform: "unset",
                          py: 1,
                          px: 2,
                          // borderColor: "error.custom",
                          color: "error.custom",
                          "&:hover": {
                            background: (theme) =>
                              alpha(theme.palette.error.custom, 0.04),
                            // borderColor: "error.custom",
                          },
                        }}
                        variant="text"
                        size="small"
                        fullWidth
                        onClick={onClearClick}
                        disabled={isEqual(state, initialState)}
                      >
                        Clear
                      </Button>
                    </Box>
                    <Box sx={{ minWidth: "100px" }}>
                      <CustomButton
                        sx={{
                          borderRadius: 1.5,
                          py: 1,
                          px: 2,
                          lineHeight: "normal",
                          textTransform: "unset",
                          height: "100%",
                        }}
                        variant="contained"
                        size="small"
                        fullWidth
                        onClick={saveMemberClick}
                        disabled={isEqual(state, initialState)}
                      >
                        Save
                      </CustomButton>
                    </Box>
                  </Stack> */}
              </StyledBox>
            </Grid>
          </Grid>
        </Grid>

        {/* Family Member Details View */}
        {/* <Grid item xs={12} md={4}>
            <Box
              sx={{
                ml: 0.75,
                bgcolor: "white",
                height: "100%",
                py: 1.5,
                px: 1.25,
              }}
            >
              <FamilyMembersDetailSection
                members={familyMembers}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteMember}
              />
            </Box>
          </Grid> */}
      </Grid>

      {/* {belowSm ? (
          <Box sx={{ position: "sticky", right: 0, left: 0, bottom: 0 }}>
            <CustomButton
              fullWidth
              sx={{ borderRadius: 0, py: 2 }}
              endIcon={<RightArrowStripesIcon />}
              loading={props.continueLoading}
              onClick={handleSubmit}
            >
              Proceed
            </CustomButton>
          </Box>
        ) : ( */}
      <Grid item xs={12}>
        <Box sx={{ pt: 0.5, bgcolor: "grey.border" }}>
          <Box sx={{ bgcolor: "common.white" }}>
            <Stack
              sx={{
                // width: "100%",
                flexDirection: { sm: "row" },
                alignItems: { sm: "flex-end" },
                justifyContent: { sm: "flex-end" },
                gap: 2,
              }}
            >
              {/* <Box>
                    <CustomButton
                      sx={{
                        borderRadius: 2,
                        py: 1.5,
                        borderColor: "grey.300",
                        textTransform: "capitalize",
                      }}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </CustomButton>
                  </Box> */}
              {/* <Box> */}
              <CustomButton
                sx={{
                  borderRadius: 0,
                  position: "absolute",
                  bottom: 0,
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                variant="contained"
                fullWidth
                onClick={handleSubmit}
              >
                {isAdd() ? "Save" : "Update"}
              </CustomButton>
              {/* </Box> */}
            </Stack>
          </Box>
        </Box>
      </Grid>
      {/* )} */}
    </Box>
    // {/* </DrawerSkeleton> */}
  );
};

export default withDialog(withDrawer(withSnackBar(EditFamilyMembersMobile)));
