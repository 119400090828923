import React from "react";
import signup from "../../assets/icons/signup.svg";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { actions, utils } from "tanyacare-middleware";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { dontShowInMenuRoutes, routes } from "../../router/routes";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { carouselData } from "./carouselData";
import logo from "../../assets/icons/logo.png";
import playstore from "../../assets/icons/playstore.png";
import huaweiStore from "../../assets/icons/playstore_1.svg";
import Typography from "@mui/material/Typography";
import Carousel from "./carousel";
import { AuthContext } from "../../contexts";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Hidden,
  Paper,
  Stack,
} from "@mui/material";
import { withSnackBar, withDialog } from "../../HOC's";
import {
  snackProps,
  checkUrlFromELPRedirection,
  getUrlParam,
  getSearchParamsString,
  SOURCE_TYPE,
} from "../../utils";
import {
  isTrialAccountType,
  storeTrialPopupState,
  tanyacareContactInfo,
  mobileAppStoreLinks,
} from "../../utils/commons";
import packJson from "../../../package.json";
import { Link } from "react-router-dom";

import { CustomButton } from "../../components";
import {
  EmailFilledIcon,
  MobileFilledIcon,
  RightArrowStripesIcon,
} from "../../assets";
import Divider from "@mui/material/Divider";
import { OnboardFormCard } from "./components";

const cardWidth = "400px";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%" },
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = (theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f0f2f6",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      background: "#ffffff",
    },
  },
  cardMaxWidth: {
    maxWidth: cardWidth,
  },
  rememberMeRoot: {
    marginRight: "8px !important",
  },
  rememberMe: {
    fontSize: "0.825rem !important",
    color: theme.palette.text.secondary,
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      password: "",
      remember: false,
      showPassword: false,
      modal: true,
      error_txt: "",
      error: false,
      checked: false,
      searchParams: "",
    };
  }
  handleClose = () => {
    this.setState({
      ...this.state,
      modal: false,
    });
  };

  handleClickShowPassword = (key) => {
    this.setState({
      ...this.state,
      [key]: !this.state[key],
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  initializePopupStorage = () => {
    // Set trial Popup local storage state
    isTrialAccountType() && storeTrialPopupState(true);
  };

  // Lifecycle Methods
  componentDidMount() {
    // ;
    this.initializePopupStorage();
    // Save Search Params
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      this.setState({
        searchParams: urlParams,
      });
    }
    // Check if the userrole is present in the loginState
    if (this.props?.loginState?.data?.user_role) {
      if (this.props?.location?.state?.from?.pathname) {
        if (!this?.context?.auth?.refresh) {
          this.context.setAuth({ ...this.context.auth, refresh: true });
          this.props.history.push(routes.home.pathName);
        } else {
          this.initializePopupStorage();
          let generateUrl =
            this.props.location.state.from.search?.length > 0
              ? this.props.location.state.from.pathname +
                this.props.location.state.from.search
              : this.props.location.state.from.pathname;
          this.props.history.push(generateUrl);
        }
      } else {
        this.props.history.push(routes.home.pathName);
      }
    }

    // this.checkisChecked()
  }

  checkisChecked() {
    var isChecked = localStorage.getItem("tcc_ic");

    if (isChecked) {
      var credentials = JSON.parse(
        utils.decrypt_local(localStorage.getItem("tcc"))
      );
      // console.log(JSON.parse(utils.decrypt_local(localStorage.getItem("tcc"))))
      this.setState({
        userId: credentials.userId,
        password: credentials.password,
        checked: true,
      });
    }
  }

  executeTrialPopup = () => {
    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      title: (
        <Typography variant="h6" align="center">
          Trial Acccount
        </Typography>
      ),
      content: (
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <Typography variant="body1" align="center">
            For any enquiry, feel free to contact us <br />
            <Typography
              variant="body1"
              sx={{ color: "secondary.main", display: "inline" }}
            >
              {tanyacareContactInfo.phone}
            </Typography>{" "}
            or{" "}
            <Typography
              variant="body1"
              sx={{ color: "secondary.main", display: "inline" }}
            >
              {tanyacareContactInfo.mail}
            </Typography>
          </Typography>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                storeTrialPopupState(false);
                this.props.dialog.setDialog({
                  ...this.props.dialog,
                  open: false,
                });
              }}
            >
              Got it
            </Button>
          </div>
        </div>
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    // ;
    if (!nextProps?.loginState?.error && !nextProps?.loginState?.loading) {
      if (nextProps?.loginState?.data?.user_role) {
        isTrialAccountType() && this.executeTrialPopup();
        if (nextProps?.location?.state?.from?.pathname) {
          let generateUrl =
            this.props.location.state.from.search?.length > 0
              ? nextProps.location.state.from.pathname +
                this.props.location.state.from.search
              : nextProps.location.state.from.pathname;
          this.props.history.push(generateUrl);
        } else {
          this.props.history.push(routes.home.pathName);
        }
      }
    }
    return true;
  }
  changeState = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };
  //SIGN IN
  signIn = async (e) => {
    // e.preventDefault()
    if (this.state?.userId && this.state?.password) {
      const _piload = {
        email: this.state?.userId,
        password: this.state?.password,
        device: "client_app_web_roles",
      };
      this.setState({ loading: true });
      let login = await this.props.LOGIN_AUTH(_piload);

      if (!login?.payload?.data?.error) {
        // ADDING DATA FROM ELP
        let elpProducts = checkUrlFromELPRedirection(this.state.searchParams);
        //  alert(JSON.stringify(elpProducts))
        this.setState({ loading: false });

        if (Array.isArray(elpProducts) && elpProducts?.length > 0) {
          let _obj = {
            isClientApp: true,
            isCart: false,
            resources: elpProducts,
            enquire: { remarks: "" },
          };

          let res_data = await this.props.ENQUIRY_PAGE_ADD({ ..._obj });
          if (!res_data?.payload?.data?.error) {
            this.props.snack.setSnack({
              open: true,
              message: "Enquiry Added Successfully!",
              duration: 6000,
              severity: snackProps.severity.success,
              setSnack: () => null,
            });
            this.props.history.push(routes.enquiries.pathName);
          } else {
            this.props.snack.setSnack({
              open: true,
              message: "Unable to Add Enquiry!",
              duration: 6000,
              severity: snackProps.severity.error,
              setSnack: () => null,
            });
          }
        } else {
          // if (this.props?.location?.state?.from?.pathname) {
          // this.props.history.push(this.props.location.state.from.pathname);
          // } else {
          this.props.history.push(routes.home.pathName);
          // }
        }
      } else {
        this.setState({
          error_txt: login?.payload?.data?.error,
          loading: false,
        });
      }
    } else {
      this.props.snack.setSnack({
        open: true,
        message: "Please enter user id and password",
        severity: snackProps.severity.error,
      });
    }
  };
  //SIGN UP
  signUp = () => {
    let obj = { pathname: dontShowInMenuRoutes.signUp.pathName };
    let products = getSearchParamsString(
      this.state.searchParams,
      "enqProducts"
    );
    if (products) {
      obj.search = `enqProducts=${products}`;
    }
    this.props.history.push(obj);
  };
  //FORGOT USER ID ROUTE
  forgotUserId = () => {
    this.props.history.push(dontShowInMenuRoutes.forgotUserId.pathName);
  };
  //FORGOT USER ID ROUTE
  forgotPassword = () => {
    this.props.history.push({
      pathname: dontShowInMenuRoutes.forgotPassword_.pathName,
    });
  };

  handleRememberMe = () => {
    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        if (this.state.checked) {
          localStorage.setItem("tcc_ic", true);
          localStorage.setItem(
            "tcc",
            utils.encrypt_local(
              JSON.stringify({
                userId: this.state.userId,
                password: this.state.password,
              })
            )
          );
        } else {
          localStorage.setItem("tcc_ic", false);
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { error, message: errorMessage } = this.props?.loginState;
    const {
      loading: enquiryAddLoading,
      // error: enquiryAddError,
      // message: enquiryAdderrorMessage,
    } = this.props?.enquiryAdd;

    return (
      <div style={{ overflowX: "hidden" }}>
        <>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Left Pane */}
            <Grid
              item
              alignSelf="stretch"
              xs={0}
              sm={0}
              md={6}
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                background: "white",
                minHeight: "100vh",
              }}
            >
              <Stack
                sx={{
                  pt: 2,
                  pr: 2,
                  pb: 5,
                  pl: 5,
                  minHeight: "100vh",
                  height: "100%",
                }}
              >
                {/* Logo Bar with Contact info */}

                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    component="img"
                    alt="logo"
                    src={logo}
                    sx={{ width: "160px" }}
                  />
                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Stack flexDirection="row" alignItems="center" gap={0.5}>
                      <EmailFilledIcon fontSize="small" />
                      <Typography
                        variant="body2"
                        sx={{ color: "primary.main" }}
                      >
                        {tanyacareContactInfo.mail}
                      </Typography>
                    </Stack>
                    <Stack flexDirection="row" alignItems="center" gap={0.5}>
                      <MobileFilledIcon fontSize="small" />
                      <Typography
                        variant="body2"
                        sx={{ color: "primary.main" }}
                      >
                        {tanyacareContactInfo.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                {/* <div>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item md={7}>
                      <img alt="logo" src={logo} />
                    </Grid>
                    <Grid item md={3}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <img alt="logo" src={mail} />
                        <div
                          style={{
                            color: "#0063e7",
                            fontSize: "12px",
                            paddingLeft: "5px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {tanyacareContactInfo.mail}
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <img alt="logo" src={phone} />
                        <span
                          style={{
                            color: "#0063e7",
                            fontSize: "12px",
                            paddingLeft: "5px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {tanyacareContactInfo.phone}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div> */}

                {/* Promotion Carousel */}
                <Stack
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ flex: 1 }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Carousel carouselData={carouselData.data} />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>

            {/* Right Pane */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  bgcolor: { xs: "common.white", md: "#f0f2f6" },
                  minHeight: "100vh",
                  pt: { md: 2.5 },
                }}
              >
                {/* Main Card */}
                <OnboardFormCard>
                  <Stack>
                    {/* sx={{ p: { xs: 0, sm: 2.5, md: 0 } }} */}
                    <Hidden mdUp>
                      <Box sx={{ margin: "auto", width: "50%" }}>
                        <img
                          src={signup}
                          alt="signup"
                          style={{ width: "150px" }}
                        />
                      </Box>
                    </Hidden>
                    <Typography
                      variant="h6"
                      align="center"
                      fontWeight="bold"
                      sx={{ mt: 1.5 }}
                    >
                      Welcome to tanya.care
                    </Typography>

                    <Typography
                      sx={{ mt: 0.75 }}
                      color="textSecondary"
                      align="center"
                      fontWeight="400"
                    >
                      Sign-in to your account using your user id and password.
                    </Typography>

                    {/* Form Fields */}
                    <Stack sx={{ mt: 4, mb: 3 }} gap={2.5}>
                      <Box>
                        <TextField
                          id="outlined-basic"
                          fullWidth={true}
                          label="User ID"
                          variant="outlined"
                          value={this.state.userId}
                          onChange={(e) =>
                            this.changeState("userId", e.target.value)
                          }
                        />
                      </Box>
                      <Box>
                        <FormControl fullWidth={true} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.props.password}
                            onChange={(e) =>
                              this.changeState("password", e.target.value)
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    this.handleClickShowPassword("showPassword")
                                  }
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                          {this.state.error && (
                            <span>{this.state?.error_txt}</span>
                          )}
                        </FormControl>
                        <Stack justifyContent="flex-end" alignItems="flex-end">
                          <FormGroup sx={{ mt: 0.5 }}>
                            <FormControlLabel
                              classes={{
                                root: classes.rememberMeRoot,
                                label: classes.rememberMe,
                              }}
                              control={
                                <Checkbox
                                  checked={this.state.checked}
                                  onChange={() => this.handleRememberMe()}
                                  size="small"
                                />
                              }
                              label="Remember Me"
                            />
                          </FormGroup>
                        </Stack>
                      </Box>
                    </Stack>

                    <Stack>
                      {/* Error Message Section */}
                      <Box>
                        {error && (
                          <FormHelperText
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                            error
                          >
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </Box>

                      {/* SignIn Button */}
                      <Box>
                        <CustomButton
                          sx={{
                            textTransform: "none",
                            py: 1.5,
                            borderRadius: 2,
                          }}
                          variant="contained"
                          fullWidth
                          endIcon={<RightArrowStripesIcon />}
                          disabled={this.state.loading || enquiryAddLoading}
                          onClick={(e) => this.signIn(e)}
                        >
                          {enquiryAddLoading && "Enquiry Adding..."}
                          {this.state.loading && "Signing In..."}
                          {!enquiryAddLoading &&
                            !this.state.loading &&
                            "Sign In"}
                        </CustomButton>
                      </Box>

                      {/* Forgot password/ User Id Section */}
                      <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{ mt: 0.75 }}
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="a"
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={() => this.forgotUserId()}
                        >
                          Forgot User ID?
                        </Typography>

                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="a"
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={() => this.forgotPassword()}
                        >
                          Forgot Password?
                        </Typography>
                      </Stack>

                      {/* Not yet registered Section */}
                      <Typography
                        sx={{ mt: 2.5 }}
                        fontWeight="400"
                        align="center"
                        variant="body2"
                      >
                        Not yet registered?{" "}
                        <Box
                          component="a"
                          sx={{
                            color: (theme) =>
                              `${theme.palette.primary.main} !important`,
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={() => this.signUp()}
                        >
                          Sign Up
                        </Box>
                      </Typography>
                    </Stack>
                  </Stack>
                </OnboardFormCard>

                {/* Get the App Section & TOM/PP section */}
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{
                    mt: { xs: 1, sm: 1.5 },
                    width: "100%",
                    px: { xs: 2, sm: 4, md: 1 },
                    columnGap: 1,
                    rowGap: { xs: 1.5, sm: 1 },
                  }}
                  className={classes.cardMaxWidth}
                  flexWrap="wrap"
                >
                  {/* Apps */}
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    sx={{ m: { xs: "0 auto", sm: 0 } }}
                  >
                    <Typography variant="body2" fontWeight="400">
                      Get the app!
                    </Typography>
                    <a
                      href={mobileAppStoreLinks.playStore}
                      target="_blank"
                      className={classes.link}
                    >
                      <Box
                        component="img"
                        src={playstore}
                        alt="playstore icon"
                        sx={{ cursor: "pointer" }}
                      />
                    </a>
                    <a
                      href={mobileAppStoreLinks.huaweiStore}
                      target="_blank"
                      className={classes.link}
                    >
                      <Box
                        component="img"
                        src={huaweiStore}
                        alt="huawei store icon"
                        sx={{ cursor: "pointer" }}
                      />
                    </a>
                    {/* <img alt="playstore icon" src={playstore} />
                    <img alt="playstore icon 1" src={playstore_1} /> */}
                  </Stack>
                  {/* Terms of Use & Privacy Policy */}
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    divider={<Divider orientation="vertical" flexItem />}
                    sx={{ m: { xs: "0 auto", sm: 0 } }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="400"
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      href="https://www.tanya.care/terms-of-use/"
                    >
                      Terms of Use
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="400"
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      href="https://www.tanya.care/privacy-policy/"
                    >
                      Privacy Policy
                    </Typography>
                  </Stack>
                </Stack>

                {/* Copyright & version */}
                <Stack
                  flexDirection="row"
                  gap={2}
                  justifyContent="center"
                  sx={{ mt: 3, mb: 2, width: "100%", px: 1 }}
                  className={classes.cardMaxWidth}
                  flexWrap="wrap"
                >
                  <Stack
                    flexDirection="row"
                    gap={2}
                    divider={<Divider orientation="vertical" flexItem />}
                    sx={{ m: { xs: "0 auto", sm: 0 } }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      &copy; tanya.care
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      All Rights Reserved
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ m: { xs: "0 auto", sm: 0 } }}
                  >{`v${packJson.version}`}</Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          {/* Promotion Carousel - Below md (includes xs, sm) */}
          {!error && (
            <Grid
              item
              xs={12}
              sm={12}
              md={0}
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                background: "white",
                // minHeight: "100vh",
              }}
            >
              {/* <Modal
           sx={{
             display: { xs: "block", sm: "block", md: "none" },
             width: "auto",
           }}
           open={this.state.modal}
           onClose={this.handleClose}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
         > */}
              <Dialog
                sx={{
                  display: { xs: "block", sm: "block", md: "none" },
                  width: "auto",
                }}
                open={this.state.modal}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                {/* <Box sx={style}> */}
                <DialogContent>
                  <Carousel
                    carouselData={carouselData.data}
                    onClose={this.handleClose}
                    close={true}
                  />
                </DialogContent>
                {/* </Box> */}
              </Dialog>
              {/* </Modal> */}
            </Grid>
          )}
        </>
      </div>
    );
  }
}

SignIn.contextType = AuthContext;

const mapStateToProps = (state) => ({
  // sign_in: state?.loginSlice?.signIn,
  loginState: state?.authState?.loginAuth,
  enquiryAdd: state?.enquiry_management?.enqpageadd,
});

// export default withStyles(styles)(
//   connect(mapStateToProps, actions)(withRouter(SignIn))
// );

export default connect(
  mapStateToProps,
  actions
)(withSnackBar(withRouter(withDialog(withStyles(styles)(SignIn)))));
