import { Box, Divider, Hidden, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import { stepperData } from "./stepperData";
import {
  CustomMobileStepper,
  CustomButton,
  SignupStepper,
} from "../../components";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@material-ui/core";
import { checkUrlFromELPRedirection, getUrlParam, snackProps, SOURCE_TYPE } from "../../utils";
import RegistrationTopbar from "./topbar";
import { useHistory, useLocation } from "react-router-dom";
import { dontShowInMenuRoutes } from "../../router/routes";

const BackPrompt = ({ handleClose, handleAccept }) => {
  return (
    <Stack spacing={2} justifyContent="center" sx={{ pt: "20px" }}>
      <Typography align="center" variant="h6" sx={{ px: "20px" }}>
        Change Mobile Number?
      </Typography>
      <Typography
        align="center"
        variant="body1"
        color="textSecondary"
        sx={{ px: "20px" }}
      >
        Are you sure, do you want to change <br /> your mobile number?
      </Typography>
      <Stack
        flexDirection="row"
        sx={{ borderTop: 1, borderColor: "grey.border" }}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: "grey.border" }}
          />
        }
      >
        <CustomButton
          onClick={handleClose}
          variant="text"
          sx={{ flex: 1, py: 1.25, borderRadius: 0, color: "text.secondary" }}
          id="no-btn-dialog"
        >
          No
        </CustomButton>
        <CustomButton
          onClick={handleAccept}
          variant="text"
          sx={{ flex: 1, py: 1.25, borderRadius: 0 }}
          color="primary"
          id="yes-btn-dialog"
        >
          Yes
        </CustomButton>
      </Stack>
    </Stack>
  );
};

const {
  GET_SALUTATION,
  GET_LANGUAGE,
  GET_ADDRESS_TYPES,
  GET_EMAIL_TYPES,
  GET_PHONE_TYPES,
  GET_CLIENT_ID_TYPES,
  GET_BLOOD_GROUP,
  GET_RELATIONSHIP,
  FILE_UPLOAD,
  SELF_REGISTER,
  VERIFY_USER_ID,
  ENQUIRY_PAGE_ADD,
  GET_ENQUIRY_BY_CLIENT_ID,
} = actions;

const Registration = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [, setSearchParam] = useState("");

  const phoneNumber = location?.state?.phoneNumber;
  const phoneCode = location?.state?.phoneNumberISD;

  console.log("------------------- PHONE ");
  console.log(phoneNumber, phoneCode);

  // Promise.resolve(dispatch(GET_LANGUAGE({}))).catch((err) => {console.log(err)})

  useEffect(() => {
    // let searchParams = window.location.search
    // history.push({
    //   pathname:dontShowInMenuRoutes.selfRegistration.subRoutes.success.pathName,
    //   state:{
    //     name: stepData?.name,
    //     userId: stepData?.userId,
    //     password: stepData?.password,
    //   },
    //   search:searchParams
    // });
    dispatch(GET_SALUTATION({}));
    dispatch(GET_LANGUAGE({}));
    dispatch(GET_ADDRESS_TYPES({}));
    dispatch(GET_EMAIL_TYPES({}));
    dispatch(GET_PHONE_TYPES({}));
    dispatch(GET_CLIENT_ID_TYPES({}));
    dispatch(GET_BLOOD_GROUP({}));
    dispatch(GET_RELATIONSHIP({}));
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      setSearchParam(urlParams);
    }

    // eslint-disable-next-line
  }, []);

  const salutation = useSelector(
    (state) => state?.clientMaster?.clientSalutation
  );
  const language = useSelector((state) => state?.clientMaster?.clientLanguage);
  const address_type = useSelector(
    (state) => state?.clientMaster?.clientAddressTypes
  );
  const email_type = useSelector(
    (state) => state?.clientMaster?.clientEmailTypes
  );
  const phone_type = useSelector(
    (state) => state?.clientMaster?.clientPhoneTypes
  );
  const client_id_type = useSelector(
    (state) => state?.clientMaster?.clientIdTypes
  );
  const blood_group = useSelector(
    (state) => state?.clientMaster?.clientBloodGroup
  );
  const relationship = useSelector(
    (state) => state?.clientMaster?.clientRelationship
  );

  // const isValidUserName = useSelector(
  //   (state) => state?.clientRegister?.validateUserId
  // );

  const validateArray = (data) => {
    return Array.isArray(data) && data?.length > 0 ? data : [];
  };

  const dependencyData = {
    salutation: validateArray(salutation?.data),
    language: validateArray(language?.data),
    address_type: validateArray(address_type?.data),
    email_type: validateArray(email_type?.data),
    phone_type: validateArray(phone_type?.data),
    client_id_type: validateArray(client_id_type?.data),
    blood_group: validateArray(blood_group?.data),
    relationship: validateArray(relationship?.data),
  };

  // console.log(language);

  const [activeStep, setActiveStep] = useState(1);

  const [stepData, setStepData] = useState({});

  const [skip, setSkip] = useState([]);

  const [continueLoading, setContinueLoading] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkUserId = async (data) => {
    const payload = { userid: data.userId };
    // alert(JSON.stringify(data?.userId))
    // dispatch(VERIFY_USER_ID({}))
    let result = Promise.resolve(await dispatch(VERIFY_USER_ID(payload)))
      .then((res) => {
        // setQuoteListData(res?.payload?.data);
        return res?.payload?.data?.isUserNameAlreadyExists === false;
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  };

  const submitOrGoNext = async (state = {}, isFirstStep, isLastStep) => {
    const skipCount = skip.includes(activeStep + 1) ? 2 : 1;
    setSkip([]);
    let isUserIdAvailable = false;

    if (isFirstStep) {
      isUserIdAvailable = await checkUserId(state);
    }

    // if step 1 & userId does not exists
    if (isFirstStep && !isUserIdAvailable) {
      setContinueLoading(false);
      return props.snack.setSnack({
        open: true,
        message: "User ID Already Exists!",
        duration: 6000,
        severity: snackProps.severity.error,
        setSnack: () => null,
      });
    } else if (
      (isFirstStep && isUserIdAvailable) ||
      isLastStep ||
      (!isFirstStep && !isLastStep)
    ) {
      // if step 1 & userId exists (or) if it is lastStep
      let temp_data;

      if (Array.isArray(state)) {
        temp_data = { ...stepData, relations: state };
      } else {
        temp_data = { ...stepData, ...state };
      }

      setStepData(temp_data);

      if (!isLastStep) {
        setContinueLoading(false);
        setActiveStep(activeStep + skipCount);
      }
      if (isLastStep) {
        handleSubmit(temp_data);
      }
    }
  };

  /**
   *
   * @param {object} state Values of the fields in an object.
   * @param {boolean} error Error validation of the step.
  //  * @param {boolean} isLastStep Send true if the step is lastStep
  //  * @param {boolean} isFirstStep Send true if the step is firstStep
   */
  const handleStepSubmit = (state, error) => {
    // Set Loading for continue/Confirm
    setContinueLoading(true);

    const isFirstStep = activeStep === 1;
    const isLastStep = activeStep === stepperData?.length;

    // If the user didn't skipped a step, then proceed with saving data
    // if (isFirstStep || !skip.includes(activeStep + 1)) {
    if (error) {
      // Turn loading to false
      setContinueLoading(false);
      return props.snack.setSnack({
        open: true,
        message: "Please fill required fields.",
        duration: 6000,
        severity: snackProps.severity.error,
      });
    } else {
      submitOrGoNext(state, isFirstStep, isLastStep);
    }
    // } else {
    //   // else move the user to second next step
    //   setActiveStep(activeStep + 2);

    //   // Clear Skips array for allowing user to go back to add details
    //   setSkip([]);
    // }
  };

  const handleMobileOTPNavigation = () => {
    history.push(dontShowInMenuRoutes.signUp.pathName);
  };

  const handleGoBackDialog = () => {
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <BackPrompt handleClose={handleClose} handleAccept={handleAccept} />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: handleMobileOTPNavigation,
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handlePreviousStep = (state, error) => {
    if (activeStep === 1) {
      return handleGoBackDialog();
    }

    let temp_data;

    if (Array.isArray(state)) {
      temp_data = { ...stepData, relations: state };
    } else {
      temp_data = { ...stepData, ...state };
    }

    setStepData(temp_data);
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = async (formData) => {
    // stepData
    // console.log(stepData);

    let data = formData;
    // console.log(data);
    if (formData.profileImage) {
      await dispatch(
        FILE_UPLOAD({
          file: formData?.profileImage,
          fileName: formData?.profileImage?.name?.trim(),
          // isbase64: true,
        })
      )
        .then(async (val) => {
          let _val = await val;
          if (_val?.payload?.data?.fileid) {
            data.profileImage = _val?.payload?.data?.fileid;
          }
          console.log(
            "\n----------------------- Profile Pic -----------------"
          );
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (formData?.id) {
      await formData?.id?.forEach(async (x, index) => {
        dispatch(
          FILE_UPLOAD({
            file: x?.idProof,
            fileName: x.idProof?.name?.trim(),
            // isbase64: true,
          })
        )
          .then(async (val) => {
            let _val = await val;
            if (_val?.payload?.data?.fileid) {
              data.id[index].idPhoto = _val?.payload?.data?.fileid;
            }
            console.log("\n----------------------- ID Pic -----------------");
            console.log(data);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
    if (formData?.relations) {
      await formData.relations?.forEach((z, i) => {
        // console.log(z)
        z.relationId &&
          z.relationId?.map(async (x, index) => {
            dispatch(
              FILE_UPLOAD({
                file: x?.idProof,
                fileName: x.idProof?.name?.trim(),
                // isbase64: true,
              })
            )
              .then(async (val) => {
                let _val = await val;
                if (_val?.payload?.data?.fileid) {
                  data.relations[i].relationId[index].idPhoto =
                    _val?.payload?.data?.fileid;
                }
                console.log(
                  "\n----------------------- Relation ID Pic -----------------"
                );
                console.log(data);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      });
    }

    console.clear();
    console.log(data);
    onAPIHitting(data);
  };

  const onAPIHitting = (data) => {
    const payload = {
      ...data,
      preferred_language: data?.preferred_language
        ? JSON.stringify(data?.preferred_language)
        : "",
      languageconstIdentifier: data?.preferred_language?.map(
        (x) => x.constIdentifier
      ),
      mobile: phoneNumber,
      mobilenumberISDcode: `+${phoneCode}`,
      // relationPrimaryEmail: data.relations?.[0]?.email ?? '',
      // relationName: data.relations?.[0]?.name ?? '',
      // relationship: data.relations?.[0]?.relationship?.value ? data.relations[0]?.relationship : null,
    };
    let rawSearchParams = window.location.search;
    Promise.resolve(dispatch(SELF_REGISTER(payload)))
      .then(async (res) => {
        if (res.payload.error) {
          setContinueLoading(false);
          return props.snack.setSnack({
            open: true,
            severity: snackProps.severity.error,
            message: "Something went wrong! Please try again.",
          });
        }
        let elpProducts = checkUrlFromELPRedirection(rawSearchParams);
        if (Array.isArray(elpProducts) && elpProducts?.length > 0) {
           // Get Enquiries by client ID
           const clientEnquiries = await dispatch(GET_ENQUIRY_BY_CLIENT_ID({
            clientId: res?.payload?.data?.Result?.[0]?.properties?.doc?.clientId,
          }));

          let _obj = {
            isCart: false,
            resources: elpProducts,
            enquire: { remarks: "" },
            loginUserId:
              res?.payload?.data?.Result?.[0]?.properties?.doc?.loginUserId,


              // If the client clicked new to tanyacare in ELP / 
            // If the client is already registered via DP/CWA and does not have any enquiry, 
            // then the client is considered to be a new Client.
            // So isNewUser is saved against the enquiry.
            isNewUser: getUrlParam(rawSearchParams, "isNewUser") === "true" || clientEnquiries?.payload?.data?.length === 0,
            isClientApp: true,

            // If the sourceType is provided, then the isClientApp boolean is not considered on saving the sourceType.
            // Instead, the provided sourceType is saved against the enquiry.
            sourceType: SOURCE_TYPE.ELP,
          };

          Promise.resolve(dispatch(ENQUIRY_PAGE_ADD({ ..._obj }))).then(
            (enquiryAddResponse) => {
              if (!enquiryAddResponse?.payload?.error) {
                props.snack.setSnack({
                  open: true,
                  message: "Enquiry Added Successfully!",
                  duration: 6000,
                  severity: snackProps.severity.success,
                  setSnack: () => null,
                });
              } else {
                props.snack.setSnack({
                  open: true,
                  message: "Unable to Add Enquiry! Something went wrong!",
                  duration: 6000,
                  severity: snackProps.severity.error,
                  setSnack: () => null,
                });
              }
            }
          );
        }
       let name = stepData?.salutation?.label ? `${stepData?.salutation?.label}.${stepData?.name}` : stepData?.name
        history.push({
          pathname:
            dontShowInMenuRoutes.selfRegistration.subRoutes.success.pathName,
          state: {
            name,
            userId: stepData?.userId,
            password: stepData?.password,
          },
          search: rawSearchParams,
        });
      })
      .catch((err) => {
        setContinueLoading(false);
        props.snack.setSnack({
          open: true,
          severity: snackProps.severity.error,
          message: "Something went wrong! Please try again.",
        });
      });
  };

  const handleSkipDialogContinue = (shouldSkip, skipStep) => {
    if (shouldSkip) {
      let skipSteps = skip;
      skipSteps.push(skipStep);
      setSkip(skipSteps);
    }
  };

  const getTopbarTitle = () => {
    const titles = [
      "Create Your Profile",
      "Add Family Members",
      "Add Your Health Info",
    ];
    return titles[activeStep - 1];
  };

  const handleUpdateLater = (initialState = {}) => {
    if (activeStep === stepperData?.length) {
      // If the active step is lastStep, then make Api call - Register User
      // handleStepSubmit(initialState, false, true, false);
      handleStepSubmit(initialState, false);
    } else {
      // If not last step, then skip it
      handleNext();
    }
  };

  return (
    <Paper>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            // pt: { xs: 1, md: 1 },
            pb: { xs: 6.5, sm: 0 },
            // pb: 6.5,
            position: "relative",
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              pt: { xs: 2, md: 0 },
              pb: { xs: 1, md: 0 },
              px: { xs: 1, md: 0 },
              position: "sticky",
              top: 0,
              left: 0,
              right: 0,
              bgcolor: "common.white",
              zIndex: "999",

              //
              borderBottom: { md: 4 },
              borderColor: { md: "grey.300" },
            }}
          >
            <Hidden mdUp>
              <RegistrationTopbar
                title={getTopbarTitle()}
                hidePrevious={false}
                handlePreviousStep={handlePreviousStep}
                hideSkip={![2, 3].includes(activeStep)}
                handleSkip={handleUpdateLater}
                continueLoading={continueLoading}
                parentID="FromRegistration"
              />
            </Hidden>
            <Stack>
              <Hidden mdDown>
                <Stack flexDirection="row">
                  <Box
                    component="img"
                    src="/assets/tanyaCare/TanyaHorizontal.png"
                    // src="/svg/tanyacareLogo/tanyacareLight.svg"
                    alt="Tanya.Care"
                    // className={classes.logo}
                    // className={classes.toplogo}
                    sx={{
                      position: "absolute",
                      width: 120,
                      left: { xs: "0.7rem", md: "1.7rem" },
                      top: "0.5rem",
                    }}
                  />
                  {/* <CustomStepper
                    steps={stepperData}
                    activeCount={activeStep - 1}
                  /> */}
                  <SignupStepper activeStep={activeStep} />
                </Stack>
              </Hidden>
              <Hidden mdUp>
                <Box>
                  <CustomMobileStepper
                    activeStep={activeStep}
                    stepsLength={stepperData?.length}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                </Box>
              </Hidden>
            </Stack>
          </Box>
          {activeStep === 1 ? (
            <Box
              sx={{
                pt: { md: 2 },
                px: { xs: 1, md: 0 },
                bgcolor: { md: "grey.border" },
              }}
            >
              <StepOne
                handleNextStep={handleStepSubmit}
                dependencyData={dependencyData}
                handleSkipDialogContinue={handleSkipDialogContinue}
                data={stepData}
                handleUpdateLater={handleUpdateLater}
                handleGoBackDialog={handleGoBackDialog}
                continueLoading={continueLoading}
              />
            </Box>
          ) : activeStep === 2 ? (
            <Box
              sx={{
                pt: { md: 2 },
                px: { xs: 1, md: 0 },
                bgcolor: { md: "grey.border" },
              }}
            >
              <StepTwo
                handleNextStep={handleStepSubmit}
                handlePreviousStep={handlePreviousStep}
                dependencyData={dependencyData}
                handleSkipDialogContinue={handleSkipDialogContinue}
                data={stepData}
                handleUpdateLater={handleUpdateLater}
                continueLoading={continueLoading}
              />
            </Box>
          ) : activeStep === 3 ? (
            <Box
              sx={{
                pt: { md: 2 },
                px: { xs: 1, md: 0 },
                bgcolor: { md: "grey.border" },
              }}
            >
              <StepThree
                handleNextStep={handleStepSubmit}
                handlePreviousStep={handlePreviousStep}
                dependencyData={dependencyData}
                handleSkipDialogContinue={handleSkipDialogContinue}
                data={stepData}
                handleUpdateLater={handleUpdateLater}
                continueLoading={continueLoading}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default withDialog(withDrawer(withSnackBar(Registration)));

// export default Registration;
