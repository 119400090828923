import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function SectionHeader({ title, id,  buttonLabel, buttonHandler = () => {}, sx }) {
  return (
    <Stack
      sx={{ py: 2, pl: 2.5, pr: 1.25, ...sx }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography fontWeight="500">{title ? title : "Untitled"}</Typography>
      {buttonLabel && (
        <Button
          id={id}
          size="small"
          onClick={buttonHandler}
          color="secondary"
          sx={{
            textTransform: "none",
            fontSize: "0.9rem",
            fontWeight: 500,
            lineHeight: 1.25,
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </Stack>
  );
}

export default SectionHeader;
