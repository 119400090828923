import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { CustomAvatar } from "../../../../components";
import { getAgeString, getGenderString } from "../../../../utils";

function ProfileSummary({ data, loading = false, ...props }) {
  return (
    <Stack
      sx={{ p: 2.5, bgcolor: "common.white" }}
      flexDirection="row"
      flexWrap={"nowrap"}
      gap={2}
      style={{
        boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
      }}
    >
      {loading ? (
        <Skeleton variant="circular" width={"48px"} height={"48px"} />
      ) : (
        <CustomAvatar
          src={data?.profilePic}
          name={data?.clientName}
          // src="/assets/temp/personAvatar.png"
          // src={`${profileData?.data?.profilePic}`}
          // loading={profileData?.loading}
          // variant={profileData?.data?.profilePic ? "circular" : "rounded"}
          width={"48px"}
          height={"48px"}
        />
      )}
      {loading ? (
        <Stack sx={{ flex: 1 }}>
          <Skeleton variant="text" width={"30%"} />
          <Skeleton variant="text" width={"35%"} />
          <Skeleton variant="text" width={"45%"} />
        </Stack>
      ) : (
        <Stack>
          <Typography fontWeight="500" sx={{ mb: 0.5 }}>
            {`${data?.salutation?.label ? `${data?.salutation?.label} .` : ""}${data?.clientName}`}
          </Typography>
          <Typography variant="body2" fontWeight="400" sx={{ mb: 0.25 }}>
            {`${getGenderString(data?.gender)}, ${getAgeString(data?.dob)} old`}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Client ID: {data?.clientId}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default ProfileSummary;
