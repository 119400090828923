import React from "react";
import { actions } from "tanyacare-middleware";
import { connect } from "react-redux";
import { AuthContext } from "./contexts";
import Loading from "./components/loaders/loading";
import { checkUrlFromELPRedirection, getUrlParam, snackProps, SOURCE_TYPE  } from "./utils";
import { routes } from "./router/routes";
import { withSnackBar } from "./HOC's";

class AppAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: { email: "", password: "", device: "client_app_web_roles", refresh: true, searchParams: "" },
            setAuth: this.setAuth,
        };
    }
    setAuth = (auth) => {
        this.setState({ auth });
    };
    async componentDidMount() {
    // Save Search Params
    if (window.location.search) {
        const urlParams = new URLSearchParams(window.location.search);
        this.setState({
          searchParams: urlParams,
        });
      }
        debugger
        await fetch("meta.json")
        .then(_=>_.json())
        .then((_) => {
            localStorage.setItem("version", _.version);
          })
          .catch((err) => {
            console.log("err:", err);
          });
        if (localStorage.getItem("_mGdRe")) {
           const login = await this.props.LOGIN_AUTH({ ...this.state.auth });
           debugger
           if(!login?.payload?.error){
            // ADDING DATA FROM ELP
            let value = this.state.searchParams ? this.state.searchParams : window.location.search ? new URLSearchParams(window.location.search) : ''
            let elpProducts = checkUrlFromELPRedirection(value)
  
            if (Array.isArray(elpProducts) && elpProducts?.length > 0) {
               // Get Enquiries by client ID
              const clientEnquiries = await this.props.GET_ENQUIRY_BY_CLIENT_ID({
                clientId: login?.payload?.data?.user_id,
              });
              let _obj = {
                isCart: false,
                resources: elpProducts,
                enquire: { remarks: "" },

                // If the client clicked new to tanyacare in ELP /
                // If the client is already registered via DP/CWA and does not have any enquiry,
                // then the client is considered to be a new Client.
                // So isNewUser is saved against the enquiry.
                isNewUser:
                  getUrlParam(value, "isNewUser") === "true" ||
                  clientEnquiries?.payload?.data?.length === 0,
                isClientApp: true,

                // If the sourceType is provided, then the isClientApp boolean is not considered on saving the sourceType.
            // Instead, the provided sourceType is saved against the enquiry.
                sourceType: SOURCE_TYPE.ELP,
              };
  
              debugger

            let res_data =  await this.props.ENQUIRY_PAGE_ADD({ ..._obj });
              if (!res_data?.payload?.error) {
                this.props.snack.setSnack({
                  open: true,
                  message: "Enquiry Added Successfully!",
                  duration: 6000,
                  severity: snackProps.severity.success,
                  setSnack: () => null,
                });
              } else {
                this.props.snack.setSnack({
                  open: true,
                  message: "Unable to Add Enquiry!",
                  duration: 6000,
                  severity: snackProps.severity.error,
                  setSnack: () => null,
                });
              }
            }
        }
        else{
          this.setState({error_txt:login?.payload?.data?.error, loading:false})
        }
        }
    }

    render() {

            return (
                <AuthContext.Provider value={this.state}>
                    {/* {!this.props?.loginState?.loading ? this.props.children : <Loading/>} */}
                    {this.props.children}
                </AuthContext.Provider>
            );

    }
}

const mapStateToProps = (state) => ({
    loginState: state?.authState?.loginAuth,
});
export default connect(mapStateToProps, actions)(withSnackBar(AppAuth));
