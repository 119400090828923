import {
  Box,
  Button,
  Divider,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import {
  CustomButton,
  CustomSelect,
  CustomTextfield,
  CustomTextfieldWithSelect,
  StyledToggleButtonGroup,
} from "../../components";
import CustomDatePicker from "../../components/datePicker";
import { Grid } from "@mui/material";
import { alpha } from "@mui/material";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { RightArrowStripesIcon } from "../../assets";
import { styled } from "@mui/system";
import SkipDialog from "./skipDialog";
import { snackProps, sortByEmergency, sortByPrimary } from "../../utils";
import {
  ClearPrompt,
  EditPrefillPrompt,
  DeletePrompt,
  FamilyMembersDetailSection,
  ChangeEmergencyContactPrompt,
  MultipleIdentification,
  MultipleContact,
  MultipleAddress,
} from "./components";
import { v4 as uuidV4 } from "uuid";
import isEqual from "react-fast-compare";

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    margin: "0.5rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "1rem 2rem",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "1rem 3rem",
  },
}));

const genderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

const initialState = {
  relationship: "",
  salutation: "",
  name: "",
  email: "",
  dob: null,
  gender: "",
  occupation: "",
  languages: [],
  otherAddress: [],
  idTypes: [],
  otherPhoneNumbers: [],
  isEmergencyContact: false,
};

const StepTwo = (props) => {
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { dependencyData } = props;

  const [state, setState] = useState(initialState);

  const [primaryContactId, setPrimaryContactId] = useState("");

  const [errorText, setErrorText] = useState();

  const [familyMembers, setFamilyMembers] = useState([]);

  const [fieldsToValidate] = useState([
    "relationship",
    "salutation",
    "name",
    "otherPhoneNumbers",
  ]);

  useEffect(() => {
    // setState({
    //   ...initialState,
    //   ...props.data?.relations?.[0],
    // });
    if (Array.isArray(props.data?.relations)) {
      setFamilyMembers([...props.data?.relations]);
    }
  }, [props.data]);

  const updateFieldState = (fieldValue, key) => {
    setState({
      ...state,
      [key]: fieldValue,
    });
  };

  const closeDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: "",
    });
  };

  // Identification Details
  const handleIdentificationSave = (idData) => {
    debugger;
    const idTypes = [...state["idTypes"]];
    if (!!idData?.id) {
      // Edit
      let updatedData = idTypes.map((idItem) => {
        if (idItem?.id === idData?.id) {
          return {
            ...idItem,
            ...idData,
          };
        }
        return idItem;
      });

      updateFieldState(updatedData, "idTypes");
    } else {
      idTypes.push({ ...idData, id: uuidV4() });
      updateFieldState(idTypes, "idTypes");
    }
  };

  const handleIdentificationDelete = (idData) => {
    closeDrawer();
    debugger;
    let idTypes = [...state["idTypes"]];
    idTypes = idTypes.filter((phoneNumber) => phoneNumber?.id !== idData?.id);
    updateFieldState(idTypes, "idTypes");
  };

  const getIDOptions = () => {
    const allOptions = [...dependencyData?.client_id_type];
    const usedOptions = [...state?.idTypes];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.id_type_name?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  // Contact Details
  // const handleContactSave = (contact) => {
  //   debugger;
  //   const otherPhoneNumbers = [...state["otherPhoneNumbers"]];
  //   if (!!contact?.id) {
  //     // Edit
  //     let updatedData = otherPhoneNumbers.map((contactData) => {
  //       if (contactData?.id === contact?.id) {
  //         return {
  //           ...contactData,
  //           ...contact,
  //         };
  //       }
  //       return contactData;
  //     });

  //     updateFieldState(updatedData, "otherPhoneNumbers");
  //   } else {
  //     otherPhoneNumbers.push({ ...contact, id: uuidV4() });
  //     updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
  //   }
  // };

  const handleContactDelete = (contact) => {
    closeDrawer();
    debugger;
    let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    otherPhoneNumbers = otherPhoneNumbers.filter(
      (phoneNumber) => phoneNumber?.id !== contact?.id
    );
    updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
  };

  const getContactOptions = () => {
    const allOptions = [...dependencyData?.phone_type];
    const usedOptions = [...state?.otherPhoneNumbers];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.mobile_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  const isSortingNeeded = (data) => {
    if (data?.length === 1) return false;
    if (data?.[0]?.isPrimary) return false;
    return true;
  };

  const handleContactSave = (contact) => {
    if (primaryContactId || state["otherPhoneNumbers"]?.length === 0) {
      if (!!contact?.id) {
        let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
        if (primaryContactId === contact?.id) {
          let updatedData = otherPhoneNumbers.map((contactData) => {
            if (contactData?.id === contact?.id) {
              return {
                ...contactData,
                ...contact,
              };
            }
            return contactData;
          });

          // Sort by primary
          updatedData = isSortingNeeded(updatedData)
            ? sortByPrimary(updatedData)
            : updatedData;
          updateFieldState(updatedData, "otherPhoneNumbers");
        } else {
          if (contact?.isPrimary) {
            otherPhoneNumbers = otherPhoneNumbers.map((phone) => ({
              ...phone,
              isPrimary: false,
            }));
          }

          let updatedData = otherPhoneNumbers.map((contactData) => {
            if (contactData?.id === contact?.id) {
              return {
                ...contactData,
                ...contact,
              };
            }
            return contactData;
          });

          // Sort by primary
          updatedData = isSortingNeeded(updatedData)
            ? sortByPrimary(updatedData)
            : updatedData;
          updateFieldState(updatedData, "otherPhoneNumbers");

          // Set PrimaryContact Id
          if (contact?.isPrimary) {
            setPrimaryContactId(contact?.id);
          }
        }
      } else {
        let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
        if (contact?.isPrimary) {
          otherPhoneNumbers = otherPhoneNumbers.map((phone) => ({
            ...phone,
            isPrimary: false,
          }));
        }
        const newContact = {
          ...contact,
          id: uuidV4(),
        };
        otherPhoneNumbers.push(newContact);

        // Sort by primary
        otherPhoneNumbers = isSortingNeeded(otherPhoneNumbers)
          ? sortByPrimary(otherPhoneNumbers)
          : otherPhoneNumbers;
        updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");

        // Set PrimaryContact Id
        if (newContact?.isPrimary) {
          setPrimaryContactId(newContact?.id);
        }
      }
    }
  };

  // Address Details
  const handleAddressSave = (address) => {
    debugger;
    const otherAddress = [...state["otherAddress"]];
    if (!!address?.id) {
      // Edit
      let updatedData = otherAddress.map((addressData) => {
        if (addressData?.id === address?.id) {
          return {
            ...addressData,
            ...address,
          };
        }
        return addressData;
      });

      updateFieldState(updatedData, "otherAddress");
    } else {
      otherAddress.push({ ...address, id: uuidV4() });
      updateFieldState(otherAddress, "otherAddress");
    }
  };

  const handleAddressDelete = (address) => {
    closeDrawer();
    debugger;
    let otherAddress = [...state["otherAddress"]];
    otherAddress = otherAddress.filter(
      (addressData) => addressData?.id !== address?.id
    );
    updateFieldState(otherAddress, "otherAddress");
  };

  const getAddressOptions = () => {
    const allOptions = [...dependencyData?.address_type];
    const usedOptions = [...state?.otherAddress];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.address_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
  };

  // Validation Function
  const validate = () => {
    let error = {};
    let validationErrorText = {};
    fieldsToValidate.map((field) => {
      switch (field) {
        case "relationship": {
          if (state["relationship"] === "") {
            error[field] = true;
            validationErrorText[field] =
              "Relationship Type should not be empty";
          }
          break;
        }
        case "salutation": {
          if (state["salutation"] === "") {
            error[field] = true;
            validationErrorText[field] = "Salutation should not be empty";
          }
          break;
        }
        case "name": {
          if (state["name"] === "") {
            error[field] = true;
            validationErrorText[field] = "Name should not be empty";
          }
          break;
        }
        case "otherPhoneNumbers": {
          if (state["otherPhoneNumbers"].length < 1) {
            error[field] = true;
            validationErrorText[field] = "Atleast one is mandatory";
          }
          break;
        }
        default:
          return "";
      }
      return field;
    });
    return {
      error: Object.keys(error)?.length > 0,
      errorText: validationErrorText,
    };
  };

  const handleSubmit = () => {
    if (familyMembers?.length === 0) {
      const { errorText: validationErrorText } = validate();
      setErrorText(validationErrorText);

      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Please add atleast one family member to save or update later",
      });
    } else {
      if (belowSm) {
        props.drawer.setDrawer({
          open: true,
          component: (handleClose) => (
            <SkipDialog
              question={"Do you want to add your health info?"}
              handleContinue={(shouldSkip) => {
                props.handleSkipDialogContinue(shouldSkip, 3);

                props.handleNextStep(familyMembers, false);
                handleClose();
              }}
              handleClose={handleClose}
            />
          ),
        });
      } else {
        props.handleNextStep(familyMembers, false);
      }
    }
  };

  // Family Members Handler
  const saveFamilyMembers = (deAllocatedMembers = []) => {
    const members =
      deAllocatedMembers?.length > 0 ? deAllocatedMembers : familyMembers;
    // If State object has id, then the mode is edit else add
    if (state?.id) {
      const otherMembers = members.filter((member) => member?.id !== state?.id);
      setFamilyMembers(sortByEmergency([...otherMembers, { ...state }]));
    } else {
      console.log("===================================================");
      console.log(members);
      debugger;
      // setFamilyMembers((prevFamilyMembers) => [
      //   ...prevFamilyMembers,
      //   { ...state, id: uuidV4() },
      // ]);

      setFamilyMembers(
        sortByEmergency([...members, { ...state, id: uuidV4() }])
      );
    }
    setState({ ...initialState });
  };

  const emergencyContactArray = () => {
    return (
      familyMembers.filter(
        (member) =>
          member?.isEmergencyContact === true && state?.id !== member?.id
      )?.length > 0
    );
  };

  const checkEmergencyContactConflict = (isEdit) => {
    console.log("state?.isEmergencyContact === true");
    // Check only if the isEmergencyContact State is true
    if (state?.isEmergencyContact === true) {
      console.log("familyMembers?.length > 0");
      // Check if there is any family member
      if (familyMembers?.length > 0) {
        console.log("emergencyContactArray()");
        // Check if any other family member has emergency contact Checked
        // If any other member is emergency contact
        if (emergencyContactArray()) {
          console.log("props.dialog.setDialog");

          const deAllocateEmergencyAndUpdateEmergency = () => {
            debugger;
            const deAllocated = familyMembers.map((member) => ({
              ...member,
              isEmergencyContact:
                state?.id === member?.id ? state?.isEmergencyContact : false,
            }));
            // setFamilyMembers(deAllocated);

            saveFamilyMembers(deAllocated);
          };

          // Ask Prompt
          props.dialog.setDialog({
            open: true,
            renderContent: (handleClose, handleAccept) => (
              <ChangeEmergencyContactPrompt
                handleClose={handleClose}
                handleAccept={handleAccept}
              />
            ),
            positiveButtonProps: {
              hide: true,
            },
            negativeButtonProps: {
              hide: true,
            },
            onAccept: () => deAllocateEmergencyAndUpdateEmergency(),
            sx: {
              Dialog: {
                "&.MuiDialog-paper": {
                  borderRadius: "12px",
                },
              },
              DialogContent: {
                "&.MuiDialogContent-root": {
                  padding: 0,
                },
              },
            },
          });
          return false;
        } else {
          // If there is no emergency contact, then allow user to save family member without prompt
          return true;
        }
      } else {
        return true;
      }
    }

    // return true otherwise
    return true;

    // // Check if the value is true
    // if (value === true) {
    //   // Check if there is any family member
    //   if (familyMembers?.length > 0) {
    //     // Check if any other family member has emergency contact Checked
    //     const emergencyContactArray = familyMembers.filter(
    //       (member) => member?.isEmergencyContact === true
    //     );

    //     // If any other member is emergency contact
    //     if (emergencyContactArray?.length > 0) {
    //       const deAllocateEmergencyAndUpdateEmergency = () => {
    //         updateFieldState(value, key);
    //       };

    //       // Ask Prompt
    //       props.dialog.setDialog({
    //         open: true,
    //         renderContent: (handleClose, handleAccept) => (
    //           <ChangeEmergencyContactPrompt
    //             handleClose={handleClose}
    //             handleAccept={handleAccept}
    //           />
    //         ),
    //         positiveButtonProps: {
    //           hide: true,
    //         },
    //         negativeButtonProps: {
    //           hide: true,
    //         },
    //         onAccept: deAllocateEmergencyAndUpdateEmergency,
    //         sx: {
    //           Dialog: {
    //             "&.MuiDialog-paper": {
    //               borderRadius: "12px",
    //             },
    //           },
    //           DialogContent: {
    //             "&.MuiDialogContent-root": {
    //               padding: 0,
    //             },
    //           },
    //         },
    //       });
    //     } else {
    //       // If there is no emergency contact, then allow user to set emergency contact without prompt
    //       updateFieldState(value, key);
    //     }
    //   } else {
    //     // FamilyMembers array is empty, so no issues in updating it.
    //     updateFieldState(value, key);
    //   }
    // } else {
    //   // Value is false, so no issues in updating it.
    //   updateFieldState(value, key);
    // }
  };

  const saveMemberClick = () => {
    const { error, errorText: validationErrorText } = validate();

    setErrorText(validationErrorText);

    if (error) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Please fill required fields",
      });
    } else {
      if (checkEmergencyContactConflict(!!state?.id)) {
        saveFamilyMembers();
      }
    }
  };

  const onEditClick = (memberId) => {
    const prefillEditData = () => {
      const editMember = familyMembers.filter(
        (member) => member?.id === memberId
      )?.[0];
      setState({ ...editMember });
    };

    if (isEqual(state, initialState)) {
      prefillEditData();
    } else {
      props.dialog.setDialog({
        open: true,
        renderContent: (handleClose, handleAccept) => (
          <EditPrefillPrompt
            handleClose={handleClose}
            handleAccept={handleAccept}
          />
        ),
        positiveButtonProps: {
          hide: true,
        },
        negativeButtonProps: {
          hide: true,
        },
        onAccept: prefillEditData,
        sx: {
          Dialog: {
            "&.MuiDialog-paper": {
              borderRadius: "12px",
            },
          },
          DialogContent: {
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          },
        },
      });
    }
  };

  const onDeleteMember = (memberId) => {
    const deleteMember = () => {
      setFamilyMembers((prevFamilyMembers) => {
        return prevFamilyMembers.filter((member) => member?.id !== memberId);
      });
    };

    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <DeletePrompt handleClose={handleClose} handleAccept={handleAccept} />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: deleteMember,
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const onClearClick = () => {
    const clearState = () => {
      return setState({ ...initialState });
    };

    if (isEqual(state, initialState)) {
      return;
    } else {
      props.dialog.setDialog({
        open: true,
        renderContent: (handleClose, handleAccept) => (
          <ClearPrompt handleClose={handleClose} handleAccept={handleAccept} />
        ),
        positiveButtonProps: {
          hide: true,
        },
        negativeButtonProps: {
          hide: true,
        },
        onAccept: clearState,
        sx: {
          Dialog: {
            "&.MuiDialog-paper": {
              borderRadius: "12px",
            },
          },
          DialogContent: {
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          },
        },
      });
    }
  };

  return (
    <Box>
      <Grid container flexWrap="wrap-reverse">
        <Grid
          container
          item
          xs={12}
          md={8}
          style={{ backgroundColor: "white" }}
        >
          <Grid container>
            <Grid item xs={12} md>
              <StyledBox>
                <CustomSelect
                  // id={"my order status filter"}
                  sx={{ mt: 1, mb: 2 }}
                  // size="small"
                  options={dependencyData?.relationship}
                  loading={false}
                  value={state["relationship"]}
                  label={"Relationship Type"}
                  onChange={(e, relationship) => {
                    updateFieldState(relationship, "relationship");
                  }}
                  multiple={false}
                  InputProps={{
                    error: errorText?.relationship ? true : false,
                    helperText: errorText?.relationship,
                    required: true
                  }}
                  id="relationshipType"
                />

                <CustomTextfieldWithSelect
                  sx={{ mt: 1, mb: 2, gap: 1 }}
                  // size="small"
                  label={"Relation Name"}
                  selectLabel={"Salutation"}
                  textFieldProps={{
                    id: "relationNameSteptwo",
                    name: "name",
                    // placeholder: "Name",
                    required: true,
                  }}
                  selectProps={{
                    id: "relationSalutationSteptwo",
                    name: "Salutation",
                    required: true,
                  }}
                  selectOptions={dependencyData?.salutation}
                  fullWidth
                  selectValue={state["salutation"]}
                  value={state["name"]}
                  onSelectChange={(e, salutation) => {
                    let data = dependencyData?.salutation?.filter(
                      (x) => x?.value === salutation?.value
                    )?.[0];
                    updateFieldState(data, "salutation");
                  }}
                  onChange={(e) => updateFieldState(e.target.value, "name")}
                  error={errorText?.name ? true : false}
                  helperText={errorText?.name}
                  selectError={errorText?.salutation ? true : false}
                  selectHelperText={errorText?.salutation}
                />

                <CustomTextfield
                  fullWidth
                  // size="small"
                  sx={{ mb: 2 }}
                  label="Primary Email Address"
                  value={state["email"]}
                  onChange={(e) => updateFieldState(e.target.value, "email")}
                  id="primaryEmailAddressSteptwo"
                />
                <CustomDatePicker
                  fullWidth
                  label={"DOB"}
                  value={state["dob"]}
                  disableFuture
                  // size="small"
                  // sx={{ marginY: 1, color: "primary.main" }}
                  InputProps={{ sx: { mb: 1 }, fullWidth: true,id:"dateOfBirthSteptwo" }}
                  onChange={(newDate) =>
                    updateFieldState(moment(newDate).toISOString(), "dob")
                  }
                />

                <StyledToggleButtonGroup
                  id={"Gender"}
                  label={"Gender"}
                  // size="small"
                  sx={{ marginY: 1 }}
                  options={genderOptions}
                  data={{ value: state["gender"] }}
                  onChange={(e, gender) => {
                    //   console.log(value)
                    updateFieldState(gender, "gender");
                  }}
                />
              </StyledBox>
            </Grid>
            <Divider orientation={"vertical"} flexItem />
            <Grid container item xs={12} md>
              <StyledBox sx={{ width: "100%" }}>
                <MultipleIdentification
                  data={state?.idTypes}
                  options={getIDOptions()}
                  required={false}
                  handleSave={handleIdentificationSave}
                  handleDelete={handleIdentificationDelete}
                  helperText={errorText?.idTypes}
                  sx={{ mb: 2 }}
                  parentID="FromStep2"
                />

                <MultipleContact
                  contacts={state?.otherPhoneNumbers}
                  options={getContactOptions()}
                  primaryContactId={primaryContactId}
                  required={true}
                  handleSave={handleContactSave}
                  handleDelete={handleContactDelete}
                  helperText={errorText?.otherPhoneNumbers}
                  sx={{ mb: 2 }}
                  parentID="FromStep2"
                />

                <MultipleAddress
                  data={state?.otherAddress}
                  options={getAddressOptions()}
                  required={false}
                  handleSave={handleAddressSave}
                  handleDelete={handleAddressDelete}
                  helperText={errorText?.otherAddress}
                  sx={{ mb: 2 }}
                  parentID="FromStep3"
                />

                {/* <ChooseIdentificationCard
                  data={state["idTypes"]}
                  title={"ID Details"}
                  label={"Id"}
                  sx={{ mb: 2 }}
                  isEdit={false}
                  removeData={removeData}
                  onSavedAddressButtonClick={onSavedIdButtonClick}
                  onAddEditAddressButtonClick={onAddEditIdButtonClick}
                /> */}

                {/* <ChooseContactCard
                  data={state["otherPhoneNumbers"]}
                  title={"Contact Details"}
                  label={"Contact"}
                  sx={{ marginY: 2 }}
                  isEdit={false}
                  removeData={removeContactData}
                  onSavedAddressButtonClick={onSavedContactButtonClick}
                  onAddEditAddressButtonClick={onAddEditContactButtonClick}
                  helperText={errorText?.otherPhoneNumbers}
                  required
                /> */}

                {/* <ChooseAddressCard
                  data={state["otherAddress"]}
                  title={"Address Details"}
                  label={"Address"}
                  sx={{ marginY: 2 }}
                  isEdit={false}
                  removeData={removeAddressData}
                  onSavedAddressButtonClick={onSavedAddressButtonClick}
                  onAddEditAddressButtonClick={onAddEditAddressButtonClick}
                /> */}

                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkboxEmergenyContactSteptwo"
                        color="success"
                        checked={state["isEmergencyContact"]}
                        onChange={(e) =>
                          updateFieldState(
                            e.target.checked,
                            "isEmergencyContact"
                          )
                        }
                      />
                    }
                    label="Same for emergency contact details"
                    sx={{ mb: 2, color: "text.secondary" }}
                  />
                </Box>

                {/* FORM CTA */}
                <Stack
                  flexDirection="row"
                  gap={1}
                  sx={{ pb: { xs: 1, md: 0 } }}
                >
                  <Box sx={{ minWidth: "100px" }}>
                    <Button
                      id="clearBtnSteptwo"
                      sx={{
                        borderRadius: 1.5,
                        lineHeight: "normal",
                        textTransform: "unset",
                        py: 1,
                        px: 2,
                        // borderColor: "error.custom",
                        color: "error.custom",
                        "&:hover": {
                          background: (themeObject) =>
                            alpha(themeObject.palette.error.custom, 0.04),
                          // borderColor: "error.custom",
                        },
                      }}
                      variant="text"
                      size="small"
                      fullWidth
                      onClick={onClearClick}
                      disabled={isEqual(state, initialState)}
                    >
                      Clear
                    </Button>
                  </Box>
                  <Box sx={{ minWidth: "100px" }}>
                    <CustomButton
                      id="saveBtnSteptwo"
                      sx={{
                        borderRadius: 1.5,
                        py: 1,
                        px: 2,
                        lineHeight: "normal",
                        textTransform: "unset",
                        height: "100%",
                      }}
                      variant="contained"
                      size="small"
                      fullWidth
                      onClick={saveMemberClick}
                      disabled={isEqual(state, initialState)}
                    >
                      Save
                    </CustomButton>
                  </Box>
                </Stack>
              </StyledBox>
            </Grid>
          </Grid>
        </Grid>

        {/* Family Member Details View */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              ml: 0.75,
              bgcolor: "white",
              height: "100%",
              py: 1.5,
              px: 1.25,
            }}
          >
            <FamilyMembersDetailSection
              members={familyMembers}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteMember}
              parentID="Steptwo"
            />
          </Box>
        </Grid>
      </Grid>

      {belowSm ? (
        <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
          <CustomButton
            fullWidth
            sx={{ borderRadius: 0, py: 2 }}
            endIcon={<RightArrowStripesIcon />}
            loading={props.continueLoading}
            onClick={handleSubmit}
            id="proceed-btn-steptwo"
          >
            Proceed
          </CustomButton>
        </Box>
      ) : (
        <Grid item xs={12}>
          <Box sx={{ pt: 0.5, bgcolor: "grey.border", width: "100%" }}>
            <Box sx={{ px: 3, py: 2, width: "100%", bgcolor: "common.white" }}>
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: { sm: "row" },
                  alignItems: { sm: "flex-end" },
                  justifyContent: { sm: "flex-end" },
                  gap: 2,
                }}
              >
                {/* <Box sx={{ minWidth: "140px" }}>
                  <CustomButton
                    sx={{
                      borderRadius: 2,
                      py: 1.5,
                      borderColor: "grey.300",
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      props.handlePreviousStep(familyMembers);
                    }}
                  >
                    Go Back
                  </CustomButton>
                </Box> */}
                <Box sx={{ minWidth: "140px" }}>
                  <CustomButton
                    sx={{
                      borderRadius: 2,
                      py: 1.5,
                      borderColor: "grey.300",
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => props.handleUpdateLater(initialState)}
                    id="updateLateBtnSteptwo"
                  >
                    Update Later
                  </CustomButton>
                </Box>
                <Box sx={{ minWidth: "140px" }}>
                  <CustomButton
                    sx={{ borderRadius: 2, py: 1.5 }}
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    id="confirmBtnSteptwo"
                  >
                    Confirm
                  </CustomButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default withDialog(withDrawer(withSnackBar(StepTwo)));
