import React from "react";
// import img from "../../assets/icons/mobile.svg";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";


const styles = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};
class ChangePassword extends React.Component {
  //masterData = this?.props?.searchPractioner?.data?.[0];
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      password: "",

    };
  }

  componentDidMount = async () => {};
  sendOTP = () => {
    const state = this.state;
    state.confirmation = true;
    this.setState({ ...this.state, ...state });
  };

  handleClickShowPassword = (key) => {
    this.setState({
      ...this.state,
      [key]: !this.state[key],
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    return (
      <>
        <div style={styles.flexCenter}>
          <p
            style={{
              margin: "20px",
              color: "black",
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            Forgot Password
          </p>
        </div>
        {/* ALERT MESSAGE */}
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              margin: "20px 20px 30px 20px",
              fontSize: "16px",
              color: "#abafb9",
            }}
          >
            The password must have Caps, alphabets, numbers
          </p>
        </div>
        <div style={{ margin: "20px" }}>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel htmlFor="outlinedAdornmentPassword">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlinedAdornmentPassword"
              type={this.state.showPassword ? "text" : "password"}
              value={this.props.password}
              onChange={(e) =>
                this.props.handleInputChange("password", e.target.value)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.handleClickShowPassword("showPassword")}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                    id="showHidePasswordBtn"
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </div>
        <div style={{ margin: "20px" }}>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel htmlFor="outlinedAdornmentConfirmPassword">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlinedAdornmentConfirmPassword"
              type={this.state.showConfirmPassword ? "text" : "password"}
              value={this.props.confirmPassword}
              onChange={(e) =>
                this.props.handleInputChange("confirmPassword", e.target.value)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      this.handleClickShowPassword("showConfirmPassword")
                    }
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                    id="showHideConfirmPasswordBtn"
                  >
                    {this.state.showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>
        </div>
        {/*  */}

        <div style={styles.buttonStyle}>
          <Button
            style={{ width: "100%", margin: "20px", textTransform: "none" }}
            variant="contained"
            disabled={this.props.loading}
            onClick={() => this.props.confirmChangePaasword()}
          >
            {this.props.loading ? `Updating...` : `Confirm`}
          </Button>
        </div>
      </>
    );
  }
}

export default ChangePassword;
