import React, { useEffect } from "react";
import { actions } from "tanyacare-middleware";
import { useDispatch, useSelector } from "react-redux";
import { drawerProps } from "../../../utils";
import { withDialog, withDrawer, withSnackBar } from "../../../HOC's";
import DrawerSkeleton from "../../drawerSkeleton";
import { NotificationCard } from "../../composedComponents/notification/notificationCard";
import ActivitySummary from "../../composedComponents/tracker/activitySummary";
import { LoadingSection } from "../../integerateHelper/loading";

const { GET_ALL_IN_APP_NOTIFICATIONS, GET_NOTIFICATION_COUNT } = actions;
const Notification = (props) => {
  const dispatch = useDispatch();

  const NotificationData = useSelector(
    (state) => state?.notification?.all_in_app
  );
  console.log(NotificationData);

  useEffect(() => {
    Promise.resolve(dispatch(GET_ALL_IN_APP_NOTIFICATIONS({}))).catch((err) => {
      console.log(err);
    });
    // eslint-disable-next-line
  }, []);

  const NotificationClickHandler = (data) => {
    props.drawer.setDrawer({
      open: true,
      anchor: drawerProps.direction.right,
      component: (handleClose) => (
        <ActivitySummary
          handleClose={() => {
            handleClose();
          }}
          onBackBtnClicked={() => {
            handleClose();
          }}
          notificationClose={props.handleClose}
          onEditBtnClicked={() => {}}
          onRescheduleBtnClicked={() => {}}
          activityLogBtnClicked={() => {}}
          // reload={props.getClientActivities}
          drawer={props.drawer}
          showServiceProfessional={true}
          hideClient={true}
          data={{ id: data?.to }}
          selectedType={() => {}}
          reload={() => {}}
          history={props.history}
          // data={{ id: data?.to }}
        />
      ),
    });
  };

  const handleCallBack = () => {
    // props.drawer.setDrawer({
    //   open: false
    // })
    Promise.resolve(dispatch(GET_ALL_IN_APP_NOTIFICATIONS({}))).catch((err) => {
      console.log(err);
    });
    Promise.resolve(dispatch(GET_NOTIFICATION_COUNT({}))).catch((err) => {
      console.log(err);
    });
  };

  return (
    <DrawerSkeleton
      buttonText={""}
      showButton={false}
      handleClose={props.handleClose}
      handleConfirm={() => {}}
      title={"Notifications"}
      parentID="FromTopnav"
    >
      {NotificationData?.loading && (
        <LoadingSection bottom={"30vh"} message="Loading Notification..." />
      )}
      {!NotificationData?.loading &&
        NotificationData?.data?.map((x) => (
          <NotificationCard
            data={x}
            onClickHandler={() => {
              NotificationClickHandler();
            }}
            callBack={() => {
              handleCallBack();
            }}
            history={props.history}
            notificationClose={props.handleClose}
          />
        ))}
    </DrawerSkeleton>
  );
};

export default withSnackBar(withDrawer(withDialog(Notification)));
