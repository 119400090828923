import React from "react";

export const HomeIcon = (props) => {
    const width = props?.width ? props?.width : 19;
    const height = props?.height ? props?.height : 16.323;
    const color = props?.color ? props?.color : '#9297a5';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 16.323"
      {...props}
    >
    <path fill={color} d="M10.5 2.1L1 10.65h2.591v7.773h6.045v-5.182h1.727v5.182h6.045V10.65H20zm0 2.324l5.182 4.664V16.7h-2.591v-5.186H7.909V16.7H5.318V9.088z" transform="translate(-1 -2.1)"/>
    </svg>
  );
};

