import PropTypes from "prop-types"
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { CustomButton } from "../..";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { dateTimeFormatter, loadingArray } from "../../../utils";

function TodoLoader({ isLast }) {
    return (
        <ListItem
            disablePadding
            divider={!isLast}
        >
            <ListItemButton role={undefined} dense disableRipple>
                <ListItemIcon sx={{ minWidth: 32 }}>
                </ListItemIcon>
                <ListItemText
                    id={"check"}
                    primary={<Skeleton width="75%" />}
                    secondary={<Skeleton width="30%" />}
                />
            </ListItemButton>
        </ListItem>
    );
}

TodoLoader.propTypes = {
    isLast: PropTypes.bool
}
function Todo(props) {
    const { todo, isLast, onToggle, onDelete, onEditClick } = props;
    const labelId = `todo${todo?.id ? todo?.id : todo?.title?.toString()?.replace(/\s/g, "")
        }`;
    return (
        <ListItem
            disablePadding
            divider={!isLast}
            secondaryAction={
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                >
                    <IconButton
                        onClick={() => onEditClick(todo, true)}
                        edge="end"
                        size="small"
                        aria-label="edit-todo"
                        id={`editTodo${todo?.id}`}
                    >
                        <CreateOutlinedIcon fontSize={"small"} color="secondary" />
                    </IconButton>
                    <IconButton
                        onClick={() => onDelete(todo?.id)}
                        edge="end"
                        size="small"
                        aria-label="delete-todo"
                        id={`deleteTodo${todo?.id}`}
                    >
                        <DeleteOutlinedIcon fontSize={"small"} color="secondary" />
                    </IconButton>
                </Stack>
            }
        >
            <ListItemButton role={undefined} dense>
                <ListItemIcon sx={{ minWidth: 32 }}>
                    <Checkbox
                        edge="start"
                        id={labelId}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled color="success" />}
                        checked={todo?.is_completed}
                        // tabIndex={-1}
                        disableRipple
                        onChange={() => onToggle(todo)}
                        inputProps={{ "aria-labelledby": labelId }}
                    />
                </ListItemIcon>
                <ListItemText
                    id={"check"}
                    primary={todo?.title}
                    secondary={dateTimeFormatter(todo?.scheduled_time)}
                />
            </ListItemButton>
        </ListItem>
    );
}

Todo.propTypes = {
    isLast: PropTypes.bool,
    onDelete: PropTypes.func,
    onEditClick: PropTypes.func,
    onToggle: PropTypes.func,
    todo: PropTypes.shape({
        id: PropTypes.string,
        is_completed: PropTypes.bool,
        scheduled_time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        title: PropTypes.string,
    })
}

function TodoCard(props) {
    const {
        data,
        isLoading,
        handleUpdate,
        handleToggle,
        handleDelete,
        handleDrawer,
        parentID=""
    } = props;
    return (
        <Paper
            sx={{
                boxShadow: "0 15px 14px 0 rgba(17, 18, 69, 0.03);",
                border: 1,
                borderColor: "grey.border",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1.5,
                    px: 2,
                    borderBottom: 1,
                    borderColor: "grey.border",
                }}
            >
                <Typography fontWeight="bold" flexGrow={1}>
                    To-do's
                </Typography>
                <CustomButton variant="text" size="small" onClick={handleDrawer} id={`addNewTodo${parentID}`}>
                    + Add New
                </CustomButton>
            </Box>
            <Box
                sx={{
                    maxHeight: 336,
                    overflow: "scroll",
                }}
            >
                <List sx={{ width: "100%", bgcolor: "background.paper", height: "100%" }}>
                    {isLoading ? (
                        loadingArray(4).map((loader, index) => (
                            <TodoLoader key={`todo-loader-${index}`} isLast={index === 3} />
                        ))
                    ) : data?.length > 0 ? (
                        data?.map((todo, index) => (
                            <Todo
                                key={todo?.id ?? index}
                                todo={todo}
                                onEditClick={handleDrawer}
                                onUpdate={handleUpdate}
                                onToggle={handleToggle}
                                onDelete={handleDelete}
                                isLast={data?.length - 1 === index}
                            />
                        ))
                    ) : (
                        <Stack alignItems="center" justifyContent="center" height="100%">
                            <Typography color="textSecondary" variant="body2" align="center" sx={{ fontStyle: "italic", my: 2 }} >
                                No Todos to show
                            </Typography>
                        </Stack>
                    )}
                </List>
            </Box>
        </Paper>
    );
}

TodoCard.propTypes = {
    data: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleDrawer: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default TodoCard;
