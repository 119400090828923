import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.fill,
  },
}));

function LogoutIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.439 5A2.452 2.452 0 0 0 5 7.44v9.76a2.452 2.452 0 0 0 2.439 2.44h6.911a2.473 2.473 0 0 0 2.414-1.83.813.813 0 1 0-1.575-.4.907.907 0 0 1-.838.608H7.439a.8.8 0 0 1-.813-.813V7.44a.8.8 0 0 1 .813-.813h6.911a.907.907 0 0 1 .838.61.812.812 0 1 0 1.575-.4A2.473 2.473 0 0 0 14.35 5H7.439Zm8.935 4.057a.813.813 0 0 0-.566 1.4l1.051 1.043H9.472a.812.812 0 1 0 0 1.626h7.387l-1.051 1.051a.814.814 0 0 0 1.15 1.15l2.442-2.435a.813.813 0 0 0 0-1.15L16.957 9.3a.814.814 0 0 0-.583-.243Z"
        className={classes.cls1}
      />
    </SvgIcon>
  );
}

LogoutIcon.defaultProps = {
  fill: "#9297A5",
};

export default LogoutIcon;
