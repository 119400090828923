import React, { useState, useRef } from "react";
import { styled, alpha } from "@mui/material/styles";
import useTheme from "@mui/styles/useTheme";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { CustomButton } from "..";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FilterIcon } from "../../assets";
import { Badge } from "@mui/material";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.primary.main, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.primary.main, 0.25),
//   },
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   //   color: "inherit",
//   color: theme.palette.text.primary,
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       width: "12ch",
//       "&:focus": {
//         width: "20ch",
//       },
//     },
//   },
// }));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showBackground'})(({ theme }) => ({
   position:'sticky',
    zIndex: 2,
    ...theme.mixins.customTop,
}));

function SearchAndFilter(props) {
  const theme = useTheme();
  const { onSearchChange, onFilterClick, showBackground = true,parentID="" } = props;
  const searchRef = useRef(null);

  // Component States
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <StyledBox sx={{ flexGrow: 1 }}>
      <AppBar
        // position="sticky"
        position="static"
        elevation={0}
        sx={{
          bgcolor: showBackground ? "common.white" : "transparent",
          borderBottom: showBackground ? 1 : 0,
          borderColor: showBackground ? "grey.300" : "none",
        }}
      >
        <Toolbar>
          {searchOpen ? (
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <IconButton
                edge="end"
                color="inherit"
                aria-label="filter"
                sx={{ mr: 0 }}
                onClick={() => setSearchOpen((prev) => !prev)}
                id={`searchBackBtnIcon${parentID}`}
              >
                <ChevronLeftIcon color="primary" />
              </IconButton>
              <InputBase
                inputRef={searchRef}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search..."
                inputProps={{ autoFocus: true, "aria-label": "search" }}
                onChange={onSearchChange}
                id={`searchTextfield${parentID}`}
              />
              <Stack
                flexDirection="row"
                gap={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <CustomButton
                  variant="text"
                  size="small"
                  onClick={() => {
                    searchRef.current.value = "";
                    onSearchChange({
                      target: {
                        value: "",
                      },
                    });
                  }}
                  id={`searchClearBtn${parentID}`}
                >
                  Clear
                </CustomButton>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="close-search"
                  sx={{ mr: 2 }}
                  onClick={() => setSearchOpen((prev) => !prev)}
                  id={`searchCloseIcon${parentID}`}
                >
                  <CloseIcon color="primary" fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <>
              {props.title && (
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color="textPrimary"
                  sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
                >
                  {showBackground ? props.title : ""}
                </Typography>
              )}
              <Stack flexDirection="row" justifyContent="flex-end" gap={2}>
                {/* <Search>
                  <SearchIconWrapper>
                    <SearchIcon color="primary" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    onChange={onSearchChange}
                  />
                </Search> */}
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="filter"
                  onClick={() => setSearchOpen((prev) => !prev)}
                  id={`searchIcon${parentID}`}
                >
                  <SearchIcon color="primary" />
                </IconButton>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="filter"
                  sx={{ mr: 2 }}
                  onClick={onFilterClick}
                  id={`filterIcon${parentID}`}
                >
                  <Badge
                    color={"secondary"}
                    variant="dot"
                    invisible={!props?.hasFilter ?? false}
                  >
                    <FilterIcon stroke={theme.palette.primary.main} />
                  </Badge>
                </IconButton>
              </Stack>
            </>
          )}
        </Toolbar>
      </AppBar>
    </StyledBox>
  );
}

export default SearchAndFilter;
