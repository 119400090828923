import React, { useEffect } from "react";
import PropTypes from "prop-types";

//package
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";
import { withRouter } from "react-router";

//material ui
import Skeleton from "@mui/material/Skeleton";
import { makeStyles, styled } from "@mui/styles";
import { alpha, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

//component
import { routes } from "../../../router/routes";
import TopNav from "../topNavBar";
// import BottomNavigation from "../bottomNavBar";
import { AuthContext } from "../../../contexts";

//utils
import { placeholderTexts } from "../../../utils/placeholderTexts";
import { NavLink } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

// const Avatar = React.lazy(() => import("@mui/material/Avatar"));

// --

const AppBar = React.lazy(() => import("@mui/material/AppBar"));
const Box = React.lazy(() => import("@mui/material/Box"));
const CssBaseline = React.lazy(() => import("@mui/material/CssBaseline"));
const Divider = React.lazy(() => import("@mui/material/Divider"));
const Drawer = React.lazy(() => import("@mui/material/Drawer"));
// const IconButton = React.lazy(() => import("@mui/material/IconButton"));
const List = React.lazy(() => import("@mui/material/List"));
const ListItem = React.lazy(() => import("@mui/material/ListItem"));
const ListItemIcon = React.lazy(() => import("@mui/material/ListItemIcon"));
const ListItemText = React.lazy(() => import("@mui/material/ListItemText"));
const Toolbar = React.lazy(() => import("@mui/material/Toolbar"));
const Typography = React.lazy(() => import("@mui/material/Typography"));
const BottomNavigation = React.lazy(() => import("../bottomNavBar"));

// const { makeStyles } = React.lazy(() => import("@mui/styles"));
// const ForwardToInboxIcon = React.lazy(() => import("@mui/icons-material/ForwardToInbox"));
// const MenuIcon = React.lazy(() => import("@mui/icons-material/Menu"));
// --
const drawerWidth = 240;

const style = makeStyles((theme) => ({
  toplogo: {
    // paddingLeft: 10,
    // paddingTop: 16,
    width: 150,
  },
  profilePic: {
    // background: `${alpha(theme.palette.secondary.light, 0.2)} `,
  },
  commonTextOverflow: {
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  profileEmail: {
    // margin: "10px 0px 0px 0px !important",
  },
  profileDivider: {
    margin: "10px 0px !important",
    width: "10%",
  },
  profileSection: {
    padding: "8px 16px",
    textAlign: "left",
  },
  listItemSelected: {
    backgroundColor: `${alpha(theme.palette.secondary.light, 0.08)} !important`,
  },
  bottomMenusContainer: {
    // position: "absolute",
    // bottom: 0,
    [theme.breakpoints.down("sm")]: {
      bottom: "-30px",
    },
  },
  bottomMenus: {
    padding: "4px 16px",
  },
  // listMenuItem: {
  //   cursor: "pointer",
  //   transition: "0.2s",
  //   "&$selected": {
  //     backgroundColor: `${alpha(theme.palette.secondary.light, 0.1)}`,
  //     "& .MuiListItemText-primary": {
  //       // fontFamily: "tanyacare_bold_calibri !important",
  //       fontWeight: "bold"
  //     },
  //     "&:hover": {
  //       backgroundColor: `${alpha(theme.palette.secondary.light, 0.1)}`,
  //     }
  //   },
  //   "&:hover": {
  //     backgroundColor: `${alpha(theme.palette.secondary.light, 0.06)} !important`,
  //   },
  //   selected: {}

  //   // "&:hover": {
  //   //   backgroundColor: `${alpha(theme.palette.secondary.light, 0.06)} !important`,
  //   //   "& .Mui-selected": {
  //   //     backgroundColor: `${alpha(theme.palette.secondary.light, 0.1)} !important`,
  //   //   }
  //   // },
  // },
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
  cursor: "pointer",
  transition: "0.2s",

  // Hover Style--
  "&:hover": {
    backgroundColor: `${alpha(theme.palette.secondary.light, 0.06)} !important`,
  },

  // Selected Style--
  "&.Mui-selected": {
    backgroundColor: `${alpha(theme.palette.secondary.light, 0.08)} !important`,
    //Selected font Style--
    "& .MuiListItemText-primary": {
      fontWeight: "bold",
    },
    // Selected hover Style--
    "&:hover": {
      backgroundColor: `${alpha(
        theme.palette.secondary.light,
        0.09
      )} !important`,
    },
  },
}));

//actions
const { GET_CLIENT_PROFILE, LOGOUT_AUTH } = actions;

function SideNavBar(props) {
  const { window } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = style();
  const authContext = React.useContext(AuthContext);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const xsOnly = useMediaQuery(theme.breakpoints.down("sm"));

  //useSelector
  const profileData = useSelector((state) => state?.clientProfile?.getProfile);

  //components state
  const [mobileOpen, setMobileOpen] = React.useState(false);

  //ComponentDidMount and dispatch Dependencies
  useEffect(() => {
    dispatch(GET_CLIENT_PROFILE({}));
  }, [dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    authContext.setAuth({ ...authContext.auth, refresh: false });
    dispatch(LOGOUT_AUTH({}));
    localStorage.removeItem("_mGdRe");
  };

  const handleNavigation = (text) => {
    props.history.push(routes[text].pathName);
    handleDrawerToggle();
  };

  const isRouteSelected = (text) => {
    // Current Parent Route
    const selectedRoute = routes[text];

    // Push the parent route pathName to currentRoutes array
    const currentRoutes = [selectedRoute.pathName];

    // If current parent route has any subroute,
    // then push the pathName of the subroutes to currentRoutes array
    if (selectedRoute?.subRoutes) {
      const subRoutes =
        Object.keys(selectedRoute?.subRoutes)?.length > 0
          ? Object.keys(selectedRoute?.subRoutes)
          : [];
      subRoutes.forEach((routeKey) => {
        currentRoutes.push(selectedRoute?.subRoutes[routeKey]?.pathName);
      });
    }

    // return if currentRoutes array contains the selected route
    return currentRoutes.includes(props.match.path);
  };

  const drawer = (
    <>
      <div style={{ position: "sticky", top: 0 }}>
        <img
          src="/assets/tanyaCare/TanyaHorizontal.png"
          // src="/svg/tanyacareLogo/tanyacareLight.svg"
          alt="Tanya.Care"
          // className={classes.logo}
          className={classes.toplogo}
        />
        <Typography variant="caption" color="textSecondary">
          {"v" +
            // placeholderTexts.versionText
            (localStorage.getItem("version") ?? placeholderTexts.versionText)}
        </Typography>
        <div className={classes.profileSection}>
          {/* <CustomAvatar
            src={`${profileData?.data?.profilePic}`}
            name={profileData?.data?.clientName}
            loading={profileData?.loading}
          /> */}
          <Box sx={{ mt: 1.25 }}>
            {profileData?.loading ? (
              <Skeleton variant="text" />
            ) : (
              <>
                <Typography>Hello,</Typography>
                <Typography
                  variant="h6"
                  className={`${classes.commonTextOverflow}`}
                >
                  {`${`${
                    profileData?.data?.salutation?.label &&
                    profileData?.data?.salutation?.label !== "other"
                      ? `${profileData?.data?.salutation?.label}.`
                      : ``
                  }${
                    profileData?.data?.clientName ??
                    placeholderTexts.sideBarProfileName
                  }`}`}
                </Typography>
              </>
            )}
          </Box>

          <Tooltip title={profileData?.data?.emailid} arrow>
            <Typography
              // variant="subtitle2"
              className={`${classes.profileEmail} ${classes.commonTextOverflow}`}
            >
              {profileData?.loading ? (
                <Skeleton variant="text" />
              ) : (
                profileData?.data?.emailid ?? placeholderTexts.sideBarEmailId
              )}
            </Typography>
          </Tooltip>
          <Divider className={classes.profileDivider} />
        </div>
      </div>
      <div style={{ overflow: "auto" }}>
        <List>
          {(Object.keys(routes)?.length > 0 ? Object.keys(routes) : []).map(
            (text, index) => {
              console.log(text);
              return (
                <CustomListItem
                  onClick={() => {
                    "logOut" === text ? handleLogout() : handleNavigation(text);
                  }}
                  key={routes[text]?.label ?? placeholderTexts?.textNotFound}
                  selected={isRouteSelected(text)}
                  // className={classes.listMenuItem}
                  classes={{
                    selected: classes.listItemSelected,
                  }}
                  id={`drawerList${routes[text]?.label}`}
                >
                  <ListItemIcon>
                    {isRouteSelected(text)
                      ? routes[text].selectedIcon(theme)
                      : routes[text].icon}
                    {/* {index % 2 === 0 ? <AboutIcon /> : <AboutIcon />} */}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      routes[text]?.label ?? placeholderTexts?.textNotFound
                    }
                  />
                </CustomListItem>
              );
            }
          )}
        </List>
        <div className={classes.bottomMenusContainer}>
          <NavLink
            to={{ pathname: "https://www.tanya.care/privacy-policy/" }}
            style={{ textDecoration: "none" }}
            target="_blank"
            id="drawerListPrivacyPolicy"
          >
            <Typography className={classes.bottomMenus} variant="subtitle2">
              Privacy Policy
            </Typography>
          </NavLink>
          <NavLink
            to={{ pathname: "https://www.tanya.care/terms-of-use/" }}
            style={{ textDecoration: "none" }}
            target="_blank"
            id="drawerListTermsConditions"
          >
            <Typography className={classes.bottomMenus} variant="subtitle2">
              Terms and Conditions
            </Typography>
          </NavLink>
        </div>
      </div>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiPaper-root": {
              pl: 1,
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // px: { xs: 1, lg: 2, },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          bgcolor: "grey[400]",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            // width: { sm: `calc(100% - ${drawerWidth}px)` },
            // ml: { sm: `${drawerWidth}px` },
            width: 1,
            boxShadow: "none !important",
            // height:{xs:56,md:64}
          }}
        >
          <Toolbar sx={{ padding: "0 0 !important" }}>
            {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography> */}
            <TopNav handleDrawerToggle={handleDrawerToggle} />
          </Toolbar>
        </AppBar>

        {/* This toolbar is used as a filler for above appbar, Don't remove it at all cost */}
        <Toolbar />
        {/* <Box sx={{
          height:{
            xs:`calc(100vh - (${topnavHeight}px + 70px))`, //calc(100vh -( topnav height + bottomnav height))
            sm:`calc(100vh - ${topnavHeight}px)`,  //calc(100vh - topnav height )
          },
          overflow:'auto'
        }}> */}
        <Box sx={{ mb: { xs: "70px", sm: "0px" } }}>{props.children}</Box>
        {/* </Box> */}
        {props?.location?.pathname !== "/profile" && xsOnly ? (
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              height: "70px",
              backgroundColor: "#ffffff",
              alignItems: "center",
              width: "100%",
              boxShadow: "0 3px 20px rgb(0 0 0 / 20%)",
              // overflowX:'hidden',
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <BottomNavigation selectedPath={props.match.path} />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

SideNavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withRouter(SideNavBar);
