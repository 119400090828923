import { SvgIcon } from "@mui/material";
import * as React from "react";

const PackageIcon = (props) => (
  <SvgIcon
    // xmlns="http://www.w3.org/2000/svg"
    // width="1em"
    // height="1em"
    {...props}
  >
    {/* <g data-name="package icon">
      <g data-name="Group 93300">
        <g data-name="Group 93388">
          <path
            d="M1.922 0 0 2.644v9.821h8.11v-.052c.576-.1.129 0 .129 0A14.521 14.521 0 0 1 9.5 10.676a12.736 12.736 0 0 1 1.488-1.428s1.226-.648 1.1-.594v-6.01L10.161 0Zm.549 1.133h3.021v1.133H1.648Zm4.119 0h3.021l.824 1.133H6.591ZM1.1 3.4h9.886v6.134c-.409-.407-2.875 2.931-2.875 2.931s-.961-1.51-.816-1.133H1.1Zm3.3 1.133a.567.567 0 0 0 0 1.133h3.3a.567.567 0 0 0 0-1.133Zm3.845 7.932c.356-.236-.3.16 0-.052.776-.537-.129 0-.129 0s-.476-.53.122.052Z"
            fill="#9297a5"
            data-name="Group 93296"
          />
          <path
            d="M11.23 7.644a1.973 1.973 0 0 0-1.574.777 1.967 1.967 0 0 0-1.568-.777 2.049 2.049 0 0 0-2.042 2.051 2.955 2.955 0 0 0 .122.839h1.09l.249-.5a.148.148 0 0 1 .137-.08.145.145 0 0 1 .128.094l.258.687.4-1.966a.144.144 0 0 1 .141-.115.144.144 0 0 1 .14.119l.492 2.671.538-1.4a.144.144 0 0 1 .135-.092.145.145 0 0 1 .135.094l.331.882.206-.33a.144.144 0 0 1 .122-.068h.763a.289.289 0 1 1 0 .289h-.687l-.319.51a.147.147 0 0 1-.137.067.145.145 0 0 1-.121-.093l-.3-.8-.581 1.528a.144.144 0 0 1-.135.093H9.14a.145.145 0 0 1-.129-.118L8.566 9.56l-.355 1.726a.145.145 0 0 1-.13.115.143.143 0 0 1-.147-.093l-.317-.846-.141.282a.144.144 0 0 1-.129.08H6.268a6.336 6.336 0 0 0 2.542 2.56c.293.2.752.581.757.585a.137.137 0 0 0 .089.031.165.165 0 0 0 .1-.037 8.975 8.975 0 0 1 .748-.579c1.166-.784 2.764-1.858 2.764-3.689a2.049 2.049 0 0 0-2.038-2.051Z"
            fill="#9297a5"
          />
        </g>
      </g>
    </g> */}
    <path
      d="M6.922 5 5 7.644v9.821h8.11v-.052c.576-.1.129 0 .129 0 .377-.61.798-1.19 1.261-1.737a12.74 12.74 0 0 1 1.488-1.428s1.226-.648 1.1-.594v-6.01L15.161 5H6.922Zm.549 1.133h3.021v1.133H6.648l.823-1.133Zm4.119 0h3.021l.824 1.133h-3.844l-.001-1.133ZM6.1 8.4h9.886v6.134c-.409-.407-2.875 2.931-2.875 2.931s-.961-1.51-.816-1.133H6.1V8.4Zm3.3 1.133a.567.567 0 0 0 0 1.133h3.3a.567.567 0 0 0 0-1.133H9.4Zm3.845 7.932c.356-.236-.3.16 0-.052.776-.537-.129 0-.129 0s-.476-.53.122.052h.007Z"
      fill="#9297A5"
    />
    <path
      d="M16.23 12.644a1.973 1.973 0 0 0-1.574.777 1.967 1.967 0 0 0-1.568-.777 2.05 2.05 0 0 0-2.042 2.051c0 .284.041.567.122.839h1.09l.249-.5a.148.148 0 0 1 .137-.08.145.145 0 0 1 .128.094l.258.687.4-1.966a.144.144 0 0 1 .141-.115.144.144 0 0 1 .14.119l.492 2.671.538-1.4a.144.144 0 0 1 .135-.092.145.145 0 0 1 .135.094l.331.882.206-.33a.144.144 0 0 1 .122-.068h.763a.289.289 0 1 1 0 .289h-.687l-.319.51a.148.148 0 0 1-.137.067.145.145 0 0 1-.121-.093l-.3-.8-.581 1.528a.144.144 0 0 1-.135.093h-.013a.145.145 0 0 1-.129-.118l-.445-2.446-.355 1.726a.144.144 0 0 1-.13.115.143.143 0 0 1-.147-.093l-.317-.846-.141.282a.144.144 0 0 1-.129.08h-1.079a6.337 6.337 0 0 0 2.542 2.56c.293.2.752.581.757.585.025.02.057.031.089.031a.165.165 0 0 0 .1-.037c.239-.206.489-.4.748-.579 1.166-.784 2.764-1.858 2.764-3.689a2.049 2.049 0 0 0-2.038-2.051Z"
      fill="#9297A5"
    />
  </SvgIcon>
);

export default PackageIcon;
