import PropTypes from "prop-types";
import React from "react";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { CustomTextfield } from "..";

function CustomDatePicker(props) {
  const { InputProps = {} } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        renderInput={(componentProps) => (
          <CustomTextfield
            {...componentProps}
            {...InputProps}
            id={componentProps.id}
            name={componentProps.name}
            placeholder={componentProps.placeholder}
            // error={props.error}
            // helperText={props.helperText}
          />
        )}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        {...props}
      />
    </LocalizationProvider>
  );
}

CustomDatePicker.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
};

export default CustomDatePicker;
