import * as React from "react";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { AvatarGroup, Box, Button, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomAvatar from "../avatar";
import {
  currencyPriceFormatter,
  dateTimeFormatterV2,
  tanyacareContactInfo,
  textClampStyleGenerator,
} from "../../utils";

const EnquiryNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
  // fontSize:"24px"
}));

const EnquiryDetailsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.enquiry.status.open,
  borderColor: theme.palette.enquiry.status.open,
  backgroundColor: "#rgba(242, 125, 53, 0.04)",
  border: "1px solid",
  padding: "0.5rem 40px",
  "&:hover": {
    borderColor: `${theme.palette.enquiry.status.open} !important`,
    color: theme.palette.enquiry.status.open,
    backgroundColor: theme.palette.common.white,
  },
}));

const style = makeStyles((theme) => ({
  centerAlign: {
    width: "fit-content !important",
    margin: "auto !important",
  },
  success: {
    backgroundColor: "#31B198",
    color: `${theme.palette.common.white} !important`,
    borderRadius: 16,
  },
  failure: {
    backgroundColor: "#BC2D4F",
    color: `${theme.palette.common.white} !important`,
    borderRadius: 16,
  },
}));

function PurchaseSuccess(props) {
  const classes = style();
  const { variant, order_data, profile_data, onRedirect } = props;

  // const paymentInitiate = useSelector(
  //   (state) => state?.clientPayment?.initiate
  // );

  // const [name, setName] = React.useState("");

  // React.useEffect(() => {
  //   if(props.name)
  //   {
  //     setName(props?.name)
  //   }
  // },[props.name])

  return (
    <>
      <Box sx={{ padding: { xs: "0.2rem ", md: "0.1rem 0.25rem" } }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItem={"center"}
          >
            <Grid item xs={12} justifyContent="center">
              <Box
                className={`${variant ? classes.success : classes.failure}`}
                sx={{
                  border: "1px solid",
                  padding: {
                    xs: "12px 14px 34px 14px",
                    md: "12px 14px 30px 73px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "fit-content",
                    marginLeft: "auto",
                    paddingBottom: { xs: "10px", md: "10px" },
                  }}
                >
                  <IconButton
                    onClick={() => onRedirect()}
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: variant ? "#3e8071" : "#BC2D4F",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Box>
                <Box sx={{ paddingRight: { xs: "0px", md: "75px" } }}>
                  <div style={{ width: "fit-content", margin: "auto" }}>
                    {variant ? (
                      <CheckCircleIcon sx={{ fontSize: 45 }} />
                    ) : (
                      <CancelIcon sx={{ fontSize: 45 }} />
                    )}
                  </div>
                  <Typography
                    align="center"
                    sx={{ fontSize: "1rem !important", color: "white" }}
                  >
                    Hi{" "}
                    {props?.name
                      ? props?.name
                      : `${
                          profile_data?.data?.salutation?.label
                            ? `${profile_data?.data?.salutation?.label}.`
                            : ``
                        } ${profile_data?.data?.clientName}`}
                  </Typography>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "1.5rem !important",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {variant
                      ? `Thank you for Purchasing the service`
                      : `Your payment is failed for the order id  ${
                          props?.paymentInitiate?.data?.orderId ?? props.orderId
                        }`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <EnquiryNameTypography sx={{ padding: "0.75rem 0 0.50rem 0" }}>
                Purchase Details
              </EnquiryNameTypography>
              <Divider sx={{ opacity: "0.5" }} />
            </Grid>
            <Grid item xs={12} justifyContent="center">
              {" "}
              <div
                style={{
                  width: "fit-content",
                  margin: "auto",
                  marginTop: "1rem",
                }}
              >
                {/* <CustomAvatar className={classes.centerAlign} src={""} name="f" width={60} height={60}>F</CustomAvatar> */}
                {/* <Avatar src={""}  name="f" sx={{width: 65, height:65, bgcolor:"#671a12"}}>F</Avatar> */}
                <AvatarGroup max={4}>
                  {order_data?.items?.map((x) => (
                    <CustomAvatar
                      src={x?.image}
                      name={x?.name?.[0]}
                      alt={x?.name}
                    />
                  ))}
                </AvatarGroup>
              </div>
              {/* <Avatar src={""} name="R" sx={{ width: 56, height:56}}>F</Avatar> */}
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginLeft: "1rem" }}>
                {/* <EnquiryIdTypography className={classes.centerAlign} sx={{fontSize:"0.95rem"}}>#EOD_01</EnquiryIdTypography> */}
                <EnquiryNameTypography
                  className={classes.centerAlign}
                  textAlign={"center"}
                  sx={{ fontSize: "1.25rem", ...textClampStyleGenerator(2) }}
                >
                  {order_data?.items?.map((x, index) => {
                    if (index === order_data?.items?.length - 1) {
                      return `${x.name}`;
                    } else {
                      return `${x.name} && `;
                    }
                  })}
                </EnquiryNameTypography>
                <Typography
                  variant="body2"
                  // align="left"
                  className={classes.centerAlign}
                  color="text.secondary"
                  sx={{
                    display: "flex",
                    fontWeight: "bold",
                    wordSpacing: "1.2px",
                  }}
                >
                  {`Date of Purchase - ${dateTimeFormatterV2(
                    order_data?.purchased_at
                  )}`}
                </Typography>
                <EnquiryNameTypography
                  className={classes.centerAlign}
                  sx={{ fontSize: "1rem", paddingY: "0.50rem" }}
                >
                  {`Amount: ${currencyPriceFormatter(
                    order_data?.currency,
                    order_data?.total_amount
                  )}`}
                </EnquiryNameTypography>
                {variant ? (
                  <Typography
                    variant="body2"
                    align="center"
                    // className={classes.centerAlign}
                    color="text.secondary"
                    sx={{ wordSpacing: "1.2px", paddingY: "1rem" }}
                  >
                    Thank you for being a customer. Let us know your experience
                    by sharing your review{" "}
                    <a href={`mailto:${tanyacareContactInfo.mail}`}>here</a>. We
                    appreciate your feedback
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    align="center"
                    // className={classes.centerAlign}
                    color="text.secondary"
                    sx={{ wordSpacing: "1.2px", paddingY: "1rem" }}
                  >
                    An error has occurred. If you continue to face the same
                    issue, please contact our{" "}
                    <a href={`tel:${tanyacareContactInfo.phone}`}>Helpdesk</a>
                  </Typography>
                )}

                <div
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    marginTop: "1rem",
                  }}
                >
                  {/* <EnquiryDetailsButton> View Order</EnquiryDetailsButton> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PurchaseSuccess;
