import * as React from "react";

function GreenCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="369"
      height="209"
      viewBox="0 0 369 209"
    >
      <g id="green_tick" data-name="green tick" transform="translate(-839 -27)">
        <circle
          id="Ellipse_16"
          data-name="Ellipse 16"
          cx="4.5"
          cy="4.5"
          r="4.5"
          transform="translate(1109 32)"
          fill="#353596"
          opacity="0.059"
        />
        <circle
          id="Ellipse_18"
          data-name="Ellipse 18"
          cx="4.5"
          cy="4.5"
          r="4.5"
          transform="translate(944 27)"
          fill="#353596"
          opacity="0.059"
        />
        <circle
          id="Ellipse_11"
          data-name="Ellipse 11"
          cx="8.5"
          cy="8.5"
          r="8.5"
          transform="translate(1191 184)"
          fill="#353596"
          opacity="0.059"
        />
        <circle
          id="Ellipse_14"
          data-name="Ellipse 14"
          cx="8.5"
          cy="8.5"
          r="8.5"
          transform="translate(1092 127)"
          fill="#353596"
          opacity="0.059"
        />
        <circle
          id="Ellipse_15"
          data-name="Ellipse 15"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1145 178)"
          fill="#353596"
          opacity="0.059"
        />
        <circle
          id="Ellipse_12"
          data-name="Ellipse 12"
          cx="6"
          cy="6"
          r="6"
          transform="translate(839 102)"
          fill="#353596"
          opacity="0.059"
        />
        <circle
          id="Ellipse_13"
          data-name="Ellipse 13"
          cx="6"
          cy="6"
          r="6"
          transform="translate(909 155)"
          fill="#353596"
          opacity="0.059"
        />
        <circle
          id="Ellipse_17"
          data-name="Ellipse 17"
          cx="6"
          cy="6"
          r="6"
          transform="translate(1009 66)"
          fill="#353596"
          opacity="0.059"
        />
        <g
          id="Group_1819"
          data-name="Group 1819"
          transform="translate(836 -133)"
        >
          <circle
            id="Ellipse_10"
            data-name="Ellipse 10"
            cx="50"
            cy="50"
            r="50"
            transform="translate(138 269)"
            fill="#31b198"
          />
          <path
            id="Path_41"
            data-name="Path 41"
            d="M-1568.565,3883.577l9.857,9.857,20.884-20.884"
            transform="translate(1741.065 -3564.05)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5"
          />
        </g>
      </g>
    </svg>
  );
}

export default GreenCheckIcon;
