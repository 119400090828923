import { SvgIcon } from "@mui/material";
import * as React from "react";

function AlertIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5a7 7 0 107 7 7.02 7.02 0 00-7-7zm0 1.4A5.6 5.6 0 116.4 12 5.617 5.617 0 0112 6.4zm-.56 2.1a.15.15 0 00-.14.14v3.92a.15.15 0 00.14.14h1.12a.15.15 0 00.14-.14V8.64a.15.15 0 00-.14-.14h-1.12zm0 5.6a.15.15 0 00-.14.14v1.12a.15.15 0 00.14.14h1.12a.15.15 0 00.14-.14v-1.12a.15.15 0 00-.14-.14h-1.12z"
        fill="#F03E50"
      />
    </SvgIcon>
  );
}

export default AlertIcon;
