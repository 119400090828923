import PropTypes from "prop-types";
import React from "react";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { CustomTextfield } from "..";

function CustomDateTimePicker(props) {
  const { InputProps = {} } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        renderInput={(componentProps) => (
          <CustomTextfield
            {...componentProps}
            {...InputProps}
            id={props.id}
            name={componentProps.name}
            placeholder={componentProps.placeholder}
            error={componentProps.error}
            helperText={componentProps.helperText}
          />
        )}
        PopperProps={{
          placement:'auto',
        }}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        {...props}
      />
    </LocalizationProvider>
  );
}

CustomDateTimePicker.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func,
};

export default CustomDateTimePicker;
