import React, { useContext } from 'react'
import { SnackContext } from '../contexts'

const withSnackBar = (WrappedComponent) => (props) => {
    const snack = useContext(SnackContext)
    return (
        <WrappedComponent {...props} snack={snack}>{props.children}</WrappedComponent>
    )
}

export default withSnackBar
