import React from "react";
import {
  Box,
  Paper,
  Grid,
  Stack,
  IconButton,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { CustomAvatar } from "../../../components";
import { CreateIcon, DeleteIcon } from "../../../assets";
import { getAgeString, getGenderString } from "../../../utils";
import FamilyMemberIcon from "../../../assets/familyMember.svg";
import { withDrawer } from "../../../HOC's";
import { ViewMemberDetailsDrawer } from ".";
import { StyledChip } from "./styledChip";

const StyledPaper = styled(Paper)(() => ({
  // margin: "0px 8px",
  border: "1px solid #E0E0E0",
  borderRadius: 8,
  padding: 12,
}));

function FamilyMemberCard(props) {
  const {
    member,
    onEditClick = () => { },
    onDeleteClick = () => { },
    // onViewMoreClick = () => {},
    hideViewMore,
    sx,
  } = props;

  const onViewMoreClick = () => {
    const closeDrawer = () => {
      props.drawer.setDrawer({
        open: false,
        component: <></>,
      });
    };

    const onEditClickInsideDrawer = () => {
      closeDrawer();
      onEditClick(member?.id);
    };
    const onDeleteClickInsideDrawer = () => {
      closeDrawer();
      onDeleteClick(member?.id, member?.isEmergenctContact);
    };

    // Open Drawer with Details
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewMemberDetailsDrawer
          member={member}
          handleClose={handleClose}
          onEditClick={onEditClickInsideDrawer}
          onDeleteClick={onDeleteClickInsideDrawer}
        />
      ),
    });
  };

  const getMemberMeta = ({
    member: person,
    onViewMoreClick: viewMoreHandler,
    hideViewMore: hideMore,
  }) => {
    return (
      <Grid item xs={12}>
        <Stack
          flexDirection="row"
          flexWrap={"wrap"}
          gap={1}
          justifyContent="space-between"
        >
          <Stack
            flexDirection="row"
            flexWrap={"wrap"}
            gap={1}
            alignItems="flex-end"
          >
            <StyledChip label={person?.relationship?.label} color="success" />
            {person?.isEmergencyContact && (
              <StyledChip label="Emergency Contact" color="error" />
            )}
          </Stack>
          {!hideMore && (
            <Button
            id={`viewMore${member?.id}`}
              variant="text"
              color="primary"
              size="small"
              sx={{
                whiteSpace: "nowrap",
                textTransform: "none",
                fontWeight: 400,
                ml: "auto",
              }}
              onClick={viewMoreHandler}
            >
              View More
            </Button>
          )}
        </Stack>
      </Grid>
    );
  };

  return (
    <StyledPaper elevation={0} sx={{ ...sx }}>
      <Grid container>
        <Grid container item xs={12} wrap="nowrap">
          <Grid item>
            <Box sx={{ mr: 2.5 }}>
              <CustomAvatar src={FamilyMemberIcon} name={member?.name} />
            </Box>
          </Grid>
          <Grid container item xs>
            <Grid item xs={12}>
              <Stack flexDirection="row" justifyContent="space-between">
                <Box>
                  <Typography align="left" sx={{ fontWeight: 500 }}>
                    {member?.salutation?.label
                      ? `${member?.salutation?.label}. `
                      : ""}
                    {member?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="left"
                    gutterBottom
                  >
                    {member?.gender ? getGenderString(member?.gender) : ""}
                    {!!member?.gender && !!member?.dob ? ", " : ""}
                    {member?.dob ? getAgeString(member?.dob) : ""}
                  </Typography>
                </Box>
                <Stack flexDirection="row" gap={0.5}>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => onEditClick(member?.id)}
                      id={`editMember${member?.id}`}
                    >
                      <CreateIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => {
                        onDeleteClick(member?.id, member?.isEmergencyContact)
                      }}
                      id={`deleteMember${member?.id}`}
                    >
                      <DeleteIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            {getMemberMeta({ member, onViewMoreClick, hideViewMore })}
            {/* <Hidden smDown>{getMemberMeta({ member, onViewMoreClick, hideViewMore })}</Hidden> */}
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Hidden smUp>
              <Box sx={{ml: 7.5}}>
              {getMemberMeta({ member, onViewMoreClick, hideViewMore })}
              </Box>
          </Hidden>
        </Grid> */}
      </Grid>
    </StyledPaper>
  );
}

export default withDrawer(FamilyMemberCard);
