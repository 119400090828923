import React from "react";
import { useRouteMatch } from "react-router-dom";
import { dontShowInMenuRoutes as routes } from "../../router/routes";
import ProfileDetail from "./detail";
import EditFamilyMembers from "./editFamilyMembers";
import EditHealthInfo from "./editHealthInfo";
import EditProfile from "./editProfile";

function Profile() {
  const match = useRouteMatch();
  const { subRoutes } = routes.profile;
  switch (match.path) {
    case routes.profile.pathName:
      return <ProfileDetail />;
    case subRoutes.editProfile.pathName:
      return <EditProfile />;
    case subRoutes.editFamilyMember.pathName:
      return <EditFamilyMembers />;
    case subRoutes.editHealthInfo.pathName:
      return <EditHealthInfo />;
    default:
      return "";
  }
}

export default Profile;
