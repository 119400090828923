import PropTypes from "prop-types"
import React from 'react'
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import ItemParticulars from "../itemParticulars"
// import ItemShipping from "../itemShipping"
import ItemOverview from '../itemOverview'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

function OrderDetail(props) {
    return (
        <Grid container spacing={0}>
            {props.isBackNeeded && <IconButton size="small" onClick={props.onBackClick} sx={{ position: "absolute", mt: 3, mx: 2 }} >
                <ArrowBackOutlinedIcon />
            </IconButton>}
            <Grid item xs={12}>
                <ItemOverview data={props.selectedItem} loading={props.loading} handleRedirect={props.handleServiceDetailRedirect} />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{bgcolor: "background.appbar", px: { xs: 1.5, md: 2.5 }, py: 1}} >
                    <Typography variant="body2" color="textSecondary" align="center" >Order ID: {props.selectedItem?.orderId}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <ItemParticulars data={props.selectedItem} loading={props.loading} />
            </Grid>

            {/* Not in Client APP */}
            {/* <Grid item xs={12}>
                <ItemShipping data={props.selectedItem} />
            </Grid> */}
        </Grid>
    )
}

OrderDetail.propTypes = {
    isBackNeeded: PropTypes.bool,
    onBackClick: function (props, propName, componentName) {
        if (props['isBackNeeded'] === true && (props[propName] === undefined || typeof (props[propName]) != 'function')) {
            return new Error(
                'Failed prop type: Invalid prop `' + propName + '` of type `undefined` supplied to `' + componentName + '`, expected `function`.'
            );
        }
    },
    selectedItem: PropTypes.object
}

export default OrderDetail
