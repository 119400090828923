import {
  Box,
  Divider,
  Stack,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import {
  ButtonWithInputField,
  CustomButton,
  CustomTextfield,
  RichTextEditor,
  StyledToggleButtonGroup,
} from "../../components";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { bmiCalculation } from "../../utils";
import { RightArrowStripesIcon } from "../../assets";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Prompt } from "../registration/components";
import isEqual from "react-fast-compare";

// const StyledBox = styled(Box)(({ theme }) => ({
//   [theme.breakpoints.up("xs")]: {
//     margin: "0.5rem",
//   },
//   [theme.breakpoints.up("md")]: {
//     margin: "1rem 2rem",
//   },
//   [theme.breakpoints.up("lg")]: {
//     margin: "1rem 3rem",
//   },
// }));
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    margin: "0.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    margin: "2rem 2.5rem 0rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "1rem 2rem 3rem",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "2rem 0rem 5rem 1.5rem",
  },
}));

const submittedByData = [
  {
    label: "You",
    value: "you",
  },
  {
    label: "Tanya Care",
    value: "other",
  },
];

const BloodGroupData = [
  {
    label: "O+ve",
    value: "O+ve",
  },
  {
    label: "A+ve",
    value: "A+ve",
  },
  {
    label: "B+ve",
    value: "B+ve",
  },
  {
    label: "A-ve",
    value: "A-ve",
  },
];

let initialState = {
  height: "",
  weight: "",
  bmi: 0,
  bloodGroup: "",
  medical_history: "",
  precaution: [],
};

const EditHealthInfo = (props) => {
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [value, setValue] = useState("");

  const { dependencyOptions } = props;

  const [state, setState] = useState(initialState);

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const richTextComponentProps = {
    multiline: true,
    rowsMax: 4,
    type: "textArea",
    isLimitHelperTextNeeded: true,
  };

  useEffect(() => {
    const crumbs = [...props.breadcrumbs];
    crumbs.push({ label: "Edit Health Info" });
    setBreadcrumbs(crumbs);
  }, [props.breadcrumbs]);

  const updateFieldState = (value, key) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const { height, weight } = state;

  useEffect(() => {
    if (height && weight) {
      updateFieldState(bmiCalculation(height, weight), "bmi");
    }
  }, [height, weight]);

  useEffect(() => {
    console.log(props.data);
    let prefillData = {
      ...initialState,
      height: props?.data?.height,
      weight: props?.data?.weight,
      bmi: props?.data?.bmi,
      bloodGroup: props?.data?.bloodGroup,
      medical_history: props?.data?.medical_history,
      precaution: props?.data?.precaution,
    };

    initialState = {
      ...prefillData,
    };

    setState(prefillData);
  }, [props.data]);

  const handleSubmit = () => {
    // const {error, errorText} = validate();
    // if(Object.keys(error)?.length > 0)
    // setErrorText(errorText);
    props.handleNextStep(state, false);
    // props.handleNextStep(state, false, true)
  };

  const removeItemFromAnArrayCommonFunction = (
    arrOfValues,
    key,
    componentState,
    bool
  ) => {
    const newArr = [...arrOfValues];
    newArr?.splice(key, 1);
    updateFieldState(newArr, componentState);
  };

  const handleCancel = () => {
    if (isEqual(state, initialState)) {
      props.resetScreen();
    } else {
      props.dialog.setDialog({
        open: true,
        renderContent: (handleClose, handleAccept) => (
          <Prompt
            title={"Change Primary Contact"}
            body={
              <>
                Are you sure, do you want to discard <br /> the change made?
              </>
            }
            handleClose={handleClose}
            handleAccept={handleAccept}
          />
        ),
        positiveButtonProps: {
          hide: true,
        },
        negativeButtonProps: {
          hide: true,
        },
        onAccept: () => props.resetScreen(),
        sx: {
          Dialog: {
            "&.MuiDialog-paper": {
              borderRadius: "12px",
            },
          },
          DialogContent: {
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          },
        },
      });
    }
  };

  return (
    <Box sx={{ bgcolor: "white", display: "flex", flexDirection: "column" }}>
      <Stack
        sx={{
          px: 4,
          py: 2,
          bgcolor: "common.white",
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          {breadcrumbs.map((breadcrumb, index) => {
            if (breadcrumbs.length - 1 === index) {
              return (
                <Typography color="text.primary">{breadcrumb.label}</Typography>
              );
            }
            return (
              <Link
                underline="none"
                color="inherit"
                onClick={() => {
                  props.resetScreen();
                }}
                sx={{
                  cursor: "pointer",
                }}
              >
                {breadcrumb.label}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Stack>

      <Divider sx={{ borderColor: "#ECECEC" }} />

      <Grid container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={2}>
        <Grid item container xs={12} md={5} lg={4}>
          <StyledBox>
            <Grid item xs={12} md={10}>
              {/* Field 1 */}
              <CustomTextfield
                // required
                fullWidth
                // size="small"
                type="number"
                id={`heightProfile`}
                sx={{ marginY: 1 }}
                label="Height"
                value={state["height"]}
                onChange={(e) => updateFieldState(e?.target?.value, "height")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={10}>
              {/* Field 2 */}
              <CustomTextfield
                // required
                fullWidth
                // size="small"
                type="number"
                id={`weightProfile`}
                sx={{ marginY: 1 }}
                label="Weight"
                value={state["weight"]}
                onChange={(e) => updateFieldState(e?.target?.value, "weight")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={10}>
              {/* Field 3 */}
              <CustomTextfield
                // required
                fullWidth
                // size="small"
                sx={{ marginY: 1 }}
                label="BMI"
                disabled={true}
                value={bmiCalculation(state["height"], state["weight"])}
                // onChange={(e) => updateFieldState(e?.target?.value, 'height')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg/m2</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={10}>
              {/* Field 4 */}
              <StyledToggleButtonGroup
                id={"bloodGroupProfile"}
                label={"Blood Group"}
                labelSx={{
                  color: "#6F6F6F",
                  fontSize: "14px",
                }}
                // size="small"
                sx={{ marginY: 1 }}
                variant="radio"
                options={
                  Array.isArray(dependencyOptions?.blood_group) &&
                  dependencyOptions?.blood_group?.length > 0
                    ? dependencyOptions?.blood_group?.map((blood) => {
                        let lbl = blood?.label;
                        let shortlbl = blood?.short_label;
                        return {
                          ...blood,
                          label: shortlbl,
                          short_label: lbl,
                        };
                      })
                    : []
                }
                data={{ value: state["bloodGroup"] }}
                onChange={(e, value) => {
                  //   console.log(value)
                  if (value !== null) {
                    updateFieldState(value, "bloodGroup");
                  }
                }}
              />
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item>
          <Divider orientation={"vertical"} />
        </Grid>
        <Grid item container xs={12} md={5} lg={4} rowSpacing={2}>
          <StyledBox>
            <Grid item xs={12} md={12} sx={{ mb: 2 }}>
              <RichTextEditor
                id={"addMedicalHistoryFromStepThree"}
                value={state["medical_history"]}
                label={"Medical History"}
                keys={"medical_history"}
                saveOnBlur={(value) => {
                  updateFieldState(value, "medical_history");
                }}
                {...richTextComponentProps}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <ButtonWithInputField
                label={"Special Precautions"}
                titleInLabel
                arrOfValues={state["precaution"]}
                buttonValue={"+ ADD"}
                componentState={"precaution"}
                removeListFunc={removeItemFromAnArrayCommonFunction}
                // error={this.state.error[component.key] ? true : false}
                // errorMessage={errorMessage.requiredMessage}

                id={{
                  text_id: "addSpecialPrecautionsFromAddEditProfile",
                  button_id: "addSpecialPrecautionsButtonFromAddEditProfile",
                }}
                handleButtonClick={(value) => {
                  if (value) {
                    let data = state["precaution"] ?? [];
                    data = [...data, value];
                    updateFieldState(data, "precaution");
                  }
                }}
              />
            </Grid>
          </StyledBox>
        </Grid>
      </Grid>

      {belowSm ? (
        <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
          <CustomButton
            fullWidth
            sx={{ borderRadius: 0, py: 2 }}
            endIcon={<RightArrowStripesIcon />}
            loading={props.continueLoading}
            onClick={handleSubmit}
            id = {`proceedHealthInfoProfile`}
          >
            Proceed
          </CustomButton>
        </Box>
      ) : (
        <Grid item xs={12}>
          <Box
            sx={{
              pt: 0.5,
              bgcolor: "grey.border",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                px: 3,
                py: 2,
                width: "100%",
                bgcolor: "common.white",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  flexDirection: { sm: "row" },
                  alignItems: { sm: "flex-end" },
                  justifyContent: { sm: "flex-end" },
                  gap: 2,
                }}
              >
                <Box sx={{ minWidth: "140px" }}>
                  <CustomButton
                    sx={{
                      borderRadius: 2,
                      py: 1.5,
                      borderColor: "grey.300",
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    id = {`cancelHealthInfoProfile`}
                    loading={props.continueLoading}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </CustomButton>
                </Box>
                <Box sx={{ minWidth: "140px" }}>
                  <CustomButton
                    sx={{ borderRadius: 2, py: 1.5 }}
                    variant="contained"
                    id = {`updateHealthInfoProfile`}
                    fullWidth
                    loading={props.continueLoading}
                    onClick={() => props.handleUpdate(state)}
                  >
                    Update
                  </CustomButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default withDialog(withDrawer(withSnackBar(EditHealthInfo)));
