import { SvgIcon } from "@mui/material"
import * as React from "react"

function EnquiriesIcon(props) {
    return (
        <SvgIcon width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M17.691 5H6.41a1.408 1.408 0 00-1.4 1.41L5 14.87a1.41 1.41 0 001.41 1.411h11.28a1.414 1.414 0 001.41-1.41V6.41A1.414 1.414 0 0017.691 5zm0 9.871H6.411V6.41h11.28v8.461z"
            />
            <path
                d="M11.346 15.576H7.821v3.525l3.525-3.525zm-1.41-4.23h-1.41V9.935h1.41v1.411zm2.82 0h-1.41V9.935h1.41v1.411zm2.82 0h-1.41V9.935h1.41v1.411z"
            />
        </SvgIcon>
    )
}

export default EnquiriesIcon
