import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CustomAvatar } from "../..";
import CancelIcon from '@mui/icons-material/Cancel';

function BookingSuccessModal({ data, handleClose }) {
  const service = data?.data?.[0]
  return (
    <Box sx={{maxWidth: "400px"}}>
      <Grid container>
        {/* Success Card */}
        <Grid item xs={12} sx={{bgcolor: "success.main", borderRadius: 1.25, position: "relative"}}>
          <IconButton size="small" onClick={handleClose} id={`close${service?.id}`} sx={{position: "absolute", top: "4px", right: "4px"}}>
            <CancelIcon htmlColor="white" fontSize="small" />
          </IconButton>
          <Stack justifyContent="center" alignItems="center" sx={{ p: 2, pb: 3 }}>
            <CheckCircleIcon htmlColor="white" sx={{fontSize: "4rem"}} />
            <Typography align="center" sx={{ color: "white", mt: 0.75 }}>
              Hi {data?.name}
            </Typography>
            <Typography variant="h6" align="center" sx={{ color: "white", my: 1 }}>
              {data?.text}
            </Typography>
            <Typography align="center" variant="body2" sx={{ color: "white", fontWeight: 300 }}>
              {data?.subText}
            </Typography>
          </Stack>
        </Grid>

        {/* Booking Details */}
        <Grid item xs={12}>
          <Stack sx={{mt: 2.5}} >
            <Typography sx={{mb: 1}} >Booking Details:</Typography>
            <Divider flexItem />
            <Stack sx={{p: 2}}>
              <Box>
                <CustomAvatar src={service?.image} name={service?.name} />
              </Box>
              <Typography sx={{mt: 1, fontWeight: 600}}>{service?.name}</Typography>
              <Typography color="textSecondary" variant="body2" >Date of Booking : {data?.bookingData}</Typography>
              {data?.serviceName && <Stack flexDirection="row" sx={{mt: 1.25}} >
                <Typography color="textSecondary">Service : </Typography>
                <Typography>{data?.serviceName}</Typography>
              </Stack>}
              <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                {data?.description}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BookingSuccessModal;
