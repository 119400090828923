import PropTypes from "prop-types";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import useTheme from "@mui/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function PromoSlide({ promo }) {
  const theme = useTheme();
  const below700 = useMediaQuery(theme.breakpoints.down("700"));
  return (
    <Box>
      {/* sx={{ m: { xs: 0, sm: below700 ? 0 : 1 } }} */}
      <img
        style={{
          margin: "auto",
          width: "300px",
          height: "300px"
        }}
        alt="logo"
        src={promo.bg_image.default}
      />
      <Typography
        variant="h6"
        sx={{
          color: "black",
          fontWeight: "bold",
          fontSize: { xs: "14px", sm: "16px", md: "18px" },
          margin: "10px 32px 0px 32px",
        }}
      >
        {promo.title}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          color: "#7e7e7e",
          fontSize: { xs: "12px", sm: "14px", md: "16px" },
          marginTop: "10px",
          margin: "5px 32px 32px 32px",
        }}
      >
        {promo.sub_title}
      </Typography>
    </Box>
  );
}

PromoSlide.propTypes = {
  ad: PropTypes.shape({
    bg_image: PropTypes.shape({
      default: PropTypes.string,
    }),
    promotion_type: PropTypes.string,
    title: PropTypes.string,
  }),
};

const useStyle = makeStyles((theme) => ({
  dots: {
    backgroundColor: "blue !important",
  },
}));

function ResponsiveCarousel({ carouselData = [], onClose, close }) {
  // Component States
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevClick = () => {
    if (currentSlide !== 0) {
      setCurrentSlide((prevSelectedItem) => prevSelectedItem - 1);
    }
  };

  const nextClick = () => {
    if (currentSlide !== carouselData?.length - 1) {
      setCurrentSlide((prevSelectedItem) => prevSelectedItem + 1);
    }
  };

  const settings = {
    showArrows: false,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: false,
    stopOnHover: false,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    autoFocus: true,
    selectedItem: currentSlide,
  };

  return (
    <Carousel {...settings}>
      {carouselData.map((promo, index) => (
        <PromoSlide key={promo?.id ?? index} promo={promo} />
      ))}
    </Carousel>
  );
}

ResponsiveCarousel.propTypes = {
  carouselData: PropTypes.array.isRequired,
};

export default ResponsiveCarousel;
