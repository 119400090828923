import React from "react";
import { Box, Stack, Typography, Divider } from "@mui/material";
import { DrawerSkeleton } from "../../../components";
import {
  DOBIcon,
  EmailIcon,
  IdCardIcon,
  InfoIcon,
  LocationIcon,
  MobileIcon,
} from "../../../assets";
import { FamilyMemberCard } from ".";
import { withDrawer } from "../../../HOC's";
import ViewMore from "./viewMore";
import moment from "moment";
// import { dateTimeFormatter, dateTimeFormatterV2 } from "../../../utils";

const InfoElement = (props) => {
  const { icon, label, value, isDataAbsent, sx } = props;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: `
            "icon label"
            "empty value"
        `,
        gridTemplateColumns: `24px 1fr`,
        gridTemplateRows: `24px 1fr`,
        gap: "2px 12px",
        ...sx,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ gridArea: "icon", minHeight: 24, minWidth: 24 }}
      >
        {React.isValidElement(icon) ? (
          icon
        ) : (
          <InfoIcon fontSize="small" color="primary" />
        )}
      </Stack>
      <Box sx={{ gridArea: "label" }}>
        {React.isValidElement(label) ? (
          label
        ) : (
          <Typography color="textSecondary">
            {label ? label : "Label"}
          </Typography>
        )}
      </Box>
      <Box sx={{ gridArea: "value" }}>
        {React.isValidElement(value) ? (
          value
        ) : (
          <Typography sx={{ fontStyle: isDataAbsent ? "italic" : "unset" }}>
            {value ? value : "Value"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const TwoRowValueElement = (props) => {
  const {
    value = [],
    primaryIdentifier = () => {},
    secondaryIdentifier = () => {},
    onMoreClick = () => {},
  } = props;
  if (value?.length <= 0) {
    return "Not Updated";
  } else {
    return (
      <Stack>
        <Typography>{primaryIdentifier(value?.[0])}</Typography>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Typography>{secondaryIdentifier(value?.[0])}</Typography>
          {value?.length > 1 && (
            <Typography
              color="secondary"
              onClick={onMoreClick}
              sx={{ cursor: "pointer", whiteSpace: "nowrap" }}
            >{`+${value?.length - 1} more`}</Typography>
          )}
        </Stack>
      </Stack>
    );
  }
};

// MOBILE
const mobilePrimaryIdentifier = (val) =>
  val?.mobile_type?.label ? val?.mobile_type?.label : "NA";
const mobileSecondaryIdentifier = (val) =>
  !val?.mobilenumberISDcode && !val?.mobilenumber
    ? "NA"
    : `${val?.mobilenumberISDcode} ${val?.mobilenumber}`;

// ID
const idPrimaryIdentifier = (val) =>
  val?.id_type_name?.label ? val?.id_type_name?.label : "NA";
const idSecondaryIdentifier = (val) => (val?.id_type ? val?.id_type : "NA");

// ADDRESS
const addressPrimaryIdentifier = (val) =>
  val?.address_type?.label ? val?.address_type?.label : "NA";
const addressSecondaryIdentifier = (val) =>
  val?.location?.address ? val?.location?.address : "NA";

const viewMoreProps = {
  mobile: {
    primary: mobilePrimaryIdentifier,
    secondary: mobileSecondaryIdentifier,
  },
  id: {
    primary: idPrimaryIdentifier,
    secondary: idSecondaryIdentifier,
  },
  address: {
    primary: addressPrimaryIdentifier,
    secondary: addressSecondaryIdentifier,
  },
};

function ViewMemberDetailsDrawer(props) {
  const { handleClose, member, onEditClick, onDeleteClick } = props;

  const handleViewMore = (component, value) => {
    const getTitle = () => {
      switch (component) {
        case "mobile":
          return "Contact Numbers";
        case "id":
          return "ID Types";
        case "address":
          return "Address";
        default:
          return "";
      }
    };

    props.drawer.setDrawer({
      open: true,
      component: () => (
        <ViewMore
          parentProps={{ ...props }}
          title={getTitle()}
          value={value}
          component={component}
          {...viewMoreProps}
        />
      ),
    });
  };

  return (
    <DrawerSkeleton
      title="FAMILY MEMBER DETAILS"
      showButton={false}
      handleClose={handleClose}
      gutters={false}
      parentID="FromViewMemberDetails"
    >
      <FamilyMemberCard
        member={member}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        hideViewMore
        sx={{
          border: 0,
        }}
      />
      <Divider light />
      <Stack gap={2} sx={{ px: 2, py: 2 }}>
        <InfoElement
          icon={<EmailIcon />}
          label={"Primary Email Address"}
          value={member?.email ? member?.email : "Not Updated"}
          isDataAbsent={!!!member?.email}
        />
        <InfoElement
          icon={<DOBIcon />}
          label={"DOB"}
          value={member?.dob ? moment(member?.dob)?.format("MM/DD/YYYY") : "Not Updated"}
          isDataAbsent={!!!member?.dob}
        />
        <InfoElement
          icon={<MobileIcon />}
          label={"Contact Number"}
          value={
            <TwoRowValueElement
              value={member?.otherPhoneNumbers}
              primaryIdentifier={(val) => mobilePrimaryIdentifier(val)}
              secondaryIdentifier={(val) => mobileSecondaryIdentifier(val)}
              onMoreClick={() =>
                handleViewMore("mobile", member?.otherPhoneNumbers)
              }
            />
          }
          isDataAbsent={member?.otherPhoneNumbers?.length <= 0}
        />
        <InfoElement
          icon={<IdCardIcon />}
          label={"Id Details"}
          value={
            <TwoRowValueElement
              value={member?.idTypes}
              primaryIdentifier={(val) => idPrimaryIdentifier(val)}
              secondaryIdentifier={(val) => idSecondaryIdentifier(val)}
              onMoreClick={() => handleViewMore("id", member?.idTypes)}
            />
          }
          isDataAbsent={member?.idTypes?.length <= 0}
        />
        <InfoElement
          icon={<LocationIcon />}
          label={"Address Details"}
          value={
            <TwoRowValueElement
              value={member?.otherAddress}
              primaryIdentifier={(val) => addressPrimaryIdentifier(val)}
              secondaryIdentifier={(val) => addressSecondaryIdentifier(val)}
              onMoreClick={() =>
                handleViewMore("address", member?.otherAddress)
              }
            />
          }
          isDataAbsent={member?.otherAddress?.length <= 0}
        />
      </Stack>
    </DrawerSkeleton>
  );
}

export default withDrawer(ViewMemberDetailsDrawer);
