import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FamilyMemberCard } from ".";

function FamilyMemberListCarousel(props) {
  const { currentSlide, members, onEditClick, onDeleteClick } = props;

  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      infiniteLoop={false}
      showThumbs={false}
      useKeyboardArrows
      autoPlay={false}
      stopOnHover={false}
      swipeable
      //   dynamicHeight
      emulateTouch
      autoFocus
      selectedItem={currentSlide}
    >
      {members?.map((member) => (
        <FamilyMemberCard
          member={member}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      ))}
    </Carousel>
  );
}

export default FamilyMemberListCarousel;
