import React from "react";

export const LocationIcon = (props) => {
    const width = props?.width ? props?.width : 18;
    const height = props?.height ? props?.height : 18;
    const color = props?.color ? props?.color : '#6f6f6f';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 18 18"
            {...props}
        >
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_47629" data-name="Rectangle 47629" width="18" height="18" transform="translate(64.5 91.5)" fill="#6f6f6f" />
                </clipPath>
            </defs>
            <g id="Group_93710" data-name="Group 93710" transform="translate(-64.5 -91.5)" clip-path="url(#clip-path)">
                <path id="Path_95370" data-name="Path 95370" d="M72.78,91.5v1.475a7.576,7.576,0,0,0-6.8,6.805H64.5v1.44h1.475a7.577,7.577,0,0,0,6.8,6.806V109.5h1.44v-1.475a7.576,7.576,0,0,0,6.8-6.805H82.5V99.78H81.025a7.576,7.576,0,0,0-6.8-6.8V91.5Zm1.44,2.926a6.1,6.1,0,0,1,5.354,5.354H78.54v1.44h1.034a6.1,6.1,0,0,1-5.354,5.354V105.54H72.78v1.034a6.1,6.1,0,0,1-5.354-5.354H68.46V99.78H67.426a6.1,6.1,0,0,1,5.354-5.354V95.46h1.44Z" fill={color} />
                <circle id="Ellipse_129062" data-name="Ellipse 129062" cx="1.8" cy="1.8" r="1.8" transform="translate(71.7 98.7)" fill={color} />
            </g>
        </svg>
    );
};
