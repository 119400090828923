import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Cart from "../screens/cart";
import Payment from "../screens/payment";
import Registration from "../screens/registration";
import PrivateRoute from "./privateRoute";
import { dontShowInMenuRoutes, routes } from "./routes";
import SignIn from "../screens/signin";
import ForgotUserId from "../screens/signin/forgotUserId";
import ForgotPassword from "../screens/signin/forgotPassword";
import SingUp from "../screens/signin/signUp";
import SuccessPage from "../screens/registration/successPage";
import UserLeaveConfirmation from '../components/customLeaveConfirmationPopup';
import Wishlist from "../screens/wishlist";
import Profile from "../screens/profile";
// import signUp from "../screens/signin/signUp";
const About = lazy(() => import("../screens/about"));
const Chat = lazy(() => import("../screens/chat"));
const Enquiries = lazy(() => import("../screens/enquiries"));
const Home = lazy(() => import("../screens/home"));
const Issues = lazy(() => import("../screens/issues"));
const Login = lazy(() => import("../screens/login"));
const MyOrders = lazy(() => import("../screens/myOrders"));
const Purchase = lazy(() => import("../screens/purchase"));
const PurchaseDetails = lazy(() =>
  import("../screens/purchase/purchaseDetails")
);
const Settings = lazy(() => import("../screens/settings"));
const Subscriptions = lazy(() => import("../screens/subscriptions"));
const Tracker = lazy(() => import("../screens/tracker"));
const RateUs = lazy(() => import("../screens/rateUs"));
const Sandbox = lazy(() => import("../screens/sandbox"));
const Loading = lazy(() => import("../components/loaders/loading"));
const NotFound = lazy(() => import("../screens/notFound"));
const NewUserSetUseridPassword = lazy(() =>
  import("../screens/newUserSetUseridPassword")
);

export default function Routers() {

  // custom prompt state
  const [confirmOpen, setConfirmOpen] = useState(true);

  const toggleConfirmOpen = async (value=false,key) =>{
    setConfirmOpen(value);  
}

  return (
    <Suspense fallback={<Loading />}>
      <Router 
         getUserConfirmation={(message, callback) => {
          return UserLeaveConfirmation(
            message,
            callback,
            confirmOpen,
            toggleConfirmOpen
          );
        }}
      >
        <Switch>
          <Route exact path={"/"}>
            {/* <Login /> */}
            <SignIn />
          </Route>
          {/* <Route exact path={dontShowInMenuRoutes.login.pathName}>
            <Login />
            <SignIn/>
          </Route> */}
          <Route exact path={dontShowInMenuRoutes.signUp.pathName}>
            <SingUp />
          </Route>
          <Route exact path={dontShowInMenuRoutes.forgotUserId.pathName}>
            <ForgotUserId />
          </Route>
          <Route exact path={dontShowInMenuRoutes.forgotPassword.pathName}>
            <ForgotPassword />
          </Route>
          {/* <Route exact path={dontShowInMenuRoutes.signIn.pathName}>
            <SignIn/>
          </Route>
          <Route exact path={dontShowInMenuRoutes.forgotUserId.pathName}>
            <ForgotUserId/>
          </Route>
          <Route exact path={dontShowInMenuRoutes.forgotPassword.pathName}>
            <ForgotPassword/>
          </Route>
          {/* <Route exact path={dontShowInMenuRoutes.register.pathName}>
            <Register />
          </Route> */}
          <PrivateRoute exact path={routes.home.pathName}>
            <Home />
          </PrivateRoute>
          <PrivateRoute path={routes.tracker.pathName}>
            <Tracker />
          </PrivateRoute>
          <PrivateRoute exact path={routes.wishlist.pathName}>
            <Wishlist/>
          </PrivateRoute>
          <PrivateRoute exact path={routes.purchase.pathName}>
            <Purchase />
          </PrivateRoute>
          <PrivateRoute
            exact
            path={routes.purchase.subRoutes.purchaseDetails.pathName}
          >
            <PurchaseDetails />
          </PrivateRoute>
          <PrivateRoute exact path={routes.enquiries.pathName}>
            <Enquiries />
          </PrivateRoute>
          <PrivateRoute exact path={routes.myOrders.pathName}>
            <MyOrders />
          </PrivateRoute>
          <PrivateRoute exact path={routes.subscriptions.pathName}>
            <Subscriptions />
          </PrivateRoute>
          <PrivateRoute
            exact
            path={routes.subscriptions.subRoutes.subscriptionDetail.pathName}
          >
            <Subscriptions />
          </PrivateRoute>
          <PrivateRoute
            exact
            path={routes.subscriptions.subRoutes.subscriptionDetail.pathName}
          >
            <Subscriptions />
          </PrivateRoute>
          <PrivateRoute
            exact
            path={
              routes.subscriptions.subRoutes.subscriptionServiceBooking.pathName
            }
          >
            <Subscriptions />
          </PrivateRoute>
          {/* <PrivateRoute exact path={routes.issues.pathName}>
            <Issues />
          </PrivateRoute> */}
          <PrivateRoute exact path={routes.settings.pathName}>
            <Settings />
          </PrivateRoute>
          {/* <PrivateRoute exact path={routes.about.pathName}>
            <About />
          </PrivateRoute>
          <PrivateRoute exact path={routes.rateUs.pathName}>
            <RateUs />
          </PrivateRoute> */}
          <PrivateRoute exact path={dontShowInMenuRoutes.cart.pathName}>
            <Cart />
          </PrivateRoute>
          <PrivateRoute exact path={dontShowInMenuRoutes.payment.pathName}>
            <Payment />
          </PrivateRoute>
          <PrivateRoute exact path={"/payment/:enquiry_id/:quote_id/:amount"}>
            <Payment />
          </PrivateRoute>
          <PrivateRoute exact path={dontShowInMenuRoutes.profile.pathName}>
            <Profile />
          </PrivateRoute>
          <Route exact path={dontShowInMenuRoutes.selfRegistration.pathName}>
            <Registration />
          </Route>
          <Route
            exact
            path={
              dontShowInMenuRoutes.selfRegistration.subRoutes.success.pathName
            }
          >
            <SuccessPage />
          </Route>
          <Route exact path="/sandbox">
            <Sandbox />
          </Route>

          <Route
            exact
            path={dontShowInMenuRoutes.newUserSetUseridPassword.pathName}
          >
            <NewUserSetUseridPassword />
          </Route>

          {/* <Route path={routes.privacy_policy.pathName} component={() => {
            window.location = routes.privacy_policy.to; return null;} }/>
          <Route path={routes.terms_conditions.pathName} component={() => { window.location = routes.terms_conditions.to; return null;} }/> */}

          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
}
