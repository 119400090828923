import React from "react";
import { CustomSnackBar } from "./components";
import { SnackContext } from "./contexts";
import { snackProps } from "./utils";

const defaultState = {
    open: false,
    message: undefined,
    duration: 4000,
    severity: snackProps.severity.success,
    vertical: snackProps.vertical.top,
    horizontal: snackProps.horizontal.center,
}

class AppSnackbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...defaultState
        };
    }

    // Callback for both Snackbar component and context close
    // reason param will be empty when not called from snackbar
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            ...defaultState
        });
    };


    handleCreate = (contextSnackProps) => {
        this.setState({ ...contextSnackProps });
    };

    render() {
        return (
            <SnackContext.Provider
                value={{
                    ...this.state,
                    onClose: this.handleClose,
                    setSnack: this.handleCreate,
                }}
            >
                {this.state.open && (
                    <CustomSnackBar
                        open={this.state.open}
                        message={this.state.message}
                        duration={this.state.duration}
                        severity={this.state.severity}
                        vertical={this.state.vertical}
                        horizontal={this.state.horizontal}
                        handleClose={this.handleClose}
                    />
                )}
                {this.props.children}
            </SnackContext.Provider>
        );
    }
}

export default AppSnackbar;
