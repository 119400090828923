import { CreateIcon, DeleteIcon } from "../../../../../assets";

import Box from "@mui/material/Box";
import { CustomButton } from "../../../../../components";
import { CustomCard } from "../../../../../components/composedComponents/subscriptions/customCard";
import { IconButton } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { isObjectEmpty } from "../../../../../utils";
import { NoDataHandler } from "../../../../../components";
import { EmptyIcon } from "../../../../../assets";

import { StyledChip } from "./styledChip";
import ViewAllIdType from "../../../../registration/components/viewAllIdType";
import AddIdDrawer from "../../../../../components/cards/addIdentificationCard";
import { withDialog, withDrawer, withSnackBar } from "../../../../../HOC's";
import { Prompt } from "../../../../registration/components";
import { SectionHeader } from "..";

const dynamicPx = { xs: 1.5, md: 1.5 };

function MultipleIdentificationMobile(props) {
  const {
    data: idData = [],
    options = [],
    handleSave,
    handleDelete,
    helperText,
  } = props;

  // Handlers
  const handleCloseDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: <></>,
    });
  };

  const handleSaveIdType = (data) => {
    handleCloseDrawer();
    handleSave(data);
  };

  const handleAddEditClick = (editData = null) => {
    debugger;
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <AddIdDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={options}
          onSave={handleSaveIdType}
          handleClose={handleClose}
        />
      ),
    });
  };

  const handldeDeleteClick = (deletedIdType) => {
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <Prompt
          title={"Delete ID"}
          body={
            <>
              Are you sure, do you want to delete <br /> this ID?
            </>
          }
          handleClose={handleClose}
          handleAccept={handleAccept}
        />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: () => handleDelete(deletedIdType),
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handleViewAllClick = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllIdType
          value={idData}
          handleClose={handleClose}
          handleEditClick={handleAddEditClick}
          handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  return (
    <>
      <Stack>
        <SectionHeader
          title="ID Details"
          buttonLabel={idData?.length > 0 ? "View All" : "+Add"}
          buttonHandler={() =>
            idData?.length > 0 ? handleViewAllClick() : handleAddEditClick()
          }
        />
        {idData?.length > 0 ? (
          <Stack
            sx={{ bgcolor: "common.white", p: 2.5 }}
            gap={2.5}
            style={{
              boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
            }}
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack>
              <Typography
                variant="body2"
                color="textSecondary"
                fontWeight="400"
              >
                {idData?.[0]?.label}
              </Typography>
              <Typography fontWeight="400">{idData?.[0]?.id_type}</Typography>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <IconButton
                size="small"
                onClick={() => {
                  handleAddEditClick(idData?.[0]);
                }}
              >
                <CreateIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  handldeDeleteClick(idData?.[0]);
                }}
              >
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Stack>
          </Stack>
        ) : (
          <Box sx={{ pb: 1, bgcolor: "common.white", boxShadow: "rgb(233 235 239) 0px 7px 7px 0px" }}>
            <NoDataHandler
              message={"You don't have any ID details"}
              empty
              emptyIcon={<EmptyIcon />}
              fontSize={"4.5rem"}
              fullheight={"100px"}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default withDrawer(withDialog(MultipleIdentificationMobile));
