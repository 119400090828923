import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.fill,
  },
}));

function NameIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.175 3a4.484 4.484 0 104.484 4.484A4.494 4.494 0 0012.175 3zm0 1.345a3.139 3.139 0 11-3.139 3.139 3.129 3.129 0 013.139-3.139zm-5.391 9.417A1.794 1.794 0 005 15.546v.682a4.179 4.179 0 002.38 3.615 10.094 10.094 0 004.8 1.093 10.094 10.094 0 004.8-1.093 4.333 4.333 0 002.262-2.943h.108v-1.354a1.8 1.8 0 00-1.785-1.784H6.784zm0 1.345h10.781a.43.43 0 01.44.439v.683a2.732 2.732 0 01-1.684 2.438 8.94 8.94 0 01-4.145.925 8.94 8.94 0 01-4.145-.925 2.731 2.731 0 01-1.684-2.438v-.682a.429.429 0 01.437-.44z"
        className={classes.cls1}
      />
    </SvgIcon>
  );
}

NameIcon.defaultProps = {
  fill: "#0063E7",
};

export default NameIcon;
