import PropTypes from "prop-types";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { CustomButton } from "../../..";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { currencyPriceFormatter, ORDER_STATUS } from "../../../../utils";
import Skeleton from "@mui/material/Skeleton";

const paidStamp2X = require("../../../../assets/images/paid-stamp-4@2x.png");

function ParticularRow({
  loading,
  particularName,
  particularAmount,
  makeNameBold,
  isPaid,
  isOffer,
}) {
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ py: 0.5 }}
    >
      <Typography {...(makeNameBold && { fontWeight: "500" })}>
        {particularName}
      </Typography>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {isPaid && (
          <img
            src={paidStamp2X.default}
            alt="paid"
            loading="lazy"
            style={{ width: 38, height: 30 }}
          />
        )}
        {loading ? (
          <Skeleton width="60px" />
        ) : (
          <Typography
            sx={{ ...(isOffer && { color: "success.main" }) }}
            fontWeight="500"
          >
            {particularAmount}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

ParticularRow.propTypes = {
  isOffer: PropTypes.bool,
  isPaid: PropTypes.bool,
  makeNameBold: PropTypes.bool,
  particularAmount: PropTypes.string,
  particularName: PropTypes.string,
};

const pxDynamic = { xs: 1.5, md: 2.5 };

function ItemParticulars({ loading, ...props }) {
  const handleDownloadInvoice = () => {
    if (props?.data?.invoiceUrl) {
      window.location.href = props?.data?.invoiceUrl;
    }
  };
  return (
    <Box>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: pxDynamic,
          py: 1,
          borderTop: 1,
          borderBottom: 1,
          borderColor: "grey.border",
        }}
      >
        <Typography sx={{ fontWeight: "500" }}>PRICE DETAILS</Typography>
        {loading ? (
          <Skeleton width="100px" />
        ) : (
          props?.data?.invoiceUrl && (
            <CustomButton
              variant="text"
              size="small"
              onClick={handleDownloadInvoice}
              startIcon={<DownloadOutlinedIcon fontSize="small" />}
              id={`downloadInvoice${props?.data?.id}`}
            >
              Download Invoice
            </CustomButton>
          )
        )}
      </Stack>
      <Stack sx={{ px: pxDynamic, py: 0.5 }}>
        <ParticularRow
          loading={loading}
          particularName={"Selling Price"}
          particularAmount={currencyPriceFormatter(
            props?.data?.currency,
            props?.data?.sellingPrice
          )}
        />
        <ParticularRow
          loading={loading}
          particularName={`Discount${
            Number(props?.data?.discountPercentage)
              ? "(" + props?.data?.discountPercentage + "% OFF)"
              : ""
          }`}
          particularAmount={`${currencyPriceFormatter(
            props?.data?.currency,
            props?.data?.sellingPrice * (props?.data?.discountPercentage / 100)
          )}`}
        />

        <ParticularRow
          loading={loading}
          particularName={"Tax Amount"}
          particularAmount={currencyPriceFormatter(
            props?.data?.currency,
            props?.data?.taxAmount
          )}
        />
        <ParticularRow
          loading={loading}
          particularName={"Total Amount Paid"}
          particularAmount={currencyPriceFormatter(
            props?.data?.currency,
            props?.data?.totalAmount
          )}
          makeNameBold
          isPaid={ORDER_STATUS.success === props?.data?.orderStatus?.value}
        />
      </Stack>
    </Box>
  );
}

export default ItemParticulars;
