import React from "react";
import { Stack } from "@mui/material";
import { FamilyMemberCard } from ".";

function FamilyMemberList(props) {
  // Component States
  const { members, onEditClick, onDeleteClick } = props;

  return (
    <Stack sx={{ gap: 2, pr: 1, maxHeight: "500px", overflow: "scroll" }}>
      {members?.map((member) => (
        <FamilyMemberCard
          member={member}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      ))}
    </Stack>
  );
}

export default FamilyMemberList;
