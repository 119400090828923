import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorPrimary,
  },
  cls2: {
    fill: (props) => props.htmlColorSecondary,
  },
}));

function OccupationDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="occupation icon">
        <path
          data-name="icons8-permanent-job (1)"
          d="M2.506 0a.878.878 0 0 0 0 1.756h.287V12.9a2.037 2.037 0 0 0-1.72 2.027v6.439a.868.868 0 0 0 .86.878h7.452a.868.868 0 0 0 .86-.878v-3.22h2.293v3.219a.868.868 0 0 0 .86.878h1.075a3.338 3.338 0 0 1-.218-1.171v-3.8a.868.868 0 0 0-.86-.878H9.384a.868.868 0 0 0-.86.878v3.22H2.793v-5.565a.287.287 0 0 1 .287-.293h.573a.868.868 0 0 0 .86-.878v-12h13.76v9.073a3.671 3.671 0 0 1 1.433-.293c.1 0 .195.006.287.011V1.756h.28a.878.878 0 0 0 0-1.756Zm4.3 4.1a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.146a.58.58 0 0 0 .573-.585V4.683a.58.58 0 0 0-.573-.585Zm4.012 0a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.146a.58.58 0 0 0 .573-.585V4.683a.58.58 0 0 0-.573-.585Zm4.012 0a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.143a.58.58 0 0 0 .573-.585V4.683a.58.58 0 0 0-.573-.583ZM6.805 8.2a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.146a.58.58 0 0 0 .573-.585V8.78a.58.58 0 0 0-.573-.585Zm4.012 0a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.146a.58.58 0 0 0 .573-.585V8.78a.58.58 0 0 0-.573-.585Zm4.012 0a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.144a.58.58 0 0 0 .573-.585V8.78a.58.58 0 0 0-.573-.58Zm4.872 8.78Zm-12.9-4.683a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.146a.58.58 0 0 0 .573-.585v-1.175a.58.58 0 0 0-.573-.585Zm4.012 0a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.146a.58.58 0 0 0 .573-.585v-1.175a.58.58 0 0 0-.573-.585Zm4.012 0a.581.581 0 0 0-.573.585v1.171a.581.581 0 0 0 .573.585h1.158a4.607 4.607 0 0 1-.011-.293 3.86 3.86 0 0 1 .452-1.814v-.006a.56.56 0 0 0-.452-.229Zm-9.744 4.1a.58.58 0 0 0-.573.585v1.171a.58.58 0 0 0 .573.585h1.146a.58.58 0 0 0 .573-.585v-1.178a.58.58 0 0 0-.573-.585Zm18.915 4.683Z"
          className={classes.cls1}
        />
        <path
          data-name="icons8-permanent-job (1)"
          d="M20.273 0ZM19.7 11.707a2.635 2.635 0 1 0 2.579 2.634 2.607 2.607 0 0 0-2.579-2.634Zm-3.273.82v-.006c-.102-.14-.285.544.001.006Zm.694 5.619a1.738 1.738 0 0 0-1.718 1.754v1.171c0 1.616 1.925 2.927 4.3 2.927s4.3-1.311 4.3-2.927V19.9a1.738 1.738 0 0 0-1.72-1.756Z"
          className={classes.cls2}
        />
      </g>
    </SvgIcon>
  );
}

OccupationDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default OccupationDuoToneIcon;
