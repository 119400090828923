import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { SectionHeader } from ".";
import { CreateIcon, DeleteIcon, EmptyIcon } from "../../../../assets";
import { styled, Chip, alpha } from "@mui/material";
import { NoDataHandler } from "../../../../components";

export const PrimaryChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  height: 24,
  borderRadius: 4,
  ...(color === "success" && {
    color: theme.palette.success.custom,
    backgroundColor: alpha(theme.palette.success.custom, 0.08),
  }),
  ...(color === "error" && {
    color: theme.palette.error.custom,
    backgroundColor: alpha(theme.palette.error.custom, 0.08),
  }),
  //   border: "1px solid #E0E0E0",
  //   borderRadius: 8,
  //   padding: 12,
}));

function ContactDetails({
  data,
  isView = false,
  onEdit,
  onDelete,
  onViewAll,
  ...props
}) {
  return (
    <Stack>
      <SectionHeader
        id={isView ? `viewAllContactDetailsMobile` : `addNewContactButtonsMobile`}
        title="Contact Details"
        buttonLabel={isView ? "View All" : "+Add"}
        buttonHandler={() => (isView ? onViewAll() : onEdit(data?.[0]))}
      />
      {data?.length > 0 ? (
        <Stack
          sx={{ bgcolor: "common.white", p: 2.5 }}
          gap={2.5}
          style={{
            boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
          }}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Stack>
            <Stack
              flexDirection="row"
              gap={1}
              alignItems="center"
              sx={{ mb: 0.25 }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                fontWeight="400"
              >
                {data?.[0]?.label}
              </Typography>
              {/* {data?.[0]?.isPrimary && (
                <PrimaryChip label="Primary" color="error" />
              )} */}
            </Stack>
            <Typography fontWeight="400">{`${data?.[0]?.mobilenumberISDcode} ${data?.[0]?.mobilenumber}`}</Typography>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <IconButton
              size="small"
              onClick={() => {
                onEdit(data?.[0]);
              }}
            >
              <CreateIcon sx={{fontSize: "16px"}} />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                onDelete(data?.[0]);
              }}
            >
              <DeleteIcon sx={{fontSize: "16px"}} />
            </IconButton>
          </Stack>
        </Stack>
      ) : (
        <Box sx={{pb: 1, bgcolor: "common.white", boxShadow: "rgb(233 235 239) 0px 7px 7px 0px"}}>
          <NoDataHandler
          message={"You don't have any contact details"}
          empty
          emptyIcon={<EmptyIcon />}
          fontSize={"4.5rem"}
          fullheight={"100px"}
        />
        </Box>
      )}
    </Stack>
  );
}

export default ContactDetails;
