import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorPrimary,
    stroke: (props) => props.htmlColorPrimary,
  },
  cls2: {
    fill: (props) => props.htmlColorSecondary,
    stroke: (props) => props.htmlColorSecondary,
  },
}));

function LanguageDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Group 95109" strokeWidth={0.5}>
        <path
          data-name="icons8-language (2)"
          d="M12.229.591C5.758.591.458 5.263.458 11.074a9.747 9.747 0 0 0 2.575 6.517h-.016a1.537 1.537 0 0 1 .112 1.2 5.656 5.656 0 0 1-.671 1.534 14.654 14.654 0 0 1-.912 1.31c-.136.177-.26.328-.352.452a2.093 2.093 0 0 0-.112.187c-.036.06-.112.057-.112.328a.923.923 0 0 0 .288.655 1.183 1.183 0 0 0 .832.3c2.359 0 4.506-1.71 6.142-2.652a12.948 12.948 0 0 0 4 .655c6.469 0 11.771-4.672 11.771-10.483S18.699.591 12.229.591Zm0 1c5.968 0 10.748 4.267 10.748 9.485s-4.78 9.485-10.748 9.485a12 12 0 0 1-3.918-.655l-.224-.078-.192.125c-1.775.991-3.912 2.588-5.774 2.605.07-.094.138-.179.24-.312a16.124 16.124 0 0 0 .976-1.42 6.444 6.444 0 0 0 .8-1.81 2.523 2.523 0 0 0-.24-1.934l-.032-.047-.016-.031a8.783 8.783 0 0 1-2.367-5.928c0-5.22 4.776-9.485 10.747-9.485Z"
          className={classes.cls1}
        />
        <path
          data-name="icons8-language (2)"
          d="M7.799 5.583a.51.51 0 0 0-.4.343l-1.551 4.365a.491.491 0 0 0-.1.281l-.656 1.841a.49.49 0 0 0 .056.54.519.519 0 0 0 .528.172.5.5 0 0 0 .376-.4l.592-1.654h2.463l.592 1.654a.5.5 0 0 0 .376.4.519.519 0 0 0 .528-.172.49.49 0 0 0 .056-.54L8.358 5.926a.512.512 0 0 0-.559-.343Zm.08 2.008.88 2.48h-1.76Zm8.4 1.466a.609.609 0 0 0-.064.016.5.5 0 0 0-.4.5v1h-2.657a.506.506 0 0 0-.464.546.512.512 0 0 0 .56.452h4.462a6.7 6.7 0 0 1-1.7 2.777 7.394 7.394 0 0 1-1.258-1.542.518.518 0 0 0-.912.008.486.486 0 0 0 .016.476 8.789 8.789 0 0 0 1.375 1.7 7.76 7.76 0 0 1-2.143 1.108.507.507 0 0 0-.41.367.492.492 0 0 0 .176.515.523.523 0 0 0 .554.055 8.592 8.592 0 0 0 2.575-1.373 10.271 10.271 0 0 0 2.159 1.357.521.521 0 0 0 .688-.234.5.5 0 0 0-.24-.671 9.507 9.507 0 0 1-1.838-1.123 7.945 7.945 0 0 0 2.015-3.416h.624a.518.518 0 0 0 .45-.248.493.493 0 0 0 0-.5.518.518 0 0 0-.45-.248h-2.562v-1a.49.49 0 0 0-.16-.38.52.52 0 0 0-.399-.137Z"
          className={classes.cls2}
        />
      </g>
    </SvgIcon>
  );
}

LanguageDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default LanguageDuoToneIcon;
