import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTheme, useMediaQuery } from "@mui/material";
import { UploadProfileImage } from "../../components/profileImageUpload";
import { styled } from "@mui/system";
import { v4 as uuidV4 } from "uuid";
import moment from "moment";
import { genderOptions } from "../../utils";
import {
  CustomButton,
  CustomDatePicker,
  CustomSelect,
  CustomTextfield,
  CustomTextfieldWithSelect,
  StyledToggleButtonGroup,
} from "../../components";
import {
  MultipleAddress,
  MultipleContact,
  MultipleEmailAddress,
  MultipleIdentification,
} from "../registration/components";
import { withDialog, withDrawer } from "../../HOC's";
import { RightArrowStripesIcon } from "../../assets";
import isEqual from "react-fast-compare";

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    margin: "0.5rem",
  },
  [theme.breakpoints.up("md")]: {
    margin: "1rem 2rem",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "1rem 3rem",
  },
}));

const initialState = {
  profileImage: "",
  salutation: "",
  name: "",
  primaryEmail: "",
  dob: null,
  gender: "",
  occupation: "",
  languages: [],
  // address: [],
  otherAddress: [],
  otherEmailIds: [],
  idTypes: [],
  otherPhoneNumbers: [],
};

function EditProfile(props) {
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { editData, dependencyOptions } = props;

  const [state, setState] = useState(initialState);
  const [checkData, setCheckData] = useState();
  const [errorText, setErrorText] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    // setState(editData);
    let temp_state = {};
    temp_state["name"] = editData?.clientName;
    temp_state["profileImage"] = editData?.profilePic;
    temp_state["salutation"] = editData?.salutation;
    temp_state["primaryEmail"] = editData?.emailid;
    temp_state["dob"] = editData?.dob;
    temp_state["gender"] = editData?.gender?.toLowerCase();
    temp_state["occupation"] = editData?.occupation;
    temp_state["languages"] = editData?.languages
      ? JSON.parse(editData?.languages)
      : [];
    temp_state["otherAddress"] = editData?.otherAddress ?? [];
    temp_state["otherEmailIds"] = editData?.otherEmailIds ?? [];
    temp_state["idTypes"] = editData?.idTypes ?? [];
    temp_state["otherPhoneNumbers"] = editData?.otherPhoneNumbers ?? [];
    console.log(editData);
    setCheckData(temp_state);
    setState(temp_state);
  }, [editData]);

  useEffect(() => {
    const crumbs = [...props.breadcrumbs];
    crumbs.push({ label: "Edit Personal Details" });
    setBreadcrumbs(crumbs);
  }, [props.breadcrumbs]);


  const updateFieldState = (value, key) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  // New ID, Contact & Address Card
  const closeDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: "",
    });
  };

  // Identification Details
  const handleIdentificationSave = (idData) => {
    debugger;
    const idTypes = [...state["idTypes"]];
    if (!!idData?.id) {
      // Edit
      let updatedData = idTypes.map((idItem) => {
        if (idItem?.id === idData?.id) {
          return {
            ...idItem,
            ...idData,
          };
        }
        return idItem;
      });

      updateFieldState(updatedData, "idTypes");
    } else {
      idTypes.push({ ...idData, id: uuidV4() });
      updateFieldState(idTypes, "idTypes");
    }
  };

  const handleIdentificationDelete = (idData) => {
    closeDrawer();
    debugger;
    let idTypes = [...state["idTypes"]];
    idTypes = idTypes.filter((phoneNumber) => phoneNumber?.id !== idData?.id);
    updateFieldState(idTypes, "idTypes");
  };

  const getIDOptions = () => {
    if (Array.isArray(dependencyOptions?.client_id_type)) {
      const allOptions = [...dependencyOptions?.client_id_type];
      const usedOptions = [...state?.idTypes];
      const remainingOptions = allOptions.filter(
        (originalOption) =>
          !usedOptions.some(
            (usedOption) =>
              usedOption?.id_type_name?.value === originalOption?.value
          )
      );
      return remainingOptions;
    }
  };

   // Email Address
   const handleEmailSave = (emailData) => {
    debugger;
    const emailIds = [...state["otherEmailIds"]];
    if (!!emailData?.id) {
      // Edit
      let updatedData = emailIds.map((emailItem) => {
        if (emailItem?.id === emailData?.id) {
          return {
            ...emailItem,
            ...emailData,
          };
        }
        return emailItem;
      });

      updateFieldState(updatedData, "otherEmailIds");
    } else {
      emailIds.push({ ...emailData, id: uuidV4() });
      updateFieldState(emailIds, "otherEmailIds");
    }
  };

  const handleEmailDelete = (emailData) => {
    closeDrawer();
    debugger;
    let emailIds = [...state["otherEmailIds"]];
    emailIds = emailIds.filter((email) => email?.id !== emailData?.id);
    updateFieldState(emailIds, "otherEmailIds");
  };

  const getEmailOptions = () => {
    if (Array.isArray(dependencyOptions?.email_type)) {
    const allOptions = [...dependencyOptions?.email_type];
    const usedOptions = [...state?.otherEmailIds];
    const remainingOptions = allOptions.filter(
      (originalOption) =>
        !usedOptions.some(
          (usedOption) =>
            usedOption?.email_type?.value === originalOption?.value
        )
    );
    return remainingOptions;
        }
  };

  // Contact Details
  const handleContactSave = (contact) => {
    debugger;
    const otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    if (!!contact?.id) {
      // Edit
      let updatedData = otherPhoneNumbers.map((contactData) => {
        if (contactData?.id === contact?.id) {
          return {
            ...contactData,
            ...contact,
          };
        }
        return contactData;
      });

      updateFieldState(updatedData, "otherPhoneNumbers");
    } else {
      otherPhoneNumbers.push({ ...contact, id: uuidV4() });
      updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
    }
  };

  const handleContactDelete = (contact) => {
    closeDrawer();
    debugger;
    let otherPhoneNumbers = [...state["otherPhoneNumbers"]];
    otherPhoneNumbers = otherPhoneNumbers.filter(
      (phoneNumber) => phoneNumber?.id !== contact?.id
    );
    updateFieldState(otherPhoneNumbers, "otherPhoneNumbers");
  };

  const getContactOptions = () => {
    if (Array.isArray(dependencyOptions?.phone_type)) {
      const allOptions = [...dependencyOptions?.phone_type];
      const usedOptions = [...state?.otherPhoneNumbers];
      const remainingOptions = allOptions.filter(
        (originalOption) =>
          !usedOptions.some(
            (usedOption) =>
              usedOption?.mobile_type?.value === originalOption?.value
          )
      );
      return remainingOptions;
    }
  };

  // Address Details
  const handleAddressSave = (address) => {
    debugger;
    const otherAddress = [...state["otherAddress"]];
    if (!!address?.id) {
      // Edit
      let updatedData = otherAddress.map((addressData) => {
        if (addressData?.id === address?.id) {
          return {
            ...addressData,
            ...address,
          };
        }
        return addressData;
      });

      updateFieldState(updatedData, "otherAddress");
    } else {
      otherAddress.push({ ...address, id: uuidV4() });
      updateFieldState(otherAddress, "otherAddress");
    }
  };

  const handleAddressDelete = (address) => {
    closeDrawer();
    debugger;
    let otherAddress = [...state["otherAddress"]];
    otherAddress = otherAddress.filter(
      (addressData) => addressData?.id !== address?.id
    );
    updateFieldState(otherAddress, "otherAddress");
  };

  const getAddressOptions = () => {
    if (Array.isArray(dependencyOptions?.address_type)) {
      const allOptions = [...dependencyOptions?.address_type];
      const usedOptions = [...state?.otherAddress];
      const remainingOptions = allOptions.filter(
        (originalOption) =>
          !usedOptions.some(
            (usedOption) =>
              usedOption?.address_type?.value === originalOption?.value
          )
      );
      return remainingOptions;
    }
  };

  const handleSubmit = () => {
    props.handleUpdate(state);
    // props.resetScreen()
  };

  const handleCancel = () => {
    if (isEqual(state, checkData)) {
      props.resetScreen();
    } else {
      props.dialog.setDialog({
        open: true,
        title: "Confirmation",
        content: "Are you sure, do you want to discard the changes made ?",
        positiveButtonProps: {
          hide: false,
        },
        negativeButtonProps: {
          hide: false,
        },
        onAccept: () => props.resetScreen(),
      });
    }
  };

  return (
    <Stack>
      <Stack sx={{ px: 4, py: 2, bgcolor: "common.white" }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          {breadcrumbs.map((breadcrumb, index) => {
            if (breadcrumbs.length - 1 === index) {
              return (
                <Typography color="text.primary">{breadcrumb.label}</Typography>
              );
            }
            return (
              <Link
                underline="none"
                color="inherit"
                onClick={() => {
                  props.resetScreen();
                }}
                sx={{
                  cursor: "pointer",
                }}
              >
                {breadcrumb.label}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Stack>
      <Box sx={{ bgcolor: "common.white" }}>
        <Grid container sx={{ border: "1px solid #ECECEC" }}>
          <Grid item xs={12} md lg={5}>
            <StyledBox>
              <Typography
                sx={{
                  mt: { xs: 1, md: 0 },
                  mb: 3,
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Personal Details
              </Typography>
              <Box sx={{ mb: 5 }}>
                <UploadProfileImage
                  value={state["profileImage"]}
                  label={"profile_img"}
                  profile_pic={state["profileImage"]}
                  handleFileChange={(e) => {
                    updateFieldState(e?.target?.files?.[0], "profileImage");
                  }}
                  error={false}
                  errorText={""}
                  id={"profileImgProfile"}
                />
              </Box>
              <CustomTextfieldWithSelect
                error={false}
                // size="small"
                sx={{ mt: 1, mb: 2, gap: 1 }}
                label={"Name"}

                selectLabel={"Salutation"}
                textFieldProps={{
                  id: "clientNameProfile",
                  name: "Name",
                  required: true,
                }}
                selectProps={{
                  id: "clientSalutationProfile",
                  name: "Salutation",
                  required: true,
                  // placeholder: "Mr.",
                }}
                selectOptions={dependencyOptions?.salutation}
                fullWidth
                selectValue={state["salutation"]}
                value={state["name"]}
                // onSelectChange={(e, value) => console.log(value?)}
                onSelectChange={(e, value) => {
                  let data = dependencyOptions?.salutation?.filter(
                    (x) => x?.value === value?.value
                  )?.[0];
                  updateFieldState(data, "salutation");
                }}
                onChange={(e) => updateFieldState(e.target.value, "name")}
                error={errorText?.name ? true : false}
                helperText={errorText?.name}
                selectError={errorText?.salutation ? true : false}
                selectHelperText={errorText?.salutation}

                // isSalutationError={errorText?.salutation ? true : false}
                // inputError={errorText?.name ? true : false}
                // helperInputText={errorText?.name}
              />
              <CustomTextfield
                fullWidth
                // size="small"
                type={"email"}
                id={`clientEmailProfile`}
                sx={{ mt: 1, mb: 2 }}
                onChange={(e) =>
                  updateFieldState(e.target.value, "primaryEmail")
                }
                value={state["primaryEmail"]}
                label="Primary Email Address"
                error={errorText?.primaryEmail ? true : false}
                helperText={errorText?.primaryEmail}
                required
              />
              <CustomDatePicker
                fullWidth
                label={"DOB"}
                value={state["dob"]}
                disableFuture
                // size="small"
                // sx={{ marginY: 1, color: "primary.main" }}
                InputProps={{
                  marginY: 2,
                  fullWidth: true,
                  required: true,
                  helperText: errorText?.dob,
                  error: errorText?.dob ? true : false,
                  id:'clientDOBProfile'
                }}
                onChange={(newDate) =>
                  updateFieldState(moment(newDate).toISOString(), "dob")
                }
                helperText={errorText?.dob}
                error={errorText?.dob ? true : false}
              />

              <StyledToggleButtonGroup
                id={"genderProfile"}
                label={"Gender"}
                // size="small"
                data={{ value: state["gender"] }}
                sx={{ marginY: 2 }}
                options={genderOptions}
                onChange={(e, value) => {
                  //   console.log(value)
                  updateFieldState(value, "gender");
                }}
                error={errorText?.gender ? true : false}
                helperText={errorText?.gender}
              />
              <CustomTextfield
                fullWidth
                id={`occupationProfile`}
                value={state["occupation"]}
                onChange={(e) => updateFieldState(e.target.value, "occupation")}
                sx={{ marginY: 2 }}
                label="Occupation"
                error={errorText?.occupation ? true : false}
                helperText={errorText?.occupation}
                // required
              />
            </StyledBox>
          </Grid>
          <Divider orientation={"vertical"} flexItem />
          <Grid item xs={12} md lg={5}>
            <StyledBox>
              <Typography
                sx={{
                  mt: { xs: 1, md: 0 },
                  mb: 3,
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Additional Details
              </Typography>
              <CustomSelect
                // id={"my order status filter"}
                sx={{ marginY: 2 }}
                options={dependencyOptions?.language}
                filterSelectedOptions
                loading={false}
                id={`languagesProfile`}
                value={state["languages"]}
                label={"Preferred Languages"}
                onChange={(e, value) => updateFieldState(value, "languages")}
                // onChange={(e, value) => console.log(value)}
                multiple={true}
                // size="small"
                error={errorText?.languages ? true : false}
                helperText={errorText?.languages}
              />

              <MultipleIdentification
                data={state?.idTypes}
                options={getIDOptions()}
                required={true}
                handleSave={handleIdentificationSave}
                handleDelete={handleIdentificationDelete}
                helperText={errorText?.idTypes}
                sx={{ mb: 2 }}
              />

              <MultipleEmailAddress
                data={state?.otherEmailIds}
                options={getEmailOptions()}
                required={false}
                handleSave={handleEmailSave}
                handleDelete={handleEmailDelete}
                helperText={errorText?.otherEmailIds}
                sx={{ mb: 2 }}
              />

              <MultipleContact
                contacts={state?.otherPhoneNumbers}
                options={getContactOptions()}
                isPrimaryLogicNeeded={false}
                // primaryContactId={primaryContactId}
                required={false}
                handleSave={handleContactSave}
                handleDelete={handleContactDelete}
                helperText={errorText?.otherPhoneNumbers}
                sx={{ mb: 2 }}
              />

              <MultipleAddress
                data={state?.otherAddress}
                options={getAddressOptions()}
                required={true}
                handleSave={handleAddressSave}
                handleDelete={handleAddressDelete}
                helperText={errorText?.otherAddress}
                sx={{ mb: 2 }}
              />
            </StyledBox>
          </Grid>
        </Grid>
        {belowSm ? (
          <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
            <CustomButton
              fullWidth
              sx={{ borderRadius: 0, py: 2 }}
              endIcon={<RightArrowStripesIcon />}
              loading={props.continueLoading}
              onClick={handleSubmit}
              id={`proceedButtonProfile`}
            >
              Proceed
            </CustomButton>
          </Box>
        ) : (
          <Stack
            sx={{
              px: { md: "2rem", lg: "3rem" },
              py: { sm: "1rem" },
              flexDirection: { sm: "row" },
              alignItems: { sm: "flex-end" },
              justifyContent: { sm: "flex-end" },
              gap: 2,
            }}
          >
            <Box sx={{ minWidth: "140px" }}>
              <CustomButton
                sx={{
                  borderRadius: 2,
                  py: 1.5,
                  borderColor: "grey.300",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                color="secondary"
                fullWidth
                // onClick={() => props.resetScreen()}
                onClick={handleCancel}
                id={`cancelButtonProfile`}
              >
                Cancel
              </CustomButton>
            </Box>

            <Box sx={{ minWidth: "140px" }}>
              <CustomButton
                sx={{ borderRadius: 2, py: 1.5 }}
                variant="contained"
                fullWidth
                loading={props.continueLoading}
                onClick={handleSubmit}
                id={`updateButtonProfile`}
              >
                Update
              </CustomButton>
            </Box>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}

EditProfile.defaultProps = {
  breadcrumbs: [],
};

export default withDrawer(withDialog(EditProfile));
