import React from "react";
import { IconButton, Stack, Typography, Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CustomButton } from "../../components";

function RegistrationTopbar({
  title,
  hidePrevious,
  handlePreviousStep,
  hideSkip,
  handleSkip,
  continueLoading,
  parentID=""
}) {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{ pb: 1.5, pt: 0.25, borderBottom: 1, borderColor: "grey.border" }}
    >
      {!hidePrevious && (
        <IconButton sx={{ zIndex: 1 }} onClick={handlePreviousStep} id={`backBtn${parentID}`}>
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Box sx={{ flex: 1 }}>
        <Typography
          variant="h6"
          align="center"
          sx={{
            transform:
              !hidePrevious && !hideSkip
                ? ""
                : !hidePrevious
                ? "translateX(-20px)"
                : "",
          }}
        >
          {title}
        </Typography>
      </Box>
      {!hideSkip && (
        <CustomButton
          variant="text"
          color="secondary"
          sx={{ zIndex: 1 }}
          loading={continueLoading}
          onClick={handleSkip}
          id={`skip-btn-${parentID}`}
        >
          Skip
        </CustomButton>
      )}
    </Stack>
  );
}

export default RegistrationTopbar;
