import {
  ORDER_STATUS,
  dateTimeFormatterV2,
  loadingArray,
} from "../../../../utils";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CustomAvatar } from "../../..";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/system";

/**----------------------------Customized Components------------------------ */
const CustomListItem = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "status",
})(({ theme, status }) => ({
  "& .MuiListItemText-primary": {
    fontWeight: "500",
  },
  "& .MuiListItemText-secondary": {
    ...(status === ORDER_STATUS.success
      ? {
          color: theme.palette.success.main,
        }
      : status === ORDER_STATUS.failed
      ? {
          color: theme.palette.error.main,
        }
      : {
          color: theme.palette.text.secondary,
        }),
  },
}));

const CustomListItemButton = styled(ListItemButton)(
  ({ theme, isSelected }) => ({
    borderBottomColor: theme.palette.grey.border,
    ...(isSelected &&
      {
        // backgroundColor: "green"
      }),
  })
);

/**----------------------------Root Component----------------------------------- */
function OrderListing(props, ref) {
  const getStatusText = (item) => {
    const date = dateTimeFormatterV2(item?.orderedOn);
    switch (item?.orderStatus?.value) {
      case ORDER_STATUS.initiated:
        return `Payment Initiated on ${date}`;
      case ORDER_STATUS.success:
        return `Purchased on ${date}`;
      case ORDER_STATUS.failed:
        return `Payment Failed on ${date}`;
      default:
        return "";
    }
  };

  return (
    <List sx={{ width: "100%", overflow: "scroll" }}>
      {props.loading &&
        loadingArray(6).map((item, index) => (
          <OrderListingLoader
            key={`Order-list-item-${index + 1}`}
            loading={props.loading}
            isLast={item?.length - 1 !== index}
          />
        ))}
      {!props.loading &&
        props.data?.map((item, index) => (
          <CustomListItemButton
            ref={props.data?.length === index + 1 ? ref : null}
            isSelected={props?.orderSelected === item?.orderItemId}
            key={`Order-item-${index + 1}-${item?.service?.id}`}
            id={`Order${item?.service?.id}`}
            sx={{ borderColor: "grey.border" }}
            divider={item?.length - 1 !== index}
            onClick={() => props.onClick(item?.orderItemId, item?.service?.id)}
          >
            <ListItemAvatar>
              <CustomAvatar src={item?.service?.image} />
            </ListItemAvatar>
            <CustomListItem
              primary={!!item?.service?.name ? item?.service?.name : ""}
              secondary={getStatusText(item)}
              status={item?.orderStatus?.value}
            />
            <ChevronRightIcon sx={{ color: "text.secondary" }} />
          </CustomListItemButton>
        ))}
    </List>
  );
}

OrderListing.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

const OrderListingLoader = ({ loading, isLast }) => (
  <CustomListItemButton divider={isLast}>
    <ListItemAvatar>
      <CustomAvatar loading={loading} />
    </ListItemAvatar>
    <CustomListItem
      primary={<Skeleton width="75%" />}
      secondary={<Skeleton width="50%" />}
    />
  </CustomListItemButton>
);

OrderListingLoader.propTypes = {
  isLast: PropTypes.bool,
  loading: PropTypes.bool,
};

export default React.forwardRef(OrderListing);
