import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import React from "react";
import { CustomAvatar, CustomButton, CustomChip } from "../..";
import { Typography } from "@mui/material";
import { dateTimeFormatterV2 } from "../../../utils";
import moment from "moment";

const CustomPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: 8,
  border: "1px solid",
  borderColor: theme.palette.grey.border,
  padding: 30,
  boxShadow: "0 16px 16px 0 rgba(54, 58, 87, 0.06)",
  backdropFilter: "blur(30px)",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    padding: 18,
  },

  // Ribbon
  overflow: "hidden",
}));

const Ribbon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "inActive",
})(({ theme, inActive }) => ({
  margin: 0,
  // padding: "12px 0px",
  background: inActive ? theme.palette.error.main : theme.palette.success.main,
  color: theme.palette.common.white,
  fontSize: "0.8rem",
  position: "absolute",
  top: 0,
  left: 0,
  transform: "translateX(18%) translateY(100%) rotate(-45deg)",
  transformOrigin: "top-left",
  width: 44,

  "&:before": {
    content: `""`,
    position: "absolute",
    top: 0,
    // margin: "0 -1px",
    width: "100%",
    height: "100%",
    background: inActive
      ? theme.palette.error.main
      : theme.palette.success.main,
    left: "100%",
  },
  "&:after": {
    content: `""`,
    position: "absolute",
    top: 0,
    // margin: "0 -1px",
    width: "100%",
    height: "100%",
    background: inActive
      ? theme.palette.error.main
      : theme.palette.success.main,
    right: "100%",
  },
}));

// const images = ["/assets/temp/heart.png", "/assets/temp/leg-massage.png", "/assets/temp/shoulder-massage.png"]

const CountBox = styled(Box)({
  fontWeight: 500,
  marginLeft: 6
});

function SubscriptionCard({ item, loading, index, ...props }) {
  // has service Expired
  const isBookable = item?.isBookable;
  const endDate = moment(item?.expired_on);
  const currentDay = moment();
  let isExpired = moment().isAfter(endDate);
  let showExpiry = Math.round(endDate.diff(currentDay, "minutes") / 1440) < 10;
  // const isLessThan14Days = item?.createdAt
  //     ? moment().diff(item?.createdAt, 'days') + 1 <= 14
  //     : false;

  const expiredText = () => {
    if (isExpired && showExpiry) return `Expired on: ${endDate.fromNow()}`;
    else if (showExpiry)
      return `Expires on: ${Math.round(
        endDate.diff(currentDay, "minutes") / 1440
      )} days`;
    else return `Expires on: ${dateTimeFormatterV2(endDate)}`;
  };
  const getServiceCount = (value) => {
    if (value === null || value <= 0) return 0;
    return value < 10 ? (value == 0 ? value : `0${value}`) : value;
  }

  const pendingCount = item?.pending_services_count;

  return (
    <CustomPaper elevation={0} onClick={() => props.handleCardClick(item?.id)} id={`subscriptionCard${item?.id}`}>
      {/* <Ribbon inActive={!item?.status}>{item?.status ? "Active" : "Inactive"}</Ribbon> */}
      {!loading && (
        <Box sx={{ position: "absolute", right: "30px" }}>
          <CustomChip
            label={item?.type}
            variant="contained"
            type={item?.type}
          />
        </Box>
      )}
      <Stack
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <Stack alignItems="center" sx={{ width: "100%" }}>
          <CustomAvatar
            src={item?.image}
            loading={loading}
            width={60}
            height={60}
          />
          {/* <Typography variant="body2" color="textSecondary" sx={{mt: 1.5, mb: 0.75}} >Monthly</Typography> */}
          {loading ? (
            <Skeleton width="60%" />
          ) : (
            <Typography
              sx={{ fontSize: "1.1rem" }}
              fontWeight="bold"
              sx={{ mt: 1 }}
            >
              {item?.name}
            </Typography>
          )}
          {loading ? (
            <Skeleton width="40%" />
          ) : (
            !!item?.expired_on && (
              <Box sx={{ py: 0.5, px: 2.5, borderRadius: 1 }}>
                <Typography
                  variant="body2"
                  color={isExpired && showExpiry ? "error" : "secondary"}
                >
                  {expiredText()}
                </Typography>
              </Box>
            )
          )}
          <Stack
            sx={{ mb: 1.7, mt: 1.3, width: "100%", justifyContent: "center" }}
            spacing={1.5}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Completed:
                </Typography>
                <CountBox component="span">
                  {getServiceCount(item?.completed_services_count)}
                </CountBox>
              </Stack>
            )}
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Booked:
                </Typography>
                <CountBox component="span">
                  {getServiceCount(item?.booked_services_count)}
                </CountBox>
              </Stack>
            )}
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Remaining:
                </Typography>
                <CountBox component="span">
                  {getServiceCount(item?.pending_services_count)}
                </CountBox>
              </Stack>
            )}
          </Stack>
        </Stack>
        {loading ? (
          <Skeleton width="90%" />
        ) : // isCancelled
        item?.isCancelled ? (
          <Typography color="error">
            Cancelled on {moment(item?.cancelledOn).format("Do MMM YYYY")}
          </Typography>
        ) : pendingCount > 0 && !isExpired ? (
          isBookable ? (
            <CustomButton
              fullWidth
              onClick={(event) => props.handleServiceBooking(event, item?.id)}
              id={`bookService${item?.id}`}
            >
              + Book a Service
            </CustomButton>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Non-bookable
            </Typography>
          )
        ) : (
          <></>
        )}
      </Stack>
    </CustomPaper>
  );
}

export default SubscriptionCard;
