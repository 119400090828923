import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.fill,
  },
}));

function BmiBodyIcon(props) {
  const classes = useStyles(props);

  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.027 4a1.438 1.438 0 1 0 1.453 1.438A1.446 1.446 0 0 0 12.027 4ZM9.848 7.616a.727.727 0 0 0-.667.44L7 13.141l1.337.573 1.158-2.7.353 2.463v5.033h1.453v-4.356h1.453v4.359h1.453v-5.036l.353-2.469 1.158 2.7 1.337-.573-2.18-5.086a.727.727 0 0 0-.667-.44l-4.36.007Zm.837 1.453h2.685l-.519 3.633h-1.647l-.519-3.633Z"
        className={classes.cls1}
      />
    </SvgIcon>
  );
}

BmiBodyIcon.defaultProps = {
  fill: "#0063E7",
};

export default BmiBodyIcon;
