import React from "react";
import { drawerProps, themes } from "../utils";

export const ThemeContext = React.createContext({
  name: themes.default,
  setTheme: () => null,
});

export const AuthContext = React.createContext({
  auth: {
    email: "",
    password: "",
    device: "client_app_web_roles",
    refresh: true,
  },
  setAuth: () => null,
});

export const SnackContext = React.createContext({
  open: false,
  message: "",
  duration: 6000,
  setSnack: () => null,
});

export const DrawerContext = React.createContext({
  open: false,
  anchor: drawerProps.direction.right,
  variant: drawerProps.variant.temporary,
  onClose: () => null,
  setDrawer: () => null,
});

export const dialogDefaultContext = {};

export const DialogContext = React.createContext({
  open: false,
  onAccept: () => null,
  onCloseCallback: () => null,
  title: "",
  content: "",
  renderContent: null,
  positiveButtonProps: {},
  negativeButtonProps: {},
  setDialog: () => null,
  sx: {
    Dialog: {},
    DialogContent: {},
  },
});

export const SOSContext = React.createContext({
  open: false,
  setSOS: () => null
})
