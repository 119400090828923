import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import { stringAvatar } from "./util";
import { ProductPlaceholderIcon } from "../../assets";
import CustomChip from "../chip";
import { Stack, styled, Typography } from "@mui/material";
import { SubscriptionType } from "../../utils";
function CustomAvatarWithText(props) {
  const borderRadius = "8px";

  const getTypeColor = (type) => {
    if (type) {
      const selected = SubscriptionType.filter(
        (subType) => subType.label === type
      );
      return selected?.length > 0 ? selected?.[0]?.color : "";
      // color: selected?.length > 0 ? "white" : "",
      // };
    }
    return {};
  };

  const CustomBox = styled(Box)(({ type }) => ({
    position: "absolute",
    bottom: 0,
    height: "20px",
    width: "100%",
    backgroundColor: getTypeColor(type),
    // borderRadius: "0px 25px 25px 25px",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    borderTopRightRadius: "6px",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "18px",
      height: "50px",
      width: "10px",
      borderBottomLeftRadius: "12px",
      boxShadow: `0 18px 0 0 ${getTypeColor(type)}`,
    },
  }));

  return (
    <Box sx={{ position: "relative" }}>
      {props.src ? (
        <Avatar
          alt={props.alt}
          src={props.src ? props.src : null}
          variant={props.variant}
          sx={{
            width: `${props.width} !important`,
            height: `${props.height} !important`,
            bgcolor: "red",
            position: "relative",
            background: "#F2FFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderBottomLeftRadius: "1em",
            // borderBottomRightRadius: "0em",
            // ...stringAvatar(props.name).sx,
            ...props.sx,
          }}
        ></Avatar>
      ) : (
        <Box
          sx={{
            background: "#F2FFFF",
            // background: "red",
            // height: "70%",
            // width: "100%",
            width: `${props.width} !important`,
            height: `${props.height} !important`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottomLeftRadius: "1em",
          }}
        >
          <ProductPlaceholderIcon />
        </Box>
      )}

      {/* <CustomChip
        label={props?.chip?.type}
        type={props?.chip?.type}
        sx={{
          mt: 0,
          fontStyle: "normal",
          width: "100%",
          zIndex: 999999,
          borderTopRightRadius: "0.75em",
          borderTopLeftRadius: "-0.75em",
          "&:after": {
            content: `""`,
            position: "absolute",
            top: "47%",
            left: "5%",
            marginLeft: "-5px",
            borderWidth: "5px",
            borderStyle: "solid",
            // borderRadius: "0.75rem",
            borderColor: " transparent transparent #555 #555",
          },
        }}
      /> */}
      <CustomBox type={props?.chip?.type}>
        <Typography
          variant="body2"
          sx={{
            position: "relative",
            zIndex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "common.white",
            fontSize: "12px",
            height:"100%"
          }}
        >
          {props?.chip?.type}
        </Typography>
      </CustomBox>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          heigth: "100%",
          // top: "70%",
          // right: 0,
        }}
      >
        {/* <Box sx={{ position: "absolute", height: "10px", width: "16px" }}>
          <Box
            sx={{ height: "100%", bgcolor: props.src ? "white" : "#F2FFFF" }}
            style={{ borderBottomLeftRadius: "0.75em" }}
          ></Box>
        </Box> */}

        {/* <CustomBox >Hello</CustomBox> */}

        {/* <CustomChip
          label={props?.chip?.type}
          type={props?.chip?.type}
          sx={{
            mt: -0.75,
            fontStyle: "normal",
            width: "100%",
            zIndex: 999999,
            border: 0,
            borderTopRightRadius: "0.75em",
            borderTopLeftRadius: "0em",
          }}
        /> */}
        {/* <Box sx={{ bgcolor: "#F67280", height: "8px" }}>
          <Box
            sx={{ height: "100%", bgcolor: "white" }}
            style={{ borderTopRightRadius: borderRadius,  borderBottomLeftRadius: borderRadius}}
          ></Box>
        </Box> */}
      </Box>
    </Box>
  );
}
CustomAvatarWithText.propTypes = {
  /**
   * Image link to render in avatar.
   */
  src: PropTypes.string,
  /**
   * alt text when src is broken(explicitly need to avoid eslint warning).
   */
  alt: PropTypes.string,
  /**
   * Specifies the variant of the avatar. Available types - 'circular' | 'rounded' | 'square'. Defaults to 'circular'.
   */
  variant: PropTypes.oneOf(["circular", "rounded", "square"]),
  /**
   * User name incase if src is broken.
   */
  name: PropTypes.string,
  /**
   * Loading state of the avatar.
   */
  loading: PropTypes.bool,
  /**
   * Specifying the width of the avatar. Defaults to 40px.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Specifying the height of the avatar. Defaults to 40px.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Background color of the avatar component.
   */
  bgcolor: PropTypes.string,
  /**
   * The icon Component.
   */
  icon: PropTypes.node,
  /**
   * Fallback as image for the avatar (can be icons too)
   */
  // imageFallback: PropTypes.bool,
};
CustomAvatarWithText.defaultProps = {
  src: "",
  alt: "",
  variant: "circular",
  name: "",
  loading: false,
  width: 80,
  height: 80,
  // icon: <ProductPlaceholderIcon />,
};
export default CustomAvatarWithText;
