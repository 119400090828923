import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  CustomButton,
  CustomTextfield,
  CustomSelect,
  AddressAutoComplete,
} from "../..";
import { IconButton } from "@mui/material";
import { useRadioGroup } from "@mui/material/RadioGroup";
import CustomRadioFormControlLabel from "./customRadioFormControlLabel";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RightArrowStripesIcon } from "../../../assets";
// import { v4 as uuidv4 } from 'uuid';

function Location(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }
  return (
    <Box onClick={() => props.onClick(props.value)}>
      <Stack
        gap={0}
        sx={{
          borderBottom: 1,
          borderColor: "grey.border",
          px: { xs: 2.5, md: 3.5 },
          pt: 2,
          cursor: "pointer",
        }}
      >
        <Typography color="textSecondary" sx={{ mb: 0.5 }}>
          Home
        </Typography>
        <CustomRadioFormControlLabel
          value={props.value}
          label={props.label}
          checked={checked}
        />
        <Typography
          sx={{
            mb: 2,
            mr: 2.25,
            color: "text.secondary",
            ...(checked && {
              color: "text.primary",
            }),
          }}
        >
          No:15 - Manas Hotel, Kuala Lumpur, Malaysia - 50100
        </Typography>
      </Stack>
    </Box>
  );
}

const options = [
  {
    label: "Stanislov Kieron",
    value: "1",
  },
  {
    label: "Stanislov Kieron2",
    value: "2",
  },
  {
    label: "Stanislov Kieron3",
    value: "3",
  },
  {
    label: "Stanislov Kieron4",
    value: "4",
  },
  {
    label: "Stanislov Kieron5",
    value: "5",
  },
];

function AddAddressDrawer(props) {
  // Component States
  const [location, setLocation] = useState("");
  const [address_type, setAddressType] = useState(null);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const styleProp = {
    sx: { width: "100%" },
  };

  useEffect(() => {
    if (props?.isEdit) {
      const { data: { add_location = {} } = {} } = props;
      setAddressLine1(add_location?.fieldState?.addressLine1);
      setAddressLine2(add_location?.fieldState?.addressLine2);
      setCity(add_location?.fieldState?.city);
      setState(add_location?.fieldState?.state);
      setCountry(add_location?.fieldState?.country);
      setPostalCode(add_location?.fieldState?.postalCode);
    }
  }, [props?.data]);

  const handleSave = () => {
    const clientLocation = {
      ...location,
      address_type,
      fieldState: {
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        postalCode,
      },
      addressLine1,
      addressLine2,
      city,
      area: city,
      state,
      country,
      postalCode,
      // id: uuidv4(),
    };

    props.onSave(clientLocation);
  };

  //   Maps autocomplete Callback
  const handleOnPlaceSelect = (addressContainer) => {
    // If none of these property exists in the value object, then return;
    if (
      !(
        !!addressContainer?.address &&
        !!addressContainer?.address_components &&
        !!addressContainer?.latitude &&
        !!addressContainer?.longitude
      )
    ) {
      return;
    } else {
      let addrLine1 = `${
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("street_number")
        )?.[0]?.long_name ?? ""
      } ${
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("route")
        )?.[0]?.long_name ?? ""
      }`;
      let addrLine2 = `${
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("sublocality")
        )?.[0]?.long_name ?? ""
      }`;
      // let street_number = value?.address_components?.filter(_ => _?.types?.includes("street_number"))?.[0]?.long_name ?? ""
      // let route = value?.address_components?.filter(_ => _?.types?.includes("route"))?.[0]?.long_name ?? ""
      let locality =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("locality")
        )?.[0]?.long_name ?? "";

      let administrative_area_level_2 =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("administrative_area_level_2")
        )?.[0]?.long_name ?? "";

      let administrative_area_level_1 =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("administrative_area_level_1")
        )?.[0]?.long_name ?? "";

      let countryName =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("country")
        )?.[0]?.long_name ?? "";

      let postal_code =
        addressContainer?.address_components?.filter((_) =>
          _?.types?.includes("postal_code")
        )?.[0]?.long_name ?? "";

      // Set All the states
      setLocation(addressContainer);
      setAddressLine1(addrLine1);
      setAddressLine2(addrLine2);

      if (locality === null) {
        setCity(administrative_area_level_2);
      } else if (administrative_area_level_2 === null) {
        setCity(locality);
      } else {
        setCity(administrative_area_level_2);
      }

      setState(administrative_area_level_1);
      setCountry(countryName);
      setPostalCode(postal_code);
    }
  };

  // Disable Country & Postal code if filled
  const checkCountryPostalCodeDisability = () => {
    if (typeof location === typeof "") {
      return false;
    } else {
      if (typeof location === "object" && location !== null) {
        if (typeof location?.address === typeof "") {
          return true;
        }
      }
    }
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          pt: 2,
          pb: 1.5,
          px: 1,
          alignItems: "center",
          border: 1,
          borderColor: "grey.border",
          position: "absolute",
          right: 0,
          left: 0,
          top: 0,
        }}
      >
        <IconButton onClick={props.handleClose} aria-label="close-drawer">
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ flex: 1, textAlign: "center", ml: "-44px" }}>
          Add Address
        </Typography>
      </Stack>
      <Box
        sx={{
          mb: 6.5,
          mt: 8.25,
          py: 2.5,
          px: 2.5,
          maxHeight: "calc(100vh - 52px + 66px)",
          overflow: "scroll",
        }}
      >
        {/* ADDRESS FIELDS */}

        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <CustomSelect
              label={"Address Type"}
              value={address_type}
              onChange={(event, newValue) => {
                setAddressType(newValue);
              }}
              options={props.options}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <AddressAutoComplete
              value={props?.data?.add_location}
              handleOnPlaceSelect={handleOnPlaceSelect}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Address Line 1"}
              value={addressLine1}
              onChange={(event) => setAddressLine1(event.target.value)}
              size="small"
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Address Line 2"}
              value={addressLine2}
              onChange={(event) => setAddressLine2(event.target.value)}
              size="small"
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"City"}
              value={city}
              onChange={(event) => setCity(event.target.value)}
              size="small"
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"State"}
              value={state}
              onChange={(event) => setState(event.target.value)}
              size="small"
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Country"}
              value={country}
              onChange={(event) => setCountry(event.target.value)}
              size="small"
              disabled={checkCountryPostalCodeDisability() && country}
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"Postal Code"}
              value={postalCode}
              onChange={(event) => setPostalCode(event.target.value)}
              size="small"
              disabled={
                checkCountryPostalCodeDisability() &&
                props?.data?.add_location?.fieldState?.postalCode &&
                postalCode
              }
              {...styleProp}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        <CustomButton
          fullWidth
          sx={{ borderRadius: 0, py: 2 }}
          endIcon={<RightArrowStripesIcon />}
          onClick={handleSave}
        >
          Save
        </CustomButton>
      </Box>
    </>
  );
}

export default AddAddressDrawer;
