import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomButton, CustomTextfield, CustomSelect } from "../../components";
import { IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RightArrowStripesIcon } from "../../assets";
import UploaderWithPreview from "../uploaderWithPreview";
import { errorMessages, isObjectEmpty, snackProps } from "../../utils";
import { withSnackBar } from "../../HOC's";

function AddIdDrawer(props) {
  const {
    isEdit,
    editData,
    options = [],
    onSave = () => {},
    handleClose = () => {},
    parentID = "",
  } = props;

  // Component States
  const [id_type_name, setIdTypeName] = useState("");
  const [id_type, setIdType] = useState();
  const [id_photo, setIdProof] = useState();
  const [errorText, setErrorText] = useState({});

  const styleProp = {
    sx: { width: "100%" },
  };

  // const handleUploadedFile = (value) => {
  //   const reader = new FileReader();
  //   reader.addEventListener(
  //     "load",
  //     async () => {
  //       value = await reader.result;

  //       setIdProof(value);
  //     },
  //     false
  //   );
  //   if (value) {
  //     reader.readAsDataURL(value);
  //   }
  // };

  useEffect(() => {
    console.log("-------------------------------------");
    console.log(editData);
    debugger;
    if (!isObjectEmpty(editData)) {
      setIdTypeName(editData?.id_type_name);
      setIdType(editData?.id_type);
      setIdProof(editData?.id_photo);
      // setIdProof();
      // setMobileType(editData?.mobile_type);
      // setIsdCode(
      //   filterCountryObject(
      //     editData?.mobilenumberISDcode?.toString()?.replace("+", "")
      //   )
      // );
      // setMobileNumber(editData?.mobilenumber);
    }
  }, [editData]);

  const validate = () => {
    let localError = false;
    let localErrorText = {};

    // If the input fields are not empty, then return error false
    if (!!id_type_name && !!id_type) return false;

    // If Id type is empty
    if (!id_type_name) {
      localError = true;
      localErrorText = {
        ...localErrorText,
        id_type_name: errorMessages.required,
      };
    }

    // If Id number is empty
    if (!id_type) {
      localError = true;
      localErrorText = { ...localErrorText, id_type: errorMessages.required };
    }

    setErrorText(localErrorText);

    return localError;
  };

  const handleSave = () => {
    debugger;
    const validation = validate();

    // Validate fields
    if (validation) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: errorMessages.fieldsRequired,
      });
    } else {
      let clientId = isEdit ? { ...editData } : {};
      clientId = {
        ...clientId,
        id_type_name,
        id_type,
        id_photo,
        id_photo_filetype: "",
        id_photo_size: "",
        label: id_type_name?.label,
        value: id_type_name?.value,
        constIdentifier: id_type_name?.constIdentifier,
        // id: uuidv4(),
      };
      onSave(clientId);
    }
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          pt: 2,
          pb: 1.5,
          px: 1,
          alignItems: "center",
          border: 1,
          borderColor: "grey.border",
          position: "absolute",
          right: 0,
          left: 0,
          top: 0,
        }}
      >
        <IconButton
          onClick={handleClose}
          aria-label="close-drawer"
          id={`closeBtn${parentID}`}
        >
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
        <Typography sx={{ flex: 1, textAlign: "center", ml: "-44px" }}>
          {isEdit ? `Edit ID` : `Add ID`}
        </Typography>
      </Stack>
      <Box
        sx={{
          mb: 6.5,
          mt: 8.25,
          py: 2.5,
          px: 2.5,
          maxHeight: "calc(100vh - 52px + 66px)",
          overflow: "scroll",
        }}
      >
        {/* ID FIELDS */}
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <CustomSelect
              label={`ID Type`}
              value={id_type_name}
              onChange={(event, newValue) => {
                setIdTypeName(newValue);
              }}
              options={options}
              size="small"
              InputProps={{
                required: true,
                error: !!errorText["id_type_name"],
                helperText: errorText["id_type_name"],
              }}
              id={`idType${parentID}`}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={"ID Number"}
              value={id_type}
              onChange={(event) => setIdType(event.target.value)}
              size="small"
              required
              error={!!errorText["id_type"]}
              helperText={errorText["id_type"]}
              id={`idNumber${parentID}`}
              {...styleProp}
            />
          </Grid>

          <Grid item xs={12}>
            <UploaderWithPreview
              label={"id_proof"}
              // handleFile={(value)=> handleUploadedFile(value)}
              handleFile={(value) => setIdProof(value)}
              handleRemove={() => {}}
              value={""}
              customExtensions={""}
              file={""}
              fileUrl={
                id_photo
                  ? `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_FILE_PORT_DB_NAME}/${id_photo}`
                  : ""
              }
              // fileUrl={""}
              idData={{
                fileUrl: id_photo
                  ? `${process.env.REACT_APP_PROTOCAL}://${process.env.REACT_APP_FILE_HOST}/${process.env.REACT_APP_FILE_PORT_DB_NAME}/${id_photo}`
                  : "",
              }}
              parentID={parentID}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", right: 0, left: 0, bottom: 0 }}>
        <CustomButton
          fullWidth
          sx={{ borderRadius: 0, py: 2 }}
          endIcon={<RightArrowStripesIcon />}
          onClick={handleSave}
          id={`${isEdit ? "updateId" : "saveId"}${parentID}`}
        >
          {isEdit ? "Update ID" : "Save ID"}
        </CustomButton>
      </Box>
    </>
  );
}

AddIdDrawer.propTypes = {
  editData: PropTypes.any,
  handleClose: PropTypes.func,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
  options: PropTypes.array,
};

export default withSnackBar(AddIdDrawer);
