import { ExitToAppOutlined } from "@mui/icons-material";
import {
  HomeIcon,
  TrackerIcon,
  PurchaseBagIcon,
  EnquiriesIcon,
  MyOrderIcon,
  SubscriptionIcon,
  ChatIcon,
  SettingsIcon
} from "../assets";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import WishlistIcon from "../assets/icons/sidebar/wishlistIcon";

export const showInMenuRoutes = {
  home: {
    pathName: "/home",
    label: "Home",
    subRoutes: [],
    icon: <HomeIcon />,
    selectedIcon: (theme) => <HomeIcon color={"secondary"} />,
  },
  tracker: {
    pathName: "/tracker",
    label: "Tracker",
    subRoutes: [],
    icon: <TrackerIcon />,
    selectedIcon: (theme) => (
      <TrackerIcon color={theme.palette.secondary.main} />
    ),
  },
  wishlist: {
    pathName: "/wishlist",
    label: "Wishlist",
    subRoutes: [],
    // icon: <FavoriteBorderOutlinedIcon />,
    icon: <WishlistIcon/>,
    selectedIcon: (theme) => <WishlistIcon color={theme.palette.secondary.main} />,
  },
  purchase: {
    pathName: "/purchase",
    label: "Purchase",
    subRoutes: {
      purchaseDetails: {
        pathName: "/purchase/details/:id/:versionId",
        absolutePath: "/purchase/details",
        label: "Purchase",
      },
    },
    icon: <PurchaseBagIcon />,
    selectedIcon: (theme) => <PurchaseBagIcon color={"secondary"} />,
  },

  enquiries: {
    pathName: "/enquiries",
    label: "Enquiries",
    subRoutes: {
      enquiryPayment: {
        pathName: "/payment/:enquiry_id/:quote_id/:amount",
        label: "Payment",
        subRoutes: [],
        icon: <PurchaseBagIcon />,
        selectedIcon: (theme) => <PurchaseBagIcon color={"secondary"} />,
      },
    },
    icon: <EnquiriesIcon />,
    selectedIcon: (theme) => <EnquiriesIcon color={"secondary"} />,
  },
  myOrders: {
    pathName: "/myOrders",
    label: "My Orders",
    subRoutes: [],
    icon: <MyOrderIcon />,
    selectedIcon: (theme) => <MyOrderIcon color={"secondary"} />,
  },
  subscriptions: {
    pathName: "/subscriptions",
    label: "Subscription",
    subRoutes: {
      subscriptionDetail: {
        pathName: "/subscriptions/detail",
        absolutePath: "/subscriptions/detail",
        label: "Subscription Detail",
      },
      subscriptionServiceBooking: {
        pathName: "/subscriptions/service/:id",
        absolutePath: "/subscriptions/service",
        label: "Subscription Service Booking",
      },
    },
    icon: <SubscriptionIcon />,
    selectedIcon: (theme) => <SubscriptionIcon color={"secondary"} />,
  },
  // issues: {
  //   pathName: "/issues",
  //   label: "Issues",
  //   subRoutes: [],
  //   icon: <HomeIcon />,
  //   selectedIcon: (theme) => <HomeIcon color={"secondary"} />,
  // },
  settings: {
    pathName: "/settings",
    label: "Settings",
    subRoutes: [],
    icon: <SettingsIcon />,
    selectedIcon: (theme) => (
      <SettingsIcon color={theme.palette.secondary.main} />
    ),
  },
  // privacy_policy: {
  //   pathName: "/privacy",
  //   to:"https://www.tanya.care/privacy-policy/",
  //   label: "Privacy Policy",
  //   subRoutes: [],
  //   icon: <SettingsIcon />,
  //   selectedIcon: (theme) => (
  //     <SettingsIcon color={theme.palette.secondary.main} />
  //   ),
  // },
  // terms_conditions: {
  //   pathName: "/terms_conditions",
  //   to:"https://www.tanya.care/terms-of-use/",
  //   label: "Terms & Conditions",
  //   subRoutes: [],
  //   icon: <SettingsIcon />,
  //   selectedIcon: (theme) => (
  //     <SettingsIcon color={theme.palette.secondary.main} />
  //   ),
  // },
  // about: {
  //   pathName: "/about",
  //   label: "About",
  //   subRoutes: [],
  //   icon: <AboutIcon />,
  //   selectedIcon: (theme) => <AboutIcon color={"secondary"} />,
  // },
  // rateUs: {
  //   pathName: "/rateUs",
  //   label: "Rate Us",
  //   subRoutes: [],
  //   icon: <HomeIcon />,
  //   selectedIcon: (theme) => <HomeIcon color={"secondary"} />,
  // },
  logOut: {
    pathName: "/",
    label: "Log out",
    subRoutes: [],
    icon: (
      <ExitToAppOutlined
        fontSize={"small"}
        style={{
          paddingLeft: 3,
        }}
      />
    ),
    selectedIcon: (theme) => (
      <ExitToAppOutlined
        color={"secondary"}
        fontSize={"small"}
        style={{
          paddingLeft: 3,
        }}
      />
    ),
  },
};

export const dontShowInMenuRoutes = {
  login: {
    pathName: "/",
    label: "Login",
    subRoutes: [],
  },
  forgotPassword: {
    pathName: "/forgotPassword",
    label: "Login",
    subRoutes: [],
  },
  newUserSetUseridPassword: {
    pathName: "/createUserIdPassword",
    label: "CreateUserIdPassword",
    subRoutes: [],
  },
  selfRegistration: {
    pathName: "/selfRegistration",
    label: "selfRegistration",
    subRoutes: {
      success: {
        pathName: "/selfRegistration/success",
        label: "success",
        subRoutes: {},
      },
    },
  },

  register: {
    pathName: "/register",
    label: "Register",
  },
  cart: {
    pathName: "/cart",
    label: "Cart",
    subRoutes: [],
  },
  payment: {
    pathName: "/payment",
    label: "Payment",
    subRoutes: [],
  },
  signIn: {
    pathName: "/signIn",
    label: "SignIn",
    subRoutes: [],
  },
  forgotUserId: {
    pathName: "/forgotUserId",
    label: "ForgotUserId",
    subRoutes: [],
  },
  forgotPassword_: {
    pathName: "/forgotPassword",
    label: "ForgotPassword",
    subRoutes: [],
  },
  signUp: {
    pathName: "/signUp",
    label: "SignUp",
    subRoutes: [],
  },
  profile: {
    pathName: "/profile",
    label: "Profile",
    subRoutes: {
      editProfile: {
        pathName: "/profile/editProfile",
        label: "Edit Profile",
        subRoutes: {},
      },
      editFamilyMember: {
        pathName: "/profile/editFamilyMembers",
        label: "Edit Family Member",
        subRoutes: {},
      },
      editHealthInfo: {
        pathName: "/profile/editHealthInfo",
        label: "Edit Health Information",
        subRoutes: {},
      },
    },
  },
};

export const routes = { ...showInMenuRoutes };

// icon: <LogoutNewIcon />,
//       selectedIcon: <LogoutNewIcon color="secondary" />,
