import PropTypes from "prop-types";
import { CreateIcon, DeleteIcon } from "../../../../../assets";

import Box from "@mui/material/Box";
import { CustomButton } from "../../../../../components";
import { CustomCard } from "../../../../../components/composedComponents/subscriptions/customCard";
import { IconButton } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { getFormattedAddress, isObjectEmpty } from "../../../../../utils";
import { NoDataHandler } from "../../../../../components";
import { EmptyIcon } from "../../../../../assets";

import { StyledChip } from "./styledChip";
import ViewAllAddress from "../../../../registration/components/viewAllAddress";
import AddAddressDrawer from "../../../../../components/cards/addAddressCard";
import { withDialog, withDrawer, withSnackBar } from "../../../../../HOC's";
import { Prompt } from "../../../../registration/components";
import { SectionHeader } from "..";

const dynamicPx = { xs: 1.5, md: 1.5 };

function MultipleAddressMobile(props) {
  const {
    data: addressData = [],
    options = [],
    handleSave,
    handleDelete,
    helperText,
  } = props;

  // Handlers
  const handleCloseDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: <></>,
    });
  };

  const handleSaveAddress = (data) => {
    handleCloseDrawer();
    handleSave(data);
  };

  const handleAddEditClick = (editData = null) => {
    debugger;
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <AddAddressDrawer
          isEdit={!!editData}
          editData={{ ...editData, fieldState: { ...editData } } ?? {}}
          options={options}
          onSave={handleSaveAddress}
          handleClose={handleClose}
        />
      ),
    });
  };

  const handldeDeleteClick = (deletedAddress) => {
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <Prompt
          title={"Delete Address"}
          body={
            <>
              Are you sure, do you want to delete <br /> this address?
            </>
          }
          handleClose={handleClose}
          handleAccept={handleAccept}
        />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: () => handleDelete(deletedAddress),
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handleViewAllClick = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllAddress
          value={addressData}
          handleClose={handleClose}
          handleEditClick={handleAddEditClick}
          handldeDeleteClick={handldeDeleteClick}
          takeAddressDataFromRootObject
        />
      ),
    });
  };

  return (
    <>
      <Stack>
        <SectionHeader
          title="Address Details"
          buttonLabel={addressData?.length > 0 ? "View All" : "+Add"}
          buttonHandler={() =>
            addressData?.length > 0
              ? handleViewAllClick()
              : handleAddEditClick()
          }
        />
        {addressData?.length > 0 ? (
          <Stack
            sx={{ bgcolor: "common.white", p: 2.5 }}
            gap={2.5}
            style={{
              boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
            }}
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack>
              <Typography
                variant="body2"
                color="textSecondary"
                fontWeight="400"
              >
                {addressData?.[0]?.address_type?.label ??
                  addressData?.[0]?.label}
              </Typography>
              <Typography fontWeight="400">
                {getFormattedAddress(addressData?.[0])}
              </Typography>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <IconButton
                size="small"
                onClick={() => {
                  handleAddEditClick(addressData?.[0]);
                }}
              >
                <CreateIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  handldeDeleteClick(addressData?.[0]);
                }}
              >
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Stack>
          </Stack>
        ) : (
          <Box
            sx={{
              pb: 1,
              bgcolor: "common.white",
              boxShadow: "rgb(233 235 239) 0px 7px 7px 0px",
            }}
          >
            <NoDataHandler
              message={"You don't have any address details"}
              empty
              emptyIcon={<EmptyIcon />}
              fontSize={"4.5rem"}
              fullheight={"100px"}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

MultipleAddressMobile.propTypes = {
  addressArray: PropTypes.array,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func,
  helperText: PropTypes.string,
  isEdit: PropTypes.bool,
  options: PropTypes.array,
  required: PropTypes.bool,
};

export default withDrawer(withDialog(MultipleAddressMobile));
