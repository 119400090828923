import React, { useEffect, useState } from "react";
// import img from "../../assets/icons/mobile.svg";
import Button from "@mui/material/Button";
import CustomOtpInput from "../../components/otpInput";
import { IconButton, Typography } from "@mui/material";
import { maskEmail, maskString } from "../../utils";
import EditIcon from "@mui/icons-material/Edit";

const styles = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  mobileNumber: {
    fontSize: "14px",
    // fontWeight: 600,
    color: "#6e7186",
  },
  resend: {
    fontSize: "14px",
    // fontWeight: 600,
    color: "#0063E7",
    cursor: "pointer"
  },
};

function OtpTimer(props) {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      // props.changeResendState(true);
    }
    let myInterval = setInterval(() => {
      // Block Resend State
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <>
      {minutes === 0 && seconds === 0 ? null : (
        <Typography sx={{ textAlign: "center", color: "success.main" }}>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
      )}
    </>
  );
}
class OTPVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: "",
      confirmation: false,
      resendState: false,
      otp: 0,
    };
  }

  componentDidMount = async () => {
    this.setState({ ...this.state, mobileNumber: this.props.mobileNumber });
  };

  resendOTP = () => {
    // alert("resend otp sent to " + this.state.mobileNumber);
    this.props.resendOtp();
  };

  render() {
    return (
      <>
        <div style={styles.flexCenter}>
          <p
            style={{
              margin: "20px",
              color: "black",
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            OTP Verification
          </p>
        </div>
        {/* ALERT MESSAGE */}
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              margin: "20px",
              fontSize: "16px",
              color: "#abafb9",
            }}
          >
            Code sent to{" "}
            <span style={styles.mobileNumber}>
              {this.props.send_OTP_to_mobile
                ? `${this.props.phoneNumberISD}-${maskString(
                    this.props?.phoneNumber
                  )}`
                : maskEmail(this.props.otp_to)}
            </span>
            <IconButton
              style={{ color: "#0063E7" }}
              onClick={() => this.props.changeOtpTo()}
              id="editCodeSent"
            >
              {" "}
              <EditIcon />{" "}
            </IconButton>
          </p>
        </div>
        {/* OTP COMPONENT */}
        <CustomOtpInput
          handleOtpChange={this.props.handleOtpChange}
          hasOtpErrored={false}
          otp={this.props.otp}
          otpSize={4}
          customInput
        />
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              margin: "20px 20px 0px 20px",
              fontSize: "16px",
              color: "#abafb9",
            }}
          >
            Didn't get the code?{" "}
            <span style={styles.resend} id="resendCodeOtp" onClick={() => this.resendOTP()}>
              {"Resend Code"}
            </span>
          </p>
          <p
            style={{
              fontSize: "16px",
              color: "#46b5c9",
              margin: "0px",
              marginTop: "20px",
            }}
          >
            <OtpTimer
              initialMinute={4}
              initialSeconds={59}
              changeResendState={(status) => {
                this.setState({ resendState: status });
              }}
            />
          </p>
        </div>
        <div style={styles.buttonStyle}>
          <Button
            style={{ width: "100%", margin: "20px", textTransform: "none" }}
            variant="contained"
            id="verifyOtpBtn"
            disabled={this.props.loading}
            onClick={() => this.props.otpverify()}
          >
            {this.props.loading ? `Verifying` : `Verify OTP`}
          </Button>
        </div>
      </>
    );
  }
}

export default OTPVerification;
