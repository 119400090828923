import * as React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Grid, Hidden, Paper, Stack, styled } from "@mui/material";
import CustomButton from "../button";
import { useHistory } from "react-router-dom";
import { withDialog, withDrawer, withSnackBar } from "../../HOC's";
import { currencyPriceFormatter } from "../../utils";
import { CustomAvatar, CustomChip } from "..";
import { alpha, IconButton } from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import { grey, pink } from "@mui/material/colors";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "10px 16px",
  position: "relative",
  boxShadow: "0 15px 14px 0 rgba(17, 18, 69, 0.03);",
  border: `1px solid ${theme.palette.grey.border}`,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 1rem 5px 1rem",
    // width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: 16,
    // background: "white",
    margin: theme.spacing(0, 1, 0, 1),
  },
  cartAdded: {
    backgroundColor: `${alpha(theme.palette.success.main, 0.25)} !important`,
    borderColor: `${alpha(theme.palette.success.main, 0.45)} !important`,
    color: `${theme.palette.success.main} !important`,
  },
  cartButton: {
    // color: `${theme.palette.enquiry.viewQuotes} !important`,
    borderColor: `${theme.palette.enquiry.viewQuotes} !important`,
  },
}));

function ProductListViewCard({
  redirectUrl,
  loading,
  handleItemEdit,
  handleItemDeactivate,
  isQuickAccessNeeded,
  ...props
}) {
  const classes = useStyles();
  const history = useHistory();

  const handleRedirect = (e, id, versionId) => {
    e.preventDefault();
    history.push(`${redirectUrl}${id}/${versionId}`);
  };

  const handleEnquiry = (e, serviceData) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.handleEnquiry(serviceData);
  };

  const handleAddToCart = (e, serviceData) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.handleAddToCart(serviceData);
  };

  const { data } = props;
  return (
    <StyledPaper>
      <Hidden mdUp>
        <IconButton
          size={"15px"}
          id={`addToWishlist${data?.id}IconButtonPurchaseListing${props?.from}`}
          sx={{
            position: "absolute",
            top: "-5%",
            right: { xs: "-1%", md: "0%" },
            background: "white",
            border: "1px solid #9e9e9e !important",
            padding: "4px",
          }}
          onClick={() =>
            props.handleAddRemoveWishList(data?.id, data?.isWishlist)
          }
        >
          {data?.isWishlist ? (
            <FavoriteIcon sx={{ fontSize: "13px", color: pink[500] }} />
          ) : (
            <FavoriteIcon sx={{ fontSize: "15px", color: grey[400] }} />
          )}
        </IconButton>
      </Hidden>
      <Grid
        container
        columnSpacing={2}
        onClick={(e) => handleRedirect(e, data?.id, data?.versionId)}
        id={`purchase-list-${data?.id}`}
      >
        <Grid item>
          <Stack>
            <CustomAvatar src={data?.image} width={50} height={50} />
            <CustomChip
              label={data?.type}
              type={data?.type}
              sx={{ mt: 1, fontStyle: "normal" }}
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Typography>{data?.name}</Typography>
          <div style={{ display: "flex" }}>
            <Typography variant="body2" color="primary">
              {currencyPriceFormatter(data?.currency, data?.discount)}
            </Typography>
            {data?.price && data?.price !== data?.discount && (
              <Typography
                variant="body2"
                style={{ paddingLeft: 5 }}
                color="textSecondary"
                sx={{ textDecoration: "line-through" }}
              >
                {currencyPriceFormatter(data?.currency, data?.price)}
              </Typography>
            )}
          </div>
          {data?.service_count > 0 && data?.package_count > 0 && (
            <Stack flexDirection="row" gap={1} sx={{ mt: 1.5 }}>
              {data?.service_count > 0 && (
                <CustomChip
                  label={`${
                    data?.service_count <= 5
                      ? data?.service_count
                      : data?.service_count + "+"
                  } ${data?.service_count > 1 ? "Services" : "Service"}`}
                  sx={{ fontStyle: "normal", color: "text.primary" }}
                />
              )}
              {data?.package_count > 0 && (
                <CustomChip
                  label={`${
                    data?.package_count <= 5
                      ? data?.package_count
                      : data?.package_count + "+"
                  } ${data?.package_count > 1 ? "Packages" : "Package"}`}
                  sx={{ fontStyle: "normal", color: "text.primary" }}
                />
              )}
            </Stack>
          )}
          <Stack flexDirection="row" gap={1} sx={{ mt: 1.5 }}>
            {data?.isExpired ? (
              <Typography
                variant={"body2"}
                textAlign={"center"}
                sx={{ color: "red" }}
              >{`This ${data?.type?.toLowerCase()} is unavailable`}</Typography>
            ) : (
              <>
                <CustomButton
                  onClick={(e) => handleEnquiry(e, data)}
                  size={"small"}
                  id={`enquireBtnPurchaseList${data?.id}`}
                >
                  Enquire
                </CustomButton>
                <CustomButton
                  onClick={(e) => handleAddToCart(e, data?.id)}
                  disabled={data?.isAddedToCart}
                  className={
                    data?.isAddedToCart ? classes.cartAdded : classes.cartButton
                  }
                  variant="outlined"
                  size={"small"}
                  id={`${
                    data?.isAddedToCart ? `added` : `addToCart`
                  }PurchaseList${data?.id}`}
                >
                  {data?.isAddedToCart ? `Added` : `Add to Cart`}
                </CustomButton>
              </>
            )}
          </Stack>
        </Grid>
        <Hidden mdDown>
          <Grid item md={4}>
            <Stack flexDirection="row" gap={1} sx={{ mt: 1.5 }}>
              <CustomButton
                onClick={() =>
                  props.handleAddRemoveWishList(data?.id, data?.isWishlist)
                }
                loading={props?.loading}
                fullWidth
                size={"small"}
                variant="outlined"
                sx={{
                  color: pink[500],
                  border: 0,
                  borderColor: pink[500],
                  backgroundColor: pink[50],
                  textTransform: "capitalize",
                  "&:hover": {
                    border: 0,
                    backgroundColor: pink[50],
                  },
                }}
              >
                {data?.isWishlist ? "Remove" : "Add to Wishlist"}
              </CustomButton>
              <CustomButton
                onClick={(e) => handleEnquiry(e, data)}
                size={"small"}
                fullWidth
                variant="outlined"
              >
                Enquire
              </CustomButton>
              <CustomButton
                onClick={(e) => handleAddToCart(e, data?.id)}
                disabled={data?.isAddedToCart}
                fullWidth
                className={
                  data?.isAddedToCart ? classes.cartAdded : classes.cartButton
                }
                size={"small"}
              >
                {data?.isAddedToCart ? `Added` : `Add to Cart`}
              </CustomButton>
            </Stack>
          </Grid>
        </Hidden>
      </Grid>
    </StyledPaper>
  );
}

export default withSnackBar(withDrawer(withDialog(ProductListViewCard)));
