import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorPrimary,
    stroke: (props) => props.htmlColorPrimary,
  },
  cls2: {
    fill: (props) => props.htmlColorSecondary,
    stroke: (props) => props.htmlColorSecondary,
  },
}));

function IdcardDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="id card icon" strokeWidth={0.5}>
        <path
          data-name="icons8-id-card (2)"
          d="M9.578 2.025a.548.548 0 0 0-.565.528v2.282H3.077A2.741 2.741 0 0 0 .25 7.474V17.86a2.741 2.741 0 0 0 2.827 2.639h18.346a2.741 2.741 0 0 0 2.827-2.639V7.474a2.741 2.741 0 0 0-2.827-2.639h-5.936V2.553a.548.548 0 0 0-.565-.528Zm.565 1.056h4.213v2.282a2.111 2.111 0 0 1-4.213 0Zm-7.067 2.81h5.987a3.283 3.283 0 0 0 6.373 0h5.987a1.645 1.645 0 0 1 1.7 1.584V17.86a1.645 1.645 0 0 1-1.7 1.584H3.077a1.645 1.645 0 0 1-1.7-1.584V7.474a1.645 1.645 0 0 1 1.7-1.584Z"
          className={classes.cls1}
        />
        <path
          data-name="icons8-id-card (2)"
          d="M4.162 9.398a.548.548 0 0 0-.565.528V16.9a.548.548 0 0 0 .565.528h6.283a.548.548 0 0 0 .565-.528V9.925a.548.548 0 0 0-.565-.528Zm.565 1.056h5.152v5.919H4.727Zm9.328.843a.529.529 0 1 0 0 1.056h3.153a.529.529 0 1 0 0-1.056Zm0 3.371a.529.529 0 1 0 0 1.056h5.982a.529.529 0 1 0 0-1.056Z"
          className={classes.cls2}
        />
      </g>
    </SvgIcon>
  );
}

IdcardDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default IdcardDuoToneIcon;
