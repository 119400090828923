import React from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomButton } from "../..";
import { CustomCard } from "./customCard";
import {
  getFormattedAddress,
  isObjectEmpty,
} from "../../../utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function ChooseLocationCard(props) {
  const { data } = props;

  return (
    <CustomCard title={props.title ? props.title : "Choose Location"}>
      <Box sx={{ pb: 2 }}>
        {!isObjectEmpty(data) && !props.isEdit && (
          <Box>
            <Stack
              gap={0}
              sx={{
                borderBottom: 1,
                borderColor: "grey.border",
                px: { xs: 2.5, md: 3.5 },
                pt: 2,
              }}
            >
              <Typography color="textSecondary" sx={{ mb: 0.5 }}>
                {data?.label}
              </Typography>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Typography
                  sx={{
                    color: "text.primary",
                  }}
                >
                  {getFormattedAddress(data)}
                </Typography>
                <CheckCircleIcon color="success" />
              </Stack>
            </Stack>
          </Box>
        )}

        <ListItemButton
          sx={{ pl: 3.5 }}
          onClick={props.onSavedAddressButtonClick}
        >
          <ListItemText
            primary={`Saved ${props.label}`}
            primaryTypographyProps={{ color: "primary" }}
          />
          <ListItemIcon sx={{ justifyContent: "flex-end" }}>
            <ChevronRightIcon color="primary" />
          </ListItemIcon>
        </ListItemButton>
        <Divider />
        {props.isEdit && (
          <Box>
            <Stack
              gap={0}
              sx={{
                borderBottom: 1,
                borderColor: "grey.border",
                px: { xs: 2.5, md: 3.5 },
                pt: 2,
              }}
            >
              <Typography color="textSecondary" sx={{ mb: 0.5 }}>
                {`Custom ${props.label}`}
              </Typography>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Typography
                  sx={{
                    color: "text.primary",
                  }}
                >
                  {data?.add_location?.address}
                </Typography>
                <CheckCircleIcon color="success" />
              </Stack>
            </Stack>
          </Box>
        )}
        <Divider />
        <Box sx={{ px: { xs: 2.5, md: 3.5 }, pt: 1 }}>
          <CustomButton
            variant="text"
            color="secondary"
            size="small"
            onClick={props.onAddEditAddressButtonClick}
          >
            {props.isEdit ? `Edit ${props.label}` : `+ Add ${props.label}`}
          </CustomButton>
        </Box>
      </Box>
    </CustomCard>
  );
}

export default ChooseLocationCard;
