import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import { stringAvatar } from "./util";
import { ProductPlaceholderIcon } from "../../assets";
import { avatarColors, idToDigitNumeric } from "../../utils";
function CustomAvatar(props) {
  return (
    <>
      {props.loading ? (
        <Skeleton
          variant="circular"
          width={props.width}
          height={props.height}
        />
      ) : (
        <Avatar
          alt={props.alt}
          src={props.src ? props.src : null}
          variant={props.variant}
          sx={{
            width: props.width,
            height: props.height,
            // ...stringAvatar(props.name).sx,
            ...props.sx,
          }}
        >
          {props.name ? (
            <Avatar
              {...stringAvatar(props.name)}
              sx={{
                background: avatarColors[idToDigitNumeric(props?.id)],
                width: props.width,
                height: props.height,
                bgcolor: props.bgcolor,
                ...props.sx,
              }}
              variant={props?.variant}
            />
          ) : props.icon ? (
            props.icon
          ) : props.children ? (
            props.children
          ) : (
            <Box
              sx={{
                background: "#F2FFFF",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ProductPlaceholderIcon />
            </Box>
          )}
        </Avatar>
      )}
    </>
  );
}
CustomAvatar.propTypes = {
  /**
   * Image link to render in avatar.
   */
  src: PropTypes.string,
  /**
   * alt text when src is broken(explicitly need to avoid eslint warning).
   */
  alt: PropTypes.string,
  /**
   * Specifies the variant of the avatar. Available types - 'circular' | 'rounded' | 'square'. Defaults to 'circular'.
   */
  variant: PropTypes.oneOf(["circular", "rounded", "square"]),
  /**
   * User name incase if src is broken.
   */
  name: PropTypes.string,
  /**
   * Loading state of the avatar.
   */
  loading: PropTypes.bool,
  /**
   * Specifying the width of the avatar. Defaults to 40px.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Specifying the height of the avatar. Defaults to 40px.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Background color of the avatar component.
   */
  bgcolor: PropTypes.string,
  /**
   * The icon Component.
   */
  icon: PropTypes.node,
  /**
   * Fallback as image for the avatar (can be icons too)
   */
  // imageFallback: PropTypes.bool,
};
CustomAvatar.defaultProps = {
  src: "",
  alt: "",
  variant: "circular",
  name: "",
  loading: false,
  width: 40,
  height: 40,
  // icon: <ProductPlaceholderIcon />,
};
export default CustomAvatar;
