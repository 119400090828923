import React, { useContext } from 'react'
import { DialogContext } from '../contexts'

const withDialog = (WrappedComponent) => (props) => {
    const dialog = useContext(DialogContext)
    return (
        <WrappedComponent {...props} dialog={dialog} >{props.children}</WrappedComponent>
    )
}

export default withDialog
