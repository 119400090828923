import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import logo from "../../assets/tanyaCare/Tanya.png";
import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  imageDiv: {
    display: "flex",
    margin: "auto",
    height: "95vh",
    justifyContent:"center",
    alignItems:"center"
  },
  image: {
    width: 200,
    height: 200,
  },
}));

export default function Loading() {
  const classes = style();

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
      <div className={classes.imageDiv}>
        <img src={logo} className={classes.image} />
      </div>
    </Box>
  );
}
