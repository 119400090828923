import * as React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  IconRoot: {
    width: "1.6em",
    height: "1.6em"
  },
  cls1: {
    fill: "none",
    stroke: "#61A1F5",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
}));

function ProductPlaceholderIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      id="prefix__SP_Client_App_Light_Mode_"
      data-name="SP / Client App [Light Mode]"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.48 29.105"
      classes={{root: classes.IconRoot}}
      sx={{
        fontSize: "2rem"
      }}
      {...props}
    >
      <g
        id="Group_3577"
        data-name="Group 3577"
        transform="translate(-19783.5 -5825.5)"
      >
        <path
          id="Path_1432"
          data-name="Path 1432"
          className={classes.cls1}
          d="M16.27,13.252,24.816,6.69l8.54,6.562"
          transform="translate(19770.422 5819.31)"
        />
        <path
          id="Path_1433"
          data-name="Path 1433"
          className={classes.cls1}
          d="M19.93,16.65v8.212a1.812,1.812,0,0,0,1.817,1.817h9.248a1.812,1.812,0,0,0,1.812-1.817v-8.12"
          transform="translate(19768.867 5815.078)"
        />
        <circle
          id="Ellipse_372"
          data-name="Ellipse 372"
          className={classes.cls1}
          cx="1.006"
          cy="1.006"
          r="1.006"
          transform="translate(19792.438 5831.573)"
        />
        <circle
          id="Ellipse_373"
          data-name="Ellipse 373"
          className={classes.cls1}
          cx="1.006"
          cy="1.006"
          r="1.006"
          transform="translate(19796.354 5832.579)"
        />
        <path
          id="Path_1434"
          data-name="Path 1434"
          className={classes.cls1}
          d="M26.879,19.4h.236a.414.414,0,0,1,.391.259l1.116,2.847a.408.408,0,0,1-.247.529.426.426,0,0,1-.541-.236l-.506-1.288L27.3,26.732a.42.42,0,0,1-.84,0V24.921l-.029-.8a.3.3,0,0,0-.311-.3.311.311,0,0,0-.316.3l-.029.9v1.725a.42.42,0,1,1-.84,0V21.419l-.541,1.38a.431.431,0,0,1-.546.236.408.408,0,0,1-.247-.529l1.116-2.847a.42.42,0,0,1,.391-.259h.316"
          transform="translate(19767.318 5813.91)"
        />
        <path
          id="Path_1435"
          data-name="Path 1435"
          className={classes.cls1}
          d="M33.733,30v1.282a.38.38,0,0,1-.753,0V30"
          transform="translate(19763.322 5809.406)"
        />
        <path
          id="Path_1436"
          data-name="Path 1436"
          className={classes.cls1}
          d="M35.35,30v1.282a.38.38,0,0,0,.753,0V30"
          transform="translate(19762.314 5809.406)"
        />
        <path
          id="Path_1437"
          data-name="Path 1437"
          className={classes.cls1}
          d="M36.37,20.83a.374.374,0,0,1,.351.236l.995,2.542a.362.362,0,0,1-.219.472.385.385,0,0,1-.489-.213l-.449-1.15"
          transform="translate(19761.881 5813.302)"
        />
        <path
          id="Path_1438"
          data-name="Path 1438"
          className={classes.cls1}
          d="M31.8,22.319l.495-1.254a.374.374,0,0,1,.351-.236"
          transform="translate(19763.822 5813.302)"
        />
        <path
          id="Path_1439"
          data-name="Path 1439"
          className={classes.cls1}
          d="M32.107,24l-.477,1.231a.385.385,0,0,1-.489.213.362.362,0,0,1-.219-.472l.058-.144"
          transform="translate(19764.205 5811.955)"
        />
        <line
          id="Line_526"
          data-name="Line 526"
          className={classes.cls1}
          x2="0.362"
          transform="translate(19790.486 5836.398)"
        />
        <line
          id="Line_527"
          data-name="Line 527"
          className={classes.cls1}
          y2="4.008"
          transform="translate(19791.217 5837.042)"
        />
        <line
          id="Line_528"
          data-name="Line 528"
          className={classes.cls1}
          x1="0.357"
          transform="translate(19799.619 5836.829)"
        />
        <line
          id="Line_529"
          data-name="Line 529"
          className={classes.cls1}
          y2="3.64"
          transform="translate(19799.389 5837.41)"
        />
        <path
          id="Path_1440"
          data-name="Path 1440"
          className={classes.cls1}
          d="M32.544,27.428h-.6l.6-3.468"
          transform="translate(19763.764 5811.972)"
        />
        <path
          id="Path_1441"
          data-name="Path 1441"
          className={classes.cls1}
          d="M36.687,24.11l.621,3.382H36.67"
          transform="translate(19761.754 5811.908)"
        />
        <line
          id="Line_530"
          data-name="Line 530"
          className={classes.cls1}
          x1="1.541"
          transform="translate(19796.307 5839.4)"
        />
        <line
          id="Line_531"
          data-name="Line 531"
          className={classes.cls1}
          x1="0.759"
          transform="translate(19797.664 5839.4)"
        />
        <path
          id="Path_1442"
          data-name="Path 1442"
          className={classes.cls1}
          d="M17.536,36.743a11.5,11.5,0,0,1,.219,2.226h4.158L21.5,34.794c-.23-2.45-3.307-2.778-6.148-7.177a.765.765,0,0,0-.863-.253h0a.782.782,0,0,0-.483.989l1.271,2.737a13.314,13.314,0,0,0,.84,1.271l-1.288-.909a1.311,1.311,0,0,1-.288-.477l-1.064-2.444a1.311,1.311,0,0,1,.288-1.328,10.869,10.869,0,0,1-.04-2.548l.224-2.3a.788.788,0,0,0-.817-.765h0a.794.794,0,0,0-.73.621l-.575,2.433a10.766,10.766,0,0,0-.236,2.3l.035,4.221A3.744,3.744,0,0,0,13.01,33.54C13.378,33.862,17.306,36.088,17.536,36.743Zm-.575,2.226v2.157h5.5V38.969Z"
          transform="translate(19772.41 5812.979)"
        />
        <path
          id="Path_1443"
          data-name="Path 1443"
          className={classes.cls1}
          d="M42.388,24.635,41.858,22.2a.794.794,0,0,0-.742-.633h0a.788.788,0,0,0-.817.765l.224,2.3a10.87,10.87,0,0,1-.04,2.548,1.311,1.311,0,0,1,.288,1.328l-1.064,2.444a1.311,1.311,0,0,1-.288.477l-1.288.909a13.315,13.315,0,0,0,.84-1.271l1.294-2.737a.782.782,0,0,0-.483-.989h0a.765.765,0,0,0-.863.253C36.079,32,33,32.324,32.772,34.774l-.414,4.175h4.158a11.5,11.5,0,0,1,.219-2.226c.23-.656,4.158-2.875,4.5-3.192a3.744,3.744,0,0,0,1.386-2.375l.035-4.221a10.766,10.766,0,0,0-.27-2.3ZM31.8,41.117h5.5V38.96H31.8Z"
          transform="translate(19763.822 5812.988)"
        />
      </g>
    </SvgIcon>
  );
}

export default ProductPlaceholderIcon
