import * as React from "react";
import { SvgIcon } from "@mui/material";

function DeleteIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <path
        d="M11.65 5a2.458 2.458 0 00-2.422 2.1H6.833a.472.472 0 00-.168 0h-1.14a.525.525 0 100 1.05h.749l.881 9.11A1.932 1.932 0 009.071 19h5.158a1.932 1.932 0 001.916-1.74l.882-9.11h.749a.525.525 0 100-1.05h-1.14a.525.525 0 00-.168 0h-2.4A2.459 2.459 0 0011.65 5zm0 1.05A1.387 1.387 0 0113 7.1h-2.7a1.387 1.387 0 011.35-1.05zm-4.322 2.1h8.644l-.872 9.009a.868.868 0 01-.871.791H9.071a.869.869 0 01-.871-.791L7.328 8.15zm3.089 1.742a.525.525 0 00-.517.533v5.25a.525.525 0 101.05 0v-5.25a.524.524 0 00-.533-.533zm2.45 0a.525.525 0 00-.517.533v5.25a.525.525 0 101.05 0v-5.25a.524.524 0 00-.533-.533z"
        fill="#FF4D4A"
      /> */}
      <path
      d="M12 0a4.272 4.272 0 0 0-2.768 1.03A4.204 4.204 0 0 0 7.81 3.6H3.67a.823.823 0 0 0-.29 0H1.407a.912.912 0 0 0-.642.264.896.896 0 0 0 0 1.272c.17.17.401.264.642.264h1.295l1.523 15.617a3.302 3.302 0 0 0 1.079 2.123c.612.55 1.408.857 2.235.86h8.919a3.355 3.355 0 0 0 2.235-.86 3.3 3.3 0 0 0 1.078-2.123L21.297 5.4h1.295c.24 0 .472-.095.642-.264a.896.896 0 0 0 0-1.272.912.912 0 0 0-.642-.264h-1.971a.916.916 0 0 0-.29 0h-4.15a4.204 4.204 0 0 0-1.419-2.568A4.272 4.272 0 0 0 12 0Zm0 1.8a2.412 2.412 0 0 1 1.48.5c.423.327.723.784.854 1.3h-4.67c.131-.516.432-.973.855-1.3a2.412 2.412 0 0 1 1.48-.5ZM4.525 5.4h14.947l-1.508 15.444a1.484 1.484 0 0 1-.486.97c-.28.25-.643.389-1.02.386h-8.92a1.51 1.51 0 0 1-1.02-.387 1.485 1.485 0 0 1-.486-.969L4.526 5.4Zm5.341 2.986a.914.914 0 0 0-.83.569.893.893 0 0 0-.064.345v9c0 .239.096.468.266.636a.912.912 0 0 0 1.284 0 .896.896 0 0 0 .266-.636v-9a.892.892 0 0 0-.266-.65.908.908 0 0 0-.656-.264Zm4.237 0a.914.914 0 0 0-.83.569.89.89 0 0 0-.064.345v9c0 .239.095.468.265.636a.912.912 0 0 0 1.284 0 .896.896 0 0 0 .266-.636v-9a.894.894 0 0 0-.566-.848.915.915 0 0 0-.355-.066Z"
      fill="#FF4D4A"
    />
    </SvgIcon>
  );
}

export default DeleteIcon;
