import * as React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: (props) => props.htmlColorPrimary,
  },
  cls2: {
    fill: (props) => props.htmlColorSecondary,
  },
}));

function HeightDuoToneIcon(props) {
  const classes = useStyles(props);
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M7.363 0a2.182 2.182 0 1 0 0 4.364 2.182 2.182 0 0 0 0-4.364Zm7.185 0L9.015 5.537a2.119 2.119 0 0 0-.562-.084h-3.27A2.198 2.198 0 0 0 3 7.636v8.728h1.09V24h2.183v-7.636h2.18V24h2.182V7.636a2.12 2.12 0 0 0-.08-.556l4.899-4.898h1.73V0h-2.636Zm2.634 3.273a2.182 2.182 0 1 0-.002 4.364 2.182 2.182 0 0 0 .003-4.364h-.001Zm-12 4.363h3.271v6.546h-3.27V7.636ZM15 8.726a2.198 2.198 0 0 0-2.182 2.18v7.637h1.088v5.453h2.183v-5.453h2.182v5.453h2.182v-5.453h1.09v-7.636a2.198 2.198 0 0 0-2.182-2.183l-4.36.003Zm0 2.183h4.364v5.453H15V10.91Z"
          className={classes.cls1}
        />
        <path
          d="M7.363 0a2.182 2.182 0 1 0 0 4.364 2.182 2.182 0 0 0 0-4.364Zm9.82 3.273a2.182 2.182 0 1 0-.002 4.364 2.182 2.182 0 0 0 .002-4.364Z"
          className={classes.cls2}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

HeightDuoToneIcon.defaultProps = {
  htmlColorPrimary: "#252b43",
  htmlColorSecondary: "#f27d35",
};

export default HeightDuoToneIcon;
