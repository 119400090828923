import { CreateIcon, DeleteIcon, EmptyIcon } from "../../../../../assets";
import Box from "@mui/material/Box";
import { CustomButton, NoDataHandler } from "../../../../../components";
import { CustomCard } from "../../../../../components/composedComponents/subscriptions/customCard";
import { IconButton } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { isObjectEmpty, snackProps } from "../../../../../utils";

import { StyledChip } from "./styledChip";
import ViewAllContact from "../../../../registration/components/viewAllContact";
import AddContactDrawer from "../../../../../components/cards/addContactCard";
import { withDialog, withDrawer, withSnackBar } from "../../../../../HOC's";
import { Prompt } from "../../../../registration/components";
import { SectionHeader } from "..";

const dynamicPx = { xs: 1.5, md: 1.5 };

function MultipleContactMobile(props) {
  const {
    contacts = [],
    options = [],
    isPrimaryLogicNeeded,
    primaryContactId,
    handleSave,
    handleDelete,
    helperText,
  } = props;

  // Handlers
  const handleCloseDrawer = () => {
    props.drawer.setDrawer({
      open: false,
      component: <></>,
    });
  };

  const primaryChangePrompt = (closeAndSave) => {
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <Prompt
          title={"Change Primary Contact"}
          body={
            <>
              Are you sure, do you want to update <br /> this contact as
              primary?
            </>
          }
          handleClose={handleClose}
          handleAccept={handleAccept}
        />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: () => closeAndSave(),
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handleSaveContact = (data) => {
    const closeAndSave = () => {
      handleCloseDrawer();
      handleSave(data);
    };

    // Will execute this function where primary logic is not needed
    if (!isPrimaryLogicNeeded) {
      return closeAndSave();
    }

    if (
      (contacts?.length === 0 && !data?.isPrimary) ||
      (primaryContactId === data?.id && !data?.isPrimary)
    ) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message: "Atleast one contact should be primary.",
      });
    } else if (
      contacts?.length > 0 &&
      primaryContactId &&
      primaryContactId !== data?.id &&
      data?.isPrimary
    ) {
      primaryChangePrompt(closeAndSave);
    } else {
      closeAndSave();
    }
  };

  const handleAddEditClick = (editData = null) => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <AddContactDrawer
          isEdit={!!editData}
          editData={editData ?? {}}
          options={options}
          onSave={handleSaveContact}
          handleClose={handleClose}
          isPrimaryLogicNeeded={isPrimaryLogicNeeded}
        />
      ),
    });
  };

  const handldeDeleteClick = (deletedContact) => {
    if (primaryContactId === deletedContact?.id) {
      return props.snack.setSnack({
        open: true,
        severity: snackProps.severity.error,
        message:
          "Please set any other contact as primary to delete this contact.",
      });
    }
    props.dialog.setDialog({
      open: true,
      renderContent: (handleClose, handleAccept) => (
        <Prompt
          title={"Delete Contact"}
          body={
            <>
              Are you sure, do you want to delete <br /> this contact?
            </>
          }
          handleClose={handleClose}
          handleAccept={handleAccept}
        />
      ),
      positiveButtonProps: {
        hide: true,
      },
      negativeButtonProps: {
        hide: true,
      },
      onAccept: () => handleDelete(deletedContact),
      sx: {
        Dialog: {
          "&.MuiDialog-paper": {
            borderRadius: "12px",
          },
        },
        DialogContent: {
          "&.MuiDialogContent-root": {
            padding: 0,
          },
        },
      },
    });
  };

  const handleViewAllClick = () => {
    props.drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllContact
          value={contacts}
          handleClose={handleClose}
          handleEditClick={handleAddEditClick}
          handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  return (
    <>
      <Stack>
        <SectionHeader
          title="Contact Details"
          buttonLabel={contacts?.length > 0 ? "View All" : "+Add"}
          buttonHandler={() =>
            contacts?.length > 0 ? handleViewAllClick() : handleAddEditClick()
          }
        />
        {contacts?.length > 0 ? (
          <Stack
            sx={{ bgcolor: "common.white", p: 2.5 }}
            gap={2.5}
            style={{
              boxShadow: "0px 7px 7px 0px rgba(233,235,239,1)",
            }}
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack>
              <Stack
                flexDirection="row"
                gap={1}
                alignItems="center"
                sx={{ mb: 0.25 }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontWeight="400"
                >
                  {contacts?.[0]?.label}
                </Typography>
                {/* {contacts?.[0]?.isPrimary && (
                <PrimaryChip label="Primary" color="error" />
              )} */}
              </Stack>
              <Typography fontWeight="400">{`${contacts?.[0]?.mobilenumberISDcode} ${contacts?.[0]?.mobilenumber}`}</Typography>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <IconButton
                size="small"
                onClick={() => {
                  handleAddEditClick(contacts?.[0]);
                }}
              >
                <CreateIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  handldeDeleteClick(contacts?.[0]);
                }}
              >
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Stack>
          </Stack>
        ) : (
          <Box sx={{ pb: 1, bgcolor: "common.white", boxShadow: "rgb(233 235 239) 0px 7px 7px 0px" }}>
            <NoDataHandler
              message={"You don't have any contact details"}
              empty
              emptyIcon={<EmptyIcon />}
              fontSize={"4.5rem"}
              fullheight={"100px"}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

MultipleContactMobile.defaultProps = {
  isPrimaryLogicNeeded: true,
};

export default withDrawer(withDialog(withSnackBar(MultipleContactMobile)));
