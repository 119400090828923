import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme, checked }) => ({
    marginLeft: 0,
    "& .MuiFormControlLabel-label": {
        flex: 1,
        color: theme.palette.text.secondary,
        ...(checked && {
            color: theme.palette.text.primary,
        })
    }
}))


function CustomFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return (
        <StyledFormControlLabel checked={checked} disabled={props.disabled} value={props.value} control={<Radio sx={{
            p: "6px",
            color: (theme) => theme.palette.grey.border,
            '&.Mui-checked': {
                color: (theme) => theme.palette.secondary.main,
            },
        }}
        id={props?.id}
        />} label={props.label} labelPlacement="start" />
    )
}

const options = [
    {
        label: "Single Leg Squats (2)",
        value: "1"
    },
    {
        label: "Bridge with Ball (3)",
        value: "2"
    },
    {
        label: "Single Leg Bridge (5)",
        value: "3"
    },
    {
        label: "Double Knee to Chest (1)",
        value: "4"
    },
    {
        label: "Knee full extension Exercise (4)",
        value: "5"
    }
]

function CustomRadioGroup(props) {
    return (
        <RadioGroup
            aria-label={props.ariaLabel}
            name={props.name}
            value={props.value}
            onChange={props.handleChange}
            
        >
            {props.options?.map((option, index) => (
                <CustomFormControlLabel id={`${props?.parentID}${option?.id}`} disabled={props.disabled} key={option?.id ?? index} value={option?.value} label={`${index + 1}. ${option?.label}`} />
            ))}
        </RadioGroup>
    )
}

export default CustomRadioGroup
