import React from "react";

export const SosIcon = (props) => {
    const width = props?.width ? props?.width : 24;
    const height = props?.height ? props?.height : 24;
    const color = props?.color ? props?.color : '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
    <g id="blood_group_icon" data-name="blood group icon" transform="translate(-26 -2172)">
    <path id="icons8-sos" d="M11.5,4A7.509,7.509,0,0,0,4,11.5a7.431,7.431,0,0,0,.848,3.4l-.813,2.911a.957.957,0,0,0,1.156,1.156L8.1,18.151A7.429,7.429,0,0,0,11.5,19a7.5,7.5,0,1,0,0-15Zm0,1.125A6.375,6.375,0,1,1,8.4,17.069a.563.563,0,0,0-.425-.051l-2.77.773.773-2.769a.563.563,0,0,0-.051-.426A6.371,6.371,0,0,1,11.5,5.123ZM7.352,9.249a1.36,1.36,0,1,0,0,2.719h.516a.423.423,0,1,1,0,.844H7.2a.709.709,0,0,1-.472-.172.469.469,0,0,0-.776.526,1.565,1.565,0,0,0,1.247.584h.666a1.36,1.36,0,1,0,0-2.719H7.352a.422.422,0,1,1,0-.844h.493a.445.445,0,0,1,.352.155.469.469,0,1,0,.795-.5,1.364,1.364,0,0,0-1.148-.6Zm4.148,0A2.126,2.126,0,0,0,9.531,11.5a2.126,2.126,0,0,0,1.969,2.25,2.126,2.126,0,0,0,1.969-2.25A2.126,2.126,0,0,0,11.5,9.249Zm3.727,0a1.36,1.36,0,1,0,0,2.719h.516a.423.423,0,1,1,0,.844h-.665a.709.709,0,0,1-.472-.172.469.469,0,0,0-.776.526,1.565,1.565,0,0,0,1.247.584h.666a1.36,1.36,0,1,0,0-2.719h-.516a.422.422,0,1,1,0-.844h.493a.445.445,0,0,1,.352.155.469.469,0,1,0,.795-.5,1.364,1.364,0,0,0-1.148-.6Zm-3.727.938A1.2,1.2,0,0,1,12.531,11.5,1.2,1.2,0,0,1,11.5,12.811,1.2,1.2,0,0,1,10.469,11.5,1.2,1.2,0,0,1,11.5,10.186Z" transform="translate(26 2172.002)"
        fill={color} />
  </g>
    </svg>
  );
};
