import { SvgIcon } from "@mui/material";
import * as React from "react";

function ListIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.318 6A1.319 1.319 0 005 7.318v2.635a1.319 1.319 0 001.318 1.318h2.635a1.32 1.32 0 001.318-1.318V7.318A1.32 1.32 0 008.953 6H6.318zm.659 1.976h1.317v1.318H6.976l.001-1.318zm4.612 0v1.318h3.294V7.976h-3.295.001zm4.612 0v1.318h1.976V7.976H16.2h.001zm-9.883 4.612A1.32 1.32 0 005 13.906v2.635a1.319 1.319 0 001.318 1.318h2.635a1.32 1.32 0 001.318-1.318v-2.635a1.32 1.32 0 00-1.318-1.318H6.318zm1.318 1.976a.658.658 0 11-.001.001l.001-.001zm3.953 0v1.318h3.294v-1.317h-3.295l.001-.001zm4.612 0v1.318h1.976v-1.317H16.2l.001-.001z"
      />
    </SvgIcon>
  );
}

export default ListIcon;
