import { SvgIcon } from "@mui/material";
import * as React from "react";

function CartIcon(props) {
  return (
    <SvgIcon
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.414 2L2 2.014l.01 2L4.084 4l3.295 7.9-1.2 1.916A2.076 2.076 0 007.941 17H20v-2H7.941l-.064-.117L9.053 13h7.469a2 2 0 001.748-1.029l3.6-6.484A1 1 0 0021 4H6.25l-.836-2zm1.668 4H19.3l-2.777 5H9.166L7.082 6zM8 18a2 2 0 100 4 2 2 0 000-4zm10 0a2 2 0 100 4 2 2 0 000-4z"
      />
    </SvgIcon>
  );
}

export default CartIcon;
