import "./App.css";

import AppAuth from "./App.auth";
import AppDialog from "./App.dialog";
import AppDrawer from "./App.drawer";
import AppSnackbar from "./App.snackbar";
import AppSOS from "./App.sos";
import AppTheme from "./AppTheme";
import { Provider } from "react-redux";
import React from "react";
import { store as ReduxStore } from "./redux/store";
import Routers from "./router";
// import { InstallPWA } from "./InstallPWA";
import Loading from "./components/loaders/loading";
import { Snackbar, Button, CssBaseline } from "@mui/material";
import * as serviceWorker from "./serviceWorkerRegistration";
import { makeStyles } from "@mui/styles";
import { Divider } from "@material-ui/core";

// const CssBaseline = React.lazy(() => import("@mui/material/CssBaseline"));

const useStyles = makeStyles((theme) => ({
  newVersion: {
    "& div": {
      backgroundColor: "#0a6df1",
    },
  },
  newVersionUpdated: {
    "& div": {
      backgroundColor: "#31b198",
    },
  },
}));

function App() {
  let [isNewVersionAvailable, setIsNewVersionAvailable] = React.useState(false);
  let [isRefreshed, setIsRefreshed] = React.useState(false);
  let [registration, setRegistration] = React.useState({});
  const classes = useStyles();

  React.useEffect(() => {
    serviceWorker.register({ promptUserToRefresh: promptUserToRefresh });
  }, []);

  const promptUserToRefresh = (reg) => {
    // Prompting after version changed
    setIsNewVersionAvailable(true);
    setRegistration(reg);
  };
  const promptAcceptToRefresh = () => {
    setIsNewVersionAvailable(false);
    setIsRefreshed(true);
    registration &&
      registration.waiting &&
      registration.waiting.postMessage &&
      registration.waiting.postMessage("skipWaiting");
  };
  return (
    <>
      <Snackbar
        open={isNewVersionAvailable}
        className={classes.newVersion}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottomcenter"}
        autoHideDuration={6000}
        message={`New version available!`}
        action={
          <>
            <Divider
              style={{ height: "25px", background: "white", width: 1 }}
              orientation="vertical"
            />
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                promptAcceptToRefresh();
              }}
            >
              Refresh
            </Button>
          </>
        }
        sx={{ bottom: { xs: 90, sm: 0 } }}
      />

      <Snackbar
        open={isRefreshed}
        className={classes.newVersionUpdated}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottomcenter"}
        message={`Latest version upgraded!`}
        action={
          <>
            <Divider
              style={{ height: 25, background: "white", width: 1 }}
              orientation="vertical"
            />
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                setIsRefreshed(false);
              }}
            >
              Close
            </Button>
          </>
        }
        sx={{ bottom: { xs: 90, sm: 0 } }}
      />
      <React.Suspense fallback={<Loading />}>
        <Provider store={ReduxStore}>
          <AppTheme>
            <CssBaseline />
            <AppSnackbar>
              <AppAuth>
                <AppDialog>
                  <AppDrawer>
                    {/* <AppSOS> */}
                      <Routers />
                    {/* </AppSOS> */}
                  </AppDrawer>
                </AppDialog>
              </AppAuth>
            </AppSnackbar>
          </AppTheme>
        </Provider>
        {/* <InstallPWA /> */}
      </React.Suspense>
    </>
  );
}

export default App;
