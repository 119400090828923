import PropTypes from "prop-types";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { DrawerSkeleton } from "../../../components";
import { CreateIcon, DeleteIcon } from "../../../assets";

function ViewAllIdType(props) {
  const {
    value,
    handleClose,
    handleEditClick,
    handldeDeleteClick,
    hideActionButtons,
    drawerSkeletonProps
  } = props;

  return (
    <DrawerSkeleton
      // title={"ID Details"}
      showButton={false}
      handleClose={handleClose}
      gutters={false}
      parentID="FromViewAllIdType"
      renderTitle={() => (
        <Typography
            sx={{
              flex: 1,
              textAlign: "center",
              ml: drawerSkeletonProps?.secondaryButtonText ? "0px" : "-44px",
              fontSize: "20px",
              fontWeight: 500
            }}
          >
            ID Details
          </Typography>
      )}
      {...drawerSkeletonProps}
    >
      {value?.map((val) => (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ py: 1, px: 4 }}
          >
            <Stack>
              <Typography sx={{fontSize: "14px", mb: 1}} color="textSecondary">
                {val?.id_type_name?.label ? val?.id_type_name?.label : "-"}
              </Typography>
              <Typography sx={{fontSize: "16px"}}>{val?.id_type ? val?.id_type : "-"}</Typography>
            </Stack>
            {!hideActionButtons && (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                // sx={{ mb: 2 }}
              >
                <IconButton onClick={() => handleEditClick(val)}>
                  <CreateIcon fontSize="small" sx={{fontSize: "16px"}} id={`edit${val?.id}`} />
                </IconButton>
                <IconButton onClick={() => handldeDeleteClick(val)}>
                  <DeleteIcon fontSize="small" sx={{fontSize: "16px"}} id={`delete${val?.id}`} />
                </IconButton>
              </Stack>
            )}
          </Stack>

          <Divider />
        </>
      ))}
    </DrawerSkeleton>
  );
}

ViewAllIdType.propTypes = {
  value: PropTypes.array,
};

export default ViewAllIdType;
