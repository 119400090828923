import PropTypes from "prop-types";
import { CustomAvatar, CustomRadioFormControlLabel } from "../..";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { loadingArray } from "../../../utils";

const CustomPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: 10,
  border: "1px solid",
  borderColor: "#e2e2e2",
  boxShadow: "0 23px 16px 0 rgba(54, 58, 87, 0.05)",
  backdropFilter: "blur(30px)",
}));

export function RadioSelectCard(props) {
  // const [rated, setRated] = useState([])

  // const handleRatingsChange = (event) => {
  //     event.stopPropagation()
  //     setRated(!rated);
  // };

  const { dontShowPic = true } = props

  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }
  return (
    <CustomPaper
      onClick={() => props.onClick(props.value)}
      elevation={0}
      id={`serviceProfessional${props?.value}`}
      sx={{ px: 2, py: 1.5, mb: 1.5, cursor: "pointer" }}
    >
      <Grid container rowSpacing={1}>
        <Grid container item xs={12} columnSpacing={1}>
          {dontShowPic ? (
            <Grid item>
              <CustomAvatar name={props.label} src={props.profile_pic} />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs>
            <RadioGroup onClick = {() => props.onClick(props.value)}>
            <CustomRadioFormControlLabel
              value={props.value}
              
              disabled={props?.disabled ? true : false}
              label={
                <>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      ...(checked && {
                        color: "text.primary",
                      }),
                    }}
                  >
                    {props.label}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {props.category}
                  </Typography>
                </>
              }
              checked={checked}
            />
            </RadioGroup>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
                    <Stack sx={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", mr: 1 }}>
                        <CustomToggleButton selected={rated} handleChange={handleRatingsChange} sx={{
                            py: 0.25, px: 1, '&$selected': { // <-- mixing the two classes
                                bgcolor: "success.main",
                                color: "common.white"
                            }


                        }}>
                            <Stack sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 0.5 }}>
                                <Typography sx={{ flex: 1 }}>4.5</Typography>
                                <StarBorderRoundedIcon fontSize="small" sx={{ color: "text.primary" }} />
                            </Stack>
                        </CustomToggleButton>
                    </Stack>
                </Grid> */}
      </Grid>
    </CustomPaper>
  );
}

function ChooseServiceProfessional(props) {
  const { loading, value, onChange, name, options } = props;
  return (
    <Stack sx={{ px: 2, pt: 1.75, pb: 2 }}>
      {loading ? (
        loadingArray(2).map((loader) => <Skeleton width="100%" />)
      ) : Array?.isArray(options) && options?.length > 0 ? (
        <>
          <Typography color="textSecondary" sx={{ pt: 2, pb: 1.5 }}>
            Select Service Professional
          </Typography>
          <RadioGroup
            // aria-label="gender"
            name={name}
            value={value}
            disabled={props?.isReschedule ? true : false}
            onChange={onChange}
          >
            {options.map((option, idx) => (
              <RadioSelectCard
                onClick={onChange}
                value={option?.value}
                disabled={props?.isReschedule ? true : false}
                label={option?.label}
                category={option?.category}
                profile_pic={option?.profile_pic}
              />
            ))}
          </RadioGroup>
        </>
      ) : (
        <Typography vairant="body2" color="textSecondary">
          Look's like we don't have any service professional for this service
          right now.
        </Typography>
      )}
    </Stack>
  );
}

ChooseServiceProfessional.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.string,
};

ChooseServiceProfessional.defaultProps = {
  options: [],
  onChange: () => {},
};

export default ChooseServiceProfessional;
