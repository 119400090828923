import React from "react";
import { connect } from 'react-redux'
import {
  Divider,
  Grid,
  Typography,
  IconButton
} from "@mui/material";
import { withStyles } from "@mui/styles";
import ActivityTimeline from "./activitySequence"
import { actions } from "tanyacare-middleware"; 
import { ChevronLeft } from "@mui/icons-material";

// const { GET_ACTIVITY_LOGS } = actions;

const styles = (theme) => ({});

class ActivityLog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         data: []
        };
      }
    async componentDidMount(){
        // let req_data = await this.props.GET_ACTIVITY_LOGS({appointmentId: "b9a97034-b289-4dbf-9103-b8c73b48fbea"})
        let req_data = await this.props.GET_ACTIVITY_LOGS({appointmentId: this.props?.id})
        let data = req_data?.payload?.data
        this.setState({data: data})
    }
  render() {
    // const { data } = this.props;
    // const orderData = data?.order_sequence?.length > 0 ? data?.order_sequence?.filter(val => val.strict) : [];
    // const bookingData = data?.order_sequence?.length > 0 ? data?.order_sequence?.filter(val => !val.strict) : [];


    return (
      <div>
        {/* {!this.state?.data && <NotFoundSection message="No data Found" />} */}

        {this.state?.data && (
          <>
            <Grid style={{ padding: "8px 6px ", display: "flex", alignItems: "center" }}>
              <Grid item>
                    <IconButton
                      onClick={this.props.handleClose}
                      id={`activityLog${this?.props?.id}`}
                    >
                      <ChevronLeft />
                    </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h6"}>Activity Log</Typography>
              </Grid>
            </Grid>

            <Divider light />

            {this.state?.data?.length > 0 && 
            <Grid container style={{ padding: "8px 0px 25px 25px" }}>
              <Grid item xs={12}>
                <Typography variant={"subtitle1"} ></Typography>
              </Grid>

              <Grid item xs={12}>
                  <ActivityTimeline sequenceData={this.state?.data}/>
                {/* <ProductTimeline sequenceData={this.state?.data ?? []} /> */}
              </Grid>
            </Grid>
            }
            {/* {bookingData?.length > 0 && 
            <Grid container style={{ padding: "8px 0px 25px 25px" }}>
              <Grid item xs={12}>
                <Typography variant={"subtitle1"} >Booking Sequence</Typography>
              </Grid>

              <Grid item xs={12}>
                <ProductTimeline lineHidden  sequenceData={bookingData ?? []} />
              </Grid>
            </Grid>
            } */}

            
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    // emailType: state?.masterSet?.emailType,
    // updateProfile: state?.resourceReducer?.updateProfileData,
    logs: state?.activity_app?.logs
  });

export default connect(
    mapStateToProps,
    actions
  )(withStyles(styles)(ActivityLog));
