import * as React from "react";

import { CustomButton } from "..";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

const defaultPositiveProps = {
  variant: "text",
  text: "Confirm",
  hide: false,
};

const defaultNegativeProps = {
  variant: "text",
  text: "Cancel",
  hide: false,
};

function CustomDialog(props) {
  const {
    open,
    handleClose,
    handleAccept,
    title,
    content,
    renderContent,
    positiveButtonProps = {},
    negativeButtonProps = {},
    sx = {},
  } = props;

  const positiveProps = {
    ...defaultPositiveProps,
    ...positiveButtonProps,
  };
  const negativeProps = {
    ...defaultNegativeProps,
    ...negativeButtonProps,
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: 300,
        },
        ...sx?.Dialog,
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      {(!!content || !!renderContent) && (
        <DialogContent sx={{ ...sx?.DialogContent }}>
          {content && (
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          )}
          {typeof renderContent === "function"
            ? renderContent(handleClose, handleAccept)
            : renderContent}
        </DialogContent>
      )}
      {(!negativeProps.hide || !positiveProps.hide) && (
        <DialogActions>
          {negativeProps.hide ? (
            ""
          ) : (
            <CustomButton
              onClick={handleClose}
              size="small"
              variant={negativeProps.variant}
            >
              {negativeProps.text}
            </CustomButton>
          )}
          {positiveProps.hide ? (
            ""
          ) : (
            <CustomButton
              onClick={handleAccept}
              size="small"
              variant={positiveProps.variant}
            >
              {positiveProps.text}
            </CustomButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

CustomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  renderContent: PropTypes.node,
  positiveButtonProps: PropTypes.shape({
    variant: PropTypes.string,
    text: PropTypes.string,
    hide: PropTypes.bool,
  }),
  negativeButtonProps: PropTypes.shape({
    variant: PropTypes.string,
    text: PropTypes.string,
    hide: PropTypes.bool,
  }),
};

export default CustomDialog;
