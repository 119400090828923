import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const boxShadow = `0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 
0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)`;

const Layer = styled(Box,{
    shouldForwardProp:prop=> !['width','height','borderRadius','backgroundColor','boxShadow','position','cursor'].includes(prop)})
    (({ width,height,borderRadius,backgroundColor,position,cursor,boxShadow }) => ({
    width,
    height,
    borderRadius,
    backgroundColor,
    position,
    cursor,
    boxShadow
  }))
  
  const InnerLayerWrapper = styled(Box,{
    shouldForwardProp:prop=> prop !== 'left' && prop !== 'top'
  })(({ left,top }) => ({
    position:'absolute',
    transition:'all 0.3s cubic-bezier(0, 0.46, 0.25, 1)',
    top,
    left,
  }))

export const CustomSwitch = (props) => {

    const {
        outerLayerWidth = 45,
        outerLayerHeight = 24,
        outerLayerActiveBgColor = "#31B198",
        outerLayerInActiveBgColor = "#C7C7C7",
        innerLayerSize = 23,
        innerLayerBgColor = "#fff",
        checked,
        handleChange = null
    } = props;


    return (
        <Layer
            id={props?.id}
            width={outerLayerWidth}
            height={outerLayerHeight}
            borderRadius={50}
            backgroundColor={checked ? outerLayerActiveBgColor : outerLayerInActiveBgColor}
            position={'relative'}
            cursor="pointer"
            onClick={() => handleChange && handleChange(!checked)}
        >
            <InnerLayerWrapper
                top={(outerLayerHeight / 2 - innerLayerSize / 2)}
                left={checked ? (outerLayerWidth - innerLayerSize) : 0}
            >
                <Layer
                    width={innerLayerSize}
                    height={innerLayerSize}
                    borderRadius={'50%'}
                    backgroundColor={innerLayerBgColor}
                    boxShadow={boxShadow}
                />
            </InnerLayerWrapper>
        </Layer>
    )
}