import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import purify from "dompurify";
import { useTheme, makeStyles } from "@mui/styles";
import { CustomCard } from "./customCard";
import { CustomButton } from "../..";

const descriptionDisplayStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    boxShadow: "none",
    border: `1px solid #d8dce5`,
  },
  fullHeight: {
    height: "100%",
  },
  displayWrapper: {
    margin: theme.spacing(0, 2.5, 2, 1.25),
    //paddingTop: theme.spacing(2),

    overflowY: "hidden",
  },
  description: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflowY: "hidden",
    // fontFamily: "GillSans",
  },
  actionButtonsWrapper: {
    display: "flex",
    gap: theme.spacing(1.25),
  },
  actionButton: {
    border: "solid 1px rgba(112, 112, 112, 0.1)",
  },
  actionButtonIcon: {
    fontSize: "1.10rem",
  },
  dialogPaper: {
    width: "100%",
  },
  newClass: {
    maxHeight: theme.spacing(6),
  },
}));

const DescriptionDisplay = ({
  title,
  content,
  terms_and_conditions,
  setInnerHtml = false,
}) => {
  const classes = descriptionDisplayStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  // const [descriptionHeight, setDescriptionHeight] = React.useState(0);
  const [showViewMore, setShowViewMore] = React.useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    const element = document.getElementById(
      `${title?.replace(/\s/g, "")}_wrapper`
    );
    if (setInnerHtml) {
      setShowViewMore(true);
      console.log(showViewMore);
    } else {
      setShowViewMore(
        element.children[0].scrollHeight > element.children[0].offsetHeight
          ? true
          : false
      );
    }
  }, [open, setInnerHtml, showViewMore, title]);

  return (
    <CustomCard title={"Description"} sx={{ height: "100%" }}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <div
            id={`${title?.replace(/\s/g, "")}_wrapper`}
            className={classes.newClass}
            style={{ paddingInline: theme.spacing(1.5), }}
          >
            <Typography
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(
                  `<div className={${classes.styleText}} style="width: 100%;overflow-wrap: anywhere;">${content}</div>`
                ),
              }}
              className={classes.description}
            ></Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            classes={{ paper: classes.dialogPaper }}
            maxWidth="xs"
          >
            <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {/* {setInnerHtml ? ( */}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: purify.sanitize(
                      `<div style="width: 100%;overflow-wrap: anywhere;">${content}</div>`
                    ),
                  }}
                ></Typography>
                {Array.isArray(terms_and_conditions) ? (
                  <>
                    <Typography>{"Terms & Conditions"}</Typography>
                    <Typography>
                      <ul style={{ margin: 0 }}>
                        {terms_and_conditions?.map((row) => (
                          <li>{row}</li>
                        ))}
                      </ul>
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" id="closeDialogDescription">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid item xs={12}>
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            sx={{ pr: 2, pb: 1 }}
          >
            <Box>
              <CustomButton
                variant="text"
                color="secondary"
                onClick={handleClickOpen("paper")}
                size="small"
                sx={{ textTransform: "unset" }}
                id="viewMoreDescription"
              >
                View More
              </CustomButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default DescriptionDisplay;
