import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

function RightArrowStripes(props) {
  return (
    <SvgIcon {...props} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="1em"
        height="1em"
        viewBox="0 0 23 15"
        {...props}
      >
        <defs>
          <clipPath id="prefix__b">
            <path d="M0 0h23v15H0z" />
          </clipPath>
          <clipPath id="prefix__c">
            <path d="M0 0h23v15H0z" />
          </clipPath>
          <mask id="prefix__e">
            <g filter="url(#prefix__a)">
              <path fillOpacity={0.388} d="M0 0h23v15H0z" />
            </g>
          </mask>
          <mask id="prefix__g">
            <g filter="url(#prefix__a)">
              <path fillOpacity={0.173} d="M0 0h23v15H0z" />
            </g>
          </mask>
          <g id="prefix__d" clipPath="url(#prefix__b)">
            <path
              d="M3510.75 301.18l4.636 4.64-4.637 4.64"
              transform="matrix(1.21053 0 0 1.25 -4240.878 -374.709)"
              fill="none"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#fff"
            />
          </g>
          <g id="prefix__f" clipPath="url(#prefix__c)">
            <path
              d="M3510.747 301.18l4.64 4.64-4.64 4.64"
              transform="matrix(1.21053 0 0 1.25 -4248.141 -374.709)"
              fill="none"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#fff"
            />
          </g>
          <filter
            id="prefix__a"
            filterUnits="objectBoundingBox"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
          >
            <feColorMatrix
              in="SourceGraphic"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            />
          </filter>
        </defs>
        <path
          d="M3510.748 301.18l4.64 4.64-4.64 4.64"
          transform="matrix(1.21053 0 0 1.25 -4233.615 -374.709)"
          fill="none"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#fff"
        />
        <use xlinkHref="#prefix__d" mask="url(#prefix__e)" />
        <use xlinkHref="#prefix__f" mask="url(#prefix__g)" />
      </svg>
    </SvgIcon>
  );
}

RightArrowStripes.propTypes = {
  viewBox: PropTypes.string,
};

RightArrowStripes.defaultProps = {
  viewBox: "0 0 24 16",
};

export default RightArrowStripes;
