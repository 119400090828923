import React from "react";
import { makeStyles } from "@mui/styles";
import { InputLabel, Typography } from "@mui/material";
import { alpha } from "@mui/material";

const styles = makeStyles((theme) => ({
  container: {
    margin: "8px 0px",
  },
  root: {
    display: "block",
    padding: "0px !important",
    margin: "8px 0px 0px 0px",
    "& ul": {
      listStyle: "none",
    },
    "& li": {
      display: "inline-flex",
      marginRight: 16,
      marginBottom: 16,
    },
    "& label": {
      cursor: "pointer",
      borderRadius: 8,
      border: `0px solid ${alpha(theme.palette.text.secondary, 0.25)}`,
      background: alpha(theme.palette.text.secondary, 0.25),
      color: alpha(theme.palette.text.primary, 0.75),
      fontSize: 14,
      padding: "4px 15px",
      transition: "all 0.2s ease-out",
    },
    "& input:checked + label": {
      transition: "all 0.3s ease-in",
      background: theme.palette.enquiry.status.open,
      border: `0px solid ${theme.palette.secondary.main}`,
      color: "white",
    },
  },
  fixMargin: {
    marginBottom: "0px !important",
  },
  label: {
    color: theme.palette.text.label,
    textTransform: "uppercase",
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: "0.8rem",
    marginBottom: (props) =>
      props?.removeMarginBetweenErrorAndComponent ? 14 : 0,
  },
  disabled: {
    background: "#f2f2f2",
    cursor: "not-allowed !important",
    color: "#999",
  },
}));

export const CustomizedRadioButton = (props) => {
  const {
    data,
    handleOnChange,
    value,
    error,
    errorText,
    removeMarginBetweenErrorAndComponent,
    disabled,
    id,
  } = props;

  const classes = styles(props);
  // console.clear()
  // console.log(disabled)

  const handleChange = (itemId) => {
    handleOnChange(itemId);
  };
  return (
    <div className={classes.container}>
      <InputLabel shrink htmlFor={data.label} className={classes.label}>
        {data.label}
      </InputLabel>
      <ul
        className={`${classes.root} ${
          error && removeMarginBetweenErrorAndComponent ? classes.fixMargin : ""
        } `}
        // id={data.label}
        id={id}
      >
        {data.options?.map((opt, index) => {
          return (
            <li
              className={`${
                index === data.options.length - 1 ? classes.fixMargin : ""
              } `}
              onClick={()=>{
                  if(!disabled){
                    handleChange(opt?.value);
                  }
                }
              }
              id={`li${opt?.label}${index}`}
            >
              <input
                type="radio"
                checked={opt.value === value ? true : false}
                value={opt.value}
                name={opt.label}
                id={`radio${opt?.label?.replaceAll(' ','_')}${index}`}
                // id={props.id}
                hidden
                disabled={disabled ?? false}
              />
              <label
                className={`${disabled && classes.disabled}`}
                id={`radio${opt?.label?.replaceAll(' ','_')}${index}`}
              >
                {opt.label}
              </label>
            </li>
          );
        })}
      </ul>
      {error && (
        <Typography
          variant="body1"
          component="div"
          className={classes.errorText}
        >
          {errorText}
        </Typography>
      )}
    </div>
  );
};
