import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { Typography, styled } from "@mui/material";

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
  "& .MuiMobileStepper-progress": {
    backgroundColor: "#eaeaea",
    width: "100%",
    marginRight: 8,
    height: 6,
    borderRadius: 6,
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

function CustomMobileStepper(props) {
  const { activeStep, stepsLength } = props;

  return (
    <StyledMobileStepper
      variant="progress"
      steps={stepsLength + 1}
      position="static"
      activeStep={activeStep}
      sx={{ flexGrow: 1 }}
      nextButton={<Typography>{`${activeStep}/${stepsLength}`}</Typography>}
    />
  );
}

export default CustomMobileStepper;
