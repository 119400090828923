import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Skeleton from "@mui/material/Skeleton";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { CreateIcon, DeleteIcon, EmptyIcon } from "../../../../assets";
import { styled, alpha } from "@mui/material";
import { StyledPaper } from "./styledPaper";
import {
  getAgeString,
  getFlexItemWidth,
  getFormattedAddress,
  getGenderString,
  isObjectEmpty,
} from "../../../../utils";
import { withDialog, withDrawer } from "../../../../HOC's";
import { DialogContext, DrawerContext } from "../../../../contexts";
import { CustomAvatar, NoDataHandler } from "../../../../components";
import ViewAllIdType from "../../../registration/components/viewAllIdType";
import ViewAllContact from "../../../registration/components/viewAllContact";
import ViewAllAddress from "../../../registration/components/viewAllAddress";
import { StyledChip } from "../../../registration/components/styledChip";
import { Prompt } from "../../../registration/components";

const RelationChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  height: 24,
  borderRadius: 4,
  ...(color === "success" && {
    color: theme.palette.success.custom,
    backgroundColor: alpha(theme.palette.success.custom, 0.08),
  }),
  ...(color === "error" && {
    color: theme.palette.error.custom,
    backgroundColor: alpha(theme.palette.error.custom, 0.08),
  }),
  //   border: "1px solid #E0E0E0",
  //   borderRadius: 8,
  //   padding: 12,
}));

const StyledDivider = styled(Divider)(() => ({
  borderColor: "#EAEAEA",
}));

function FamilyMemberAttribute({ label, value }) {
  return (
    <Stack
      sx={{
        width: getFlexItemWidth(2, 4),
        // width: "calc(1/2*100% - (1 - 1/2)*4px)",
        minWidth: "fit-content",
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        fontWeight="400"
        sx={{ color: "rgba(146, 151, 165, 1)", mb: 1.5 }}
      >
        {label ? label : "Untitled"}
      </Typography>
      <Typography fontWeight="400" sx={{ fontSize: "14px !important" }}>{value ? value : "Not updated"}</Typography>
    </Stack>
  );
}

function DocumentAttribute({
  label,
  type,
  value,
  data,
  viewAllCallback,
  showPrimary,
}) {
  return (
    <Stack
      sx={{
        width: getFlexItemWidth(2, 4),
        minWidth: "fit-content",
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        fontWeight="400"
        sx={{ color: "rgba(146, 151, 165, 1)", mb: 1.5 }}
      // gutterBottom
      >
        {label ? label : "Untitled"}
      </Typography>
      <Stack flexDirection="row" gap={1} alignItems="center" sx={{ mb: 1.5 }}>
        <Typography
          fontWeight="500"
          fontSize={"16px"}
          sx={{ overflowWrap: "break-word" }}
        >
          {type ? type : "Not updated"}
        </Typography>
        {showPrimary && <StyledChip label="Primary" color="error" />}
      </Stack>
      <Stack flexDirection="row" gap={1.5} alignItems="center">
        <Typography
          fontWeight="400"
          sx={{ maxWidth: "200px", color: "rgba(146, 151, 165, 1)", overflowWrap: "break-word", fontSize: "14px !important" }}
        >
          {value ? value : ""}
        </Typography>
        {data?.length > 1 && (
          <Stack flexDirection={"row"} gap={1} alignItems="center">
            <Button
              color="secondary"
              size="small"
              sx={{ textTransform: "unset", color: "#F3AB40", fontSize: "14px", fontWeight: 400, lineHeight: 1.5, p: 0 }}
              onClick={viewAllCallback}
              disableRipple
            >
              View All
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

function EmergencyContactBanner() {
  const borderRadius = "6px";
  return (
    <Box
      sx={{
        position: 'absolute',
        right: '0px',
        top: '0px'
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "white",
          bgcolor: "#F67280",
          borderRadius: borderRadius,
          py: 0.75,
          px: 1,
          position: 'absolute',
          right: '0px',
          whiteSpace: 'nowrap',
          lineHeight: 1,
          fontSize: "10px !important"
        }}
        style={{
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: 0,
        }}
      >
        Emergency Contact
      </Typography>
      <Box sx={{
        bgcolor: "#F67280", height: "8px", width: "8px", position: 'absolute',
        top: '22px',
        right: '0px'
      }}>
        <Box
          sx={{ height: "100%", bgcolor: "white" }}
          style={{ borderTopRightRadius: borderRadius }}
        ></Box>
      </Box>
    </Box>
  );
}

export function MemberCard({ data, isEmergency, ...props }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const drawer = React.useContext(DrawerContext);
  const dialog = React.useContext(DialogContext);

  // const viewAllIdType = () => {
  //   drawer.setDrawer({
  //     ...drawer,
  //     open: true,
  //     component: <>Hi</>,
  //   });
  // };

  // const viewAllContact = () => {
  //   drawer.setDrawer({
  //     ...drawer,
  //     open: true,
  //     component: <>Hi</>,
  //   });
  // };

  // const viewAllAddress = () => {
  //   drawer.setDrawer({
  //     ...drawer,
  //     open: true,
  //     component: <>Hi</>,
  //   });
  // };

  const viewAllIdType = () => {
    drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllIdType
          value={data?.idTypes ?? []}
          handleClose={handleClose}
          hideActionButtons
        // handleEditClick={handleAddEditClick}
        // handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  const viewAllContact = () => {
    drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllContact
          value={data?.otherPhoneNumbers ?? []}
          handleClose={handleClose}
          hideActionButtons
        // handleEditClick={handleAddEditClick}
        // handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  const viewAllAddress = () => {
    drawer.setDrawer({
      open: true,
      component: (handleClose) => (
        <ViewAllAddress
          value={data?.otherAddress ?? []}
          handleClose={handleClose}
          hideActionButtons
          takeAddressDataFromRootObject
        // handleEditClick={handleAddEditClick}
        // handldeDeleteClick={handldeDeleteClick}
        />
      ),
    });
  };

  const handleDeleteClick = (isEmergencyContact) => {
    if (isEmergencyContact) {
      props.dialog.setDialog({
        open: true,
        content: (<></>),
        renderContent: (handleClose, handleAccept) => (
          <Prompt
            title={"Delete Family Member"}
            body={
              <>
                Deleting this family member will disable <br /> Emergency contact option in Call Assist
              </>
            }
            handleClose={handleClose}
            handleAccept={handleAccept}
          />
        ),
        positiveButtonProps: {
          hide: true,
        },
        negativeButtonProps: {
          hide: true,
        },
        onAccept: () => props.handleDelete(),
        sx: {
          Dialog: {
            "&.MuiDialog-paper": {
              borderRadius: "12px",
            },
          },
          DialogContent: {
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          },
        },
      });
    } else {
      props.handleDelete()
    }


    // dialog.setDialog({
    //   open: true,
    //   title: "Confirmation",
    //   content: "Are you sure, remove this relation details ?",
    //   positiveButtonProps: {
    //     hide: false,
    //   },
    //   negativeButtonProps: {
    //     hide: false,
    //   },
    //   onAccept: () => props.handleDelete(),
    // });
  };

  const getPrimaryPhoneNumber = (phoneNumbers = []) => {
    const primaryPhoneNumberData = phoneNumbers?.filter(
      (x) => x?.isPrimary
    )?.[0];
    if (primaryPhoneNumberData) {
      return `${primaryPhoneNumberData?.mobilenumberISDcode} ${primaryPhoneNumberData?.mobilenumber}`;
    }
    return ``;
  };
  return (
    <Paper
      sx={{
        position: "relative",
        border: "1px solid #E0E0E0",
        boxShadow: "0px 10px 25px 0px rgba(49, 49, 49, 0.08)",
        borderRadius: 1,
      }}
    >
      {/* Emergency Contact Badge */}
      {isEmergency && <EmergencyContactBanner />}

      {/* Card */}
      <Stack sx={{ px: 2, py: 1.5 }}>
        <Stack flexDirection="row" gap={2}>
          {/* <Avatar
            src="/assets/temp/personAvatar.png"
            sx={{ width: "48px", height: "48px" }}
          /> */}
          <CustomAvatar
            name={data?.name}
            id={data?.id}
            sx={{ fontSize: "14px", width: "48px", height: "48px" }}
          />
          <Stack flex={1}>
            <Stack
              flexDirection="row"
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              sx={{ mb: 2 }}
            >
              <Stack
              //   sx={{ pb: 1 }}
              >
                <Typography fontWeight="500" sx={{ mb: 1.5 }}>
                  {`${data?.salutation?.label ? `${data?.salutation?.label}. ` : ""}${data?.name}`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontStyle: "italic",
                    // mt: 0.5,
                    fontWeight: "400",
                    color: "rgba(146, 151, 165, 1)",
                  }}
                >
                  {`${getGenderString(data?.gender)}${data?.dob ? ", " : ""}${getAgeString(
                    data?.dob
                  )}${data?.dob ? ` old` : ""}`}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="flex-end"
                justifyContent="flex-end"
                gap={3}
                sx={{ my: 1.5 }}
              >
                <IconButton id={`editFamilyMember${data?.id}`} size="small" onClick={() => props.handleEdit()} disableRipple sx={{ p: 0 }}>
                  <CreateIcon sx={{ fontSize: "16px" }} />
                </IconButton>
                <IconButton id={`deleteFamilyMember${data?.id}`} size="small" onClick={() => handleDeleteClick(data?.isEmergencyContact)} disableRipple sx={{ p: 0 }}>
                  <DeleteIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent={"space-between"}
              alignItems="center"
            >
              {/* Relation */}
              {data?.relationship?.label && (
                <RelationChip
                  label={data?.relationship?.label}
                  color="success"
                />
              )}

              {/* Show More */}
              <Stack
                flexDirection="row"
                alignItems="center"
                onClick={() => setIsExpanded((prev) => !prev)}
                sx={{ cursor: "pointer" }}
                gap={1.5}
              >
                <Typography
                  variant="body2"
                  fontWeight={"400"}
                  sx={{ color: "#9A95C4" }}
                >
                  {isExpanded ? `Show less` : `Show more`}
                </Typography>
                <IconButton
                  style={{
                    transition: "transform 0.3s ease",
                    ...(isExpanded && {
                      transform: "rotate(-180deg)",
                    }),
                    padding: 0
                  }}
                  id={`showMore${data?.id}`}
                  disableRipple
                >
                  <ExpandCircleDownIcon fontSize="small" htmlColor={"#9297A5"} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Collapse in={isExpanded}>
        <Stack>
          <StyledDivider flexItem />
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            rowGap={3}
            columnGap={0.5}
            sx={{ px: 2, pt: 2, pb: 3 }}
          >
            <FamilyMemberAttribute
              label="Primary Email Address"
              value={data?.email}
            />
            <FamilyMemberAttribute
              label="Primary Mobile Number"
              value={getPrimaryPhoneNumber(data?.otherPhoneNumbers)}
            />

            {Array.isArray(data?.idTypes) && data?.idTypes?.length > 0 ? (
              <DocumentAttribute
                label="ID Details"
                type={data?.idTypes?.[0]?.label}
                value={data?.idTypes?.[0]?.id_type}
                data={data?.idTypes}
                viewAllCallback={viewAllIdType}
              // value="WE242323232"
              />
            ) : (
              <DocumentAttribute label="ID Details" type="" value="" />
            )}

            {Array.isArray(data?.otherPhoneNumbers) &&
              data?.otherPhoneNumbers?.length > 0 ? (
              <DocumentAttribute
                label="Contact Details"
                type={data?.otherPhoneNumbers?.[0]?.label}
                value={`${data?.otherPhoneNumbers?.[0]?.mobilenumberISDcode} ${data?.otherPhoneNumbers?.[0]?.mobilenumber}`}
                data={data?.otherPhoneNumbers}
                viewAllCallback={viewAllContact}
                showPrimary={data?.otherPhoneNumbers?.[0]?.isPrimary}
              />
            ) : (
              <DocumentAttribute label="Contact Details" type="" value="" />
            )}

            {Array.isArray(data?.otherAddress) &&
              data?.otherAddress?.length > 0 ? (
              <DocumentAttribute
                label="Address Details"
                type={data?.otherAddress?.[0]?.label}
                value={getFormattedAddress(isObjectEmpty(data?.otherAddress?.[0]?.add_location?.address) ? data?.otherAddress?.[0]?.add_location?.address : data?.otherAddress?.[0])}
                data={data?.otherAddress}
                viewAllCallback={viewAllAddress}
              />
            ) : (
              <DocumentAttribute label="Address Details" type="" value="" />
            )}
          </Stack>
        </Stack>
      </Collapse>
    </Paper>
  );
}

export function MemberCardLoader() {
  return (
    <Paper
      sx={{
        position: "relative",
        border: "1px solid #E0E0E0",
        boxShadow: "0px 10px 25px 0px rgba(49, 49, 49, 0.08)",
        borderRadius: 1,
      }}
    >
      {/* Card */}
      <Stack sx={{ px: 2, py: 1.5 }}>
        <Stack flexDirection="row" gap={2}>
          <Skeleton variant="circular" width={48} height={48} />
          <Stack flex={1}>
            <Stack
              flexDirection="row"
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <Stack width={"100%"}>
                <Skeleton variant="text" width={"40%"} />
                <Skeleton variant="text" width={"35%"} />
              </Stack>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Skeleton variant="text" width={"35%"} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

function FamilyMemberDetails({ data, loading, handleEdit, ...props }) {
  return (
    <StyledPaper
      sx={{
        borderRadius: 0,
        border: "1px solid rgba(216, 220, 229, 1)",
        boxShadow: "0px 4px 25px 0px rgba(49, 49, 49, 0.08)",
        height: "100%"
      }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 3, py: 1.5 }}
      >
        <Typography
          variant="h6"
          fontWeight="500"
          // style={{ fontSize: "18px !important" }}
          sx={{ fontSize: "18px !important" }}
        >
          Family Members Details
        </Typography>
        <Button
          size="small"
          id={`addNewFamilyMembers`}
          sx={{ textTransform: "unset", fontWeight: "500", fontSize: "18px" }}
          onClick={() => {
            handleEdit(2);
          }}
        >
          + Add New
        </Button>
      </Stack>
      <StyledDivider />
      <Stack rowGap={0.5} sx={{ px: 3, py: 2.5 }}>
        {loading ? (
          <MemberCardLoader />
        ) : data?.length > 0 ? (
          data?.map((member, index) => (
            <MemberCard
              data={member}
              id={index}
              isEmergency={member?.isEmergencyContact}
              handleEdit={() => {
                handleEdit(member);
              }}
              handleDelete={() => {
                props.handleDelete(member?.id);
              }}
              dialog={props.dialog}
            />
          ))
        ) : (
          
          <NoDataHandler
          message={"No family members added yet"}
          empty
          emptyIcon={<EmptyIcon />}
          // fontSize={"4.5rem"}
          fullheight={"300px"}
        />
        )}
      </Stack>
    </StyledPaper>
  );
}

export default withDialog(FamilyMemberDetails);
