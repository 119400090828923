export const carouselData = {
  data: [
    {
      id: 1,
      sub_title:
        "A team of trained and certified healthcare professional will work closely to manage your health, wealth and wellness.",
      title: "A Team of Healthcare Experts",
      bg_image: require("../../assets/icons/icon_1.svg"),
    },
    {
      id: 2,
      sub_title:
        "An assigned Care Manager to oversee your personalised care journey",
      title: "A Dedicated Care Manager",
      bg_image: require("../../assets/icons/icon_2.svg"),
    },
    {
      id: 3,
      sub_title:
        "Just a click of button ensures safe and secure purchases via a one-stop platform",
      title: "Convenient Purchases",
      bg_image: require("../../assets/icons/icon_3.svg"),
    },
  ],
  loading: false,
};
