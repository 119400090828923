import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { alpha } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import React, { useState } from "react";
import { DrawerSkeleton } from "../../components";
import CustomAvatar from "../../components/avatar";
import CustomTextfield from "../../components/textfield";
// import { CustomizedRadioButton } from "../../components/cutomRadioButton";
import { currencyPriceFormatter, SOURCE_TYPE } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "tanyacare-middleware";

const EnquiryIdTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const EnquiryNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
}));

const EnquiryStatustypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.enquiry.viewQuotes,
  fontWeight: "bold",
  paddingLeft: 2,
}));

const DesscriptionTitleTypography = styled(Typography)(({ theme }) => ({
  // color: theme.palette.enquiry.pricingRelated,
  color: theme.palette.text.secondary,
}));

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: alpha(theme.palette.text.secondary, 0.1),
//     color: theme.palette.text.secondary,
//     fontWeight: "bold",
//     fontSize: 12,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     // fontSize: 14,
//     paddingLeft: 15,
//     paddingRight: 3,
//   },
// }));

const EnquiryDetailsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.enquiry.viewQuotes,
  borderColor: theme.palette.text.secondary,
  padding: "0.5rem 40px",
  //   marginLeft: "auto",
  //   width: "fit-content",
  "&:hover": {
    borderColor: `${theme.palette.enquiry.viewQuotes} !important`,
    backgroundColor: theme.palette.enquiry.viewQuotes,
  },
}));

const style = makeStyles((theme) => ({
  userCard: {
    border: `1px dashed ${alpha(theme.palette.text.secondary, 0.25)}`,
    padding: "0.75rem 1.5rem",
    borderRadius: 8,
    // backgroundColor:"rgba(0, 0, 0, 0.44);",
  },
  cancelButton: {
    color: `${theme.palette.enquiry.viewQuotes} !important`,
  },
}));

const {
  GET_ENQUIRY_BY_CLIENT_ID
} = actions;


const AddEnquiry = (props) => {
  const classes = style();
  const dispatch = useDispatch();

  // Store States
  const authData = useSelector((state) => state?.authState?.loginAuth?.data);

  const { data, ids = [], showDetails = true,parentID="" } = props;

  // const [showQuoteDetails, setShowQuoteDetails] = useState(false);
  // const [enquiredFor, setEnquiredFor] = useState("")
  const [description, setDescription] = useState("");

  // console.clear()
  console.log(ids);

  const onSubmit = async () => {

    // Added the GET_ENQUIRY_BY_CLIENT_ID action to check if the client is a new client or not
    const clientEnquiries = await dispatch(GET_ENQUIRY_BY_CLIENT_ID({
      clientId: authData?.user_id,
    }));


    props.handleAddEnquiry({
      isClientApp: true,
      isCart: props.isCart,
      resources: ids?.length > 0 ? ids : [data?.id],
      enquire: { remarks: description },
      sourceType: SOURCE_TYPE.CLIENT_APP,
      isNewUser: clientEnquiries?.payload?.data?.length === 0,
    });
  };

  return (
    <DrawerSkeleton
      buttonText={"Add Enquiry"}
      handleClose={props.handleClose}
      handleConfirm={onSubmit}
      title={"Add Enquiry"}
    >
      <Box>
        {/* <div style={{ marginTop: "2rem" }}>
          <EnquiryNameTypography
            sx={{
              width: "fit-content",
              margin: "auto",
              marginBottom: "1rem",
              fontSize: "14px",
            }}
          >
            NEW ENQUIRY
          </EnquiryNameTypography>
          <Divider />
        </div> */}
        {showDetails ? (
          <>
            <div style={{ padding: "1rem" }}>
              <Grid container alignItems={"center"}>
                <Grid item alignItems="center">
                  <CustomAvatar
                    variant={"circle"}
                    src={data?.image}
                    name={data?.name?.[0]}
                  >
                    {data?.name}
                  </CustomAvatar>
                </Grid>
                <Grid item xs>
                  <div style={{ marginLeft: "1rem" }}>
                    <EnquiryIdTypography
                      sx={{ fontSize: "0.90rem", fontStyle: "italic" }}
                    >
                      {data?.type}
                    </EnquiryIdTypography>
                    <EnquiryNameTypography sx={{ fontSize: "1rem" }}>
                      {data?.name}
                    </EnquiryNameTypography>
                    <Typography
                      variant="body2"
                      align="left"
                      color="text.primary"
                      sx={{ display: "flex", marginTop: "0.25rem" }}
                    >
                      {data?.periodicity ? (
                        <DesscriptionTitleTypography
                          sx={{ fontSize: "0.85rem" }}
                        >
                          {data?.periodicity}
                        </DesscriptionTitleTypography>
                      ) : (
                        <Box></Box>
                      )}

                      <Box sx={{ flexGrow: 1 }} />
                      <EnquiryStatustypography variant="body2">
                        {currencyPriceFormatter(data?.currency, data?.discount)}
                        {/* {`${data?.currency && data?.price ? `${data?.currency} ${data?.price}`: data?.price ? `${data?.price}` : data?.discount ? `${data?.currency ? data?.currency : `MYR ` }${data.discount}`: ``}`} */}
                      </EnquiryStatustypography>
                      {data?.price && data?.price !== data?.discount && (
                        <Typography
                          variant="body2"
                          style={{ paddingLeft: 5 }}
                          color="textSecondary"
                          sx={{ textDecoration: "line-through" }}
                        >
                          {currencyPriceFormatter(data?.currency, data?.price)}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Divider />
          </>
        ) : (
          <></>
        )}
        <div style={{ padding: "1rem" }}>
          {/* <Typography>What kind of help you looking for?</Typography>

        <CustomizedRadioButton
          data={{
            key: "1",
            label: "",
            options: [
              {
                // key: "General Enquiry",
                label: "General Enquiry",
                value: "General Enquiry",
              },
              {
                // key: "Pricing Related",
                label: "Pricing Related",
                value: "Pricing Related",
              },
              {
                // key: "Need More Service",
                label: "Need More Service",
                value: "Need More Service",
              },

            ],
            id: "",
          }} //needs label, options, handleOnChange
          value={enquiredFor}
          id={""}
          handleOnChange={(e) => setEnquiredFor(e)}
        // handleOnChange={(e, newValue) => alert(e)}
          error={false}
          errorText={"NoError"}
          disabled={false}
        /> */}

          <CustomTextfield
            sx={{ paddingTop: 2 }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            fullWidth
            placeholder={"Description"}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            padding: "1rem",
          }}
        >
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={() => props?.handleClose()}
          >
            Cancel
          </Button>
          <EnquiryDetailsButton
            variant="contained"
            onClick={() =>
              props.handleAddEnquiry({
                isClientApp: true,
                isCart: props.isCart,
                resources: ids?.length > 0 ? ids : [data?.id],
                enquire: { remarks: description },
              })
            }
          >
            Create
          </EnquiryDetailsButton>
        </div> */}
      </Box>
    </DrawerSkeleton>
  );
};

export default AddEnquiry;
