import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

const CustomizedButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== "size"
})(({ size }) => {
  return {
    borderRadius: 6,
    lineHeight: "normal",
    textTransform: "unset",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    ...(size==="small" && {
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 16,
      paddingRight: 16,
    })
  };
});

function CustomButton(props) {
  return (
    <CustomizedButton
      variant={props.variant}
      color={props.color}
      href={props.href}
      onClick={props.onClick}
      loading={props.loading}
      disabled={props.disabled}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      loadingPosition={props.loadingPosition}
      loadingIndicator={props.loadingIndicator}
      disableElevation
      fullWidth={props.fullWidth}
      size={props.size}
      {...props}
    >
      {props.children}
    </CustomizedButton>
  );
}

CustomButton.propTypes = {
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "error",
    "info",
    "warning",
  ]),

  /**
   * The URL to link to when the button is clicked. If defined, an a element will be used as the root node.
   */
  href: PropTypes.string,

  /**
   * Event handler for click
   */
  onClick: PropTypes.func.isRequired,

  /**
   * If true, the loading indicator is shown.
   */
  loading: PropTypes.bool,

  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * Element placed before the children.
   */
  startIcon: PropTypes.node,

  /**
   * Element placed after the children.
   */
  endIcon: PropTypes.node,

  /**
   * The loading indicator can be positioned on the start, end, or the center of the button.
   */
  loadingPosition: PropTypes.oneOf(["start", "end", "center"]),

  /**
   * Element placed before the children if the button is in loading state.
   */
  loadingIndicator: PropTypes.node,

  /**
   * If true, the button will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,

  /**
   * The size of the component. small is equivalent to the dense button styling.
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

CustomButton.defaultProps = {
  variant: "contained",
  color: "primary",
  onClick: () => {},
  loading: false,
  disabled: false,
  loadingPosition: "center",
  fullWidth: false,
  size: "medium",
};

export default CustomButton;
